import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import * as amplify from 'utils/amplify';
import * as UActions from 'store/actions/user-action';
import * as CustActions from 'store/actions/customer-action';
import * as PActions from 'store/actions/portal-action';
import API from 'utils/api';
import {getUserSession} from 'store/selectors/user-selectors';
import {STORE_LOCALE} from 'store/actions/i18n-action';
import {APIRequestGenerator} from './index';

export function* cognitoLoginSaga() {
    try {
        let authenticatedUser = yield call(amplify.currentUser);
        let session = {...authenticatedUser.signInUserSession};
        yield put({
            type: UActions.STORE_LOGIN,
            payload: {
                username: authenticatedUser.username,
                session,
            },
        });
        yield put({
            type: UActions.USER_PROFILE_SAGA,
            payload: {session},
        });
        yield put({type: UActions.USER_PROFILE_CONFIRM_SUCCESS});
    } catch (e) {
        yield put({
            type: UActions.COGNITO_LOGIN_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.COGNITO_LOGIN_SUCCESS});
    }
}

export function* userProfileSaga(action) {
    try {
        let resp = yield call(API.get, {endpoint: '/me', session: action.payload.session});
        yield put({
            type: UActions.STORE_PROFILE,
            payload: resp.data,
        });
        if (resp.data.language && resp.data.language.code)
            yield put({type: STORE_LOCALE, payload: resp.data.language.code});
        yield put({
            type: UActions.FETCH_ENDPOINT_SAGA,
            payload: resp.data.customer.id,
        });
    } catch (e) {
        yield put({
            type: UActions.USER_PROFILE_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.USER_PROFILE_SUCCESS});
    }
}

export function* confirmUserProfileSaga() {
    try {
        let session = yield select(getUserSession);
        yield call(API.get, {endpoint: '/me', session});
        yield put({type: UActions.USER_PROFILE_CONFIRM_SUCCESS});
    } catch (e) {
        yield put({
            type: UActions.USER_PROFILE_CONFIRM_FAILED,
            payload: e.message,
        });
    }
}

export function* fetchEndpointSaga(action) {
    try {
        const session = yield select(getUserSession);
        let resp = yield call(API.get, {endpoint: `/endpoints/${action.payload}`, session});
        yield put({
            type: UActions.STORE_ENDPOINT,
            payload: resp.data.core_api,
        });
    } catch (e) {
        yield put({
            type: UActions.FETCH_ENDPOINT_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.FETCH_ENDPOINT_SUCCESS});
    }
}

export function* userLogoutSaga() {
    yield call(amplify.logout);
    yield call([localStorage, 'setItem'], 'amplify-authenticator-authState', 'SignIn');
}

export function* updateCognitoEmailSaga(action) {
    let {email, user} = action.payload;
    if (!email) {
        yield put({
            type: UActions.COGNITO_UPDATE_EMAIL_FAILED,
            payload: 'Email is missing',
        });
    }

    try {
        if (!user) user = yield call(amplify.currentUser);
        yield call(amplify.updateEmail(user, email));
        yield put({
            type: UActions.STORE_EMAIL,
            payload: email,
        });
    } catch (e) {
        yield put({
            type: UActions.COGNITO_UPDATE_EMAIL_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.COGNITO_UPDATE_EMAIL_SUCCESS});
    }
}

export function* updateProfileSaga(action) {
    let {email, ...params} = action.payload;
    try {
        if (email)
            yield put({
                type: UActions.COGNITO_UPDATE_EMAIL_SAGA,
                payload: {email},
            });
        let session = yield select(getUserSession);
        let resp = yield call(API.put, {endpoint: `/me/`, session: session, params});
        yield put({type: UActions.STORE_PROFILE, payload: resp.data});
        if (resp.data.language) yield put({type: STORE_LOCALE, payload: resp.data.language.code});
    } catch (e) {
        yield put({
            type: UActions.UPDATE_PROFILE_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({
            type: PActions.STORE_PRIMARY_TOAST,
            payload: {content: 'Your profile has been updated'},
        });
        yield put({type: UActions.UPDATE_PROFILE_SUCCESS});
    }
}

export function* updateDashboardSaga(action) {
    let {...params} = action.payload;
    try {
        let session = yield select(getUserSession);
        let resp = yield call(API.put, {endpoint: `/me/`, session: session, params});
        yield put({type: UActions.STORE_PROFILE, payload: resp.data});
        if (resp.data.language) yield put({type: STORE_LOCALE, payload: resp.data.language.code});
    } catch (e) {
        yield put({
            type: UActions.UPDATE_PROFILE_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.UPDATE_PROFILE_SUCCESS});
    }
}

export function* cognitoChangePasswordSaga(action) {
    try {
        let {oldPassword, newPassword} = action.payload;
        let user = yield call(amplify.currentUser);
        yield call(amplify.changePassword, user, oldPassword, newPassword);
        yield put({
            type: PActions.STORE_PRIMARY_ALERT,
            payload: {title: 'Your password has been updated'},
        });
    } catch (e) {
        yield put({
            type: UActions.COGNITO_CHANGE_PASSWORD_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.COGNITO_CHANGE_PASSWORD_SUCCESS});
    }
}

export function* userRegisterSaga(action) {
    try {
        let {user, password, ...profile} = action.payload;
        yield call(amplify.completeNewPassword, user, password);
        yield put({
            type: UActions.UPDATE_PROFILE_SAGA,
            payload: profile,
        });
    } catch (e) {
        yield put({
            type: UActions.USER_REGISTER_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: UActions.USER_REGISTER_SUCCESS});
    }
}

export function* userTravelSaga(action) {
    yield put({type: PActions.LOADING, payload: 'travel'});
    yield put({type: CustActions.CLEAR_CUSTOMER});
    if (action.payload) yield put({type: UActions.STORE_TRAVEL, payload: action.payload});
    else yield put({type: UActions.STOP_TRAVEL});
    yield put({type: PActions.LOADED, payload: 'travel'});
}

export function* fetchProfileLiteSaga(action) {
    try {
        yield put({type: PActions.LOADING, payload: 'croo_talk_lite_credentials'});
        let user_id = action.payload.id;
        let resp = yield call(
            APIRequestGenerator,
            'get',
            `/users/${user_id}/croo_talk_lite_credentials`
        );
        yield put({
            type: UActions.STORE_USER_LITE_CREDS,
            payload: resp.data,
        });
        yield put({type: UActions.FETCH_ENDPOINT_SUCCESS});
    } catch (e) {
        yield put({
            type: UActions.FETCH_ENDPOINT_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'croo_talk_lite_credentials'});
    }
}

export function* updateProfileLiteSaga(action) {
    try {
        yield put({type: PActions.LOADING, payload: 'croo_talk_lite_credentials'});
        let user_id = action.payload.id;
        let resp = yield call(
            APIRequestGenerator,
            'put',
            `/users/${user_id}/croo_talk_lite_credentials`
        );
        yield put({
            type: UActions.STORE_USER_LITE_CREDS,
            payload: resp.data,
        });
        yield put({type: UActions.FETCH_ENDPOINT_SUCCESS});
    } catch (e) {
        yield put({
            type: UActions.FETCH_ENDPOINT_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'croo_talk_lite_credentials'});
    }
}
const userSagas = [
    takeLatest(UActions.COGNITO_LOGIN_SAGA, cognitoLoginSaga),
    takeLatest(UActions.USER_PROFILE_SAGA, userProfileSaga),
    takeLatest(UActions.USER_LOGOUT_SAGA, userLogoutSaga),
    takeLatest(UActions.FETCH_ENDPOINT_SAGA, fetchEndpointSaga),
    takeEvery(UActions.UPDATE_PROFILE_SAGA, updateProfileSaga),
    takeLatest(UActions.USER_PROFILE_CONFIRM_SAGA, confirmUserProfileSaga),
    takeEvery(UActions.UPDATE_DASHBOARD_SAGA, updateDashboardSaga),
    takeEvery(UActions.COGNITO_UPDATE_EMAIL_SAGA, updateCognitoEmailSaga),
    takeEvery(UActions.COGNITO_CHANGE_PASSWORD_SAGA, cognitoChangePasswordSaga),
    takeLatest(UActions.USER_REGISTER_SAGA, userRegisterSaga),
    takeEvery(UActions.USER_TRAVEL_SAGA, userTravelSaga),
    takeEvery(UActions.FETCH_USER_LITE_CREDS_SAGA, fetchProfileLiteSaga),
    takeEvery(UActions.UPDATE_USER_LITE_CREDS, updateProfileLiteSaga),
];
export default userSagas;
