import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spinner from '../../../../components/spinner/Spinner';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import IntegrationBindings from '../IntegrationBindings';
import {Alert, Button, CardBody, CardHeader, Row} from 'reactstrap';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import CardContent from '../../../../components/content/CardContent';
import SidePanel from '../../../../components/panel/SidePanel';
import SidePanelHeader from '../../../../components/panel/SidePanelHeader';
import SidePanelContent from '../../../../components/panel/SidePanelContent';
import IntegrationSettings from '../IntegrationSettings';
import ContentWrapper from '../../../../components/content/ContentWrapper';
import {
    deleteIntegrationBinding,
    fetchCustomerExtensions,
    fetchIntegrationAccess,
    updateIntegrationBinding,
} from '../../../../store/actions/customer-action';
import LightFontAwesomeIcon from '../../../../components/utils/LightFontAwesomeIcon';
import {Has} from '../../../../components/security/Has';
import {loaded, loading} from '../../../../store/actions/portal-action';
import {
    getUserCustomerLicense,
    getUserTravel,
    userRequestAttributesSelector,
} from '../../../../store/selectors/user-selectors';
import {customerIntegrationSelector} from '../../../../store/selectors/customer-selectors';
import API from '../../../../utils/api';

const messages = defineMessages({
    zoho_crm: {
        id: 'title.table.users.zoho_crm',
        defaultMessage: 'Zoho CRM User Bindings',
    },
    zohodesk: {
        id: 'title.table.users.zohodesk',
        defaultMessage: 'Zoho Desk User Bindings',
    },
    migration_procedure: {
        id: 'zoho.migration.procedure',
        defaultMessage: 'migration procedure',
    },
    migration_procedure_url: {
        id: 'zoho.migration.procedure.url',
        defaultMessage: 'http://croo.link/3pWO2sr',
    },
});

const InactiveIntegration = ({title}) => {
    const travel = useSelector(getUserTravel);

    return (
        <div
            className={
                'd-flex flex-grow-1 flex-column align-items-center justify-content-around m-5'
            }
        >
            <div className={'d-flex flex-column align-items-center'}>
                <small className={'text-center mb-3 w-50'}>
                    {!!!travel ? (
                        <FormattedMessage
                            id={'message.admin.integration.deprecated'}
                            defaultMessage={
                                '{integration_name} is now deprecated. Please use the {new_integration_name} integration instead.'
                            }
                            values={{
                                integration_name: title,
                                new_integration_name: 'Zoho PhoneBridge',
                            }}
                        />
                    ) : (
                        `${title} is deprecated.`
                    )}
                </small>
            </div>
        </div>
    );
};

const DeprecatedZohoIntegrationRoute = ({intl, name, title, ...props}) => {
    const reduxDispatch = useDispatch();
    const integration = useSelector(customerIntegrationSelector(name));
    const loadingAccess = useSelector(getLoading(`${name}_access`));
    const requestAttributes = useSelector(userRequestAttributesSelector);
    const customerLicense = useSelector(getUserCustomerLicense);
    // The licence is checked and if not authorised, the integration is not displayed.
    const restriction = customerLicense === 'PRO';

    const oauth_activation = async () => {
        reduxDispatch(loading(`integration_redirect_${title}`));
        let resp = await API.get({
            endpoint: `/integrations/${name}/redirect`,
            tries: 1,
            ...requestAttributes,
        });
        if (resp.data && resp.data.url) {
            reduxDispatch(loaded(`integration_redirect_${title}`));
            window.open(resp.data.url, '_top');
        } else {
            reduxDispatch(loaded(`integration_redirect_${title}`));
            props.history.push(`/admin`);
        }
    };

    const [settingsModal, setSettingsModal] = useState(false);

    const toggleSettingsModal = () => setSettingsModal((s) => !s);

    useEffect(() => {
        reduxDispatch(fetchIntegrationAccess(name));
        reduxDispatch(fetchCustomerExtensions());
    }, [reduxDispatch, name]);

    return (
        <ContentWrapper>
            {!restriction && (
                <Alert color={'danger'}>
                    <FormattedMessage
                        id={'zoho.deprecation.alert'}
                        defaultMessage={
                            'The {title} integration is now deprecated, and will soon be deleted. Please follow the {migration_procedure} in order to migrate to Zoho PhoneBridge and keep using your integration features.'
                        }
                        values={{
                            title: title,
                            migration_procedure: (
                                <a
                                    className={'text-white'}
                                    style={{textDecoration: 'underline'}}
                                    href={intl.formatMessage(messages.migration_procedure_url)}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    {intl.formatMessage(messages.migration_procedure)}
                                </a>
                            ),
                        }}
                    />
                </Alert>
            )}
            <CardContent fullHeight scroll>
                {restriction ? (
                    <div className='align-horizontal-vertical'>
                        <FormattedMessage
                            id={'tooltip.admin_sidebar.warning'}
                            defaultMessage={
                                'Your license does not give you access to integrations.'
                            }
                        />
                    </div>
                ) : (
                    <>
                        <CardHeader className='d-flex align-items-baseline'>
                            <Row className='flex-grow-1'>
                                <h3 className='mb-0 mr-2'>{intl.formatMessage(messages[name])}</h3>
                            </Row>
                            <Has
                                aPolicyMatching={[{action: 'WRITE', resourceType: 'INTEGRATIONS'}]}
                            >
                                <Button color={'primary'} size={'sm'} onClick={toggleSettingsModal}>
                                    <LightFontAwesomeIcon icon={'pen'} className={'mr-1'} />
                                    <FormattedMessage id={'btn.edit'} defaultMessage={'Edit'} />
                                </Button>
                            </Has>
                            <SidePanel
                                className={'bg-white'}
                                isOpen={settingsModal}
                                setOpen={setSettingsModal}
                                onGlassClick={() => setSettingsModal(false)}
                            >
                                <SidePanelHeader>
                                    {intl.formatMessage(messages[name])}
                                </SidePanelHeader>
                                <SidePanelContent>
                                    {integration && (
                                        <IntegrationSettings
                                            toggle={toggleSettingsModal}
                                            integration={{name, ...integration}}
                                        />
                                    )}
                                </SidePanelContent>
                            </SidePanel>
                        </CardHeader>
                        <CardBody
                            style={{overflowY: 'auto'}}
                            className={'d-flex flex-column flex-grow-1 p-0'}
                        >
                            {loadingAccess ? (
                                <Spinner
                                    centered
                                    color={'primary'}
                                    className={'align-self-center'}
                                    background={'transparent'}
                                    global={false}
                                    size={50}
                                />
                            ) : (
                                <>
                                    {integration &&
                                    integration.access &&
                                    integration.access.has_access ? (
                                        <IntegrationBindings
                                            disabled
                                            name={name}
                                            integration={integration}
                                            title={title}
                                            updateIntegrationBinding={(action) =>
                                                reduxDispatch(updateIntegrationBinding(action))
                                            }
                                            deleteIntegrationBinding={(action) =>
                                                reduxDispatch(deleteIntegrationBinding(action))
                                            }
                                        />
                                    ) : (
                                        <InactiveIntegration
                                            title={title}
                                            oauth={oauth_activation}
                                        />
                                    )}
                                </>
                            )}
                        </CardBody>
                    </>
                )}
            </CardContent>
        </ContentWrapper>
    );
};

export default injectIntl(DeprecatedZohoIntegrationRoute);
