import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    add: {
        id: 'btn.add',
        defaultMessage: 'Add',
    },
    errorMessageExtension: {
        id: 'input.error.extension',
        defaultMessage: 'The extension must contain only numbers and be a maximum of 6 characters',
    },
    infoMessageExtension: {
        id: 'input.info.extension',
        defaultMessage: 'It is recommended to use at least 2 digits for an extension number',
    },
    errorMessageName: {
        id: 'input.error.name',
        defaultMessage: 'The name must not contain special characters',
    },
    errorMessagePasswordVoicemail: {
        id: 'input.error.password_voicemail',
        defaultMessage:
            'The voicemail password must contain only numbers and be a maximum of 10 characters',
    },
    labelExtension: {
        id: 'input.label.extension',
        defaultMessage: 'Extension',
    },
    placeholderExtension: {
        id: 'input.placeholder.extension',
        defaultMessage: 'Choose an extension number',
    },
    groupRingTime: {
        id: 'input.label.ring.time.on.forward',
        defaultMessage: 'Ring time during a transfer',
    },
    preRingTime: {
        id: 'input.label.ring.time.before.forward',
        defaultMessage: 'Initial ring time',
    },
    redirectNumber: {
        id: 'input.label.redirect.number',
        defaultMessage: 'Redirect number(s)',
    },
    ringStrategy: {
        id: 'input.label.ring.strategy',
        defaultMessage: 'Ring strategy',
    },
    sendAttachment: {
        id: 'input.label.send.attachment',
        defaultMessage: 'Send as attachment',
    },
    strategyInfo: {
        id: 'select.strategy.ring.info',
        defaultMessage:
            'The "Ring Group" option will make every extension in the group ring at once until someone picks up the call, whereas the "Hunt group" option will make them ring one at a time until someone picks up.',
    },
    ringAll: {
        id: 'select.strategy.ring.all',
        defaultMessage: 'Ring group',
    },
    hunt: {
        id: 'select.strategy.ring.hunt',
        defaultMessage: 'Hunt group',
    },
    generate: {
        id: 'btn.generate',
        defaultMessage: 'Generate',
    },
    createExtension: {
        id: 'btn.create.extension',
        defaultMessage: 'Create Extension',
    },
    adminExtension: {
        id: 'message.admin.extension.general',
        defaultMessage: 'General Settings',
    },
    labelName: {
        id: 'input.label.display.name',
        defaultMessage: 'Display Name',
    },
    placeholderName: {
        id: 'input.placeholder.display.name',
        defaultMessage: 'How should we call you?',
    },
    labelSecret: {
        id: 'input.label.secret',
        defaultMessage: 'Secret',
    },
    placeholderSecret: {
        id: 'input.placeholder.secret',
        defaultMessage: 'Your secret',
    },
    voicemailSettings: {
        id: 'message.admin.extension.voicemail.settings',
        defaultMessage: 'Voicemail Settings',
    },
    labelVoicemailPassword: {
        id: 'input.label.voicemail.password',
        defaultMessage: 'Voicemail Password',
    },
    placeholderVoicemailPassword: {
        id: 'input.placeholder.voicemail.email.password',
        defaultMessage: 'Your password',
    },
    labelVoicemailDelivery: {
        id: 'input.label.voicemail.delivery.by.email',
        defaultMessage: 'Deliver by email',
    },
    labelVoicemailEmailAddress: {
        id: 'input.label.voicemail.email.address',
        defaultMessage: 'Email Address',
    },
    placeholderVoicemailEmailAddress: {
        id: 'input.placeholder.voicemail.email.address',
        defaultMessage: 'Your email address',
    },
    labelVoicemailDelete: {
        id: 'input.label.voicemail.delete.after.delivery',
        defaultMessage: 'Delete after delivery',
    },
    callForwardSettings: {
        id: 'message.admin.extension.call.forward.settings',
        defaultMessage: 'Call Forward Settings',
    },
    labelCallForward: {
        id: 'input.label.call.forward.enable',
        defaultMessage: 'Enabled',
    },
    advanced: {
        id: 'message.admin.advanced',
        defaultMessage: 'Advanced',
    },
    deleteExtension: {
        id: 'message.admin.delete.extension',
        defaultMessage: 'Delete Extension',
    },
    deleteExtensionExplanation: {
        id: 'message.admin.delete.extension.explanation',
        defaultMessage:
            'Deleting an extension will disable all voicemail and call forwarding features for the attached number.',
    },
    cancel: {
        id: 'btn.cancel',
        defaultMessage: 'Cancel',
    },
    save: {
        id: 'btn.save',
        defaultMessage: 'Save',
    },
    deleteExtensionConfirmation: {
        id: 'message.admin.delete.extension.confirmation',
        defaultMessage:
            'Are you sure you wish to delete this extension? This operation cannot be undone.',
    },
    sipGenerate: {
        id: 'message.sip.generate',
        defaultMessage:
            'If you modify your SIP credentials, all of your devices will be disconnected.',
    },
    errorOccured: {
        id: 'message.error',
        defaultMessage: 'Invalid field',
    },
    editExtension: {
        id: 'message.admin.edit.extension',
        defaultMessage: 'Edit extension',
    },
    titleTableExtensions: {
        id: 'title.table.extensions',
        defaultMessage: 'Extensions',
    },
    titleTableExtensionNumber: {
        id: 'title.table.extension.number',
        defaultMessage: 'Extension',
    },
    titleTableExtensionName: {
        id: 'title.table.extension.name',
        defaultMessage: 'Extension Name',
    },
    inputSearch: {
        id: 'input.placeholder.search',
        defaultMessage: 'Search',
    },
    errorExistExtension: {
        id: 'input.error.exist_extension',
        defaultMessage: 'This extension already exists',
    },
});
