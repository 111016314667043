import {faPhoneSquareAlt} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage} from 'react-intl';
import React from 'react';
import TextInput from '../../input/TextInput';
import {formatAmericanNumber} from '../../../utils/format';

const messages = defineMessages({
    display: {
        id: 'filter.display.to.number',
        defaultMessage: 'To number: {number}',
    },
    title: {
        id: 'filter.title.to.number',
        defaultMessage: 'To number',
    },
});

const factory = (number) => ({
    type: 'to_number',
    index: `to_number_${number}`,
    serialized: number,
    custom_filter: (query) => {
        query.filters.to_number = query.filters.to_number
            ? [number, ...query.filters.to_number]
            : [number];
        return query;
    },
    elastic_filter: (query) =>
        query.filter('bool', {
            should: [
                {
                    bool: {
                        must: [
                            {wildcard: {from_number: `*${number.replace(/([^0-9]+)/gi, '')}*`}},
                            {term: {'direction.keyword': 'in'}},
                        ],
                    },
                },
                {
                    bool: {
                        must: [
                            {wildcard: {to_number: `*${number.replace(/([^0-9]+)/gi, '')}*`}},
                            {term: {'direction.keyword': 'out'}},
                        ],
                    },
                },
                {
                    bool: {
                        must: [
                            {wildcard: {to_number: `*${number.replace(/([^0-9]+)/gi, '')}*`}},
                            {term: {'direction.keyword': 'internal'}},
                        ],
                    },
                },
            ],
        }),
    display: {
        icon: faPhoneSquareAlt,
        content: (
            <FormattedMessage
                {...messages.display}
                values={{number: formatAmericanNumber(number)}}
            />
        ),
    },
});

export const to_number_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: TextInput,
    componentProps: {
        placeholder: 'To number',
        type: 'phone',
        validator: (number) => number && number !== '',
    },
    factory: factory,
    icon: faPhoneSquareAlt,
};
