import React from 'react';
import * as PropTypes from 'prop-types';
import {Card} from 'reactstrap';

const CardContent = ({children, fullHeight, scroll, style}) => {
    return (
        <Card
            className={`d-flex flex-column ${fullHeight ? 'flex-grow-1' : ''}`}
            style={{overflowY: scroll ? 'auto' : 'hidden', ...style}}
        >
            {children}
        </Card>
    );
};

CardContent.propTypes = {
    fullHeight: PropTypes.bool,
    scroll: PropTypes.bool,
    style: PropTypes.object,
};

CardContent.defaultProps = {
    fullHeight: false,
    scroll: false,
    style: {},
};

export default CardContent;
