import {combineReducers} from 'redux';
import user from 'store/reducers/user-reducer';
import i18n from 'store/reducers/i18n-reducer';
import portal from 'store/reducers/portal-reducer';
import cdr from 'store/reducers/cdr-reducer';
import customer from 'store/reducers/customer-reducer';
import download from 'store/reducers/download-reducer';

export const staticReducers = {
    user,
    portal,
    i18n,
    cdr,
    customer,
    download,
};

const reducers = (asyncReducers) => {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers,
    });
};

export default reducers;
