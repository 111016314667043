import React, {useEffect, useReducer, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, CardBody, CardFooter, CardHeader} from 'reactstrap';
import {getCustomerApiKeys, getCustomerMaxApiKeys} from 'store/selectors/customer-selectors';
import ApiKeyForm from './ApiKeyForm';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCustomerApiKeys} from 'store/actions/customer-action';
import {Table} from 'components/layout/Table/Table';
import ContentWrapper from 'components/content/ContentWrapper';
import CardContent from 'components/content/CardContent';
import SidePanel from 'components/panel/SidePanel';
import SearchBar from 'components/search/SearchBar';
import isEqual from 'lodash.isequal';
import LightFontAwesomeIcon from '../../../../components/utils/LightFontAwesomeIcon';
import CheckboxInput from '../../../../components/input/CheckboxInput';
import {Action, ActionsCell, BooleanCell} from '../../../../components/layout/Table/Cells';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import Spinner from '../../../../components/spinner/Spinner';
import {Has} from '../../../../components/security/Has';
import {getUserCustomerLicense} from 'store/selectors/user-selectors';

const columns = [
    {
        id: 'api_key',
        title: <FormattedMessage id={'title.table.api.key'} defaultMessage={'API Key'} />,
        key: 'apikey',
        sort: {key: 'apikey'},
        width: 'auto',
    },
    {
        id: 'timezone',
        title: <FormattedMessage id={'title.table.timezone'} defaultMessage={'Timezone'} />,
        key: 'timezone',
        sort: {key: 'timezone'},
        width: 'minmax(200px, 300px)',
    },
    {
        id: 'enabled',
        title: <FormattedMessage id={'title.table.enabled'} defaultMessage={'Active'} />,
        cellFactory: ({enabled}) => <BooleanCell enabled={enabled} />,
        width: '100px',
    },
];

const ApiKeys = () => {
    const apiKeys = useSelector(getCustomerApiKeys);
    const maxApiKeys = useSelector(getCustomerMaxApiKeys);
    const loading = useSelector(getLoading('api_keys'));
    const customerLicense = useSelector(getUserCustomerLicense);
    // The licence is checked and if not authorised, the integration is not displayed.
    const restriction = customerLicense === 'PRO';

    const reduxDispatch = useDispatch();

    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [apiKey, setApiKey] = useState(null);
    const [external, setExternal] = useState(false);
    const [foundApiKeys, foundApiKeysDispatch] = useReducer((state, action) => {
        const found = action.payload;
        return isEqual(state, found) ? state : found;
    }, apiKeys);

    useEffect(() => {
        reduxDispatch(fetchCustomerApiKeys({external}));
    }, [reduxDispatch, external]);

    const create = () => {
        setApiKey(null);
        setSidePanelOpen(true);
    };

    const cancel = () => {
        setApiKey(null);
        setSidePanelOpen(false);
    };

    const edit = (api_key) => {
        setApiKey(api_key);
        setSidePanelOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    {restriction ? (
                        <div className='align-horizontal-vertical'>
                            <FormattedMessage
                                id={'tooltip.admin_sidebar.warning'}
                                defaultMessage={
                                    'Your license does not give you access to integrations.'
                                }
                                className='align-horizontal-vertical'
                            />
                        </div>
                    ) : (
                        <>
                            <CardHeader className='table-header no-select'>
                                <h2 className='mb-0 mr-2'>
                                    <FormattedMessage
                                        id='title.table.api.keys'
                                        defaultMessage='API Keys'
                                    />
                                </h2>
                                <Has
                                    aPolicyMatching={[{action: 'WRITE', resourceType: 'API_KEYS'}]}
                                >
                                    <Button
                                        className={'align-self-center mr-auto'}
                                        color={'primary'}
                                        disabled={apiKeys.length >= maxApiKeys}
                                        onClick={create}
                                        size={'sm'}
                                    >
                                        <LightFontAwesomeIcon icon={'plus'} />
                                    </Button>
                                </Has>
                                <span
                                    className={
                                        'd-flex flex-row align-items-center align-self-center ml-auto mr-3'
                                    }
                                >
                                    <FormattedMessage
                                        id={'input.checkbox.show.external.api.keys'}
                                        defaultMessage={'External API keys'}
                                    >
                                        {(m) => (
                                            <CheckboxInput
                                                id={'external-api-keys'}
                                                label={m}
                                                value={external}
                                                onValueChange={setExternal}
                                            />
                                        )}
                                    </FormattedMessage>
                                </span>
                                <SearchBar
                                    fields={['apikey', 'timezone']}
                                    items={apiKeys}
                                    live
                                    minimumCharacters={3}
                                    placeholder={'Search'}
                                    setFoundItems={(f) => foundApiKeysDispatch({payload: f})}
                                />
                            </CardHeader>
                            <CardBody
                                style={{overflowY: 'auto'}}
                                className={'d-flex flex-column flex-grow-1 p-0'}
                            >
                                {loading ? (
                                    <Spinner
                                        centered
                                        color={'primary'}
                                        background={'transparent'}
                                        global={false}
                                        size={50}
                                    />
                                ) : (
                                    <Table
                                        itemSize={55}
                                        columns={[
                                            ...columns,
                                            {
                                                id: 'edit',
                                                title: '',
                                                cellFactory: (api_key) =>
                                                    api_key.permissions.zohoIntegration ? null : (
                                                        <ActionsCell>
                                                            <Has
                                                                aPolicyMatching={[
                                                                    {
                                                                        action: 'WRITE',
                                                                        resourceType: 'API_KEYS',
                                                                    },
                                                                ]}
                                                            >
                                                                <Action
                                                                    onClick={() => edit(api_key)}
                                                                    icon={'pen'}
                                                                />
                                                            </Has>
                                                        </ActionsCell>
                                                    ),
                                                width: '50px',
                                                className: 'justify-content-end',
                                            },
                                        ]}
                                        items={foundApiKeys}
                                        itemsDispatch={(items) =>
                                            foundApiKeysDispatch({payload: items})
                                        }
                                    />
                                )}
                            </CardBody>
                            {!external && !loading && (
                                <CardFooter>
                                    <small>
                                        <FormattedMessage
                                            id={'table.caption.api.key.used'}
                                            defaulMessage={'{current}/{maximum} API keys used'}
                                            values={{
                                                current: apiKeys.length,
                                                maximum: maxApiKeys,
                                            }}
                                        />
                                    </small>
                                </CardFooter>
                            )}
                        </>
                    )}
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={isSidePanelOpen}
                setOpen={setSidePanelOpen}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <ApiKeyForm apiKey={apiKey} toggle={cancel} />
            </SidePanel>
        </>
    );
};

export default ApiKeys;
