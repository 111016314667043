import React from 'react';
import './Spinner.css';
import * as PropTypes from 'prop-types';
import {ConditionalWrapper} from '../utils/ConditionalWrapper';

const Spinner = ({background, centered, className, color, global, noMargin, size, style, text, position = 'inherit'}) => (
    <ConditionalWrapper
        condition={centered}
        wrapper={(children) => (
            <div className={`d-flex w-100 h-100 justify-content-around spinner-position-${position}`}>{children}</div>
        )}
    >
        <div
            className={`d-flex flex-column align-items-center justify-content-center bg-${background} ${
                global ? 'spinner-global' : ''
            }`}
        >
            <div
                className={`${className} spinner spinner-${color}`}
                style={{
                    borderWidth: size / 25,
                    width: size,
                    height: size,
                    margin: noMargin ? '-60px !important' : 0,
                    ...style,
                }}
            />
            {text && <small className={'text-muted mt-3'}>{text}</small>}
        </div>
    </ConditionalWrapper>
);

Spinner.propTypes = {
    background: PropTypes.string,
    centered: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.oneOf(['white', 'primary', 'default']),
    message: PropTypes.string,
    global: PropTypes.bool,
    noMargin: PropTypes.bool,
    size: PropTypes.number,
    style: PropTypes.object,
};

Spinner.defaultProps = {
    background: 'gradient-primary',
    centered: false,
    className: '',
    color: 'white',
    global: true,
    noMargin: false,
    size: 200,
    text: null,
    style: {},
};

export default Spinner;
