import React from 'react';
import {Card, CardBody} from 'reactstrap';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import TeamAvatar from './TeamAvatar';
import UserAvatar from '../user/UserAvatar';
import Avatar from '../media/image/Avatar';
import {FormattedMessage} from 'react-intl';

const TeamCard = ({actionComponent, className, onClick, style, team}) => {
    return (
        <Card className={className} style={style}>
            <CardBody>
                <div className={'d-flex flex-column align-items-center w-100'}>
                    <div className={'pb-1'}>
                        <TeamAvatar onClick={onClick} size={'lg'} team={team} />
                    </div>
                    {team ? <h3>{team.name}</h3> : <Skeleton />}
                    <div>
                        {team &&
                            team.team_users.map((team_user) => (
                                <UserAvatar
                                    id={`team-${team.id}-${team_user.user.id}-avatar`}
                                    className={'border border-white mx--2'}
                                    user={team_user.user}
                                    size={'xs'}
                                    key={team_user.user.id}
                                />
                            ))}
                        {(!team || (team && team.team_users.length === 0)) && (
                            <>
                                <Avatar size={'xs'} alt={'Empty'} style={{visibility: 'hidden'}} />
                                <small className={'text-muted'}>
                                    <FormattedMessage
                                        id={'message.no.members'}
                                        defaultMessage={'This team has no members'}
                                    />
                                </small>
                                <Avatar size={'xs'} alt={'Empty'} style={{visibility: 'hidden'}} />
                            </>
                        )}
                    </div>
                    <div className={'mt-3'}>{actionComponent}</div>
                </div>
            </CardBody>
        </Card>
    );
};

TeamCard.propTypes = {
    actionComponent: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    team: PropTypes.shape({
        avatar: PropTypes.string,
        name: PropTypes.string.isRequired,
    }),
};

export default TeamCard;
