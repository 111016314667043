import React, {useReducer, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {geti18nLocale, getUserRegistered} from 'store/selectors/user-selectors';
import {localeChange} from 'store/actions/i18n-action';
import {registerUser} from 'store/actions/user-action';
import PasswordSetter from '../../../components/password/PasswordSetter';
import isEqual from 'lodash.isequal';
import RedirectTo from '../../../components/utils/RedirectTo';
import {useHistory} from 'react-router-dom';

const UserOnboarding = ({user}) => {
    const locale = useSelector(geti18nLocale);
    const registered = useSelector(getUserRegistered);

    const history = useHistory();

    const reduxDispatch = useDispatch();

    const [form, formDispatch] = useReducer(
        (state, {field, value}) => {
            const newState = {...state};
            newState[field] = value;
            return isEqual(state, newState) ? state : newState;
        },
        {
            full_name: user.challengeParam.userAttributes.name || '',
            nickname: user.challengeParam.userAttributes.family_name || '',
            email: user.challengeParam.userAttributes.email,
            privacy_policy: false,
            newsletter: false,
        }
    );
    const [validPassword, setValidPassword] = useState(false);
    const [password, setPassword] = useState(undefined);

    const submit = (e) => {
        e.preventDefault();
        reduxDispatch(registerUser({...form, password, user}));
    };

    const formInputDispatch = (event) =>
        formDispatch({field: event.target.name, value: event.target.value});

    const formCheckboxDispatch = (event) => {
        formDispatch({field: event.target.name, value: !form[event.target.name]});
    };

    return registered ? (
        <Card className='w-card align-self-center bg-secondary border-0 no-select d-flex'>
            <CardBody>
                <RedirectTo history={history} timer={10} path={'/'} />
            </CardBody>
        </Card>
    ) : (
        <Card className='col-8 align-self-center no-select d-flex'>
            <CardHeader>
                <FormattedMessage id='title.signup.form' defaultMessage='Setup your account'>
                    {(m) => <h3 className='mb-0'>{m}</h3>}
                </FormattedMessage>
            </CardHeader>
            <CardBody className='p-3'>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Row>
                        <Col className={'border-right'}>
                            <Row>
                                <FormGroup className='col'>
                                    <FormattedMessage
                                        id='input.label.nickname'
                                        defaultMessage='Nickname'
                                    >
                                        {(m) => <label className='form-control-label'>{m}</label>}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id='input.placeholder.nickname'
                                        defaultMessage='How should we call you?'
                                    >
                                        {(m) => (
                                            <Input
                                                placeholder={m}
                                                name='nickname'
                                                type='text'
                                                value={form.nickname}
                                                onChange={formInputDispatch}
                                                required
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <FormattedMessage
                                        id='input.label.full_name'
                                        defaultMessage='Full name'
                                    >
                                        {(m) => <label className='form-control-label'>{m}</label>}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id='input.placeholder.full_name'
                                        defaultMessage='Your full name'
                                    >
                                        {(m) => (
                                            <Input
                                                placeholder={m}
                                                name='full_name'
                                                type='text'
                                                value={form.full_name}
                                                onChange={formInputDispatch}
                                                required
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <FormattedMessage
                                        id='input.label.email'
                                        defaultMessage='Email address'
                                    >
                                        {(m) => <label className='form-control-label'>{m}</label>}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id='input.placeholder.email'
                                        defaultMessage='Your email address'
                                    >
                                        {(m) => (
                                            <Input
                                                placeholder={m}
                                                name='email'
                                                type='email'
                                                value={form.email}
                                                onChange={formInputDispatch}
                                                disabled
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <FormattedMessage
                                        id='input.label.language'
                                        defaultMessage='Language'
                                    >
                                        {(m) => <label className='form-control-label'>{m}</label>}
                                    </FormattedMessage>
                                    <Input
                                        type='select'
                                        name='language'
                                        value={locale}
                                        onChange={(e) =>
                                            reduxDispatch(localeChange(e.target.value))
                                        }
                                    >
                                        <option value='en'>English</option>
                                        <option value='fr'>French</option>
                                    </Input>
                                </FormGroup>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <PasswordSetter
                                        size={'md'}
                                        className={'d-flex flex-column w-100'}
                                        onValidityChange={setValidPassword}
                                        onValueChange={setPassword}
                                        showFormLabels
                                    />
                                </Col>
                            </Row>
                            <Row className={'align-items-center'}>
                                <Col md={8}>
                                    <div className='custom-control custom-checkbox mb-1'>
                                        <input
                                            className='custom-control-input'
                                            checked={form.privacy_policy}
                                            name='privacy_policy'
                                            onChange={formCheckboxDispatch}
                                            id='privacyCheck'
                                            type='checkbox'
                                            required
                                        />
                                        <FormattedMessage
                                            id='input.label.privacy_policy'
                                            defaultMessage='I agree with the Privacy Policy'
                                        >
                                            {(m) => (
                                                <label
                                                    className='custom-control-label text-sm-sm'
                                                    htmlFor='privacyCheck'
                                                >
                                                    {m}
                                                </label>
                                            )}
                                        </FormattedMessage>
                                    </div>
                                    <div className='custom-control custom-checkbox mb-1'>
                                        <input
                                            className='custom-control-input'
                                            checked={form.newsletter}
                                            name='newsletter'
                                            onChange={formCheckboxDispatch}
                                            id='newsletterCheck'
                                            type='checkbox'
                                        />
                                        <FormattedMessage
                                            id='input.label.newsletter'
                                            defaultMessage='Opt-in to receive our newsletter'
                                        >
                                            {(m) => (
                                                <label
                                                    className='custom-control-label text-sm-sm'
                                                    htmlFor='newsletterCheck'
                                                >
                                                    {m}
                                                </label>
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormattedMessage id='btn.submit' defaultMessage='Submit'>
                                        {(m) => (
                                            <button
                                                className='btn btn-primary btn-sm float-right'
                                                type='submit'
                                                disabled={!(form.privacy_policy && validPassword)}
                                            >
                                                {m}
                                            </button>
                                        )}
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    );
};

export default UserOnboarding;
