import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import LightFontAwesomeIcon from '../../utils/LightFontAwesomeIcon';
import HoverAnimation from '../../animation/HoverAnimation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

export const ActionsCell = ({children}: {children: React.ReactElement | React.ReactElement[]}) => (
    <span className={'d-flex justify-content-end'}>
        {Array.isArray(children)
            ? children.map((c, i) => {
                  let className = c.props.className;
                  if (i !== 0) {
                      if (className) c.props.className += ' ml-2';
                      else className = 'ml-2';
                  }
                  return React.cloneElement(c, {
                      key: i,
                      className,
                  });
              })
            : children}
    </span>
);

interface ActionTypeProps {
    id?: string;
    className: string;
    color?: string;
    enabled: boolean;
    icon: string;
    onClick: () => void;
    tooltipId: string;
    tooltip?: React.ReactNode;
}

export const Action = ({
    id,
    className,
    color,
    enabled,
    icon,
    onClick,
    tooltip,
    tooltipId,
    ...props
}: ActionTypeProps) => (
    <>
        {tooltip && (
            <UncontrolledTooltip target={tooltipId} placement={'left'}>
                {tooltip}
            </UncontrolledTooltip>
        )}
        <span id={tooltipId}>
            <HoverAnimation animation={'pulse'}>
                <LightFontAwesomeIcon
                    className={`${className} text-${enabled ? 'primary' : 'lighter'} 
                        cursor-${enabled ? 'pointer' : 'default'}`}
                    fixedWidth
                    size={'lg'}
                    onClick={onClick}
                    icon={icon}
                    {...props}
                />
            </HoverAnimation>
        </span>
    </>
);

Action.defaultProps = {
    className: '',
    enabled: true,
    tooltipId: '',
};

export const BooleanCell = ({enabled}: {enabled: boolean}) => (
    <FontAwesomeIcon
        icon={enabled ? faCheck : faTimes}
        style={{color: enabled ? 'green' : 'red'}}
    />
);

BooleanCell.defaultProps = {
    enabled: false,
};
