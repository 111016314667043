import {DependencyList, EffectCallback, Reducer, useEffect, useReducer, useRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import isEqual from 'lodash.isequal';
import {set} from 'lodash';
import {Isomorph, Paths} from './types';

const usePrevious = <T>(value: T, initialValue: T) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useEffectDebugger = (
    effectHook: EffectCallback,
    dependencies: DependencyList,
    dependencyNames: string[] = []
) => {
    const previousDeps: DependencyList = usePrevious(dependencies, []);

    const changedDeps: object = dependencies.reduce((accum: object, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency,
                },
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log('[use-effect-debugger] ', changedDeps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effectHook, dependencies);
};

export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current && savedCallback.current();
        }

        if (delay != null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

interface DeepStateAction<T> {
    type: 'set' | 'force';
    payload: T;
}

export const useDeepState = <T>(defaultState: T) => {
    const [reducer, dispatch] = useReducer((state: T, action: DeepStateAction<T>) => {
        switch (action.type) {
            case 'set':
                return isEqual(state, action.payload) ? state : action.payload;
            case 'force':
                return action.payload;
            default:
                return state;
        }
    }, defaultState);

    return [
        reducer,
        (payload: T) => dispatch({type: 'set', payload}),
        (payload: T) => dispatch({type: 'force', payload}),
    ];
};

interface ListReducerSingleItemAction<T> {
    type: 'add' | 'edit' | 'remove';
    payload: T;
}

interface ListReducerMultiItemAction<T> {
    type: 'replace';
    payload: T[];
}

interface ListReducerNoItemAction {
    type: 'reset';
}

type ListReducerAction<T> =
    | ListReducerSingleItemAction<T>
    | ListReducerMultiItemAction<T>
    | ListReducerNoItemAction;

export const useListReducer = <T>(getId: (v: T) => any = (x) => x, defaultState: T[] = []) => {
    const idComparator = (a: T, b: T) => getId(a) === getId(b);

    const [state, dispatch] = useReducer((prevState: T[], action: ListReducerAction<T>) => {
        let newState = [...prevState];
        switch (action.type) {
            case 'reset':
                return defaultState;
            case 'replace':
                newState = action.payload;
                break;
            case 'add':
                newState = newState.filter((item) => !idComparator(item, action.payload));
                newState.unshift(action.payload);
                break;
            case 'edit':
                const index = newState.findIndex((item) => idComparator(item, action.payload));
                newState.splice(index, 1, action.payload);
                break;
            case 'remove':
                newState = newState.filter((item) => !idComparator(item, action.payload));
                break;
            default:
                newState = prevState;
        }
        return isEqual(prevState, newState) ? prevState : newState;
    }, defaultState);

    return [state, dispatch];
};

interface ObjectReducerSingleItemAction<T> {
    type: 'edit';
    payload: {
        field: Paths<T, 4>;
        value: any;
    };
}

interface ObjectReducerReplaceAction<T> {
    type: 'replace';
    payload: T;
}

interface ObjectReducerNoItemAction {
    type: 'reset';
}

type ObjectReducerAction<T> =
    | ObjectReducerSingleItemAction<T>
    | ObjectReducerReplaceAction<T>
    | ObjectReducerNoItemAction;

export const useObjectReducer: <T extends object>(
    defaultState: T
) => [T, (action: ObjectReducerAction<T>) => void] = <T extends object>(defaultState: T) => {
    return useReducer<Reducer<T, ObjectReducerAction<T>>>((prevState, action) => {
        let newState = {...prevState};
        switch (action.type) {
            case 'reset':
                return defaultState;
            case 'replace':
                return action.payload;
            case 'edit':
                set<T>(newState, action.payload.field, action.payload.value);
                break;
            default:
                newState = prevState;
        }
        return isEqual(prevState, newState) ? prevState : newState;
    }, defaultState);
};

export const useQueryStringObjectReducer = <T extends object>(
    defaultState: T,
    isomorph: Isomorph<T, string>
): [T, (action: ObjectReducerAction<T>) => void] => {
    const history = useHistory();
    const location = useLocation();

    const [state, dispatch] = useObjectReducer(
        location.search ? isomorph.to(location.search.slice(1)) : defaultState
    );

    useEffect(() => {
        const currentParams = new URLSearchParams(location.search);
        const newParams = new URLSearchParams(isomorph.from(state));
        const result = new URLSearchParams();

        currentParams.forEach((value, key) => result.set(key, value));
        newParams.forEach((value, key) => result.set(key, value));

        if (!isEqual(location.search.slice(1), result.toString())) {
            history.replace(`${location.pathname}?${result.toString()}`);
        }
    }, [state, isomorph, history, location.pathname, location.search]);

    return [state, dispatch];
};
