import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChartPie,
    faHiking,
    faHistory,
    faInfoCircle,
    faLink,
    faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import CheckboxInput from '../../components/input/CheckboxInput';
import AdminOnlyBadge from '../../components/notification/whats-new/AdminOnlyBadge';
import {Has} from '../../components/security/Has';

const whats_new_fr = [
    {
        date: '2022-01-27',
        changelog: [
            {
                title: "Ligne du temps d'un appel",
                content: (
                    <>
                        Vous pouvez maintenant voir la ligne du temps d'un appel dans la section{' '}
                        <strong>Historique des appels</strong>. L'icône <strong>Information</strong>{' '}
                        (
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={'text-primary'}
                            fixedWidth
                        />
                        ) vous permet d'obtenir une liste détaillée des évènements d'un appel, comme
                        le temps passé en attente et le temps de sonnerie.
                    </>
                ),
            },
            {
                title: "Statistiques d'appel",
                content: (
                    <>
                        La section <strong>Rapports</strong> vous offre maintenant la possibilité
                        voir de nouvelles statistiques sur votre système téléphonique, incluant un
                        tableau horaire qui indique le temps passé au téléphone par chaque
                        utilisateur.
                    </>
                ),
                has: {
                    aPolicyMatching: [{resourceType: 'QUEUE_EVENTS'}],
                },
            },
            {
                title: 'Facturation',
                content: (
                    <>
                        Dans la section <string>Paramètres</string>, vous pouvez maintenant accéder
                        à vos information de facturation, telles que votre adresse et votre carte
                        bancaire. <AdminOnlyBadge />
                    </>
                ),
                has: {
                    theRole: 'administrator',
                },
            },
            {
                title: 'SMS/MMS par courriel',
                content: (
                    <>
                        Vous pouvez maintenant recevoir et envoyer des SMS/MMS par courriel.
                        <Has theRole={'administrator'}>
                            Dirigez-vous dans la section <strong>Paramètres</strong> afin d'associer
                            l'un de vos numéros de téléphone avec un utilisateur.
                        </Has>
                    </>
                ),
            },
        ],
    },
    {
        date: '2021-07-26',
        changelog: [
            {
                title: 'Filtres avancés',
                content: (
                    <>
                        Dans la section <strong>Historique des appels</strong>, vous pouvez
                        maintenant filtrer par plusieurs filtres de la même catégorie. Par exemple,
                        vous pouvez ajouter plusieurs filtres de type extension pour voir les appels
                        effectués par n'importe laquelle des extensions sélectionnées. Lorsque vous
                        ajoutez plusieurs filtres de type étiquette, vous pouvez appuyer sur l'icône
                        Lier/Délier (
                        <FontAwesomeIcon
                            icon={faLink}
                            className={'text-primary'}
                            fixedWidth
                            transform={{rotate: 45}}
                        />
                        /
                        <FontAwesomeIcon
                            icon={faUnlink}
                            className={'text-primary'}
                            fixedWidth
                            transform={{rotate: 45}}
                        />
                        ) pour basculer le filtre entre le mode conjoint (l'appel doit contenir
                        toutes les étiquettes) et le mode disjoint (l'appel doit contenir au moins
                        une des étiquettes)
                    </>
                ),
            },
            {
                title: 'Horodateur',
                content: (
                    <>
                        Si vous utiliser les fonctionalités de connexion/déconnexion ou de pause
                        dans les files d'attente de votre système téléphonique, vous pouvez
                        maintenant voir un historique du temps passé par vos utilisateurs dans les
                        files d'attente en vous dirigeant vers la section <strong>Rapports</strong>{' '}
                        (
                        <FontAwesomeIcon icon={faChartPie} className={'text-primary'} fixedWidth />)
                        de la barre latérale et en sélectionnant <strong>Horodateur</strong>.
                    </>
                ),
                has: {
                    aPolicyMatching: [{resourceType: 'QUEUE_EVENTS'}],
                },
            },
            {
                title: 'Intégration Zoho PhoneBridge',
                content: (
                    <>
                        Si vous utilisez les intégrations Zoho CRM ou Zoho Desk, dirigez vous vers
                        <strong>le panneau d'administration</strong> pour migrer vers Zoho
                        PhoneBridge.
                    </>
                ),
            },
            {
                title: 'Général',
                content: 'Résolution de bogues et améliorations à la performance du portail',
            },
        ],
    },
    {
        date: '2021-03-15',
        changelog: [
            {
                title: 'Équipes et règles de sécurité',
                content: (
                    <>
                        Vous pouvez maintenant créer des équipes dans{' '}
                        <strong> le panneau d'administration</strong>. De plus, vous pouvez
                        maitenant créer des règles de sécurité et les assigner à certains
                        utilisateurs ou à certaines équipes. Les règles de sécurité vous permettent
                        de régler si vos utilisateurs peuvent voir certaines resources dans le
                        portail, comme les appels, les statistiques de files d'attente, le moniteur
                        d'activité, les utilisateurs, les équipes, les intégrations, etc.
                        <AdminOnlyBadge />
                    </>
                ),
                has: {theRole: 'administrator'},
            },
            {
                title: 'General',
                content: 'Résolution de bogues',
            },
        ],
    },
    {
        date: '2020-08-28',
        changelog: [
            {
                title: "'Mes appels' devient 'Historique des appels'",
                content: (
                    <>
                        Vous pouvez maintenant accéder à vos appels avec l'icône
                        <strong> Historique des appels</strong> (
                        <FontAwesomeIcon icon={faHistory} className={'text-primary'} fixedWidth />)
                        dans la barre latérale.{' '}
                        <Has theRole={'administrator'}>
                            Pour les administrateurs, vous pouvez passer rapidement de l'historique
                            de vos appels vers l'historique de tout votre système téléphonique avec
                            la touche à bascule
                            <span className={'card px-2 py-1 d-inline'}>
                                <CheckboxInput
                                    inline
                                    value={true}
                                    onValueChange={() => {}}
                                    label={
                                        <FormattedMessage
                                            id={'toggle.show.only.my.calls'}
                                            defaultMessage={'Show only my calls'}
                                        />
                                    }
                                />
                            </span>
                            .
                        </Has>
                    </>
                ),
            },
            {
                title: 'Tableau de bord',
                content: (
                    <>
                        Si vous êtes un administrateur, votre page d'accueil a été remplacée par le
                        <strong> Tableau de bord</strong>. Vous pouvez maintenant créer des graphes
                        et des tables afin d'obtenir une vue compréhensive de votre système
                        téléphonique. Obtenez de l'information sur l'évolution de vos appels et de
                        vos utilisateurs au fil du temps, et mettez en place des objectifs pour
                        votre équipe.
                        <AdminOnlyBadge />
                    </>
                ),
                has: {
                    theRole: 'administrator',
                },
            },
            {
                title: "Moniteur d'activité",
                content: (
                    <>
                        Si vous êtes un administrateur, vous pouvez maintenant voir une ligne du
                        temps de l'activité de vos utilisateurs! Le{' '}
                        <strong>Moniteur d'activité</strong> vous permet d'avoir une vue d'ensemble
                        des actions entreprises par vos utilisateurs, et d'avoir un aperçu de leur
                        horaire. Vous pouvez accéder à ce module avec l'îcone
                        <strong> Moniteur d'activité</strong> (
                        <FontAwesomeIcon icon={faHiking} className={'text-primary'} fixedWidth />
                        ) dans la barre latérale.
                        <AdminOnlyBadge />
                    </>
                ),
                has: {
                    theRole: 'administrator',
                },
            },
            {
                title: 'Général',
                content: 'Résolution de bogues et améliorations à la performance du portail',
            },
        ],
    },
];

export default whats_new_fr;
