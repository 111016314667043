import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'reactstrap';
import {
    faCheckCircle,
    faExclamationTriangle,
    faInfoCircle,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EntranceExitAnimation from '../animation/EntranceExitAnimation';
import {removePortalToast} from '../../store/actions/portal-action';
import IdleAnimation from '../animation/IdleAnimation';
import {getPortalToasts} from '../../store/selectors/portal-selectors';
import Spinner from '../spinner/Spinner';

const TOAST_ICONS = {
    primary: (
        <IdleAnimation animation={'heartBeat'} speed={'fast'}>
            <FontAwesomeIcon icon={faCheckCircle} />
        </IdleAnimation>
    ),
    secondary: (
        <IdleAnimation animation={'heartBeat'} speed={'fast'}>
            <FontAwesomeIcon icon={faInfoCircle} />
        </IdleAnimation>
    ),
    warning: (
        <IdleAnimation animation={'heartBeat'} speed={'fast'}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
        </IdleAnimation>
    ),
    danger: (
        <IdleAnimation animation={'heartBeat'} speed={'fast'}>
            <FontAwesomeIcon icon={faTimesCircle} />
        </IdleAnimation>
    ),
};

const TOAST_LOADERS = {
    primary: <Spinner color={'white'} background={'transparent'} global={false} size={20} />,
    secondary: <Spinner color={'default'} background={'transparent'} global={false} size={20} />,
    warning: <Spinner color={'white'} background={'transparent'} global={false} size={20} />,
    danger: <Spinner color={'white'} background={'transparent'} global={false} size={20} />,
};

const Toast = ({id, loading, content, type}) => {
    const reduxDispatch = useDispatch();

    const [show, setShow] = useState(true);

    useEffect(() => {
        const showTimer = setTimeout(() => setShow(false), 5000);
        const removeTimer = setTimeout(() => reduxDispatch(removePortalToast(id)), 7000);
        return () => {
            clearTimeout(showTimer);
            clearTimeout(removeTimer);
        };
    }, [id, reduxDispatch]);

    return (
        <EntranceExitAnimation entrance={'scale-in-ver-top'} exit={'scale-out-ver-top'} show={show}>
            <Button
                className={'d-flex flex-row cursor-default'}
                color={type}
                style={{zIndex: 100000}}
            >
                {
                    <span className='ml--2 mr-2'>
                        {loading ? TOAST_LOADERS[type] : TOAST_ICONS[type]}
                    </span>
                }
                <span>{content}</span>
            </Button>
        </EntranceExitAnimation>
    );
};

export const Toasts = () => {
    const toasts = useSelector(getPortalToasts);

    return (
        <div
            style={{zIndex: '999999'}}
            className={
                'center position-absolute top-2 d-flex flex-column align-items-center justify-content-around'
            }
        >
            {toasts &&
                toasts.map((toast) => (
                    <div
                        className='d-flex flex-row justify-content-start align-items-center justify-content-between mb-2'
                        key={toast.id}
                    >
                        {toast && <Toast {...toast} />}
                    </div>
                ))}
        </div>
    );
};
