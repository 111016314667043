import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Button, FormFeedback, FormGroup, Input} from 'reactstrap';
import PasswordMeter from './PasswordMeter';

const PasswordSetter = ({
    className,
    onCancel,
    onSubmit,
    onValidityChange,
    onValueChange,
    requireCurrent,
    showFormLabels,
    size,
}) => {
    const [valid, setValid] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    useEffect(() => {
        setPasswordsMatch(newPassword === passwordConfirmation);
    }, [newPassword, passwordConfirmation]);

    const s = (e) => {
        e.preventDefault();
        onSubmit(currentPassword, newPassword);
    };

    useEffect(() => {
        onValidityChange(passwordsMatch && valid);
    }, [valid, passwordsMatch, onValidityChange]);

    useEffect(() => {
        onValueChange(newPassword);
    }, [onValueChange, newPassword]);

    return (
        <div className={className}>
            {requireCurrent && (
                <FormGroup>
                    {showFormLabels && (
                        <FormattedMessage
                            id='input.placeholder.profile.current_password'
                            defaultMessage='Current Password'
                        >
                            {(m) => <label className='form-control-label'>{m}</label>}
                        </FormattedMessage>
                    )}
                    <FormattedMessage
                        id='input.placeholder.profile.current_password'
                        defaultMessage='Current Password'
                    >
                        {(m) => (
                            <Input
                                placeholder={m}
                                name='current'
                                type='password'
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                                size={size}
                            />
                        )}
                    </FormattedMessage>
                </FormGroup>
            )}
            <FormGroup>
                {showFormLabels && (
                    <FormattedMessage
                        id='input.placeholder.profile.new_password'
                        defaultMessage='New password'
                    >
                        {(m) => <label className='form-control-label'>{m}</label>}
                    </FormattedMessage>
                )}
                <FormattedMessage
                    id='input.placeholder.profile.new_password'
                    defaultMessage='New password'
                >
                    {(m) => (
                        <Input
                            placeholder={m}
                            name='password'
                            type='password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            size={size}
                        />
                    )}
                </FormattedMessage>
            </FormGroup>
            <FormGroup>
                {showFormLabels && (
                    <FormattedMessage
                        id='input.placeholder.password_confirm'
                        defaultMessage='Password Confirmation'
                    >
                        {(m) => <label className='form-control-label'>{m}</label>}
                    </FormattedMessage>
                )}
                <FormattedMessage
                    id='input.placeholder.profile.password_confirm'
                    defaultMessage='Confirm your new password'
                >
                    {(m) => (
                        <Input
                            placeholder={m}
                            name='confirmation'
                            type='password'
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            invalid={!passwordsMatch}
                            required
                            size={size}
                        />
                    )}
                </FormattedMessage>
                <FormattedMessage
                    id={'error.password.does_not_match'}
                    defaultMessage={'New password and confirmation do not match'}
                >
                    {(m) => <FormFeedback>{m}</FormFeedback>}
                </FormattedMessage>
            </FormGroup>
            <PasswordMeter
                className={'mt-2'}
                password={newPassword}
                onValidityChange={(v) => setValid(v)}
            />
            <div className='d-flex flex-row justify-content-between'>
                {onCancel && (
                    <FormattedMessage id='profile.form.btn.cancel' defaultMessage='Cancel'>
                        {(m) => (
                            <Button onClick={onCancel} size={size} color='secondary'>
                                {m}
                            </Button>
                        )}
                    </FormattedMessage>
                )}
                {onSubmit && (
                    <FormattedMessage
                        id='profile.form.btn.change_password'
                        defaultMessage='Change Password'
                    >
                        {(m) => (
                            <Button
                                onClick={s}
                                color='primary'
                                size={size}
                                disabled={!(passwordsMatch && valid)}
                            >
                                {m}
                            </Button>
                        )}
                    </FormattedMessage>
                )}
            </div>
        </div>
    );
};

PasswordSetter.propTypes = {
    className: PropTypes.string,
    requireCurrent: PropTypes.bool,
    ownForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValueChange: PropTypes.func,
    showFormLabels: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md']),
};

PasswordSetter.defaultProps = {
    className: '',
    requireCurrent: false,
    onValidityChange: () => {},
    onValueChange: () => {},
    showFormLabels: false,
    size: 'sm',
};

export default PasswordSetter;
