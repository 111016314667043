import React from 'react';
import UserAvatar from '../user/UserAvatar';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';
import {getCustomerUsers} from '../../store/selectors/customer-selectors';
import CallsSummary from './CallsSummary';
import TagsSummary from './TagsSummary';
import JourneyLifeline from '../../views/modules/Journeys/JourneyLifeline';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

const JourneyCard = ({journey, onExpand, startTime, endTime}) => {
    const users = useSelector(getCustomerUsers);
    const user = users.find((u) => u.id === journey.ids.user_id);

    return (
        <Card className={'mb-2 with-shadow'}>
            <CardBody className={'d-flex flex-row'}>
                <div className={'d-flex flex-column align-items-start flex-grow-1'}>
                    <div className={'d-flex flex-row align-items-baseline mb-2'}>
                        {user && <UserAvatar className={'mr-2'} user={user} size={'xs'} />}
                        <h4 className={'mr-5'}>{user && user.full_name}</h4>
                    </div>
                    <Row className={'d-flex flex-row w-100 mb-2'}>
                        <Col xs={4} className={'d-flex flex-column align-items-start'}>
                            <span className={'text-small-uppercase'}>
                                <FormattedMessage id={'label.calls'} defaultMessage={'Calls'} />
                            </span>
                            {journey.ids.extension ? (
                                <CallsSummary
                                    id={journey.ids.user_id}
                                    className={'mr-5'}
                                    summary={journey.calls_summary}
                                />
                            ) : (
                                <small className={'text-muted'}>
                                    <FormattedMessage
                                        id={'label.user.has.no.extension'}
                                        defaultMessage={'This user has no extension'}
                                    />
                                </small>
                            )}
                        </Col>
                        <Col xs={8} className={'d-flex flex-column align-items-start'}>
                            <span className={'text-small-uppercase'}>
                                <FormattedMessage id={'label.tags'} defaultMessage={'Tags'} />
                            </span>
                            {journey.ids.extension ? (
                                <TagsSummary summary={journey.tags_summary} />
                            ) : (
                                <small className={'text-muted'}>
                                    <FormattedMessage
                                        id={'label.user.has.no.extension'}
                                        defaultMessage={'This user has no extension'}
                                    />
                                </small>
                            )}
                        </Col>
                    </Row>
                    <Row className={'d-flex flex-column flex-grow-1 w-100 justify-content-stretch'}>
                        <Col xs={12} className={'d-flex flex-column align-items-start'}>
                            <span className={'text-small-uppercase'}>
                                <FormattedMessage
                                    id={'label.lifeline'}
                                    defaultMessage={'This user has no extension'}
                                />
                            </span>
                            {journey.activities.length === 0 && (
                                <small className={'text-muted'}>
                                    <FormattedMessage
                                        id={'label.no.activity'}
                                        defaultMessage={'No Activity'}
                                    />
                                </small>
                            )}
                            {journey.activities.length > 2 ? (
                                <>
                                    <div
                                        className={
                                            'mt-1 d-flex flex-row flex-grow-1 w-100 align-items-center'
                                        }
                                    >
                                        <JourneyLifeline
                                            startTime={startTime}
                                            endTime={endTime}
                                            activities={journey.activities}
                                        />
                                    </div>
                                </>
                            ) : (
                                journey.activities.length > 0 && (
                                    <small className={'text-muted'}>
                                        <FormattedMessage
                                            id={'label.not.enough.activity'}
                                            defaultMessage={
                                                'Not enough activity to generate lifeline'
                                            }
                                        />
                                    </small>
                                )
                            )}
                        </Col>
                    </Row>
                </div>
                <div className={'d-flex flex-row align-items-center'}>
                    <FontAwesomeIcon
                        className={'text-primary cursor-pointer'}
                        icon={faChevronRight}
                        onClick={() => {
                            onExpand(journey);
                        }}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

JourneyCard.propTypes = {};

export default JourneyCard;
