import React from 'react';
import {
    ConnectedIcon,
    InboundIcon,
    InternalIcon,
    MissedIcon,
    OutboundIcon,
    VoicemailIcon,
} from '../call/icons/CallIcons';
import {FormattedMessage} from 'react-intl';

const STATUSES = ['hanged', 'missed', 'voicemail'];
const DIRECTIONS = ['in', 'out', 'internal'];

const STATUS_ICONS = {
    hanged: (id) => <ConnectedIcon id={id} />,
    missed: (id) => <MissedIcon id={id} />,
    voicemail: (id) => <VoicemailIcon id={id} />,
};

const DIRECTION_ICONS = {
    in: (id) => <InboundIcon id={id} />,
    out: (id) => <OutboundIcon id={id} />,
    internal: (id) => <InternalIcon id={id} />,
};

const CallsSummary = ({id, className, summary}) => {
    return Object.values(summary).every((x) => x.total === 0) ? (
        <small className={'text-muted'}>
            <FormattedMessage id={'label.no.calls.to.show'} defaultMessage={'No calls to show'} />
        </small>
    ) : (
        <div className={`d-flex flex-column ${className}`}>
            <span>
                {STATUSES.map((s, index) => (
                    <React.Fragment key={index}>
                        <span className={`mr-1 ${index !== 0 && 'ml-3'}`}>
                            {STATUS_ICONS[s](`call-summary-icon-${id}-${s}`)}
                        </span>
                        <span className={`mr-2`}>{summary[s] ? summary[s].total : 0}</span>
                        {s === 'hanged' &&
                            DIRECTIONS.map((d, index) => (
                                <React.Fragment key={index}>
                                    <span className={'mr-1'}>
                                        {DIRECTION_ICONS[d](`call-summary-icon-${id}-${d}`)}
                                    </span>
                                    <span className={'mr-2'}>
                                        {(summary[s] && summary[s][d]) || 0}
                                    </span>
                                </React.Fragment>
                            ))}
                    </React.Fragment>
                ))}
            </span>
        </div>
    );
};

CallsSummary.propTypes = {};

export default CallsSummary;
