import React from 'react';
import PropTypes from 'prop-types';
import {ConnectedIcon, MissedIcon, NotConnectedIcon, VoicemailIcon} from '../call/icons/CallIcons';
import LightFontAwesomeIcon from '../utils/LightFontAwesomeIcon';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {getUserLocale} from '../../store/selectors/user-selectors';
import {defineMessages, FormattedMessage} from 'react-intl';
import {formatAmericanNumber} from '../../utils/format';
import {CallHistoryActionsCell, TagsCell} from '../call/CallsTableCells.jsx';
import {formatCall} from '../../store/selectors/cdr-selectors';
import {Alert} from 'reactstrap';

export const PhoneCallActivity = ({call}) => {
    return (
        <div className={'d-flex flex-column w-100'}>
            <div className={'d-flex flex-row w-100 justify-content-between'}>
                <small className={'d-flex flex-row flex-grow-1'}>
                    {call.to ? (
                        call.from ? (
                            <FormattedMessage
                                id={'message.activity.from_number_to_number'}
                                defaultMessage={'Call made from {from} to {to}'}
                                values={{
                                    from: formatAmericanNumber(call.from),
                                    to: formatAmericanNumber(call.to),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id={'message.activity.to_number'}
                                defaultMessage={'Call made to {to}'}
                                values={{
                                    to: formatAmericanNumber(call.to),
                                }}
                            />
                        )
                    ) : call.from ? (
                        <FormattedMessage
                            id={'message.activity.from_number'}
                            defaultMessage={'Call made from {from}'}
                            values={{
                                from: formatAmericanNumber(call.from),
                            }}
                        />
                    ) : null}
                </small>
                <CallHistoryActionsCell call={call} />
            </div>
            <TagsCell call={call} />
        </div>
    );
};

PhoneCallActivity.propTypes = {};

// noinspection JSUnusedGlobalSymbols
export const ACTIVITY_ICONS = {
    phone_call: (id) => <ConnectedIcon className={'p-1'} id={id} />,
    missed_phone_call: (id) => <MissedIcon className={'p-1'} id={id} />,
    voicemail: (id) => <VoicemailIcon className={'p-1'} id={id} />,
    not_connected_phone_call: (id) => <NotConnectedIcon className={'p-1'} id={id} />,
};

// noinspection JSUnusedGlobalSymbols
const ACTIVITY_COMPONENTS = {
    phone_call: (activity) => <PhoneCallActivity call={formatCall(activity.associated_call)} />,
    missed_phone_call: (activity) => (
        <PhoneCallActivity call={formatCall(activity.associated_call)} />
    ),
    voicemail: (activity) => <PhoneCallActivity call={formatCall(activity.associated_call)} />,
    not_connected_phone_call: (activity) => (
        <PhoneCallActivity call={formatCall(activity.associated_call)} />
    ),
};

export const ACTIVITY_MESSAGES = defineMessages({
    phone_call_start: {
        id: 'message.activity.phone_call.start',
        defaultMessage: 'Started a phone call',
    },
    phone_call_end: {
        id: 'message.activity.phone_call.end',
        defaultMessage: 'Ended a phone call',
    },
    missed_phone_call: {
        id: 'message.activity.missed_phone_call',
        defaultMessage: 'Missed  a phone call',
    },
    voicemail: {
        id: 'message.activity.voicemail',
        defaultMessage: 'Received a voicemail',
    },
    not_connected_phone_call: {
        id: 'message.activity.not_connected',
        defaultMessage: 'Made an outbound call that went unanswered',
    },
});

const UnknownEvent = (
    <span className={'p-1'}>
        <LightFontAwesomeIcon icon={'question'} className={'text-primary'} fixedWidth />
    </span>
);

const ActivityTimelineElement = ({activity, last}) => {
    const locale = useSelector(getUserLocale);

    const titleMessage =
        activity.action.status === 'start' || activity.action.status === 'end'
            ? ACTIVITY_MESSAGES[`${activity.action.type}_${activity.action.status}`]
            : ACTIVITY_MESSAGES[activity.action.type];

    return (
        <div className={'d-flex flex-row w-100'} style={{minHeight: 150}}>
            <div className={'d-flex flex-column align-items-center mr-3'}>
                <div
                    className={
                        'd-flex flex-row align-items-center justify-content-around rounded-circle border border-primary'
                    }
                    style={{width: 34}}
                >
                    {Object.keys(ACTIVITY_ICONS).includes(activity.action.type)
                        ? ACTIVITY_ICONS[activity.action.type](
                              `journeys-call-icon-${activity.action.id}`
                          )
                        : UnknownEvent}
                </div>
                {!last && (
                    <div style={{width: 1}} className={'flex-grow-1 border-left border-primary'} />
                )}
            </div>
            <div className={'d-flex flex-column mr-5 pt-1'}>
                <h3>
                    {moment(activity.action.time)
                        .locale(locale ? locale : 'en')
                        .format('hh:mm A')}
                </h3>
            </div>
            <div className={'d-flex flex-column flex-grow-1 pt-1'}>
                <h3>
                    <FormattedMessage {...titleMessage} />
                </h3>
                {ACTIVITY_COMPONENTS[activity.action.type](activity)}
            </div>
        </div>
    );
};

ActivityTimelineElement.propTypes = {
    activity: PropTypes.object.isRequired,
    last: PropTypes.bool,
};

ActivityTimelineElement.defaultProps = {
    last: false,
};

const ActivityTimeline = ({activities}) => {
    return activities.length > 0 ? (
        <div className={'w-100 mt-5'}>
            {activities.map((activity, index) => (
                <ActivityTimelineElement
                    key={index}
                    activity={activity}
                    last={index + 1 === activities.length}
                />
            ))}
        </div>
    ) : (
        <div className={'w-100'}>
            <Alert color={'secondary'} className={'m-2 text-center'}>
                <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
            </Alert>
        </div>
    );
};

ActivityTimeline.propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object),
};

ActivityTimeline.defaultProps = {
    activities: [],
};

export default ActivityTimeline;
