import React from 'react';
import LightFontAwesomeIcon from '../../utils/LightFontAwesomeIcon';
import {FormattedMessage} from 'react-intl';
import * as PropTypes from 'prop-types';
import {formatAlphanumericString} from '../../../utils/format';
import {UncontrolledTooltip} from 'reactstrap';

export const CallIcon = ({id, icon, color, message, size, className, tooltip}) => {
    return (
        <>
            {tooltip && (
                <UncontrolledTooltip target={formatAlphanumericString(id)} placement={'right'}>
                    {message}
                </UncontrolledTooltip>
            )}
            <span id={formatAlphanumericString(id)} className={className}>
                <LightFontAwesomeIcon
                    icon={icon}
                    className={`text-${color}`}
                    size={size}
                    fixedWidth
                />
            </span>
        </>
    );
};

CallIcon.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    message: PropTypes.node.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
    className: PropTypes.string,
    tooltip: PropTypes.bool,
};

CallIcon.defaultProps = {
    color: 'default',
    size: LightFontAwesomeIcon.defaultProps.size,
    className: '',
    tooltip: false,
};

export const ConnectedIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'success'}
            icon={'phone'}
            id={id}
            message={<FormattedMessage id={'message.status.hanged'} defaultMessage={'Connected'} />}
            size={size}
        />
    );
};

ConnectedIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};

export const MissedIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'danger'}
            icon={'phone-slash'}
            id={id}
            message={<FormattedMessage id={'message.status.missed'} defaultMessage={'Missed'} />}
            size={size}
        />
    );
};

MissedIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};

export const VoicemailIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'info'}
            icon={'voicemail'}
            id={id}
            message={
                <FormattedMessage id={'message.status.voicemail'} defaultMessage={'Voicemail'} />
            }
            size={size}
        />
    );
};

VoicemailIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};

export const NotConnectedIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'default'}
            icon={'phone-slash'}
            id={id}
            message={
                <FormattedMessage
                    id={'message.status.not_connected'}
                    defaultMessage={'No Answer'}
                />
            }
            size={size}
        />
    );
};

VoicemailIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};

export const InboundIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'default'}
            icon={'arrow-down'}
            id={id}
            message={<FormattedMessage id={'message.direction.in'} defaultMessage={'Inbound'} />}
            size={size}
        />
    );
};

InboundIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};

export const OutboundIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'default'}
            icon={'arrow-up'}
            id={id}
            message={<FormattedMessage id={'message.direction.out'} defaultMessage={'Outbound'} />}
            size={size}
        />
    );
};

OutboundIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};

export const InternalIcon = ({id, size, className}) => {
    return (
        <CallIcon
            className={className}
            color={'default'}
            icon={'redo'}
            id={id}
            message={
                <FormattedMessage id={'message.direction.internal'} defaultMessage={'Internal'} />
            }
            size={size}
        />
    );
};

InternalIcon.propTypes = {
    id: PropTypes.string.isRequired,
    size: LightFontAwesomeIcon.propTypes.size,
};
