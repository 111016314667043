import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    FETCH_CALL_HISTORY_FAILED,
    FETCH_NEW_CALL_HISTORY_FAILED,
    FETCH_CALL_HISTORY_SAGA,
    FETCH_NEW_CALL_HISTORY_SAGA,
    FETCH_CALL_HISTORY_SUCCESS,
    FETCH_NEW_CALL_HISTORY_SUCCESS,
    FETCH_CALL_RECORDING_FAILED,
    FETCH_NEW_CALL_RECORDING_FAILED,
    FETCH_CALL_RECORDING_LINK_SAGA,
    FETCH_NEW_CALL_RECORDING_LINK_SAGA,
    FETCH_CALL_RECORDING_SUCCESS,
    FETCH_NEW_CALL_RECORDING_SUCCESS,
    FETCH_LIVE_CALLS_FAILED,
    FETCH_LIVE_CALLS_SAGA,
    FETCH_LIVE_CALLS_SUCCESS,
    FETCH_WIDGET_DATA_FAILED,
    FETCH_WIDGET_DATA_SAGA,
    FETCH_WIDGET_DATA_SUCCESS,
    FETCHED_CALLS,
    FETCHED_NEW_CALLS,
    FETCHING_CALLS,
    FETCHING_NEW_CALLS,
    STORE_CALL_DIRECTIONS,
    STORE_CALL_HISTORY,
    STORE_NEW_CALL_HISTORY,
    STORE_CALL_RECORDING,
    STORE_NEW_CALL_RECORDING,
    STORE_CALL_STATISTICS,
    STORE_CALL_STATUSES,
    STORE_NEW_CALL_STATUSES,
    STORE_CALL_TAGS,
    STORE_LIVE_CALLS,
    STORE_UNFILTERED_CALL_HISTORY,
    STORE_UNFILTERED_NEW_CALL_HISTORY,
    STORE_CONTINUATION_TOKEN,
    STORE_WIDGET_DATA,
    FETCH_CALL_PARTICIPANTS,
    FETCH_NEW_CALL_PARTICIPANTS,
    STORE_CALL_PARTICIPANTS,
    FETCH_WIDGET_DATA_ONCE_SAGA,
    STORE_WIDGET_HITS,
} from '../actions/cdr-action';
import {LOADED, LOADING, STORE_DANGER_TOAST} from '../actions/portal-action';
import {getUserProfileConfirm} from '../selectors/user-selectors';
import {APIRequestGenerator} from './index';

export function* fetchCalls(action) {
    try {
        yield put({type: FETCHING_CALLS});

        const [l, s] = yield all([
            call(APIRequestGenerator, 'post', '/calls', {
                params: action.payload.params.list,
            }),
            call(APIRequestGenerator, 'post', '/calls/statistics', {
                params: action.payload.params.statistics,
            }),
        ]);
        yield put({
            type: STORE_CALL_HISTORY,
            payload: {
                data: [...l.data.hits.hits.map((h) => h._source)],
                behaviour: action.payload.behaviour,
            },
        });
        yield put({
            type: STORE_UNFILTERED_CALL_HISTORY,
            payload: {
                data: [...l.data.hits.hits.map((h) => h._source)],
                behaviour: action.payload.behaviour,
            },
        });
        yield put({
            type: STORE_CALL_TAGS,
            payload: {
                data: s.data.aggregations.tags.buckets.map((b) => b.key),
            },
        });
        yield put({
            type: STORE_CALL_STATUSES,
            payload: {
                data: s.data.aggregations.statuses.buckets.map((b) => b.key),
            },
        });
        yield put({
            type: STORE_CALL_DIRECTIONS,
            payload: {
                data: s.data.aggregations.directions.buckets.map((b) => b.key),
            },
        });
        yield put({
            type: STORE_CALL_STATISTICS,
            payload: {
                data: {
                    status_count: s.data.aggregations.statuses.buckets
                        // We filter the connected to not display them for the moment

                        .filter((bucket) => bucket.key !== 'connected')
                        .reduce((result, bucket) => {
                            result[bucket.key] = bucket.doc_count;
                            return result;
                        }, {}),
                    direction_count: s.data.aggregations.directions.buckets.reduce(
                        (result, bucket) => {
                            result[bucket.key] = bucket.doc_count;
                            return result;
                        },
                        {},
                    ),
                },
            },
        });
        yield put({type: FETCH_CALL_HISTORY_SUCCESS});
    } catch (e) {
        yield put({
            type: FETCH_CALL_HISTORY_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: FETCHED_CALLS});
    }
}

//New Call History
export function* fetchNewCalls(action) {
    try {
        yield put({type: FETCHING_NEW_CALLS});
        const [
            l,
            // s
        ] = yield all([
            call(APIRequestGenerator, 'newPost', '/calls', {
                params: action.payload.params.list,
                version: 'v3',
            }),
        ]);
        yield put({
            type: STORE_NEW_CALL_HISTORY,
            payload: {
                data: [...l.result.calls.map((h) => h)],
                behaviour: action.payload.behaviour,
            },
        });
        yield put({
            type: STORE_UNFILTERED_NEW_CALL_HISTORY,
            payload: {
                data: [...l.result.calls.map((h) => h)],
                behaviour: action.payload.behaviour,
            },
        });
        yield put({
            type: STORE_CONTINUATION_TOKEN,
            payload: {
                data: [...(l.result.continuationToken || '')],
                behaviour: action.payload.behaviour,
            },
        });
        yield put({
            type: STORE_NEW_CALL_STATUSES,
            payload: {
                data: l.result.status.map((s) => s),
            },
        });
        yield put({type: FETCH_NEW_CALL_HISTORY_SUCCESS});
    } catch (e) {
        yield put({
            type: FETCH_NEW_CALL_HISTORY_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: FETCHED_NEW_CALLS});
    }
}

export function* fetchLiveCallsSaga(action) {
    try {
        let {data} = yield call(APIRequestGenerator, 'post', '/calls/history', {
            params: action.payload.params,
        });
        const live_events = data.hits.hits
            .filter((hit) => hit._source.action === 'connected')
            .filter((hit) => hit.inner_hits.event_chain.hits.hits[0]._source.action === 'connected')
            .map((hit) => hit._source);
        yield put({
            type: STORE_LIVE_CALLS,
            payload: {
                data: live_events,
                behaviour: action.payload.behaviour,
            },
        });
        yield put({type: FETCH_LIVE_CALLS_SUCCESS});
    } catch (e) {
        yield put({
            type: FETCH_LIVE_CALLS_FAILED,
            payload: e.message,
        });
        return e;
    }
}

export function* fetchCallRecordingLinkSaga(action) {
    try {
        yield put({type: LOADING, payload: `recording_${action.payload.id}`});
        let response = yield call(
            APIRequestGenerator,
            'get',
            `/calls/${action.payload.id}/recordings`,
            {params: {save_as: action.payload.saveAs}},
        );
        const url = response.data.link;
        yield put({type: STORE_CALL_RECORDING, payload: {url: url, id: action.payload}});
        if (action.payload.callback) {
            yield call(action.payload.callback, url);
        }
    } catch (e) {
        yield put({
            type: STORE_DANGER_TOAST,
            payload: {
                content: `Could not find a recording for call ${action.payload.id}`,
            },
        });
        yield put({
            type: FETCH_CALL_RECORDING_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: LOADED, payload: `recording_${action.payload.id}`});
        yield put({type: FETCH_CALL_RECORDING_SUCCESS});
    }
}
// new call Recording
export function* fetchNewCallRecordingLinkSaga(action) {
    try {
        yield put({type: LOADING, payload: `recording_${action.payload.term_callid}`});
        var params = {
            orig_callid: action.payload.orig_callid,
            term_callid: action.payload.term_callid,
            domain: action.payload.domain,
        };

        let response = yield call(APIRequestGenerator, 'newPost', `/call-recording`, {
            params: params,
            version: 'v3',
        });
        const url = response.result[0].url;
        yield put({
            type: STORE_NEW_CALL_RECORDING,
            payload: {
                url: url,
                orig_callid: action.payload.orig_callid,
                term_callid: action.payload.term_callid,
                domain: action.payload.domain,
            },
        });
        if (action.payload.callback) {
            yield call(action.payload.callback, url);
        }
    } catch (e) {
        yield put({
            type: STORE_DANGER_TOAST,
            payload: {
                content: `Could not find a recording for call this record`, // ${action.payload.term_callid}`,
            },
        });
        yield put({
            type: FETCH_NEW_CALL_RECORDING_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: LOADED, payload: `recording_${action.payload.term_callid}`});
        yield put({type: FETCH_NEW_CALL_RECORDING_SUCCESS});
    }
}

export function* fetchWidgetData(action) {
    try {
        let confirmProfile = yield select(getUserProfileConfirm);
        if (confirmProfile) {
            yield put({type: LOADING, payload: `widget_${action.payload.id}`});
            // ES matches an elasticsearch request
            if (action.payload.type === 'ES') {
                let rawData = yield call(APIRequestGenerator, 'post', '/calls/statistics', {
                    params: action.payload.params.statistics,
                });
                yield put({
                    type: STORE_WIDGET_DATA,
                    payload: {
                        data: rawData.data.aggregations,
                        id: action.payload.id,
                    },
                });
                yield put({
                    type: STORE_WIDGET_HITS,
                    payload: {
                        data: rawData.data.hits,
                        id: action.payload.id,
                    },
                });
                if (rawData?.data?.aggregations?.tags?.buckets) {
                    yield put({
                        type: STORE_CALL_TAGS,
                        payload: {
                            data: rawData.data.aggregations.tags.buckets.map((b) => b.key),
                        },
                    });
                }
                if (rawData?.data?.aggregations?.statuses?.buckets) {
                    yield put({
                        type: STORE_CALL_STATUSES,
                        payload: {
                            data: rawData.data.aggregations.statuses.buckets.map((b) => b.key),
                        },
                    });
                }
                if (rawData?.data?.aggregations?.directions?.buckets) {
                    yield put({
                        type: STORE_CALL_DIRECTIONS,
                        payload: {
                            data: rawData.data.aggregations.directions.buckets.map((b) => b.key),
                        },
                    });
                }
            } else {
                let data = yield call(
                    APIRequestGenerator,
                    'get',
                    `/queues/stats/${action.payload.params.queue}`,
                    {...action.payload.params},
                    false,
                );
                yield put({
                    type: STORE_WIDGET_DATA,
                    payload: {
                        data,
                        id: action.payload.id,
                    },
                });
                yield put({type: FETCH_WIDGET_DATA_SUCCESS});
            }
            yield put({type: FETCH_WIDGET_DATA_SUCCESS});
        }
    } catch (e) {
        yield put({
            type: FETCH_WIDGET_DATA_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: LOADED, payload: `widget_${action.payload.id}`});
    }
}

export function* fetchCallParticipants(action) {
    try {
        yield put({type: LOADING, payload: `participants_${action.payload.callId}`});
        let {data} = yield call(
            APIRequestGenerator,
            'get',
            `/calls/${action.payload.callId}/participants`,
        );
        yield put({
            type: STORE_CALL_PARTICIPANTS,
            payload: {data: data.aggregations.call_events.hits.hits},
        });
    } catch (e) {
        yield put({
            type: FETCH_CALL_HISTORY_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: LOADED, payload: `participants_${action.payload.callId}`});
    }
}
export function* fetchNewCallParticipants(action) {
    try {
        yield put({type: LOADING, payload: `participants_${action.payload.callId}`});
        let {data} = yield call(
            APIRequestGenerator,
            'get',
            `/${action.payload.callId}/participants`,
        );
        yield put({
            type: STORE_CALL_PARTICIPANTS,
            payload: {data: data.aggregations.call_events.hits.hits},
        });
    } catch (e) {
        yield put({
            type: FETCH_NEW_CALL_HISTORY_FAILED,
            payload: e.message,
        });
        return e;
    } finally {
        yield put({type: LOADED, payload: `participants_${action.payload.callId}`});
    }
}

const cdrSagas = [
    takeLatest(FETCH_CALL_HISTORY_SAGA, fetchCalls),
    takeLatest(FETCH_NEW_CALL_HISTORY_SAGA, fetchNewCalls),
    takeEvery(FETCH_CALL_RECORDING_LINK_SAGA, fetchCallRecordingLinkSaga),
    takeEvery(FETCH_NEW_CALL_RECORDING_LINK_SAGA, fetchNewCallRecordingLinkSaga),
    takeEvery(FETCH_LIVE_CALLS_SAGA, fetchLiveCallsSaga),
    takeEvery(FETCH_WIDGET_DATA_SAGA, fetchWidgetData),
    takeLatest(FETCH_WIDGET_DATA_ONCE_SAGA, fetchWidgetData),
    takeEvery(FETCH_CALL_PARTICIPANTS, fetchCallParticipants),
    takeEvery(FETCH_NEW_CALL_PARTICIPANTS, fetchNewCallParticipants),
];

export default cdrSagas;
