import React, {useEffect, useReducer, useState} from 'react';
import SidePanelHeader from '../../../../components/panel/SidePanelHeader';
import SidePanelContent from '../../../../components/panel/SidePanelContent';
import {formatShortAmericanNumber} from '../../../../utils/format';
import {useDispatch, useSelector} from 'react-redux';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import {
    customerSmsBindingsSelector,
    incomingExtensionSelector,
} from '../../../../store/selectors/customer-selectors';
import isEqual from 'lodash.isequal';
import {useListReducer} from '../../../../utils/hooks';
import Spinner from '../../../../components/spinner/Spinner';
import SmsBindings from './SmsBindings';
import FormSection from '../../../../components/form/FormSection';
import {faCog, faSms, faUsers, faSquarePhone} from '@fortawesome/free-solid-svg-icons';
import {Alert, Button, Card, CardBody, CardText, Form, FormGroup, Nav, Row} from 'reactstrap';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import DangerModal from '../../../../components/notification/DangerModal';
import {MultiActions} from '../../../../utils/requests';
import {
    createCustomerSmsBinding,
    createCustomerSmsConfig,
    deleteCustomerSmsBinding,
    deleteCustomerSmsConfig,
    fetchBandwidthApplication,
    updateCustomerSmsBinding,
    updateCustomerSmsConfig,
} from '../../../../store/actions/customer-action';
import SelectInput from '../../../../components/input/SelectInput';

const defaultOpenSections = {
    generalOpen: true,
    bindingsOpen: true,
    servicesOpen: true,
    advancedOpen: false,
    showDidValidation: false,
};

const defaultSmsBindingsState = [];

const SmsConfigForm = ({smsConfig, toggle, intl}) => {
    const bindingsActionFactory = new MultiActions((binding) => binding.user, {
        c: (binding) =>
            createCustomerSmsBinding({
                user_id: binding.user,
                sms_did: smsConfigState.did,
                outbound_chat: binding.inbound,
            }),
        u: (binding) =>
            updateCustomerSmsBinding({
                id: binding.id,
                user_id: binding.user,
                sms_did: smsConfigState.did,
                outbound_chat: binding.outbound_chat,
            }),
        d: (binding) =>
            deleteCustomerSmsBinding({
                id: binding.id,
                sms_did: smsConfigState.did,
            }),
    });

    const SERVICES_MESSAGES = defineMessages({
        CROO_TALK: {
            id: 'message.admin.sms.config.service.croo_talk',
            defaultMessage: 'SMS to email',
        },
        CROO_TALK_LITE: {
            id: 'message.admin.sms.config.service.croo_talk_lite',
            defaultMessage: 'Croo Talk Lite',
        },
    });

    const defaultSmsConfigState = {
        did: '',
        enabled: true,
        service_type: 'CROO_TALK',
    };

    const smsServicesSelection = [
        {value: 'CROO_TALK', label: intl.formatMessage(SERVICES_MESSAGES.CROO_TALK)},
        {value: 'CROO_TALK_LITE', label: intl.formatMessage(SERVICES_MESSAGES.CROO_TALK_LITE)},
    ];

    // const from store
    const loadingBindings = useSelector(
        getLoading(`sms_bindings_${smsConfig ? smsConfig.did : ''}`, !!smsConfig)
    );
    const smsBindings = useSelector(customerSmsBindingsSelector(smsConfig ? smsConfig.did : null));
    const incomingList = useSelector(incomingExtensionSelector());
    // Local state
    const [deleteSmsConfigModal, setDeleteSmsConfigModal] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    // Reducers
    const [smsConfigState, smsConfigDispatch] = useReducer((prevSmsConfigState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultSmsConfigState;
            case 'edit':
                if (isEqual(prevSmsConfigState[action.payload.field], action.payload.value))
                    return prevSmsConfigState;
                const newState = {...prevSmsConfigState};
                newState[action.payload.field] = action.payload.value;
                return newState;
            default:
                return prevSmsConfigState;
        }
    }, smsConfig || defaultSmsConfigState);

    const [smsBindingsState, smsBindingsDispatch] = useListReducer(
        (binding) => binding.user,
        smsBindings || defaultSmsBindingsState
    );

    useEffect(() => {
        if (!loadingBindings && !!smsBindings) {
            smsBindingsDispatch({type: 'replace', payload: smsBindings});
        }
    }, [loadingBindings, smsBindingsDispatch, smsBindings]);

    // Form actions
    const reduxDispatch = useDispatch();
    const submit = (e) => {
        e.preventDefault();
        const binding_actions = bindingsActionFactory
            .fromDiff(smsBindings || [], smsBindingsState)
            .getActions();
        if (!!smsConfig) {
            reduxDispatch(
                updateCustomerSmsConfig({
                    did: smsConfigState.did,
                    service_type: smsConfigState.service_type,
                    binding_actions,
                })
            );
            toggle();
        } else {
            // no empty validation
            if (!smsConfigState.did) {
                setShowValidation(true);
            } else {
                reduxDispatch(fetchBandwidthApplication(smsConfigState.did));
                reduxDispatch(
                    createCustomerSmsConfig({
                        did: smsConfigState.did,
                        service_type: smsConfigState.service_type,
                        binding_actions,
                    })
                );
                toggle();
            }
        }
    };

    const editField = (name, value) => {
        smsConfigDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });
        // validation removal check
        if (name === 'did' && showValidation) {
            setShowValidation(!showValidation);
        }
        if (checkServiceTypeBindingsLimit(name, value)) {
            clearSmsBindings();
        }
    };

    const clearSmsBindings = () => {
        smsBindingsState.forEach((binding) => {
            smsBindingsDispatch({type: 'remove', payload: {user: binding.user}});
        });
    };

    const deleteSmsConfig = () => {
        reduxDispatch(deleteCustomerSmsConfig({did: smsConfigState.did}));
        setDeleteSmsConfigModal(false);
        toggle();
    };

    const checkServiceTypeBindingsLimit = (name, value) => {
        return name === 'service_type' && value === 'CROO_TALK_LITE' && smsBindingsState.length > 1;
    };

    const showDidValidation = () => {
        return (
            <Alert color={'warning'} className={'m-4 text-center'}>
                <span>
                    <FormattedMessage
                        id='message.admin.sms.binding.did.validation'
                        defaultMessage={`This field is mandatory`}
                    />
                </span>
            </Alert>
        );
    };
    const showBindingWarning = () => {
        return (
            <Alert color={'danger'} className={'m-2 text-center'}>
                <div>
                    <i className='fa fa-exclamation-triangle' />
                </div>
                <span className={''}>
                    <FormattedMessage
                        id='message.admin.sms.binding.warning'
                        defaultMessage={`Enabling this feature will disable SMS services in Bria`}
                    />
                </span>
            </Alert>
        );
    };

    return (
        <>
            <SidePanelHeader>
                {!!smsConfig ? (
                    formatShortAmericanNumber(smsConfig.did)
                ) : (
                    <FormattedMessage
                        id='message.admin.sms.config.title'
                        defaultMessage={`Create SMS configuration`}
                    />
                )}
            </SidePanelHeader>
            <SidePanelContent>
                {!smsConfig ? showBindingWarning() : ''}
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={'pb-2'}>
                        {!!!smsConfig && (
                            <FormSection
                                icon={faSms}
                                title={
                                    <FormattedMessage
                                        id='message.admin.sms.config.header'
                                        defaultMessage={`SMS number`}
                                    />
                                }
                                isOpenByDefault={defaultOpenSections.generalOpen}
                            >
                                <Row>
                                    <FormGroup className={'col'}>
                                        <SelectInput
                                            className={'pr-1'}
                                            onValueChange={(v) => editField('did', v)}
                                            options={incomingList.map((u) => ({
                                                label: u,
                                                value: u,
                                            }))}
                                            value={smsConfigState.did}
                                        />
                                        {showValidation ? showDidValidation() : ''}
                                    </FormGroup>
                                </Row>
                            </FormSection>
                        )}
                        <FormSection
                            icon={faSquarePhone}
                            isOpenByDefault={defaultOpenSections.servicesOpen}
                            title={
                                <FormattedMessage
                                    id='message.admin.sms.services.header'
                                    defaultMessage={`SMS services`}
                                />
                            }
                        >
                            <FormGroup>
                                <div className={'d-flex flex-column'}>
                                    <SelectInput
                                        className={'pr-1'}
                                        onValueChange={(v) => editField('service_type', v)}
                                        options={smsServicesSelection}
                                        value={smsConfigState.service_type}
                                        enabled={!loadingBindings}
                                    />
                                </div>
                            </FormGroup>
                        </FormSection>
                        <FormSection
                            icon={faUsers}
                            isOpenByDefault={defaultOpenSections.bindingsOpen}
                            title={
                                <FormattedMessage
                                    id='message.admin.sms.binding.header'
                                    defaultMessage={`User bindings`}
                                />
                            }
                        >
                            <div className={'d-flex flex-column flex-grow-1'}>
                                {loadingBindings ? (
                                    <Spinner
                                        color={'primary'}
                                        background={'transparent'}
                                        global={false}
                                        size={50}
                                    />
                                ) : (
                                    <SmsBindings
                                        did={smsConfigState.did}
                                        serviceType={smsConfigState.service_type}
                                        smsBindingsState={smsBindingsState}
                                        smsBindingsDispatch={smsBindingsDispatch}
                                    />
                                )}
                            </div>
                        </FormSection>
                        {!!smsConfig && (
                            <FormSection
                                icon={faCog}
                                isOpenByDefault={defaultOpenSections.advancedOpen}
                                title={
                                    <FormattedMessage
                                        id='message.admin.advanced'
                                        defaultMessage='Advanced'
                                    />
                                }
                            >
                                <FormattedMessage
                                    id='message.admin.delete.sms.number'
                                    defaultMessage={`Delete SMS number`}
                                >
                                    {(m) => (
                                        <Card>
                                            <CardBody>
                                                <h2 className={'text-danger'}>{m}</h2>
                                                <CardText className={'text-muted'}>
                                                    <small>
                                                        <FormattedMessage
                                                            id={
                                                                'message.admin.delete.sms.number.explanation'
                                                            }
                                                            defaultMessage={
                                                                'Deleting a SMS number will disable all SMS-to-email features for this phone number.'
                                                            }
                                                        />
                                                    </small>
                                                </CardText>
                                                <Button
                                                    color={'danger'}
                                                    size={'sm'}
                                                    onClick={() =>
                                                        setDeleteSmsConfigModal(
                                                            !deleteSmsConfigModal
                                                        )
                                                    }
                                                >
                                                    {m}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    )}
                                </FormattedMessage>
                                <DangerModal
                                    isOpen={deleteSmsConfigModal}
                                    toggle={() => setDeleteSmsConfigModal(!deleteSmsConfigModal)}
                                    title={
                                        <FormattedMessage
                                            id={'message.admin.delete.sms.number'}
                                            defaultMessage={'Delete SMS number'}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id={'message.admin.delete.sms.number.confirmation'}
                                            defaultMessage={
                                                'Are you sure you wish to delete this SMS number? This operation cannot be undone.'
                                            }
                                        />
                                    }
                                    onClick={deleteSmsConfig}
                                />
                            </FormSection>
                        )}
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
};

SmsConfigForm.propTypes = {};

export default injectIntl(SmsConfigForm);
