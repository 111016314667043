const UPPERCASE_REGEX = /[A-Z]/g;
const LOWERCASE_REGEX = /[a-z]/g;
const ALPHANUMERIC_REGEX = /[A-zA-Z]/g;
const DIGIT_REGEX = /[0-9]/g;
const NON_DIGIT = /[0-9]/g;
const SYMBOL_REGEX = /[^A-zA-Z0-9]/g;
const NON_SYMBOL = /[^A-zA-Z0-9]/g;

const LETTER_SEQUENCE = "abcdefghijklmnopqrstuvwxyz";
const DIGIT_SEQUENCE = "01234567890";
const SYMBOL_SEQUENCE = "!@#$%^&*()";

const number_of_characters = (password) => password.length * 4;

const uppercase = (password) => {
    let contains_only_uppercase = password.replace(UPPERCASE_REGEX, "").length === 0;
    return contains_only_uppercase ? 0 : (password.length - password.replace(UPPERCASE_REGEX, "").length) * 2;
};

const lowercase = (password) => {
    let contains_only_lowercase = password.replace(LOWERCASE_REGEX, "").length === 0;
    return contains_only_lowercase ? 0 : (password.length - password.replace(LOWERCASE_REGEX, "").length) * 2;
};

const digit = (password) => {
    let contains_only_digits = password.replace(DIGIT_REGEX, "").length === 0;
    return contains_only_digits ? 0 : (password.length - password.replace(NON_DIGIT, "").length) * 4;
};

const symbol = (password) => {
    let contains_only_symbol = password.replace(SYMBOL_REGEX, "").length === 0;
    return contains_only_symbol ? 0 : (password.length - password.replace(NON_SYMBOL, "").length) * 6;
};

const middle_digits_or_symbol = (password) => {
    let middle = password.substring(1, password.length - 1);
    return (middle.length - middle.replace(ALPHANUMERIC_REGEX, "").length) * 2;
};

const requirements = (validators) => (password) => {
    return validators.every(v => v(password)) ? validators.length * 2 : 0;
};

const letters_only = (password) => {
    let contains_only_letters = password.replace(ALPHANUMERIC_REGEX, "").length === 0;
    return contains_only_letters ? -password.length : 0;
};

const digits_only = (password) => {
    let contains_only_digits = password.replace(ALPHANUMERIC_REGEX, "").length === 0;
    return contains_only_digits ? -password.length : 0;
};

const repeat_characters = (password) => {
    let score = 0;
    let repeated_characters = 0;

    for (let i = 0; i < password.length; i++) {
        let exists = false;
        for (let j = 0; j < password.length; j++) {
            if (password[i] === password[j] && i !== j) {
                exists = true;
                score += Math.abs(password.length / (j - i));
            }
        }
        if (exists) {
            repeated_characters++;
            let unique_characters = password.length - repeated_characters;
            score = unique_characters > 0 ? Math.ceil(score / unique_characters) : Math.ceil(score)
        }
    }
    return -score;
};

let consecutive_uppercase_characters = (password) => {
    let score = 0;
    let last_uppercase_character_index = -2;

    for (let i = 0; i < password.length; i++) {
        if (password[i].match(UPPERCASE_REGEX) && (i - 1)) {
            if (i - 1 === last_uppercase_character_index) {
                score++;
            }
            last_uppercase_character_index = i;
        }
    }
    return -score * 2
};

let consecutive_lowercase_characters = (password) => {
    let score = 0;
    let last_lowercase_character_index = -2;

    for (let i = 0; i < password.length; i++) {
        if (password[i].match(LOWERCASE_REGEX) && (i - 1)) {
            if (i - 1 === last_lowercase_character_index) {
                score++;
            }
            last_lowercase_character_index = i;
        }
    }
    return -score * 2
};

let consecutive_digits = (password) => {
    let score = 0;
    let last_digit_index = -2;

    for (let i = 0; i < password.length; i++) {
        if (password[i].match(DIGIT_REGEX) && (i - 1)) {
            if (i - 1 === last_digit_index) {
                score++;
            }
            last_digit_index = i;
        }
    }
    return -score * 2
};

let sequential_letters = (threshold) => (password) => {
    let score = 0;
    for (let i = 0; i < LETTER_SEQUENCE.length - threshold; i++) {
        let forward_sequence = LETTER_SEQUENCE.substring(i, (i + threshold));
        let backwards_sequence = forward_sequence.split("").reverse().join("");
        if (password.toLowerCase().indexOf(forward_sequence) !== -1 || password.toLowerCase().indexOf(backwards_sequence) !== -1) {
            score++
        }
    }
    return -score * 3
};

let sequential_digits = (threshold) => (password) => {
    let score = 0;
    for (let i = 0; i < DIGIT_SEQUENCE.length - threshold; i++) {
        let forward_sequence = DIGIT_SEQUENCE.substring(i, (i + threshold));
        let backwards_sequence = forward_sequence.split("").reverse().join("");
        if (password.indexOf(forward_sequence) !== -1 || password.indexOf(backwards_sequence) !== -1) {
            score++
        }
    }
    return -score * 3
};

let sequential_symbols = (threshold) => (password) => {
    let score = 0;
    for (let i = 0; i < SYMBOL_SEQUENCE.length - threshold; i++) {
        let forward_sequence = SYMBOL_SEQUENCE.substring(i, (i + threshold));
        let backwards_sequence = forward_sequence.split("").reverse().join("");
        if (password.indexOf(forward_sequence) !== -1 || password.indexOf(backwards_sequence) !== -1) {
            score++
        }
    }
    return -score * 3
};

const add = {
    number_of_characters: number_of_characters,
    uppercase: uppercase,
    lowercase: lowercase,
    digit: digit,
    symbol: symbol,
    middle_digits_or_symbol: middle_digits_or_symbol,
    requirements: requirements
};

const deduct = {
    letters_only: letters_only,
    digits_only: digits_only,
    repeat_characters: repeat_characters,
    consecutive_uppercase_characters: consecutive_uppercase_characters,
    consecutive_lowercase_characters: consecutive_lowercase_characters,
    consecutive_digits: consecutive_digits,
    sequential_letters: sequential_letters,
    sequential_digits: sequential_digits,
    sequential_symbols: sequential_symbols
};

export {add, deduct}
