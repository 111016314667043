import React, {useState} from 'react';
import {Card, CardBody} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import Auth from '@aws-amplify/auth';
import RedirectTo from '../../components/utils/RedirectTo';
import {useQuery} from 'utils/hooks';
import PasswordSetter from '../../components/password/PasswordSetter';
import LocaleSelector from '../../components/locale/LocaleSelector';

const NewPassword = () => {
    const query = useQuery();

    const login = query.get('login');
    const token = query.get('token');

    const [done, setDone] = useState(false);

    const submit = async (newPassword) => {
        try {
            await Auth.forgotPasswordSubmit(login, token, newPassword);
            setDone(true);
        } catch (e) {
            alert(e.message);
        }
    };

    return (
        <div className='Section__container___3YYTG'>
            <Card className='w-card align-self-center bg-secondary border-0 no-select d-flex'>
                <CardBody className=''>
                    {!(token && login) ? (
                        <>
                            <span>
                                <FormattedMessage
                                    id='message.password_reset.link.false'
                                    defaultMessage='Your link has already been used'
                                />
                            </span>
                            <br />
                            <RedirectTo timer={10} path={'/'} />
                        </>
                    ) : done ? (
                        <>
                            <span>
                                <FormattedMessage
                                    id='message.password_reset.done'
                                    defaultMessage='Your password has been reset'
                                />
                            </span>
                            <br />
                            <RedirectTo timer={3} path={'/'} />
                        </>
                    ) : (
                        <>
                            <FormattedMessage
                                id='title.forgot_password'
                                defaultMessage='Reset your password'
                            >
                                {(m) => <h3 className='text-muted ta-center mb-4'>{m}</h3>}
                            </FormattedMessage>
                            <PasswordSetter
                                onSubmit={(currentPassword, newPassword) => submit(newPassword)}
                                size={'md'}
                            />
                        </>
                    )}
                </CardBody>
            </Card>
            <LocaleSelector style={{position: 'fixed', top: 15, right: 15}} />
        </div>
    );
};

export default NewPassword;
