import {faCalendar, faCalendarMinus, faCalendarPlus} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import React, {useEffect, useState} from 'react';
import {DateInput, DateRangeInput} from '../../input/DateInput';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getUserLocale} from '../../../store/selectors/user-selectors';
import {getDefaultTimeZone} from '../../../utils/datetime';

const types = defineMessages({
    between: {
        id: 'date.select.type.between',
        defaultMessage: 'Between',
    },
    before: {
        id: 'date.select.type.before',
        defaultMessage: 'Before',
    },
    after: {
        id: 'date.select.type.after',
        defaultMessage: 'After',
    },
    today: {
        id: 'date.select.type.today',
        defaultMessage: 'Today',
    },
    lastWeek: {
        id: 'date.select.type.last.week',
        defaultMessage: 'Last week',
    },
    twoWeeks: {
        id: 'date.select.type.last.two.weeks',
        defaultMessage: 'Last two weeks',
    },
    lastMonth: {
        id: 'date.select.type.last.month',
        defaultMessage: 'Last month',
    },
    twoMonths: {
        id: 'date.select.type.last.two.months',
        defaultMessage: 'Last two months',
    },
    lastYear: {
        id: 'date.select.type.last.year',
        defaultMessage: 'Last year',
    },
});

const messages = defineMessages({
    display_between: {
        id: 'message.date.between',
        defaultMessage: 'Between {start} and {end}',
    },
    display_before: {
        id: 'message.date.before',
        defaultMessage: 'Before {end}',
    },
    display_after: {
        id: 'message.date.after',
        defaultMessage: 'After {start}',
    },
    title: {
        id: 'filter.title.date',
        defaultMessage: 'Date',
    },
});

const getValidTypes = (limit) => {
    if (limit) {
        const validTypes = {...types};
        delete validTypes.after;
        delete validTypes.before;
        return validTypes;
    } else return {before: types.before, after: types.after, between: types.between};
};

const DateFilter = injectIntl(({intl, defaultValue, limit, onValidityChange, onValueChange}) => {
    const [startDate, setStartDate] = useState(
        moment(defaultValue[0]).isValid() ? moment(defaultValue[0]).toDate().toString() : ''
    );
    const [endDate, setEndDate] = useState(
        moment(defaultValue[1]).isValid() ? moment(defaultValue[1]).toDate().toString() : ''
    );
    const [type, setType] = useState(defaultValue[2] ? defaultValue[2] : 'between');

    const [toggle, setToggle] = useState(false);
    const validTypes = getValidTypes(limit);

    useEffect(() => {
        const startOfToday = moment().startOf('day');
        const endOfToday = moment().endOf('day');

        switch (type) {
            case 'after':
                setEndDate(undefined);
                break;
            case 'before':
                setStartDate(undefined);
                break;
            case 'today':
                setEndDate(endOfToday);
                setStartDate(startOfToday);
                break;
            case 'lastWeek':
                setEndDate(endOfToday);
                setStartDate(startOfToday.subtract(7, 'days'));
                break;
            case 'twoWeeks':
                setEndDate(endOfToday);
                setStartDate(startOfToday.subtract(14, 'days'));
                break;
            case 'lastMonth':
                setEndDate(endOfToday);
                setStartDate(startOfToday.subtract(1, 'months'));
                break;
            case 'twoMonths':
                setEndDate(endOfToday);
                setStartDate(startOfToday.subtract(2, 'months'));
                break;
            case 'lastYear':
                setEndDate(endOfToday);
                setStartDate(startOfToday.subtract(1, 'years'));
                break;
            default:
                break;
        }
    }, [type]);

    useEffect(() => {
        onValueChange([startDate, endDate]);
    }, [startDate, endDate, onValueChange]);

    return (
        <div className={'flex-grow-1 d-flex flex-row align-items-center'}>
            <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)} size={'sm'}>
                <DropdownToggle className='btn-sm btn-outline-default' caret>
                    <FormattedMessage {...validTypes[type]} />
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(validTypes).map((t, key) => {
                        return (
                            <DropdownItem key={key} onClick={() => setType(t)}>
                                <FormattedMessage {...validTypes[t]} />
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
            {type === 'before' && (
                <DateInput
                    onValidityChange={onValidityChange}
                    onValueChange={setEndDate}
                    value={endDate}
                />
            )}
            {type !== 'before' && type !== 'after' && (
                <DateRangeInput
                    onValidityChange={onValidityChange}
                    onValueChange={([start, end]) => {
                        setStartDate(start);
                        setEndDate(end);
                    }}
                    startPlaceholder={intl.formatMessage(types.before)}
                    endPlaceholder={intl.formatMessage(types.after)}
                    value={[startDate, endDate]}
                    limit={limit}
                    enabled={type === 'between'}
                />
            )}
            {type === 'after' && (
                <DateInput
                    onValidityChange={onValidityChange}
                    onValueChange={setStartDate}
                    value={startDate}
                />
            )}
        </div>
    );
});

DateFilter.propTypes = {
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    limit: PropTypes.number,
    onEnter: PropTypes.func,
    onEsc: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
};

DateFilter.defaultProps = {
    defaultValue: ['', ''],
    limit: null,
    onEnter: () => {},
    onEsc: () => {},
    onValidityChange: () => true,
};

const DateDisplayContent = ({start, end}) => {
    const locale = useSelector(getUserLocale);

    return (
        <FormattedMessage
            {...(start
                ? end
                    ? messages.display_between
                    : messages.display_after
                : messages.display_before)}
            values={{
                ...(start
                    ? {start: moment(start).locale(locale).tz(getDefaultTimeZone()).format('LL')}
                    : {}),
                ...(end
                    ? {end: moment(end).locale(locale).tz(getDefaultTimeZone()).format('LL')}
                    : {}),
            }}
        />
    );
};

const factory = ([start, end]) => {
    return {
        type: 'date',
        index: 'date',
        state_value: [start, end, !start ? 'before' : !end ? 'after' : 'between'],
        serialized: `${start || ''}_${end || ''}`,
        custom_filter: (query) => {
            query.filters.date = {
                ...(start
                    ? {start: moment(start).tz('utc').startOf('day').format('YYYY-MM-DDTHH:mm:ss')}
                    : {}),
                ...(end
                    ? {end: moment(end).tz('utc').endOf('day').format('YYYY-MM-DDTHH:mm:ss')}
                    : {}),
                time_zone: getDefaultTimeZone(),
            };
            return query;
        },
        elastic_filter: (query) =>
            query.filter('range', 'call_start_time', {
                ...(start
                    ? {gte: moment(start).tz('utc').startOf('day').format('YYYY-MM-DDTHH:mm:ss')}
                    : {}),
                ...(end
                    ? {lte: moment(end).endOf('day').tz('utc').format('YYYY-MM-DDTHH:mm:ss')}
                    : {}),
                time_zone: getDefaultTimeZone(),
            }),
        display: {
            icon: start ? (end ? faCalendar : faCalendarPlus) : faCalendarMinus,
            content: <DateDisplayContent start={start} end={end} />,
        },
    };
};

export const date_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: DateFilter,
    factory: factory,
    icon: faCalendar,
    deserializer: (s) => {
        try {
            const args = s.split('_');
            if (args.length !== 2) return null;
            const start = moment(args[0]).isValid() ? args[0] : undefined;
            const end = moment(args[1]).isValid() ? args[1] : undefined;
            return factory([start, end]);
        } catch (e) {
            return null;
        }
    },
};
