import React from 'react'
import ContentWrapper from "../../components/content/ContentWrapper";
import CallsTable from "../../components/call/CallsTable";
import {FormattedMessage} from "react-intl";
import CardContent from "../../components/content/CardContent";

const CallHistory = () => {

    return <>
        <ContentWrapper>
            <CardContent fullHeight>
                <CallsTable
                    title={<FormattedMessage id={'title.call.history'} defaultMessage={'Call History'}/>}
                />
            </CardContent>
        </ContentWrapper>
    </>;
};

export default CallHistory;