/* i18n Action types */
export const STORE_PRIMARY_ALERT = 'portal STORE_PRIMARY_ALERT';
export const STORE_DANGER_ALERT = 'portal STORE_DANGER_ALERT';
export const STORE_WARNING_ALERT = 'portal STORE_WARNING_ALERT';
export const STORE_INFO_ALERT = 'portal STORE_INFO_ALERT';
export const WARM_UP = 'portal WARM LAMBDA';
export const HIDE_ALERT = 'portal HIDE_ALERT';
export const REMOVE_TOAST = 'portal REMOVE_TOAST';
export const STORE_TOAST = 'portal STORE_TOAST';
export const STORE_SECONDARY_TOAST = 'portal STORE_SECONDARY_TOAST';
export const STORE_WARNING_TOAST = 'portal STORE_WARNING_TOAST';
export const STORE_DANGER_TOAST = 'portal STORE_DANGER_TOAST';
export const STORE_PRIMARY_TOAST = 'portal STORE_PRIMARY_TOAST';
export const SHOW_AUDIO = 'portal PLAY_AUDIO';
export const HIDE_AUDIO = 'portal HIDE_AUDIO';
export const STORE_AUDIO = 'portal SET_AUDIO';
export const LOADING = 'portal LOADING';
export const LOADED = 'portal LOADED';

/* i18n Action Creators */

export const successAlert = (payload) => ({type: STORE_PRIMARY_ALERT, payload});
export const warningAlert = (payload) => ({type: STORE_WARNING_ALERT, payload});
export const dangerAlert = (payload) => ({type: STORE_DANGER_ALERT, payload});
export const infoAlert = (payload) => ({type: STORE_INFO_ALERT, payload});
export const hidePortalAlert = () => ({type: HIDE_ALERT});
export const warmUpLambda = () => ({type: WARM_UP});
export const storeToast = (payload) => ({type: STORE_TOAST, payload});
export const warningToast = (payload) => ({type: STORE_WARNING_TOAST, payload});
export const dangerToast = (payload) => ({type: STORE_DANGER_TOAST, payload});
export const primaryToast = (payload) => ({type: STORE_PRIMARY_TOAST, payload});
export const secondaryToast = (payload) => ({type: STORE_SECONDARY_TOAST, payload});
export const removePortalToast = (payload) => ({type: REMOVE_TOAST, payload});
export const showAudio = () => ({type: SHOW_AUDIO});
export const hideAudio = () => ({type: HIDE_AUDIO});
export const storeAudio = (payload) => ({type: STORE_AUDIO, payload});
export const loading = (payload) => ({type: LOADING, payload});
export const loaded = (payload) => ({type: LOADED, payload});
