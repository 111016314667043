import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
    getCustomerUsers,
    getCustomerSmsBindingsUsers,
} from '../../../../store/selectors/customer-selectors';
import SelectInput from '../../../../components/input/SelectInput';
import {FormattedMessage} from 'react-intl';
import {Action, ActionsCell} from '../../../../components/layout/Table/Cells';
import {Table} from '../../../../components/layout/Table/Table';
import {Alert, Button} from 'reactstrap';

const sms_bindings_columns = [
    {
        id: 'user',
        title: 'User',
        cellFactory: (binding, {users}) =>
            users ? users.find((u) => u.id === binding.user).full_name : '',
        width: 'auto',
    },
    {
        id: 'edit',
        title: '',
        cellFactory: (binding, {smsBindingsDispatch}) => (
            <ActionsCell>
                <Action
                    icon={'times'}
                    onClick={() =>
                        smsBindingsDispatch({
                            type: 'remove',
                            payload: {user: binding.user},
                        })
                    }
                />
            </ActionsCell>
        ),
        width: '50px',
        className: 'justify-content-end',
    },
];
// add service type no empty verif
const isBindingsReachedLimits = (smsBindingsState, serviceType) => {
    switch (serviceType) {
        case 'CROO_TALK_LITE':
            return smsBindingsState.length >= 1;

        case 'CROO_TALK':
            return false;

        default:
            return false;
    }
};

const isUserNotAlreadyBinded = (bindings_users, selectedUser, serviceType) => {
    let status = true;
    if (serviceType === 'CROO_TALK_LITE') {
        bindings_users.forEach((user) => {
            if (user.user === selectedUser) {
                status = false;
            }
        });
    }
    return status;
};

const showValidation = () => {
    return (
        <Alert color={'warning'} className={'m-4 text-center'}>
            <span>
                <FormattedMessage
                    id='message.admin.sms.binding.user.validation'
                    defaultMessage={`This user is already binded`}
                />
            </span>
        </Alert>
    );
};

const SmsBindings = ({did, serviceType, smsBindingsState, smsBindingsDispatch}) => {
    const users = useSelector(getCustomerUsers);
    const bindings_users = useSelector(getCustomerSmsBindingsUsers);

    const [selectedUser, setSelectedUser] = useState('');
    const [showUserValidation, setShowUserValidation] = useState(false);

    return (
        <div className={'d-flex flex-column'}>
            <div className={'d-flex w-100 py-2'}>
                <SelectInput
                    className={'pr-1'}
                    onValueChange={setSelectedUser}
                    options={users.map((u) => ({label: u.full_name, value: u.id}))}
                    value={selectedUser}
                />
                <Button
                    color={'primary'}
                    disabled={
                        !!!selectedUser || isBindingsReachedLimits(smsBindingsState, serviceType)
                    }
                    onClick={() => {
                        if (isUserNotAlreadyBinded(bindings_users, selectedUser, serviceType)) {
                            smsBindingsDispatch({
                                type: 'add',
                                payload: {user: selectedUser, outbound_chat: true, sms_did: did},
                            });
                            if (showUserValidation) setShowUserValidation(!showUserValidation);
                        } else {
                            setShowUserValidation(true);
                        }
                    }}
                    size={'sm'}
                >
                    <FormattedMessage id={'btn.add'} defaultMessage={'Add'} />
                </Button>
            </div>
            <div
                style={{overflowY: 'auto', height: smsBindingsState.length * 55 + 20}}
                className={'d-flex flex-column flex-grow-1 px-0'}
            >
                <Table
                    columns={sms_bindings_columns}
                    handles={{smsBindingsDispatch, users}}
                    head={false}
                    items={smsBindingsState}
                    itemSize={55}
                    itemsDispatch={(items) => smsBindingsDispatch({type: 'sort', payload: items})}
                />
            </div>
            {showUserValidation ? showValidation() : ''}
        </div>
    );
};

SmsBindings.propTypes = {};

export default SmsBindings;
