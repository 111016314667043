import {color_from_string} from '../../../utils/colors';
import React from 'react';
import PropTypes from 'prop-types';
import {UncontrolledTooltip} from 'reactstrap';

const LETTER_HEADER_COMPONENTS = {
    xs: 'h5',
    sm: 'h4',
    md: 'h3',
    lg: 'h2',
    xl: 'h1',
};

const AVATAR_SIZES = {
    xs: 24,
    sm: 36,
    md: 48,
    lg: 58,
    xl: 74,
};

const Avatar = ({className, id, onClick, size, style, src, alt}) => {
    const H = LETTER_HEADER_COMPONENTS[size];
    return (
        <>
            <span
                id={id}
                onClick={onClick ? onClick : () => {}}
                className={`cursor-${onClick !== null ? 'pointer' : 'default'} avatar ${
                    size !== 'md' && 'avatar-' + size
                } rounded-circle ${className}`}
                style={{
                    backgroundColor: alt ? color_from_string(alt) : '#dddddd',
                    ...style,
                }}
            >
                {src ? (
                    <img alt={alt} src={src} />
                ) : (
                    <H className={'text-white m-0'} style={{lineHeight: `${AVATAR_SIZES[size]}px`}}>
                        {alt ? alt[0] : ''}
                    </H>
                )}
            </span>
            {id && (
                <UncontrolledTooltip placement={'top'} target={id}>
                    {alt}
                </UncontrolledTooltip>
            )}
        </>
    );
};

Avatar.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    src: PropTypes.string,
    alt: PropTypes.string,
};

Avatar.defaultProps = {
    onClick: null,
    size: 'md',
};

export default Avatar;
