import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {localeChange} from '../../store/actions/i18n-action';
import {geti18nLocale} from '../../store/selectors/user-selectors';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

const LocaleSelector = ({className, style}) => {
    const locale = useSelector(geti18nLocale);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const locales = {
        en: '🇺🇸 English',
        fr: '🇫🇷 Français',
    };

    return (
        <div className={className} style={style}>
            <Dropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
                <DropdownToggle caret>{locales[locale || 'en']}</DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem onClick={() => dispatch(localeChange('en'))}>
                        {locales.en}
                    </DropdownItem>
                    <DropdownItem onClick={() => dispatch(localeChange('fr'))}>
                        {locales.fr}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

LocaleSelector.propTypes = {};

export default LocaleSelector;
