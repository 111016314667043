import React from 'react';
import '../../styles/LinearProgressBarStyle.css';
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FormattedMessage} from 'react-intl';
import {Badge, Progress} from 'reactstrap';

const formatValue = (format, value, max) => {
    switch (format.key) {
        case 'number':
            return value;
        case 'percentage':
            return ((value / max) * 100).toFixed(2);
        default:
            return value;
    }
};

const formatMax = (format, max) => {
    switch (format.key) {
        case 'number':
            return max;
        case 'percentage':
            return 100;
        default:
            return max;
    }
};

const formatTopText = (format, value, max) => {
    switch (format.key) {
        case 'number':
            return value + ' out of ' + max;
        case 'percentage':
            return (
                formatValue(format, value, max) +
                '% (' +
                value +
                ') out of ' +
                formatMax(format, max) +
                '% (' +
                max +
                ')'
            );
        default:
            return formatValue(format, value, max) + ' out of ' + formatMax(format, max);
    }
};

const getColor = (gauge, value, max) => {
    if (value / max < 0.25) {
        return gauge ? '#FF2F40' : 'danger';
    } else if (value / max < 0.5) {
        return gauge ? '#fb6340' : 'warning';
    } else if (value / max < 0.75) {
        return gauge ? '#2dce89' : 'success';
    } else return gauge ? '#E0007A' : 'primary';
};

const getGaugeValue = (value, max) => {
    let percentage = 0;
    if (max) percentage = ((value / max) * 100).toFixed(2);
    if (percentage >= 100.0) return 100;
    else return percentage;
};

export const LinearProgressBar = ({extras}) => {
    const format = extras ? extras.format : null;
    const items = extras ? extras.items : [];
    const max = extras ? extras.max : null;

    const styleGenerator = (height, itemsLength) => {
        const padding = height / 3 + 'px';
        const style = {
            height: height,
            textAlign: 'center',
        };

        if (!(itemsLength > 1)) {
            style.paddingTop = padding;
            style.paddingBottom = padding;
        } else {
            style.overflowY = 'scroll';
        }

        return style;
    };

    return (
        <>
            {format && items && max ? (
                <AutoSizer disableWidth>
                    {({height}) => {
                        return (
                            <div style={styleGenerator(height, items.length)}>
                                {items.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <div className='text-center'>
                                            {item.text +
                                                ': ' +
                                                formatTopText(format, item.value, max)}
                                        </div>
                                        <Progress
                                            className={'tall'}
                                            barClassName={'align-items-start'}
                                            color={getColor(false, item.value, max)}
                                            value={formatValue(format, item.value, max)}
                                            max={formatMax(format, max)}
                                        >
                                            <Badge className={'ml-1'}>
                                                {formatValue(format, item.value, max) +
                                                    (format.key === 'percentage' ? '%' : null)}
                                            </Badge>
                                        </Progress>
                                    </React.Fragment>
                                ))}
                            </div>
                        );
                    }}
                </AutoSizer>
            ) : (
                <div className={'text-center'} style={{paddingTop: '100px'}}>
                    <FormattedMessage
                        id='widget.incomplete'
                        defaultMessage='Please fill in the widget configurations'
                    />
                </div>
            )}
        </>
    );
};

export const ProgressGauge = ({data, extras}) => {
    const allCalls = data.find((i) => i.key === 'All Calls');

    const value = allCalls ? allCalls.value : 0;
    const max = extras ? extras.max : null;

    return (
        <>
            {value && max ? (
                <AutoSizer disableWidth>
                    {({height}) => {
                        const fontSize = height / 6 + 'px';
                        const paddingTop = height / 8 + 'px';
                        const paddingBottom = height / 8 + 'px';
                        return (
                            <div
                                style={{
                                    height: 'auto',
                                    width: (3 * height) / 4,
                                    margin: 'auto',
                                    paddingTop,
                                    paddingBottom,
                                }}
                            >
                                <CircularProgressbarWithChildren
                                    value={getGaugeValue(value, max)}
                                    styles={buildStyles({
                                        textColor: getColor(true, value, max),
                                        pathColor: getColor(true, value, max),
                                        trailColor: '#e9ecef',
                                    })}
                                >
                                    <div
                                        className={'text-center'}
                                        style={{
                                            fontSize: fontSize,
                                            color: getColor(true, value, max),
                                        }}
                                    >
                                        {getGaugeValue(value, max)}%
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                        );
                    }}
                </AutoSizer>
            ) : (
                <div className={'text-center'} style={{paddingTop: '100px'}}>
                    <FormattedMessage
                        id='widget.incomplete'
                        defaultMessage='Please fill in the widget configurations'
                    />
                </div>
            )}
        </>
    );
};

export const ProgressValue = ({data}) => {
    const allCalls = data.find((i) => i.key === 'All Calls');

    const value = allCalls ? allCalls.value : 0;

    return (
        <>
            {value != null ? (
                <AutoSizer disableWidth>
                    {({height}) => {
                        const fontSize = height / 2 + 'px';
                        const paddingTop = height / 8 + 'px';
                        const paddingBottom = (3 * height) / 8 + 'px';
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                    paddingTop,
                                    paddingBottom,
                                }}
                            >
                                <h1 style={{fontSize: fontSize}}>{value}</h1>
                            </div>
                        );
                    }}
                </AutoSizer>
            ) : (
                <div className={'text-center'} style={{paddingTop: '100px'}}>
                    <FormattedMessage
                        id='widget.incomplete'
                        defaultMessage='Please fill in the widget configurations'
                    />
                </div>
            )}
        </>
    );
};
