import React from 'react';
import {faArrowsAltH} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import {direction_icon, direction_label} from '../../../utils/call_history';
import * as PropTypes from 'prop-types';
import ButtonsSelectInput from '../../input/ButtonsSelectInput';

export const messages = defineMessages({
    display: {
        id: 'message.direction',
        defaultMessage: 'Direction: {direction}',
    },
    title: {
        id: 'filter.direction.title',
        defaultMessage: 'Direction',
    },
});

const factory = (direction) => ({
    type: 'direction',
    index: `direction_${direction}`,
    serialized: direction,
    custom_filter: (query) => {
        query.filters.direction = query.filters.direction
            ? [direction, ...query.filters.direction]
            : [direction];
        return query;
    },
    elastic_filter: (query) => query.filter('term', 'direction.keyword', direction),
    display: {
        icon: direction_icon(direction),
        content: (
            <FormattedMessage
                {...messages.display}
                values={{direction: direction_label(direction)}}
            />
        ),
        intlContent: (intl) => (
            <FormattedMessage
                {...messages.display}
                values={{direction: direction_label(direction, intl)}}
            />
        ),
    },
});

const DirectionFilter = injectIntl(({intl, onValueChange, size}) => {
    const directions = ['out', 'in', 'internal']; //TODO: replace hard coded value with new directions// useSelector(getCallsDirections);

    return (
        <ButtonsSelectInput
            onValueChange={(v) => {
                onValueChange(v, true);
            }}
            options={directions.map((d) => ({
                value: d,
                label: direction_label(d, intl),
                icon: direction_icon(d),
            }))}
            size={size}
        />
    );
});

DirectionFilter.propTypes = {
    onValueChange: PropTypes.func,
    size: PropTypes.string,
};

DirectionFilter.defaultProps = {
    size: 'sm',
};

export const direction_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: DirectionFilter,
    auto: true,
    factory: factory,
    icon: faArrowsAltH,
};
