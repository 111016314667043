import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {Button, Card, CardBody, CardText, Form, FormGroup, Label, Nav, Row} from 'reactstrap';
import {faCog, faShare, faVoicemail} from '@fortawesome/free-solid-svg-icons';
import FormSection from 'components/form/FormSection';
import Spinner from 'components/spinner/Spinner';
import {getLoading} from 'store/selectors/portal-selectors';
import {
    getCustomerExtensionConfig,
    getCustomerExtensions,
} from 'store/selectors/customer-selectors';
import SidePanelContent from 'components/panel/SidePanelContent';
import SidePanelHeader from 'components/panel/SidePanelHeader';
import TextInput from 'components/input/TextInput';
import PasswordInput from 'components/input/PasswordInput';
import CheckboxInput from 'components/input/CheckboxInput';
import SelectInput from 'components/input/SelectInput';
import {Table} from 'components/layout/Table/Table';
import {Action, ActionsCell} from 'components/layout/Table/Cells';
import {createCustomerExtension, updateCustomerExtension} from 'store/actions/customer-action';
import WarningSipModal from './Modal/WarningSipModal';
import DeleteExtensionModal from './Modal/DeleteExtensionModal';
import {messages} from './extensionTraduction';
import {limitValue, notNumber, specialCharacters} from 'utils/validation';

const numbers_columns = [
    {
        id: 'number',
        title: '',
        cellFactory: (number: string) => number,
        width: 'auto',
    },
    {
        id: 'remove',
        title: '',
        cellFactory: (number: string, handles: any) => (
            <ActionsCell>
                <Action
                    icon={'times'}
                    onClick={() =>
                        handles.setCurrentExtension({
                            ...handles.currentExtension,
                            follow_me: {
                                ...handles.currentExtension.follow_me,
                                numbers: handles.currentExtension.follow_me.numbers.filter(
                                    (num: string) => num !== number
                                ),
                            },
                        })
                    }
                />
            </ActionsCell>
        ),
        width: '50px',
        className: 'justify-content-end',
    },
];

const defaultOpenSections = {
    generalSettings: true,
    voicemailSettings: false,
    callForwardSettings: false,
    advancedOpen: false,
};

const ringStrategy = (intl: any) => [
    {name: intl.formatMessage(messages.ringAll), value: 'ringall'},
    {name: intl.formatMessage(messages.hunt), value: 'hunt'},
];

export interface DefaultExtensionProps {
    extension: string;
    name: string;
    sip_password: string;
    type: string;
    site: string;
    voicemail: {
        enabled: boolean;
        password: string;
        email: {
            address: string;
            delete: boolean;
            attach: boolean;
        };
    };
    follow_me: {
        enabled: boolean;
        numbers: string[];
        group_ringtime: number | string;
        pre_ringtime: number | string;
        strategy: string;
    };
}

const defaultExtensionState: DefaultExtensionProps = {
    extension: '',
    name: '',
    sip_password: '',
    type: 'sip',
    site: '',
    voicemail: {
        enabled: false,
        password: '',
        email: {
            address: '',
            delete: false,
            attach: false,
        },
    },
    follow_me: {
        enabled: false,
        numbers: [],
        group_ringtime: 20,
        pre_ringtime: 7,
        strategy: 'ringall',
    },
};

interface ExtensionFormProps {
    tempExtension?: DefaultExtensionProps;
    toggle: () => void;
}

const ExtensionForm = ({tempExtension, toggle}: ExtensionFormProps) => {
    const intl = useIntl();
    const reduxDispatch = useDispatch();

    const extension = useSelector(getCustomerExtensionConfig);
    const loading: boolean | undefined = useSelector(getLoading('extension_settings'));
    const customerExtensions = Object.values(useSelector(getCustomerExtensions));

    const [number, setNumber] = useState<string>('');
    const [errorFormExtension, setErrorFormExtension] = useState(!tempExtension);
    const [errorFormExistExtension, setErrorFormExistExtension] = useState(false);
    const [errorFormName, setErrorFormName] = useState(!tempExtension);
    const [errorFormVoicemailPassword, setErrorFormVoicemailPassword] = useState(false);
    const [currentExtension, setCurrentExtension] = useState(defaultExtensionState);
    const [deleteExtensionModal, setDeleteExtensionModal] = useState(false);
    const [warningSipGenerateModal, setWarningSipGenerateModal] = useState(false);
    const [emptyExtension, setEmptyExtension] = useState(false);

    useEffect(() => {
        if (tempExtension && Object.keys(extension).length === 0 && !loading)
            setEmptyExtension(true);
        if (!loading && tempExtension) {
            if (!extension?.follow_me?.enabled) {
                setCurrentExtension({
                    ...extension,
                    follow_me: {
                        ...extension.follow_me,
                        group_ringtime: 20,
                        pre_ringtime: 7,
                        strategy: 'ringall',
                    },
                });
            } else setCurrentExtension(extension);
        }
        if (currentExtension.extension === extension.extension) setCurrentExtension(extension);
    }, [tempExtension, loading, extension, currentExtension.extension]);

    useEffect(() => {
        if (tempExtension === null) {
            const extensionExist = customerExtensions.some(
                (element: any) => element.extension === currentExtension.extension
            );
            setErrorFormExistExtension(extensionExist);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExtension.extension]);

    useEffect(() => {
        if (!emptyExtension) {
            const {follow_me} = currentExtension;
            const verificationFieldFollowMe =
                follow_me.numbers.length > 1 ||
                (follow_me.pre_ringtime !== 7 && typeof follow_me.pre_ringtime !== 'string') ||
                (follow_me.group_ringtime !== 20 && typeof follow_me.group_ringtime !== 'string') ||
                follow_me.strategy !== 'ringall';

            setCurrentExtension({
                ...currentExtension,
                follow_me: {
                    ...currentExtension.follow_me,
                    enabled: verificationFieldFollowMe,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentExtension?.follow_me?.numbers,
        currentExtension?.follow_me?.pre_ringtime,
        currentExtension?.follow_me?.group_ringtime,
        currentExtension?.follow_me?.strategy,
    ]);

    useEffect(() => {
        if (!emptyExtension) {
            const {voicemail} = currentExtension;
            const verificationFieldEmail =
                voicemail.email.address.length > 0 ||
                voicemail.email.attach ||
                voicemail.email.delete ||
                voicemail.password.length > 0;

            setCurrentExtension({
                ...currentExtension,
                voicemail: {
                    ...currentExtension.voicemail,
                    enabled: verificationFieldEmail,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentExtension?.voicemail?.email.address,
        currentExtension?.voicemail?.email.delete,
        currentExtension?.voicemail?.email.attach,
        currentExtension?.voicemail?.password,
    ]);

    const submit = (e: any) => {
        e.preventDefault();
        if (tempExtension) {
            reduxDispatch(updateCustomerExtension(currentExtension));
        } else {
            reduxDispatch(createCustomerExtension(currentExtension));
        }
    };

    const addNumber = () => {
        setCurrentExtension({
            ...currentExtension,
            follow_me: {
                ...currentExtension.follow_me,
                numbers: [...currentExtension.follow_me.numbers, number],
            },
        });
        setNumber('');
    };

    const errorInput = (value: string, nameInput: string) => {
        if (nameInput === 'extension') {
            if ((value.length >= 1 && notNumber(value)) || value.length === 0) {
                setErrorFormExtension(true);
                return notNumber(value);
            } else if (value.length >= 1 && !notNumber(value)) {
                setErrorFormExtension(false);
            }
        }

        if (nameInput === 'name') {
            if ((value.length >= 1 && specialCharacters(value)) || value.length === 0) {
                setErrorFormName(true);
                return specialCharacters(value);
            } else if (value.length >= 1 && !specialCharacters(value)) {
                setErrorFormName(false);
            }
        }

        if (nameInput === 'password-voicemail') {
            if (value.length >= 1 && notNumber(value)) {
                setErrorFormVoicemailPassword(true);
                return notNumber(value);
            } else if ((value.length >= 1 && !notNumber(value)) || value.length === 0) {
                setErrorFormVoicemailPassword(false);
            }
        }
    };

    const disabledForm =
        errorFormExtension ||
        errorFormName ||
        errorFormVoicemailPassword ||
        errorFormExistExtension;

    return (
        <>
            <SidePanelHeader>
                {tempExtension || currentExtension.extension === extension.extension
                    ? `${intl.formatMessage(messages.editExtension)} ${currentExtension.extension}`
                    : intl.formatMessage(messages.createExtension)}
            </SidePanelHeader>
            {
                <SidePanelContent style={{height: '90%'}}>
                    {loading ? (
                        <Spinner
                            color={'primary'}
                            background={'transparent'}
                            global={false}
                            size={50}
                        />
                    ) : emptyExtension ? (
                        intl.formatMessage(messages.errorOccured)
                    ) : (
                        <Form role='form' className='form-sm' onSubmit={submit}>
                            <Nav vertical className={''}>
                                <FormSection
                                    icon={faCog}
                                    isOpenByDefault={defaultOpenSections.generalSettings}
                                    title={intl.formatMessage(messages.adminExtension)}
                                    paddingLeft={'30px'}
                                >
                                    {!(currentExtension.extension === extension.extension) && (
                                        <Row>
                                            <FormGroup className='col'>
                                                <Label className='form-control-label'>
                                                    {intl.formatMessage(messages.labelExtension)}
                                                </Label>
                                                <TextInput
                                                    onValueChange={(value: string) => {
                                                        setCurrentExtension({
                                                            ...currentExtension,
                                                            extension: value,
                                                        });
                                                        errorInput(value, 'extension');
                                                    }}
                                                    maxLength={6}
                                                    enabled={!tempExtension}
                                                    placeholder={intl.formatMessage(
                                                        messages.placeholderExtension
                                                    )}
                                                    value={currentExtension.extension}
                                                    errorMessage={
                                                        errorFormExistExtension
                                                            ? intl.formatMessage(
                                                                  messages.errorExistExtension
                                                              )
                                                            : intl.formatMessage(
                                                                  messages.errorMessageExtension
                                                              )
                                                    }
                                                    validator={(value: string) =>
                                                        errorFormExistExtension || notNumber(value)
                                                    }
                                                    infoMessage={
                                                        !notNumber(currentExtension.extension) &&
                                                        currentExtension.extension.length === 1
                                                            ? intl.formatMessage(
                                                                  messages.infoMessageExtension
                                                              )
                                                            : ''
                                                    }
                                                />
                                            </FormGroup>
                                        </Row>
                                    )}
                                    <Row>
                                        <FormGroup className='col'>
                                            <Label className='form-control-label'>
                                                {intl.formatMessage(messages.labelName)}
                                            </Label>
                                            <TextInput
                                                onValueChange={(value: string) => {
                                                    setCurrentExtension({
                                                        ...currentExtension,
                                                        name: value,
                                                    });
                                                    errorInput(value, 'name');
                                                }}
                                                placeholder={intl.formatMessage(
                                                    messages.placeholderName
                                                )}
                                                value={currentExtension.name}
                                                errorMessage={intl.formatMessage(
                                                    messages.errorMessageName
                                                )}
                                                validator={(value: string) =>
                                                    specialCharacters(value)
                                                }
                                            />
                                        </FormGroup>
                                    </Row>
                                    {currentExtension.sip_password && (
                                        <Row>
                                            <FormGroup className='col'>
                                                <Label className='form-control-label'>
                                                    {intl.formatMessage(messages.labelSecret)}
                                                </Label>
                                                <PasswordInput
                                                    enabled={false}
                                                    name={'sipsecret'}
                                                    placeholder={intl.formatMessage(
                                                        messages.placeholderSecret
                                                    )}
                                                    value={currentExtension.sip_password}
                                                />
                                            </FormGroup>
                                            <div
                                                className='d-flex align-items-center'
                                                style={{margin: '7px 15px 0 0'}}
                                            >
                                                <Button
                                                    color={'primary'}
                                                    size={'sm'}
                                                    onClick={() => setWarningSipGenerateModal(true)}
                                                    disabled={disabledForm}
                                                >
                                                    {intl.formatMessage(messages.generate)}
                                                </Button>
                                            </div>
                                        </Row>
                                    )}
                                </FormSection>
                                <FormSection
                                    icon={faVoicemail}
                                    isOpenByDefault={defaultOpenSections.voicemailSettings}
                                    title={intl.formatMessage(messages.voicemailSettings)}
                                    component={
                                        <CheckboxInput
                                            onValueChange={() =>
                                                setCurrentExtension({
                                                    ...currentExtension,
                                                    voicemail: {
                                                        ...currentExtension.voicemail,
                                                        enabled:
                                                            !currentExtension.voicemail?.enabled,
                                                    },
                                                })
                                            }
                                            value={currentExtension.voicemail?.enabled}
                                        />
                                    }
                                    paddingLeft={'30px'}
                                >
                                    <Row>
                                        <FormGroup className='col mr-2'>
                                            <Label className='form-control-label'>
                                                {intl.formatMessage(
                                                    messages.labelVoicemailPassword
                                                )}
                                            </Label>
                                            <PasswordInput
                                                onValueChange={(value: string) => {
                                                    setCurrentExtension({
                                                        ...currentExtension,
                                                        voicemail: {
                                                            ...currentExtension.voicemail,
                                                            password: value,
                                                        },
                                                    });
                                                    errorInput(value, 'password-voicemail');
                                                }}
                                                placeholder={intl.formatMessage(
                                                    messages.placeholderVoicemailPassword
                                                )}
                                                value={currentExtension.voicemail?.password}
                                                errorMessage={intl.formatMessage(
                                                    messages.errorMessagePasswordVoicemail
                                                )}
                                                maxLength={10}
                                                validator={(value: string) => notNumber(value)}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <FormSection
                                        isOpenByDefault={false}
                                        title={intl.formatMessage(messages.labelVoicemailDelivery)}
                                        titleClass={'small'}
                                    >
                                        <Row>
                                            <FormGroup className='col'>
                                                <Label className='form-control-label'>
                                                    {intl.formatMessage(
                                                        messages.labelVoicemailEmailAddress
                                                    )}
                                                </Label>
                                                <TextInput
                                                    type='email'
                                                    onValueChange={(value: string) =>
                                                        setCurrentExtension({
                                                            ...currentExtension,
                                                            voicemail: {
                                                                ...currentExtension.voicemail,
                                                                email: {
                                                                    ...currentExtension.voicemail
                                                                        .email,
                                                                    address: value,
                                                                },
                                                            },
                                                        })
                                                    }
                                                    placeholder={intl.formatMessage(
                                                        messages.placeholderVoicemailEmailAddress
                                                    )}
                                                    value={
                                                        currentExtension.voicemail?.email.address
                                                    }
                                                />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup
                                                className={
                                                    'col d-flex flex-row justify-content-between align-items-center pl-3'
                                                }
                                                check
                                            >
                                                <CheckboxInput
                                                    className={
                                                        'w-100 d-flex justify-content-between'
                                                    }
                                                    label={intl.formatMessage(
                                                        messages.labelVoicemailDelete
                                                    )}
                                                    enabled={
                                                        currentExtension.voicemail.email.address
                                                            .length > 0
                                                    }
                                                    onValueChange={() =>
                                                        setCurrentExtension({
                                                            ...currentExtension,
                                                            voicemail: {
                                                                ...currentExtension.voicemail,
                                                                email: {
                                                                    ...currentExtension.voicemail
                                                                        .email,
                                                                    delete: !currentExtension
                                                                        .voicemail.email.delete,
                                                                },
                                                            },
                                                        })
                                                    }
                                                    value={currentExtension.voicemail?.email.delete}
                                                />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup
                                                className={
                                                    'col d-flex flex-row justify-content-between align-items-center pl-3 mt-2'
                                                }
                                                check
                                            >
                                                <CheckboxInput
                                                    className={
                                                        'w-100 d-flex justify-content-between'
                                                    }
                                                    label={intl.formatMessage(
                                                        messages.sendAttachment
                                                    )}
                                                    enabled={
                                                        currentExtension.voicemail.email.address
                                                            .length > 0
                                                    }
                                                    onValueChange={() =>
                                                        setCurrentExtension({
                                                            ...currentExtension,
                                                            voicemail: {
                                                                ...currentExtension.voicemail,
                                                                email: {
                                                                    ...currentExtension.voicemail
                                                                        .email,
                                                                    attach: !currentExtension
                                                                        .voicemail.email.attach,
                                                                },
                                                            },
                                                        })
                                                    }
                                                    value={currentExtension.voicemail?.email.attach}
                                                />
                                            </FormGroup>
                                        </Row>
                                    </FormSection>
                                </FormSection>
                                <FormSection
                                    icon={faShare}
                                    isOpenByDefault={defaultOpenSections.callForwardSettings}
                                    title={intl.formatMessage(messages.callForwardSettings)}
                                    component={
                                        <CheckboxInput
                                            onValueChange={() =>
                                                setCurrentExtension({
                                                    ...currentExtension,
                                                    follow_me: {
                                                        ...currentExtension.follow_me,
                                                        enabled:
                                                            !currentExtension.follow_me?.enabled,
                                                    },
                                                })
                                            }
                                            value={currentExtension.follow_me?.enabled}
                                        />
                                    }
                                    paddingLeft={'25px'}
                                >
                                    <FormGroup row check>
                                        <Label className='form-control-label'>
                                            {intl.formatMessage(messages.preRingTime)}
                                        </Label>
                                        <TextInput
                                            className='pr-3 mb-3'
                                            maxLength={2}
                                            type='number'
                                            name='pre_ringtime'
                                            onValueChange={(value: string) => {
                                                setCurrentExtension({
                                                    ...currentExtension,
                                                    follow_me: {
                                                        ...currentExtension.follow_me,
                                                        pre_ringtime: limitValue(
                                                            Number(value),
                                                            60,
                                                            0
                                                        ),
                                                    },
                                                });
                                            }}
                                            value={currentExtension.follow_me.pre_ringtime.toString()}
                                        />
                                    </FormGroup>
                                    <FormGroup row check>
                                        <Label className='form-control-label'>
                                            {intl.formatMessage(messages.groupRingTime)}
                                        </Label>
                                        <TextInput
                                            className='pr-3 mb-3'
                                            maxLength={2}
                                            type='number'
                                            name='group_ringtime'
                                            onValueChange={(value: string) => {
                                                setCurrentExtension({
                                                    ...currentExtension,
                                                    follow_me: {
                                                        ...currentExtension.follow_me,
                                                        group_ringtime: limitValue(
                                                            Number(value),
                                                            60,
                                                            0
                                                        ),
                                                    },
                                                });
                                            }}
                                            value={currentExtension.follow_me.group_ringtime.toString()}
                                        />
                                    </FormGroup>
                                    <FormGroup row check>
                                        <span
                                            className='m-0 form-control-label'
                                            style={{fontWeight: '600 !important'}}
                                        >
                                            {intl.formatMessage(messages.ringStrategy)}
                                        </span>
                                        <SelectInput
                                            className='pr-3'
                                            onValueChange={(value: string) =>
                                                setCurrentExtension({
                                                    ...currentExtension,
                                                    follow_me: {
                                                        ...currentExtension.follow_me,
                                                        strategy: value,
                                                    },
                                                })
                                            }
                                            options={ringStrategy(intl).map((ring) => ({
                                                label: ring.name,
                                                value: ring.value,
                                            }))}
                                            value={currentExtension.follow_me.strategy}
                                            infoMessage={intl.formatMessage(messages.strategyInfo)}
                                        />
                                    </FormGroup>
                                    <FormSection
                                        isOpenByDefault={false}
                                        title={intl.formatMessage(messages.redirectNumber)}
                                        titleClass={'small'}
                                        paddingLeft={'6px'}
                                    >
                                        <div className='d-flex justify-content-between'>
                                            <TextInput
                                                type='string'
                                                maxLength={10}
                                                onValueChange={(value: string) => {
                                                    if (!notNumber(value)) setNumber(value);
                                                }}
                                                value={number}
                                                className='mr-2'
                                            />
                                            <Button
                                                color={'primary'}
                                                size={'sm'}
                                                onClick={() => addNumber()}
                                                disabled={!number}
                                            >
                                                {intl.formatMessage(messages.add)}
                                            </Button>
                                        </div>
                                        {currentExtension.follow_me.numbers.length > 0 && (
                                            <div
                                                style={{
                                                    overflowY: 'auto',
                                                    height:
                                                        currentExtension.follow_me.numbers.length *
                                                            30 +
                                                        1,
                                                }}
                                                className={'d-flex flex-column flex-grow-1 mt-2'}
                                            >
                                                <Table
                                                    columns={numbers_columns}
                                                    handles={{
                                                        currentExtension,
                                                        setCurrentExtension,
                                                    }}
                                                    head={false}
                                                    items={currentExtension.follow_me.numbers}
                                                    itemSize={30}
                                                />
                                            </div>
                                        )}
                                    </FormSection>
                                </FormSection>
                                {!!tempExtension && (
                                    <FormSection
                                        icon={faCog}
                                        isOpenByDefault={defaultOpenSections.advancedOpen}
                                        title={intl.formatMessage(messages.advanced)}
                                    >
                                        <Card>
                                            <CardBody>
                                                <h2 className={'text-danger'}>
                                                    {intl.formatMessage(messages.deleteExtension)}
                                                </h2>
                                                <CardText className={'text-muted'}>
                                                    <small>
                                                        {intl.formatMessage(
                                                            messages.deleteExtensionExplanation
                                                        )}
                                                    </small>
                                                </CardText>
                                                <Button
                                                    color={'danger'}
                                                    size={'sm'}
                                                    onClick={() =>
                                                        setDeleteExtensionModal(
                                                            !deleteExtensionModal
                                                        )
                                                    }
                                                >
                                                    {intl.formatMessage(messages.deleteExtension)}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </FormSection>
                                )}
                            </Nav>
                            <div className='d-flex justify-content-between mt-4'>
                                <Button
                                    color={'secondary'}
                                    size={'sm'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggle();
                                    }}
                                >
                                    {intl.formatMessage(messages.cancel)}
                                </Button>
                                <Button color={'primary'} size={'sm'} disabled={disabledForm}>
                                    {intl.formatMessage(messages.save)}
                                </Button>
                            </div>
                        </Form>
                    )}
                    <WarningSipModal
                        isOpen={warningSipGenerateModal}
                        toggleModal={() => {
                            setWarningSipGenerateModal(!warningSipGenerateModal);
                        }}
                        currentExtension={currentExtension}
                    />
                    <DeleteExtensionModal
                        isOpen={deleteExtensionModal}
                        toggleModal={() => {
                            setDeleteExtensionModal(!deleteExtensionModal);
                            toggle();
                        }}
                        extension={currentExtension.extension}
                    />
                </SidePanelContent>
            }
        </>
    );
};

export default ExtensionForm;
