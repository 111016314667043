import React from 'react';
import * as PropTypes from 'prop-types';
import {make_id} from '../../utils';
import {ConditionalWrapper} from '../utils/ConditionalWrapper';
import {Label} from 'reactstrap';

const CheckboxInput = ({className, enabled, id, inline, label, onValueChange, size, value}) => {
    const input_id = id ? id : make_id(10);

    return (
        <ConditionalWrapper
            condition={size === 'sm'}
            wrapper={(children) => (
                <small
                    className={`${className} ${
                        inline ? 'd-inline-flex' : 'd-flex'
                    }  flex-row align-items-center`}
                >
                    {children}
                </small>
            )}
        >
            <>
                {label && (
                    <Label className={'mr-2 mb-0'} for={input_id}>
                        {label}
                    </Label>
                )}
                <label className='custom-toggle'>
                    <input
                        type='checkbox'
                        checked={value}
                        disabled={!enabled}
                        onChange={(e) => onValueChange(e.target.checked)}
                    />
                    <span className='custom-toggle-slider rounded-circle' />
                </label>
            </>
        </ConditionalWrapper>
    );
};
CheckboxInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    enabled: PropTypes.bool,
    inline: PropTypes.bool,
    id: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['sm', 'md']),
    value: PropTypes.bool,
};

CheckboxInput.defaultProps = {
    className: '',
    enabled: true,
    label: '',
    inline: false,
    onValueChange: () => {},
    size: 'sm',
    value: false,
};

export default CheckboxInput;
