import React from 'react';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import ButtonsSelectInput from '../../input/ButtonsSelectInput';
import {status_color, status_icon, status_label} from '../../../utils/call_history';
import {useSelector} from 'react-redux';
import {getCallsStatuses} from '../../../store/selectors/cdr-selectors';
import PropTypes from 'prop-types';

export const messages = defineMessages({
    display: {
        id: 'message.status',
        defaultMessage: 'Status: {status}',
    },
    title: {
        id: 'filter.status.title',
        defaultMessage: 'Status',
    },
});

const factory = (status) => ({
    type: 'status',
    index: `status_${status}`,
    serialized: status,
    custom_filter: (query) => {
        query.filters.status = query.filters.status ? [status, ...query.filters.status] : [status];
        return query;
    },
    elastic_filter: (query) => query.filter('term', 'action.keyword', status),
    display: {
        icon: status_icon(status),
        color: status_color(status),
        content: <FormattedMessage {...messages.display} values={{status: status_label(status)}} />,
        intlContent: (intl) => (
            <FormattedMessage {...messages.display} values={{status: status_label(status, intl)}} />
        ),
    },
});

const StatusFilter = injectIntl(({intl, onValueChange, size}) => {
    const statuses = useSelector(getCallsStatuses);

    return (
        <ButtonsSelectInput
            onValueChange={(v) => onValueChange(v, true)}
            options={statuses.map((status) => ({
                color: status_color(status),
                icon: status_icon(status),
                label: status_label(status, intl),
                value: status,
            }))}
            size={size}
        />
    );
});

StatusFilter.propTypes = {
    onValueChange: PropTypes.func,
    size: PropTypes.string,
};

StatusFilter.defaultProps = {
    size: 'sm',
};

export const status_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: StatusFilter,
    auto: true,
    factory: factory,
    icon: faPhone,
};
