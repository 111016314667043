import React from 'react';
import {useSelector} from 'react-redux';
import {getUserLocale} from '../../store/selectors/user-selectors';
import moment from 'moment';

const CallTimestampCell = ({timestamp}) => {
    const locale = useSelector(getUserLocale);

    return (
        <>
            {moment(timestamp)
                .locale(locale ? locale : 'en')
                .format('LL, hh:mm A')}
        </>
    );
};

CallTimestampCell.propTypes = {};

export default CallTimestampCell;
