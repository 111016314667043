let UPPERCASE_REGEX = /[A-Z]/g;
let LOWERCASE_REGEX = /[a-z]/g;
let DIGIT_REGEX = /[0-9]/g;
let SYMBOL_REGEX = /[^A-zA-Z0-9]/g;


function number_of_characters(minimum_number_of_characters) {
    function number_of_characters(password) {
        return password.length >= minimum_number_of_characters;
    }

    number_of_characters.values = {
        name: 'number_of_characters',
        message: {
            minimum_number_of_characters: minimum_number_of_characters
        }
    };

    return number_of_characters;
}

const uppercase = (password) => {

    uppercase.values = {
        name: 'uppercase',
    };

    return password.match(UPPERCASE_REGEX);
};


const lowercase = (password) => {

    lowercase.values = {
        name: 'lowercase',
    };

    return password.match(LOWERCASE_REGEX);
};


const digit = (password) => {

    digit.values = {
        name: 'digit',
    };


    return password.match(DIGIT_REGEX);
};


const symbol = (password) => {

    symbol.values = {
        name: 'symbol'
    };

    return password.match(SYMBOL_REGEX);
};


const validators = {
    number_of_characters: number_of_characters,
    uppercase: uppercase,
    lowercase: lowercase,
    digit: digit,
    symbol: symbol
};

export {validators}
