import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import isEqual from 'lodash.isequal';
import {Has} from '../../security/Has';
import {getUserCustomerLicense} from '../../../store/selectors/user-selectors';

/** FONTAWESOME */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faLock} from '@fortawesome/free-solid-svg-icons';

/** FILTERS */
import {id_filter} from './Id';
import {date_filter} from './Date';
import {direction_filter} from './Direction';
import {status_filter} from './Status';
import {duration_filter} from './Duration';
import {extension_filter} from './Extension';
import {name_filter} from './Name';
import {external_number_filter} from './ExternalNumber';
import {internal_number_filter} from './InternalNumber';
import {tag_filter} from './Tag';

export const filters = {
    id: id_filter,
    date: date_filter,
    direction: direction_filter,
    status: status_filter,
    duration: duration_filter,
    extension: extension_filter,
    name: name_filter,
    external_number: external_number_filter,
    internal_number: internal_number_filter,
    tag: tag_filter,
};

export const filter_restrictions = {
    id: {},
    date: {},
    direction: {},
    status: {},
    duration: {},
    extension: {
        aPolicyMatching: [{resourceType: 'EXTENSIONS'}],
    },
    name: {},
    external_number: {},
    internal_number: {},
    tag: {
        // requiresTravel: true,
        // orElse: (
        //     <DropdownItem disabled>
        //         <FontAwesomeIcon fixedWidth icon={tag_filter.icon} />
        //         {tag_filter.title}
        //         <FontAwesomeIcon fixedWidth icon={faLock} className={'ml-2'} />
        //     </DropdownItem>
        // ),
    },
};

export const FilterFactory = ({
    auto,
    component,
    componentProps,
    add,
    cancel,
    factory,
    hideCancel,
    defaultValue,
}) => {
    let Component = component;

    const [value, setValue] = useState(undefined);
    const [valid, setValid] = useState();

    const addFilter = () => {
        add(factory(value));
        cancel();
    };

    const autoAddFilter = (value) => {
        add(factory(value));
        cancel();
    };

    const valueDispatch = (v, add = false) => {
        if (auto || add) {
            autoAddFilter(v);
            return;
        }
        if (!isEqual(value, v)) setValue(v);
    };

    const disableOk = defaultValue ? isEqual([defaultValue[0], defaultValue[1]], value) : null;

    return (
        <>
            <Component
                onEnter={(v) => addFilter(factory(v))}
                onEsc={cancel}
                onValidityChange={setValid}
                onValueChange={valueDispatch}
                value={value}
                defaultValue={defaultValue}
                {...componentProps}
            />
            {!auto && !hideCancel && (
                <FormattedMessage defaultMessage='Add' id='btn.add.name'>
                    {(m) => (
                        <Button
                            className='ml-1'
                            color='primary'
                            disabled={!valid}
                            onClick={addFilter}
                            size='sm'
                        >
                            {m}
                        </Button>
                    )}
                </FormattedMessage>
            )}
            {!hideCancel && (
                <FormattedMessage id='btn.cancel' defaultMessage='Cancel'>
                    {(m) => (
                        <Button onClick={cancel} color='secondary' size='sm'>
                            {m}
                        </Button>
                    )}
                </FormattedMessage>
            )}
            {!auto && hideCancel && (
                <FormattedMessage defaultMessage='OK' id='btn.ok'>
                    {(m) => (
                        <Button
                            className='ml-1'
                            color='primary'
                            disabled={disableOk}
                            onClick={addFilter}
                            size='sm'
                        >
                            {m}
                        </Button>
                    )}
                </FormattedMessage>
            )}
        </>
    );
};

FilterFactory.propTypes = {
    add: PropTypes.func.isRequired,
    auto: PropTypes.bool,
    cancel: PropTypes.func.isRequired,
    hideCancel: PropTypes.bool,
    component: PropTypes.any.isRequired,
    componentProps: PropTypes.object,
    factory: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
};

FilterFactory.defaultProps = {
    auto: false,
};

const FilterFactories = ({addFilter, setActive}) => {
    const [activeFilterType, setActiveFilterType] = useState(null);
    const [toggle, setToggle] = useState(false);
    const customerLicense = useSelector(getUserCustomerLicense);

    useEffect(() => {
        setActive(activeFilterType != null);
    }, [activeFilterType, setActive]);

    return (
        <div className='d-flex flex-grow-1 ml-3 align-items-center'>
            <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)}>
                <DropdownToggle
                    className={'btn-sm'}
                    color={'primary'}
                    onClick={() => setToggle(!toggle)}
                    data-toggle='dropdown'
                >
                    <FontAwesomeIcon
                        className={'mr-1'}
                        icon={activeFilterType ? filters[activeFilterType].icon : faFilter}
                    />
                    {activeFilterType ? (
                        filters[activeFilterType].title
                    ) : (
                        <FormattedMessage id='btn.add.filter' defaultMessage='Add Filter' />
                    )}
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(filters).map((type, index) => {
                        const restriction =
                            type === 'tag' &&
                            (customerLicense === 'PREMIUM' || customerLicense === 'PRO');

                        return (
                            filters[type].component && (
                                <Has key={index} {...filter_restrictions[type]}>
                                    <DropdownItem
                                        onClick={() => setActiveFilterType(type)}
                                        disabled={restriction}
                                    >
                                        <FontAwesomeIcon fixedWidth icon={filters[type].icon} />
                                        {filters[type].title}
                                        {restriction && (
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faLock}
                                                className={'ml-2'}
                                            />
                                        )}
                                    </DropdownItem>
                                </Has>
                            )
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
            {activeFilterType && (
                <div className='d-flex flex-row flex-grow-1 mr-3'>
                    <FilterFactory
                        add={addFilter}
                        auto={filters[activeFilterType].auto}
                        cancel={() => setActiveFilterType(null)}
                        component={filters[activeFilterType].component}
                        componentProps={filters[activeFilterType].componentProps}
                        factory={filters[activeFilterType].factory}
                    />
                </div>
            )}
        </div>
    );
};

FilterFactories.propTypes = {
    addFilter: PropTypes.func.isRequired,
    setActive: PropTypes.func,
};

FilterFactories.defaultProps = {
    setActive: () => {},
};

export default FilterFactories;
