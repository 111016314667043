import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {getUser} from '../../../store/selectors/user-selectors';
import whats_new from '../../../assets/images/whats_new.png';
import {FormattedMessage} from 'react-intl';
import {getRelevantUpdates} from '../../../utils/updates';
import moment from 'moment';
import {useLocalStorage} from 'react-use';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import WhatsNewChange from './WhatsNewChangelistItem';
import WhatsNewChangelog from './WhatsNewChangelist';
import {ConditionalWrapper} from '../../utils/ConditionalWrapper';
import {Has} from '../../security/Has';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const WhatsNew = () => {
    const user = useSelector(getUser);

    const updates = useMemo(
        () => (user ? getRelevantUpdates(user.last_active, user.language.code) : []),
        [user]
    );

    const [gotIt, updateGotIt] = useLocalStorage('croo-whats-new-got-it');
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(updates.length > 0 && (gotIt == null || !moment(gotIt).isSame(moment(), 'day')));
    }, [updates.length, gotIt]);

    return (
        <>
            <Modal isOpen={isOpen} centered scrollable size={'lg'}>
                <ModalHeader>
                    <span className={'pr-2'} role={'img'} aria-label={'sparkles'}>
                        ✨
                    </span>
                    <FormattedMessage id={'modal.title.whats.new'} defaultMessage={"What's new?"} />
                    <span className={'pl-2'} role={'img'} aria-label={'sparkles'}>
                        ✨
                    </span>
                </ModalHeader>
                <ModalBody className={'d-flex flex-column'} style={{textAlign: 'justify'}}>
                    <img
                        src={whats_new}
                        alt={"What's New?"}
                        className={'mb-3 align-self-center'}
                        style={{width: '40%'}}
                    />
                    <span className={'mb-3'}>
                        <FormattedMessage
                            id={'message.access.knowledge.base'}
                            defaultMessage={
                                'Here is a list of changes on the Croo Portal since your last login. As always, you can browse the {link} to get in-detail guides and information about the Croo Portal and its features.'
                            }
                            values={{
                                link: (
                                    <a
                                        href={'http://croo.link/3aSYI3N'}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <FormattedMessage
                                            id={'message.croo.knowledge.base'}
                                            defaultMessage={'Croo Knowledge Base'}
                                        />
                                        <FontAwesomeIcon
                                            icon={faExternalLinkAlt}
                                            className={'mx-1'}
                                        />
                                    </a>
                                ),
                            }}
                        />
                    </span>
                    {updates.map((update) => (
                        <React.Fragment key={update.date}>
                            <h5>{moment(update.date).format('LL')}</h5>
                            <WhatsNewChangelog>
                                {update.changelog.map((change, index) => (
                                    <ConditionalWrapper
                                        key={index}
                                        condition={!!change.has}
                                        wrapper={(children) => (
                                            <Has {...change.has}>{children}</Has>
                                        )}
                                    >
                                        <WhatsNewChange title={change.title}>
                                            {change.content}
                                        </WhatsNewChange>
                                    </ConditionalWrapper>
                                ))}
                            </WhatsNewChangelog>
                        </React.Fragment>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={'primary'}
                        size={'sm'}
                        onClick={() => {
                            setOpen(false);
                            updateGotIt(moment().startOf('day').toISOString());
                        }}
                    >
                        <FormattedMessage id={'btn.got.it'} defaultMessage={'Got it!'} />
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

WhatsNew.propTypes = {};

export default WhatsNew;
