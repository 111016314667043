import {cyrb53} from './index';

// noinspection JSUnusedGlobalSymbols
export const CROO_COLORS = {
    pink: '#E0007A',
    blue: '#404cff',
    orange: '#fcba5b',
    turquoise: '#76e0d5',
};

export const CROO_DATA_VIZ_COLORS = ['#E0007A', '#B16BDB', '#3FA4FF', '#00C8F4', '#76E0D5'];

export const lighten = (color, by) => {
    let usePound = false;

    if (color[0] === '#') {
        color = color.slice(1);
        usePound = true;
    }

    const as_int = parseInt(color, 16);

    let red = (as_int >> 16) + by;

    if (red > 255) red = 255;
    else if (red < 0) red = 0;

    let blue = ((as_int >> 8) & 0x00ff) + by;

    if (blue > 255) blue = 255;
    else if (blue < 0) blue = 0;

    let green = (as_int & 0x0000ff) + by;

    if (green > 255) green = 255;
    else if (green < 0) green = 0;

    return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
};

export const darken = (color, by) => {
    let usePound = false;

    if (color[0] === '#') {
        color = color.slice(1);
        usePound = true;
    }

    const as_int = parseInt(color, 16);

    let red = (as_int >> 16) - by;

    if (red > 255) red = 255;
    else if (red < 0) red = 0;

    let blue = ((as_int >> 8) & 0x00ff) - by;

    if (blue > 255) blue = 255;
    else if (blue < 0) blue = 0;

    let green = (as_int & 0x0000ff) - by;

    if (green > 255) green = 255;
    else if (green < 0) green = 0;

    return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
};

export const generate_palette = (length) => {
    let base = CROO_DATA_VIZ_COLORS;

    if (length <= base.length) {
        return base.slice(0, length);
    }

    for (let i = 0; i < length / base.length; i++) {
        base = base.concat(base.map((c) => lighten(c, i * 30)));
    }

    return base.slice(0, length);
};

export const color_from_string = (string, seed = 0, palette_length = 4) => {
    if (typeof seed == 'string') seed = cyrb53(seed);
    const palette = generate_palette(palette_length);
    return palette[cyrb53(string, seed) % palette_length];
};
