import moment from "moment";
import {date_filter} from "../../call/filters/Date";

export const defaultLayouts = {
    "Default": [
        {
            "title": "Calls by Agent and Status",
            "dataType": "widget.bar.calls_by_agent_per_status",
            "filters": {},
            "extras": {},
            "i": "1",
            "order": 0,
            "width": 2,
            "height": 1
        },
        {
            "title": "Inbound Calls",
            "dataType": "widget.value.calls_amount",
            "filters": {
                "direction": {
                    "type": "direction",
                    "index": "direction",
                    "serialized": "in",
                }
            },
            "extras": {
                "item": {
                    "key": "All Calls",
                    "text": "All Calls",
                    "value": 87
                }
            },
            "i": "4",
            "order": 2,
            "width": 1,
            "height": 1
        },
        {
            "title": "Calls by Tag",
            "dataType": "widget.pie.calls_by_tags",
            "filters": {},
            "extras": {},
            "i": "5",
            "order": 3,
            "width": 1,
            "height": 1
        },
        {
            "title": "Calls by Language",
            "dataType": "widget.pie.calls_by_language",
            "filters": {},
            "extras": {},
            "i": "2",
            "order": 4,
            "width": 1,
            "height": 1
        },
        {
            "title": "Calls by Day",
            "dataType": "widget.line.calls_by_day",
            "filters": {},
            "extras": {},
            "i": "3",
            "order": 5,
            "width": 1,
            "height": 1
        }
    ]
};

export const defaultMasterFilters = {
    date: date_filter.factory([moment().subtract(1, 'month'), moment()])
};