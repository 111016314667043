import React from 'react';

export const ConditionalWrapper: ({
    condition,
    wrapper,
    children,
}: {
    condition: boolean;
    wrapper: (children: React.ReactNode) => React.ReactNode;
    children: React.ReactNode;
}) => any = ({condition, wrapper, children}) => (condition ? wrapper(children) : children);
