import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import WidgetExtrasForm from './WidgetExtrasForm';
import {useSelector} from 'react-redux';
import {getWidgetData} from '../../store/selectors/cdr-selectors';
import {getCustomerExtensions, getCustomerQueues} from '../../store/selectors/customer-selectors';
import {getParsedData, getRawData, getWidgetOptions} from './utils/studioFunctions';
import {getLoading} from '../../store/selectors/portal-selectors';
import {getUserLocale} from '../../store/selectors/user-selectors';
import Spinner from '../spinner/Spinner';
import {Input, Label} from 'reactstrap';

const WidgetStudioForm = injectIntl(({intl, dataType, title, setTitle, extras, addExtra}) => {
    const widgetOptions = getWidgetOptions(dataType);

    const extensions = useSelector(getCustomerExtensions);
    const queues = useSelector(getCustomerQueues);
    const locale = useSelector(getUserLocale);
    const rawData = getRawData(widgetOptions, useSelector(getWidgetData)['-1']);
    const loading = useSelector(getLoading('-1'));

    return (
        <>
            <div className={'ml-2'} style={{overflowY: 'auto'}}>
                <Label className='form-control-label'>
                    <FormattedMessage
                        id='dashboard.studio.widget_title'
                        defaultMessage='Widget title'
                    />
                </Label>
                <Input
                    size='sm'
                    type='text'
                    value={title}
                    onChange={(e) => {
                        e.preventDefault();
                        setTitle(e.target.value);
                    }}
                    placeholder={intl.formatMessage({
                        id: 'dashboard.studio.widget_title',
                        defaultMessage: 'Widget title',
                    })}
                />
            </div>
            {widgetOptions.header &&
                (!loading ? (
                    <WidgetExtrasForm
                        data={
                            widgetOptions.data.type === 'queue'
                                ? queues
                                : getParsedData(widgetOptions, rawData, extensions, locale)
                        }
                        type={widgetOptions.data.type}
                        extras={extras}
                        addExtra={addExtra}
                        options={widgetOptions.header}
                    />
                ) : (
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                ))}
        </>
    );
});

export default WidgetStudioForm;
