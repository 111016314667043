import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

interface DangerModalProps {
    content?: any;
    isOpen?: boolean;
    onClick?: any;
    onClosed?: any;
    title?: any;
    toggle?: () => void;
    btnTitle?: string;
}

const DangerModal = ({
    content,
    isOpen,
    onClick,
    onClosed = () => {},
    title = '',
    toggle,
    btnTitle,
}: DangerModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            className={'modal-danger modal-dialog-centered'}
        >
            <div className={'modal-content bg-gradient-primary'}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>{content}</ModalBody>
                <ModalFooter>
                    <FormattedMessage id='btn.cancel' defaultMessage={`Cancel`}>
                        {(m) => (
                            <Button
                                color={'link'}
                                className={'text-white ml-auto'}
                                onClick={toggle}
                            >
                                {m}
                            </Button>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id={btnTitle} defaultMessage={'Validate'}>
                        {(m) => (
                            <Button color={'white'} onClick={onClick}>
                                {m}
                            </Button>
                        )}
                    </FormattedMessage>
                </ModalFooter>
            </div>
        </Modal>
    );
};

DangerModal.defaultProps = {
    btnTitle: 'btn.validate',
};

export default DangerModal;
