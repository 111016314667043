import React from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import DangerModal from 'components/notification/DangerModal';
import {deleteCustomerExtension} from 'store/actions/customer-action';
import {messages} from '../extensionTraduction';

const DeleteExtensionModal = ({
    isOpen,
    toggleModal,
    extension,
}: {
    isOpen: boolean;
    toggleModal: () => void;
    extension: string;
}) => {
    const intl = useIntl();
    const reduxDispatch = useDispatch();
    const deleteExtension = () => {
        reduxDispatch(deleteCustomerExtension(extension));
        toggleModal();
    };
    return (
        <DangerModal
            isOpen={isOpen}
            toggle={() => toggleModal()}
            title={intl.formatMessage(messages.deleteExtension)}
            content={intl.formatMessage(messages.deleteExtensionConfirmation)}
            onClick={() => deleteExtension()}
        />
    );
};

export default DeleteExtensionModal;
