import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {formatItem} from './utils/studioFunctions';
import SelectInput from '../input/SelectInput';
import {Input, Label} from 'reactstrap';

const formats = [
    {key: 'number', text: 'Actual Value'},
    {key: 'percentage', text: 'Percentage'},
];

const checkData = (data, type) => {
    if (type === 'queue') return data && data.length > 0 && data.map;
    else return data && data.length > 1 && data.map;
};

const extractFormat = (format) => {
    if (format) return format;
    else return null;
};

const extractItems = (extras, multi) => {
    if (multi) {
        if (extras.items) {
            return extras.items;
        } else return [];
    } else {
        if (extras.item) return extras.item;
        else return null;
    }
};

const WidgetExtrasForm = injectIntl(({intl, data, type, extras, addExtra, options}) => {
    return (
        <>
            {options.format ? (
                <div className={'d-flex flex-column ml-2 mt-2'}>
                    <Label className='form-control-label'>
                        <FormattedMessage
                            id='dashboard.studio.widget_format'
                            defaultMessage='Measure progression by'
                        />
                    </Label>
                    <SelectInput
                        options={formats.map((format) => ({label: format.text, value: format}))}
                        onValueChange={(value) => {
                            addExtra({index: 'format', value});
                        }}
                        value={extras ? extractFormat(extras.format) : null}
                        placeholder={intl.formatMessage({
                            id: 'dashboard.studio.select',
                            defaultMessage: 'Select...',
                        })}
                    />
                </div>
            ) : null}
            {options.item && checkData(data, type) ? (
                <div className={'d-flex flex-column ml-2 mt-2'}>
                    <Label className='form-control-label'>
                        <FormattedMessage
                            id='dashboard.studio.widget_item'
                            defaultMessage='View for'
                        />
                    </Label>
                    <SelectInput
                        multi={options.multi}
                        options={data.map((item) => ({label: formatItem(type, item), value: item}))}
                        placeholder={intl.formatMessage({
                            id: 'dashboard.studio.select',
                            defaultMessage: 'Select...',
                        })}
                        onValueChange={(value) => {
                            addExtra({
                                index: options.multi ? 'items' : 'item',
                                value,
                            });
                        }}
                        value={extras ? extractItems(extras, options.multi) : null}
                    />
                </div>
            ) : null}
            {options.max ? (
                <div className={'ml-2 mt-2'}>
                    <Label className='form-control-label'>
                        <FormattedMessage
                            id='dashboard.studio.widget_max'
                            defaultMessage='Goal to reach'
                        />
                    </Label>
                    <Input
                        size='sm'
                        type='text'
                        value={extras ? extras.max : 0}
                        onChange={(e) => {
                            e.preventDefault();
                            addExtra({index: 'max', value: e.target.value});
                        }}
                        placeholder={intl.formatMessage({
                            id: 'dashboard.studio.max',
                            defaultMessage: 'Goal',
                        })}
                    />
                </div>
            ) : null}
        </>
    );
});

export default WidgetExtrasForm;
