import React, {useEffect, useReducer, useState} from 'react';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import ContentWrapper from '../../../components/content/ContentWrapper';
import CardContent from '../../../components/content/CardContent';
import SidePanel from '../../../components/panel/SidePanel';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomerTeams} from '../../../store/selectors/customer-selectors';
import {fetchCustomerTeams, fetchCustomerUsers} from '../../../store/actions/customer-action';
import GridList from '../../../components/layout/GridList/GridList';
import SearchBar from '../../../components/search/SearchBar';
import isEqual from 'lodash.isequal';
import LightFontAwesomeIcon from '../../../components/utils/LightFontAwesomeIcon';
import {getLoading} from '../../../store/selectors/portal-selectors';
import Spinner from '../../../components/spinner/Spinner';
import {getUser, getUserTravel} from '../../../store/selectors/user-selectors';
import TeamCard from '../../../components/team/TeamCard';
import TeamForm from './TeamForm';
import {Has, hasPolicy} from '../../../components/security/Has';

const Teams = () => {
    const teams = useSelector(getCustomerTeams);
    const loading = useSelector(getLoading('teams'));
    const travel = useSelector(getUserTravel);
    const currentUser = useSelector(getUser);

    const reduxDispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const [team, setTeam] = useState(null);
    const [foundTeams, foundTeamsDispatch] = useReducer((state, action) => {
        const found = action.payload;
        return isEqual(state, found) ? state : found;
    }, teams);

    useEffect(() => {
        reduxDispatch(fetchCustomerTeams());
        reduxDispatch(fetchCustomerUsers());
    }, [reduxDispatch, travel]);

    const cancel = () => {
        setTeam(null);
        setModal(false);
    };

    const edit = (team) => {
        setTeam(team);
        setModal(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='d-flex flex-row align-items-baseline border-bottom-0'>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage id='title.table.teams' defaultMessage='Teams' />
                        </h2>
                        <Has aPolicyMatching={[{action: 'WRITE', resourceType: 'TEAMS'}]}>
                            <Button
                                className={'mr-auto align-self-center'}
                                color={'primary'}
                                onClick={() => edit(null)}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                        <FormattedMessage id={'input.placeholder.search'} defaultMessage={'Search'}>
                            {(msg) => (
                                <SearchBar
                                    className={'mr-auto'}
                                    fields={['name', 'description']}
                                    items={teams}
                                    live
                                    minimumCharacters={3}
                                    setFoundItems={(f) => foundTeamsDispatch({payload: f})}
                                    placeholder={msg}
                                />
                            )}
                        </FormattedMessage>
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1'}
                    >
                        {loading ? (
                            <Spinner
                                centered
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <GridList
                                items={foundTeams}
                                itemComponent={(item) => (
                                    <TeamCard
                                        key={item.email}
                                        actionComponent={
                                            <Has
                                                aPolicyMatching={[
                                                    {action: 'WRITE', resourceType: 'TEAMS'},
                                                ]}
                                            >
                                                <Button
                                                    onClick={() => edit(item)}
                                                    color={'primary'}
                                                    size={'sm'}
                                                >
                                                    <LightFontAwesomeIcon
                                                        icon={'pen'}
                                                        className={'mr-1'}
                                                    />
                                                    <FormattedMessage
                                                        id={'btn.edit'}
                                                        defaultMessage={'Edit'}
                                                    />
                                                </Button>
                                            </Has>
                                        }
                                        className={'mb-2 with-shadow'}
                                        onClick={
                                            hasPolicy(currentUser, 'write', 'users')
                                                ? () => edit(item)
                                                : null
                                        }
                                        team={item}
                                        style={{minHeight: 211, maxHeight: 211}}
                                    />
                                )}
                                itemKey={(item) => item.id}
                                itemWidth={300}
                                itemHeight={221}
                                style={{overflowX: 'visible'}}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                size={50}
                isOpen={modal}
                setOpen={setModal}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <TeamForm team={team} toggle={cancel} />
            </SidePanel>
        </>
    );
};

export default Teams;
