import React from 'react';
import {useSelector} from 'react-redux';
import {getLoadings} from '../../store/selectors/portal-selectors';
import Spinner from '../spinner/Spinner';

const Working = () => {
    const loadings = useSelector(getLoadings);

    return (
        Object.values(loadings).some((v) => v === true) && (
            <small className={'d-flex flex-row align-items-center'}>
                <Spinner
                    className={'mr-2'}
                    color={'primary'}
                    background={'transparent'}
                    global={false}
                    size={25}
                />
                Working...
            </small>
        )
    );
};

Working.propTypes = {};

export default Working;
