import List from '../List/List';
import React from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';

const generateIndexesForRow = (rowIndex, maxItemsPerRow, itemsAmount) => {
    const result = [];
    const startIndex = rowIndex * maxItemsPerRow;
    for (let i = startIndex; i < Math.min(startIndex + maxItemsPerRow, itemsAmount); i++) {
        result.push(i);
    }
    return result;
};

const rowRendererFactory =
    (itemSize, size) =>
    ({data, index, style}) => {
        const {items, itemComponent, LoadingComponent} = data;
        const maxItemsPerRow = getMaxItemsAmountPerRow(size, itemSize);
        const rowComponents = generateIndexesForRow(index, maxItemsPerRow, items.length).map((i) =>
            itemComponent(items[i])
        );

        const gridStyle = {
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(${itemSize}px, ${1 / maxItemsPerRow}fr))`,
            gridGap: '10px',
        };

        return index < data.itemCount && index >= 0 ? (
            <div style={{...style, ...gridStyle}}>{rowComponents}</div>
        ) : (
            LoadingComponent
        );
    };

const getMaxItemsAmountPerRow = (size, itemSize) => Math.max(Math.floor(size / itemSize), 1);

const getRowsAmount = (size, itemSize, itemsAmount) => {
    const maxItemsPerRow = getMaxItemsAmountPerRow(size, itemSize);
    return Math.ceil(itemsAmount / maxItemsPerRow);
};

const GridList = ({
    className,
    layout,
    itemComponent,
    itemKey,
    itemHeight,
    itemWidth,
    items,
    infinity,
    loadingComponent,
    style,
}) => {
    const loadMore = (start) => {
        if (infinity && !infinity.isLoadingMore) infinity.loadMore(start);
    };

    return (
        <AutoSizer>
            {({width, height}) => {
                const rowCount = getRowsAmount(
                    layout === 'horizontal' ? height : width,
                    layout === 'horizontal' ? itemHeight : itemWidth,
                    items.length
                );

                const rowRenderer = rowRendererFactory(
                    layout === 'horizontal' ? itemHeight : itemWidth,
                    layout === 'horizontal' ? height : width
                );

                const rowKey = (index, itemData) => {
                    const itemsPerRow = Math.ceil(itemData.items.length / itemData.itemCount);
                    return itemData.items[index * itemsPerRow]
                        ? itemKey(itemData.items[index * itemsPerRow])
                        : index;
                };

                return (
                    <div className={'d-flex flex-column'}>
                        <List
                            className={`flex-grow-1 croo-table-list-container ${className}`}
                            hasMore={infinity ? infinity.hasMore : false}
                            height={height}
                            infinite={!!infinity}
                            isLoadingMore={infinity ? infinity.isLoadingMore : undefined}
                            itemCount={rowCount}
                            itemData={{
                                infinity,
                                items,
                                itemComponent,
                                itemCount: rowCount,
                                LoadingComponent: loadingComponent,
                            }}
                            items={items}
                            itemKey={rowKey}
                            itemRenderer={rowRenderer}
                            itemSize={layout === 'horizontal' ? itemWidth : itemHeight}
                            layout={layout}
                            loadMore={loadMore}
                            minimumBatchSize={infinity ? infinity.minimumBatchSize : undefined}
                            style={style}
                            threshold={infinity ? infinity.threshold : undefined}
                            width={width}
                        />
                    </div>
                );
            }}
        </AutoSizer>
    );
};

GridList.propTypes = {
    className: PropTypes.string,
    itemKey: PropTypes.func.isRequired,
    itemWidth: PropTypes.number.isRequired,
    itemHeight: PropTypes.number.isRequired,
    items: PropTypes.array,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    onSort: PropTypes.func,
    infinity: PropTypes.shape({
        hasMore: PropTypes.bool,
        isLoadingMore: PropTypes.bool,
        loadMore: PropTypes.func.isRequired,
        minimumBatchSize: PropTypes.number,
        threshold: PropTypes.number,
    }),
    style: PropTypes.object,
};

GridList.defaultProps = {
    className: '',
    direction: 'vertical',
    items: [],
    style: {},
};

export default GridList;
