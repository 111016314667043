import React, {useCallback} from 'react';
import Select from 'react-select';
import isEqual from 'lodash.isequal';
import CreatableSelect from 'react-select/creatable';
import {FormText} from 'reactstrap';

interface SelectInputProps {
    className?: string;
    enabled?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    multi?: boolean;
    onValueChange?: any;
    onValidityChange?: any;
    options: {
        label: string;
        value: any;
    }[];
    placeholder?: string;
    searchable?: boolean;
    validator?: any;
    value?: any;
    reactSelectProps?: any;
    menuPortalTarget?: any;
    infoMessage?: string;
    isLoading?: boolean;
}

const SelectInput = ({
    className,
    enabled,
    isCreatable,
    isClearable,
    isSearchable,
    menuPortalTarget,
    multi,
    onValidityChange,
    onValueChange,
    options,
    placeholder,
    reactSelectProps,
    searchable,
    validator,
    value,
    infoMessage,
    isLoading,
}: SelectInputProps) => {
    const valueToOption = useCallback(
        (v: any) => {
            if (multi) {
                if (v == null) return [];
                const matches = options.filter(({value}) =>
                    v.some((item: any) => isEqual(item, value))
                );
                return isEqual(matches, v) ? v : matches;
            } else {
                if (v == null) return {value: '', label: placeholder};
                const item = options.find(({value}) => isEqual(v, value));
                return item ? item : {value: '', label: placeholder};
            }
        },
        [multi, options, placeholder]
    );

    const onChange = (v: any) => {
        if (v) {
            onValueChange(multi ? v.map((v: any) => v.value) : v.value);
            onValidityChange(validator(multi ? v.map((v: any) => v.value) : v.value));
        } else {
            onValueChange(multi ? [] : null);
            onValidityChange(multi ? [] : null);
        }
    };

    const Component = isCreatable ? CreatableSelect : Select;

    return (
        <>
            <Component
                {...reactSelectProps}
                className={`flex-grow-1 ${className}`}
                classNamePrefix={'react-select'}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={!enabled}
                isMulti={multi}
                isLoading={isLoading}
                menuPlacement={'auto'}
                menuPortalTarget={menuPortalTarget}
                onChange={onChange}
                options={options}
                searchable={searchable}
                placeholder={placeholder}
                theme={{
                    spacing: {
                        baseUnit: 3,
                        controlHeight: 32,
                        menuGutter: 6,
                    },
                    colors: {
                        primary: '#E0007A',
                        primary25: '#f6f9fc',
                        primary50: '#ffffff',
                        primary75: '#ffffff',
                        neutral0: '#ffffff',
                        neutral100: '#000000',
                    },
                }}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: '#dee2e6',
                        color: '#8898aa',
                    }),
                    menu: (provided) => ({...provided, zIndex: 2000}),
                }}
                value={valueToOption(value)}
            />
            {infoMessage && <FormText>{infoMessage}</FormText>}
        </>
    );
};

SelectInput.defaultProps = {
    className: '',
    enabled: true,
    isCreatable: false,
    isClearable: false,
    isSearchable: true,
    menuPortalTarget: undefined,
    multi: false,
    onValidityChange: () => true,
    searchable: false,
    placeholder: 'Select...',
    validator: () => true,
    reactSelectProps: {},
};

export default SelectInput;
