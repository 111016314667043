import React from 'react';
import {getPortalAudio} from '../../../store/selectors/portal-selectors';
import {useDispatch, useSelector} from 'react-redux';
import {hideAudio} from '../../../store/actions/portal-action';
import AudioPlayer from './AudioPlayer';
import EntranceExitAnimation from '../../animation/EntranceExitAnimation';
import LightFontAwesomeIcon from '../../utils/LightFontAwesomeIcon';

const AudioOverlay = () => {
    const audio = useSelector(getPortalAudio);

    const reduxDispatch = useDispatch();

    return (
        <EntranceExitAnimation
            entrance={'fadeInUp'}
            exit={'fadeOutDown'}
            show={audio.show || false}
            speed={'faster'}
        >
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 10,
                }}
                className={'w-100 center'}
            >
                <span
                    style={{zIndex: 12, position: 'absolute', right: '5px'}}
                    onClick={() => reduxDispatch(hideAudio())}
                    className={'cursor-pointer'}
                >
                    <LightFontAwesomeIcon icon={'times'} />
                </span>
                <AudioPlayer {...audio} />
            </div>
        </EntranceExitAnimation>
    );
};

export default AudioOverlay;
