import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';

// ReactStrap
import {Button, Card, CardBody, CardDeck, CardHeader, CardText, CardTitle, Row} from 'reactstrap';

// Input
import TextInput from 'components/input/TextInput';

// Components
import ContentWrapper from 'components/content/ContentWrapper';
import Spinner from 'components/spinner/Spinner';
import DangerModal from '../../components/notification/DangerModal';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faMobile,
    faPhone,
    faUser,
    IconDefinition,
    faClipboard,
} from '@fortawesome/free-solid-svg-icons';

// Store
import {getUser} from 'store/selectors/user-selectors';
import {getCustomers} from 'store/selectors/customer-selectors';
import {fetchCustomer, updateCustomer} from 'store/actions/customer-action';
import {hasRole} from 'components/security/Has';

const messages = defineMessages({
    activate: {
        id: 'message.customer.activate',
        defaultMessage: 'Activate',
    },
    active: {
        id: 'message.customer.active',
        defaultMessage: 'Active',
    },
    disable: {
        id: 'message.customer.disable',
        defaultMessage: 'Disable',
    },
    disabled: {
        id: 'message.customer.disabled',
        defaultMessage: 'Disabled',
    },
    deleted: {
        id: 'message.customer.deleted',
        defaultMessage: 'Deleted',
    },
    areYouSureActivate: {
        id: 'message.customer.are.you.sure.activate',
        defaultMessage: 'Are you sure you wish to activate {customer}?',
    },
    areYouSureDisable: {
        id: 'message.customer.are.you.sure.disable',
        defaultMessage: 'Are you sure you wish to disable {customer}?',
    },
    confirmCustomerChange: {
        id: 'message.confirm.customer.change',
        defaultMessage: 'Activating/Disabling a Customer',
    },
    notFound: {
        id: 'message.not.found',
        defaultMessage: 'not found',
    },
    save: {
        id: 'btn.save',
        defaultMessage: 'Save',
    },
    note: {
        id: 'message.note',
        defaultMessage: 'Note',
    },
    customerStatus: {
        id: 'message.customer.status',
        defaultMessage: 'Customer Status',
    },
    accountManagement: {
        id: 'message.account.management',
        defaultMessage: 'Account Management',
    },
    deviceManagement: {
        id: 'message.device.management',
        defaultMessage: 'Device Management',
    },
});

const defaultCustomer = {
    id: '',
    pbx_admin_users: [],
    deleted: false,
    name: '',
    scopeable_id: null,
    preferences: {},
    aws_region: '',
    implicit_role: null,
    sms_config: [],
    creation_date: '',
    note: '',
    licenses: [],
    active: false,
};

const TravellerDashboard = () => {
    const intl = useIntl();
    const reduxDispatch = useDispatch();

    // Selector
    const user = useSelector(getUser);
    const customers = useSelector(getCustomers);
    const travellingCustomer = customers.filter(
        (customer: {id: string}) => customer.id === user.travel.customer
    );

    // State
    const [sbc, setSbc] = useState({name: '', ip: ''});
    const [showModal, setShowModal] = useState(false);
    const [customer, setCustomer] = useState(defaultCustomer);

    const loading = customers.length === 0 && travellingCustomer.length === 0;

    useEffect(() => {
        if (travellingCustomer[0] === undefined)
            reduxDispatch(fetchCustomer({id: user.travel.customer}));
    }, [travellingCustomer, reduxDispatch, user.travel.customer]);

    useEffect(() => {
        if (travellingCustomer[0]) setCustomer({...travellingCustomer[0]});
        // eslint-disable-next-line
    }, [customers]);

    useEffect(() => {
        const notFound = intl.formatMessage(messages.notFound);
        if (!sbc.ip || !sbc.name) {
            fetch(`https://dns.google/resolve?name=sip.${user.travel.customer}.konexim.net`)
                .then(async (response) => {
                    const {Answer} = await response.json();
                    if (!response.ok) {
                        const error = (Answer && Answer.message) || response.statusText;
                        return Promise.reject(error);
                    }
                    setSbc({
                        ...sbc,
                        name: Answer === undefined ? notFound : Answer[0].data,
                        ip: Answer === undefined ? notFound : Answer[1].data,
                    });
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }
    }, [sbc, user.travel.customer, intl]);

    // Update Customer
    const updateNote = () => {
        reduxDispatch(
            updateCustomer({
                id: customer.id,
                name: customer.name,
                aws_region: customer.aws_region,
                active: customer.active,
                note: customer.note,
            })
        );
    };

    const submit = () => {
        reduxDispatch(
            updateCustomer({
                id: user.travel.customer,
                active: !customer.active,
            })
        );
        setShowModal(false);
    };

    const elements = [
        {
            title: intl.formatMessage(messages.customerStatus),
            icon: faUser,
            action: (
                <Button
                    color={customer?.active ? 'danger' : 'success'}
                    size={'sm'}
                    disabled={!hasRole(user, 'super administrator')}
                    onClick={() => setShowModal(true)}
                    style={{float: 'right'}}
                >
                    {customer?.active
                        ? intl.formatMessage(messages.disable)
                        : intl.formatMessage(messages.activate)}
                </Button>
            ),
        },
        {
            title: 'PBX',
            icon: faPhone,
            subroute: [
                {
                    label: 'Admin',
                    disable: customer === undefined,
                    url: `https://${user.travel.customer}.konexim.net`,
                },
            ],
            info: [
                {
                    label: 'SBC Name : ',
                    value: sbc.name,
                },
                {
                    label: 'SBC IP : ',
                    value: sbc.ip,
                },
            ],
        },
        {
            title: intl.formatMessage(messages.accountManagement),
            icon: faBuilding,
            subroute: [
                {
                    label: 'Hubspot',
                    disable: customer === undefined,
                    url: `https://app.hubspot.com/contacts/7185668/objects/0-1/views/all/list?query=${customer?.name}`,
                },
                {
                    label: 'Zoho Subscription',
                    disable: customer === undefined,
                    url: `https://subscriptions.zoho.com/app#/subscriptions?customview_id=&filter_by=SubscriptionStatus.All&per_page=25&search_criteria={"subscription_number_contains":"","reference_contains":"${user.travel.customer}"}&sort_column=next_billing_at&sort_order=A`,
                },
            ],
        },
        {
            title: intl.formatMessage(messages.deviceManagement),
            icon: faMobile,
            subroute: [
                {
                    label: 'Yealink Admin (VPN)',
                    disable: customer === undefined,
                    url: `http://bill-n-prov.konexim.lan:9981/view/userselect?customerid=${user.travel.customer}`,
                },
                {
                    label: 'Polycom (VPN)',
                    disable: customer === undefined,
                    url: `http://bill-n-prov.konexim.lan:9982/view/userselect?customerid=${user.travel.customer}`,
                },
            ],
        },
        {
            title: intl.formatMessage(messages.note),
            icon: faClipboard,
            note: {
                style: {height: '115px'},
                value: customer?.note,
                action: (
                    <Row className='float-right mr-0 mt-2'>
                        <Button
                            color='primary'
                            size={'sm'}
                            disabled={!hasRole(user, 'super administrator')}
                            onClick={() => updateNote()}
                        >
                            {intl.formatMessage(messages.save)}
                        </Button>
                    </Row>
                ),
            },
        },
    ];

    const renderAction = (element: any) => (
        <>
            <CardTitle>
                <b>
                    <FormattedMessage id={'message.customer'} defaultMessage={'Customer'} />
                </b>
                &nbsp;:&nbsp;
                {user.travel.customer}
            </CardTitle>
            {renderCardBody(element)}
        </>
    );

    const renderSubroute = (element: any) =>
        element.subroute.map(
            (route: {label: string; disable: boolean; url: string}, index: number) => (
                <CardText key={index}>
                    <Link
                        to={{
                            pathname: route.url,
                        }}
                        target='_blank'
                        className={route.disable ? 'disabled-link' : ''}
                    >
                        {route.label}
                    </Link>
                </CardText>
            )
        );

    const renderInfo = (element: any) =>
        element.info.map((i: any, index: number) => (
            <CardTitle key={index}>
                <b>{i.label}</b>
                {i.value}
            </CardTitle>
        ));

    const renderCardBody = (element: {
        title?: string;
        icon?: IconDefinition;
        action: any;
        subroute?: undefined;
    }) => {
        if (loading)
            return (
                <Spinner color={'primary'} background={'transparent'} global={false} size={20} />
            );

        if (customer === undefined) {
            return (
                <FormattedMessage
                    id='message.customer.not_find'
                    defaultMessage='No customer found'
                />
            );
        } else {
            return (
                <>
                    <b>
                        <FormattedMessage id={'message.customer.state'} defaultMessage={'State'} />
                    </b>
                    &nbsp;:&nbsp;
                    {customer.deleted ? (
                        intl.formatMessage(messages.deleted)
                    ) : (
                        <>
                            {customer.active
                                ? intl.formatMessage(messages.active)
                                : intl.formatMessage(messages.disabled)}
                            {element.action}
                        </>
                    )}
                </>
            );
        }
    };

    const renderNote = (element: any) => (
        <>
            {loading ? (
                <Spinner color={'primary'} background={'transparent'} global={false} size={20} />
            ) : (
                <>
                    <TextInput
                        onValueChange={(v: any) => setCustomer({...customer, note: v})}
                        value={customer?.note}
                        enabled={true}
                        type={'textarea'}
                        style={element.note.style}
                    />
                    {element.note.action && element.note.action}
                </>
            )}
        </>
    );

    return (
        <>
            <ContentWrapper>
                <CardDeck>
                    {elements.map((element, index) => (
                        <Card key={index}>
                            <CardHeader className={'d-flex align-items-baseline'}>
                                {element.icon && (
                                    <FontAwesomeIcon
                                        className={'mr-1 text-primary'}
                                        icon={element.icon}
                                    />
                                )}
                                {element.title}
                            </CardHeader>
                            <CardBody>
                                {element.action && renderAction(element)}
                                {element.subroute && renderSubroute(element)}
                                {element.info && renderInfo(element)}
                                {element.note && renderNote(element)}
                            </CardBody>
                        </Card>
                    ))}
                </CardDeck>
            </ContentWrapper>
            {showModal && (
                <DangerModal
                    isOpen={showModal}
                    toggle={() => setShowModal(!showModal)}
                    onClosed={() => setShowModal(false)}
                    title={intl.formatMessage(messages.confirmCustomerChange)}
                    btnTitle='btn.validate'
                    content={
                        customer.active ? (
                            <FormattedMessage
                                {...messages.areYouSureDisable}
                                values={{
                                    customer: user.travel.customer,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                {...messages.areYouSureActivate}
                                values={{
                                    customer: user.travel.customer,
                                }}
                            />
                        )
                    }
                    onClick={submit}
                />
            )}
        </>
    );
};

export default TravellerDashboard;
