import React, {useCallback, useEffect, useReducer, useState} from 'react';
import * as PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
    getCallHistory,
    getCallsLoading,
    getCallsStatistics,
    getUnfilteredCallHistory,
} from '../../store/selectors/cdr-selectors';
import {newPortalCustomerAccessSelector} from '../../store/selectors/customer-selectors';
import {fetchCustomerNewPortalAccess} from '../../store/actions/customer-action';

import {fetchCallHistory} from '../../store/actions/cdr-action';
import {fetchCustomerExtensions} from '../../store/actions/customer-action';
import {createFileDownload, resetCSVData} from '../../store/actions/download-action';
import {FilterDisplays} from './filters/FilterDisplays';
import {columns} from './call_history_table_descriptor';
import FilterFactories, {filters as FILTERS} from './filters/FilterFactories';
import {build_call_history_query} from './calls_elastic_search_query_builder';
import {status_color, status_icon, status_label} from '../../utils/call_history';
import {FormattedMessage, injectIntl} from 'react-intl';
import {status_filter} from './filters/Status';
import {tag_filter} from './filters/Tag';
import isEqual from 'lodash.isequal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getUser, userRequestCustomerSelector} from '../../store/selectors/user-selectors';
import {useListReducer, useObjectReducer, useQuery} from '../../utils/hooks';
import {useHistory} from 'react-router-dom';
import Spinner from '../spinner/Spinner';
import {external_number_filter} from './filters/ExternalNumber';
import {name_filter} from './filters/Name';
import {Table} from '../layout/Table/Table';
import {direction_filter} from './filters/Direction';
import {extension_filter} from './filters/Extension';
import CheckboxInput from '../input/CheckboxInput';
import {Has} from '../security/Has';
import {faFileExcel, faLink, faUnlink} from '@fortawesome/free-solid-svg-icons';
import {getDownloadLoading} from '../../store/selectors/download-selectors';
import ModalDownload from './modals/ModalDownload';
import {Button, CardBody, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';

const is_filter = (key) => {
    return Object.keys(FILTERS).some((k) => key.startsWith(k));
};

const extract_query_filters = (query) => {
    const results = {};
    query.forEach((value, key) => {
        const filterKey = Object.keys(FILTERS).find((k) => key.startsWith(k));
        const filter = filterKey && FILTERS[filterKey];
        if (filter != null) {
            const decoded = decodeURIComponent(value);
            const result = filter.deserializer
                ? filter.deserializer(decoded)
                : filter.factory(decoded);
            if (result) results[key] = result;
        }
    });
    return results;
};

const TURNOVER = 500;
const THRESHOLD = 200;

const CallHistory = ({defaultFilters, intl, title}) => {
    const query = useQuery();
    const history = useHistory();

    const customer = useSelector(userRequestCustomerSelector);
    const calls = useSelector(getCallHistory);
    const newPortalAccess = useSelector(newPortalCustomerAccessSelector);

    const unfilteredCalls = useSelector(getUnfilteredCallHistory);

    const stats = useSelector(getCallsStatistics);
    const loading = useSelector(getCallsLoading);
    const user = useSelector(getUser);
    const downloadLoading = useSelector(getDownloadLoading);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [latestQuery, setLatestQuery] = useState({});

    const reduxDispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [filterFactoryActive, setFilterFactoryActive] = useState(false);
    const [onlyMyCalls, setOnlyMyCalls] = useState(false);
    const [showNewHistoryPage, setShowNewHistoryPage] = useState(false);
    const [showNewHistoryPageToggle, setShowNewHistoryPageToggle] = useState(false);
    const [sort, sortDispatch] = useReducer((state, action) => {
        return isEqual(state, action) ? state : action;
    }, null);
    const [filters, filtersDispatch] = useReducer((previousFilters, action) => {
        let newFilters = {...previousFilters};
        switch (action.type) {
            case 'add':
                if (action.payload.index === 'extension') {
                    setOnlyMyCalls(false);
                }
                newFilters[action.payload.index] = action.payload.filter;
                break;
            case 'remove':
                delete newFilters[action.payload.index];
                break;
            case 'clear':
                newFilters = {};
                break;
            default:
                return previousFilters;
        }
        return isEqual(previousFilters, newFilters) ? previousFilters : newFilters;
    }, extract_query_filters(query));

    const [extras, extrasDispatch] = useObjectReducer({
        tagMode: 'or',
        nameMode: 'or',
    });

    const [invisibleFilters, invisibleFiltersDispatch] = useListReducer(
        (f) => f.index,
        defaultFilters,
    );

    const fetchCallHistoryCallback = useCallback(
        (from, size, behaviour) => {
            const query = build_call_history_query(
                [
                    ...Object.values(invisibleFilters).map((f) => f.custom_filter),
                    ...Object.values(filters).map((f) => f.custom_filter),
                ],
                [],
                sort,
                size,
                from,
                extras,
            );
            setLatestQuery(query);
            reduxDispatch(
                fetchCallHistory({
                    params: query,
                    behaviour,
                }),
            );
        },
        [filters, invisibleFilters, reduxDispatch, sort, extras],
    );

    const replaceCalls = useCallback(
        () => fetchCallHistoryCallback(0, TURNOVER, 'replace'),
        [fetchCallHistoryCallback],
    );
    const extendCalls = useCallback(
        () => fetchCallHistoryCallback(Object.values(unfilteredCalls).length, TURNOVER, 'extend'),
        [unfilteredCalls, fetchCallHistoryCallback],
    );

    useEffect(() => {
        const newQuery = new URLSearchParams();
        query.forEach((value, key) => {
            if (!is_filter(key)) newQuery.set(key, value);
        });
        Object.values(filters).forEach((f) =>
            newQuery.set(f.index, encodeURIComponent(f.serialized)),
        );
        history.push({search: newQuery.toString()});
        //eslint-disable-next-line
    }, [filters, showNewHistoryPage]);

    useEffect(() => {
        setTotal(
            stats && stats.status_count
                ? Object.values(stats.status_count).reduce((t, i) => t + i, 0)
                : 0,
        );
    }, [stats, showNewHistoryPage]);

    useEffect(() => {
        setHasMore(unfilteredCalls ? Object.values(unfilteredCalls).length !== total : true);
    }, [unfilteredCalls, total, showNewHistoryPage]);

    const addFilter = (filter) => {
        filtersDispatch({
            type: 'add',
            payload: {
                index: filter.index,
                filter: filter,
            },
        });
    };

    const removeFilter = (filter) =>
        filtersDispatch({
            type: 'remove',
            payload: {
                index: filter.index,
            },
        });

    const clearFilters = () =>
        filtersDispatch({
            type: 'clear',
        });

    useEffect(() => {
        if (onlyMyCalls) {
            invisibleFiltersDispatch({
                type: 'add',
                payload: extension_filter.factory(user.extension.toString()),
            });
            filtersDispatch({type: 'remove', payload: {index: 'extension'}});
        } else {
            invisibleFiltersDispatch({type: 'remove', payload: {index: 'extension'}});
        }
    }, [onlyMyCalls, invisibleFiltersDispatch, user.extension]);

    useEffect(() => {
        replaceCalls();
    }, [filters, sort, fetchCallHistoryCallback, replaceCalls, customer]);

    useEffect(() => {
        reduxDispatch(fetchCustomerExtensions());
    }, [reduxDispatch, customer]);

    useEffect(() => {
        if (showNewHistoryPage) {
            history.push('/new_call_history');
        }
    }, [showNewHistoryPage, history, newPortalAccess]);

    useEffect(() => {
        setShowNewHistoryPageToggle(newPortalAccess.oldPortalAccess);
    }, [newPortalAccess, customer]);
    useEffect(() => {
        reduxDispatch(fetchCustomerNewPortalAccess());
    }, [reduxDispatch, customer]);

    return (
        <>
            <CardHeader className={'border-bottom-0 d-flex flex-row align-items-baseline'}>
                <h2>{title}</h2>
                <FilterFactories addFilter={addFilter} setActive={setFilterFactoryActive} />
                {!filterFactoryActive && user.extension !== null && (
                    <span>
                        <Has
                            aPolicyMatching={[{action: 'READ', resourceType: 'CALLS'}]}
                            forbidTravel
                        >
                            <CheckboxInput
                                label={
                                    <FormattedMessage
                                        id={'toggle.show.only.my.calls'}
                                        defaultMessage={'Show only my calls'}
                                    />
                                }
                                onValueChange={setOnlyMyCalls}
                                value={onlyMyCalls}
                            />
                        </Has>
                    </span>
                )}
                {showNewHistoryPageToggle ? (
                    <CheckboxInput
                        label={
                            <FormattedMessage
                                id={'toggle.new.call.history'}
                                defaultMessage={'New Call History'}
                            />
                        }
                        onValueChange={setShowNewHistoryPage}
                        value={showNewHistoryPage}
                    />
                ) : (
                    <></>
                )}

                <Button
                    className={`ml-3`}
                    onClick={() => {
                        reduxDispatch(resetCSVData());
                        toggle();
                    }}
                    size={'sm'}
                    color={'secondary'}
                >
                    <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{
                            marginRight: '3.5px',
                        }}
                    />
                    <span>
                        <FormattedMessage
                            id={'csv.export.this.view'}
                            defaultMessage={'Export This View'}
                        />
                    </span>
                </Button>
                <ModalDownload
                    modal={modal}
                    toggle={toggle}
                    dispatch={reduxDispatch}
                    downloadFile={createFileDownload}
                    total={total}
                    params={latestQuery}
                    filters={filters}
                />
            </CardHeader>
            <CardBody style={{overflowY: 'auto'}} className={'d-flex flex-column flex-grow-1 p-0'}>
                <FilterDisplays
                    className={'py-2 px-4'}
                    filters={Object.values(filters)}
                    removeFilter={removeFilter}
                    clearFilters={clearFilters}
                    scrollable={false}
                    group
                    groupAction={{
                        tag: {
                            asLink: true,
                            component: (
                                <FontAwesomeIcon
                                    className={'cursor-pointer text-default ml-0'}
                                    transform={{rotate: 45}}
                                    icon={extras.tagMode === 'and' ? faLink : faUnlink}
                                    onClick={() => {
                                        extrasDispatch({
                                            type: 'edit',
                                            payload: {
                                                field: 'tagMode',
                                                value: extras.tagMode === 'and' ? 'or' : 'and',
                                            },
                                        });
                                    }}
                                />
                            ),
                        },
                        name: {
                            asLink: true,
                            component: (
                                <FontAwesomeIcon
                                    className={'mx-1 cursor-pointer text-default ml-0'}
                                    transform={{rotate: 45}}
                                    icon={extras.nameMode === 'and' ? faLink : faUnlink}
                                    onClick={() => {
                                        extrasDispatch({
                                            type: 'edit',
                                            payload: {
                                                field: 'nameMode',
                                                value: extras.nameMode === 'and' ? 'or' : 'and',
                                            },
                                        });
                                    }}
                                />
                            ),
                        },
                    }}
                />
                {loading && (
                    <Spinner
                        background={'transparent'}
                        className={'d-flex align-self-center'}
                        color={'primary'}
                        global={false}
                        size={50}
                        style={{
                            position: 'absolute',
                            top: '45%',
                            bottom: '45%',
                            left: '45%',
                            right: '45%',
                            zIndex: 10,
                        }}
                    />
                )}

                {downloadLoading.length > 0 && (
                    <Spinner
                        background={'transparent'}
                        className={'d-flex align-self-center'}
                        color={'primary'}
                        global={false}
                        size={50}
                        style={{
                            position: 'absolute',
                            top: '45%',
                            bottom: '45%',
                            left: '45%',
                            right: '45%',
                            zIndex: 10,
                        }}
                    />
                )}
                <Table
                    columns={columns}
                    handles={{
                        activeTags: Object.keys(filters)
                            .filter((k) => k.startsWith('tag_'))
                            .map((k) => filters[k].serialized),
                        nameFilterDispatch: (name) => addFilter(name_filter.factory(name)),
                        numberFilterDispatch: (number) =>
                            addFilter(external_number_filter.factory(number)),
                        tagFilterDispatch: (tag) => addFilter(tag_filter.factory(tag)),
                        statusFilterDispatch: (status) => addFilter(status_filter.factory(status)),
                        directionFilterDispatch: (direction) =>
                            addFilter(direction_filter.factory(direction)),
                        extensionFilterDispatch: (extension) =>
                            addFilter(extension_filter.factory(extension)),
                    }}
                    infinity={{
                        hasMore: hasMore,
                        isLoadingMore: loading,
                        loadMore: extendCalls,
                        minimumBatchSize: TURNOVER,
                        threshold: THRESHOLD,
                    }}
                    itemKey={(index, itemData) =>
                        itemData.items[index] ? itemData.items[index].id : index
                    }
                    itemSize={55}
                    items={Object.values(calls)}
                    onSort={(items, sort) => sortDispatch(sort)}
                />
                {stats && stats.status_count && (
                    <ListGroup flush>
                        <ListGroupItem className={'d-flex'}>
                            {Object.keys(stats.status_count).map((k, i) => {
                                const filter = status_filter.factory(k);
                                return (
                                    <Button
                                        className={`mr-2`}
                                        color={status_color(k)}
                                        key={i}
                                        onClick={() => {
                                            filtersDispatch({
                                                type: 'add',
                                                payload: {index: filter.index, filter: filter},
                                            });
                                        }}
                                        outline
                                        size={'sm'}
                                    >
                                        <FontAwesomeIcon icon={status_icon(k)} />
                                        <span>{status_label(k, intl)}: </span>
                                        <span>{stats.status_count[k]}</span>
                                    </Button>
                                );
                            })}
                            <Button size={'sm'} color={'link'} className={`ml-auto cursor-default`}>
                                <FormattedMessage id={'message.total'} defaultMessage={'Total'} /> :
                                {total}
                            </Button>
                        </ListGroupItem>
                    </ListGroup>
                )}
            </CardBody>
        </>
    );
};

CallHistory.propTypes = {
    defaultFilters: PropTypes.arrayOf(PropTypes.func),
};

CallHistory.defaultProps = {
    defaultFilters: [],
};

export default injectIntl(CallHistory);
