import React, {useState} from 'react';
import {NavLink as NavLinkRRD} from 'react-router-dom';
import {faAngleDown, faAngleLeft, faStopwatch, faLock} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Collapse, NavLink, Tooltip, UncontrolledTooltip} from 'reactstrap';
import {formatAlphanumericString} from '../../utils/format';
import {Has} from '../security/Has';
import {FormattedMessage} from 'react-intl';
import {getUserCustomerLicense} from '../../store/selectors/user-selectors';
import {useSelector} from 'react-redux';

export const ComingSoonLink = (props) => {
    const [comingSoonTooltipOpen, setComingSoonTooltipOpen] = useState(false);

    const toggle = () => {
        setComingSoonTooltipOpen(!comingSoonTooltipOpen);
    };

    const id = formatAlphanumericString(props.path);
    return (
        <NavLink
            id={id}
            disabled
            className='admin-link cursor-default'
            tag={NavLinkRRD}
            to={'/comingsoon'}
        >
            {props.children}
            <FontAwesomeIcon fixedWidth icon={faStopwatch} className={'ml-2 text-primary'} />
            <Tooltip placement={'right'} isOpen={comingSoonTooltipOpen} toggle={toggle} target={id}>
                <FormattedMessage id={'title.coming_soon'} defaultMessage={'Coming Soon'} />
            </Tooltip>
        </NavLink>
    );
};

const renderLinks = (routes, path, license) =>
    routes.map((route, key) => {
        if (!route.no_nav) {
            if (!route.component)
                return (
                    <Has {...route.has} key={key}>
                        <ComingSoonLink path={route.path} title={route.title}>
                            {route.titleComponent || route.title}
                        </ComingSoonLink>
                    </Has>
                );
            return (
                <Has {...route.has} key={key}>
                    <NavLink
                        className='admin-link'
                        to={path + route.path}
                        tag={NavLinkRRD}
                        disabled={route?.props?.license === license}
                        id={`admin-link-${formatAlphanumericString(route.path)}`}
                    >
                        {route.titleComponent || route.title}
                        {path === '/admin/integrations' && route?.props?.license === license && (
                            <>
                                <FontAwesomeIcon fixedWidth icon={faLock} />
                                <UncontrolledTooltip
                                    placement='right'
                                    target={`admin-link-${formatAlphanumericString(route.path)}`}
                                >
                                    <FormattedMessage
                                        id='tooltip.admin_sidebar.warning'
                                        defaultMessage='Your license does not give you access to integrations.'
                                    />
                                </UncontrolledTooltip>
                            </>
                        )}
                    </NavLink>
                </Has>
            );
        }
        return null;
    });

const SectionComponent = (props) => {
    const [collapse, setCollapse] = useState(props.collapse);
    const customerLicense = useSelector(getUserCustomerLicense);

    return (
        <Has {...(props.section.has || {})}>
            <div className='section'>
                <div
                    className='section-title-container d-flex
                flex-row justify-content-between align-items-center pt-2 pb-2 cursor-pointer'
                    onClick={() => {
                        setCollapse(!collapse);
                    }}
                >
                    <div className='section-title d-flex align-items-baseline'>
                        {props.section.icon && (
                            <FontAwesomeIcon
                                fixedWidth
                                className='mr-1 text-primary'
                                icon={props.section.icon}
                            />
                        )}
                        {props.section.iconSrc && (
                            <img
                                alt='...'
                                className={'mr-1'}
                                src={props.section.iconSrc}
                                style={{width: 20}}
                            />
                        )}
                        {props.section.titleComponent || <span>{props.section.title}</span>}
                    </div>
                    <FontAwesomeIcon icon={!collapse ? faAngleLeft : faAngleDown} color='#E0007A' />
                </div>
            </div>
            <Collapse isOpen={collapse}>
                <div className='section-list pl-4'>
                    {renderLinks(
                        props.section.subroutes,
                        props.routePrefix + (props.section.path || ''),
                        customerLicense
                    )}
                </div>
            </Collapse>
        </Has>
    );
};

const AdminSidebar = ({routes, routePrefix}) => (
    <div className='sidebar admin-sidebar'>
        <div className='sidebar-top' />
        {routes &&
            routes.map((section, index) => (
                <SectionComponent
                    section={section}
                    key={index}
                    collapse={section.collapse}
                    routePrefix={routePrefix}
                />
            ))}
    </div>
);

export default AdminSidebar;
