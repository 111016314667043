import React from 'react';
import {createRoot} from 'react-dom/client';

// Import Amplify and his me pool configuration
import Amplify from '@aws-amplify/core';
import amplifyConfig from 'config/amplify-config';

import * as Sentry from '@sentry/browser';

import ReactGA from 'react-ga';

// Import Main App component
import App from 'app/App.jsx';

import {mobileCheck} from 'utils/window';
import MobileComingSoon from 'views/MobileComingSoon';

// Import Style
import 'assets/styles/argon-dashboard-pro-react-style.css';
import 'assets/styles/over-agron-pro-style.css';
import 'assets/styles/foneco-portal-general-style.css';

import 'assets/vendor/animate.css/animate.min.css';
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';

// On prod
if (process.env.NODE_ENV === 'production') {
    // Sentry
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_URL});
    // Google Analytics
    ReactGA.initialize('UA-114679780-2');
}

// Configure Amplify & make it global for the app
Amplify.configure(amplifyConfig);

// Insert the React App into the 'croo' div
const container = document.getElementById('croo')!;
const root = createRoot(container);
root.render(mobileCheck() ? <MobileComingSoon /> : <App />);
