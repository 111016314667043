module.exports = {
    Auth: {
        region: process.env.REACT_APP_APF_AUTH_REGION,
        userPoolId: process.env.REACT_APP_APF_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_APF_AUTH_CLIENT_APP_ID,
        identityPoolId: process.env.REACT_APP_APF_AUTH_IDTY_POOL
    },
    Analytics : {
        disabled: true
    }
};