import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Badge} from 'reactstrap';

const AdminOnlyBadge = () => {
    return (
        <Badge className={'ml-2'} pill color={'primary'}>
            <span className={'mr-1'} role={'img'} aria-label={'admin'}>
                👩🏾‍💻
            </span>
            <FormattedMessage
                id={'message.administrators.only'}
                defaultMessage={'Administrators Only'}
            />
        </Badge>
    );
};

AdminOnlyBadge.propTypes = {
    //
};

export default AdminOnlyBadge;
