import React from 'react';
import PropTypes from 'prop-types';

interface SidePanelContentProps {
    className?: string;
    children?: any;
    style?: any;
}

const SidePanelContent = ({className, style, children = false}: SidePanelContentProps) => (
    <div className={'d-flex flex-column flex-grow-1'} style={{overflowY: 'scroll'}}>
        <div className={className} style={style}>
            {children}
        </div>
    </div>
);

SidePanelContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.any,
};

SidePanelContent.defaultProps = {
    className: 'flex-grow-1 p-4',
    style: {},
};

export default SidePanelContent;
