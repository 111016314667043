import React, {useReducer, useState} from 'react';
import {Button, Card, CardBody, CardText, Form, FormGroup, FormText, Nav, Row} from 'reactstrap';
import {defineMessages, FormattedMessage} from 'react-intl';
import {
    createCustomerApiKey,
    deleteCustomerApiKey,
    updateCustomerApiKey,
} from '../../../../store/actions/customer-action';
import momentTZ from 'moment-timezone';
import {faCog, faLock} from '@fortawesome/free-solid-svg-icons';
import DangerModal from '../../../../components/notification/DangerModal';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import {useDispatch} from 'react-redux';
import FormSection from '../../../../components/form/FormSection';
import CheckboxInput from '../../../../components/input/CheckboxInput';
import SelectInput from '../../../../components/input/SelectInput';
import isEqual from 'lodash.isequal';
import SidePanelContent from '../../../../components/panel/SidePanelContent';
import SidePanelHeader from '../../../../components/panel/SidePanelHeader';
import {getDefaultTimeZone} from '../../../../utils/datetime';

const defaultOpenSections = {
    timezoneOpen: true,
    permissionsOpen: true,
    advancedOpen: false,
};

const permission_messages = defineMessages({
    make_calls: {
        id: 'input.label.make.calls',
        defaultMessage: 'Make calls',
    },
    get_cdrs: {
        id: 'input.label.get.cdrs',
        defaultMessage: 'Get call directory records',
    },
    get_recordings: {
        id: 'input.label.get.recordings',
        defaultMessage: 'Get recordings',
    },
    get_users: {
        id: 'input.label.get.users',
        defaultMessage: 'Get users',
    },
    queue_manager: {
        id: 'input.label.manage.queue',
        defaultMessage: 'Manage queue',
    },
    dnd: {
        id: 'input.label.dnd',
        defaultMessage: 'Do not disturb',
    },
    sms: {
        id: 'input.label.sms',
        defaultMessage: 'SMS',
    },
    bulk_sms: {
        id: 'input.label.bulk.sms',
        defaultMessage: 'Bulk SMS',
    },
    voicemail: {
        id: 'input.label.voicemail',
        defaultMessage: 'Voicemail',
    },
    generic_socket: {
        id: 'input.label.generic.socket',
        defaultMessage: 'Webhooks',
    },
    import_data: {
        id: 'input.label.import.data',
        defaultMessage: 'Import Customer Data',
    },
});

const getAllPermissionsAs = (bool) => {
    let new_permissions = {};
    Object.keys(permission_messages).map((k) => (new_permissions[k] = bool));
    return new_permissions;
};

const ApiKeyForm = ({apiKey, toggle}) => {
    const reduxDispatch = useDispatch();

    const defaultApiKeyState = {
        apikey: '',
        timezone: getDefaultTimeZone(),
        permissions: getAllPermissionsAs(false),
    };

    const [apiKeyState, apiKeyDispatch] = useReducer((prevApiKeyState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultApiKeyState;
            case 'edit':
                if (isEqual(prevApiKeyState[action.payload.field], action.payload.value))
                    return prevApiKeyState;
                const newState = {...prevApiKeyState};
                newState[action.payload.field] = action.payload.value;
                return newState;
            default:
                return prevApiKeyState;
        }
    }, apiKey || defaultApiKeyState);

    const [deleteApiKeyModal, setDeleteApiKeyModal] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (!!apiKey) {
            reduxDispatch(
                updateCustomerApiKey({
                    apikey: apiKeyState.apikey,
                    timezone: apiKeyState.timezone,
                    permissions: {...apiKeyState.permissions},
                })
            );
        } else {
            reduxDispatch(
                createCustomerApiKey({
                    timezone: apiKeyState.timezone,
                    permissions: {...apiKeyState.permissions},
                })
            );
        }
        toggle();
    };

    const deleteApiKey = () => {
        reduxDispatch(deleteCustomerApiKey({apikey: apiKeyState.apikey}));
        setDeleteApiKeyModal(false);
        toggle();
    };

    const editField = (name, value) =>
        apiKeyDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });

    const togglePermission = (name, checked) => {
        apiKeyDispatch({
            type: 'edit',
            payload: {
                field: 'permissions',
                value: {...apiKeyState.permissions, [name]: checked},
            },
        });
    };

    const toggleAllPermissions = (bool) => {
        return apiKeyDispatch({
            type: 'edit',
            payload: {field: 'permissions', value: getAllPermissionsAs(bool)},
        });
    };

    return (
        <>
            <SidePanelHeader>
                {!!apiKey ? (
                    apiKey.apikey
                ) : (
                    <FormattedMessage id={'btn.create.api.key'} defaultMessage={'Create API Key'} />
                )}
            </SidePanelHeader>
            <SidePanelContent>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={'pb-2'}>
                        <FormSection
                            icon={faClock}
                            isOpenByDefault={defaultOpenSections.timezoneOpen}
                            title={
                                <FormattedMessage
                                    id={'message.admin.api.keys.timezone'}
                                    defaultMessage={'Timezone'}
                                />
                            }
                        >
                            <Row>
                                <FormGroup className='col'>
                                    <FormattedMessage
                                        id='input.label.timezone'
                                        defaultMessage='Timezone'
                                    >
                                        {(m) => <label className='form-control-label'>{m}</label>}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id='input.placeholder.timezone'
                                        defaultMessage='Timezone'
                                    >
                                        {(m) => (
                                            <SelectInput
                                                options={momentTZ.tz
                                                    .names()
                                                    .map((t) => ({value: t, label: t}))}
                                                onValueChange={(v) => editField('timezone', v)}
                                                placeholder={m}
                                                value={apiKeyState.timezone}
                                            />
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id={'form.text.select.timezone.explanation'}
                                        defaultMessage={
                                            'Select the timezone this API key will be used in'
                                        }
                                    >
                                        {(m) => <FormText color={'muted'}>{m}</FormText>}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                        </FormSection>
                        <FormSection
                            icon={faLock}
                            isOpenByDefault={defaultOpenSections.permissionsOpen}
                            title={
                                <FormattedMessage
                                    id='message.admin.api.keys.permissions'
                                    defaultMessage='Permissions'
                                />
                            }
                        >
                            {Object.keys(permission_messages).map((message) => (
                                <Row key={message} className={'mb-1'}>
                                    <FormGroup
                                        className={
                                            'col d-flex flex-row justify-content-between align-items-center'
                                        }
                                        check
                                    >
                                        <FormattedMessage {...permission_messages[message]}>
                                            {(m) => (
                                                <CheckboxInput
                                                    className={
                                                        'w-100 d-flex justify-content-between'
                                                    }
                                                    label={m}
                                                    onValueChange={(v) =>
                                                        togglePermission(message, v)
                                                    }
                                                    value={apiKeyState.permissions[message]}
                                                />
                                            )}
                                        </FormattedMessage>
                                    </FormGroup>
                                </Row>
                            ))}
                            <Row className={'d-flex flex-row justify-content-end pr-3 mb-1 mt-2'}>
                                <Button
                                    color={'secondary'}
                                    size={'sm'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleAllPermissions(false);
                                    }}
                                >
                                    <FormattedMessage
                                        id='btn.remove.all.permissions'
                                        defaultMessage='Remove All'
                                    />
                                </Button>
                                <Button
                                    color={'primary'}
                                    size={'sm'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleAllPermissions(true);
                                    }}
                                >
                                    <FormattedMessage
                                        id='btn.grant.all.permissions'
                                        defaultMessage='Grant All'
                                    />
                                </Button>
                            </Row>
                        </FormSection>
                        {!!apiKey && (
                            <FormSection
                                icon={faCog}
                                isOpenByDefault={defaultOpenSections.advancedOpen}
                                title={
                                    <FormattedMessage
                                        id='message.admin.advanced'
                                        defaultMessage='Advanced'
                                    />
                                }
                            >
                                <FormattedMessage
                                    id='message.admin.delete.api.key'
                                    defaultMessage={`Delete API key`}
                                >
                                    {(m) => (
                                        <Card>
                                            <CardBody>
                                                <h2 className={'text-danger'}>{m}</h2>
                                                <CardText className={'text-muted'}>
                                                    <small>
                                                        <FormattedMessage
                                                            id={
                                                                'message.admin.delete.api.key.explanation'
                                                            }
                                                            defaultMessage={
                                                                'Deleted API keys will no longer be usable for calls against the portal.'
                                                            }
                                                        />
                                                    </small>
                                                </CardText>
                                                <Button
                                                    color={'danger'}
                                                    size={'sm'}
                                                    onClick={() =>
                                                        setDeleteApiKeyModal(!deleteApiKeyModal)
                                                    }
                                                >
                                                    {m}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    )}
                                </FormattedMessage>
                                <DangerModal
                                    isOpen={deleteApiKeyModal}
                                    toggle={() => setDeleteApiKeyModal(!deleteApiKeyModal)}
                                    title={
                                        <FormattedMessage
                                            id={'message.admin.delete.api.key'}
                                            defaultMessage={'Delete API key'}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id={'message.admin.delete.api.key.confirmation'}
                                            defaultMessage={
                                                'Are you sure you wish to delete this API key? This operation cannot be undone.'
                                            }
                                        />
                                    }
                                    onClick={deleteApiKey}
                                />
                            </FormSection>
                        )}
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
};

export default ApiKeyForm;
