/* CDR Action types */
export const FETCHING_CALLS = 'cdr/FETCHING';

export const FETCHING_CUSTOMER_NEW_PORTAL_ACCESS = 'cdr/customer/new_portal_access/FETCHING';
export const FETCH_CUSTOMER_NEW_PORTAL_ACCESS_SUCCESS = 'cdr/customer/new_portal_access/FETCH_SUCCESS';
export const FETCH_CUSTOMER_NEW_PORTAL_ACCESS_FAILED = 'cdr/customer/new_portal_access/FETCH_FAILED';
export const FETCHED_CUSTOMER_NEW_PORTAL_ACCESS = 'cdr/customer/new_portal_access/FETCHED';

export const FETCHING_NEW_CALLS = 'cdr/new/FETCHING';
export const FETCHED_NEW_CALLS = 'cdr/new/FETCHED';

export const FETCHED_CALLS = 'cdr/FETCHED';

export const FETCH_CALL_HISTORY_SAGA = 'cdr/history/FETCH';
export const FETCH_CALL_HISTORY_SUCCESS = 'cdr/history/FETCH_SUCCESS';
export const FETCH_CALL_HISTORY_FAILED = 'cdr/history/FETCH_FAILED';
export const FETCH_CALL_PARTICIPANTS = 'cdr/history/participant/FETCH';

export const FETCH_NEW_CALL_PARTICIPANTS = 'cdr/history/participant/new/FETCH';
export const FETCH_NEW_CALL_HISTORY_SUCCESS = 'cdr/history/new/FETCH_SUCCESS';
export const FETCH_NEW_CALL_HISTORY_FAILED = 'cdr/history/new/FETCH_FAILED';
export const FETCH_NEW_CALL_HISTORY_SAGA = 'cdr/history/new/FETCH';

export const FETCH_LIVE_CALLS_SAGA = 'cdr/live/FETCH';
export const FETCH_LIVE_CALLS_SUCCESS = 'cdr/live/FETCH_SUCCESS';
export const FETCH_LIVE_CALLS_FAILED = 'cdr/live/FETCH_FAILED';

export const FETCH_CALL_RECORDING_LINK_SAGA = 'cdr/history/recording/FETCH';
export const FETCH_CALL_RECORDING_SUCCESS = 'cdr/history/recording/FETCH_SUCCESS';
export const FETCH_CALL_RECORDING_FAILED = 'cdr/history/recording/FETCH_FAILED';

export const FETCH_NEW_CALL_RECORDING_LINK_SAGA = 'cdr/new/history/recording/FETCH';
export const FETCH_NEW_CALL_RECORDING_SUCCESS = 'cdr/new/history/recording/FETCH_SUCCESS';
export const FETCH_NEW_CALL_RECORDING_FAILED = 'cdr/new/history/recording/FETCH_FAILED';

export const FETCH_WIDGET_DATA_SAGA = 'cdr/widget/FETCH';
export const FETCH_WIDGET_DATA_ONCE_SAGA = 'cdr/widget/FETCH_ONCE';
export const FETCH_WIDGET_DATA_SUCCESS = 'cdr/widget/FETCH_SUCCESS';
export const FETCH_WIDGET_DATA_FAILED = 'cdr/widget/FETCH_FAILED';

export const STORE_CALL_HISTORY = 'cdr/history/STORE';
export const STORE_UNFILTERED_CALL_HISTORY = 'cdr/history/STORE_UNFILTERED';

export const STORE_CONTINUATION_TOKEN = 'cdr/history/new/STORE_CONTINUATION_TOKEN';
export const STORE_UNFILTERED_NEW_CALL_HISTORY = 'cdr/history/new/STORE_UNFILTERED';
export const STORE_NEW_CALL_HISTORY = 'cdr/new/history/STORE';

export const STORE_LIVE_CALLS = 'cdr/live/STORE';

export const STORE_CALL_TAGS = 'cdr/tags/STORE';
export const STORE_CALL_DIRECTIONS = 'cdr/directions/STORE';
export const STORE_CALL_STATUSES = 'cdr/statuses/STORE';
export const STORE_CALL_STATISTICS = 'cdr/statistics/STORE';
export const STORE_CALL_PARTICIPANTS = 'cdr/participants/STORE';

export const STORE_NEW_CALL_STATUSES = 'cdr/new/statuses/STORE';
export const STORE_NEW_CALL_STATISTICS = 'cdr/new/statistics/STORE';
export const STORE_CUSTOMER_NEW_PORTAL_ACCESS = 'cdr/customer/new_portal_access/STORE';

export const STORE_CALL_RECORDING = 'cdr/history/recording/STORE';
export const DELETE_CALL_RECORDING = 'cdr/history/recording/DELETE';

export const STORE_NEW_CALL_RECORDING = 'cdr/history/new/recording/STORE';
export const DELETE_NEW_CALL_RECORDING = 'cdr/history/new/recording/DELETE';

export const STORE_WIDGET_DATA = 'cdr/widget/STORE';
export const STORE_WIDGET_HITS = 'cdr/widget/STORE_HITS';
export const CLEAR_WIDGET_DATA = 'cdr/widget/CLEAR';

export const CLEAR_CALLS = 'cdr/CLEAR';

export const CLEAR_CUSTOMER_NEW_PORTAL_ACCESS = 'cdr/customer/new_portal_access/CLEAR';
export const CLEAR_CONTINUATION_TOKEN = 'cdr/new/CLEAR_CONTINUATION_TOKEN';

/* User Action Creators */
export const fetchCallHistory = (payload) => ({type: FETCH_CALL_HISTORY_SAGA, payload});
export const fetchCallParticipants = (payload) => ({type: FETCH_CALL_PARTICIPANTS, payload});
export const fetchWidgetData = (payload) => ({type: FETCH_WIDGET_DATA_SAGA, payload});
export const fetchWidgetDataOnce = (payload) => ({type: FETCH_WIDGET_DATA_ONCE_SAGA, payload});
// noinspection JSUnusedGlobalSymbols
export const fetchLiveCalls = (payload) => ({type: FETCH_LIVE_CALLS_SAGA, payload});
export const fetchRecordingLink = (payload) => ({type: FETCH_CALL_RECORDING_LINK_SAGA, payload});
export const deleteRecording = (payload) => ({type: DELETE_CALL_RECORDING, payload});

export const fetchNewRecordingLink = (payload) => ({type: FETCH_NEW_CALL_RECORDING_LINK_SAGA, payload});
export const fetchNewCallHistory = (payload) => ({type: FETCH_NEW_CALL_HISTORY_SAGA, payload});
export const fetchNewCallParticipants = (payload) => ({type: FETCH_NEW_CALL_PARTICIPANTS, payload});
