import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import SidePanelHeader from 'components/panel/SidePanelHeader';
import SidePanelContent from 'components/panel/SidePanelContent';
import {trad} from '../phoneNumbersTraduction';
import {Button, FormGroup, Label, Row} from 'reactstrap';
import TextInput from 'components/input/TextInput';
import {DateInput} from 'components/input/DateInput';
import SelectInput from 'components/input/SelectInput';
import CheckboxInput from 'components/input/CheckboxInput';
import {limitValue, notNumber} from 'utils/validation';
import {
    createCustomerPhoneNumber,
    fetchProviderListPhoneNumber,
    updateCustomerPhoneNumber,
} from '../../../../../store/actions/customer-action';
import {getProvidersList} from '../../../../../store/selectors/customer-selectors';
import {getLoading} from 'store/selectors/portal-selectors';
import FormSection from 'components/form/FormSection';
import DeleteNumber from './DeleteNumber';
import {faPhone} from '@fortawesome/free-solid-svg-icons';

interface PhoneNumber {
    did: string;
    rewrite_did: string;
    provider: string;
    cost: string;
    bill: string;
    enable_after: Date;
    note: string;
    local_domain_name: string;
    local_inbound_peer: string;
    customer: string;
    enabled: boolean;
}

const defaultPhoneNumber = (currentCustomer: string): PhoneNumber => {
    return {
        did: '',
        rewrite_did: '',
        provider: 'Bandwith',
        cost: '0.000',
        bill: '0.000',
        enable_after: new Date(),
        note: '',
        local_domain_name: `${currentCustomer}.docker201.lan`,
        local_inbound_peer: '',
        customer: currentCustomer,
        enabled: true,
    };
};

const PhoneNumbersConfigsForm = ({
    phoneNumberProps,
    toggleSidePanel,
    currentCustomer,
}: {
    phoneNumberProps: any;
    toggleSidePanel: () => void;
    currentCustomer: string;
}) => {
    const reduxDispatch = useDispatch();
    const intl = useIntl();
    const providersList = useSelector(getProvidersList);
    const providerLoading = useSelector(getLoading('providers_list'));

    const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber(currentCustomer));
    const [didNumber, setDidNumber] = useState(defaultPhoneNumber(''));

    const newPhoneNumber = Object.keys(phoneNumberProps).length === 0;

    useEffect(() => {
        if (providersList.length === 0) reduxDispatch(fetchProviderListPhoneNumber());
    }, [reduxDispatch, providersList]);

    useEffect(() => {
        if (Object.keys(phoneNumberProps).length > 1) {
            setPhoneNumber(phoneNumberProps);
            setDidNumber(phoneNumberProps.did);
        }
    }, [phoneNumberProps]);

    const submit = (e: any) => {
        e.preventDefault();
        if (!newPhoneNumber) {
            reduxDispatch(updateCustomerPhoneNumber({params: phoneNumber, didNumber}));
            toggleSidePanel();
        } else {
            reduxDispatch(createCustomerPhoneNumber({params: phoneNumber}));
            toggleSidePanel();
        }
    };

    const preSetForm = () =>
        setPhoneNumber({
            ...phoneNumber,
            cost: '0.0065',
            bill: '0.019',
            note: 'Toll Free',
        });

    const textInput = (fields: any) => {
        const {name, label, type, value, maxLength, minValue, maxValue} = fields;
        const limitInput = typeof minValue === 'number' && typeof maxValue === 'number';
        return (
            <Row>
                <FormGroup className='col'>
                    <Label className='form-control-label mb-0'>{label}</Label>
                    <TextInput
                        type={type}
                        name={name}
                        value={value}
                        onValueChange={(v: any) =>
                            setPhoneNumber({
                                ...phoneNumber,
                                [name]: limitInput ? limitValue(Number(v), maxValue, minValue) : v,
                            })
                        }
                        style={{height: type === 'textarea' ? '115px' : ''}}
                        maxLength={maxLength}
                    />
                </FormGroup>
            </Row>
        );
    };

    const dateInput = (fields: any) => {
        const {name, label, value} = fields;
        return (
            <Row className='flex-column px-3 mb-3'>
                <Label className='form-control-label mb-0'>{label}</Label>
                <DateInput
                    value={value}
                    onValueChange={(v: any) => setPhoneNumber({...phoneNumber, [name]: v})}
                />
            </Row>
        );
    };

    const selectInput = (fields: any) => {
        const {name, label, value, options, loading = false} = fields;
        return (
            <Row>
                <FormGroup className='col'>
                    <Label className='form-control-label mb-0'>{label}</Label>
                    <SelectInput
                        value={value}
                        options={options}
                        isLoading={loading}
                        onValueChange={(v: any) => setPhoneNumber({...phoneNumber, [name]: v})}
                    />
                </FormGroup>
            </Row>
        );
    };

    const checkboxInput = (fields: any) => {
        const {name, label, value} = fields;
        return (
            <Row className='px-3 mb-3 mt-3'>
                <Label className='form-control-label mb-0 mr-3'>{label}</Label>
                <CheckboxInput
                    value={value}
                    onValueChange={(v: any) => setPhoneNumber({...phoneNumber, [name]: v})}
                />
            </Row>
        );
    };

    const renderField = (field: any, index: number) => {
        switch (field.type) {
            case 'text':
            case 'textarea':
            case 'number':
                return <React.Fragment key={index}>{textInput(field)}</React.Fragment>;
            case 'datePicker':
                return <React.Fragment key={index}>{dateInput(field)}</React.Fragment>;
            case 'select':
                return <React.Fragment key={index}>{selectInput(field)}</React.Fragment>;
            case 'checkbox':
                return <React.Fragment key={index}>{checkboxInput(field)}</React.Fragment>;
        }
    };

    const fieldsForm = [
        {
            name: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            value: phoneNumber.enabled,
            require: false,
        },
        {
            name: 'did',
            label: 'DID',
            type: 'text',
            value: phoneNumber.did,
            require: true,
            maxLength: 12,
            invalid: (value: string) => notNumber(value),
        },
        {
            name: 'rewrite_did',
            label: 'Over Write DID',
            type: 'text',
            value: phoneNumber.rewrite_did,
            require: false,
            maxLength: 10,
            invalid: (value: string) => notNumber(value),
        },
        {
            name: 'local_domain_name',
            label: 'Local Domain Name',
            type: 'text',
            value: phoneNumber.local_domain_name,
            require: true,
        },
        {
            name: 'provider',
            label: 'Provider',
            type: 'select',
            value: phoneNumber.provider,
            options: providersList,
            require: true,
            loading: providerLoading,
        },
        {
            name: 'cost',
            label: 'Cost Rate',
            type: 'number',
            value: phoneNumber.cost,
            require: false,
            maxLength: 6,
            minValue: 0,
            maxValue: 1000,
        },
        {
            name: 'bill',
            label: 'Bill Rate',
            type: 'number',
            value: phoneNumber.bill,
            require: false,
            maxLength: 6,
            minValue: 0,
            maxValue: 1000,
        },
        {
            name: 'enable_after',
            label: 'Enable After',
            type: 'datePicker',
            value: phoneNumber.enable_after,
            require: false,
        },
        {
            name: 'note',
            label: 'Note',
            type: 'textarea',
            value: phoneNumber.note,
            require: false,
        },
    ];

    return (
        <>
            <SidePanelHeader>
                {intl.formatMessage(!newPhoneNumber ? trad.modificationNumber : trad.addNumber)}
                <Button size={'sm'} color={'primary'} onClick={() => preSetForm()} className='ml-3'>
                    {intl.formatMessage(trad.prefill)}
                </Button>
            </SidePanelHeader>
            <SidePanelContent style={{height: '100%'}}>
                <FormSection
                    icon={faPhone}
                    title={intl.formatMessage(trad.configPhoneNumber)}
                    isOpenByDefault={true}
                >
                    {fieldsForm.map((field, index) => renderField(field, index))}
                </FormSection>
                <DeleteNumber
                    did={phoneNumber.did}
                    currentCustomer={currentCustomer}
                    toggle={() => toggleSidePanel()}
                    newNumber={newPhoneNumber}
                />
                <div className='d-flex justify-content-between mt-4 pb-5'>
                    <Button color={'secondary'} size={'sm'} onClick={() => toggleSidePanel()}>
                        <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                    </Button>
                    <Button color={'primary'} size={'sm'} onClick={(event) => submit(event)}>
                        <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                    </Button>
                </div>
            </SidePanelContent>
        </>
    );
};

export default PhoneNumbersConfigsForm;
