import {BarGraph, DynamicBarGraph, LineGraph, PieChart} from './generators/NivoGenerators';
import {LinearProgressBar, ProgressGauge, ProgressValue} from './generators/ProgressGenerators';
import {QueueList} from './generators/QueueGenerators';
import {
    dataPassThrough,
    parseBarAgentCallsByStatus,
    parseBarAgentCallsByTags,
    parseBarCallsByAgent,
    parseBarCallsByStatus,
    parseLineCallsByDay,
    parseLineCallsByHour,
    parseLineCallsByMonth,
    parsePieData,
    parsePieDataByAgent,
    parsePieDataByStatus,
    parseProgressData,
    parseProgressDataByAgent,
} from './WidgetDataParsers';
import {BarExtensionTick} from '../utils/studioFunctions';
import {getDefaultTimeZone} from '../../../utils/datetime';

const WidgetOptions = {
    'widget.line': {
        'widget.line.calls_by_hour': {
            aggs: {
                calls_by_hour: {
                    agg_type: 'date_histogram',
                    field: 'call_start_time',
                    options: {interval: 'hour', time_zone: getDefaultTimeZone()},
                },
            },
            component: {
                component: LineGraph,
                params: {
                    x: 'Hour',
                    y: 'Calls',
                },
            },
            data: {
                type: 'ES',
                parser: (data, locale) => parseLineCallsByHour(data.calls_by_hour, locale),
            },
            localeRequired: true,
        },
        'widget.line.calls_by_day': {
            aggs: {
                calls_by_day: {
                    agg_type: 'date_histogram',
                    field: 'call_start_time',
                    options: {
                        interval: 'day',
                        time_zone: getDefaultTimeZone(),
                    },
                },
            },
            component: {
                component: LineGraph,
                params: {
                    x: 'Day',
                    y: 'Calls',
                },
            },
            data: {
                type: 'ES',
                parser: (data, locale) => parseLineCallsByDay(data.calls_by_day, locale),
            },
            localeRequired: true,
        },
        'widget.line.calls_by_month': {
            aggs: {
                calls_by_month: {
                    agg_type: 'date_histogram',
                    field: 'call_start_time',
                    options: {interval: 'month', time_zone: getDefaultTimeZone()},
                },
            },
            component: {
                component: LineGraph,
                params: {
                    x: 'Month',
                    y: 'Calls',
                },
            },
            data: {
                type: 'ES',
                parser: (data, locale) => parseLineCallsByMonth(data.calls_by_month, locale),
            },
            localeRequired: true,
        },
    },
    'widget.pie': {
        'widget.pie.calls_by_language': {
            aggs: {
                language: {
                    agg_type: 'terms',
                    field: 'language.keyword',
                },
            },
            component: {
                component: PieChart,
            },
            data: {
                type: 'ES',
                parser: (data) => parsePieData(data.language),
            },
        },
        'widget.pie.calls_by_agent': {
            aggs: {
                calls_by_agent: {
                    agg_type: 'terms',
                    field: 'extension.keyword',
                },
            },
            component: {
                component: PieChart,
            },
            data: {
                type: 'ES',
                parser: (data, extensions) => parsePieDataByAgent(data.calls_by_agent, extensions),
            },
            extensionsRequired: true,
        },
        'widget.pie.calls_by_status': {
            aggs: {
                calls_by_status: {
                    agg_type: 'terms',
                    field: 'action.keyword',
                },
            },
            component: {
                component: PieChart,
            },
            data: {
                type: 'ES',
                parser: (data) => parsePieDataByStatus(data.calls_by_status),
            },
        },
        'widget.pie.calls_by_tags': {
            aggs: {
                calls_by_tags: {
                    agg_type: 'terms',
                    field: 'tags.keyword',
                },
            },
            component: {
                component: PieChart,
            },
            data: {
                type: 'ES',
                parser: (data) => parsePieData(data.calls_by_tags),
            },
        },
    },
    'widget.bar': {
        'widget.bar.calls_by_agent': {
            aggs: {
                calls_by_agent: {
                    agg_type: 'terms',
                    field: 'extension.keyword',
                    options: {
                        size: 30,
                    },
                },
            },
            component: {
                component: BarGraph,
                params: {
                    x: 'Agents',
                    y: 'Calls',
                    keys: ['Calls'],
                    indexBy: 'Agent',
                    tick: BarExtensionTick,
                },
            },
            data: {
                type: 'ES',
                parser: (data, extensions) => parseBarCallsByAgent(data.calls_by_agent, extensions),
            },
            extensionsRequired: true,
        },
        'widget.bar.calls_by_status': {
            aggs: {
                calls_by_status: {
                    agg_type: 'terms',
                    field: 'action.keyword',
                },
            },
            component: {
                component: BarGraph,
                params: {
                    x: 'Statuses',
                    y: 'Calls',
                    keys: ['Calls'],
                    indexBy: 'Status',
                },
            },
            data: {
                type: 'ES',
                parser: (data) => parseBarCallsByStatus(data.calls_by_status),
            },
        },
        'widget.bar.calls_by_agent_per_status': {
            aggs: {
                calls_by_agent: {
                    agg_type: 'terms',
                    field: 'extension.keyword',
                    options: {
                        size: 30,
                    },
                    aggs: {
                        calls_by_status: {
                            agg_type: 'terms',
                            field: 'action.keyword',
                        },
                    },
                },
            },
            component: {
                component: DynamicBarGraph,
                params: {
                    x: 'Agents',
                    y: 'Calls',
                    indexBy: 'Agent',
                    tick: BarExtensionTick,
                },
            },
            data: {
                type: 'ES',
                parser: (data, extensions) =>
                    parseBarAgentCallsByStatus(data.calls_by_agent, extensions),
            },
            extensionsRequired: true,
        },
        'widget.bar.calls_by_agent_per_tags': {
            aggs: {
                calls_by_agent: {
                    agg_type: 'terms',
                    field: 'extension.keyword',
                    options: {
                        size: 30,
                    },
                    aggs: {
                        calls_by_tags: {
                            agg_type: 'terms',
                            field: 'tags.keyword',
                        },
                    },
                },
            },
            component: {
                component: DynamicBarGraph,
                params: {
                    x: 'Agents',
                    y: 'Calls',
                    indexBy: 'Agent',
                    tick: BarExtensionTick,
                },
            },
            data: {
                type: 'ES',
                parser: (data, extensions) =>
                    parseBarAgentCallsByTags(data.calls_by_agent, extensions),
            },
            extensionsRequired: true,
        },
    },
    'widget.progress': {
        'widget.progress.calls_by_agent': {
            aggs: {
                calls_by_agent: {
                    agg_type: 'terms',
                    field: 'extension.keyword',
                },
            },
            component: {
                component: LinearProgressBar,
            },
            data: {
                type: 'ES',
                parser: (data, extensions) =>
                    parseProgressDataByAgent(data.calls_by_agent, extensions),
            },
            header: {
                format: true,
                item: true,
                max: true,
                multi: true,
            },
            extensionsRequired: true,
        },
        'widget.progress.calls_by_status': {
            aggs: {
                calls_by_status: {
                    agg_type: 'terms',
                    field: 'action.keyword',
                },
            },
            component: {
                component: LinearProgressBar,
            },
            data: {
                type: 'ES',
                parser: (data) => parseProgressData(data.calls_by_status),
            },
            header: {
                format: true,
                item: true,
                max: true,
                multi: true,
            },
        },
    },
    'widget.gauge': {
        'widget.gauge.calls_gauge': {
            aggs: {
                calls_gauge: {
                    agg_type: 'terms',
                    field: 'action.keyword',
                },
            },
            component: {
                component: ProgressGauge,
            },
            data: {
                type: 'ES',
                parser: (data) => parseProgressData(data.calls_gauge),
            },
            header: {
                format: false,
                max: true,
            },
        },
    },
    'widget.value': {
        'widget.value.calls_amount': {
            aggs: {
                calls_amount: {
                    agg_type: 'terms',
                    field: 'action.keyword',
                },
            },
            component: {
                component: ProgressValue,
            },
            data: {
                type: 'ES',
                parser: (data) => parseProgressData(data.calls_amount),
            },
        },
    },
    'widget.table': {
        'widget.table.queue_members': {
            aggs: {},
            component: {
                component: QueueList,
            },
            data: {
                type: 'queue',
                parser: dataPassThrough,
            },
            header: {
                format: false,
                item: true,
                max: false,
            },
        },
    },
};

export default WidgetOptions;
