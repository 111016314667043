import React, {useEffect, useState} from 'react';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomerPhoneNumbers} from '../../../../store/selectors/customer-selectors';
import {getUser, getUserTravel} from '../../../../store/selectors/user-selectors';
import {formatShortAmericanNumber} from '../../../../utils/format';
import {
    fetchCustomerPhoneNumbers,
    fetchCustomerUsers,
} from '../../../../store/actions/customer-action';
import CardContent from '../../../../components/content/CardContent';
import {Action, ActionsCell, BooleanCell} from '../../../../components/layout/Table/Cells';
import {Table} from '../../../../components/layout/Table/Table';
import SidePanel from '../../../../components/panel/SidePanel';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import Spinner from '../../../../components/spinner/Spinner';
import LightFontAwesomeIcon from '../../../../components/utils/LightFontAwesomeIcon';
import {Has} from '../../../../components/security/Has';
import ContentWrapper from '../../../../components/content/ContentWrapper';
import PhoneNumbersConfigsForm from './Form/PhoneNumbersConfigsForm';
import {FormattedMessage} from 'react-intl';

const phone_numbers_columns = [
    {
        id: 'did',
        title: (
            <FormattedMessage
                id={'navbar.admin.phone_numbers.did_number'}
                defaultMessage={'DID Number'}
            />
        ),
        cellFactory: ({did}) => formatShortAmericanNumber(did),
        sort: {key: 'did'},
        width: 'minmax(200px, 300px)',
    },
    {
        id: 'rewriteDID',
        title: (
            <FormattedMessage
                id={'navbar.admin.phone_numbers.rewrite_did'}
                defaultMessage={'Rewrite DID'}
            />
        ),
        cellFactory: ({rewrite_did}) => rewrite_did,
        width: '200px',
    },
    {
        id: 'rate',
        title: <FormattedMessage id={'navbar.admin.phone_numbers.rate'} defaultMessage={'Rate'} />,
        cellFactory: ({bill}) => bill,
        width: '200px',
    },
    {
        id: 'note',
        title: 'Note',
        cellFactory: ({note}) => note,
        width: '250px',
    },
    {
        id: 'enabled',
        title: <FormattedMessage id={'title.table.enabled'} defaultMessage={'Active'} />,
        cellFactory: ({enabled}) => <BooleanCell enabled={enabled} />,
        width: '50px',
    },
];

const PhoneNumbersConfig = () => {
    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [phoneNumbersConfig, setPhoneNumbersConfig] = useState({});
    const loading = useSelector(getLoading('phone_numbers'));
    const {customer} = useSelector(getUser);
    const travel = useSelector(getUserTravel);

    const currentCustomer = travel?.customer || customer.id;

    const phone_numbers = useSelector(getCustomerPhoneNumbers);
    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch(fetchCustomerPhoneNumbers());
        reduxDispatch(fetchCustomerUsers());
    }, [reduxDispatch, currentCustomer]);

    const create = () => {
        setPhoneNumbersConfig({});
        setSidePanelOpen(true);
    };

    const cancel = () => {
        setPhoneNumbersConfig({});
        setSidePanelOpen(false);
    };

    const edit = (phone_numbers_config) => {
        setPhoneNumbersConfig(phone_numbers_config);
        setSidePanelOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='table-header no-select'>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage
                                id='message.admin.phone_numbers.title'
                                defaultMessage={`Phone Numbers Configurations`}
                            />
                        </h2>
                        <Has oneOfTheseRoles={['super administrator', 'customer service agent']}>
                            <Button
                                className={'align-self-center mr-auto'}
                                color={'primary'}
                                onClick={create}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1 p-0'}
                    >
                        {loading ? (
                            <Spinner
                                centered
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <Table
                                itemSize={55}
                                items={phone_numbers}
                                columns={[
                                    ...phone_numbers_columns,
                                    {
                                        id: 'edit',
                                        title: '',
                                        cellFactory: (config) => (
                                            <ActionsCell>
                                                <Has
                                                    oneOfTheseRoles={[
                                                        'super administrator',
                                                        'customer service agent',
                                                    ]}
                                                >
                                                    <Action
                                                        onClick={() => edit(config)}
                                                        icon={'pen'}
                                                    />
                                                </Has>
                                            </ActionsCell>
                                        ),
                                        width: 'auto',
                                        className: 'justify-content-end',
                                    },
                                ]}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={isSidePanelOpen}
                setOpen={setSidePanelOpen}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <PhoneNumbersConfigsForm
                    phoneNumberProps={phoneNumbersConfig}
                    toggleSidePanel={cancel}
                    currentCustomer={currentCustomer}
                />
            </SidePanel>
        </>
    );
};

export default PhoneNumbersConfig;
