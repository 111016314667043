import React from 'react';
import * as PropTypes from 'prop-types';

const IdleAnimation = ({animation, children, speed}) =>
    <span className={`animated ${animation} ${speed}`}>
        {children}
    </span>;

IdleAnimation.propTypes = {
    animation: PropTypes.string.isRequired,
    speed: PropTypes.oneOf(
        ['delay-1s', 'delay-2s', 'delay-3s', 'delay-4s', 'delay-5s', 'fast', 'faster', 'slow', 'slower']
    ),
};

IdleAnimation.defaultProps = {
    speed: 'fast',
};

export default IdleAnimation;