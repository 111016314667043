/* Download Actions */
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';
export const DOWNLOAD_CANCEL = 'DOWNLOAD_CANCEL';
export const DOWNLOAD_FILE_CANCEL = 'DOWNLOAD_FILE_CANCEL';
export const LOADING = 'download LOADING';
export const LOADED = 'download LOADED';
export const RESET = 'download RESET';


/* Download Action Creators */
export const createFileDownload = (payload) => ({type: DOWNLOAD_FILE, payload});
export const cancelFileDownload = (payload) => ({type: DOWNLOAD_FILE_CANCEL, payload});
export const loading = (payload) => ({type: LOADING, payload});
export const loaded = (payload) => ({type: LOADED, payload});
export const resetCSVData = (payload) => ({type: RESET, payload});