import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {getCustomerUsers} from '../../../../store/selectors/customer-selectors';
import SelectInput from '../../../../components/input/SelectInput';
import {FormattedMessage} from 'react-intl';
import CheckboxInput from '../../../../components/input/CheckboxInput';
import {Action, ActionsCell} from '../../../../components/layout/Table/Cells';
import {Table} from '../../../../components/layout/Table/Table';
import {Button} from 'reactstrap';

const fax_bindings_columns = [
    {
        id: 'user',
        title: 'User',
        cellFactory: (binding, {users}) =>
            users ? users.find((u) => u.id === binding.user).full_name : '',
        width: 'auto',
    },
    {
        id: 'inbound',
        title: '',
        cellFactory: (binding, {faxBindingsDispatch}) => (
            <span>
                <CheckboxInput
                    className={'w-100 d-flex justify-content-between align-items-center'}
                    label={'Email notifications'}
                    onValueChange={(v) => {
                        return faxBindingsDispatch({
                            type: 'edit',
                            payload: {...binding, user: binding.user, inbound: v},
                        });
                    }}
                    value={binding.inbound}
                />
            </span>
        ),
        width: '200px',
    },
    {
        id: 'edit',
        title: '',
        cellFactory: (binding, {faxBindingsDispatch}) => (
            <ActionsCell>
                <Action
                    icon={'times'}
                    onClick={() =>
                        faxBindingsDispatch({
                            type: 'remove',
                            payload: {user: binding.user},
                        })
                    }
                />
            </ActionsCell>
        ),
        width: '50px',
        className: 'justify-content-end',
    },
];

const FaxBindings = ({did, faxBindingsState, faxBindingsDispatch}) => {
    const users = useSelector(getCustomerUsers);

    const [selectedUser, setSelectedUser] = useState('');

    return (
        <div className={'d-flex flex-column'}>
            <div className={'d-flex w-100 py-2'}>
                <SelectInput
                    className={'pr-1'}
                    onValueChange={setSelectedUser}
                    options={users.map((u) => ({label: u.full_name, value: u.id}))}
                    value={selectedUser}
                />
                <Button
                    color={'primary'}
                    disabled={!!!selectedUser}
                    onClick={() =>
                        faxBindingsDispatch({
                            type: 'add',
                            payload: {user: selectedUser, inbound: true, fax_did: did},
                        })
                    }
                    size={'sm'}
                >
                    <FormattedMessage id={'btn.add'} defaultMessage={'Add'} />
                </Button>
            </div>
            <div
                style={{overflowY: 'auto', height: faxBindingsState.length * 55 + 20}}
                className={'d-flex flex-column flex-grow-1 px-0'}
            >
                <Table
                    columns={fax_bindings_columns}
                    handles={{faxBindingsDispatch, users}}
                    head={false}
                    items={faxBindingsState}
                    itemSize={55}
                    itemsDispatch={(items) => faxBindingsDispatch({type: 'sort', payload: items})}
                />
            </div>
        </div>
    );
};

FaxBindings.propTypes = {};

export default FaxBindings;
