import React, {useState} from 'react';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlane, faRunning, faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import UserAvatar from '../user/UserAvatar';
import {getUser} from '../../store/selectors/user-selectors';
import {travelTo, userLogout} from '../../store/actions/user-action';
import {make_travel_query_string} from '../../utils/history';
import {useQuery} from '../../utils/hooks';
import {Has} from '../security/Has';
import Working from '../notification/Working';

const DropdownItemLink = (props) => {
    return (
        <DropdownItem tag={Link} {...props}>
            {props.children}
        </DropdownItem>
    );
};

const TopNavbar = () => {
    const user = useSelector(getUser);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const query = useQuery();

    const reduxDispatch = useDispatch();

    return (
        <>
            <Navbar color={'transparent'}>
                <Working />
                <div className={'d-flex flex-row align-items-baseline ml-auto'}>
                    {user.travel && (
                        <>
                            <Button
                                className={'d-flex flex-row align-items-center'}
                                color={'primary'}
                                onClick={() => {
                                    reduxDispatch(travelTo());
                                    history.push({
                                        pathname: history.pathname,
                                        search: make_travel_query_string(query, null),
                                    });
                                }}
                                outline
                                size={'sm'}
                            >
                                <FontAwesomeIcon icon={faPlane} className={'mr-1'} />
                                <FormattedMessage
                                    id={'message.travelling.to.customer'}
                                    defaultMessage={'Travelling to {customer}'}
                                    values={{customer: user.travel.customer}}
                                />
                                <FontAwesomeIcon className={'ml-1 cursor-pointer'} icon={faTimes} />
                            </Button>
                        </>
                    )}
                </div>
                <Has forbidTravel>
                    <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
                        <DropdownToggle tag='span'>
                            <UserAvatar onClick={() => true} size={'sm'} user={user} />
                        </DropdownToggle>
                        <DropdownMenu flip className={'mt-3'}>
                            <DropdownItem header tag='div'>
                                <FormattedMessage
                                    id='navbar.user.menu.welcome'
                                    defaultMessage='Welcome {name}'
                                    values={{name: user.nickname}}
                                >
                                    {(txt) => <h6 className='text-overflow m-0'>{txt}</h6>}
                                </FormattedMessage>
                            </DropdownItem>
                            <DropdownItemLink to='/profile/me'>
                                <FontAwesomeIcon icon={faUser} />
                                <FormattedMessage
                                    id='navbar.user.menu.myprofile'
                                    defaultMessage='My Profile'
                                />
                            </DropdownItemLink>
                            <a
                                className='cursor-pointer cursor-pointer dropdown-item'
                                href='https://support.croo.io/hc/en-us'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <i className='ni ni-support-16' />
                                <FormattedMessage
                                    id='navbar.user.menu.support'
                                    defaultMessage='Support'
                                />
                            </a>
                            <DropdownItem divider />
                            <DropdownItem
                                className='cursor-pointer'
                                onClick={() => reduxDispatch(userLogout())}
                            >
                                <FontAwesomeIcon icon={faRunning} />
                                <FormattedMessage
                                    id='navbar.user.menu.logout'
                                    defaultMessage='Logout'
                                />
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Has>
            </Navbar>
        </>
    );
};

export default TopNavbar;
