import {faSignature} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage} from 'react-intl';
import React from 'react';
import TextInput from '../../input/TextInput';

const messages = defineMessages({
    display: {
        id: 'filter.display.name',
        defaultMessage: 'Name contains: {name}',
    },
    title: {
        id: 'filter.title.name',
        defaultMessage: 'Name',
    },
});

const factory = (name) => ({
    type: 'name',
    index: `name_${name}`,
    serialized: name,
    custom_filter: (query) => {
        query.filters.name = query.filters.name ? [name, ...query.filters.name] : [name];
        return query;
    },
    elastic_filter: (query) =>
        name
            .split(/[ ,.-]/)
            .filter((w) => w.length > 0)
            .reduce(
                (query, word) =>
                    query.filter('wildcard', 'caller_id_name', `*${word.toLowerCase()}*`),
                query
            ),
    display: {
        icon: faSignature,
        content: <FormattedMessage {...messages.display} values={{name}} />,
    },
});

export const name_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: TextInput,
    componentProps: {
        placeholder: 'Name',
        validator: (name) => name && name !== '',
    },
    factory: factory,
    icon: faSignature,
};
