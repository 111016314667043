import React, {useEffect, useReducer, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomers} from '../../store/selectors/customer-selectors';
import {fetchCustomers} from '../../store/actions/customer-action';
import CardContent from '../../components/content/CardContent';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import LightFontAwesomeIcon from '../../components/utils/LightFontAwesomeIcon';
import SearchBar from '../../components/search/SearchBar';
import Spinner from '../../components/spinner/Spinner';
import ContentWrapper from '../../components/content/ContentWrapper';
import {getLoading} from '../../store/selectors/portal-selectors';
import isEqual from 'lodash.isequal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Table} from '../../components/layout/Table/Table';
import SidePanel from '../../components/panel/SidePanel';
import CustomerForm from './CustomerForm';
import {Action, ActionsCell} from '../../components/layout/Table/Cells';
import {travelTo} from '../../store/actions/user-action';
import {useHistory} from 'react-router-dom';
import {Has} from '../../components/security/Has';

const columns = [
    {
        id: 'id',
        title: <FormattedMessage id={'message.customer.id'} defaultMessage={'Customer ID'} />,
        cellFactory: ({id}) => (
            <a href={`https://${id}.konexim.net`} target={'_blank'} rel='noreferrer'>
                {id}
            </a>
        ),
        sort: {key: 'id'},
        width: 'minmax(200px, 300px)',
    },
    {
        id: 'name',
        title: <FormattedMessage id={'message.customer.name'} defaultMessage={'Name'} />,
        key: 'name',
        sort: {key: 'name'},
        width: 'minmax(300px, 500px)',
    },
    {
        id: 'active',
        title: <FormattedMessage id={'message.customer.active'} defaultMessage={'Active'} />,
        cellFactory: ({active}) => (
            <FontAwesomeIcon
                icon={active ? faCheck : faTimes}
                style={{color: active ? 'green' : 'red'}}
            />
        ),
        width: 'auto',
    },
];

const Customers = () => {
    const customers = useSelector(getCustomers);
    const loading = useSelector(getLoading('customers'));
    const history = useHistory();
    const reduxDispatch = useDispatch();

    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [foundCustomers, foundCustomersDispatch] = useReducer((state, action) => {
        return isEqual(state, action.payload) ? state : action.payload;
    }, customers);

    useEffect(() => {
        reduxDispatch(fetchCustomers());
    }, [reduxDispatch]);

    const create = () => {
        setCustomer(null);
        setSidePanelOpen(true);
    };

    const cancel = () => {
        setCustomer(null);
        setSidePanelOpen(false);
    };

    const edit = (api_key) => {
        setCustomer(api_key);
        setSidePanelOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='d-flex flex-row align-items-baseline border-bottom-0'>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage
                                id={'navbar.admin.customers'}
                                defaultMessage={'Customers'}
                            />
                        </h2>
                        <Has theRole={'super administrator'}>
                            <Button
                                className={'mr-auto align-self-center'}
                                color={'primary'}
                                onClick={() => create()}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                        <FormattedMessage id={'input.placeholder.search'} defaultMessage={'Search'}>
                            {(msg) => (
                                <SearchBar
                                    className={'mr-auto'}
                                    fields={['id', 'name']}
                                    items={customers}
                                    live
                                    minimumCharacters={3}
                                    setFoundItems={(f) => foundCustomersDispatch({payload: f})}
                                    placeholder={msg}
                                />
                            )}
                        </FormattedMessage>
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1 p-0'}
                    >
                        {loading ? (
                            <Spinner
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <Table
                                itemSize={55}
                                columns={[
                                    ...columns,
                                    {
                                        id: 'edit',
                                        title: '',
                                        cellFactory: (customer) => (
                                            <ActionsCell>
                                                <Action
                                                    onClick={() => {
                                                        reduxDispatch(travelTo(customer.id));
                                                        history.push('/');
                                                    }}
                                                    icon={'plane-departure'}
                                                />
                                                <Action
                                                    onClick={() => edit(customer)}
                                                    icon={'pen'}
                                                />
                                            </ActionsCell>
                                        ),
                                        width: '50px',
                                        className: 'justify-content-end',
                                    },
                                ]}
                                items={foundCustomers}
                                itemsDispatch={(items) => {
                                    foundCustomersDispatch({payload: items});
                                }}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={isSidePanelOpen}
                setOpen={setSidePanelOpen}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <CustomerForm customer={customer} toggle={cancel} />
            </SidePanel>
        </>
    );
};

Customers.propTypes = {};

export default Customers;
