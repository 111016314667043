import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {faHashtag} from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
    display: {
        id: 'filter.display.id',
        defaultMessage: 'ID: {id}',
    },
    title: {
        id: 'filter.title.id',
        defaultMessage: 'ID',
    },
});

const factory = (id) => ({
    type: 'id',
    index: 'id',
    serialized: id,
    custom_filter: (query) => {
        query.filters.id = id;
        return query;
    },
    elastic_filter: (query) => query.filter('term', 'call_id', id),
    display: {
        icon: faHashtag,
        content: <FormattedMessage {...messages.display} values={{id}} />,
    },
});

export const id_filter = {
    factory: factory,
};
