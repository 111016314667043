import React, {ReactElement, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {Collapse, NavItem, NavLink} from 'reactstrap';

interface FormSectionProps {
    children?: any;
    icon?: any;
    isOpenByDefault?: boolean;
    opens?: boolean;
    title?: any | ReactElement;
    component?: any;
    paddingLeft?: string;
    titleClass?: string;
}

const FormSection = ({
    children,
    icon,
    isOpenByDefault,
    opens,
    title,
    component,
    paddingLeft,
    titleClass,
}: FormSectionProps) => {
    const [isOpen, setOpen] = useState(isOpenByDefault);

    const toggle = () => {
        setOpen(!isOpen);
    };

    const style = {
        paddingLeft: paddingLeft,
    };

    return (
        <NavItem tag={'div'}>
            <NavLink
                onClick={toggle}
                className={`py-2 d-flex flex-row justify-content-start align-items-center ${
                    opens ? 'cursor-pointer' : ''
                }`}
            >
                <FontAwesomeIcon
                    fixedWidth
                    icon={isOpen ? faAngleDown : faAngleRight}
                    className={'mr-2'}
                    color={opens ? '#E0007A' : 'transparent'}
                />
                <div className={'d-flex justify-content-between w-100'}>
                    <div className={titleClass}>
                        {icon && (
                            <FontAwesomeIcon
                                fixedWidth
                                className={'mr-1 text-primary'}
                                icon={icon}
                            />
                        )}
                        {title}
                    </div>
                    {component}
                </div>
            </NavLink>
            <Collapse isOpen={isOpen} style={style}>
                {children}
            </Collapse>
        </NavItem>
    );
};

FormSection.defaultProps = {
    icon: null,
    opens: true,
    isOpenByDefault: true,
};

export default FormSection;
