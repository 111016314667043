import {formatSecondsToHour} from '../../utils/format';
import React from 'react';
import moment from 'moment';
import {
    CallHistoryActionsCell,
    NameCell,
    NumberCell,
    StatusCell,
    TagsCell,
    UserCell,
    ParticipantHistoryCell,
} from './CallsTableCells';
import {FormattedMessage} from 'react-intl';
import CallTimestampCell from './CallTimestampCell';

export const columns = [
    {
        id: 'status',
        title: '',
        cellFactory: (call, handles) => (
            <StatusCell
                call={call}
                statusFilterDispatch={handles.statusFilterDispatch}
                directionFilterDispatch={handles.directionFilterDispatch}
            />
        ),
        width: '30px',
    },
    {
        id: 'timestamp',
        title: <FormattedMessage id={'filter.title.date'} defaultMessage={'Date'} />,
        cellFactory: (call) => <CallTimestampCell timestamp={call.timestamp} />,
        sort: {
            comparator: (call1, call2) =>
                moment(call1.timestamp).toDate() - moment(call2.timestamp).toDate(),
        },
        width: 'minmax(150px, 250px)',
    },
    {
        id: 'name',
        title: <FormattedMessage id={'filter.title.name'} defaultMessage={'Name'} />,
        cellFactory: (call, handles) => (
            <NameCell call={call} nameFilterDispatch={handles.nameFilterDispatch} />
        ),
        sort: {key: 'name'},
        width: 'minmax(150px, 230px)',
    },
    {
        id: 'number',
        title: (
            <FormattedMessage
                id={'filter.title.external.number'}
                defaultMessage={'External phone number'}
            />
        ),
        cellFactory: (call, handles) => (
            <NumberCell call={call} numberFilterDispatch={handles.numberFilterDispatch} />
        ),
        width: 'minmax(140px, 250px)',
    },
    {
        id: 'extension',
        title: <FormattedMessage id={'filter.title.extension'} defaultMessage={'Extension'} />,
        cellFactory: (call, handles) => (
            <UserCell call={call} extensionFilterDispatch={handles.extensionFilterDispatch} />
        ),
        sort: {key: 'extension'},
        width: 'minmax(170px, 220px)',
    },
    {
        id: 'duration',
        title: <FormattedMessage id={'filter.title.duration'} defaultMessage={'Duration'} />,
        cellFactory: (call) => formatSecondsToHour(call.duration),
        sort: {key: 'duration'},
        width: 'minmax(75px, 95px)',
    },
    {
        id: 'queue_time',
        title: <FormattedMessage id={'tabs.reports.hold.times'} defaultMessage={'Hold Time'} />,
        cellFactory: (call) => formatSecondsToHour(call.queue_time),
        sort: {key: 'queue_time'},
        width: 'minmax(85px, 105px)',
    },
    {
        id: 'tags',
        title: <FormattedMessage id={'title.table.filter.tags'} defaultMessage={'Tags'} />,
        cellFactory: (call, handles) => (
            <TagsCell
                call={call}
                tagFilterDispatch={handles.tagFilterDispatch}
                activeTags={handles.activeTags}
            />
        ),
        width: 'minmax(250px, auto)',
        style: {
            overflow: 'visible',
        },
    },
    {
        id: 'participant_history',
        cellFactory: (call) => <ParticipantHistoryCell callId={call.id} isNewHistory={false}/>,
        width: '50px',
    },
    {
        id: 'recording',
        cellFactory: (call) => <CallHistoryActionsCell call={call} />,
        width: '80px',
        className: 'justify-content-end',
    },
];
