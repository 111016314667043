import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';

const EntranceExitAnimation = ({entrance, exit, children, show, speed}) => {

    const [shouldRender, setShouldRender] = useState(show);

    useEffect(() => {
        if (show) setShouldRender(true);
    }, [setShouldRender, show]);

    const onAnimationEnd = () => {
        if (!show) setShouldRender(false);
    };

    return shouldRender && <span
        style={{display: 'inline-block', transitionProperty: 'all', transition: 'transform'}}
        className={`animated ${show ? entrance : exit} ${speed}`}
        onAnimationEnd={onAnimationEnd}
        onTransitionEnd={onAnimationEnd}
    >
        {children}
    </span>;
};

EntranceExitAnimation.propTypes = {
    entrance: PropTypes.string,
    exit: PropTypes.string,
    show: PropTypes.bool.isRequired,
    speed: PropTypes.oneOf(
        ['delay-1s', 'delay-2s', 'delay-3s', 'delay-4s', 'delay-5s', 'fast', 'faster', 'slow', 'slower']
    ),
};

EntranceExitAnimation.defaultProps = {
    entrance: 'fadeIn',
    exit: 'fadeOut',
    speed: 'fast',
};

export default EntranceExitAnimation;