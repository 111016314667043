import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Responsive, WidthProvider} from 'react-grid-layout';
import AutoSizer from 'react-virtualized-auto-sizer';
import WidgetGridHeader from './WidgetGridHeader';
import {userPreferencesSelector} from '../../store/selectors/user-selectors';
import WidgetHeader from './WidgetHeader';
import WidgetFactory from './factories/WidgetFactory';
import {defaultLayouts, defaultMasterFilters} from './utils/config';
import {
    LayoutToRGLConverter,
    RGLToLayoutConverter,
    getInitialCurrentLayout,
} from './utils/layoutFunctions';
import isEqual from 'lodash.isequal';
import {updateDashboard, userConfirmProfile} from '../../store/actions/user-action';
import {useDeepState, useInterval} from '../../utils/hooks';
import {useThrottle} from 'react-use';
import {Card, CardBody, CardHeader} from 'reactstrap';

const ResponsiveGridLayout = WidthProvider(Responsive);

const WidgetGrid = ({title}) => {
    const reduxDispatch = useDispatch();
    const dashboardPreferences = useSelector(userPreferencesSelector('dashboard', 'v1'));

    const [refreshFlag, setRefreshFlag] = useState(false);
    const debouncedRefreshFlag = useThrottle(refreshFlag, 2000);

    const [layouts, setLayouts] = useDeepState(
        dashboardPreferences
            ? dashboardPreferences.layouts
                ? dashboardPreferences.layouts
                : defaultLayouts
            : defaultLayouts
    );

    const [masterFilters, setMasterFilters] = useDeepState(
        dashboardPreferences
            ? dashboardPreferences.masterFilters
                ? dashboardPreferences.masterFilters
                : defaultMasterFilters
            : defaultMasterFilters
    );

    const [currentLayout, setCurrentLayout] = useDeepState(
        getInitialCurrentLayout(
            dashboardPreferences?.layouts,
            dashboardPreferences?.currentLayout,
            defaultLayouts
        )
    );

    const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
    const [currentWidget, setCurrentWidget] = useDeepState({});

    const [editMode, setEditMode] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const convertLayoutToRGL = (editMode) => {
        return LayoutToRGLConverter(currentBreakpoint, layouts, currentLayout, editMode);
    };

    const convertRGLToLayout = (layout) => {
        return RGLToLayoutConverter(layout, layouts, currentLayout, currentBreakpoint);
    };

    const combineFilters = (widgetFilters) => {
        if (Object.keys(masterFilters).includes('date')) {
            if (Object.keys(widgetFilters).includes('date')) return widgetFilters;
            else return {...widgetFilters, date: masterFilters['date']};
        } else return widgetFilters;
    };

    const updateLayouts = (newLayouts) => {
        if (!isEqual(layouts, newLayouts)) {
            reduxDispatch(
                updateDashboard({
                    preferences: {
                        preferences: JSON.stringify({
                            dashboard: {
                                v1: {
                                    ...dashboardPreferences,
                                    layouts: newLayouts,
                                },
                            },
                        }),
                    },
                })
            );
            setLayouts(newLayouts);
        }
    };

    const updateMasterFilters = (newMasterFilters) => {
        if (!isEqual(masterFilters, newMasterFilters)) {
            reduxDispatch(
                updateDashboard({
                    preferences: {
                        preferences: JSON.stringify({
                            dashboard: {
                                v1: {
                                    ...dashboardPreferences,
                                    masterFilters: newMasterFilters,
                                },
                            },
                        }),
                    },
                })
            );
            setMasterFilters(newMasterFilters);
        }
    };

    const updateCurrentLayout = (newCurrentLayout) => {
        if (!isEqual(currentLayout, newCurrentLayout)) {
            reduxDispatch(
                updateDashboard({
                    preferences: {
                        preferences: JSON.stringify({
                            dashboard: {
                                v1: {
                                    ...dashboardPreferences,
                                    currentLayout: newCurrentLayout,
                                },
                            },
                        }),
                    },
                })
            );
            setCurrentLayout(newCurrentLayout);
        }
    };

    const refreshPage = () => {
        reduxDispatch(userConfirmProfile());
        setRefreshFlag(!refreshFlag);
    };

    useInterval(refreshPage, 300000);

    return (
        <>
            <CardHeader className={'d-flex flex-column'}>
                <WidgetGridHeader
                    title={title}
                    layouts={layouts}
                    setLayouts={updateLayouts}
                    masterFilters={masterFilters}
                    setMasterFilters={updateMasterFilters}
                    currentLayout={currentLayout}
                    setCurrentLayout={updateCurrentLayout}
                    currentWidget={currentWidget}
                    setCurrentWidget={setCurrentWidget}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    onRefresh={refreshPage}
                />
            </CardHeader>
            <CardBody style={{overflowY: 'auto'}} className={'d-flex flex-column flex-grow-1 p-0'}>
                <div className={'flex-grow-1'} style={{height: 80}}>
                    <AutoSizer disableWidth>
                        {({height}) => {
                            if (height < 500) height = 500 / 2 - 15;
                            else height = height / 2 - 15;
                            return (
                                <div className={'d-flex flex-column'}>
                                    <ResponsiveGridLayout
                                        className={'layout flex-grow-1'}
                                        layouts={convertLayoutToRGL(editMode)}
                                        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                        cols={{lg: 2, md: 2, sm: 1, xs: 1, xxs: 1}}
                                        useCSSTransforms
                                        onBreakpointChange={(breakpoint) => {
                                            setCurrentBreakpoint(breakpoint);
                                        }}
                                        onLayoutChange={(layout) => {
                                            updateLayouts({
                                                ...layouts,
                                                [currentLayout]: convertRGLToLayout(layout),
                                            });
                                        }}
                                        rowHeight={height}
                                    >
                                        {layouts[currentLayout].map((widget) => {
                                            return (
                                                <div key={widget.i}>
                                                    <Card
                                                        style={
                                                            editMode
                                                                ? {
                                                                      cursor: 'pointer',
                                                                      height:
                                                                          height * widget.height +
                                                                          (widget.height - 1) * 10 +
                                                                          'px',
                                                                  }
                                                                : {
                                                                      height:
                                                                          height * widget.height +
                                                                          (widget.height - 1) * 10 +
                                                                          'px',
                                                                  }
                                                        }
                                                    >
                                                        <CardHeader
                                                            className={
                                                                'border-bottom-0 d-flex flex-row align-items-baseline justify-content-between'
                                                            }
                                                        >
                                                            <WidgetHeader
                                                                widget={widget}
                                                                editMode={editMode}
                                                                layouts={layouts}
                                                                setLayouts={updateLayouts}
                                                                currentLayout={currentLayout}
                                                                setCurrentWidget={setCurrentWidget}
                                                                setModalOpen={setModalOpen}
                                                            />
                                                        </CardHeader>
                                                        <CardBody className={'flex-grow-1 w-100'}>
                                                            <div
                                                                style={{
                                                                    height:
                                                                        height * widget.height - 90,
                                                                }}
                                                                className={'responsive-chart'}
                                                            >
                                                                <WidgetFactory
                                                                    id={widget.i}
                                                                    type={widget.dataType}
                                                                    filters={combineFilters(
                                                                        widget.filters
                                                                    )}
                                                                    extras={widget.extras}
                                                                    refreshFlag={
                                                                        debouncedRefreshFlag
                                                                    }
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            );
                                        })}
                                    </ResponsiveGridLayout>
                                </div>
                            );
                        }}
                    </AutoSizer>
                </div>
            </CardBody>
        </>
    );
};

export default WidgetGrid;
