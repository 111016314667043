import {faTag, faTags} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage} from 'react-intl';
import React, {useEffect, useState} from 'react';
import ButtonsSelectInput from '../../input/ButtonsSelectInput';
import {useSelector} from 'react-redux';
import {getCallsTags} from '../../../store/selectors/cdr-selectors';
import * as PropTypes from 'prop-types';

const messages = defineMessages({
    display: {
        id: 'filter.display.tag',
        defaultMessage: '{tag}',
    },
    title: {
        id: 'filter.title.tag',
        defaultMessage: 'Tag',
    },
});

const TagFilter = ({size, onValueChange}) => {
    const tags = useSelector(getCallsTags);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(
            tags && tags.length > 0
                ? tags.sort().map((t) => ({label: t, value: t, icon: faTag}))
                : []
        );
    }, [tags]);

    return (
        <ButtonsSelectInput
            onValueChange={(v) => onValueChange(v, true)}
            options={options}
            size={size}
        />
    );
};

TagFilter.propTypes = {
    onValueChange: PropTypes.func.isRequired,
    size: PropTypes.string,
};

TagFilter.defaultProps = {
    size: 'sm',
};

const factory = (tag) => ({
    type: 'tag',
    index: `tag_${tag}`,
    serialized: tag,
    custom_filter: (query) => {
        query.filters.tags = query.filters.tags ? [tag, ...query.filters.tags] : [tag];
        return query;
    },
    elastic_filter: (query) => query.filter('match', 'tags.keyword', tag),
    display: {
        icon: faTag,
        color: 'default',
        content: <FormattedMessage {...messages.display} values={{tag}} />,
    },
});

export const tag_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: TagFilter,
    auto: true,
    factory: factory,
    icon: faTags,
};
