import {defineMessages} from 'react-intl';

export const trad = defineMessages({
    addNumber: {
        id: 'message.add.number',
        defaultMessage: 'Add number',
    },
    modificationNumber: {
        id: 'message.modification.number',
        defaultMessage: 'Edit a number',
    },
    advanced: {
        id: 'message.admin.advanced',
        defaultMessage: 'Advanced',
    },
    deletePhoneNumber: {
        id: 'message.admin.phone_number.delete',
        defaultMessage: 'Delete phone number',
    },
    warningPhoneNumber: {
        id: 'message.admin.phone_number.warning',
        defaultMessage: 'This phone number will immediately no longer be usable for calling.',
    },
    warningModalPhoneNumber: {
        id: 'message.admin.phone_number.warning.modal',
        defaultMessage:
            'Are you sure you wish to delete this phone number? This operation cannot be undone.',
    },
    validate: {
        id: 'btn.validate',
        defaultMessage: 'Validate',
    },
    configPhoneNumber: {
        id: 'message.admin.phone_number.config',
        defaultMessage: 'Phone number configuration',
    },
    prefill: {
        id: 'message.admin.phone_number.prefill',
        defaultMessage: 'Pre-fill form for toll-free',
    },
});
