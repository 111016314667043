import {STORE_LOCALE} from 'store/actions/i18n-action'
import {formatNavLang} from 'utils/format'

let defaultLanguage = formatNavLang((navigator.languages && navigator.languages[0]) || navigator.language)

const initialState = {
    locale: defaultLanguage,
};

export default function i18nReducer (state = initialState, action) {
    switch (action.type) {
        case STORE_LOCALE:
            return {...state, locale: action.payload};
        default:
            return state ;
    }
}