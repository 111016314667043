import React, {useEffect} from 'react';
import Spinner from '../../../components/spinner/Spinner';
import {Table} from '../../../components/layout/Table/Table';
import {useDispatch, useSelector} from 'react-redux';
import {getLoading} from '../../../store/selectors/portal-selectors';
import {fetchIntegrationUsers} from '../../../store/actions/customer-action';
import {
    customerExtensionsSelector,
    customerIntegrationBindingSelector,
    customerIntegrationUsersSelector,
} from '../../../store/selectors/customer-selectors';
import {hasPolicy} from '../../../components/security/Has';
import {getUser} from '../../../store/selectors/user-selectors';
import {Action, ActionsCell} from '../../../components/layout/Table/Cells';
import {defineMessages, injectIntl} from 'react-intl';
import SelectInput from '../../../components/input/SelectInput';

const messages = defineMessages({
    placeholder: {
        id: 'input.integration.user.placeholder',
        defaultMessage: 'Select a {title} user to bind to this extension',
    },
});

const columns = [
    {
        title: 'Full name',
        key: 'name',
        sort: {key: 'name'},
        width: 'minmax(300px, 400px)',
    },
    {
        title: 'Extension',
        key: 'extension',
        sort: {key: 'extension'},
        width: 'auto',
    },
    {
        title: 'Connected user',
        cellFactory: (user, handles) => (
            <SelectInput
                enabled={
                    hasPolicy(handles.currentUser, 'write', 'integrations') && !handles.disabled
                }
                value={handles.getValueInBindings(user.extension)}
                onValueChange={(v) => handles.changeBinding(user.extension)(v)}
                placeholder={handles.intl.formatMessage(messages.placeholder, {
                    title: handles.title,
                })}
                options={
                    handles.users
                        ? Object.keys(handles.users).map((user) => ({
                              label: handles.users[user].name + ' - ' + handles.users[user].email,
                              value: user,
                          }))
                        : []
                }
            />
        ),
        width: '400px',
    },
    {
        title: '',
        cellFactory: (user, handles) => (
            <ActionsCell>
                <Action
                    enabled={!handles.disabled}
                    onClick={() => {
                        handles.deleteBinding(
                            user.extension,
                            handles.getValueInBindings(user.extension)
                        );
                    }}
                    icon={'trash'}
                />
            </ActionsCell>
        ),
        width: '50px',
    },
];

const IntegrationBindings = ({
    integration,
    name,
    updateIntegrationBinding,
    deleteIntegrationBinding,
    intl,
    title,
    disabled,
}) => {
    const loadingUsers = useSelector(getLoading(`${name}_users`));
    const loadingBinding = useSelector(getLoading(`${name}_binding`));
    const currentUser = useSelector(getUser);

    const extensions = useSelector(customerExtensionsSelector);
    const bindings = useSelector(customerIntegrationBindingSelector(name));
    const users = useSelector(customerIntegrationUsersSelector(name));

    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch(fetchIntegrationUsers(name));
    }, [name, reduxDispatch]);

    const getValueInBindings = (extension) => {
        if (!(!integration || !integration.bindings || !integration.bindings[extension])) {
            return integration.bindings[extension];
        }
    };

    const changeBinding = (extension) => (value) => {
        updateIntegrationBinding({
            name: name,
            extension,
            value: value,
        });
    };

    const deleteBinding = (extension, value) => {
        deleteIntegrationBinding({
            name: name,
            extension,
            value,
        });
    };

    return (
        <>
            {loadingUsers || loadingBinding ? (
                <Spinner
                    centered
                    color={'primary'}
                    className={'align-self-center'}
                    background={'transparent'}
                    global={false}
                    size={50}
                />
            ) : (
                <Table
                    columns={columns}
                    itemSize={55}
                    items={extensions}
                    handles={{
                        getValueInBindings: getValueInBindings,
                        changeBinding: changeBinding,
                        deleteBinding,
                        bindings: bindings,
                        users: users,
                        currentUser: currentUser,
                        intl,
                        title,
                        disabled: disabled || loadingBinding,
                    }}
                />
            )}
        </>
    );
};

IntegrationBindings.defaultProps = {
    disabled: false,
};

export default injectIntl(IntegrationBindings);
