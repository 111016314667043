import React from 'react';

const WhatsNewChangelistItem = (props) => {
    return props.title ? (
        <li className={'mb-1'}>
            <strong>{props.title} - </strong> {props.children}
        </li>
    ) : (
        <li className={'mb-1'}>{props.children}</li>
    );
};

WhatsNewChangelistItem.propTypes = {
    //
};

export default WhatsNewChangelistItem;
