import React, {useState} from 'react';
import moment from 'moment';
import {ACTIVITY_ICONS, ACTIVITY_MESSAGES} from '../../../components/journeys/ActivityTimeline';
import {FormattedMessage} from 'react-intl';
import {formatAlphanumericString} from '../../../utils/format';
import * as PropTypes from 'prop-types';
import {arrayToObjectByGrouper} from '../../../utils';
import {Popover, PopoverBody} from 'reactstrap';
import AutoSizer from 'react-virtualized-auto-sizer';

const getEventPosition = (journeyStart, activityTime, journeyDuration, lifelineWidth) => {
    const activityDuration = moment.duration(activityTime.diff(journeyStart)).asSeconds();
    const percentageOfLifeline = activityDuration / journeyDuration;
    return percentageOfLifeline * lifelineWidth;
};

const getSegmentLength = (activityStartTime, activityEndTime, journeyDuration, lifelineWidth) => {
    const activityDuration = moment.duration(activityEndTime.diff(activityStartTime)).asSeconds();
    const percentageOfLifeline = activityDuration / journeyDuration;
    return percentageOfLifeline * lifelineWidth;
};

const activityTime = (activity) => moment(activity.action.time);

const hasStatus = (activity, status) => activity.action.status === status;
const isInstant = (activity) => hasStatus(activity, 'instant');
const isProgressive = (activity) => !isInstant(activity);

const LIFELINE_HEIGHT = 10;

const JourneyEvent = ({activities, position}) => {
    // eslint-disable-line

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const icon =
        activities.length > 1
            ? activities.length
            : ACTIVITY_ICONS[activities[0].action.type](
                  formatAlphanumericString(`event-icon-id-${activities[0].action.id}`)
              );

    return (
        <>
            <span
                id={formatAlphanumericString(
                    `lifeline-event-popover-id-${activities[0].action.id}-${activities.length}`
                )}
                className={'d-flex align-items-center bg-white rounded-circle with-shadow'}
                style={{
                    width: LIFELINE_HEIGHT * 3,
                    height: LIFELINE_HEIGHT * 3,
                    left: position,
                    position: 'absolute',
                    transform: 'translate(-10%, -40%)',
                }}
            >
                <small
                    className={'d-flex flex-row w-100 justify-content-around'}
                    style={{fontWeight: 600}}
                >
                    {icon}
                </small>
            </span>
            {
                <Popover
                    placement={'top'}
                    isOpen={isPopoverOpen}
                    toggle={() => setIsPopoverOpen(!isPopoverOpen)}
                    trigger={'hover'}
                    target={formatAlphanumericString(
                        `lifeline-event-popover-id-${activities[0].action.id}-${activities.length}`
                    )}
                >
                    <PopoverBody>
                        {activities.map((a, index) => {
                            const titleMessage =
                                a.action.status === 'start' || a.action.status === 'end'
                                    ? ACTIVITY_MESSAGES[`${a.action.type}_${a.action.status}`]
                                    : ACTIVITY_MESSAGES[a.action.type];

                            return (
                                <span className={'d-flex flex-row pb-1'} key={index}>
                                    <span className={'mr-3'}>
                                        {ACTIVITY_ICONS[a.action.type](
                                            formatAlphanumericString(`event-icon-id-${a.action.id}`)
                                        )}
                                    </span>
                                    <span className={'flex-grow-1 mr-3'}>
                                        <FormattedMessage {...titleMessage} />
                                    </span>
                                    <span>{moment(a.action.time).format('hh:mm A')}</span>
                                </span>
                            );
                        })}
                    </PopoverBody>
                </Popover>
            }
        </>
    );
};

const JourneySegment = ({busy, popoverActivities, width}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <>
            <span
                id={
                    popoverActivities.length > 0
                        ? formatAlphanumericString(
                              `lifeline-popover-id-${popoverActivities[0].action.id}-${popoverActivities.length}`
                          )
                        : undefined
                }
                style={{width: width, height: LIFELINE_HEIGHT}}
                className={busy ? 'bg-primary' : 'bg-secondary'}
            />
            {popoverActivities.length > 0 && (
                <Popover
                    placement={'top'}
                    isOpen={isPopoverOpen}
                    toggle={() => setIsPopoverOpen(!isPopoverOpen)}
                    trigger={'hover'}
                    target={formatAlphanumericString(
                        `lifeline-popover-id-${popoverActivities[0].action.id}-${popoverActivities.length}`
                    )}
                >
                    <PopoverBody>
                        {popoverActivities.map((a, index) => {
                            const titleMessage =
                                a.action.status === 'start' || a.action.status === 'end'
                                    ? ACTIVITY_MESSAGES[`${a.action.type}_${a.action.status}`]
                                    : ACTIVITY_MESSAGES[a.action.type];

                            return (
                                <span className={'d-flex flex-row pb-1'} key={index}>
                                    <span className={'mr-3'}>
                                        {ACTIVITY_ICONS[a.action.type](
                                            formatAlphanumericString(
                                                `lifeline-icon-id-${popoverActivities[0].action.id}-${popoverActivities.length}`
                                            )
                                        )}
                                    </span>
                                    <span className={'flex-grow-1 mr-3'}>
                                        <FormattedMessage {...titleMessage} />
                                    </span>
                                    <span>{moment(a.action.time).format('hh:mm A')}</span>
                                </span>
                            );
                        })}
                    </PopoverBody>
                </Popover>
            )}
        </>
    );
};

const JourneyLifeline = ({activities, startTime, endTime}) => {
    const sortedActivities = activities
        .filter(
            (a) =>
                (startTime == null || activityTime(a) > startTime) &&
                (endTime == null || activityTime(a) < endTime)
        )
        .sort((a, b) => activityTime(a) - activityTime(b));

    const middleActivities = [...sortedActivities];
    const firstActivity = middleActivities.shift();
    const lastActivity = middleActivities.pop();

    const firstStart = sortedActivities.filter((a) => hasStatus(a, 'start'))[0];
    const lastEnd = sortedActivities.filter((a) => hasStatus(a, 'end')).reverse()[0];

    if (firstStart == null || lastEnd == null) {
        return <small className={'text-muted'}>Not enough activity to generate lifeline</small>;
    }

    const barStart = startTime || activityTime(firstActivity).startOf('minute');
    const barEnd = endTime || activityTime(lastActivity).endOf('minute');
    const barLength = moment.duration(barEnd.diff(barStart)).asSeconds();

    let busyCount = 0;
    let popoverElements = {};

    const isBound = (activity) => {
        const associated_activities = activities.filter(
            (a) => isProgressive(a) && a.action.id === activity.action.id
        );
        return (
            associated_activities.length === 2 &&
            associated_activities.some((a) => hasStatus(a, 'start')) &&
            associated_activities.some((a) => hasStatus(a, 'end'))
        );
    };

    const progressiveActivities = sortedActivities.filter(isBound);
    const instantActivities = arrayToObjectByGrouper(sortedActivities.filter(isInstant), (a) =>
        activityTime(a).hour()
    );

    return (
        <>
            <small>{barStart.format('hh:mm A')}</small>
            <span className={'px-4 d-flex flex-row flex-grow-1'} style={{height: LIFELINE_HEIGHT}}>
                <AutoSizer className={'d-flex flex-grow-1 h-100'} disableHeight>
                    {({width}) => {
                        return (
                            <>
                                <span
                                    className={'bg-secondary'}
                                    style={{
                                        width: getSegmentLength(
                                            barStart,
                                            activityTime(firstStart),
                                            barLength,
                                            width
                                        ),
                                        height: LIFELINE_HEIGHT,
                                    }}
                                />
                                {Object.values(instantActivities).map(
                                    (a, index) =>
                                        a.length > 0 && (
                                            <JourneyEvent
                                                key={index}
                                                activities={a}
                                                position={getEventPosition(
                                                    barStart,
                                                    activityTime(a[0]),
                                                    barLength,
                                                    width
                                                )}
                                            />
                                        )
                                )}
                                {progressiveActivities.map((a, index) => {
                                    let segment;
                                    if (a.action.status === 'start') {
                                        busyCount++;
                                        popoverElements[a.action.id] = a;
                                    }
                                    if (a.action.status === 'end') {
                                        busyCount--;
                                        delete popoverElements[a.action.id];
                                    }
                                    if (index < progressiveActivities.length - 1) {
                                        segment = (
                                            <JourneySegment
                                                key={index}
                                                busy={busyCount > 0}
                                                popoverActivities={Object.values(popoverElements)}
                                                width={getSegmentLength(
                                                    activityTime(a),
                                                    activityTime(progressiveActivities[index + 1]),
                                                    barLength,
                                                    width
                                                )}
                                            />
                                        );
                                    } else {
                                        segment = (
                                            <JourneySegment
                                                key={index}
                                                busy={busyCount > 0}
                                                popoverActivities={Object.values(popoverElements)}
                                                width={getSegmentLength(
                                                    activityTime(a),
                                                    activityTime(lastEnd),
                                                    barLength,
                                                    width
                                                )}
                                            />
                                        );
                                    }
                                    return segment;
                                })}
                                <span
                                    style={{
                                        width: getSegmentLength(
                                            activityTime(lastEnd),
                                            barEnd,
                                            barLength,
                                            width
                                        ),
                                        height: LIFELINE_HEIGHT,
                                    }}
                                    className={'bg-secondary'}
                                />
                            </>
                        );
                    }}
                </AutoSizer>
            </span>
            <small>{barEnd.format('hh:mm A')}</small>
        </>
    );
};

JourneyLifeline.propTypes = {
    activities: PropTypes.array,
};

JourneyLifeline.defaultProps = {
    activities: [],
};

export default JourneyLifeline;
