import React, {useEffect} from 'react';
import routes from './routes';
import {NavLink as NavLinkRRD, Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {Nav, Navbar, NavItem, NavLink} from 'reactstrap';
import ReportSwitch from './ReportSwitch';
import {useDispatch, useSelector} from 'react-redux';

import {
    fetchCustomerAgents,
    fetchCustomerExtensions,
    fetchCustomerQueues,
    fetchCustomerUsers,
} from '../../../store/actions/customer-action';
import ContentWrapper from '../../../components/content/ContentWrapper';
import {getUserTravel, getUserCustomerLicense} from '../../../store/selectors/user-selectors';

const Reports = () => {
    let {path, url} = useRouteMatch();

    const travelling = useSelector(getUserTravel);
    const customerLicense = useSelector(getUserCustomerLicense);

    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch(fetchCustomerQueues({include_system_queues: false}));
        reduxDispatch(fetchCustomerAgents({include_system_agents: false}));
        reduxDispatch(fetchCustomerExtensions());
        reduxDispatch(fetchCustomerUsers());
    }, [reduxDispatch, travelling]);

    return (
        <ContentWrapper scroll={false} limitedWidth={false}>
            {customerLicense === 'PRO' ? (
                <Redirect
                    to={{
                        pathname: '/license',
                        state: {
                            access: 'reports',
                        },
                    }}
                />
            ) : (
                <>
                    <Navbar expand={'md'} className={'small'} container={false}>
                        <Nav navbar>
                            {routes.map((route) => {
                                return (
                                    <NavItem key={route.name}>
                                        <NavLink
                                            id={route.name}
                                            tag={NavLinkRRD}
                                            to={url + route.path}
                                        >
                                            {route.title}
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    </Navbar>
                    <Switch>
                        {routes.map((route) => (
                            <Route path={path + route.path} key={route.path}>
                                <ReportSwitch route={route} />
                            </Route>
                        ))}
                        <Route path={'/'} render={() => <Redirect to={path + routes[0].path} />} />
                    </Switch>
                </>
            )}
        </ContentWrapper>
    );
};

export default Reports;
