import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../media/image/Avatar';

const UserAvatar = ({className, id, onClick, size, user}) => (
    <Avatar
        className={className}
        id={id}
        onClick={onClick}
        size={size}
        src={user ? user.avatar : undefined}
        alt={user ? user.full_name : undefined}
    />
);

UserAvatar.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    user: PropTypes.shape({
        nickname: PropTypes.string.isRequired,
        avatar: PropTypes.string,
    }),
};

UserAvatar.defaultProps = {
    size: 'md',
};

export default UserAvatar;
