import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faUserClock,
    faUser,
    faEarth,
    faClock,
    faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {Action, ActionsCell} from '../layout/Table/Cells';
import {copyToClipboard} from '../../utils/clipboard';
import {secondaryToast} from '../../store/actions/portal-action';
import {useDispatch, useSelector} from 'react-redux';
import InformationModal from '../notification/InformationModal';
import {FormattedMessage} from 'react-intl';
import {Button, Form, FormGroup} from 'reactstrap';
import {createPbxAdminUsers} from '../../store/actions/customer-action';
import {getUserName, getUserCustomer} from '../../store/selectors/user-selectors';
import {getPbxAdminUsers} from '../../store/selectors/customer-selectors';
import moment from 'moment';
import {getLoading} from '../../store/selectors/portal-selectors';

const SidebarPbxAdmin = () => {
    const reduxDispatch = useDispatch();

    const username = useSelector(getUserName);
    const userCustomer = useSelector(getUserCustomer);
    const pbxAdminUser = useSelector(getPbxAdminUsers);
    const loadingPbxAdminUsers = useSelector(getLoading(`pbx_admin_users`, false));
    const [pbxUserToggle, setPbxUserToggle] = useState(false);

    const handleClick = () => {
        if (!pbxUserToggle) {
            reduxDispatch(createPbxAdminUsers({customer_id: userCustomer.id, username: username}));
        }
        setPbxUserToggle(!pbxUserToggle);
    };

    const createPbxUrl = () => {
        return 'https://' + userCustomer.id + '.konexim.net/';
    };

    const createPbxUrlLogin = () => {
        return 'https://' + userCustomer.id + '.konexim.net/admin/config.php';
    };

    const getTimeFromTimer = () => {
        let today = new Date();
        today.setHours(today.getHours() + 1);
        let format_time = moment(today).format('HH:mm');
        return format_time;
    };

    const getLoginButton = () => {
        return (
            <Button color={'primary'} disabled={loadingPbxAdminUsers} size={'sm'} type={'submit'}>
                <FormattedMessage id={'btn.signIn'} defaultMessage={'Sign in'} />
            </Button>
        );
    };

    const modal = () => {
        return (
            <InformationModal
                isOpen={pbxUserToggle}
                toggle={() => handleClick()}
                size={'sm'}
                title={
                    <div>
                        <FormattedMessage
                            id={'message.admin_sidebar.pbx_user_cred.title'}
                            defaultMessage={'PBX administrator credentials'}
                        />
                    </div>
                }
                content={
                    <div>
                        <Form action={createPbxUrlLogin()} method={'POST'} target='_blank'>
                            <FormGroup>
                                <label>
                                    <FontAwesomeIcon icon={faEarth} className={'pr-1'} />

                                    <FormattedMessage
                                        id={'message.admin_sidebar.pbx_user_cred.url'}
                                        defaultMessage={'<b>URL</b>'}
                                        values={{
                                            b: (chunks) => <strong>{chunks}</strong>,
                                        }}
                                    />
                                </label>
                                <div className={'d-flex'}>
                                    <a
                                        className={'mr-2'}
                                        href={createPbxUrl()}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {createPbxUrl()}
                                    </a>
                                    <ActionsCell>
                                        <Action
                                            icon={'copy'}
                                            onClick={() => {
                                                copyToClipboard(createPbxUrl());
                                                reduxDispatch(
                                                    secondaryToast({
                                                        content: 'Link copied to clipboard',
                                                    })
                                                );
                                            }}
                                        />
                                    </ActionsCell>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                    <FontAwesomeIcon icon={faUser} className={'pr-1'} />
                                    <FormattedMessage
                                        id={'message.admin_sidebar.pbx_user_cred.user'}
                                        defaultMessage={'<b>User</b>'}
                                        values={{
                                            b: (chunks) => <strong>{chunks}</strong>,
                                        }}
                                    />
                                </label>
                                <div className={'d-flex'}>
                                    <input
                                        type='hidden'
                                        name='username'
                                        value={pbxAdminUser.username}
                                    />
                                    <span className={'mr-2'}>
                                        {!loadingPbxAdminUsers ? pbxAdminUser.username : ''}
                                    </span>
                                    {!loadingPbxAdminUsers ? (
                                        <ActionsCell>
                                            <Action
                                                icon={'copy'}
                                                onClick={() => {
                                                    copyToClipboard(
                                                        pbxAdminUser.username
                                                            ? pbxAdminUser.username
                                                            : ''
                                                    );
                                                    reduxDispatch(
                                                        secondaryToast({
                                                            content: 'Link copied to clipboard',
                                                        })
                                                    );
                                                }}
                                            />
                                        </ActionsCell>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                    <FontAwesomeIcon icon={faUserSecret} className={'pr-1'} />
                                    <FormattedMessage
                                        id={'message.admin_sidebar.pbx_user_cred.password'}
                                        defaultMessage={'<b>Password</b>'}
                                        values={{
                                            b: (chunks) => <strong>{chunks}</strong>,
                                        }}
                                    />
                                </label>
                                <div className={'d-flex'}>
                                    <span className={'mr-2'}>
                                        <input
                                            type='hidden'
                                            name='password'
                                            value={pbxAdminUser.password}
                                        />
                                        {!loadingPbxAdminUsers ? pbxAdminUser.password : ''}
                                    </span>
                                    {!loadingPbxAdminUsers ? (
                                        <ActionsCell>
                                            <Action
                                                icon={'copy'}
                                                onClick={() => {
                                                    copyToClipboard(
                                                        pbxAdminUser.password
                                                            ? pbxAdminUser.password
                                                            : ''
                                                    );
                                                    reduxDispatch(
                                                        secondaryToast({
                                                            content: 'Link copied to clipboard',
                                                        })
                                                    );
                                                }}
                                            />
                                        </ActionsCell>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                    <FontAwesomeIcon icon={faClock} className={'pr-1'} />
                                    <FormattedMessage
                                        id={'message.admin_sidebar.pbx_user_cred.timer'}
                                        defaultMessage={'<b>Timer</b>'}
                                        values={{
                                            b: (chunks) => <strong>{chunks}</strong>,
                                        }}
                                    />
                                </label>
                                <div className={'d-flex'}>
                                    {!loadingPbxAdminUsers ? getTimeFromTimer() : ''}
                                </div>
                            </FormGroup>
                            {getLoginButton()}
                        </Form>
                    </div>
                }
            />
        );
    };

    return (
        <div className={'d-flex flex-column justify-content-center cursor-pointer nav-link'}>
            <FontAwesomeIcon icon={faUserClock} onClick={handleClick} />
            {modal()}
        </div>
    );
};

export default SidebarPbxAdmin;
