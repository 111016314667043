import React, {useEffect, useReducer} from 'react';
import * as PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "reactstrap";


const ButtonsSelectInput = ({multi, onValueChange, options, value, enabled}) => {

    const [state, dispatch] = useReducer((state, value) => {
        if (multi) {
            state.hasChanged = true;
            state[value] = !state[value];
            return {...state}
        }
        return value
    }, multi ? Object.assign({}, ...options.map(o => ({[o.value]: value.includes(o.value)}))) : value);

    useEffect(() => {
        if (multi && state && state.hasChanged) onValueChange(Object.keys(state).filter(k => k !== 'hasChanged' && state[k]));
        if (!multi && state) onValueChange(state)
    }, [multi, state, onValueChange]);

    return (
        <div className={'d-flex flex-grow-1 flex-wrap mt--1'}>
            {options.map((option, index) =>
                <Button
                    className={'mt-1'}
                    color={option.color ? option.color : 'default'}
                    disabled={!enabled}
                    key={index}
                    onClick={() => dispatch(option.value)}
                    outline
                    size={'sm'}
                    active={multi && state[option.value]}
                >
                    {option.icon && <FontAwesomeIcon icon={option.icon} className={"mr-1"}/>}
                    {option.label ? option.label : option.value}
                </Button>)}
        </div>
    );
};

ButtonsSelectInput.propTypes = {
    enabled: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        icon: PropTypes.any,
        color: PropTypes.string
    })),
};

ButtonsSelectInput.defaultProps = {
    enabled: true,
    options: [],
};

export default ButtonsSelectInput;