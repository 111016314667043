import React, {useEffect, useState} from 'react';
import JourneyCard from './JourneyCard';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomerJourneys, getCustomerUsers} from '../../store/selectors/customer-selectors';
import Spinner from '../spinner/Spinner';
import GridList from '../layout/GridList/GridList';
import {fetchCustomerJourneys, fetchCustomerUsers} from '../../store/actions/customer-action';
import JourneyLongCard from './JourneyLongCard';
import {Has} from '../security/Has';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHiking, faTimes} from '@fortawesome/free-solid-svg-icons';
import {DateInput} from '../input/DateInput';
import moment from 'moment-timezone';
import {getUserTravel} from '../../store/selectors/user-selectors';
import CheckboxInput from '../input/CheckboxInput';
import SelectInput from '../input/SelectInput';
import {arrayFromRange} from '../../utils';
import {FormattedMessage} from 'react-intl';
import {getDefaultTimeZone} from '../../utils/datetime';

const JourneysList = () => {
    const [date, setDate] = useState(moment());
    const [dynamic, setDynamic] = useState(false);
    const [startHour, setStartHour] = useState(8);
    const [endHour, setEndHour] = useState(18);
    const [user, setUser] = useState(null);

    const travel = useSelector(getUserTravel);
    const journeys = useSelector(getCustomerJourneys);
    const users = useSelector(getCustomerUsers);
    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch(fetchCustomerUsers());
        reduxDispatch(
            fetchCustomerJourneys({
                start_time: moment(date).startOf('day').format('YYYY-MM-DD'),
                end_time: moment(date).startOf('day').add(1, 'days').format('YYYY-MM-DD'),
                time_zone: getDefaultTimeZone(),
            })
        );
    }, [date, reduxDispatch, travel]);

    const spinner = (
        <Spinner color={'primary'} background={'transparent'} global={false} size={50} />
    );

    return (
        <>
            <CardHeader className={'border-bottom-0 d-flex flex-row align-items-baseline'}>
                <h2 className={'mr-2'}>
                    <FormattedMessage id={'title.journeys'} defaultMessage={'Journeys'} />
                </h2>
                <DateInput onValueChange={setDate} value={moment(date).format('YYYY-MM-DD')} />
                {user ? (
                    <Button
                        className={'d-flex flex-row align-items-center ml-2'}
                        color={'primary'}
                        onClick={() => setUser(null)}
                        outline
                        size={'sm'}
                    >
                        <FontAwesomeIcon icon={faHiking} className={'mr-1'} />
                        <FormattedMessage
                            id={'title.user.journey'}
                            defaultMessage={"{user}'s Journey"}
                            values={{user: user.full_name}}
                        />
                        <FontAwesomeIcon className={'ml-1 cursor-pointer'} icon={faTimes} />
                    </Button>
                ) : (
                    <>
                        <CheckboxInput
                            className={'ml-4 mr-2'}
                            label={
                                <FormattedMessage
                                    id={'label.dynamic.time'}
                                    defaultMessage={'Dynamic Time'}
                                />
                            }
                            onValueChange={setDynamic}
                            value={dynamic}
                        />
                        {!dynamic && (
                            <>
                                <span style={{width: 200}}>
                                    <SelectInput
                                        className={'mx-1'}
                                        onValueChange={setStartHour}
                                        value={startHour}
                                        options={arrayFromRange(endHour).map((h) => ({
                                            label: moment()
                                                .startOf('day')
                                                .add(h, 'hours')
                                                .format('hh:mm A'),
                                            value: h,
                                        }))}
                                    />
                                </span>
                                <span className={'px-1'}> - </span>
                                <span style={{width: 200}}>
                                    <SelectInput
                                        className={'mx-1'}
                                        onValueChange={setEndHour}
                                        value={endHour}
                                        options={arrayFromRange(24 - startHour, startHour + 1).map(
                                            (h) => ({
                                                label: moment()
                                                    .startOf('day')
                                                    .add(h, 'hours')
                                                    .format('hh:mm A'),
                                                value: h,
                                            })
                                        )}
                                    />
                                </span>
                            </>
                        )}
                    </>
                )}
            </CardHeader>
            <CardBody style={{overflowY: 'auto'}} className={'d-flex flex-column flex-grow-1'}>
                <Has loaded={['users', 'journeys']} orElse={spinner}>
                    {user ? (
                        <JourneyLongCard date={date} user={user} />
                    ) : (
                        <GridList
                            items={journeys}
                            itemComponent={(journey) => (
                                <JourneyCard
                                    journey={journey}
                                    key={journey.ids.user_id}
                                    onExpand={() =>
                                        setUser(users.find((u) => u.id === journey.ids.user_id))
                                    }
                                    startTime={
                                        !dynamic
                                            ? moment(date).startOf('day').add(startHour, 'hours')
                                            : null
                                    }
                                    endTime={
                                        !dynamic
                                            ? moment(date).startOf('day').add(endHour, 'hours')
                                            : null
                                    }
                                />
                            )}
                            itemKey={(journey) => journey.ids.user_id}
                            itemWidth={1000}
                            itemHeight={185}
                            style={{overflowX: 'visible'}}
                        />
                    )}
                </Has>
            </CardBody>
        </>
    );
};

JourneysList.propTypes = {};

export default JourneysList;
