import {
    DOWNLOAD_FILE,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_FAIL,
    DOWNLOAD_CANCEL,
    LOADED,
    LOADING,
    RESET
} from 'store/actions/download-action';

const initialState = {
    ready: false,
    file: {},
    error: null,
    loading: false,
    progress: 0,
    abort: false,
    totalCalls: 0
};


export default function DownloadReducer(state = initialState, action) {

    switch (action.type) {
        case DOWNLOAD_FILE:
            return {...state, ready: false, loading: true, ...action.payload};
        case DOWNLOAD_FILE_SUCCESS:
            return {...state, ready:true, loading: false, file: action.file};
        case DOWNLOAD_FILE_FAIL:
            return {...state, ready: false, loading: false, error: action.error};
        case LOADING:
            return {...state, ready: false, loading: true, progress: action.progress, totalCalls: action.totalCalls};
        case LOADED:
            return {
                ...state,
                loading: false,
                ready: true
            };
        case RESET:
            return {
                ...initialState,
                progress: 0,
                abort: action.abort
            };
        case DOWNLOAD_CANCEL:
            return {
                ...initialState,
                abort: action.abort
            };
        default:
            return state;
    }


}
