import React from 'react';
import {FormattedMessage} from 'react-intl';

import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const InformationModal = ({content, isOpen, onClosed, title, toggle, size}) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            size={size}
            className={'modal-error modal-dialog-centered'}
        >
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{content}</ModalBody>
            <ModalFooter>
                <FormattedMessage id='btn.close' defaultMessage={'Close'}>
                    {(m) => (
                        <Button color={'link'} onClick={toggle}>
                            {m}
                        </Button>
                    )}
                </FormattedMessage>
            </ModalFooter>
        </Modal>
    );
};

export default InformationModal;
