import React, {useEffect} from 'react';
import {useObjectReducer} from '../../../../../utils/hooks';
import {Form, FormGroup, Label} from 'reactstrap';
import SelectInput from '../../../../../components/input/SelectInput';
import {defineMessages, injectIntl, WrappedComponentProps} from 'react-intl';
import {getLoading} from '../../../../../store/selectors/portal-selectors';
import {useSelector} from 'react-redux';

const messages = defineMessages({
    group_calls: {
        id: 'message.group_calls',
        defaultMessage: 'Group Calls',
    },
    per_hour: {
        id: 'message.per_hour',
        defaultMessage: 'Per hour',
    },
    per_day: {
        id: 'message.per_day',
        defaultMessage: 'Per day',
    },
    per_month: {
        id: 'message.per_month',
        defaultMessage: 'Per month',
    },
});

export type Granularity = 'hour' | 'day' | 'month';

export type QueuesOverTimeReportParametersType = {
    granularity: Granularity;
};

const defaultParameters: QueuesOverTimeReportParametersType = {
    granularity: 'day',
};

interface QueuesOverTimeReportParametersProps extends WrappedComponentProps {
    onChange: (p: QueuesOverTimeReportParametersType) => void;
}

const QueuesOverTimeReportParameters = ({onChange, intl}: QueuesOverTimeReportParametersProps) => {
    const loading = useSelector(getLoading('queue_event_statistics'));

    const localizedGranularities = [
        {value: 'hour', label: intl.formatMessage(messages.per_hour)},
        {value: 'day', label: intl.formatMessage(messages.per_day)},
        {value: 'month', label: intl.formatMessage(messages.per_month)},
    ];

    const [parameters, dispatch] = useObjectReducer(defaultParameters);

    useEffect(() => {
        onChange(parameters);
    }, [onChange, parameters]);

    return (
        <Form
            inline
            className={`d-flex flex-column flex-lg-row justify-content-between align-items-stretch align-items-lg-baseline`}
        >
            <FormGroup check inline>
                <Label className={'mr-3'}>{intl.formatMessage(messages.group_calls)}</Label>
                <SelectInput
                    value={parameters.granularity}
                    enabled={!loading}
                    onValueChange={(value: any) =>
                        dispatch({type: 'edit', payload: {field: 'granularity', value}})
                    }
                    options={localizedGranularities}
                />
            </FormGroup>
        </Form>
    );
};

export default injectIntl(QueuesOverTimeReportParameters);
