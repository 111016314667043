export const LayoutToRGLConverter = (currentBreakpoint, layouts, currentLayout, editMode) => {
    if (currentBreakpoint === 'lg' || currentBreakpoint === 'md') {
        let y0 = 0;
        let y1 = 0;
        return {
            [currentBreakpoint]: layouts[currentLayout]
                .sort((widget1, widget2) => {
                    return widget1.order - widget2.order;
                })
                .map((widget) => {
                    let x;
                    let y;

                    if (widget.width === 1) x = y0 > y1 ? 1 : 0;
                    else x = 0;
                    if (widget.width === 1) y = y0 > y1 ? y1 : y0;
                    else y = y0 > y1 ? y0 : y1;

                    let tempItem = {
                        i: widget.i,
                        x: x,
                        y: y,
                        w: widget.width,
                        h: widget.height,
                        isDraggable: editMode,
                    };

                    if (widget.width === 1) {
                        if (x === 0) y0 += widget.height;
                        else y1 += widget.height;
                    } else {
                        if (y0 === y1) {
                            y0 += widget.height;
                            y1 += widget.height;
                        } else if (y0 > y1) y0 += widget.height;
                        else y1 += widget.height;
                    }

                    return tempItem;
                }),
        };
    } else {
        let y = 0;
        return {
            [currentBreakpoint]: layouts[currentLayout]
                .sort((widget1, widget2) => {
                    return widget1.order - widget2.order;
                })
                .map((widget) => {
                    let tempItem = {
                        i: widget.i,
                        x: 0,
                        y: y,
                        w: widget.width,
                        h: widget.height,
                        isDraggable: editMode,
                    };
                    y += widget.height;
                    return tempItem;
                }),
        };
    }
};

export const RGLToLayoutConverter = (layout, genLayouts, currentLayout, currentBreakpoint) => {
    return layout.map((widget) => {
        for (let i = 0; i < genLayouts[currentLayout].length; i++) {
            if (widget.i === genLayouts[currentLayout][i].i)
                return {
                    ...genLayouts[currentLayout][i],
                    order:
                        currentBreakpoint === 'lg' || currentBreakpoint === 'md'
                            ? 2 * widget.y + widget.x
                            : widget.y,
                };
        }
        return null;
    });
};

export const getInitialCurrentLayout = (layouts, currentLayout, defaultLayouts) => {
    if (layouts) {
        if (Object.keys(layouts).length > 0 && currentLayout) {
            if (Object.keys(layouts).some(layoutName => layoutName === currentLayout))
                return currentLayout;
            else return Object.keys(layouts)[0]
        }
    }
    return Object.keys(defaultLayouts)[0];
};
