import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import Main from 'views/Main.jsx';
import {connect, Provider} from 'react-redux';
import store from 'app/store';
import {IntlProvider} from 'react-intl';
import lang from 'locales/lang';
import SignUpRouter from 'views/auth/signup/SignUpRouter';
import NewPassword from 'views/auth/NewPassword';
import Alerts from '../components/notification/Alerts';

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/en');
require('@formatjs/intl-pluralrules/locale-data/fr');
require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/locale-data/en');
require('@formatjs/intl-relativetimeformat/locale-data/fr');

let mapStateToProps = (state) => ({
    locale: state.i18n.locale,
});

// Connect with Redux
const MyIntlProvider = (props) => (
    <IntlProvider
        locale={props.locale}
        defaultLocale='en'
        key={props.locale}
        messages={lang[props.locale]}
    >
        {props.children}
    </IntlProvider>
);
const ConnectedIntlProvider = connect(mapStateToProps)(MyIntlProvider);

// ========================================================
// Wrap our App with Redux and i18n
// ========================================================
const App = () => (
    <Provider store={store}>
        <ConnectedIntlProvider>
            <Router>
                <Alerts />
                <Switch>
                    <Route path='/signup' component={SignUpRouter} />
                    <Route path='/password_reset' component={NewPassword} />
                    <Route path='/' component={Main} />
                    <Route path='*' render={() => <Redirect to='/' />} />
                </Switch>
            </Router>
        </ConnectedIntlProvider>
    </Provider>
);

export default App;
