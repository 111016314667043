import {createSelector} from 'reselect';
import {exist} from 'utils';

export const geti18nLocale = (state) => state.i18n.locale;
export const getUserRegistered = (state) => state.user.registered;
export const getUserConnected = (state) => state.user.connected;
export const getUser = (state) => state.user;
export const getUserPreferences = (state) => state.user.preferences;
export const getUserCustomer = (state) => state.user.customer;

export const getUserCustomerLicense = (state) => {
    if (state.user != null) {
        if (state.user.customer != null) {
            if (state.user.customer.licenses != null) {
                if (state.user.customer.licenses.length > 0) {
                    return state.user.customer.licenses[0].license_type.name;
                }
            }
        }
    }

    return null;
};

export const getUserSession = (state) => state.user.session;
export const getUserId = (state) => state.user.id;
export const getUserNickname = (state) => state.user.nickname;
export const getUserFullName = (state) => state.user.full_name;
export const getUserEmail = (state) => state.user.email;
export const getUserLanguage = (state) => state.user.language;
export const getUserLocale = (state) => state.user.language.code;
export const getUserUrl = (state) => state.user.url;
export const getUserTravel = (state) => state.user.travel;
export const getUserExtension = (state) => state.user.extension;
export const getUserProfileConfirm = (state) => state.user.profileConfirm;
export const getUserTimeZone = (state) => state.user.timezone;
export const getUserName = (state) => state.user.username;
export const getUserLiteCredentials = (state) => state.user.liteCredentials;
export const getUserMissedCallsNotification = (state) => state.user.missed_calls_notification;

export const userRequestCustomerSelector = createSelector(
    [getUserTravel, getUserCustomer],

    (travel, defaultCustomer) => {
        if (travel && travel.customer) return travel.customer;
        else if (defaultCustomer) return defaultCustomer.id;
        else return null;
    }
);

export const userRequestHeaderSelector = createSelector(
    [userRequestCustomerSelector],

    (customer) => {
        let headers = {};
        if (customer) headers['X-Customer'] = customer;
        return headers;
    }
);

export const userRequestAttributesSelector = createSelector(
    [getUserSession, userRequestHeaderSelector, getUserUrl],

    (session, headers, url) => {
        return session && url ? {session, headers, url} : null;
    }
);

export const userProfileSelector = createSelector(
    [
        getUserId,
        getUserNickname,
        getUserFullName,
        getUserExtension,
        getUserEmail,
        getUserLanguage,
        getUserTimeZone,
        getUserMissedCallsNotification,
    ],

    (id, nickname, full_name, extension, email, language, timezone, missed_calls_notification) => ({
        id,
        nickname,
        full_name,
        extension,
        email,
        language,
        timezone,
        missed_calls_notification,
    })
);

export const userTravelingSelector = createSelector([getUserTravel], (travel) => exist(travel));

export const userPreferencesSelector = (preference, version) =>
    createSelector([getUserPreferences], (preferences) => {
        if (preferences) if (preferences[preference]) return preferences[preference][version];
        return null;
    });
