import {defineMessages} from 'react-intl';

/* Messages for translating policy statements into natural language */

export const whoMessages = defineMessages({
    user: {
        id: 'who.user',
        defaultMessage: '{who}',
    },
    team: {
        id: 'who.team',
        defaultMessage: 'Every member of {who}',
    },
    customer: {
        id: 'who.customer',
        defaultMessage: 'Every member of this organization',
    },
});

export const resourceTypeMessages = defineMessages({
    ALL: {
        id: 'resource.name.all',
        defaultMessage: 'any resource',
    },
    API_KEYS: {
        id: 'resource.name.api.keys',
        defaultMessage: 'API keys',
    },
    CALLS: {
        id: 'resource.name.calls',
        defaultMessage: 'calls',
    },
    CALL_RECORDINGS: {
        id: 'resource.name.calls.recordings',
        defaultMessage: 'call recordings',
    },
    CUSTOMERS: {
        id: 'resource.name.customers',
        defaultMessage: 'customers',
    },
    EXTENSIONS: {
        id: 'resource.name.extensions',
        defaultMessage: 'extensions',
    },
    FAXES: {
        id: 'resource.name.faxes',
        defaultMessage: 'faxes',
    },
    IMPORT_DATA: {
        id: 'resource.name.import.data',
        defaultMessage: 'import data',
    },
    INCOMING_SMS_NUMBER: {
        id: 'resource.name.incoming.sms.numbers',
        defaultMessage: 'SMS Configurations',
    },
    INTEGRATIONS: {
        id: 'resource.name.integrations',
        defaultMessage: 'integrations',
    },
    JOURNEYS: {
        id: 'resource.name.journeys',
        defaultMessage: 'user journeys',
    },
    POLICIES: {
        id: 'resource.name.policies',
        defaultMessage: 'policies',
    },
    QUEUE_AGENTS: {
        id: 'resource.name.queue.agents',
        defaultMessage: 'queue agents',
    },
    QUEUE_EVENTS: {
        id: 'resource.name.queue.events',
        defaultMessage: 'queue statistics',
    },
    QUEUES: {
        id: 'resource.name.queues',
        defaultMessage: 'queues',
    },
    ROLES: {
        id: 'resource.name.roles',
        defaultMessage: 'roles',
    },
    SMS: {
        id: 'resource.name.sms',
        defaultMessage: 'SMS',
    },
    TEAMS: {
        id: 'resource.name.teams',
        defaultMessage: 'teams',
    },
    USERS: {
        id: 'resource.name.users',
        defaultMessage: 'users',
    },
    UNKNOWN: {
        id: 'resource.name.unknown',
        defaultMessage: 'an unknown resource',
    },
});

export const actionMessages = defineMessages({
    READ: {
        id: 'message.action.read',
        defaultMessage: 'is allowed to view',
    },
    WRITE: {
        id: 'message.action.write',
        defaultMessage: 'is allowed to create, edit, and delete',
    },
    ALL: {
        id: 'message.action.all',
        defaultMessage: 'is allowed to do anything to',
    },
    UNKNOWN: {
        id: 'message.action.unknown',
        defaultMessage: 'is allowed to do an unknown action',
    },
});

export const scopeMessages = defineMessages({
    OWN: {
        id: 'message.scope.own',
        defaultMessage: 'belonging to themselves',
    },
    OWN_TEAMS: {
        id: 'message.scope.own.teams',
        defaultMessage: 'belonging to members of teams they are part of',
    },
    OWN_CUSTOMERS: {
        id: 'message.scope.own.customers',
        defaultMessage: 'within this organization',
    },
    SPECIFIC_USERS: {
        id: 'message.scope.specific.users',
        defaultMessage: 'belonging to these users:',
    },
    SPECIFIC_TEAMS: {
        id: 'message.scope.specific.teams',
        defaultMessage: 'belonging to members of these teams:',
    },
    SPECIFIC_CUSTOMERS: {
        id: 'message.scope.specific.customers',
        defaultMessage: 'within these organizations:',
    },
    SPECIFIC_QUEUES: {
        id: 'message.scope.specific.queues',
        defaultMessage: 'within these queues:',
    },
    SPECIFIC_QUEUE_AGENTS: {
        id: 'message.scope.specific.queue.agents',
        defaultMessage: 'belonging to these queue agents:',
    },
    SPECIFIC_EXTENSIONS: {
        id: 'message.scope.specific.extensions',
        defaultMessage: 'belonging to these extensions:',
    },
    EVERYONE: {
        id: 'message.scope.everyone',
        defaultMessage: 'anywhere',
    },
    UNKNOWN: {
        id: 'message.scope.unknown',
        defaultMessage: 'within an unknown scope',
    },
});
