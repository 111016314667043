import React from 'react'
import * as queryString from 'query-string'
import Auth from "@aws-amplify/auth";
import WaitingSingUp from 'views/auth/signup/WaitingSingUp'
import UserRegister from 'views/auth/signup/UserOnboarding'

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        let query_params = (queryString.parse(props.location.search));
        let loading = true;
        if (!query_params['login'] || !query_params['token'])
            loading = false;
        else this.signIn(query_params['login'], query_params['token'])
        this.state = {
            loading,
            user: null
        };
    }

    signIn = async (login, token) => {
        try {
            const user = await Auth.signIn(login, token);
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.setState({user});
            } else this.setState({loading: false});
        } catch (e) {
            this.setState({loading: false});
        }
    };

    render = () => <div className="Section__container___3YYTG">
        {this.state.user ? <UserRegister user={this.state.user}/> : <WaitingSingUp loading={this.state.loading}/>}
    </div>
}

export default SignUp;