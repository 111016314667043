import {all, call, cancelled, fork, select, take} from 'redux-saga/effects';

import API from 'utils/api';
import {STORE_ENDPOINT} from 'store/actions/user-action';
import {userRequestAttributesSelector} from 'store/selectors/user-selectors';

import userSagas from 'store/sagas/user-sagas';
import cdrSagas from 'store/sagas/cdr-sagas';
import customerSagas from 'store/sagas/customer-sagas';
import portalSagas from 'store/sagas/portal-sagas';
import downloadSagas from 'store/sagas/download-sagas';
import defaultsDeep from 'lodash.defaultsdeep';

export default function* rootSaga() {
    yield all([...userSagas, ...cdrSagas, ...customerSagas, ...portalSagas, ...downloadSagas]);
}

export function takeFirst(pattern, saga, ...args) {
    return fork(function* () {
        while (true) {
            const action = yield take(pattern);
            yield call(saga, ...args.concat(action));
        }
    });
}

/**
 * @return {null}
 */
export function* APIRequestGenerator(method, endpoint, parameters) {
    const abortController = new AbortController();
    let resp;

    let reqAttributes = yield select(userRequestAttributesSelector);

    while (reqAttributes === null) {
        yield take(STORE_ENDPOINT);
        reqAttributes = yield select(userRequestAttributesSelector);
    }
    try {
        resp = yield call(API[method], {
            ...defaultsDeep(parameters || {}, reqAttributes),
            endpoint,
            signal: abortController.signal,
        });
    } catch (e) {
        if (yield cancelled()) {
            abortController.abort();
        }
        throw e;
    }

    if (resp) {
        return resp;
    } else {
        return null;
    }
}
