import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import moment from 'moment';

class MainFooter extends React.Component {
    render() {
        return (
            <>
                <Container fluid className='bottom-0 text-sm-sm pb-2 no-select'>
                    <Row className='align-items-center justify-content-lg-between'>
                        <Col>
                            <div className='copyright text-left text-muted'>
                                © {moment().year()}{' '}
                                <a
                                    className='font-weight-bold ml-1'
                                    rel='noopener noreferrer'
                                    href='https://www.croo.io'
                                    target='_blank'
                                >
                                    The Croo Group
                                </a>
                            </div>
                        </Col>
                        <Col className='ta-end'>
                            <a
                                className='footer-link'
                                rel='noopener noreferrer'
                                href='https://support.croo.io/hc/en-us/articles/360049088952-Terms-Conditions'
                                target='_blank'
                            >
                                {' '}
                                Terms and Conditions{' '}
                            </a>
                            <a
                                className='footer-link'
                                rel='noopener noreferrer'
                                href='https://support.croo.io/hc/en-us'
                                target='_blank'
                            >
                                {' '}
                                Support{' '}
                            </a>
                            <a
                                className='footer-link'
                                rel='noopener noreferrer'
                                href='https://support.croo.io/hc/en-us/requests/new'
                                target='_blank'
                            >
                                {' '}
                                Contact Us{' '}
                            </a>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default MainFooter;
