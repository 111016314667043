import React from 'react'
import {Card, CardBody, CardHeader, Container} from 'reactstrap'
import {FormattedMessage} from 'react-intl'

const ComingSoon = () => (
    <>
        <Container className="mt--6 d-flex flex-grow-1 flex-column" fluid>
            <Card>
                <CardHeader>
                    <FormattedMessage id="title.coming_soon" defaultMessage="Coming Soon"/>
                </CardHeader>
                <CardBody>

                </CardBody>
            </Card>
        </Container>
    </>
);

export default ComingSoon;