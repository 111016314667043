import React from 'react';
import {Card, CardBody, UncontrolledTooltip} from 'reactstrap';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import LightFontAwesomeIcon from '../utils/LightFontAwesomeIcon';
import UserAvatar from './UserAvatar';
import {useSelector} from 'react-redux';
import {getCustomerTeams} from '../../store/selectors/customer-selectors';
import {getLoading} from '../../store/selectors/portal-selectors';
import {FormattedMessage} from 'react-intl';
import {formatAlphanumericString} from '../../utils/format';

const shortenEmail = (email) => {
    return email.length > 60 ? `${email.slice(0, 60)}...` : email;
};

const UserCard = ({actionComponent, className, onClick, style, user}) => {
    const teams = useSelector(getCustomerTeams);
    const loadingTeams = useSelector(getLoading('teams'));

    return (
        <Card className={className} style={style}>
            <CardBody>
                <div className={'d-flex align-iteml-start h-100'}>
                    <UserAvatar
                        className={'mt-1 flex-grow-0 flex-shrink-0'}
                        onClick={onClick}
                        size={'lg'}
                        user={user}
                    />
                    <div
                        className={
                            'd-flex flex-column flex-grow-1 flex-shrink-1 mx-2 text-truncate'
                        }
                    >
                        <span className={'mr-2 cursor-pointer text-truncate'} onClick={onClick}>
                            {user ? user.full_name : <Skeleton />}
                        </span>
                        <a
                            className={'text-small text-primary text-truncate'}
                            href={user ? `mailto:${user.email}` : '#'}
                        >
                            {user ? shortenEmail(user.email) : <Skeleton />}
                        </a>
                        {user && user.extension && (
                            <span className={'d-flex'}>
                                <span className={'text-muted'}>
                                    <LightFontAwesomeIcon icon={'phone'} size={'xs'} />
                                    <small className={'ml-1'}>{user.extension}</small>
                                </span>
                                {!loadingTeams && teams != null && teams !== [] && (
                                    <span className={'text-muted ml-3'}>
                                        {teams.filter((t) =>
                                            t.team_users.some((tu) => tu.user.id === user.id)
                                        ).length <= 1 ? (
                                            teams
                                                .filter((t) =>
                                                    t.team_users.some(
                                                        (tu) => tu.user.id === user.id
                                                    )
                                                )
                                                .map((t) => (
                                                    <>
                                                        <LightFontAwesomeIcon
                                                            icon={'users'}
                                                            size={'xs'}
                                                        />
                                                        <small className={'text-muted ml-1'}>
                                                            {t.name}
                                                        </small>
                                                    </>
                                                ))
                                        ) : (
                                            <span>
                                                <LightFontAwesomeIcon icon={'users'} size={'xs'} />
                                                <small
                                                    className={'text-muted ml-1'}
                                                    id={formatAlphanumericString(
                                                        `user-teaml-${user.id}`
                                                    )}
                                                >
                                                    {
                                                        teams.filter((t) =>
                                                            t.team_users.some(
                                                                (tu) => tu.user.id === user.id
                                                            )
                                                        ).length
                                                    }{' '}
                                                    <FormattedMessage
                                                        id={'message.teams'}
                                                        defaultMessage={'Teams'}
                                                    />
                                                </small>
                                                <UncontrolledTooltip
                                                    placement={'right'}
                                                    target={formatAlphanumericString(
                                                        `user-teaml-${user.id}`
                                                    )}
                                                >
                                                    {teams
                                                        .filter((t) =>
                                                            t.team_users.some(
                                                                (tu) => tu.user.id === user.id
                                                            )
                                                        )
                                                        .map((t) => t.name)
                                                        .join(', ')}
                                                </UncontrolledTooltip>
                                            </span>
                                        )}
                                    </span>
                                )}
                            </span>
                        )}
                    </div>
                    <div
                        className={'align-self-center flex-grow-0 flex-shrink-0'}
                        style={{marginLeft: 'auto'}}
                    >
                        {actionComponent}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

UserCard.propTypes = {
    actionComponent: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    user: PropTypes.shape({
        avatar: PropTypes.string,
        email: PropTypes.string.isRequired,
        extension: PropTypes.number,
        full_name: PropTypes.string.isRequired,
        nickname: PropTypes.string.isRequired,
    }),
};

export default UserCard;
