import {filters as FILTERS} from '../../call/filters/FilterFactories';
import {tag_filter} from '../../call/filters/Tag';
import WidgetOptions from '../factories/WidgetOptions';
import {useDispatch, useSelector} from 'react-redux';
import {customerExtensionsSelector} from '../../../store/selectors/customer-selectors';
import {getLoadedOnce} from '../../../store/selectors/portal-selectors';
import React, {useEffect} from 'react';
import {fetchCustomerExtensions} from '../../../store/actions/customer-action';
import {formatAlphanumericString} from '../../../utils/format';
import {extension_label} from '../../../utils/call_history';
import {UncontrolledTooltip} from 'reactstrap';

export const getWidgetType = (dataType) => {
    const dataTypeArray = dataType.split('.');
    return dataTypeArray[0] + '.' + dataTypeArray[1];
};

export const getElasticFilters = (filters) => {
    return Object.values(filters).map((f) => {
        const filter = Object.keys(FILTERS).includes(f.type) ? FILTERS[f.type] : tag_filter;
        const result = filter.deserializer
            ? filter.deserializer(f.serialized)
            : filter.factory(f.serialized);
        return result.custom_filter;
    });
};

export const getRawData = (widgetOptions, data) => {
    const result = {};
    Object.keys(widgetOptions.aggs).forEach((name) => {
        result[name] = data != null && data[name] != null ? data[name] : {buckets: []};
    });
    return result;
};

export const getParsedData = (widgetOptions, rawData, extensions, locale) => {
    const extensionsRequired = widgetOptions.extensionsRequired;
    const localeRequired = widgetOptions.localeRequired;
    const dataParser = widgetOptions.data.parser;

    if (extensionsRequired && localeRequired)
        return dataParser(
            rawData,
            extensions ? Object.values(extensions) : undefined,
            locale ? locale : 'en'
        );
    else if (extensionsRequired)
        return dataParser(rawData, extensions ? Object.values(extensions) : undefined);
    else if (localeRequired) return dataParser(rawData, locale ? locale : 'en');
    else return dataParser(rawData);
};

export const formatItem = (type, item) => {
    if (type === 'queue') {
        if (item) {
            if (item['descr']) return item['queue'] + ' - ' + item['descr'];
            else return item['queue'];
        } else return 'Select Queue';
    } else {
        if (item) return item['text'];
        else return 'Select Item';
    }
};

export const getWidgetOptions = (dataType) => {
    const dataTypeArray = dataType.split('.');
    const widgetType = dataTypeArray[0] + '.' + dataTypeArray[1];
    return WidgetOptions[widgetType][dataType];
};

export const isESDataType = (widgetOptions) => widgetOptions.data.type === 'ES';

export const BarExtensionTick = ({y, x, value}) => {
    const extensions = useSelector(customerExtensionsSelector);
    const loadedExtensionsOnce = useSelector(getLoadedOnce('extensions'));

    const reduxDispatch = useDispatch();

    useEffect(() => {
        if (!loadedExtensionsOnce) {
            reduxDispatch(fetchCustomerExtensions());
        }
    }, [reduxDispatch, loadedExtensionsOnce]);

    return (
        <>
            <UncontrolledTooltip
                placement={'top'}
                target={formatAlphanumericString(`tick-at-${value}`)}
            >
                {extension_label(value, extensions)}
            </UncontrolledTooltip>
            <g
                id={formatAlphanumericString(`tick-at-${value}`)}
                transform={`translate(${x},${y + 22})`}
            >
                <text textAnchor='middle' dominantBaseline='middle' style={{fontSize: 10}}>
                    {value}
                </text>
            </g>
        </>
    );
};
