import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {defineMessages, FormattedMessage} from 'react-intl';
import TextInput from '../../input/TextInput';
import {
    faHourglass,
    faHourglassEnd,
    faHourglassHalf,
    faHourglassStart,
} from '@fortawesome/free-solid-svg-icons';
import {formatDuration} from '../../../utils/format';

const MINUTES_CAP = 720;
const SECONDS_CAP = 3600;

const types = defineMessages({
    between: {
        id: 'duration.select.type.between',
        defaultMessage: 'Between',
    },
    shorter: {
        id: 'duration.select.type.shorter',
        defaultMessage: 'Shorter than',
    },
    longer: {
        id: 'duration.select.type.longer',
        defaultMessage: 'Longer than',
    },
});

const messages = defineMessages({
    display_between: {
        id: 'message.duration.between',
        defaultMessage: 'Between {minimum} and {maximum}',
    },
    display_shorter: {
        id: 'message.duration.shorter',
        defaultMessage: 'Shorter than {maximum}',
    },
    display_longer: {
        id: 'message.duration.longer',
        defaultMessage: 'Longer than {minimum}',
    },
    title: {
        id: 'filter.title.duration',
        defaultMessage: 'Duration',
    },
});

const to_seconds = (m = 0, s = 0) => {
    if (m < 0 || s < 0) return 0;
    return 60 * m + s;
};
const from_seconds = (s = 0) => {
    if (s < 0) return [0, 0];
    const minutes = Math.floor(s / 60);
    const seconds = s - 60 * minutes;
    return [minutes, seconds];
};

const consistent_range = (minimum, maximum) => minimum <= maximum;

const validate_shorter = (minimum, maximum) => maximum > 0;

const validate_longer = (minimum) => minimum >= 0;

const validate_between = (minimum, maximum) => {
    return (
        validate_longer(minimum) &&
        validate_shorter(minimum, maximum) &&
        consistent_range(minimum, maximum)
    );
};

const validate_from_type = {
    between: validate_between,
    shorter: validate_shorter,
    longer: validate_longer,
};

export const DurationFilter = ({onValidityChange, onValueChange, onEnter, onEsc}) => {
    const [minimumMinutes, setMinimumMinutes] = useState(undefined);
    const [minimumSeconds, setMinimumSeconds] = useState(undefined);
    const [maximumMinutes, setMaximumMinutes] = useState(undefined);
    const [maximumSeconds, setMaximumSeconds] = useState(undefined);

    const [minimum, setMinimum] = useState();
    const [maximum, setMaximum] = useState();

    const [toggle, setToggle] = useState(false);
    const [type, setType] = useState('between');

    useEffect(() => {
        switch (type) {
            case 'between':
                setMinimumMinutes(undefined);
                setMinimumSeconds(undefined);
                setMaximumMinutes(undefined);
                setMaximumSeconds(undefined);
                break;
            case 'longer':
                setMaximumMinutes(undefined);
                setMaximumSeconds(undefined);
                break;
            case 'shorter':
                setMinimumMinutes(undefined);
                setMinimumSeconds(undefined);
                break;
            default:
                break;
        }
    }, [setMinimumMinutes, setMinimumSeconds, setMaximumMinutes, setMaximumSeconds, type]);

    useEffect(() => {
        setMinimum(to_seconds(minimumMinutes, minimumSeconds));
    }, [minimumMinutes, minimumSeconds, setMinimum]);

    useEffect(() => {
        setMaximum(to_seconds(maximumMinutes, maximumSeconds));
    }, [maximumMinutes, maximumSeconds, setMaximum]);

    const readjustMaximumInputs = (
        minimumMinutes,
        minimumSeconds,
        maximumMinutes,
        maximumSeconds
    ) => {
        if (
            type === 'between' &&
            !consistent_range(
                to_seconds(minimumMinutes, minimumSeconds),
                to_seconds(maximumMinutes, maximumSeconds)
            )
        ) {
            const [newMaximumMinutes, newMaximumSeconds] = from_seconds(
                to_seconds(minimumMinutes, minimumSeconds) + 1
            );
            setMaximumMinutes(newMaximumMinutes);
            setMaximumSeconds(newMaximumSeconds);
        }
    };

    const readjustMinimumInputs = (
        minimumMinutes,
        minimumSeconds,
        maximumMinutes,
        maximumSeconds
    ) => {
        if (
            type === 'between' &&
            !consistent_range(
                to_seconds(minimumMinutes, minimumSeconds),
                to_seconds(maximumMinutes, maximumSeconds)
            )
        ) {
            const [newMinimumMinutes, newMinimumSeconds] = from_seconds(
                to_seconds(maximumMinutes, maximumSeconds) - 1
            );
            setMinimumMinutes(newMinimumMinutes);
            setMinimumSeconds(newMinimumSeconds);
        }
    };

    useEffect(() => {
        onValueChange([minimum, maximum]);
    }, [minimum, maximum, onValueChange]);

    useEffect(() => {
        onValidityChange(validate_from_type[type](minimum, maximum));
    }, [maximum, minimum, onValidityChange, type]);

    return (
        <div className={'flex-grow-1 d-flex flex-row align-items-center'}>
            <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)} size={'sm'}>
                <DropdownToggle className='btn-sm btn-outline-default' caret>
                    <FormattedMessage {...types[type]} />
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(types).map((t, key) => {
                        return (
                            <DropdownItem key={key} onClick={() => setType(t)}>
                                <FormattedMessage {...types[t]} />
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
            {type !== 'shorter' && (
                <>
                    <FormattedMessage id={'message.duration.minutes'} defaultMessage={'Minutes'}>
                        {(m) => (
                            <TextInput
                                className={'mr-1'}
                                onEnter={() => onEnter([minimum, maximum])}
                                onEsc={onEsc}
                                onValueChange={(v) => {
                                    const cappedValue = Math.min(MINUTES_CAP, Number(v));
                                    readjustMaximumInputs(
                                        cappedValue,
                                        minimumSeconds,
                                        maximumMinutes,
                                        maximumSeconds
                                    );
                                    setMinimumMinutes(cappedValue);
                                }}
                                placeholder={m}
                                type={'number'}
                                value={minimumMinutes}
                            />
                        )}
                    </FormattedMessage>
                    <FormattedMessage id={'message.duration.seconds'} defaultMessage={'Seconds'}>
                        {(m) => (
                            <TextInput
                                onEnter={() => onEnter([minimum, maximum])}
                                onEsc={onEsc}
                                onValueChange={(v) => {
                                    const cappedValue = Math.min(SECONDS_CAP, Number(v));
                                    readjustMaximumInputs(
                                        minimumMinutes,
                                        cappedValue,
                                        maximumMinutes,
                                        maximumSeconds
                                    );
                                    setMinimumSeconds(cappedValue);
                                }}
                                placeholder={m}
                                type={'number'}
                                value={minimumSeconds}
                            />
                        )}
                    </FormattedMessage>
                </>
            )}
            {type === 'between' && (
                <span className={'mx-2'}>
                    <FormattedMessage id={'message.and'} defaultMessage={'and'} />
                </span>
            )}
            {type !== 'longer' && (
                <>
                    <FormattedMessage id={'message.duration.minutes'} defaultMessage={'Minutes'}>
                        {(m) => (
                            <TextInput
                                className={'mr-1'}
                                onEnter={() => onEnter([minimum, maximum])}
                                onEsc={onEsc}
                                onValueChange={(v) => {
                                    const cappedValue = Math.min(MINUTES_CAP, Number(v));
                                    readjustMinimumInputs(
                                        minimumMinutes,
                                        minimumSeconds,
                                        cappedValue,
                                        maximumSeconds
                                    );
                                    setMaximumMinutes(cappedValue);
                                }}
                                placeholder={m}
                                type={'number'}
                                value={maximumMinutes}
                            />
                        )}
                    </FormattedMessage>
                    <FormattedMessage id={'message.duration.seconds'} defaultMessage={'Seconds'}>
                        {(m) => (
                            <TextInput
                                onEnter={() => onEnter([minimum, maximum])}
                                onEsc={onEsc}
                                onValueChange={(v) => {
                                    const cappedValue = Math.min(SECONDS_CAP, Number(v));
                                    readjustMinimumInputs(
                                        minimumMinutes,
                                        minimumSeconds,
                                        maximumMinutes,
                                        cappedValue
                                    );
                                    setMaximumSeconds(cappedValue);
                                }}
                                placeholder={m}
                                type={'number'}
                                value={maximumSeconds}
                            />
                        )}
                    </FormattedMessage>
                </>
            )}
        </div>
    );
};

DurationFilter.propTypes = {
    onValidityChange: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    onEsc: PropTypes.func,
};

DurationFilter.defaultProps = {
    onValidityChange: () => true,
    onEnter: () => {},
    onEsc: () => {},
};

const factory = ([minimum, maximum]) => ({
    type: 'duration',
    index: 'duration',
    serialized: `${minimum || ''}_${maximum || ''}`,
    custom_filter: (query) => {
        query.filters.duration = {
            ...(minimum ? {minimum: Number(minimum)} : {}),
            ...(maximum ? {maximum: Number(maximum)} : {}),
        };
        return query;
    },
    elastic_filter: (query) =>
        query.filter('range', 'duration', {
            ...(minimum ? {gte: minimum} : {}),
            ...(maximum ? {lte: maximum} : {}),
        }),
    display: {
        icon: minimum ? (maximum ? faHourglassHalf : faHourglassEnd) : faHourglassStart,
        content: (
            <FormattedMessage
                {...(minimum
                    ? maximum
                        ? messages.display_between
                        : messages.display_longer
                    : messages.display_shorter)}
                values={{
                    ...(minimum ? {minimum: formatDuration(minimum)} : {}),
                    ...(maximum ? {maximum: formatDuration(maximum)} : {}),
                }}
            />
        ),
    },
});

export const duration_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: DurationFilter,
    factory: factory,
    icon: faHourglass,
    deserializer: (s) => {
        try {
            const args = s.split('_');
            if (args.length !== 2) return null;
            const minimum = isNaN(args[0]) ? undefined : args[0];
            const maximum = isNaN(args[1]) ? undefined : args[1];
            return factory([minimum, maximum]);
        } catch (e) {
            return null;
        }
    },
};
