import React, {useMemo, useState} from 'react';
import {
    Alert,
    Badge,
    Nav,
    NavItem,
    NavLink,
    Table,
    UncontrolledCollapse,
    UncontrolledTooltip,
} from 'reactstrap';
import * as moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import columns from './columns';
import {
    getCustomerAgents,
    getCustomerQueues,
    getCustomerUsers,
} from '../../../../../store/selectors/customer-selectors';
import isEqual from 'lodash.isequal';
import {useSelector} from 'react-redux';
import {defineMessages, FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import classnames from 'classnames';
import {generate_palette} from '../../../../../utils/colors';
import {ResponsiveSankey} from '@nivo/sankey';

const messages = defineMessages({
    all_calls: {
        id: 'messages.reports.all.calls',
        defaultMessage: 'All Calls',
    },
    incoming: {
        id: 'message.reports.incoming',
        defaultMessage: 'Incoming',
    },
    answered: {
        id: 'message.reports.answered',
        defaultMessage: 'Answered',
    },
    unanswered: {
        id: 'message.reports.unanswered',
        defaultMessage: 'Unanswered',
    },
    timed_out: {
        id: 'message.reports.timed_out',
        defaultMessage: 'Timed out',
    },
    exited: {
        id: 'message.reports.exited',
        defaultMessage: 'Bailed Out',
    },
    abandoned: {
        id: 'message.reports.abandoned',
        defaultMessage: 'Abandoned',
    },
});

const COLUMNS: Results[] = [
    'answered',
    'timed_out',
    'exited',
    'abandoned',
    'call_time',
    'hold_time',
];

const REDUCERS = {
    call_time: (array: number[]) =>
        array.reduce((total, current) => total + current, 0) / array.length,
    hold_time: (array: number[]) =>
        array.reduce((total, current) => total + current, 0) / array.length,
    answered: (array: number[]) => array.reduce((total, current) => total + current, 0),
    timed_out: (array: number[]) => array.reduce((total, current) => total + current, 0),
    abandoned: (array: number[]) => array.reduce((total, current) => total + current, 0),
    exited: (array: number[]) => array.reduce((total, current) => total + current, 0),
};

const make_queue_id = (queue: any) =>
    queue.descr ? `${queue.extension}-${queue.descr}` : `${queue.extension}`;

type Link = {
    source: string;
    target: string;
    value: number;
};

const incoming_calls_for_queue = (
    queue_data: {[x: string]: Entry; [x: number]: Entry} & {total: Total}
) => {
    return ['answered', 'timed_out', 'exited', 'abandoned'].reduce(
        (total, node_id) => total + queue_data.total[node_id as Status],
        0
    );
};

const unanswered_calls_for_queue = (
    queue_data: {[x: string]: Entry; [x: number]: Entry} & {total: Total}
) => {
    return ['answered', 'timed_out', 'exited', 'abandoned']
        .filter((n) => n !== 'answered')
        .reduce((total, node_id) => total + queue_data.total[node_id as Status], 0);
};

const make_queue_row = (
    queue: any,
    queue_data: {[x: string]: Entry; [x: number]: Entry} & {total: Total}
) => {
    let incoming = incoming_calls_for_queue(queue_data);
    let unanswered = unanswered_calls_for_queue(queue_data);

    let answered_percentage =
        incoming > 0 ? Math.round((100 * queue_data.total.answered) / incoming) : 0;
    let abandoned_percentage =
        incoming > 0 ? Math.round((100 * queue_data.total.abandoned) / incoming) : 0;
    let exited_percentage =
        incoming > 0 ? Math.round((100 * queue_data.total.exited) / incoming) : 0;
    let timed_out_percentage =
        incoming > 0 ? Math.round((100 * queue_data.total.timed_out) / incoming) : 0;
    let unanswered_percentage =
        incoming > 0 ? Math.round((100 * unanswered_calls_for_queue(queue_data)) / incoming) : 0;

    return {
        name: queue.descr ? `${queue.descr} (${queue.extension})` : queue.extension,
        inbound: incoming,
        answered: (
            <span className={'d-flex flex-row align-items-center'}>
                <span className={'font-weight-bold mr-1'}>{queue_data.total.answered}</span>
                <Badge
                    pill
                    color={'secondary'}
                    id={`queue-${queue.id}-answered`}
                    className={'text-muted'}
                >
                    {answered_percentage}%
                </Badge>
                <UncontrolledTooltip
                    target={`queue-${queue.id}-answered`}
                    placement={'right'}
                    tag={'span'}
                >
                    <FormattedMessage
                        id={'message.tooltip.call.proportion'}
                        defaultMessage={'{numerator} out of {denominator} {status} calls'}
                        values={{
                            numerator: queue_data.total.answered,
                            denominator: incoming,
                            status: (
                                <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage {...messages.incoming} />
                                </span>
                            ),
                        }}
                    />
                </UncontrolledTooltip>
            </span>
        ),
        average_call_time: moment.utc(queue_data.total.call_time * 1000).format('mm:ss'),
        timed_out: (
            <span className={'d-flex flex-row align-items-center'}>
                <span className={'font-weight-bold mr-1'}>{queue_data.total.timed_out}</span>
                <Badge
                    pill
                    color={'secondary'}
                    id={`queue-${queue.id}-timed_out`}
                    className={'text-muted'}
                >
                    {timed_out_percentage}%
                </Badge>
                <UncontrolledTooltip
                    target={`queue-${queue.id}-timed_out`}
                    placement={'right'}
                    tag={'span'}
                >
                    <FormattedMessage
                        id={'message.tooltip.call.proportion'}
                        defaultMessage={'{numerator} out of {denominator} {status} calls'}
                        values={{
                            numerator: queue_data.total.timed_out,
                            denominator: incoming,
                            status: (
                                <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage {...messages.incoming} />
                                </span>
                            ),
                        }}
                    />
                </UncontrolledTooltip>
            </span>
        ),
        abandoned: (
            <span className={'d-flex flex-row align-items-center'}>
                <span className={'font-weight-bold mr-1'}>{queue_data.total.abandoned}</span>
                <Badge
                    pill
                    color={'secondary'}
                    id={`queue-${queue.id}-abandoned`}
                    className={'text-muted'}
                >
                    {abandoned_percentage}%
                </Badge>
                <UncontrolledTooltip
                    target={`queue-${queue.id}-abandoned`}
                    placement={'right'}
                    tag={'span'}
                >
                    <FormattedMessage
                        id={'message.tooltip.call.proportion'}
                        defaultMessage={'{numerator} out of {denominator} {status} calls'}
                        values={{
                            numerator: queue_data.total.abandoned,
                            denominator: incoming,
                            status: (
                                <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage {...messages.incoming} />
                                </span>
                            ),
                        }}
                    />
                </UncontrolledTooltip>
            </span>
        ),
        exited: (
            <span className={'d-flex flex-row align-items-center'}>
                <span className={'font-weight-bold mr-1'}>{queue_data.total.exited}</span>
                <Badge
                    pill
                    color={'secondary'}
                    id={`queue-${queue.id}-exited`}
                    className={'text-muted'}
                >
                    {exited_percentage}%
                </Badge>
                <UncontrolledTooltip
                    target={`queue-${queue.id}-exited`}
                    placement={'right'}
                    tag={'span'}
                >
                    <FormattedMessage
                        id={'message.tooltip.call.proportion'}
                        defaultMessage={'{numerator} out of {denominator} {status} calls'}
                        values={{
                            numerator: queue_data.total.exited,
                            denominator: incoming,
                            status: (
                                <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage {...messages.incoming} />
                                </span>
                            ),
                        }}
                    />
                </UncontrolledTooltip>
            </span>
        ),
        unanswered: (
            <span className={'d-flex flex-row align-items-center'}>
                <span className={'font-weight-bold mr-1'}>
                    {unanswered_calls_for_queue(queue_data)}
                </span>
                <Badge
                    pill
                    color={'secondary'}
                    id={`queue-${queue.id}-unanswered`}
                    className={'text-muted'}
                >
                    {unanswered_percentage}%
                </Badge>
                <UncontrolledTooltip
                    target={`queue-${queue.id}-unanswered`}
                    placement={'right'}
                    tag={'span'}
                >
                    <FormattedMessage
                        id={'message.tooltip.call.proportion'}
                        defaultMessage={'{numerator} out of {denominator} {status} calls'}
                        values={{
                            numerator: unanswered,
                            denominator: incoming,
                            status: (
                                <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage {...messages.incoming} />
                                </span>
                            ),
                        }}
                    />
                </UncontrolledTooltip>
            </span>
        ),
    };
};

const make_agent_row = (
    queue: any,
    agent: any,
    queue_data: {[x: string]: Entry; [x: number]: Entry} & {total: Total}
) => {
    const agent_data = queue_data[agent.id];

    const answered_percentage = Math.round((100 * agent_data.answered) / queue_data.total.answered);

    return {
        name: agent.user ? `${agent.agent} (${agent.user.extension})` : agent.agent,
        inbound: '',
        answered: (
            <span className={'d-flex flex-row align-items-center'}>
                <span className={'font-weight-bold mr-1'}>{agent_data.answered}</span>
                <Badge
                    pill
                    color={'secondary'}
                    id={`queue-${queue.id}-agent-${agent.id}-answered`}
                    className={'text-muted'}
                >
                    {answered_percentage}%
                </Badge>
                <UncontrolledTooltip
                    target={`queue-${queue.id}-agent-${agent.id}-answered`}
                    placement={'right'}
                    tag={'span'}
                >
                    <FormattedMessage
                        id={'message.tooltip.call.proportion'}
                        defaultMessage={'{numerator} out of {denominator} {status} calls'}
                        values={{
                            numerator: agent_data.answered,
                            denominator: queue_data.total.answered,
                            status: (
                                <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage {...messages.answered} />
                                </span>
                            ),
                        }}
                    />
                </UncontrolledTooltip>
            </span>
        ),
        average_call_time: moment.utc(agent_data.call_time * 1000).format('mm:ss'),
        timed_out: '',
        abandoned: '',
        exited: '',
        unanswered: '',
    };
};

const make_queue_rows = (
    queue: any,
    queue_agents: any[],
    queue_data: {[x: string]: Entry; [x: number]: Entry} & {total: Total}
) => {
    const queue_row = make_queue_row(queue, queue_data);
    return (
        <React.Fragment key={queue.id}>
            <tr className={`queue${queue.id}content bg-secondary`} key={queue.id}>
                <td className={'collapse-icon'}>
                    <UncontrolledCollapse
                        timeout={0}
                        toggler={`.queue${queue.id}content`}
                        tag={'span'}
                    >
                        <FontAwesomeIcon icon={faAngleDown} />
                    </UncontrolledCollapse>
                    <UncontrolledCollapse
                        timeout={0}
                        defaultOpen={true}
                        toggler={`.queue${queue.id}content`}
                        tag={'span'}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </UncontrolledCollapse>
                </td>
                {columns.map((c, i) => (
                    <td key={i} className={`${c?.className} align-items-center`}>
                        {queue_row[c.key]}
                    </td>
                ))}
            </tr>
            {queue_agents.map((a) => {
                const agent_row = make_agent_row(queue, a, queue_data);
                return (
                    <UncontrolledCollapse
                        timeout={0}
                        toggler={`.queue${queue.id}content`}
                        tag={'tr'}
                        key={`${queue.id}${a.id}`}
                    >
                        <td />
                        {columns.map((c, j) => (
                            <td className={c.className} key={j}>
                                {agent_row[c.key]}
                            </td>
                        ))}
                    </UncontrolledCollapse>
                );
            })}
        </React.Fragment>
    );
};

type Status = 'answered' | 'timed_out' | 'exited' | 'abandoned';
type Results = 'call_time' | 'hold_time' | Status;

type Entry = {
    queue: number;
    agent: number;
    result: Results;
} & {
    [k in Results]: number;
};

type QueuesByQueueAndAgentReportProps = {
    report: {
        data: {
            data: Entry[];
        };
    };
} & WrappedComponentProps;

type SortedData = {
    [k in string | number]: {
        [k in string | number]: Entry;
    } & {total: Total};
};

type Total = {
    [k in Results]: number;
};

const QueuesByQueueAndAgentReport = ({report, intl}: QueuesByQueueAndAgentReportProps) => {
    const queues = useSelector(getCustomerQueues, isEqual);
    const agents = useSelector(getCustomerAgents, isEqual);
    const users = useSelector(getCustomerUsers, isEqual);

    const data = useMemo(() => {
        const START_NODE = {
            id: intl.formatMessage(messages.incoming),
            color: generate_palette(1)[0],
        };

        const END_NODES = {
            answered: {id: intl.formatMessage(messages.answered), color: generate_palette(4)[0]},
            timed_out: {id: intl.formatMessage(messages.timed_out), color: generate_palette(4)[1]},
            exited: {id: intl.formatMessage(messages.exited), color: generate_palette(4)[2]},
            abandoned: {id: intl.formatMessage(messages.abandoned), color: generate_palette(4)[3]},
        };

        if (report == null || report.data == null || report.data.data == null) return null;

        const sorted_data: SortedData = {};
        let queue_ids: number[] = [];
        let agent_ids: number[] = [];

        report.data.data.forEach((entry) => {
            if (sorted_data[entry.queue] == null) {
                // @ts-ignore
                sorted_data[entry.queue] = {};
            }
            sorted_data[entry.queue][entry.agent] = entry;
            queue_ids.push(entry.queue);
            agent_ids.push(entry.agent);
        });

        Object.keys(sorted_data).forEach((queue_id) => {
            const queue_data = sorted_data[queue_id];
            // @ts-ignore
            const total_data: Total = {};
            COLUMNS.forEach((column_name) => {
                const grouped_by_column_name = Object.values(queue_data).map(
                    (agent_entry) => agent_entry[column_name]
                );
                total_data[column_name] = REDUCERS[column_name](grouped_by_column_name);
            });
            delete queue_data['0'];
            sorted_data[queue_id].total = total_data;
        });

        const defined_queues = Array.from(new Set(queue_ids).values())
            .map((id) => queues.find((q: any) => q.id === id))
            .filter((queue) => queue != null);

        const defined_agents = Array.from(new Set(agent_ids).values())
            .filter((id) => id !== 0)
            .map((id) => agents.find((a: any) => a.id === id))
            .filter((agent) => agent != null);

        const tableData = defined_queues.map((q) =>
            make_queue_rows(
                q,
                defined_agents
                    .filter((a) => Object.keys(sorted_data[q.id]).includes(a.id.toString()))
                    .map((a) => ({
                        ...a,
                        user: users.find((u: any) => `${u.full_name}` === a.agent),
                    })),
                sorted_data[q.id]
            )
        );

        const queue_nodes = defined_queues.map((queue) => ({id: make_queue_id(queue)}));
        const agent_nodes = defined_agents.map((agent) => ({id: agent.agent}));

        let nodes = [START_NODE, ...queue_nodes, ...agent_nodes, ...Object.values(END_NODES)];

        const incoming_to_queues_links = defined_queues.map((queue) => ({
            source: START_NODE.id,
            target: make_queue_id(queue),
            value: incoming_calls_for_queue(sorted_data[queue.id]),
        }));

        const queues_to_agents_links: Link[] = [];

        defined_queues.forEach((queue) => {
            Object.keys(sorted_data[queue.id]).forEach((agent_id) => {
                if (agent_id !== 'total') {
                    queues_to_agents_links.push({
                        source: make_queue_id(queue),
                        target: defined_agents.find((a) => a.id.toString() === agent_id.toString())
                            .agent,
                        value: sorted_data[queue.id][agent_id]['answered'],
                    });
                }
            });
        });

        const queues_to_end_nodes_links: Link[] = [];
        defined_queues.forEach((queue) => {
            queues_to_end_nodes_links.push({
                source: make_queue_id(queue),
                target: END_NODES.timed_out.id,
                value: sorted_data[queue.id]['total']['timed_out'],
            });
            queues_to_end_nodes_links.push({
                source: make_queue_id(queue),
                target: END_NODES.abandoned.id,
                value: sorted_data[queue.id]['total']['abandoned'],
            });
            queues_to_end_nodes_links.push({
                source: make_queue_id(queue),
                target: END_NODES.exited.id,
                value: sorted_data[queue.id]['total']['exited'],
            });
        });

        const agents_to_end_nodes_links: Link[] = [];
        defined_agents.forEach((agent) => {
            const queue_to_agent_nodes_for_this_agent = queues_to_agents_links.filter(
                (n) => n.target === agent.agent
            );
            agents_to_end_nodes_links.push({
                source: agent.agent,
                target: END_NODES.answered.id,
                value: queue_to_agent_nodes_for_this_agent.reduce(
                    (total, node) => total + node.value,
                    0
                ),
            });
        });

        const links = [
            ...incoming_to_queues_links,
            ...queues_to_agents_links,
            ...queues_to_end_nodes_links,
            ...agents_to_end_nodes_links,
        ].filter((l) => l.value !== 0);
        nodes = nodes.filter((n) => links.find((l) => l.target === n.id || l.source === n.id));

        const flowData = {
            nodes: nodes,
            links: links,
        };

        return {tableData, flowData};
    }, [report, intl, queues, agents, users]);

    const [activeTab, setActiveTab] = useState<'table' | 'flow'>('table');

    return data ? (
        <>
            <Nav tabs className={'nav-fill d-flex flex-row'}>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === 'table'})}
                        onClick={() => setActiveTab('table')}
                    >
                        <FormattedMessage id={'tab.reports.table'} defaultMessage={'Table'} />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === 'flow'})}
                        onClick={() => setActiveTab('flow')}
                    >
                        <FormattedMessage id={'tabs.reports.flow'} defaultMessage={'Flow'} />
                    </NavLink>
                </NavItem>
            </Nav>
            {activeTab === 'table' && (
                <div className={'table-responsive'}>
                    <Table className={'table-flush align-items-center'} striped hover>
                        <thead className={'thead-light no-select sticky-top'}>
                            <tr>
                                <th />
                                {columns.map((c, i) => (
                                    <th className={c.className} key={i}>
                                        {typeof c.title === 'string' ? c.title : c.title(intl)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={'table-body'}>{data.tableData}</tbody>
                    </Table>
                </div>
            )}
            {activeTab === 'flow' && (
                <div className={'d-flex flex-column flex-grow-1 flex-shrink-1 responsive-chart'}>
                    <ResponsiveSankey
                        data={data.flowData}
                        margin={{top: 40, right: 100, bottom: 40, left: 100}}
                        align='justify'
                        colors={generate_palette(12)}
                        nodeOpacity={1}
                        nodeThickness={18}
                        nodeInnerPadding={3}
                        nodeSpacing={24}
                        nodeBorderWidth={0}
                        nodeBorderColor={{from: 'color', modifiers: [['darker', 0.8]]}}
                        linkOpacity={0.5}
                        linkHoverOthersOpacity={0.1}
                        enableLinkGradient={true}
                        labelPosition='outside'
                        labelOrientation='horizontal'
                        labelPadding={16}
                        labelTextColor={{from: 'color', modifiers: [['darker', 1]]}}
                        animate={true}
                    />
                </div>
            )}
        </>
    ) : (
        <Alert color={'secondary'} className={'m-2 text-center'}>
            <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
        </Alert>
    );
};

export default injectIntl(QueuesByQueueAndAgentReport);
