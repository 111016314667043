import * as UActions from 'store/actions/user-action';

const initialState = {
    connected: false,
    registered: false,
};

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case UActions.STORE_LOGIN:
            return {...state, connected: true, ...action.payload};
        case UActions.STORE_ENDPOINT:
            return {...state, url: action.payload};
        case UActions.STORE_PROFILE:
            return {...state, ...action.payload};
        case UActions.STORE_EMAIL:
            return {...state, email: action.payload};
        case UActions.COGNITO_LOGIN_FAILED:
        case UActions.COGNITO_UPDATE_EMAIL_FAILED:
        case UActions.COGNITO_CHANGE_PASSWORD_FAILED:
        case UActions.USER_PROFILE_FAILED:
        case UActions.FETCH_ENDPOINT_FAILED:
            return {...state, error: action.payload};
        case UActions.USER_PROFILE_CONFIRM_FAILED:
            return {...state, profileConfirm: false, error: action.payload};
        case UActions.USER_PROFILE_CONFIRM_SUCCESS:
            return {...state, profileConfirm: true};
        case UActions.USER_REGISTER_SUCCESS:
            return {...state, registered: true};
        case UActions.STORE_TRAVEL:
            return {...state, travel: {customer: action.payload}};
        case UActions.STOP_TRAVEL:
            return {...state, travel: undefined};
        case UActions.STORE_USER_LITE_CREDS:
            let payload = action.payload;
            if (Array.isArray(action.payload)) payload = payload[0];
            return {...state, liteCredentials: payload};
        case UActions.UPDATE_USER_LITE_CREDS:
            return {...state, liteCredentials: action.payload};
        default:
            return state;
    }
}
