import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'reactstrap';
import {defineMessages, FormattedMessage, MessageDescriptor, useIntl} from 'react-intl';
import dashboard from 'assets/images/dashboard.png';
import journeys from 'assets/images/journeys.png';
import reports from 'assets/images/reports.png';
import ContentWrapper from '../../components/content/ContentWrapper';

interface LicenseProps {
    location: {
        state: {
            access: string;
        };
    };
}

const messages: Record<string, MessageDescriptor> = defineMessages({
    dashboard: {
        id: 'resource.name.dashboard',
        defaultMessage: 'Dashboard',
    },
    journeys: {
        id: 'resource.name.journeys.2',
        defaultMessage: 'Journeys',
    },
    reports: {
        id: 'resource.name.reports',
        defaultMessage: 'Reports',
    },
});

const images: Record<string, string> = {
    dashboard: dashboard,
    journeys: journeys,
    reports: reports,
};

const plurals: Record<string, number> = {
    dashboard: 1,
    journeys: 2,
    reports: 2,
};

const LicenseAccess = (props: LicenseProps) => {
    const intl = useIntl();
    const history = useHistory();

    return (
        <ContentWrapper>
            <div
                className={
                    'w-100 flex flex-column align-items-center justify-content-center text-center'
                }
            >
                <h2>
                    <FormattedMessage
                        id={'message.access.license'}
                        defaultMessage={`Your licence does not give you access to the {param}`}
                        values={{
                            param: intl.formatMessage(messages[props.location.state.access]),
                            count: plurals[props.location.state.access],
                        }}
                    />
                </h2>
                <div>
                    <img
                        className={'p-3 shadow my-2'}
                        style={{height: 480}}
                        src={images[props.location.state.access]}
                        alt='Warning screen.'
                    />
                </div>
                <Button size='sm' onClick={() => history.goBack()} className={'mt-4'}>
                    <FormattedMessage id={'btn.return'} defaultMessage={'Return'} />
                </Button>
            </div>
        </ContentWrapper>
    );
};

export default LicenseAccess;
