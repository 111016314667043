import React from 'react';
import {ForgotPassword} from 'aws-amplify-react';
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import classnames from 'classnames';
import Auth from '@aws-amplify/auth';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import LocaleSelector from '../../../components/locale/LocaleSelector';

class AForgotPassword extends ForgotPassword {
    constructor(props) {
        super(props);
        this._validAuthStates = ['forgotPassword'];
        this.state = {
            new: '',
            confirmation: '',
        };
    }

    submit = async (e) => {
        e.preventDefault();
        try {
            await Auth.forgotPassword(this.state.email);
            this.setState({link_sent: true});
        } catch (e) {
            alert(e.message);
        }
    };

    handleChange = (event) => this.setState({[event.target.name]: event.target.value});

    showComponent(_theme) {
        return (
            <Card className='w-card top-25 align-self-start bg-secondary border-0 no-select d-flex'>
                <CardBody className=''>
                    {this.state.link_sent ? (
                        <FormattedMessage
                            id='message.forgot_password.sent'
                            defaultMessage={`A mail have will be sent to the email {email}, check your mailbox`}
                            values={{email: this.state.email}}
                        >
                            {(txt) => <span>{txt}</span>}
                        </FormattedMessage>
                    ) : (
                        <>
                            <FormattedMessage
                                id='title.forgot_password'
                                defaultMessage='Reset your password'
                            >
                                {(txt) => <h3 className='text-muted ta-center mb-4'>{txt}</h3>}
                            </FormattedMessage>
                            <Form role='form' onSubmit={this.submit}>
                                <FormGroup
                                    className={classnames('mb-3', {
                                        focused: this.state.focusedEmail,
                                    })}
                                >
                                    <InputGroup className='input-group-merge input-group-alternative'>
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </InputGroupText>
                                        <FormattedMessage
                                            id='input.placeholder.email'
                                            defaultMessage='Your email address'
                                        >
                                            {(m) => (
                                                <Input
                                                    placeholder={m}
                                                    name='email'
                                                    type='email'
                                                    onFocus={() =>
                                                        this.setState({focusedEmail: true})
                                                    }
                                                    onBlur={() =>
                                                        this.setState({focusedEmail: false})
                                                    }
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            )}
                                        </FormattedMessage>
                                    </InputGroup>
                                </FormGroup>
                                <div className='d-flex justify-content-between'>
                                    <FormattedMessage
                                        id='link.forgot_password.sign.in'
                                        defaultMessage='Back to Sign In'
                                    >
                                        {(m) => (
                                            <Button
                                                size={'sm'}
                                                color='secondary'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.changeState('signIn');
                                                }}
                                            >
                                                {m}
                                            </Button>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id='btn.reset.password'
                                        defaultMessage='Send code'
                                    >
                                        {(m) => (
                                            <Button size={'sm'} color='primary' type='submit'>
                                                {m}
                                            </Button>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </Form>
                        </>
                    )}
                </CardBody>
                <LocaleSelector style={{position: 'fixed', top: 15, right: 15}} />
            </Card>
        );
    }
}

export default AForgotPassword;
