import Auth from '@aws-amplify/auth';
import {OrIfExist} from './index';

// Amplify
export const login = async (payload) => {
    try {
        return await Auth.signIn(OrIfExist(payload.email, payload.username), payload.password);
    } catch (e) {
        return e;
    }
};

export const logout = async () => await Auth.signOut();

export const currentSession = async () => await Auth.currentSession();
export const userInfo = async () => await Auth.currentUserInfo();
export const currentUser = async () => await Auth.currentAuthenticatedUser();
export const updateEmail = async (user, email) => await Auth.updateUserAttributes(user, {email});
export const completeNewPassword = async (user, password) =>
    await Auth.completeNewPassword(user, password, []);
export const changePassword = async (user, oldPassword, newPassword) => {
    await Auth.changePassword(user, oldPassword, newPassword);
};
