import {faBuilding} from '@fortawesome/free-solid-svg-icons';
import Customers from '../views/admin/Customers';
import {FormattedMessage} from 'react-intl';
import React from 'react';

const superAdministration = [
    {
        path: '/customers',
        name: 'customers',
        title: <FormattedMessage id={'navbar.admin.customers'} defaultMessage={'Customers'} />,
        collapse: true,
        icon: faBuilding,
        has: {
            oneOfTheseRoles: ['super administrator', 'customer service agent'],
        },
        subroutes: [
            {
                path: '/list',
                name: 'customers-list',
                title: (
                    <FormattedMessage id={'navbar.admin.customers'} defaultMessage={'Customers'} />
                ),
                has: {
                    oneOfTheseRoles: ['super administrator', 'customer service agent'],
                },
                component: Customers,
            },
        ],
    },
];

export default superAdministration;
