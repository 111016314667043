import React from 'react'
import ContentWrapper from "../../components/content/ContentWrapper";
import {FormattedMessage} from "react-intl";
import CardContent from "../../components/content/CardContent";
import NewCallsTable from 'components/call/NewCallsTable';

const NewCallHistory = () => {

    return <>
        <ContentWrapper>
            <CardContent fullHeight>
                <NewCallsTable
                    title={<FormattedMessage id={'title.new.call.history'} defaultMessage={'New Call History'}/>}
                />
            </CardContent>
        </ContentWrapper>
    </>;
};

export default NewCallHistory;