import ContentWrapper from '../../../../components/content/ContentWrapper';
import CardContent from '../../../../components/content/CardContent';
import {Button, CardBody, CardHeader} from 'reactstrap';
import React, {useEffect, useState} from 'react';
import {Has} from '../../../../components/security/Has';
import LightFontAwesomeIcon from '../../../../components/utils/LightFontAwesomeIcon';
import Spinner from '../../../../components/spinner/Spinner';
import {Table} from '../../../../components/layout/Table/Table';
import {Action, ActionsCell, BooleanCell} from '../../../../components/layout/Table/Cells';
import {formatShortAmericanNumber} from '../../../../utils/format';
import {useDispatch, useSelector} from 'react-redux';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import {getCustomerSmsConfigs} from '../../../../store/selectors/customer-selectors';
import {
    fetchCustomerSmsBindings,
    fetchCustomerSmsConfigs,
    fetchCustomerUsers,
    fetchIncoming,
    fetchCustomerSmsBindingsUsers,
} from '../../../../store/actions/customer-action';
import SidePanel from '../../../../components/panel/SidePanel';
import SmsConfigForm from './SmsConfigForm';
import {FormattedMessage} from 'react-intl';

const sms_configs_columns = [
    {
        id: 'did',
        title: 'SMS',
        cellFactory: ({did}) => formatShortAmericanNumber(did),
        width: 'auto',
        sort: {key: 'did'},
    },
    {
        id: 'active',
        title: 'Active',
        cellFactory: ({enabled}) => <BooleanCell enabled={enabled} />,
        width: '100px',
    },
];

const SmsConfigs = () => {
    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [smsConfig, setSmsConfig] = useState(null);
    const loading = useSelector(getLoading('sms_configs'));
    const configs = useSelector(getCustomerSmsConfigs);

    const reduxDispatch = useDispatch();

    // this useEffect fetch the bindings if edit panel is open to edit a configuration
    useEffect(() => {
        if (!!smsConfig) {
            reduxDispatch(fetchCustomerSmsBindings({id: smsConfig.did}));
        }
    }, [smsConfig, reduxDispatch]);

    useEffect(() => {
        reduxDispatch(fetchCustomerSmsConfigs());
        reduxDispatch(fetchCustomerUsers());
        reduxDispatch(fetchIncoming());
        reduxDispatch(fetchCustomerSmsBindingsUsers());
    }, [reduxDispatch]);

    const create = () => {
        setSmsConfig(null);
        setSidePanelOpen(true);
    };

    const cancel = () => {
        setSmsConfig(null);
        setSidePanelOpen(false);
    };

    const edit = (sms_config) => {
        setSmsConfig(sms_config);
        setSidePanelOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='table-header no-select'>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage
                                id='message.admin.sms.title'
                                defaultMessage={`SMS Configurations`}
                            />
                        </h2>
                        <Has oneOfTheseRoles={['super administrator', 'customer service agent']}>
                            <Button
                                className={'align-self-center mr-auto'}
                                color={'primary'}
                                onClick={create}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1 p-0'}
                    >
                        {loading ? (
                            <Spinner
                                centered
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <Table
                                itemSize={55}
                                items={configs}
                                columns={[
                                    ...sms_configs_columns,
                                    {
                                        id: 'edit',
                                        title: '',
                                        cellFactory: (config) => (
                                            <ActionsCell>
                                                <Has
                                                    aPolicyMatching={[
                                                        {action: 'WRITE', resourceType: 'SMS'},
                                                    ]}
                                                >
                                                    <Action
                                                        onClick={() => edit(config)}
                                                        icon={'pen'}
                                                    />
                                                </Has>
                                            </ActionsCell>
                                        ),
                                        width: '50px',
                                        className: 'justify-content-end',
                                    },
                                ]}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={isSidePanelOpen}
                setOpen={setSidePanelOpen}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <SmsConfigForm
                    smsConfig={smsConfig}
                    toggle={() => setSidePanelOpen(!isSidePanelOpen)}
                />
            </SidePanel>
        </>
    );
};
SmsConfigs.propTypes = {};

export default SmsConfigs;
