import React, {useReducer, useState} from 'react';
import {Button, Card, CardBody, CardText, Form, FormGroup, Label, Nav, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import * as PropTypes from 'prop-types';

import {useDispatch, useSelector} from 'react-redux';
import {faCog, faUserFriends, faUsers} from '@fortawesome/free-solid-svg-icons';
import FormSection from '../../../components/form/FormSection';
import SidePanelContent from '../../../components/panel/SidePanelContent';
import SidePanelAvatar from '../../../components/panel/SidePanelAvatar';
import SidePanelHeader from '../../../components/panel/SidePanelHeader';
import TextInput from '../../../components/input/TextInput';
import {userRequestCustomerSelector} from '../../../store/selectors/user-selectors';
import SelectInput from '../../../components/input/SelectInput';
import DangerModal from '../../../components/notification/DangerModal';
import isEqual from 'lodash.isequal';
import {useListReducer} from '../../../utils/hooks';
import {MultiActions} from '../../../utils/requests';
import TeamAvatar from '../../../components/team/TeamAvatar';
import TeamUsers from './TeamUsers';
import Spinner from '../../../components/spinner/Spinner';
import {getLoading} from '../../../store/selectors/portal-selectors';
import {
    createCustomerTeam,
    createCustomerTeamUser,
    deleteCustomerTeam,
    deleteCustomerTeamUser,
    updateCustomerTeam,
    updateCustomerTeamUser,
} from '../../../store/actions/customer-action';
import {getCustomerTeams} from '../../../store/selectors/customer-selectors';

const defaultOpenSections = {
    teamOpen: true,
    advancedOpen: false,
    membersOpen: false,
};

const getAncestry = (team) => {
    const ancestry = [];
    if (team != null) {
        ancestry.push(team.id);
        if (team.parent != null) {
            ancestry.push(...getAncestry(team.parent));
        }
    }
    return ancestry;
};

const getDescendants = (team, teams) => {
    const descendants = [];
    if (team != null) {
        descendants.push(...team.children);
        team.children.forEach((childId) => {
            const child = teams.find((t) => t.id === childId);
            if (child != null) {
                descendants.push(getDescendants(child, teams));
            }
        });
    }
    return descendants;
};

const TeamForm = ({team, toggle}) => {
    const userActionsFactory = new MultiActions((team_user) => team_user.user.id, {
        c: (team_user) =>
            createCustomerTeamUser(
                team ? {team_id: team.id, user_id: team_user.user.id} : {user_id: team_user.user.id}
            ),
        u: (team_user) =>
            updateCustomerTeamUser({
                id: team_user.id,
                team_id: team.id,
                user_id: team_user.user.id,
            }),
        d: (team_user) =>
            deleteCustomerTeamUser({
                id: team_user.id,
                team_id: team.id,
                user_id: team_user.user.id,
            }),
    });

    const reduxDispatch = useDispatch();

    const teams = useSelector(getCustomerTeams);
    const loadingUsers = useSelector(getLoading('users'));
    const customer = useSelector(userRequestCustomerSelector);

    const defaultTeamState = () => ({
        id: '',
        parent: '',
        name: '',
        description: '',
        customer: customer,
    });

    const defaultTeamUsers = () => [];

    const getTeamState = () => {
        if (!!!team) return defaultTeamState();
        return {
            id: team.id,
            parent: team.parent != null ? team.parent.id : null,
            name: team.name,
            description: team.description,
        };
    };

    const getTeamUsers = () => {
        if (!!!team) return defaultTeamUsers();
        return team.team_users;
    };

    const [teamState, teamDispatch] = useReducer((prevTeamState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultTeamState();
            case 'edit':
                if (isEqual(prevTeamState[action.payload.field], action.payload.value))
                    return prevTeamState;
                const newState = {...prevTeamState};
                newState[action.payload.field] = action.payload.value;
                return newState;
            default:
                return prevTeamState;
        }
    }, getTeamState());

    const [teamUsersState, teamUsersDispatch] = useListReducer(
        (team_user) => team_user.user.id,
        getTeamUsers()
    );

    const [deleteTeamModal, setDeleteTeamModal] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (!!team) {
            const userActions = userActionsFactory
                .fromDiff(team.team_users, teamUsersState)
                .getActions();
            reduxDispatch(
                updateCustomerTeam({
                    id: team.id,
                    name: teamState.name,
                    parent_id:
                        teamState.parent && teamState.parent !== '' ? teamState.parent : null,
                    description: teamState.description,
                    user_actions: userActions,
                })
            );
        } else {
            const userActions = userActionsFactory.fromDiff([], teamUsersState).getActions();
            reduxDispatch(
                createCustomerTeam({
                    name: teamState.name,
                    description: teamState.description,
                    parent_id:
                        teamState.parent && teamState.parent !== '' ? teamState.parent : null,
                    customer_id: teamState.customer,
                    user_actions: userActions,
                })
            );
        }
        toggle();
    };

    const deleteTeam = () => {
        reduxDispatch(deleteCustomerTeam({id: team.id}));
        setDeleteTeamModal(false);
        toggle();
    };

    const editField = (name, value) =>
        teamDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });

    const ancestry = getAncestry(team);
    const descendants = getDescendants(team, teams);

    return (
        <>
            <SidePanelHeader reversed />
            <SidePanelAvatar>
                <TeamAvatar
                    className={'rounded-circle border border-white'}
                    team={team}
                    size={'xl'}
                />
            </SidePanelAvatar>
            <SidePanelContent>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={''}>
                        <FormSection
                            icon={faUserFriends}
                            isOpenByDefault={defaultOpenSections.teamOpen}
                            title={
                                <FormattedMessage id='message.admin.team' defaultMessage='Team' />
                            }
                        >
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.name'
                                            defaultMessage='Name'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id={'input.placeholder.team.name'}
                                        defaultMessage={'Team name'}
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('name', v)}
                                                placeholder={m}
                                                value={teamState.name}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.parent.team'
                                            defaultMessage='Parent Team'
                                        />
                                    </Label>
                                    <SelectInput
                                        onValueChange={(v) => editField('parent', v)}
                                        options={teams
                                            .map((team) => ({
                                                label: team.name,
                                                value: team.id,
                                            }))
                                            .filter(
                                                (t) =>
                                                    descendants.findIndex((id) => id === t.value) <
                                                        0 &&
                                                    ancestry.findIndex(
                                                        (id) =>
                                                            id === t.value &&
                                                            (team.parent == null
                                                                ? true
                                                                : id !== team.parent.id)
                                                    ) < 0
                                            )}
                                        isClearable
                                        isSearchable
                                        value={teamState.parent}
                                    />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.description'
                                            defaultMessage='Description'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.description'
                                        defaultMessage='Description'
                                    >
                                        {(m) => (
                                            <TextInput
                                                type={'textarea'}
                                                onValueChange={(v) => editField('description', v)}
                                                placeholder={m}
                                                value={teamState.description}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                        </FormSection>
                        {!!team && (
                            <FormSection
                                icon={faUsers}
                                isOpenByDefault={defaultOpenSections.membersOpen}
                                title={'Members'}
                            >
                                <div className={'d-flex flex-column flex-grow-1'}>
                                    {loadingUsers ? (
                                        <Spinner
                                            color={'primary'}
                                            background={'transparent'}
                                            global={false}
                                            size={50}
                                        />
                                    ) : (
                                        <TeamUsers
                                            teamUsersState={teamUsersState}
                                            teamUsersDispatch={teamUsersDispatch}
                                        />
                                    )}
                                </div>
                            </FormSection>
                        )}
                        {!!team && (
                            <FormSection
                                icon={faCog}
                                isOpenByDefault={defaultOpenSections.advancedOpen}
                                title={
                                    <FormattedMessage
                                        id={'message.admin.advanced'}
                                        defaultMessage={'Advanced'}
                                    />
                                }
                            >
                                <FormattedMessage
                                    id='message.admin.delete.team'
                                    defaultMessage={`Delete team`}
                                >
                                    {(m) => (
                                        <Card>
                                            <CardBody>
                                                <h4 className={'text-danger'}>{m}</h4>
                                                <CardText className={'text-muted'}>
                                                    <small>
                                                        <FormattedMessage
                                                            id={
                                                                'message.admin.delete.team.explanation'
                                                            }
                                                            defaultMessage={
                                                                'Members will lose all permissions associated with this team.'
                                                            }
                                                        />
                                                    </small>
                                                </CardText>
                                                <Button
                                                    color={'danger'}
                                                    size={'sm'}
                                                    onClick={() =>
                                                        setDeleteTeamModal(!deleteTeamModal)
                                                    }
                                                >
                                                    {m}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    )}
                                </FormattedMessage>
                                <DangerModal
                                    isOpen={deleteTeamModal}
                                    toggle={() => setDeleteTeamModal(!deleteTeamModal)}
                                    title={
                                        <FormattedMessage
                                            id={'message.admin.delete.team'}
                                            defaultMessage={'Delete team'}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id={'message.admin.delete.team.confirmation'}
                                            defaultMessage={
                                                'Are you sure you wish to delete this team? This operation cannot be undone.'
                                            }
                                        />
                                    }
                                    onClick={deleteTeam}
                                />
                            </FormSection>
                        )}
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
};

TeamForm.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        team_users: PropTypes.arrayOf(
            PropTypes.shape({
                team: PropTypes.number,
                user: PropTypes.shape({
                    id: PropTypes.number,
                    nickname: PropTypes.string,
                    full_name: PropTypes.string,
                }),
            })
        ),
    }),
    toggle: PropTypes.func.isRequired,
};

export default TeamForm;
