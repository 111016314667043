import React, {useEffect, useReducer, useState} from 'react';
import {Button, Card, CardBody, CardText, Form, FormGroup, Label, Nav, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {
    customerExtensionsSelector,
    getCustomerRoles,
} from '../../../store/selectors/customer-selectors';
import {
    createCustomerUser,
    createCustomerUserRole,
    deleteCustomerUser,
    deleteCustomerUserRole,
    resendCustomerUserInvitation,
    updateCustomerUser,
    updateCustomerUserRole,
} from '../../../store/actions/customer-action';

import {useDispatch, useSelector} from 'react-redux';
import Auth from '@aws-amplify/auth';
import {successAlert} from '../../../store/actions/portal-action';
import {faCog, faStar, faUser, faUserLock} from '@fortawesome/free-solid-svg-icons';
import UserAvatar from '../../../components/user/UserAvatar';
import FormSection from '../../../components/form/FormSection';
import SidePanelContent from '../../../components/panel/SidePanelContent';
import SidePanelAvatar from '../../../components/panel/SidePanelAvatar';
import SidePanelHeader from '../../../components/panel/SidePanelHeader';
import TextInput from '../../../components/input/TextInput';
import {
    getUser,
    getUserLiteCredentials,
    userRequestCustomerSelector,
} from '../../../store/selectors/user-selectors';
import {updateUserLiteCredentials} from '../../../store/actions/user-action';
import SelectInput from '../../../components/input/SelectInput';
import CheckboxInput from '../../../components/input/CheckboxInput';
import DangerModal from '../../../components/notification/DangerModal';
import isEqual from 'lodash.isequal';
import {useListReducer} from '../../../utils/hooks';
import {MultiActions} from '../../../utils/requests';
import {validateEmail} from '../../../utils/format';
import Spinner from '../../../components/spinner/Spinner';
import {getLoading} from '../../../store/selectors/portal-selectors';
import PropTypes from 'prop-types';

const defaultOpenSections = {
    profile: true,
    advanced: false,
    crooTalkLiteCredentials: true,
    roles: false,
};

const isValid = (userState) =>
    userState != null && userState.email != null && validateEmail(userState.email);

const UserForm = ({user, toggle}) => {
    const roleActionFactory = new MultiActions((user_role) => user_role.role.id, {
        c: (user_role) =>
            createCustomerUserRole(
                user ? {user_id: user.id, role_id: user_role.role.id} : {role_id: user_role.role.id}
            ),
        u: (user_role) =>
            updateCustomerUserRole({
                id: user_role.id,
                user_id: user.id,
                role_id: user_role.role.id,
            }),
        d: (user_role) =>
            deleteCustomerUserRole({
                id: user_role.id,
                user_id: user.id,
                role_id: user_role.role.id,
            }),
    });

    const reduxDispatch = useDispatch();

    const currentUser = useSelector(getUser);
    const extensions = useSelector(customerExtensionsSelector);
    const roles = useSelector(getCustomerRoles);
    const customer = useSelector(userRequestCustomerSelector);
    const liteProfile = useSelector(getUserLiteCredentials);
    const loadingCredentials = useSelector(getLoading('croo_talk_lite_credentials'));

    const defaultUserState = () => ({
        id: '',
        nickname: '',
        full_name: '',
        email: '',
        extension: '',
        customer: customer,
        language: currentUser.language,
    });

    const defaultUserRoles = () => [];

    const getUserState = () => {
        if (!!!user) return defaultUserState();
        return {
            id: user.id,
            nickname: user.nickname,
            full_name: user.full_name,
            email: user.email,
            extension: user.extension,
        };
    };

    const getUserRoles = () => {
        if (!!!user) return defaultUserRoles();
        return user.user_roles;
    };

    const [userState, userDispatch] = useReducer((prevUserState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultUserState();
            case 'edit':
                if (isEqual(prevUserState[action.payload.field], action.payload.value))
                    return prevUserState;
                const newState = {...prevUserState};
                newState[action.payload.field] = action.payload.value;
                return newState;
            default:
                return prevUserState;
        }
    }, getUserState());

    const [userRolesState, userRolesDispatch] = useListReducer(
        (user_role) => user_role.role.id,
        getUserRoles()
    );

    const [deleteUserModal, setDeleteUserModal] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (!!user) {
            const roleActions = roleActionFactory
                .fromDiff(user.user_roles, userRolesState)
                .getActions();
            reduxDispatch(
                updateCustomerUser({
                    id: user.id,
                    nickname: userState.nickname,
                    full_name: userState.full_name,
                    email: user.email,
                    extension: userState.extension,
                    role_actions: roleActions,
                })
            );
        } else {
            const roleActions = roleActionFactory.fromDiff([], userRolesState).getActions();
            reduxDispatch(
                createCustomerUser({
                    nickname: userState.nickname,
                    full_name: userState.full_name,
                    email: userState.email,
                    extension: userState.extension === '' ? null : userState.extension,
                    customer_id: userState.customer,
                    language_id: userState.language.id,
                    role_actions: roleActions,
                })
            );
        }
        toggle();
    };

    const reset = async (email) => {
        try {
            await Auth.forgotPassword(email);
        } catch (e) {
            alert(e.message);
        } finally {
            reduxDispatch(successAlert({type: 'success', title: 'Link sent'}));
        }
    };

    const deleteUser = () => {
        reduxDispatch(deleteCustomerUser({id: user.id}));
        setDeleteUserModal(false);
        toggle();
    };

    const toggleRole = (role, checked) => {
        return userRolesDispatch({
            type: checked ? 'add' : 'remove',
            payload: {role},
        });
    };

    const editField = (name, value) =>
        userDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });

    const [valid, setValid] = useState(isValid(userState));

    useEffect(() => {
        setValid(isValid(userState));
    }, [userState, setValid]);

    const updateLiteCredentials = () => {
        reduxDispatch(updateUserLiteCredentials({id: user.id}));
    };

    return (
        <>
            <SidePanelHeader reversed />
            <SidePanelAvatar>
                <UserAvatar
                    className={'rounded-circle border border-white'}
                    user={user}
                    size={'xl'}
                />
            </SidePanelAvatar>
            <SidePanelContent>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={''}>
                        <FormSection
                            icon={faUser}
                            isOpenByDefault={defaultOpenSections.profile}
                            title={
                                <FormattedMessage
                                    id='message.admin.user.profile'
                                    defaultMessage='User Profile'
                                />
                            }
                        >
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.nickname'
                                            defaultMessage='Nickname'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id={'input.placeholder.nickname'}
                                        defaultMessage={'How should we call you?'}
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('nickname', v)}
                                                placeholder={m}
                                                value={userState.nickname}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.full_name'
                                            defaultMessage='Full name'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.full_name'
                                        defaultMessage='Your full name'
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('full_name', v)}
                                                placeholder={m}
                                                value={userState.full_name}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.email'
                                            defaultMessage='Email address'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.email'
                                        defaultMessage='Your email address'
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('email', v)}
                                                placeholder={m}
                                                value={userState.email}
                                                enabled={!!!user}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.extension'
                                            defaultMessage='Extension'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='select.placeholder.extension'
                                        defaultMessage='Choose an extension'
                                    >
                                        {(m) => (
                                            <SelectInput
                                                isClearable={true}
                                                isSearchable={true}
                                                onValueChange={(v) =>
                                                    editField(
                                                        'extension',
                                                        v && v !== '' ? Number(v) : null
                                                    )
                                                }
                                                options={
                                                    extensions
                                                        ? extensions.map((e) => ({
                                                              value: Number(e.extension),
                                                              label: e.name
                                                                  ? `${e.extension} - ${e.name}`
                                                                  : e.extension,
                                                          }))
                                                        : {}
                                                }
                                                placeholder={m}
                                                value={userState.extension}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            {!!user && (
                                <div className='text-sm-sm'>
                                    <span className='font-weight-bold'>
                                        <FormattedMessage
                                            id='message.admin.user.cant.login'
                                            defaultMessage={`{nickname} {full_name} can't log in ?`}
                                            values={{
                                                nickname: userState.nickname,
                                                full_name: userState.full_name,
                                            }}
                                        />
                                    </span>
                                    <br />
                                    <FormattedMessage
                                        id='message.admin.password.reset'
                                        defaultMessage={`Send a {reset_link} to this user`}
                                        values={{
                                            reset_link: (
                                                <span
                                                    onClick={() => reset(userState.email)}
                                                    className='text-underline cursor-pointer'
                                                >
                                                    <FormattedMessage
                                                        id='link.admin.user.password.reset'
                                                        defaultMessage='password reset link'
                                                    />
                                                </span>
                                            ),
                                        }}
                                    />
                                    <br />
                                    <FormattedMessage
                                        id='message.admin.invitation.resend'
                                        defaultMessage={`Resend the {invitation_email} to this user`}
                                        values={{
                                            invitation_email: (
                                                <span
                                                    onClick={() =>
                                                        reduxDispatch(
                                                            resendCustomerUserInvitation({
                                                                userId: user.id,
                                                            })
                                                        )
                                                    }
                                                    className='text-underline cursor-pointer'
                                                >
                                                    <FormattedMessage
                                                        id='link.admin.user.invitation.resend'
                                                        defaultMessage='signup email'
                                                    />
                                                </span>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        </FormSection>
                        <FormSection
                            icon={faStar}
                            isOpenByDefault={defaultOpenSections.crooTalkLiteCredentials}
                            title={
                                <FormattedMessage
                                    id={'title.lite'}
                                    defaultMessage={'Croo Talk Lite'}
                                />
                            }
                        >
                            {loadingCredentials ? (
                                <Spinner
                                    className={'w-full'}
                                    color={'primary'}
                                    background={'transparent'}
                                    global={false}
                                    size={50}
                                />
                            ) : (
                                <>
                                    <Row>
                                        <FormGroup className='col'>
                                            <Label className='form-control-label'>
                                                <FormattedMessage
                                                    id='input.label.username'
                                                    defaultMessage='Username'
                                                />
                                            </Label>
                                            <FormattedMessage
                                                id='input.placeholder.username'
                                                defaultMessage='Your username'
                                            >
                                                {(m) => (
                                                    <TextInput
                                                        placeholder={m}
                                                        value={userState.email}
                                                        enabled={!!!user}
                                                        onValueChange={() => {}}
                                                    />
                                                )}
                                            </FormattedMessage>
                                        </FormGroup>
                                    </Row>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.password'
                                            defaultMessage='Password'
                                        />
                                    </Label>
                                    <div className={'d-flex w-100 ml--3'}>
                                        <div className={'col pr-2'}>
                                            <FormattedMessage
                                                id='input.placeholder.password'
                                                defaultMessage='Password'
                                            >
                                                {(m) => (
                                                    <TextInput
                                                        placeholder={m}
                                                        value={liteProfile?.password}
                                                        enabled={!!!user}
                                                        onValueChange={() => {}}
                                                    />
                                                )}
                                            </FormattedMessage>
                                        </div>
                                        {!liteProfile?.password ? (
                                            <div className={'d-flex flex-column mt-1 pr-4 pl-4'}>
                                                <Spinner
                                                    color={'primary'}
                                                    background={'transparent'}
                                                    global={false}
                                                    size={20}
                                                />
                                            </div>
                                        ) : (
                                            <Button
                                                color={'primary'}
                                                size={'sm'}
                                                className={'mr--3'}
                                                onClick={updateLiteCredentials}
                                            >
                                                <i className={'fa fa-sync pr-1'} />
                                                <FormattedMessage
                                                    id='btn.reset'
                                                    defaultMessage='Reset'
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </FormSection>
                        <FormSection
                            icon={faUserLock}
                            isOpenByDefault={true}
                            title={<FormattedMessage id={'title.roles'} defaultMessage={'Roles'} />}
                        >
                            {roles
                                .filter(
                                    currentUser.roles.some((r) => r.name === 'Super Administrator')
                                        ? () => true
                                        : (r) => r.name !== 'Super Administrator'
                                )
                                .map((r) => (
                                    <Row key={r.id} className={'mb-1'}>
                                        <FormGroup
                                            className={
                                                'col d-flex flex-row justify-content-between align-items-center'
                                            }
                                            check
                                        >
                                            <CheckboxInput
                                                className={'w-100 d-flex justify-content-between'}
                                                label={r.name}
                                                onValueChange={(v) => toggleRole(r, v)}
                                                value={userRolesState.some(
                                                    (ur) => ur.role.id === r.id
                                                )}
                                            />
                                        </FormGroup>
                                    </Row>
                                ))}
                        </FormSection>
                        {!!user && currentUser.id !== user.id && (
                            <FormSection
                                icon={faCog}
                                isOpenByDefault={defaultOpenSections.advanced}
                                title={
                                    <FormattedMessage
                                        id={'message.admin.advanced'}
                                        defaultMessage={'Advanced'}
                                    />
                                }
                            >
                                <Card>
                                    <CardBody>
                                        <h4 className={'text-danger'}>
                                            <FormattedMessage
                                                id='message.admin.delete.user'
                                                defaultMessage={`Delete user`}
                                            />
                                        </h4>
                                        <CardText className={'text-muted'}>
                                            <small>
                                                <FormattedMessage
                                                    id={'message.admin.delete.user.explanation'}
                                                    defaultMessage={
                                                        "Deleted users will no longer be able to use the portal. This user's calls will still be visible in the reports."
                                                    }
                                                />
                                            </small>
                                        </CardText>
                                        <Button
                                            color={'danger'}
                                            size={'sm'}
                                            onClick={() => setDeleteUserModal(!deleteUserModal)}
                                        >
                                            <FormattedMessage
                                                id='message.admin.delete.user'
                                                defaultMessage={`Delete user`}
                                            />
                                        </Button>
                                    </CardBody>
                                </Card>
                                <DangerModal
                                    isOpen={deleteUserModal}
                                    toggle={() => setDeleteUserModal(!deleteUserModal)}
                                    title={
                                        <FormattedMessage
                                            id={'message.admin.delete.user'}
                                            defaultMessage={'Delete user'}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id={'message.admin.delete.user.confirmation'}
                                            defaultMessage={
                                                'Are you sure you wish to delete this user? This operation cannot be undone.'
                                            }
                                        />
                                    }
                                    onClick={deleteUser}
                                />
                            </FormSection>
                        )}
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'} disabled={!valid}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
};

UserForm.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        nickname: PropTypes.string,
        full_name: PropTypes.string,
        email: PropTypes.string,
        extension: PropTypes.number,
        user_roles: PropTypes.arrayOf(
            PropTypes.shape({
                user: PropTypes.number,
                role: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                }),
            })
        ),
    }),
    toggle: PropTypes.func.isRequired,
};

export default UserForm;
