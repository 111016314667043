import React, {useMemo} from 'react';
import {formatAlphanumericString, formatDuration} from '../../../../../utils/format';
import {Alert, Badge, CardBody, Table, UncontrolledCollapse} from 'reactstrap';
import {extension_label} from '../../../../../utils/call_history';
import moment, {Moment} from 'moment';
import {
    getCustomerAgents,
    getCustomerExtensions,
} from '../../../../../store/selectors/customer-selectors';
import {useSelector} from 'react-redux';
import {RecursivePartial} from '../../../../../utils/types';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleRight} from '@fortawesome/free-solid-svg-icons';

const EVENTS = {
    logins: {
        start: 19,
        end: 20,
    },
    pauses: {
        start: 17,
        end: 18,
    },
};

const isRelevant = (event: number) => {
    return [
        EVENTS.logins.start,
        EVENTS.logins.end,
        EVENTS.pauses.start,
        EVENTS.pauses.end,
    ].includes(event);
};

const isPause = (event: number) => {
    return [EVENTS.pauses.start, EVENTS.pauses.end].includes(event);
};

const isLogin = (event: number) => {
    return [EVENTS.logins.start, EVENTS.logins.end].includes(event);
};

const START_EVENTS = [17, 19];
const END_EVENTS = [18, 20];

interface Interval {
    start: Moment;
    end: Moment;
    duration: number;
    type: 'login' | 'pause';
}

interface Day {
    intervals: Interval[];
    _total_login_duration: number;
    _total_pause_duration: number;
}

interface TotalDurationMetadata {
    _total_login_duration: number;
    _total_pause_duration: number;
}

interface NameMetadata {
    _name: string;
}

type Agent = TotalDurationMetadata &
    NameMetadata & {
        [index: string]: Day;
    };

type Entry = {
    agent: number;
    queue: number;
    event: number;
    datetime: string;
};

const make_interval_section = (interval: Interval, agent_name: string, index: number) => {
    return (
        <UncontrolledCollapse
            timeout={0}
            toggler={`.agent${formatAlphanumericString(agent_name)}content`}
            tag={'tr'}
            key={index}
        >
            <td />
            <td colSpan={2}>
                {`${interval.start.format('YYYY-MM-DD hh:mm A')} - ${interval.end.format(
                    'YYYY-MM-DD hh:mm A'
                )}`}
                <Badge
                    className={'ml-2 text-white'}
                    color={interval.type === 'login' ? 'success' : 'danger'}
                >
                    {interval.type === 'login' ? (
                        <FormattedMessage
                            id={'message.login.period'}
                            defaultMessage={'Login Period'}
                        />
                    ) : (
                        <FormattedMessage id={'message.pause'} defaultMessage={'Pause'} />
                    )}
                </Badge>
            </td>
            <td className={'border-left'}>
                {interval.type === 'login' && (interval.duration / 3600).toFixed(2)}
            </td>
            <td>{interval.type === 'login' && formatDuration(interval.duration)}</td>
            <td className={'border-left'}>
                {interval.type === 'pause' && (interval.duration / 3600).toFixed(2)}
            </td>
            <td>{interval.type === 'pause' && formatDuration(interval.duration)}</td>
            <td className={'border-left'} />
            <td />
        </UncontrolledCollapse>
    );
};

const make_interval_sections = (intervals: Interval[], agent_name: string) => {
    return intervals.map((v, i) => make_interval_section(v, agent_name, i));
};

const make_day_total_section = (date: string, day: Day) => {
    return (
        <>
            <td />
            <td colSpan={2}>
                <FormattedMessage
                    id={'message.daily.total.for.date'}
                    defaultMessage={'Daily Total for {date}'}
                    values={{date: <b>{date}</b>}}
                />
            </td>
            <td className={'border-left'}>{(day._total_login_duration / 3600).toFixed(2)}</td>
            <td>{formatDuration(day._total_login_duration)}</td>
            <td className={'border-left'}>{(day._total_pause_duration / 3600).toFixed(2)}</td>
            <td>{formatDuration(day._total_pause_duration)}</td>
            <td className={'border-left'}>
                {(
                    Math.max(day._total_login_duration - day._total_pause_duration, 0) / 3600
                ).toFixed(2)}
            </td>
            <td>
                {formatDuration(Math.max(day._total_login_duration - day._total_pause_duration, 0))}
            </td>
        </>
    );
};

const make_day_section = (date: string, agent_name: string, day: Day) => {
    return (
        day.intervals.length > 0 && (
            <React.Fragment key={date}>
                {make_interval_sections(day.intervals, agent_name)}
                <UncontrolledCollapse
                    timeout={0}
                    toggler={`.agent${formatAlphanumericString(agent_name)}content`}
                    className={'croo-table-footer'}
                    tag={'tr'}
                >
                    {make_day_total_section(date, day)}
                </UncontrolledCollapse>
            </React.Fragment>
        )
    );
};

const make_days_sections = (agent: Agent) => {
    return Object.keys(agent)
        .filter((k) => !k.startsWith('_'))
        .map((date) => make_day_section(date, agent._name, agent[date]));
};

const make_agent_total_section = (agent_name: string, agent: Agent) => {
    return (
        <>
            <td className={'collapse-icon'}>
                {(agent._total_login_duration > 0 || agent._total_pause_duration > 0) && (
                    <>
                        <UncontrolledCollapse
                            timeout={0}
                            toggler={`.agent${formatAlphanumericString(agent_name)}content`}
                            tag={'span'}
                        >
                            <FontAwesomeIcon icon={faAngleDown} />
                        </UncontrolledCollapse>
                        <UncontrolledCollapse
                            timeout={0}
                            defaultOpen={true}
                            toggler={`.agent${formatAlphanumericString(agent_name)}content`}
                            tag={'span'}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </UncontrolledCollapse>
                    </>
                )}
            </td>
            <td colSpan={2}>{agent_name}</td>
            <td className={'border-left'}>{(agent._total_login_duration / 3600).toFixed(2)}</td>
            <td>{formatDuration(agent._total_login_duration)}</td>
            <td className={'border-left'}>{(agent._total_pause_duration / 3600).toFixed(2)}</td>
            <td>{formatDuration(agent._total_pause_duration)}</td>
            <td className={'border-left'}>
                {((agent._total_login_duration - agent._total_pause_duration) / 3600).toFixed(2)}
            </td>
            <td>{formatDuration(agent._total_login_duration - agent._total_pause_duration)}</td>
        </>
    );
};

const make_agent_section = (agent_name: string, agent: Agent) => {
    return (
        <React.Fragment key={agent_name}>
            <tr className={`agent${formatAlphanumericString(agent_name)}content bg-secondary`}>
                {make_agent_total_section(agent_name, agent)}
            </tr>
            {make_days_sections(agent)}
        </React.Fragment>
    );
};

type ParsedQueuesTimeSheetsReportData = {[key: string]: Agent};

const make_agent_sections = (parsed_data: ParsedQueuesTimeSheetsReportData) => {
    if (Object.keys(parsed_data).length === 0) {
        return (
            <Alert color={'secondary'} className={'m-2 text-center'}>
                <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
            </Alert>
        );
    }
    return (
        <Table className={'table-flush align-items-center'} striped hover>
            <thead className={'thead-light no-select'}>
                <tr>
                    <th />
                    <th colSpan={2} />
                    <th colSpan={2} className={'border-left'}>
                        <FormattedMessage id={'message.logins'} defaultMessage={'Logins'} />
                    </th>
                    <th colSpan={2} className={'border-left'}>
                        <FormattedMessage id={'message.pauses'} defaultMessage={'Pauses'} />
                    </th>
                    <th colSpan={2} className={'border-left'}>
                        <FormattedMessage
                            id={'message.productive.time'}
                            defaultMessage={'Productive Time'}
                        />
                    </th>
                </tr>
                <tr>
                    <th />
                    <th colSpan={2} />
                    <th className={'border-left'}>
                        <FormattedMessage id={'message.hours'} defaultMessage={'Hours'} />
                    </th>
                    <th>
                        <FormattedMessage id={'message.duration'} defaultMessage={'Duration'} />
                    </th>
                    <th className={'border-left'}>
                        <FormattedMessage id={'message.hours'} defaultMessage={'Hours'} />
                    </th>
                    <th>
                        <FormattedMessage id={'message.duration'} defaultMessage={'Duration'} />
                    </th>
                    <th className={'border-left'}>
                        <FormattedMessage id={'message.hours'} defaultMessage={'Hours'} />
                    </th>
                    <th>
                        <FormattedMessage id={'message.duration'} defaultMessage={'Duration'} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(parsed_data)
                    .filter((k) => !k.startsWith('_'))
                    .map((agent_name) =>
                        make_agent_section(parsed_data[agent_name]._name, parsed_data[agent_name])
                    )}
            </tbody>
        </Table>
    );
};

interface QueuesTimeSheetsReportProps extends WrappedComponentProps {
    report: {
        data: {
            data: Entry[];
        };
    };
}

const QueuesTimeSheetsReport = ({report}: QueuesTimeSheetsReportProps) => {
    const agents = useSelector(getCustomerAgents);
    const extensions = useSelector(getCustomerExtensions);

    const data = useMemo(() => {
        if (report == null || report.data == null || report.data.data == null) return null;

        const sorted_data: any = {};
        const parsed_data: RecursivePartial<ParsedQueuesTimeSheetsReportData> = {};

        report.data.data.forEach((entry: Entry) => {
            if (isRelevant(entry.event)) {
                if (sorted_data[entry.agent] == null) {
                    sorted_data[entry.agent] = {};
                    parsed_data[entry.agent] = {};
                }

                const agent = agents.find((a: any) => a.id === entry.agent);
                let agentName = agent ? agent.agent : null;
                if (agentName != null && agentName.match(/SIP\/[0-9]{1,6}/)) {
                    agentName = extension_label(agentName.split('/')[1], Object.values(extensions));
                }

                // @ts-ignore
                parsed_data[entry.agent]['_name'] = agentName || `${entry.agent}`;

                const momentDatetime = moment(entry.datetime);

                if (sorted_data[entry.agent] == null) {
                    sorted_data[entry.agent] = {};
                    // @ts-ignore
                    parsed_data[entry.agent] = {};
                }

                const day = momentDatetime.clone().startOf('day').format('YYYY-MM-DD');

                if (sorted_data[entry.agent][day] == null) {
                    sorted_data[entry.agent][day] = [];
                    // @ts-ignore
                    parsed_data[entry.agent][day] = {};
                }

                sorted_data[entry.agent][day].push(entry);
            }
        });

        Object.keys(sorted_data).forEach((agent) => {
            let totalAgentLoginDuration = 0;
            let totalAgentPauseDuration = 0;

            Object.keys(sorted_data[agent]).forEach((day) => {
                const intervals: Interval[] = [];
                let counter = 0;
                let totalLoginDuration = 0;
                let totalPauseDuration = 0;
                let currentStartDate: Moment | null;
                const filtered_logins = sorted_data[agent][day]
                    // @ts-ignore
                    .sort((a: string, b: string) => moment(a) - moment(b))
                    .filter((event: Entry) => isLogin(event.event));

                filtered_logins.forEach((event: Entry, index: number) => {
                    if (START_EVENTS.includes(event.event)) {
                        if (index === filtered_logins.length - 1) {
                            // Login as last even of the day, which means login must occur the next day
                            const currentStartDate = moment(event.datetime);
                            const midnight = moment(event.datetime).endOf('day');
                            const duration = midnight.diff(currentStartDate, 'seconds');
                            totalLoginDuration += duration;
                            intervals.push({
                                type: 'login',
                                start: currentStartDate,
                                end: midnight,
                                duration: duration,
                            } as Interval);
                        } else if (counter === 0) {
                            currentStartDate = moment(event.datetime);
                        }
                        counter++;
                    } else if (END_EVENTS.includes(event.event)) {
                        if (counter === 0 && index === 0) {
                            // Logout as first event of the day, which means login occurred before midnight the previous day.
                            const currentEndDate = moment(event.datetime);
                            const midnight = moment(event.datetime).startOf('day');
                            const duration = currentEndDate.diff(midnight, 'seconds');
                            totalLoginDuration += duration;
                            intervals.push({
                                type: 'login',
                                start: midnight,
                                end: currentEndDate,
                                duration: duration,
                            } as Interval);
                        } else if (counter === 1) {
                            const currentEndDate = moment(event.datetime);
                            const duration = currentEndDate.diff(currentStartDate, 'seconds');
                            totalLoginDuration += duration;
                            intervals.push({
                                type: 'login',
                                start: currentStartDate,
                                end: currentEndDate,
                                duration: duration,
                            } as Interval);
                        }
                        counter--;
                    }
                    if (counter < 0) counter = 0;
                });

                currentStartDate = null;
                counter = 0;

                const filtered_pauses = sorted_data[agent][day]
                    // @ts-ignore
                    .sort((a: string, b: string) => moment(a) - moment(b))
                    .filter((event: Entry) => isPause(event.event));

                filtered_pauses.forEach((event: Entry, index: number) => {
                    if (START_EVENTS.includes(event.event)) {
                        if (index === filtered_pauses.length - 1) {
                            // Pause as last even of the day, which means unpause must occur the next day
                            const currentStartDate = moment(event.datetime);
                            const midnight = moment(event.datetime).endOf('day');
                            const duration = midnight.diff(currentStartDate, 'seconds');
                            totalPauseDuration += duration;
                            intervals.push({
                                type: 'pause',
                                start: currentStartDate,
                                end: midnight,
                                duration: duration,
                            } as Interval);
                        } else if (counter === 0) {
                            currentStartDate = moment(event.datetime);
                        }
                        counter++;
                    } else if (END_EVENTS.includes(event.event)) {
                        if (counter === 0 && index === 0) {
                            // Unpause as first event of the day, which means pause occurred before midnight the previous day.
                            const currentEndDate = moment(event.datetime);
                            const midnight = moment(event.datetime).startOf('day');
                            const duration = currentEndDate.diff(midnight, 'seconds');
                            totalPauseDuration += duration;
                            intervals.push({
                                type: 'pause',
                                start: midnight,
                                end: currentEndDate,
                                duration: duration,
                            } as Interval);
                        } else if (counter === 1) {
                            const currentEndDate = moment(event.datetime);
                            const duration = currentEndDate.diff(currentStartDate, 'seconds');
                            totalPauseDuration += duration;
                            intervals.push({
                                type: 'pause',
                                start: currentStartDate,
                                end: currentEndDate,
                                duration: duration,
                            } as Interval);
                        }
                        counter--;
                    }
                    if (counter < 0) counter = 0;
                });

                totalAgentLoginDuration += totalLoginDuration;
                totalAgentPauseDuration += totalPauseDuration;
                if (totalLoginDuration > 0 || totalPauseDuration > 0) {
                    // @ts-ignore
                    parsed_data[agent][day]['_total_login_duration'] = totalLoginDuration;
                    // @ts-ignore
                    parsed_data[agent][day]['_total_pause_duration'] = totalPauseDuration;
                    // @ts-ignore
                    parsed_data[agent][day]['intervals'] = intervals.sort(
                        (a: Interval, b: Interval) => a.start.diff(b.start)
                    );
                } else {
                    // @ts-ignore
                    parsed_data[agent][day]['_total_login_duration'] = 0;
                    // @ts-ignore
                    parsed_data[agent][day]['_total_pause_duration'] = 0;
                    // @ts-ignore
                    parsed_data[agent][day]['intervals'] = [];
                }
            });

            if (totalAgentLoginDuration > 0 || totalAgentPauseDuration > 0) {
                // @ts-ignore
                parsed_data[agent]._total_login_duration = totalAgentLoginDuration;
                // @ts-ignore
                parsed_data[agent]._total_pause_duration = totalAgentPauseDuration;
            } else {
                // @ts-ignore
                parsed_data[agent]['_total_login_duration'] = 0;
                // @ts-ignore
                parsed_data[agent]['_total_pause_duration'] = 0;
            }
        });

        return make_agent_sections(parsed_data as ParsedQueuesTimeSheetsReportData);
    }, [report, agents, extensions]);

    return data ? (
        <CardBody className={'p-0'}>
            <div className={'table-responsive'}>{data}</div>
        </CardBody>
    ) : (
        <div>
            <Alert color={'secondary'} className={'m-2 text-center'}>
                <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
            </Alert>
        </div>
    );
};

export default injectIntl(QueuesTimeSheetsReport);
