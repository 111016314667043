import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../media/image/Avatar';

const TeamAvatar = ({className, id, onClick, size, team}) => (
    <Avatar
        className={className}
        id={id}
        onClick={onClick}
        size={size}
        src={team ? team.avatar : undefined}
        alt={team ? team.name : undefined}
    />
);

TeamAvatar.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    team: PropTypes.shape({
        name: PropTypes.string.isRequired,
        avatar: PropTypes.string,
    }),
};

TeamAvatar.defaultProps = {
    onClick: () => {},
    size: 'md',
};

export default TeamAvatar;
