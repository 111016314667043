import {faPhoneSquare} from '@fortawesome/free-solid-svg-icons';
import {defineMessages, FormattedMessage} from 'react-intl';
import React from 'react';
import TextInput from '../../input/TextInput';
import {formatAmericanNumber} from '../../../utils/format';

const messages = defineMessages({
    display: {
        id: 'filter.display.from.number',
        defaultMessage: 'From number: {number}',
    },
    title: {
        id: 'filter.title.from.number',
        defaultMessage: 'From number',
    },
});

const factory = (number) => ({
    type: 'from_number',
    index: `from_number_${number}`,
    serialized: number,
    custom_filter: (query) => {
        query.filters.from_number = query.filters.from_number
            ? [number, ...query.filters.from_number]
            : [number];
        return query;
    },
    elastic_filter: (query) =>
        query.filter('bool', {
            should: [
                {
                    bool: {
                        must: [
                            {wildcard: {from_number: `*${number.replace(/([^0-9]+)/gi, '')}*`}},
                            {term: {'direction.keyword': 'out'}},
                        ],
                    },
                },
                {
                    bool: {
                        must: [
                            {wildcard: {to_number: `*${number.replace(/([^0-9]+)/gi, '')}*`}},
                            {term: {'direction.keyword': 'in'}},
                        ],
                    },
                },
            ],
        }),
    display: {
        icon: faPhoneSquare,
        content: (
            <FormattedMessage
                {...messages.display}
                values={{number: formatAmericanNumber(number)}}
            />
        ),
    },
});

export const from_number_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: TextInput,
    componentProps: {
        placeholder: 'From Number',
        type: 'phone',
        validator: (source) => source && source !== '',
    },
    factory: factory,
    icon: faPhoneSquare,
};
