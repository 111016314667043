import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import {validators} from './passwordValidators';
import {add, deduct} from './scoreModifiers';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Card, CardBody, CardText, CardTitle, Progress} from 'reactstrap';

const VALIDATOR_MESSAGES = defineMessages({
    number_of_characters: {
        id: 'message.password.meter.length',
        defaultMessage: 'Contain at least {minimum_number_of_characters} characters',
    },
    uppercase: {
        id: 'message.password.meter.uppercase.requirement',
        defaultMessage: 'Contain at least 1 uppercase character',
    },
    lowercase: {
        id: 'message.password.meter.lowercase.requirement',
        defaultMessage: 'Contain at least 1 lowercase character',
    },
    digit: {
        id: 'message.password.meter.digit.requirement',
        defaultMessage: 'Contain at least one digit',
    },
    symbol: {
        id: 'message.password.meter.non.alpha.requirement',
        defaultMessage: 'Contain at least one non-alphanumeric character',
    },
});

const THRESHOLD_MESSAGES = defineMessages({
    false: {
        id: 'badge.password.meter.insufficient',
        defaultMessage: 'Insufficient',
    },
    true: {
        id: 'badge.password.meter.good',
        defaultMessage: 'Good',
    },
});

const DEFAULT_VALIDATORS = [
    validators.number_of_characters(8),
    validators.uppercase,
    validators.lowercase,
    validators.digit,
    validators.symbol,
];

const DEFAULT_SCORE_MODIFIERS = [
    add.number_of_characters,
    add.uppercase,
    add.lowercase,
    add.digit,
    add.symbol,
    add.middle_digits_or_symbol,
    add.requirements(DEFAULT_VALIDATORS),
    deduct.letters_only,
    deduct.digits_only,
    deduct.repeat_characters,
    deduct.consecutive_uppercase_characters,
    deduct.consecutive_lowercase_characters,
    deduct.consecutive_digits,
    deduct.sequential_letters(3),
    deduct.sequential_digits(3),
    deduct.sequential_symbols(3),
];

const PasswordMeter = ({
    password,
    validators,
    scoreModifiers,
    onValidityChange,
    onScoreChange,
    className,
}) => {
    const [valid, setValid] = useState(false);
    const [score, setScore] = useState(0);

    useEffect(() => {
        setValid(validators.every((v) => v(password)));

        const unbound_score = scoreModifiers
            .map((score_modifier) => score_modifier(password))
            .reduce((score_total, score_modifier_score) => score_total + score_modifier_score);

        setScore(Math.max(0, Math.min(unbound_score, 100)));
    }, [password, validators, scoreModifiers]);

    useEffect(() => {
        onValidityChange(valid);
    }, [onValidityChange, valid]);

    useEffect(() => {
        onScoreChange(score);
    }, [onScoreChange, score]);

    return (
        <Card className={`${className}`}>
            <CardBody>
                <CardTitle>
                    <FormattedMessage
                        id={'message.password.meter.title'}
                        defaultMessage={'Password Strength'}
                    />
                </CardTitle>
                <CardText className={'text-sm'}>
                    <FormattedMessage
                        id={'message.password.meter.explanation'}
                        defaultMessage={'In order to be secure, your password must:'}
                    />
                </CardText>
                <ul className={'fa-ul text-sm ml-1'}>
                    {validators.map((v) => {
                        let valid = v(password);
                        return (
                            <li className={'pb-1'} key={v.values.name}>
                                <FontAwesomeIcon
                                    className={`fas mr-2 text-${valid ? 'primary' : 'secondary'}`}
                                    icon={valid ? faCheck : faTimes}
                                />
                                <FormattedMessage
                                    {...VALIDATOR_MESSAGES[v.values.name]}
                                    values={v.values.message}
                                />
                            </li>
                        );
                    })}
                </ul>
                <Progress disabled={!valid} color={'primary'} value={score} max={100} />
                <Badge color={valid ? 'primary' : 'secondary'}>
                    <FormattedMessage {...THRESHOLD_MESSAGES[valid]} />
                </Badge>
            </CardBody>
        </Card>
    );
};

PasswordMeter.defaultProps = {
    className: '',
    validators: DEFAULT_VALIDATORS,
    scoreModifiers: DEFAULT_SCORE_MODIFIERS,
    onValidityChange: () => {},
    onScoreChange: () => {},
};

PasswordMeter.propsType = {
    className: PropTypes.string,
    password: PropTypes.func.isRequired,
    validators: PropTypes.arrayOf(PropTypes.func),
    onValidityChange: PropTypes.func,
    scoreModifiers: PropTypes.arrayOf(PropTypes.func),
    onScoreChange: PropTypes.func,
};

export default PasswordMeter;
