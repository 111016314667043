import React, {useEffect, useMemo} from 'react';
import WidgetOptions from './WidgetOptions';
import {useDispatch, useSelector} from 'react-redux';
import {getUserLocale} from '../../../store/selectors/user-selectors';
import {build_call_history_query} from '../../call/calls_elastic_search_query_builder';
import {fetchWidgetData} from '../../../store/actions/cdr-action';
import {getSingleWidgetData} from '../../../store/selectors/cdr-selectors';
import {getCustomerExtensions} from '../../../store/selectors/customer-selectors';
import isEqual from 'lodash.isequal';
import {getLoadedOnce, getLoading} from '../../../store/selectors/portal-selectors';
import Spinner from '../../spinner/Spinner';
import {
    getElasticFilters,
    getParsedData,
    getRawData,
    getWidgetType,
} from '../utils/studioFunctions';
import {useDeepState} from '../../../utils/hooks';
import {fetchCustomerExtensions} from '../../../store/actions/customer-action';

const WidgetFactory = ({id, type, filters, extras, refreshFlag}) => {
    const [ownFilters, setOwnFilters] = useDeepState(filters);
    const extensionsLoadedOnce = useSelector(getLoadedOnce('extensions'));

    useEffect(() => {
        setOwnFilters(filters);
    }, [filters, setOwnFilters]);

    const widgetOptions = useMemo(() => WidgetOptions[getWidgetType(type)][type], [type]);
    const Component = widgetOptions.component.component;

    const reduxDispatch = useDispatch();

    const extensions = useSelector(getCustomerExtensions);
    const locale = useSelector(getUserLocale);
    const loading = useSelector(getLoading(`widget_${id}`));
    const loaded_once = useSelector(getLoadedOnce(`widget_${id}`));
    const widgetData = useSelector(getSingleWidgetData(id));
    const rawData = useMemo(() => getRawData(widgetOptions, widgetData), [
        widgetData,
        widgetOptions,
    ]);

    const [parsedData, setParsedData] = useDeepState(
        getParsedData(widgetOptions, rawData, extensions)
    );

    useEffect(() => {
        if (widgetOptions.data.type === 'ES') {
            const query = build_call_history_query(
                getElasticFilters(ownFilters),
                Object.keys(widgetOptions.aggs).map((name) => {
                    return (query) => {
                        query.aggs[name] = widgetOptions.aggs[name];
                        return query;
                    };
                })
            );

            reduxDispatch(
                fetchWidgetData({
                    type: widgetOptions.data.type,
                    params: query,
                    id: id,
                })
            );
        } else {
            if (extras?.item?.queue) {
                reduxDispatch(
                    fetchWidgetData({
                        type: widgetOptions.data.type,
                        params: {queue: extras.item.queue},
                        id: id,
                    })
                );
            }
        }
    }, [reduxDispatch, ownFilters, widgetOptions, id, extras, refreshFlag, type]);

    useEffect(() => {
        const newParsedData = getParsedData(widgetOptions, rawData, extensions, locale);
        if (!isEqual(parsedData, newParsedData)) {
            setParsedData(newParsedData);
        }
    }, [parsedData, setParsedData, extras, widgetOptions, rawData, extensions, locale]);

    useEffect(() => {
        if (!extensionsLoadedOnce) {
            reduxDispatch(fetchCustomerExtensions());
        }
    }, [reduxDispatch, extensionsLoadedOnce]);

    return (
        <>
            {(id === '-1' && loading) || (id !== '-1' && !loaded_once) ? (
                <Spinner color={'primary'} background={'transparent'} global={false} size={50} />
            ) : (
                <Component
                    data={parsedData}
                    params={widgetOptions.component.params}
                    extras={extras}
                />
            )}
        </>
    );
};

export default WidgetFactory;
