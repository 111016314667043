import React, {useState} from 'react';
import {
    NavLink as NavLinkRRD,
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import {Card, CardHeader, Nav, Navbar, NavItem, NavLink} from 'reactstrap';
import {ReportSwitchRoute} from './routes';
import ReportComponent from './ReportComponent';

interface ReportSwitchProps {
    route: ReportSwitchRoute;
}

const ReportSwitch = ({route}: ReportSwitchProps) => {
    let {path, url} = useRouteMatch();
    const location = useLocation();

    const [parameters, setParameters] = useState(route.defaultParameters || {});

    return (
        <>
            {route.parameters && (
                <Card className={'mb-0'}>
                    <CardHeader>
                        <route.parameters onChange={setParameters} parameters={parameters} />
                    </CardHeader>
                </Card>
            )}
            {route.routes.length > 1 && (
                <React.Fragment key={route.name}>
                    <Navbar expand={'md'} className={'small'} container={false}>
                        <Nav navbar>
                            {route.routes.map((r) => {
                                return (
                                    <NavItem key={r.name}>
                                        <NavLink id={r.name} tag={NavLinkRRD} to={url + r.path}>
                                            {r.title}
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    </Navbar>
                </React.Fragment>
            )}
            <Switch>
                {route.routes.map((r) => {
                    return (
                        <Route path={path + r.path} key={r.name}>
                            <ReportComponent route={r} parentParameters={parameters} />
                        </Route>
                    );
                })}
                <Route
                    path='/'
                    render={() => (
                        <Redirect
                            to={{
                                search: location.search,
                                pathname: path + route.routes[0].path,
                            }}
                        />
                    )}
                />
            </Switch>
        </>
    );
};

export default ReportSwitch;
