import {
    CLEAR_CUSTOMER,
    UPDATE_INTEGRATION_BINDING_SUCCESS,
    STORE_AGENTS,
    STORE_API_KEYS,
    STORE_PBX_ADMIN_USERS,
    STORE_CUSTOMERS,
    STORE_EXTENSION_SETTINGS_SAGA,
    STORE_EXTENSIONS,
    STORE_NEW_EXTENSIONS,
    STORE_NEW_PORTAL_ACCESS,
    FETCH_NEW_PORTAL_ACCESS_SUCCESS,
    FETCH_NEW_PORTAL_ACCESS_FAILED,
    STORE_FAX_BINDINGS,
    STORE_FAX_CONFIGS,
    STORE_INTEGRATION_ACCESS,
    STORE_INTEGRATION_BINDING,
    STORE_INTEGRATION_USERS,
    STORE_JOURNEYS,
    STORE_LANGUAGES,
    STORE_QUEUE_EVENTS_STATISTICS,
    STORE_QUEUES,
    STORE_ROLES,
    STORE_TEAMS,
    STORE_USERS,
    STORE_CUSTOMER,
    STORE_JOURNEY,
    STORE_POLICIES,
    STORE_RESOURCE_TYPES,
    STORE_SMS_CONFIGS,
    STORE_SMS_BINDINGS,
    STORE_SMS_BANDWIDTH,
    STORE_SMS_BINDINGS_USERS,
    STORE_INCOMING,
    STORE_USER_PRO,
    CREATE_USER_PRO,
    FETCH_USER_PRO_SUCCESS,
    FETCH_USER_PRO_FAILED,
    STORE_POWER_BROKER_API_KEY,
    STORE_POLYCOM_DEVICE_CONFIGURATIONS,
    STORE_POLYCOM_CONFIGURATION_TEMPLATES,
    STORE_CUSTOMER_PHONE_NUMBERS,
    STORE_PROVIDERS_LIST,
    STORE_CUSTOMER_CDR_PROXY,
} from 'store/actions/customer-action';

import {arrayToObjectByKey} from 'utils';

const initialState = {
    users: [],
    customers: [],
    teams: [],
    roles: [],
    fax_configs: [],
    fax_bindings: {},
    extensions: {},
    newExtensions: {},
    newPortalAccess: {},
    newPortalAccessSuccess: {},
    newPortalAccessFailed: {},
    extension_config: {},
    api_keys: [],
    queues: [],
    agents: [],
    integrations: {},
    incoming: {},
    reports: {},
    languages: [],
    long_journeys: {},
    short_journeys: [],
    sms_configs: [],
    sms_bindings: [],
    sms_bandwidth_dashboard_number: [],
    sms_bindings_users: [],
    policies: [],
    resource_types: [],
    pbx_admin_users: {},
    power_broker_api_key: '',
    phone_numbers: [],
    provider: [],
    cdr_proxy: [],
    device_configurations: [],
    configuration_templates: [],
};

export default function CustomerReducer(state = initialState, action) {
    switch (action.type) {
        case STORE_EXTENSION_SETTINGS_SAGA:
            return {...state, extension_config: action.payload};
        case STORE_EXTENSIONS:
            let extensions = arrayToObjectByKey(action.payload, 'extension');
            return {...state, extensions: {...extensions}};
        case STORE_NEW_EXTENSIONS:
            let newExtensions = action.metadata;
            return {...state, newExtensions: {...newExtensions}};
        case STORE_NEW_PORTAL_ACCESS:
            let newPortalAccess = action.metadata;
            return {...state, newPortalAccess: {...newPortalAccess}};
        case FETCH_NEW_PORTAL_ACCESS_SUCCESS:
            let newPortalAccessSuccess = action.metadata;
            return {...state, newPortalAccessSuccess: {...newPortalAccessSuccess}};
        case FETCH_NEW_PORTAL_ACCESS_FAILED:
            let newPortalAccessFailed = action.metadata;
            return {...state, newPortalAccessFailed: {...newPortalAccessFailed}};
        case CLEAR_CUSTOMER:
            return initialState;
        case STORE_USERS:
            let newUsers = arrayToObjectByKey(action.payload, 'id');
            let users = Object.values(newUsers);
            return {...state, users};
        case STORE_CUSTOMERS:
            let newCustomers = arrayToObjectByKey(action.payload, 'id');
            let customers = Object.values(newCustomers);
            return {...state, customers};
        case STORE_CUSTOMER:
            let customer = action.payload;
            return {...state, customers: [customer, ...state.customers]};
        case STORE_JOURNEYS:
            let newJourneys = action.payload;
            return {...state, short_journeys: newJourneys};
        case STORE_JOURNEY:
            let newJourney = action.payload;
            return {
                ...state,
                long_journeys: {...state.long_journeys, [newJourney.ids.user_id]: newJourney},
            };
        case STORE_TEAMS:
            let newTeams = arrayToObjectByKey(action.payload, 'id');
            let teams = Object.values(newTeams);
            return {...state, teams};
        case STORE_ROLES:
            let newRoles = arrayToObjectByKey(action.payload, 'id');
            let roles = Object.values(newRoles);
            return {...state, roles};
        case STORE_RESOURCE_TYPES:
            return {...state, resource_types: action.payload};
        case STORE_POLICIES:
            return {...state, policies: action.payload};
        case STORE_FAX_CONFIGS:
            let newFaxConfigs = arrayToObjectByKey(action.payload, 'did');
            let faxConfigs = Object.values(newFaxConfigs);
            return {...state, fax_configs: faxConfigs};
        case STORE_FAX_BINDINGS:
            let newFaxBindings = arrayToObjectByKey(action.payload.data, 'id');
            let faxBindings = Object.values(newFaxBindings);
            return {
                ...state,
                fax_bindings: {...state.fax_bindings, [action.payload.id]: faxBindings},
            };
        case STORE_SMS_CONFIGS:
            let newSmsConfigs = arrayToObjectByKey(action.payload, 'did');
            let smsConfigs = Object.values(newSmsConfigs);
            return {...state, sms_configs: smsConfigs};
        case STORE_SMS_BINDINGS:
            let newSmsBindings = arrayToObjectByKey(action.payload.data, 'id');
            let smsBindings = Object.values(newSmsBindings);
            return {
                ...state,
                sms_bindings: {...state.sms_bindings, [action.payload.id]: smsBindings.flat()},
            };
        case STORE_SMS_BANDWIDTH:
            return {
                ...state,
                sms_bandwidth_dashboard_number: [],
            };
        case STORE_SMS_BINDINGS_USERS:
            let newBindings_users = arrayToObjectByKey(action.payload, 'id');
            let sms_bindings_users = Object.values(newBindings_users);
            return {
                ...state,
                sms_bindings_users,
            };
        case STORE_LANGUAGES:
            let newLanguages = arrayToObjectByKey(action.payload, 'id');
            let languages = Object.values(newLanguages);
            return {...state, languages};
        case STORE_API_KEYS:
            let newApiKeys = arrayToObjectByKey(action.payload, 'apikey');
            let max_api_keys = action.metadata.max_api_keys;
            let api_keys = Object.values(newApiKeys);
            return {...state, api_keys, max_api_keys};
        case STORE_QUEUES:
            let newQueues = arrayToObjectByKey(action.payload, 'id');
            let queues = Object.values(newQueues);
            return {...state, queues};
        case STORE_AGENTS:
            let newAgents = arrayToObjectByKey(action.payload, 'id');
            let agents = Object.values(newAgents);
            return {...state, agents};
        case STORE_INTEGRATION_ACCESS:
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    [action.payload.name]: {
                        ...state.integrations[action.payload.name],
                        access: action.payload.data,
                    },
                },
            };
        case STORE_INTEGRATION_USERS:
            let integrationUsers = {};
            if (state.integrations[action.payload.name])
                integrationUsers = {...state.integrations[action.payload.name].users};
            action.payload.data.users.forEach((obj) => {
                integrationUsers[obj.id] = {
                    id: obj.id,
                    binding: obj.binding,
                    email: obj.email,
                    name: obj.username,
                    integration_specific_values: obj.integration_specific_values,
                };
            });
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    [action.payload.name]: {
                        ...state.integrations[action.payload.name],
                        users: integrationUsers,
                    },
                },
            };
        case STORE_INTEGRATION_BINDING:
            let bindings = {};
            action.payload.data.forEach((obj) => {
                bindings[obj.extension] = obj.userzoho;
            });
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    [action.payload.name]: {
                        ...state.integrations[action.payload.name],
                        bindings,
                    },
                },
            };
        case UPDATE_INTEGRATION_BINDING_SUCCESS:
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    [action.payload.name]: {
                        ...state.integrations[action.payload.name],
                        bindings: action.payload,
                    },
                },
            };
        case STORE_QUEUE_EVENTS_STATISTICS:
            return {...state, reports: {...state.reports, [action.payload.name]: action.payload}};
        case STORE_INCOMING:
            return {...state, incoming: {...action.payload.data}};
        case STORE_USER_PRO:
            return {...state, pro_module: action.payload};
        case FETCH_USER_PRO_SUCCESS:
            return {...state, pro_module: action.payload};
        case FETCH_USER_PRO_FAILED:
            return {...state, pro_module: action.payload};
        case CREATE_USER_PRO:
            return {...state, pro_module: action.payload};
        case STORE_PBX_ADMIN_USERS:
            return {
                ...state,
                pbx_admin_users: action.payload.data,
            };
        case STORE_POWER_BROKER_API_KEY:
            return {
                ...state,
                power_broker_api_key: action.payload,
            };
        case STORE_POLYCOM_DEVICE_CONFIGURATIONS:
            let newDeviceConfigurations = arrayToObjectByKey(action.payload, 'mac_address');
            let device_configurations = Object.values(newDeviceConfigurations);
            return {
                ...state,
                device_configurations,
                provisioning_up_to_date: action.metadata.is_up_to_date,
            };
        case STORE_POLYCOM_CONFIGURATION_TEMPLATES:
            let newConfigurationTemplates = arrayToObjectByKey(action.payload, 'name');
            let configuration_templates = Object.values(newConfigurationTemplates);
            return {
                ...state,
                configuration_templates,
                provisioning_up_to_date: action.metadata.is_up_to_date,
            };
        case STORE_CUSTOMER_PHONE_NUMBERS:
            return {
                ...state,
                phone_numbers: action.payload,
            };
        case STORE_PROVIDERS_LIST:
            return {
                ...state,
                provider: action.payload,
            };
        case STORE_CUSTOMER_CDR_PROXY:
            return {
                ...state,
                cdr_proxy: action.payload,
            };

        default:
            return state;
    }
}
