import React, {useEffect, useReducer, useState} from 'react';
import * as PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBackwardStep, faForwardStep, faPause, faPlay} from '@fortawesome/free-solid-svg-icons';
import Nouislider from 'nouislider-react';
import moment from 'moment';
import {useAudio} from 'react-use';
import {Button, Card, CardBody} from 'reactstrap';

const AudioPlayer = ({autoPlay, loop, muted, preload, src, srcObject, title}) => {
    const [currentTrack, setCurrentTrack] = useState(0);

    const [audio, audioState, controls, ref] = useAudio({
        src: Array.isArray(src) ? src[currentTrack] : src,
        autoPlay: autoPlay,
        muted: muted,
        loop: loop,
        preload: preload,
        title: title,
        crossOrigin: 'anonymous',
    });

    const [seek, dispatch] = useReducer((seek, action) => {
        switch (action.type) {
            case 'manual':
                controls.seek(action.time);
                return action.time;
            case 'auto':
                return action.time;
            default:
                return 0;
        }
    }, 0);

    useEffect(() => {
        if (audioState) {
            dispatch({type: 'auto', time: audioState.time});
        }
    }, [audioState]);

    useEffect(() => {
        if (srcObject && ref.current) {
            ref.current.srcObject = srcObject;
        }
    }, [srcObject, ref]);

    return (
        <Card className={'m-0'}>
            <CardBody className={'d-flex flex-row align-items-center py-1'}>
                {Array.isArray(src) && (
                    <Button
                        className={'rounded-circle mr-1 p-0'}
                        size={'sm'}
                        color={'primary'}
                        style={{width: '20px', height: '20px'}}
                        onClick={() => setCurrentTrack((i) => i - 1)}
                        disabled={!src || (Array.isArray(src) && currentTrack === 0)}
                    >
                        <FontAwesomeIcon size={'xs'} icon={faBackwardStep} />
                    </Button>
                )}
                <Button
                    className={'rounded-circle mr-1'}
                    size={'sm'}
                    color={'primary'}
                    style={{width: '28px', height: '28px'}}
                    onClick={audioState.paused ? controls.play : controls.pause}
                    disabled={!src}
                >
                    <FontAwesomeIcon size={'xs'} icon={audioState.paused ? faPlay : faPause} />
                </Button>
                {Array.isArray(src) && (
                    <Button
                        className={'rounded-circle mr-2 p-0'}
                        size={'sm'}
                        color={'primary'}
                        style={{width: '20px', height: '20px'}}
                        onClick={() => setCurrentTrack((i) => i + 1)}
                        disabled={!src || (Array.isArray(src) && currentTrack >= src.length - 1)}
                    >
                        <FontAwesomeIcon size={'xs'} icon={faForwardStep} />
                    </Button>
                )}
                {Array.isArray(src) && (
                    <small className={'mr-3'}>
                        {currentTrack + 1}/{src.length}
                    </small>
                )}
                <small className={'mr-3'}>
                    {moment.utc(1000 * audioState.time).format('mm:ss')}
                </small>
                <div className={' flex-grow-1 mr-3 p-1'}>
                    <Nouislider
                        connect={[true, false]}
                        start={seek}
                        range={{min: 0, max: Math.round(Math.max(audioState.duration, 1))}}
                        onSlide={(render, handle, value) => {
                            dispatch({type: 'manual', time: value[0]});
                        }}
                    />
                </div>
                <small>{moment.utc(1000 * audioState.duration).format('mm:ss')}</small>
                {audio}
            </CardBody>
        </Card>
    );
};

AudioPlayer.propTypes = {
    autoPlay: PropTypes.bool,
    className: PropTypes.string,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    preload: PropTypes.string,
    src: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    title: PropTypes.string,
    type: PropTypes.string,
    volume: PropTypes.number,
};

AudioPlayer.defaultProps = {
    autoPlay: true,
    hidden: false,
    loop: false,
    muted: false,
    preload: 'auto',
    volume: 1.0,
};

export default AudioPlayer;
