import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from 'store/reducers';
import Sagas from 'store/sagas';
import {composeWithDevToolsDevelopmentOnly} from '@redux-devtools/extension';

// create the saga middleware\
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevToolsDevelopmentOnly({});

export const initializeStore = () => {
    const store = createStore(reducers(), composeEnhancers(applyMiddleware(sagaMiddleware)));

    sagaMiddleware.run(Sagas);

    store.asyncReducers = {};

    store.injectReducer = (key, reducer) => {
        store.asyncReducers[key] = reducer;

        store.replaceReducer(reducers(store.asyncReducers));
        return store;
    };

    return store;
};

const store = initializeStore();
export default store;
