import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress} from 'reactstrap';
import {CSVLink} from 'react-csv';
import {useSelector} from 'react-redux';
import {
    getCSVContainer,
    getCSVProgress,
    getDownloadError,
    getDownloadLoading,
} from '../../../store/selectors/download-selectors';
import {getCustomerExtensions} from '../../../store/selectors/customer-selectors';
import {cancelFileDownload, resetCSVData} from '../../../store/actions/download-action';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import {FilterDisplays} from '../filters/FilterDisplays';
import {generate} from '../../../utils/csv-generator';

const ModalDownload = ({modal, dispatch, toggle, downloadFile, total, params, filters}) => {
    const [downloadReady, setDownloadReady] = useState(false);
    const [hideExportOptions, setHideExportOptions] = useState(false);

    const csvContainer = useSelector(getCSVContainer);
    const csvProgress = useSelector(getCSVProgress);
    const csvLoading = useSelector(getDownloadLoading);
    const csvError = useSelector(getDownloadError);

    const extensions = useSelector(getCustomerExtensions);

    const [csvData, setCsvData] = useState([]);
    const [displayedProgress, setDisplayedProgress] = useState(0);
    const [displayedProgressIntervalId, setDisplayedProgressIntervalId] = useState(null);

    const domain =
        window.location.protocol + '//' + window.location.host + window.location.pathname;

    const query = {
        total: total,
        params: params,
    };

    useEffect(() => {
        if (csvLoading) {
            if (displayedProgressIntervalId == null) {
                const timeoutId = setInterval(() => {
                    setDisplayedProgress((p) => p + 1);
                }, 2000);
                setDisplayedProgressIntervalId(timeoutId);
            }
        } else {
            if (displayedProgressIntervalId != null) {
                clearInterval(displayedProgressIntervalId);
                setDisplayedProgressIntervalId(null);
            }
        }
    }, [csvLoading, displayedProgressIntervalId, setDisplayedProgressIntervalId]);

    useEffect(() => {
        setDisplayedProgress((p) => Math.max(p, csvProgress * 100));
    }, [csvProgress]);

    useEffect(() => {
        setDownloadReady(false);
    }, [csvProgress]);

    useEffect(() => {
        if (csvContainer.length > 0) {
            setDownloadReady(true);
            setCsvData(generate(csvContainer, extensions, moment, domain));
        }
    }, [csvContainer, extensions, domain]);

    useEffect(() => {
        if (!modal) {
            dispatch(resetCSVData());
            dispatch(cancelFileDownload());
            setHideExportOptions(false);
        }
    }, [modal, dispatch]);

    const startDownload = () => {
        dispatch(downloadFile(query));
        setHideExportOptions(true);
    };

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className='Modal'>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id={'csv.title'} defaultMessage={'Export Data'} />
                </ModalHeader>
                <ModalBody>
                    {(csvLoading || downloadReady) && (
                        <div>
                            {downloadReady ? (
                                <p>
                                    <FormattedMessage
                                        id={'csv.loaded'}
                                        defaultMessage={
                                            'File generated. Use the button below to download it.'
                                        }
                                    />
                                </p>
                            ) : (
                                <p>
                                    <FormattedMessage
                                        id={'csv.load'}
                                        defaultMessage={
                                            'Your file is being generated, this may take a few minutes...'
                                        }
                                    />
                                </p>
                            )}
                            <Progress animated value={displayedProgress || 0} />
                        </div>
                    )}
                    {query.total < 10000 ? (
                        <h3>
                            {downloadReady === false && csvLoading === true
                                ? Math.round((csvProgress * 100 * query.total) / 100)
                                : query.total}
                            <span>
                                {downloadReady === false &&
                                    csvLoading === true &&
                                    `/${query.total}`}{' '}
                            </span>
                            <FormattedMessage id={'csv.calls'} defaultMessage={'calls'} />
                        </h3>
                    ) : (
                        <>
                            <h3>
                                {downloadReady === false && csvLoading === true
                                    ? Math.round((csvProgress * 100 * 10000) / 100)
                                    : '10000'}
                                <span>
                                    {downloadReady === false && csvLoading === true && `/${10000}`}{' '}
                                </span>
                                <FormattedMessage id={'csv.calls'} defaultMessage={'calls'} />
                            </h3>
                            <p className='text-muted small'>
                                <FormattedMessage
                                    id={'csv.over_1000'}
                                    defaultMessage={
                                        'The export of calls is limited to 10,000 entries. If you want to export all of these calls, use a date filter to split them into multiple segments.'
                                    }
                                />
                            </p>
                        </>
                    )}

                    {filters && Object.keys(filters).length > 0 && (
                        <div>
                            <span>
                                <FormattedMessage id={'csv.filters'} defaultMessage={'Filters'} /> :
                            </span>
                            <FilterDisplays filters={Object.values(filters)} />
                        </div>
                    )}

                    {Object.keys(filters).length === 0 && (
                        <div>
                            <p>
                                <FormattedMessage
                                    id={'csv.no_filters'}
                                    defaultMessage={'No filter applied'}
                                />
                            </p>
                        </div>
                    )}

                    <p>
                        {/*display any error message that we receive from the server, usually happens when API calls fail for any reason*/}
                        {csvError && (
                            <p className={'text-error'}>
                                <FormattedMessage
                                    id={'message.problem.generating.csv'}
                                    defaultMessage={
                                        'There was a problem generating the file, please try again or contact your administrator.'
                                    }
                                />
                            </p>
                        )}
                    </p>
                </ModalBody>

                {downloadReady === false && csvProgress === 0 && hideExportOptions === false && (
                    <ModalFooter>
                        <Button
                            size={'sm'}
                            color='primary'
                            onClick={startDownload}
                            disabled={query.total === 0}
                        >
                            <FormattedMessage id={'csv.generate'} defaultMessage={'Export'} />
                        </Button>
                        <Button color='secondary' onClick={toggle} id='cancelGenerate' size={'sm'}>
                            <FormattedMessage id={'csv.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                    </ModalFooter>
                )}

                {downloadReady === false && csvLoading === true && (
                    <ModalFooter>
                        <Button size={'sm'} color='secondary' onClick={toggle}>
                            <FormattedMessage id={'csv.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                    </ModalFooter>
                )}

                {downloadReady === true && (
                    <ModalFooter>
                        <CSVLink data={csvData} filename={'portal-reports.csv'}>
                            <Button size={'sm'} color='primary'>
                                <FormattedMessage id={'csv.download'} defaultMessage={'Download'} />
                            </Button>
                        </CSVLink>
                        <Button size={'sm'} color='secondary' onClick={toggle}>
                            <FormattedMessage id={'csv.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                    </ModalFooter>
                )}
            </Modal>
        </div>
    );
};

export default ModalDownload;
