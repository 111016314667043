import React from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import './SidePanel.css';
import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'reactstrap';

const getOrientation = (position) =>
    position === 'top' || position === 'bottom' ? 'horizontal' : 'vertical';
const shouldRenderGlassBefore = (position) => position === 'right' || position === 'bottom';

const getGlassStyle = (position, size) => {
    const horizontal = getOrientation(position) === 'horizontal';
    return {
        width: horizontal ? '100vw' : `${100 - size}vw`,
        height: horizontal ? `${100 - size}vh` : '100vh',
        ...(position === 'right' && {left: 0}),
        ...(position === 'top' && {bottom: 0}),
        position: 'inherit',
    };
};

const getPanelStyle = (position, size) => {
    const horizontal = getOrientation(position) === 'horizontal';
    return {
        width: horizontal ? '100vw' : `${size}vw`,
        height: horizontal ? `${size}vh` : '100vh',
        ...(position === 'right' && {right: 0}),
        ...(position === 'bottom' && {bottom: 0}),
        ...(position === 'top' && {bottom: 0}),
        ...(position === 'left' && {bottom: 0}),
        position: 'inherit',
        overflow: 'visible',
        backgroundColor: 'white',
    };
};

const getCloseButtonIcon = (position) => {
    switch (position) {
        case 'top':
            return faChevronUp;
        case 'bottom':
            return faChevronDown;
        case 'right':
            return faChevronRight;
        case 'left':
            return faChevronLeft;
        default:
            return null;
    }
};

const getCloseButtonStyle = (position) => {
    switch (position) {
        case 'top':
            return {
                marginTop: '-2rem',
                marginBottom: '-2rem',
            };
        case 'bottom':
            return {
                marginBottom: '-2rem',
                marginTop: '-2rem',
            };
        case 'right':
            return {
                marginRight: '-2rem',
                marginLeft: '-2rem',
            };
        case 'left':
            return {
                marginLeft: '-2rem',
                marginRight: '-2rem',
            };
        default:
            return {};
    }
};
const getPanelContentStyle = () => {
    return {};
};

const SidePanel = ({
    children,
    className,
    isOpen,
    setOpen,
    onOpen,
    onOpening,
    onOpened,
    onClose,
    onClosing,
    onClosed,
    onGlassClick,
    position,
    size,
}) => {
    const isHorizontal = getOrientation(position) === 'horizontal';
    const renderGlassBefore = shouldRenderGlassBefore(position);

    const glass = (
        <div
            className='glass'
            style={getGlassStyle(position, size)}
            onClick={(e) => onGlassClick(e)}
        />
    );
    const close = (
        <div
            className={`d-flex flex-${
                isHorizontal ? 'row' : 'column'
            } justify-content-around bg-transparent`}
            onClick={() => setOpen(false)}
            style={{zIndex: 10000, overflow: 'visible', ...getCloseButtonStyle(position)}}
        >
            <Button
                style={{zIndex: 10001}}
                onClick={() => setOpen(false)}
                color={'primary'}
                size={'sm'}
            >
                <FontAwesomeIcon icon={getCloseButtonIcon(position)} />
            </Button>
        </div>
    );

    return (
        <div>
            <div className={`sliding-panel-container ${isOpen ? 'active' : ''}`}>
                <CSSTransition
                    in={isOpen}
                    timeout={500}
                    classNames={`panel-container-${position}`}
                    unmountOnExit
                    onEnter={(node, isAppearing) => onOpen(node, isAppearing)}
                    onEntering={(node, isAppearing) => onOpening(node, isAppearing)}
                    onEntered={(node, isAppearing) => onOpened(node, isAppearing)}
                    onExit={(node) => onClose(node)}
                    onExiting={(node) => onClosing(node)}
                    onExited={(node) => onClosed(node)}
                    style={{display: isHorizontal ? 'block' : 'flex'}}
                >
                    <div>
                        {renderGlassBefore && glass}
                        <div
                            className={`panel d-flex flex-${
                                isHorizontal ? 'column' : 'row'
                            } justify-content-around`}
                            style={getPanelStyle(position, size)}
                        >
                            {renderGlassBefore && close}
                            <div
                                className={`panel-content ${className || ''} d-flex flex-column`}
                                style={getPanelContentStyle(position)}
                            >
                                {children}
                            </div>
                            {!renderGlassBefore && close}
                        </div>
                        {!renderGlassBefore && glass}
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};
SidePanel.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onGlassClick: PropTypes.func,
    position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    size: PropTypes.number,
    style: PropTypes.object,
};

SidePanel.defaultProps = {
    isOpen: false,
    className: '',
    position: 'right',
    onGlassClick: () => null,
    onOpen: () => null,
    onOpening: () => null,
    onOpened: () => null,
    onClose: () => null,
    onClosing: () => null,
    onClosed: () => null,
    setOpen: () => null,
    size: 50,
    style: {},
};

export default SidePanel;
