import { createSelector } from 'reselect';
import { arrayToObjectByKey } from '../../utils';

export const getRawCallHistory = (state) => Object.values(state.cdr.history);
export const getRawNewCallHistory = (state) => Object.values(state.cdr.newHistory);
export const getRawUnfilteredCallHistory = (state) => Object.values(state.cdr.unfilteredHistory);
export const getRawNewUnfilteredCallHistory = (state) => Object.values(state.cdr.newUnfilteredHistory);
export const getRawContinuationTokenNewCallHistory = (state) => Object.values(state.cdr.continuationTokenNewCallHistory);
export const getRawLiveCalls = (state) => Object.values(state.cdr.live);
export const getCallRecordings = (state) => state.cdr.recordings;
export const getCallsLoading = (state) => state.cdr.loading;
export const getNewCallsLoading = (state) => state.cdr.newCallsloading;
export const getWidgetData = (state) => state.cdr.widget;

export const getSingleWidgetData = (widget_id) =>
    createSelector(getWidgetData, (widget_data) => widget_data[widget_id]);

export const getCallRecording = (call_id) =>
    createSelector(getCallRecordings, (urls) => urls[call_id]);

export const formatCall = (call) => ({
    id: call.call_id,
    timestamp: call.call_start_time,
    direction: call.direction,
    status: call.action,
    name: call.caller_id_name,
    from: call.from_number,
    to: call.to_number,
    extension: call.extension,
    duration: call.duration,
    queue_time: call.queue_time,
    recorded: call.recorded,
    tags: call.tags,
    number: call.direction === 'in' ? call.from_number : call.to_number,
    source: call.direction === 'in' ? call.to_number : call.from_number,
});
export const newFormatCall = (call) => ({
    id: call.cdr_id,
    timestamp: call.call_start_time,
    direction: call.direction,
    status: call.action,
    name: call.caller_id_name,
    from: call.from_number,
    to: call.to_number,
    extension: call.extension,
    duration: call.duration,
    queue_time: call.time_holding,
    recorded: (call.duration > 0) ? true :false,
    tags: call.tags,
    from_number: call.from_number,
    to_number: call.to_number,
    orig_callid: call.orig_callid,
    term_callid: call.term_callid,
    domain: call.domain,
    call_time_details:call.call_time_details
});

const formatCalls = (calls) => arrayToObjectByKey(calls.map(formatCall), 'id');
const newFormatCalls = (calls) => arrayToObjectByKey(calls.map(newFormatCall), 'id');

export const getCallHistory = createSelector(getRawCallHistory, (cdrList) => formatCalls(cdrList));
export const getNewCallHistory = createSelector(getRawNewCallHistory, (cdrList) => newFormatCalls(cdrList));

export const getUnfilteredCallHistory = createSelector(getRawUnfilteredCallHistory, (cdrList) =>
    formatCalls(cdrList)
);
export const getNewUnfilteredCallHistory = createSelector(getRawNewUnfilteredCallHistory, (cdrList) =>
    formatCalls(cdrList)
);
export const getContinuationTokenNewCallHistory = createSelector(getRawContinuationTokenNewCallHistory, (continuationToken) =>
    continuationToken
);

// noinspection JSUnusedGlobalSymbols
export const getLiveCalls = createSelector(getRawLiveCalls, (cdrList) => formatCalls(cdrList));

export const getCallsTags = (state) => state.cdr.tags;
export const getCallsDirections = (state) => state.cdr.directions;
export const getCallsStatuses = (state) => state.cdr.statuses;
export const getNewCallsStatuses = (state) => state.cdr.newStatuses;
export const getCallsStatistics = (state) => state.cdr.stats;
export const getCallsParticipants = (state) => state.cdr.call_events;
