import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomerJourney} from '../../store/selectors/customer-selectors';
import ActivityTimeline from './ActivityTimeline';
import Spinner from '../spinner/Spinner';
import {fetchCustomerJourney} from '../../store/actions/customer-action';
import {getLoading} from '../../store/selectors/portal-selectors';
import * as moment from 'moment';
import {Has} from '../security/Has';

const JourneyLongCard = ({date, user}) => {
    const spinner = (
        <Spinner color={'primary'} background={'transparent'} global={false} size={50} />
    );

    const reduxDispatch = useDispatch();

    const journey = useSelector(getCustomerJourney(user.id));
    const loadingJourney = useSelector(getLoading(`journeys_${user.id}`, true));

    useEffect(() => {
        reduxDispatch(
            fetchCustomerJourney({
                id: user.id,
                start_time: moment(date).startOf('day').format('YYYY-MM-DD'),
                end_time: moment(date).startOf('day').add(1, 'days').format('YYYY-MM-DD'),
            })
        );
    }, [reduxDispatch, user.id, date]);

    return !loadingJourney ? (
        <Has loaded={`journeys_${user.id}`} orElse={spinner}>
            <div className={'d-flex flex-column align-items-start flex-grow-1'}>
                <ActivityTimeline activities={journey.activities} />
            </div>
        </Has>
    ) : (
        spinner
    );
};

JourneyLongCard.propTypes = {};

export default JourneyLongCard;
