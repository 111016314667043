import React, {useEffect, useState} from 'react';
import PoliciesUsers from './PoliciesUsers';
import PoliciesTeams from './PoliciesTeams';
import PoliciesEveryone from './PoliciesEveryone';
import ContentWrapper from '../../../components/content/ContentWrapper';
import {Nav, Navbar, NavItem, NavLink} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchCustomer,
    fetchCustomerAgents,
    fetchCustomerExtensions,
    fetchCustomerQueues,
    fetchCustomerResourceTypes,
    fetchCustomerTeams,
    fetchCustomerUsers,
} from '../../../store/actions/customer-action';
import SidePanel from '../../../components/panel/SidePanel';
import PolicyForm from './PolicyForm';
import {Has} from '../../../components/security/Has';
import Spinner from '../../../components/spinner/Spinner';
import {getUserTravel, userRequestCustomerSelector} from '../../../store/selectors/user-selectors';
import {FormattedMessage} from 'react-intl';
import {useLocalStorage} from 'react-use';

const Policies = () => {
    const travel = useSelector(getUserTravel);
    const customerId = useSelector(userRequestCustomerSelector);

    const [mode, setMode] = useLocalStorage('croo_portal_preferred_policies_mode', 'teams');

    const [modal, setModal] = useState(false);
    const [who, setWho] = useState(null);
    const [policy, setPolicy] = useState(null);
    const [roleId, setRoleId] = useState(null);

    const edit = (policy, roleId, who) => {
        setPolicy(policy);
        setRoleId(roleId);
        setModal(true);
        setWho(who);
    };

    const cancel = () => {
        setPolicy(null);
        setRoleId(null);
        setModal(false);
        setWho(null);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCustomerUsers());
        dispatch(fetchCustomerTeams());
        dispatch(fetchCustomerResourceTypes());
        dispatch(fetchCustomerQueues({include_system_queues: false}));
        dispatch(fetchCustomerAgents({include_system_agents: false}));
        dispatch(fetchCustomerExtensions());
    }, [dispatch, travel]);

    useEffect(() => {
        dispatch(fetchCustomer({id: customerId}));
    }, [dispatch, customerId]);

    return (
        <Has
            loaded={['users', 'teams', 'customers', 'resource_types']}
            orElse={
                <Spinner
                    centered
                    color={'primary'}
                    background={'transparent'}
                    global={false}
                    size={50}
                />
            }
        >
            <ContentWrapper>
                <Navbar expand={'md'} className={'small'}>
                    <Nav navbar>
                        <NavItem className={`cursor-pointer ${mode === 'users' || 'active'}`}>
                            <NavLink onClick={() => setMode('users')}>
                                <FormattedMessage
                                    id={'title.table.users'}
                                    defaultMessage={'Users'}
                                />
                            </NavLink>
                        </NavItem>
                        <NavItem className={`cursor-pointer ${mode === 'teams' || 'active'}`}>
                            <NavLink onClick={() => setMode('teams')}>
                                <FormattedMessage
                                    id={'title.table.teams'}
                                    defaultMessage={'Teams'}
                                />
                            </NavLink>
                        </NavItem>
                        <NavItem className={`cursor-pointer ${mode === 'everyone' || 'active'}`}>
                            <NavLink onClick={() => setMode('everyone')}>
                                <FormattedMessage
                                    id={'title.table.everyone'}
                                    defaultMessage={'Everyone'}
                                />
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>
                {mode === 'users' && <PoliciesUsers edit={edit} />}
                {mode === 'teams' && <PoliciesTeams edit={edit} />}
                {mode === 'everyone' && <PoliciesEveryone edit={edit} />}
                <SidePanel
                    isOpen={modal}
                    setOpen={setModal}
                    className={'bg-white'}
                    onGlassClick={cancel}
                >
                    <PolicyForm who={who} policy={policy} toggle={cancel} roleId={roleId} />
                </SidePanel>
            </ContentWrapper>
        </Has>
    );
};

Policies.propTypes = {};

export default Policies;
