import {defineMessages} from 'react-intl';
import {IntlShape} from 'react-intl/src/types';

export type ByQueueAndAgentReportColumns =
    | 'name'
    | 'inbound'
    | 'answered'
    | 'average_call_time'
    | 'unanswered'
    | 'timed_out'
    | 'exited'
    | 'abandoned';

const messages = defineMessages({
    name: {
        id: 'table.column.name',
        defaultMessage: 'Queue',
    },
    inbound: {
        id: 'table.column.inbound',
        defaultMessage: 'Inbound',
    },
    answered: {
        id: 'table.column.answered',
        defaultMessage: 'Answered',
    },
    average_call_time: {
        id: 'table.column.average.call.time',
        defaultMessage: 'Average Call Time',
    },
    unanswered: {
        id: 'table.column.unanswered',
        defaultMessage: 'Unanswered',
    },
    timed_out: {
        id: 'table.column.timed.out',
        defaultMessage: 'Timed Out',
    },
    abandoned: {
        id: 'table.column.abandoned',
        defaultMessage: 'Abandoned',
    },
    exited: {
        id: 'table.column.exited',
        defaultMessage: 'Bailed Out',
    },
});

const columns: {
    className?: string;
    id: ByQueueAndAgentReportColumns;
    title: string | ((intl: IntlShape) => string);
    key: ByQueueAndAgentReportColumns;
}[] = [
    {
        id: 'name',
        title: (intl) => intl.formatMessage(messages.name),
        key: 'name',
    },
    {
        id: 'inbound',
        title: (intl) => intl.formatMessage(messages.inbound),
        key: 'inbound',
    },
    {
        id: 'answered',
        title: (intl) => intl.formatMessage(messages.answered),
        key: 'answered',
        className: 'border-left',
    },
    {
        id: 'average_call_time',
        title: (intl) => intl.formatMessage(messages.average_call_time),
        key: 'average_call_time',
    },
    {
        id: 'unanswered',
        title: (intl) => intl.formatMessage(messages.unanswered),
        key: 'unanswered',
        className: 'border-left',
    },
    {
        id: 'timed_out',
        title: (intl) => intl.formatMessage(messages.timed_out),
        key: 'timed_out',
    },
    {
        id: 'abandoned',
        title: (intl) => intl.formatMessage(messages.abandoned),
        key: 'abandoned',
    },
    {
        id: 'exited',
        title: (intl) => intl.formatMessage(messages.exited),
        key: 'exited',
    },
];

export default columns;
