import React from 'react';
import * as PropTypes from 'prop-types';

const fixed_width_class = (fixedWidth) => (fixedWidth ? 'fa-fw' : '');

const flip_class = (flip) => (flip ? `fa-flip-${flip}` : '');

const icon_class = (icon) => `fas fa-${icon}`;

const pulse_class = (pulse) => (pulse ? 'fa-pulse' : '');

const rotate_class = (rotate) => (rotate ? `fa-rotate-${rotate}` : '');

const size_class = (size) => `fa-${size}${typeof size === 'number' ? 'x' : ''}`;

const spin_class = (spin) => (spin ? 'fa-spin' : '');

const classes = (icon, size, style, fixedWidth, flip, rotate, pulse, spin) => {
    return [
        fixed_width_class(fixedWidth),
        flip_class(flip),
        icon_class(icon),
        pulse_class(pulse),
        rotate_class(rotate),
        size_class(size),
        spin_class(spin),
    ].join(' ');
};

const LightFontAwesomeIcon = ({
    id,
    icon,
    className,
    size,
    style,
    fixedWidth,
    flip,
    rotate,
    pulse,
    spin,
    onClick,
    onMouseOver,
    onMouseLeave,
}) => {
    return (
        <span
            id={id}
            className={`${classes(
                icon,
                size,
                style,
                fixedWidth,
                flip,
                rotate,
                pulse,
                spin
            )} ${className}`}
            style={style}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        />
    );
};

LightFontAwesomeIcon.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    fixedWidth: PropTypes.bool,
    flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
    icon: PropTypes.string.isRequired,
    pulse: PropTypes.bool,
    rotate: PropTypes.oneOf([90, 180, 270]),
    size: PropTypes.oneOfType([PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']), PropTypes.number]),
    spin: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

LightFontAwesomeIcon.defaultProps = {
    size: 1,
};

export default LightFontAwesomeIcon;
