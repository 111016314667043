import {
    HIDE_ALERT,
    HIDE_AUDIO,
    REMOVE_TOAST,
    SHOW_AUDIO,
    STORE_AUDIO,
    STORE_DANGER_ALERT,
    STORE_DANGER_TOAST,
    STORE_INFO_ALERT,
    STORE_PRIMARY_ALERT,
    STORE_PRIMARY_TOAST,
    STORE_SECONDARY_TOAST,
    STORE_WARNING_ALERT,
    STORE_WARNING_TOAST,
} from 'store/actions/portal-action';
import {LOADED, LOADING, STORE_TOAST} from '../actions/portal-action';
import {make_id, make_timestamp, sortArrayByObjKeyReverted} from '../../utils';

const initialState = {
    alert: undefined,
    toasts: [],
    audio: {},
    loading: {},
    loaded_once: {},
};

export default function portalReducer(state = initialState, action) {
    switch (action.type) {
        case STORE_PRIMARY_ALERT:
            return {...state, alert: {...action.payload, type: 'success'}};
        case STORE_INFO_ALERT:
            return {...state, alert: {...action.payload, type: 'info'}};
        case STORE_DANGER_ALERT:
            return {...state, alert: {...action.payload, type: 'danger'}};
        case STORE_WARNING_ALERT:
            return {...state, alert: {...action.payload, type: 'warning'}};
        case HIDE_ALERT:
            return initialState;
        case STORE_TOAST:
            return {...state, toast: {...action.payload}};
        case STORE_SECONDARY_TOAST:
            return {
                ...state,
                toasts: sortArrayByObjKeyReverted(
                    [
                        {
                            ...action.payload,
                            id: make_id(10),
                            timestamp: make_timestamp(),
                            type: 'secondary',
                        },
                        ...state.toasts,
                    ],
                    'timestamp'
                ),
            };
        case STORE_WARNING_TOAST:
            return {
                ...state,
                toasts: sortArrayByObjKeyReverted(
                    [
                        {
                            ...action.payload,
                            id: make_id(10),
                            timestamp: make_timestamp(),
                            type: 'warning',
                        },
                        ...state.toasts,
                    ],
                    'timestamp'
                ),
            };
        case STORE_DANGER_TOAST:
            return {
                ...state,
                toasts: sortArrayByObjKeyReverted(
                    [
                        {
                            ...action.payload,
                            id: make_id(10),
                            timestamp: make_timestamp(),
                            type: 'danger',
                        },
                        ...state.toasts,
                    ],
                    'timestamp'
                ),
            };
        case STORE_PRIMARY_TOAST:
            return {
                ...state,
                toasts: sortArrayByObjKeyReverted(
                    [
                        {
                            ...action.payload,
                            id: make_id(10),
                            timestamp: make_timestamp(),
                            type: 'primary',
                        },
                        ...state.toasts,
                    ],
                    'timestamp'
                ),
            };
        case REMOVE_TOAST:
            return {...state, toasts: [...state.toasts.filter((t) => t.id !== action.payload)]};
        case SHOW_AUDIO:
            return {...state, audio: {...state.audio, show: true}};
        case HIDE_AUDIO:
            return {...state, audio: {show: false}};
        case STORE_AUDIO:
            return {...state, audio: {...state.audio, ...action.payload}};
        case LOADING:
            return {...state, loading: {...state.loading, [action.payload]: true}};
        case LOADED:
            return {
                ...state,
                loading: {...state.loading, [action.payload]: false},
                loaded_once: {...state.loaded_once, [action.payload]: true},
            };
        default:
            return state;
    }
}
