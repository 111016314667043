import React, {useState} from 'react';
import SidePanel from '../panel/SidePanel';
import SidePanelContent from '../panel/SidePanelContent';
import {FilterFactory, filters} from '../call/filters/FilterFactories';
import isEqual from 'lodash.isequal';
import {FormattedMessage, injectIntl} from 'react-intl';
import WidgetStudio from './WidgetStudio';
import {useSelector} from 'react-redux';
import {getLoadingPattern} from '../../store/selectors/portal-selectors';
import Spinner from '../spinner/Spinner';
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Row,
} from 'reactstrap';

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const WidgetGridHeader = injectIntl(
    ({
        intl,
        title,
        layouts,
        setLayouts,
        currentLayout,
        masterFilters,
        setMasterFilters,
        setCurrentLayout,
        currentWidget,
        setCurrentWidget,
        editMode,
        setEditMode,
        modalOpen,
        setModalOpen,
        onRefresh,
    }) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);

        const [addingLayout, setAddingLayout] = useState(false);
        const [deletingLayout, setDeletingLayout] = useState(false);

        const [newLayoutName, setNewLayoutName] = useState('');

        const loadingAnyWidget = useSelector(getLoadingPattern('widget', false));

        const dropdownToggle = () => {
            if (addingLayout) addingLayoutToggle();
            setDropdownOpen(!dropdownOpen);
        };

        const addingLayoutToggle = () => setAddingLayout(!addingLayout);
        const deletingLayoutToggle = () => setDeletingLayout(!deletingLayout);

        const modalToggle = () => {
            setCurrentWidget(null);
            setModalOpen(!modalOpen);
        };

        const editModeToggle = () => setEditMode(!editMode);

        const deleteLayout = () => {
            let newLayouts = layouts;
            delete newLayouts[currentLayout];

            setCurrentLayout(Object.keys(newLayouts)[0]);
            setLayouts(newLayouts);
        };

        const generateDeleteLayout = () => {
            if (deletingLayout)
                return (
                    <>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                deleteLayout();
                                deletingLayoutToggle();
                                dropdownToggle();
                            }}
                            children={<FormattedMessage id={'btn.ok'} defaultMessage={'OK'} />}
                            size={'sm'}
                        />
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                deletingLayoutToggle();
                                dropdownToggle();
                            }}
                            children={
                                <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                            }
                            size={'sm'}
                        />
                    </>
                );
            else return <FormattedMessage id={'btn.delete'} defaultMessage={'Delete'} />;
        };

        const generateAddLayout = () => {
            if (addingLayout)
                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (newLayoutName) {
                                setCurrentLayout(newLayoutName);
                                setLayouts({...layouts, [newLayoutName]: []});
                                if (!editMode) editModeToggle();
                            }
                            addingLayoutToggle();
                            dropdownToggle();
                        }}
                    >
                        <Input
                            type='text'
                            onChange={(e) => {
                                e.preventDefault();
                                setNewLayoutName(e.target.value);
                            }}
                            placeholder={intl.formatMessage({
                                id: 'dashboard.layout_name',
                                defaultMessage: 'Layout title',
                            })}
                        />
                    </Form>
                );
            else return <FormattedMessage id={'btn.add'} defaultMessage={'Add'} />;
        };

        const addLayoutFilter = (layoutFilter) => {
            let newMasterFilters = {...masterFilters};
            newMasterFilters[layoutFilter.index] = layoutFilter;
            if (!isEqual(masterFilters, newMasterFilters)) setMasterFilters(newMasterFilters);
        };

        const WidgetHandler = (widgetData) => {
            let currentWidgets = [...layouts[currentLayout]];
            let dt = new Date().getTime();
            let i = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            });
            let order =
                Math.max.apply(
                    Math,
                    layouts[currentLayout].map((widget) => widget.order)
                ) + 1;
            if (widgetData.i) {
                setLayouts({
                    ...layouts,
                    [currentLayout]: currentWidgets.map((widget) => {
                        if (widget.i === widgetData.i) return widgetData;
                        else return widget;
                    }),
                });
            } else {
                currentWidgets.push({
                    ...widgetData,
                    i,
                    order,
                    width: 1,
                    height: 1,
                    title: widgetData.title,
                });
                setLayouts({...layouts, [currentLayout]: currentWidgets});
            }
            setCurrentWidget(null);
        };

        return (
            <>
                <Row className={'flex-row justify-content-between'}>
                    <Col className={'d-flex flex-col align-items-baseline justify-content-between'}>
                        <div>{title}</div>
                        <div className={'d-flex'}>
                            <FilterFactory
                                add={addLayoutFilter}
                                auto={false}
                                hideCancel={true}
                                cancel={() => {}}
                                component={filters['date'].component}
                                componentProps={{...filters['date'].componentProps, limit: 365}}
                                factory={filters['date'].factory}
                                defaultValue={
                                    masterFilters['date']
                                        ? masterFilters['date'].state_value
                                        : undefined
                                }
                            />
                        </div>
                        <div className={'d-flex flex-row align-items-center'}>
                            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                                <DropdownToggle
                                    caret
                                    children={
                                        <FormattedMessage
                                            id={'dashboard.layouts'}
                                            defaultMessage={'Layouts'}
                                        />
                                    }
                                    size='sm'
                                />
                                <DropdownMenu end>
                                    <DropdownItem
                                        header
                                        children={
                                            <FormattedMessage
                                                id={'dashboard.your_layouts'}
                                                defaultMessage={'Your Layouts'}
                                            />
                                        }
                                    />
                                    {Object.keys(layouts).map((key) => {
                                        return (
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setEditMode(false);
                                                    setCurrentLayout(key);
                                                }}
                                                active={currentLayout === key}
                                                disabled={currentLayout === key}
                                                key={key}
                                            >
                                                {capitalize(key)}
                                            </DropdownItem>
                                        );
                                    })}
                                    <DropdownItem
                                        header
                                        children={
                                            <FormattedMessage
                                                id={'dashboard.manage_layouts'}
                                                defaultMessage={'Manage Layouts'}
                                            />
                                        }
                                    />
                                    <DropdownItem
                                        toggle={false}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!addingLayout) {
                                                addingLayoutToggle();
                                            }
                                        }}
                                        children={generateAddLayout()}
                                    />
                                    {addingLayout ? (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.preventDefault();
                                                addingLayoutToggle();
                                            }}
                                            children={
                                                <FormattedMessage
                                                    id={'btn.cancel'}
                                                    defaultMessage={'Cancel'}
                                                />
                                            }
                                        />
                                    ) : (
                                        <DropdownItem
                                            toggle={false}
                                            disabled={Object.keys(layouts).length <= 1}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!deletingLayout) {
                                                    deletingLayoutToggle();
                                                }
                                            }}
                                            children={generateDeleteLayout()}
                                        />
                                    )}
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.preventDefault();
                                            editModeToggle();
                                        }}
                                        children={
                                            <FormattedMessage
                                                id={'btn.edit'}
                                                defaultMessage={'Edit'}
                                            />
                                        }
                                    />
                                </DropdownMenu>
                            </Dropdown>
                            <span className={'ml-1'}>
                                {!loadingAnyWidget ? (
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onRefresh();
                                        }}
                                        children={<i className={'fa fa-sync'} />}
                                        size='sm'
                                    />
                                ) : (
                                    <Spinner
                                        color={'primary'}
                                        background={'transparent'}
                                        global={false}
                                        size={20}
                                    />
                                )}
                            </span>
                        </div>
                    </Col>
                </Row>
                {editMode ? (
                    <Row className={'flex-row justify-content-between'}>
                        <Col className={'d-flex flex-col'}>
                            <hr
                                className={'mt-3 mb-2'}
                                style={{
                                    width: 'inherit',
                                    border: '1px solid rgba(0, 0, 0, 0.05)',
                                }}
                            />
                        </Col>
                    </Row>
                ) : null}
                {editMode ? (
                    <Row className={'flex-row justify-content-between mt-2'}>
                        <Col className={'d-flex flex-col align-items-between'}>
                            <div className={'d-flex mr-auto'}>
                                <Input
                                    type='text'
                                    size='sm'
                                    defaultValue={currentLayout}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setNewLayoutName(e.target.value);
                                    }}
                                    placeholder={intl.formatMessage({
                                        id: 'dashboard.layout_name',
                                        defaultMessage: 'Layout title',
                                    })}
                                />
                                <Button
                                    className={'ml-2'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (newLayoutName) {
                                            if (
                                                !Object.keys(layouts).some(
                                                    (title) => title === newLayoutName
                                                )
                                            ) {
                                                const newLayouts = {...layouts};
                                                const currentGenLayout = newLayouts[currentLayout];
                                                delete newLayouts[currentLayout];
                                                setCurrentLayout(newLayoutName);
                                                setLayouts({
                                                    ...newLayouts,
                                                    [newLayoutName]: currentGenLayout,
                                                });
                                            }
                                        }
                                    }}
                                    children={
                                        <FormattedMessage
                                            id={'dashboard.edit_title'}
                                            defaultMessage={'Edit title'}
                                        />
                                    }
                                    size='sm'
                                />
                            </div>
                            <div style={{marginRight: '-8px'}} className={'d-flex'}>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        modalToggle();
                                    }}
                                    children={
                                        <FormattedMessage
                                            id={'dashboard.add_widget'}
                                            defaultMessage={'Add widget'}
                                        />
                                    }
                                    size='sm'
                                />
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        editModeToggle();
                                    }}
                                    children={
                                        <FormattedMessage id={'btn.done'} defaultMessage={'Done'} />
                                    }
                                    size='sm'
                                />
                                <SidePanel
                                    isOpen={modalOpen}
                                    setOpen={setModalOpen}
                                    onGlassClick={modalToggle}
                                    size={90}
                                >
                                    <SidePanelContent className={'d-flex flex-grow-1 p-0'}>
                                        <WidgetStudio
                                            widgetHandler={WidgetHandler}
                                            currentWidget={currentWidget}
                                            masterFilters={masterFilters}
                                            modalToggle={modalToggle}
                                        />
                                    </SidePanelContent>
                                </SidePanel>
                            </div>
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    }
);

export default WidgetGridHeader;
