import {FormattedMessage} from 'react-intl';
import React, {Dispatch} from 'react';
import {HasCheck} from '../../../components/security/Has';
import QueueOverTimeReport from './queues/over_time/QueueOverTimeReport';
import QueuesByQueueAndAgentReport from './queues/by_queue_and_agent/QueuesByQueueAndAgentReport';
import QueuesTimeSheetsReport from './queues/time_sheets/QueuesTimeSheetsReport';
import QueueReportsParameters from './queues/QueuesReportsParameters';
import QueuesOverTimeReportParameters from './queues/over_time/QueuesOverTimeReportParameters';
import moment from 'moment';
import {fetchCustomerQueueEventsStatistics} from '../../../store/actions/customer-action';
import {filterEmptyParameters} from '../../../utils/format';
import CallReportsParameters from './calls-statistics/CallsReportsParameters';
import {fetchWidgetData, fetchWidgetDataOnce} from 'store/actions/cdr-action';
import CallsStatisticsAll from './calls-statistics/statistics_all/CallsStatisticsAll';
import CallsStatisticsByAgent from './calls-statistics/statistics_by_agent/CallsStatisticsByAgent';
import {date_filter} from '../../../components/call/filters/Date';
import CallStatisticsByAgentParameters from './calls-statistics/statistics_by_agent/CallStatisticsByAgentParameters';
import CallStatisticsAllParameters from './calls-statistics/statistics_all/CallStatisticsAllParameters';

interface BaseReportRoute {
    name: string;
    path: string;
    title: React.ReactElement;
    parameters?: React.FC<any>;
    defaultParameters?: any;
    has?: Partial<HasCheck>;
}

export interface ComponentReportRoute extends BaseReportRoute {
    component: React.FC<any>;
    queryFactory: (dispatch: Dispatch<any>, parameters: any) => any;
    className?: string;
    fullHeight: boolean;
}

export interface ReportSwitchRoute extends BaseReportRoute {
    routes: ComponentReportRoute[];
}

export const reportsRoutes: ReportSwitchRoute[] = [
    {
        name: 'queues',
        path: '/queues',
        title: <FormattedMessage id={'navbar.reports.queues'} defaultMessage={'Queues'} />,
        parameters: QueueReportsParameters,
        defaultParameters: {
            date: {
                start: moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            queues: [],
            agents: [],
        },
        has: {
            aPolicyMatching: [{resourceType: 'QUEUES'}, {resourceType: 'AGENTS'}],
        },
        routes: [
            {
                name: 'over_time',
                path: '/over_time',
                component: QueueOverTimeReport,
                parameters: QueuesOverTimeReportParameters,
                defaultParameters: {
                    granularity: 'day',
                },
                className: 'responsive-chart',
                fullHeight: true,
                queryFactory: (dispatch, parameters) => {
                    const params = {
                        start_date: parameters.date.start,
                        end_date: parameters.date.end,
                        queue_ids: parameters.queues,
                        agent_ids: parameters.agents,
                        groupers: [
                            {field: 'datetime', frequency: parameters.granularity},
                            {field: 'result'},
                        ],
                        aggregators: [
                            {field: 'call_time', aggregator: 'mean'},
                            {field: 'hold_time', aggregator: 'mean'},
                            {field: 'abandoned', aggregator: 'sum'},
                            {field: 'answered', aggregator: 'sum'},
                            {field: 'exited', aggregator: 'sum'},
                            {field: 'timed_out', aggregator: 'sum'},
                        ],
                        presentation: 'table',
                    };
                    dispatch(
                        fetchCustomerQueueEventsStatistics({
                            name: 'over_time',
                            ...filterEmptyParameters(params),
                        })
                    );
                },
                title: (
                    <FormattedMessage
                        id={'navbar.reports.queues.over.time'}
                        defaultMessage={'Queue Statistics Over Time'}
                    />
                ),
            },
            {
                name: 'by_queue_and_agent',
                path: '/by_queue_and_agent',
                component: QueuesByQueueAndAgentReport,
                fullHeight: true,
                title: (
                    <FormattedMessage
                        id={'navbar.reports.queues.by.queue.and.agent'}
                        defaultMessage={'Agent statistics for each queue'}
                    />
                ),
                queryFactory: (dispatch, parameters) => {
                    const params = {
                        start_date: parameters.date.start,
                        end_date: parameters.date.end,
                        queue_ids: parameters.queues,
                        agent_ids: parameters.agents,
                        groupers: [{field: 'queue'}, {field: 'agent'}],
                        aggregators: [
                            {field: 'call_time', aggregator: 'mean'},
                            {field: 'hold_time', aggregator: 'mean'},
                            {field: 'abandoned', aggregator: 'sum'},
                            {field: 'answered', aggregator: 'sum'},
                            {field: 'exited', aggregator: 'sum'},
                            {field: 'timed_out', aggregator: 'sum'},
                        ],
                        presentation: 'table',
                    };
                    dispatch(
                        fetchCustomerQueueEventsStatistics({
                            name: 'by_queue_and_agent',
                            ...filterEmptyParameters(params),
                        })
                    );
                },
            },
            {
                name: 'time_sheets',
                path: '/time_sheets',
                component: QueuesTimeSheetsReport,
                fullHeight: false,
                title: (
                    <FormattedMessage
                        id={'navbar.reports.queues.time.sheets'}
                        defaultMessage={'Time Sheets'}
                    />
                ),
                queryFactory: (dispatch, parameters) => {
                    const params = {
                        start_date: parameters.date.start,
                        end_date: parameters.date.end,
                        queue_ids: parameters.queues,
                        agent_ids: parameters.agents,
                        presentation: 'table',
                    };
                    dispatch(
                        fetchCustomerQueueEventsStatistics({
                            name: 'time_sheets',
                            ...filterEmptyParameters(params),
                        })
                    );
                },
            },
        ],
    },
    {
        name: 'calls_statistics',
        path: '/calls_statistics',
        title: <FormattedMessage id={'navbar.reports.calls_statistics'} defaultMessage={'Calls'} />,
        parameters: CallReportsParameters,
        defaultParameters: {
            date: {
                start: moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
        },
        has: {
            aPolicyMatching: [{resourceType: 'CALLS_STATISTICS'}, {resourceType: 'EXTENSIONS'}],
        },
        routes: [
            {
                name: 'calls_statistics_all',
                path: '/calls_statistics_all',
                component: CallsStatisticsAll,
                parameters: CallStatisticsAllParameters,
                fullHeight: true,
                title: (
                    <FormattedMessage
                        id={'navbar.reports.calls_statistics.all'}
                        defaultMessage={'All Calls'}
                    />
                ),
                queryFactory: (dispatch, parameters) => {
                    let date = {filters: {date: {}}};

                    if (parameters?.date?.end) {
                        date = date_filter
                            .factory([parameters?.date?.start, parameters?.date?.end])
                            .custom_filter(date);
                    }

                    const params = {
                        filters: {
                            date: date.filters.date,
                            direction: ['in', 'out'],
                            status: ['hanged', 'missed', 'not connected'],
                            ...(parameters?.paginatedExtensions?.length > 0 && {
                                extension: parameters.paginatedExtensions,
                            }),
                        },
                        aggs: {
                            by_extension: {
                                agg_type: 'terms',
                                field: 'extension.keyword',
                                options: {
                                    size: 14,
                                    order: {_key: 'asc'},
                                },
                                aggs: {
                                    duration: {
                                        agg_type: 'sum',
                                        field: 'duration',
                                    },
                                    by_direction: {
                                        agg_type: 'terms',
                                        field: 'direction.keyword',
                                        aggs: {
                                            duration: {
                                                agg_type: 'sum',
                                                field: 'duration',
                                            },
                                            by_action: {
                                                agg_type: 'terms',
                                                field: 'action.keyword',
                                                aggs: {
                                                    duration: {
                                                        agg_type: 'sum',
                                                        field: 'duration',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            duration: {
                                agg_type: 'sum',
                                field: 'duration',
                            },
                        },
                    };

                    const totalParams = {
                        filters: {
                            date: date.filters.date,
                            direction: ['in', 'out'],
                            status: ['hanged', 'missed', 'not connected'],
                            ...(parameters?.extensions?.length > 0 && {
                                extension: parameters.extensions,
                            }),
                        },
                        aggs: {
                            by_direction: {
                                agg_type: 'terms',
                                field: 'direction.keyword',
                                aggs: {
                                    duration: {
                                        agg_type: 'sum',
                                        field: 'duration',
                                    },
                                    by_action: {
                                        agg_type: 'terms',
                                        field: 'action.keyword',
                                        aggs: {
                                            duration: {
                                                agg_type: 'sum',
                                                field: 'duration',
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    };

                    dispatch(
                        fetchWidgetData({
                            id: 'calls_statistics_all',
                            type: 'ES',
                            params: {
                                statistics: filterEmptyParameters(params),
                            },
                        })
                    );

                    dispatch(
                        fetchWidgetData({
                            id: 'calls_statistics_all_total',
                            type: 'ES',
                            params: {
                                statistics: filterEmptyParameters(totalParams),
                            },
                        })
                    );
                },
            },
            {
                name: 'calls_statistics_by_agent',
                path: '/calls_statistics_by_agent',
                component: CallsStatisticsByAgent,
                parameters: CallStatisticsByAgentParameters,
                defaultParameters: {
                    hideEmptyHours: true,
                },
                fullHeight: true,
                className: 'responsive-chart',
                title: (
                    <FormattedMessage
                        id={'navbar.reports.calls_statistics.by_agent'}
                        defaultMessage={'Calls by agent'}
                    />
                ),
                queryFactory: (dispatch, parameters) => {
                    let date = {filters: {date: {}}};

                    const startDate = moment(parameters?.date?.start);
                    const endDate = moment(parameters?.date?.end);

                    if (parameters?.date?.end) {
                        date = date_filter
                            .factory([
                                startDate.subtract(0, 'days').format('YYYY-MM-DD'),
                                endDate.add(0, 'days').format('YYYY-MM-DD'),
                            ])
                            .custom_filter(date);
                    }

                    const params = {
                        filters: {
                            status: ['hanged', 'not connected'],
                            date: date.filters.date,
                            ...(parameters?.paginatedExtensions?.length > 0 && {
                                extension: parameters.paginatedExtensions,
                            }),
                        },
                        aggs: {
                            by_extension: {
                                agg_type: 'terms',
                                field: 'extension.keyword',
                                options: {
                                    size: 14,
                                    order: {_key: 'asc'},
                                },
                                aggs: {
                                    by_hour: {
                                        agg_type: 'date_histogram',
                                        field: 'call_start_time',
                                        options: {
                                            interval: 'hour',
                                            time_zone: 'America/Toronto',
                                        },
                                        aggs: {
                                            duration: {
                                                agg_type: 'sum',
                                                field: 'duration',
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    };

                    dispatch(
                        fetchWidgetDataOnce({
                            id: 'calls_statistics_by_agent',
                            type: 'ES',
                            params: {
                                statistics: filterEmptyParameters(params),
                            },
                        })
                    );
                },
            },
        ],
    },
];

export default reportsRoutes;
