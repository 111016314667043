import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    DOWNLOAD_CANCEL,
    DOWNLOAD_FILE,
    DOWNLOAD_FILE_CANCEL,
    DOWNLOAD_FILE_FAIL,
    DOWNLOAD_FILE_SUCCESS,
    LOADING,
} from '../actions/download-action';

import {APIRequestGenerator} from './index';

let dataPart = {};
let abortFetch = false;
//Approximate download speed: 1mn 31s
export function* downloadCSV(action) {
    try {
        abortFetch = false;

        let csvContainer = [];
        let size = action.payload.total;
        let count = 0;
        let callsToMake = parseInt(size / 1000);
        let remainder = size % 1000;

        let newParams = action.payload.params.list;
        let progress = 0;

        if (callsToMake > 9) {
            callsToMake = 9;
        }

        if (callsToMake < 1) {
            callsToMake = 1;
        }

        while (count * 1000 <= callsToMake * 1000) {
            newParams['from'] = count * 1000;
            newParams['size'] = 1000;

            dataPart = yield call(APIRequestGenerator, 'post', '/calls', {
                params: newParams,
            });

            csvContainer.push(dataPart);

            progress = (count / callsToMake).toFixed(2);

            let totalCalls = callsToMake * 1000 + remainder;

            yield put({type: LOADING, progress: progress, loading: true, totalCalls: totalCalls});
            count++;

            if (abortFetch === true) {
                yield put({type: DOWNLOAD_CANCEL, abort: abortFetch});
                return;
            }
        }
        yield put({type: DOWNLOAD_FILE_SUCCESS, file: csvContainer});
    } catch (e) {
        yield put({type: DOWNLOAD_FILE_FAIL, error: 'csv.error'});
    }
}

export function* cancelCSV() {
    abortFetch = !abortFetch;
    yield put({type: DOWNLOAD_CANCEL, abort: abortFetch});
}

const downloadSagas = [
    takeLatest(DOWNLOAD_FILE, downloadCSV),

    takeEvery(DOWNLOAD_FILE_CANCEL, cancelCSV),
];

export default downloadSagas;
