import React, {useState} from 'react';
import TextInput from './TextInput';

interface PasswordInputProps {
    className?: string;
    enabled?: boolean;
    name?: string;
    onValidityChange?: any;
    onValueChange?: any;
    onEnter?: any;
    onEsc?: any;
    title?: string;
    placeholder?: any;
    validator?: any;
    value?: string | undefined;
    errorMessage?: string;
    maxLength?: number;
}

const PasswordInput = ({
    className,
    enabled,
    name,
    onValidityChange,
    onValueChange,
    onEnter,
    onEsc,
    title,
    placeholder,
    validator,
    value,
    errorMessage,
    maxLength,
}: PasswordInputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextInput
            className={className}
            type={showPassword ? 'text' : 'password'}
            name={name}
            enabled={enabled}
            icon={showPassword ? 'eye-slash' : 'eye'}
            onValidityChange={onValidityChange}
            onValueChange={onValueChange}
            onEnter={onEnter}
            onEsc={onEsc}
            onIconClick={() => setShowPassword(!showPassword)}
            title={title}
            placeholder={placeholder}
            validator={validator}
            value={value}
            errorMessage={errorMessage}
            maxLength={maxLength}
        />
    );
};

export default PasswordInput;
