import React from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getUserCustomerLicense} from '../../store/selectors/user-selectors';
import ContentWrapper from '../../components/content/ContentWrapper';
import CardContent from '../../components/content/CardContent';
import WidgetGrid from '../../components/dashboard/WidgetGrid';
import {FormattedMessage} from 'react-intl';
import {Has} from '../../components/security/Has';
import TravellerDashboard from './TravellerDashboard';

const Dashboard = () => {
    const customerLicense: string = useSelector(getUserCustomerLicense);

    return customerLicense === 'PREMIUM' || customerLicense === 'PRO' ? (
        <ContentWrapper>
            <CardContent fullHeight scroll>
                <Redirect
                    to={{
                        pathname: '/license',
                        state: {
                            access: 'dashboard',
                        },
                    }}
                />
            </CardContent>
        </ContentWrapper>
    ) : (
        <Has forbidTravel orElse={<TravellerDashboard />}>
            <WidgetGrid
                title={<FormattedMessage id={'title.dashboard'} defaultMessage={'Dashboard'} />}
            />
        </Has>
    );
};

export default Dashboard;
