import {useSelector} from 'react-redux';
import {customerExtensionsSelector} from '../../../store/selectors/customer-selectors';
import React, {useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTerminal, faUser} from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../input/SelectInput';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import * as PropTypes from 'prop-types';
import {extension_label} from '../../../utils/call_history';

export const messages = defineMessages({
    display: {
        id: 'message.extension',
        defaultMessage: 'User: {extension}',
    },
    title: {
        id: 'filter.title.extension',
        defaultMessage: 'Extension',
    },
});

const ExtensionFilter = ({onValidityChange, onValueChange, validator}) => {
    const extensions = useSelector(customerExtensionsSelector);

    const [extension, setExtension] = useState();

    useEffect(() => {
        onValueChange(extension);
    }, [extension, onValueChange]);

    return (
        <div className={'d-flex'} style={{minWidth: 500}}>
            <Button size={'sm'} outline color={'default'} onClick={() => onValueChange('s', true)}>
                <FontAwesomeIcon icon={faTerminal} className={'mr-1'} />
                <FormattedMessage id={'message.system.extension'} defaultMessage={'System'} />
            </Button>
            {extensions && (
                <div className={'d-flex flex-grow-1'}>
                    <SelectInput
                        isCreatable
                        onValueChange={setExtension}
                        onValidityChange={onValidityChange}
                        options={extensions.map((e) => ({
                            label: e.name ? `${e.extension} - ${e.name}` : `${e.extension}`,
                            value: e.extension,
                        }))}
                        placeholder={'Extension'}
                        validator={validator}
                        value={extension}
                    />
                </div>
            )}
        </div>
    );
};

ExtensionFilter.propTypes = {
    onEnter: PropTypes.func,
    onEsc: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValueChange: PropTypes.func,
    validator: PropTypes.func,
};

ExtensionFilter.defaultProps = {
    onEnter: () => {},
    onEsc: () => {},
    onValidityChange: () => true,
    validator: () => true,
};

const ExtensionFilterDisplay = injectIntl(({extension, intl}) => {
    const extensions = useSelector(customerExtensionsSelector);

    return (
        <FormattedMessage
            {...messages.display}
            values={{
                extension: extension_label(
                    extension,
                    extensions,
                    intl.formatMessage({id: 'message.system.extension', defaultMessage: 'System'})
                ),
            }}
        />
    );
});

ExtensionFilterDisplay.propTypes = {
    extension: PropTypes.string.isRequired,
};

const factory = (extension) => ({
    type: 'extension',
    index: `extension_${extension}`,
    serialized: extension,
    custom_filter: (query) => {
        query.filters.extension = query.filters.extension
            ? [extension, ...query.filters.extension]
            : [extension];
        return query;
    },
    elastic_filter: (query) => query.filter('term', 'extension', extension),
    display: {
        icon: faUser,
        content: <ExtensionFilterDisplay extension={extension} />,
    },
});

export const extension_filter = {
    title: <FormattedMessage {...messages.title} />,
    component: ExtensionFilter,
    componentProps: {
        validator: (extension) => extension !== null,
    },
    factory: factory,
    icon: faUser,
};
