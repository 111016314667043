import React, {useState} from 'react';
import {Button, Card, CardBody, CardText} from 'reactstrap';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import {trad} from '../phoneNumbersTraduction';
import DangerModal from 'components/notification/DangerModal';
import FormSection from 'components/form/FormSection';
import {useIntl} from 'react-intl';
import {deleteCustomerPhoneNumber} from 'store/actions/customer-action';
import {useDispatch} from 'react-redux';

const DeleteNumber = ({
    did,
    toggle,
    currentCustomer,
    newNumber,
}: {
    did: string;
    toggle: () => void;
    currentCustomer: string;
    newNumber: boolean;
}) => {
    const reduxDispatch = useDispatch();
    const intl = useIntl();
    const [deletePhoneNumberModal, setDeletePhoneNumberModal] = useState(false);

    const deletePhoneNumber = (e: any) => {
        e.preventDefault();
        reduxDispatch(
            deleteCustomerPhoneNumber({
                did_number: did,
                customer: currentCustomer,
            })
        );
        toggle();
    };

    return (
        <>
            {!newNumber && (
                <FormSection
                    icon={faCog}
                    title={intl.formatMessage(trad.advanced)}
                    isOpenByDefault={false}
                >
                    <Card>
                        <CardBody>
                            <h2 className={'text-danger'}>
                                {intl.formatMessage(trad.deletePhoneNumber)}
                            </h2>
                            <CardText className={'text-muted'}>
                                <small>{intl.formatMessage(trad.warningPhoneNumber)}</small>
                            </CardText>
                            <Button
                                color={'danger'}
                                size={'sm'}
                                onClick={() => setDeletePhoneNumberModal(true)}
                            >
                                {intl.formatMessage(trad.deletePhoneNumber)}
                            </Button>
                        </CardBody>
                    </Card>
                    <DangerModal
                        isOpen={deletePhoneNumberModal}
                        toggle={() => setDeletePhoneNumberModal(!deletePhoneNumberModal)}
                        title={intl.formatMessage(trad.deletePhoneNumber)}
                        onClosed={() => setDeletePhoneNumberModal(false)}
                        content={intl.formatMessage(trad.warningModalPhoneNumber)}
                        onClick={(e: any) => deletePhoneNumber(e)}
                    />
                </FormSection>
            )}
        </>
    );
};

export default DeleteNumber;
