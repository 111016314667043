import {formatQueryString} from 'utils/format';
import {Auth} from 'aws-amplify';

type Session = {
    idToken: {
        jwtToken: string;
        payload: {
            exp: number;
        };
    };
    accessToken: {
        jwtToken: string;
    };
};
export const apiHeader = (session: Session, headers: object) => {
    return {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'X-Access-Token': session.accessToken.jwtToken,
        'Content-Type': 'application/json',
        ...headers,
    };
};

let fetchWithRetry: (
    url: string,
    options: object,
    tries: number,
) => Promise<Response | NodeJS.Timeout>;
fetchWithRetry = async (url: string, options: object, tries: number) => {
    try {
        return await fetch(url, options);
    } catch (err) {
        if (tries === 1) throw err;
        return setTimeout(async () => await fetchWithRetry(url, options, tries - 1), 500);
    }
};

function isResponse(item: Response | NodeJS.Timeout): item is Response {
    return (item as Response).ok !== undefined;
}

export type ApiRequestParameters = Partial<{
    url: string;
    endpoint: string;
    method: 'DELETE' | 'GET' | 'POST' | 'PUT';
    session: Session;
    params: object;
    headers: object;
    signal: AbortSignal;
    json: boolean;
    tries: number;
    mode: string;
    version: 'v1' | 'v2' | 'v3';
}>;

async function apiRequest({
    url,
    endpoint,
    method = 'GET',
    session,
    params,
    headers,
    signal,
    json = true,
    tries = 10,
    mode = 'cors',
    version = 'v1',
}: ApiRequestParameters = {}) {
    if (session !== undefined) {
        if (session.idToken.payload.exp * 1000 <= Date.now()) {
            headers = new Headers(
                apiHeader((await Auth.currentSession()) as unknown as Session, headers || {}),
            );
        } else {
            headers = new Headers(apiHeader(session, headers || {}));
        }
    }
    let queryUrl = `${url ? url : process.env.REACT_APP_API_BASEPATH}/${version}${
        endpoint ? endpoint : ''
    }`;
    let response;
    let options = {method, headers, signal, mode};
    if (method === 'GET') {
        if (params !== undefined) {
            queryUrl += '?' + encodeURI(formatQueryString(params));
        }
        response = await fetchWithRetry(queryUrl, options, tries);
    } else {
        response = await fetchWithRetry(
            queryUrl,
            {...options, body: JSON.stringify(params)},
            tries,
        );
    }
    if (json && method !== 'DELETE' && isResponse(response)) {
        const jsonContents = await response.json();
        if (jsonContents.status && jsonContents.status >= 400) {
            throw jsonContents;
        } else return jsonContents;
    } else return response;
}

//for new apis
async function apiNewRequest({
    url,
    endpoint,
    method = 'GET',
    session,
    params,
    headers,
    signal,
    json = true,
    tries = 10,
    mode = 'cors',
    version = 'v3',
}: ApiRequestParameters = {}) {
    if (session !== undefined) {
        if (session.idToken.payload.exp * 1000 <= Date.now()) {
            headers = new Headers(
                apiHeader((await Auth.currentSession()) as unknown as Session, headers || {}),
            );
        } else {
            headers = new Headers(apiHeader(session, headers || {}));
        }
    }
    url = process.env.REACT_APP_AZ_API_BASEPATH;
    let queryUrl = `${url}/${version}${endpoint ? endpoint : ''}`;
    let response;
    let options = {method, headers, mode};
    if (endpoint === '/calls/statistics') {
        params = {
            filters: {
                name: ['string'],
                external_number: ['string'],
                extension: ['string'],
            },
            sort: {
                by: 'string',
                order: 'string',
            },
            size: 0,
            from: 0,
            extras: {
                tagMode: 'string',
                nameMode: 'string',
            },
        };
    }
    if (method === 'GET') {
        if (params !== undefined) {
            queryUrl += '?' + encodeURI(formatQueryString(params));
        }
        response = await fetchWithRetry(queryUrl, options, tries);
    } else {
        response = await fetchWithRetry(
            queryUrl,
            {...options, body: JSON.stringify(params)},
            tries,
        );
    }
    if (json && method !== 'DELETE' && isResponse(response)) {
        const jsonContents = await response.json();
        if (jsonContents.status && jsonContents.status >= 400) {
            throw jsonContents;
        } else return jsonContents;
    } else return response;
}

const API = {
    get: (args: ApiRequestParameters) => apiRequest({...args}),
    post: (args: ApiRequestParameters) => apiRequest({...args, method: 'POST'}),
    put: (args: ApiRequestParameters) => apiRequest({...args, method: 'PUT'}),
    del: (args: ApiRequestParameters) => apiRequest({...args, method: 'DELETE'}),
    newPost: (args: ApiRequestParameters) => apiNewRequest({...args, method: 'POST'}),
    newGet: (args: ApiRequestParameters) => apiNewRequest({...args}),
};
export default API;
