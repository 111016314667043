import React, {useEffect, useState} from 'react';
import {NavLink as NavLinkRRD, Route, Switch, useHistory} from 'react-router-dom';
import {InputGroup, NavbarBrand, NavLink, Popover, PopoverBody} from 'reactstrap';
import logo from 'assets/images/logo.svg';
import administrationRoutes from '../../routes/administration';
import superAdministrationRoutes from '../../routes/superAdministration';
import * as R from 'routes';
import {administration, superAdministration} from 'routes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlaneArrival, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons';
import {exist} from 'utils';
import HoverAnimation from '../animation/HoverAnimation';
import {Side, SideItem} from './Side';
import AdminSidebar from './AdminSidebar';
import {useDispatch, useSelector} from 'react-redux';
import {travelTo} from '../../store/actions/user-action';
import {
    getUserSession,
    getUserTravel,
    userTravelingSelector,
} from '../../store/selectors/user-selectors';
import {useQuery} from '../../utils/hooks';
import {Has} from '../security/Has';
import {make_travel_query_string} from '../../utils/history';
import API from '../../utils/api';
import {loaded, loading} from '../../store/actions/portal-action';
import * as PropTypes from 'prop-types';
import {getCustomers} from '../../store/selectors/customer-selectors';
import SelectInput from '../input/SelectInput';
import {fetchCustomers} from '../../store/actions/customer-action';
import {defineMessages, useIntl} from 'react-intl';
import {getLoading} from '../../store/selectors/portal-selectors';
import SidebarPbxAdmin from './SidebarPbxAdmin';

const messages = defineMessages({
    loadingCustomers: {
        id: 'message.loading.customers',
        defaultMessage:
            'Loading customers ... (You can still travel by typing a customer ID or phone number)',
    },
    noOptions: {
        id: 'message.no.options.customers',
        defaultMessage: 'Invalid customer ID or phone number',
    },
    travelTo: {
        id: 'message.travel.to.customer',
        defaultMessage: 'Travel to {customer}',
    },
    placeholder: {
        id: 'message.travel.placeholder',
        defaultMessage: 'Customer ID/Phone number...',
    },
});

const SidebarTravel = () => {
    const intl = useIntl();
    const query = useQuery();
    const history = useHistory();

    const session = useSelector(getUserSession);
    const travel = useSelector(getUserTravel);
    const travelling = useSelector(userTravelingSelector);
    const customers = useSelector(getCustomers);
    const loadingCustomers = useSelector(getLoading('customers'));
    const reduxDispatch = useDispatch();

    const [toggle, setToggle] = useState(false);
    const [travelInput, setTravelInput] = useState(travel ? travel.customer : '');

    useEffect(() => {
        reduxDispatch(fetchCustomers());
    }, [reduxDispatch, travelling]);

    const handleKeyPress = (input) => {
        if (!/^[0-9]+$/g.test(input) && input.length > 2 && !isNaN(input.slice(-2))) {
            reduxDispatch(travelTo(input));
            setToggle(false);
            history.push({
                pathname: history.pathname,
                search: make_travel_query_string(query, input),
            });
            setTravelInput(input);
        } else if (/^[0-9]+$/g.test(input)) {
            setToggle(false);
            reduxDispatch(loading('travel'));
            API.get({session, endpoint: `/customers/${input}`}).then((res) => {
                const customerId = res.data.id;
                reduxDispatch(travelTo(customerId));
                history.push({search: make_travel_query_string(query, customerId)});
                reduxDispatch(loaded('travel'));
            });
            setTravelInput(input);
        }
    };

    return (
        <SideItem>
            <span className={'d-flex flex-column justify-content-center'}>
                <NavLink
                    href='/'
                    id={'nomad'}
                    onClick={
                        exist(travel)
                            ? (e) => {
                                  e.preventDefault();
                                  reduxDispatch(travelTo());
                                  history.push({
                                      pathname: history.pathname,
                                      search: make_travel_query_string(query, null),
                                  });
                              }
                            : (e) => {
                                  e.preventDefault();
                                  setToggle(true);
                              }
                    }
                >
                    <FontAwesomeIcon icon={exist(travel) ? faPlaneArrival : faPlaneDeparture} />
                </NavLink>
                <Popover
                    placement='auto'
                    target='nomad'
                    className='popover-default'
                    toggle={() => setToggle(false)}
                    isOpen={toggle}
                    style={{width: 512}}
                >
                    <PopoverBody className={'d-flex align-items-center'}>
                        <InputGroup>
                            <SelectInput
                                value={travelInput}
                                validator={(input) =>
                                    (!/^[0-9]+$/g.test(input) &&
                                        input.length > 2 &&
                                        !isNaN(input.slice(-2))) ||
                                    /^[0-9]+$/g.test(input)
                                }
                                className={'text-default'}
                                isCreatable
                                isSearchable
                                onValueChange={handleKeyPress}
                                placeholder={intl.formatMessage(messages.placeholder)}
                                options={customers.map((c) => ({
                                    value: c.id,
                                    label: c.name && c.name !== '' ? `${c.name} - ${c.id}` : c.id,
                                }))}
                                reactSelectProps={{
                                    menuPlacement: 'top',
                                    isLoading: loadingCustomers,
                                    loadingMessage: () =>
                                        intl.formatMessage(messages.loadingCustomers),
                                    noOptionsMessage: () => intl.formatMessage(messages.noOptions),
                                    formatCreateLabel: (input) =>
                                        intl.formatMessage(messages.travelTo, {customer: input}),
                                    onCreateOption: handleKeyPress,
                                    isValidNewOption: (input) =>
                                        (!/^[0-9]+$/g.test(input) &&
                                            input.length > 2 &&
                                            !isNaN(input.slice(-2))) ||
                                        /^[0-9]+$/g.test(input),
                                }}
                            />
                        </InputGroup>
                    </PopoverBody>
                </Popover>
            </span>
        </SideItem>
    );
};

SidebarTravel.propTypes = {
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
};
const Sidebar = () => {
    const travelling = useSelector(userTravelingSelector);

    const createLink = (route) => {
        if (route.travel == null || travelling) {
            return (
                <Has key={route.path} {...route.has}>
                    <SideItem>
                        <HoverAnimation
                            className={'d-flex flex-column justify-content-center'}
                            animation={'pulse'}
                        >
                            <NavLink exact={route.exact} to={route.path} tag={NavLinkRRD}>
                                {route.icon ? 
                                <FontAwesomeIcon icon={route.icon} size={'1x'} />
                                :<></>}
                            </NavLink>
                        </HoverAnimation>
                    </SideItem>
                </Has>
            );
        }
        return null;
    };

    return (
        <div className='navbar-vertical fixed-left d-flex flex-row shadow-none'>
            <div className='sidebar main-sidebar'>
                <div className='sidenav-header d-flex align-items-center'>
                    <NavbarBrand>
                        <img
                            alt='...'
                            className='navbar-brand-img px-2 py--1'
                            src={logo}
                            style={{height: 50}}
                        />
                    </NavbarBrand>
                </div>
                <div className='sidenav-list'>
                    <Side>{R.modules.map(createLink)}</Side>
                    <Side className='f-end'>
                        <Has oneOfTheseRoles={['super administrator', 'customer service agent']}>
                            <SideItem>
                                <SidebarPbxAdmin />
                            </SideItem>
                            <SidebarTravel />
                        </Has>
                        {R.superAdministration.map(createLink)}
                        {R.administration.map(createLink)}
                    </Side>
                </div>
            </div>
            <Switch>
                <Route path='/super_admin'>
                    <AdminSidebar
                        routes={superAdministrationRoutes}
                        routePrefix={superAdministration[0].path}
                    />
                </Route>
                <Route path='/admin'>
                    <AdminSidebar
                        routes={administrationRoutes}
                        routePrefix={administration[0].path}
                    />
                </Route>
            </Switch>
        </div>
    );
};

export default Sidebar;
