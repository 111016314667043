import moment, {Moment} from 'moment';

export const enumerateDaysBetweenDates = function (startDate: Moment, endDate: Moment) {
    const dates = [];
    let date = moment(startDate);
    while (date.format('M/D/YYYY') !== endDate.format('M/D/YYYY')) {
        dates.push(moment(date));
        date = date.add(1, 'days');
    }
    return dates;
};

export const getDefaultTimeZone = () => {
    // @ts-ignore
    const defaultTimeZone = moment.defaultZone;
    return defaultTimeZone?.name ?? moment?.tz?.guess() ?? 'UTC';
};
