import React, {useEffect, useState} from 'react';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
    customerFaxBindingsSelector,
    getCustomerFaxConfigs,
} from '../../../../store/selectors/customer-selectors';
import {formatShortAmericanNumber} from '../../../../utils/format';
import {
    fetchCustomerFaxBindings,
    fetchCustomerFaxConfigs,
    fetchCustomerUsers,
} from '../../../../store/actions/customer-action';
import CardContent from '../../../../components/content/CardContent';
import {Action, ActionsCell, BooleanCell} from '../../../../components/layout/Table/Cells';
import {Table} from '../../../../components/layout/Table/Table';
import SidePanel from '../../../../components/panel/SidePanel';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import Spinner from '../../../../components/spinner/Spinner';
import LightFontAwesomeIcon from '../../../../components/utils/LightFontAwesomeIcon';
import {Has} from '../../../../components/security/Has';
import ContentWrapper from '../../../../components/content/ContentWrapper';
import FaxConfigForm from './FaxConfigForm';
import {nullSelector} from '../../../../store/selectors/generic-selectors';

const fax_configs_columns = [
    {
        id: 'did',
        title: 'Fax Number',
        cellFactory: ({did}) => formatShortAmericanNumber(did),
        width: 'auto',
        sort: {key: 'did'},
    },
    {
        id: 'enabled emails',
        title: 'Fax To Email',
        cellFactory: ({enable_emails}) => <BooleanCell enabled={enable_emails} />,
        width: '150px',
    },
    {
        id: 'did',
        title: 'Active',
        cellFactory: ({enabled}) => <BooleanCell enabled={enabled} />,
        width: '100px',
    },
];

const FaxConfigs = () => {
    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [faxConfig, setFaxConfig] = useState(null);
    const loading = useSelector(getLoading('fax_configs'));

    const configs = useSelector(getCustomerFaxConfigs);
    const bindings = useSelector(
        !!faxConfig ? customerFaxBindingsSelector(faxConfig.did) : nullSelector
    );
    const reduxDispatch = useDispatch();

    useEffect(() => {
        if (!!faxConfig) reduxDispatch(fetchCustomerFaxBindings({id: faxConfig.did}));
    }, [faxConfig, reduxDispatch]);

    useEffect(() => {
        reduxDispatch(fetchCustomerFaxConfigs());
        reduxDispatch(fetchCustomerUsers());
    }, [reduxDispatch]);

    const create = () => {
        setFaxConfig(null);
        setSidePanelOpen(true);
    };

    const cancel = () => {
        setFaxConfig(null);
        setSidePanelOpen(false);
    };

    const edit = (fax_config) => {
        setFaxConfig(fax_config);
        setSidePanelOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='table-header no-select'>
                        <h2 className='mb-0 mr-2'>Fax Configs</h2>
                        <Has oneOfTheseRoles={['super administrator', 'customer service agent']}>
                            <Button
                                className={'align-self-center mr-auto'}
                                color={'primary'}
                                onClick={create}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1 p-0'}
                    >
                        {loading ? (
                            <Spinner
                                centered
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <Table
                                itemSize={55}
                                items={configs}
                                columns={[
                                    ...fax_configs_columns,
                                    {
                                        id: 'edit',
                                        title: '',
                                        cellFactory: (config) => (
                                            <ActionsCell>
                                                <Has
                                                    aPolicyMatching={[
                                                        {action: 'WRITE', resourceType: 'FAXES'},
                                                    ]}
                                                >
                                                    <Action
                                                        onClick={() => edit(config)}
                                                        icon={'pen'}
                                                    />
                                                </Has>
                                            </ActionsCell>
                                        ),
                                        width: '50px',
                                        className: 'justify-content-end',
                                    },
                                ]}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={isSidePanelOpen}
                setOpen={setSidePanelOpen}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <FaxConfigForm
                    faxConfig={faxConfig}
                    faxBindings={bindings}
                    toggle={() => setSidePanelOpen(!isSidePanelOpen)}
                />
            </SidePanel>
        </>
    );
};

FaxConfigs.propTypes = {};

export default FaxConfigs;
