import React from 'react';
import LightFontAwesomeIcon from '../utils/LightFontAwesomeIcon';
import {FormattedMessage} from 'react-intl';
import {Badge, Button} from 'reactstrap';

const TagsSummary = ({className, summary}) => {
    return Object.keys(summary).length > 0 ? (
        <span className={className + ' d-flex flex-column'}>
            <span>
                {Object.keys(summary).map((tag) => {
                    return (
                        <Button
                            className={`cursor-default mr-1`}
                            color={'darker'}
                            key={tag}
                            onClick={(e) => e.preventDefault()}
                            outline
                            size={'sm'}
                        >
                            <LightFontAwesomeIcon icon={'tag'} className={'mr-1'} />
                            {tag}
                            <Badge className={'ml-2 mb--1 text-white'} color={'darker'}>
                                {summary[tag].total}
                            </Badge>
                        </Button>
                    );
                })}
            </span>
        </span>
    ) : (
        <small className={'text-muted'}>
            <FormattedMessage id={'label.no.tags.to.show'} defaultMessage={'No tags to show'} />
        </small>
    );
};

TagsSummary.propTypes = {};

export default TagsSummary;
