import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {getCustomerUsers} from '../../../store/selectors/customer-selectors';
import SelectInput from '../../../components/input/SelectInput';
import {Table} from '../../../components/layout/Table/Table';
import {Action, ActionsCell} from '../../../components/layout/Table/Cells';
import {formatDateForCdr} from '../../../utils/format';
import {Button} from 'reactstrap';

const team_users_columns = [
    {
        id: 'user',
        title: 'User',
        cellFactory: (teamUser) => teamUser.user.full_name,
        width: 'auto',
    },
    {
        id: 'since',
        title: 'Member since',
        cellFactory: (teamUser) => `Member since ${formatDateForCdr(teamUser.creation_time)}`,
        width: '300px',
    },
    {
        id: 'edit',
        title: '',
        cellFactory: (teamUser, {teamUsersDispatch}) => (
            <ActionsCell>
                <Action
                    icon={'times'}
                    onClick={() =>
                        teamUsersDispatch({
                            type: 'remove',
                            payload: {user: teamUser.user},
                        })
                    }
                />
            </ActionsCell>
        ),
        width: '50px',
        className: 'justify-content-end',
    },
];

const TeamUsers = ({teamUsersState, teamUsersDispatch}) => {
    const users = useSelector(getCustomerUsers);

    const [selectedUser, setSelectedUser] = useState(null);

    return (
        <div className={'d-flex flex-column'}>
            <div className={'d-flex w-100 py-2'}>
                <SelectInput
                    className={'pr-1'}
                    onValueChange={setSelectedUser}
                    options={users.map((user) => ({label: user.full_name, value: user}))}
                    value={selectedUser}
                />
                <Button
                    color={'primary'}
                    disabled={!!!selectedUser}
                    onClick={() =>
                        teamUsersDispatch({
                            type: 'add',
                            payload: {user: selectedUser},
                        })
                    }
                    size={'sm'}
                >
                    <FormattedMessage id={'btn.add'} defaultMessage={'Add'} />
                </Button>
            </div>
            <div
                style={{overflowY: 'auto', height: teamUsersState.length * 55 + 20}}
                className={'d-flex flex-column flex-grow-1 px-0'}
            >
                <Table
                    columns={team_users_columns}
                    handles={{teamUsersDispatch, users}}
                    head={false}
                    items={teamUsersState}
                    itemSize={55}
                    itemsDispatch={(items) => teamUsersDispatch({type: 'sort', payload: items})}
                />
            </div>
        </div>
    );
};

TeamUsers.propTypes = {};

export default TeamUsers;
