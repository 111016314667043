import React, {useEffect, useReducer, useState} from 'react';
import SidePanelHeader from '../../../../components/panel/SidePanelHeader';
import SidePanelContent from '../../../../components/panel/SidePanelContent';
import {FormattedMessage} from 'react-intl';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardText,
    Form,
    FormGroup,
    Label,
    Nav,
    Row,
} from 'reactstrap';
import FormSection from '../../../../components/form/FormSection';
import {faCog, faFax, faUsers} from '@fortawesome/free-solid-svg-icons';
import DangerModal from '../../../../components/notification/DangerModal';
import {useDispatch, useSelector} from 'react-redux';
import isEqual from 'lodash.isequal';
import {
    createCustomerFaxBinding,
    createCustomerFaxConfig,
    deleteCustomerFaxBinding,
    deleteCustomerFaxConfig,
    updateCustomerFaxBinding,
    updateCustomerFaxConfig,
} from '../../../../store/actions/customer-action';
import {formatShortAmericanNumber} from '../../../../utils/format';
import {getLoading} from '../../../../store/selectors/portal-selectors';
import FaxBindings from './FaxBindings';
import {customerFaxBindingsSelector} from '../../../../store/selectors/customer-selectors';
import Spinner from '../../../../components/spinner/Spinner';
import {MultiActions} from '../../../../utils/requests';
import {useListReducer} from '../../../../utils/hooks';
import TextInput from '../../../../components/input/TextInput';

const defaultOpenSections = {
    generalOpen: true,
    bindingsOpen: true,
    advancedOpen: false,
};

const defaultFaxConfigState = {
    did: '',
    enable_emails: true,
    enabled: true,
};

const defaultFaxBindingsState = [];

const FaxConfigForm = ({faxConfig, toggle}) => {
    const bindingsActionFactory = new MultiActions((binding) => binding.user, {
        c: (binding) =>
            createCustomerFaxBinding({
                user_id: binding.user,
                fax_did: faxConfigState.did,
                inbound: binding.inbound,
            }),
        u: (binding) =>
            updateCustomerFaxBinding({
                id: binding.id,
                user_id: binding.user,
                fax_did: faxConfigState.did,
                inbound: binding.inbound,
            }),
        d: (binding) =>
            deleteCustomerFaxBinding({
                id: binding.id,
                fax_did: faxConfigState.did,
            }),
    });

    const loadingBindings = useSelector(
        getLoading(`fax_bindings_${faxConfig ? faxConfig.did : ''}`, !!faxConfig)
    );

    const faxBindings = useSelector(customerFaxBindingsSelector(faxConfig ? faxConfig.did : null));

    const reduxDispatch = useDispatch();

    const [faxConfigState, faxConfigDispatch] = useReducer((prevFaxConfigState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultFaxConfigState;
            case 'edit':
                if (isEqual(prevFaxConfigState[action.payload.field], action.payload.value))
                    return prevFaxConfigState;
                const newState = {...prevFaxConfigState};
                newState[action.payload.field] = action.payload.value;
                return newState;
            default:
                return prevFaxConfigState;
        }
    }, faxConfig || defaultFaxConfigState);

    const [faxBindingsState, faxBindingsDispatch] = useListReducer(
        (binding) => binding.user,
        faxBindings || defaultFaxBindingsState
    );

    useEffect(() => {
        if (!loadingBindings && !!faxBindings) {
            faxBindingsDispatch({type: 'replace', payload: faxBindings});
        }
    }, [loadingBindings, faxBindingsDispatch, faxBindings]);

    const [deleteFaxConfigModal, setDeleteFaxConfigModal] = useState(false);
    const [showDidValidation, setShowDidValidation] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        const binding_actions = bindingsActionFactory
            .fromDiff(faxBindings || [], faxBindingsState)
            .getActions();
        if (!!faxConfig) {
            reduxDispatch(
                updateCustomerFaxConfig({
                    did: faxConfigState.did,
                    binding_actions,
                })
            );
            toggle();
        } else {
            // no empty validation
            if (faxConfigState.did.length < 1) {
                setShowDidValidation(true);
            } else {
                reduxDispatch(createCustomerFaxConfig({did: faxConfigState.did, binding_actions}));
                toggle();
            }
        }
    };

    const deleteFaxConfig = () => {
        reduxDispatch(deleteCustomerFaxConfig({did: faxConfigState.did}));
        setDeleteFaxConfigModal(false);
        toggle();
    };

    const getDidValidation = () => {
        return (
            <Alert color={'warning'} className={'m-4 text-center'}>
                <span>
                    <FormattedMessage
                        id='message.admin.fax.binding.did.validation'
                        defaultMessage={`This field is mandatory`}
                    />
                </span>
            </Alert>
        );
    };

    const editField = (name, value) => {
        faxConfigDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });
        // validation removal check
        if (name === 'did' && showDidValidation) {
            setShowDidValidation(!showDidValidation);
        }
    };

    return (
        <>
            <SidePanelHeader>
                {!!faxConfig ? formatShortAmericanNumber(faxConfig.did) : 'Create Fax Number'}
            </SidePanelHeader>
            <SidePanelContent>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={'pb-2'}>
                        {!!!faxConfig && (
                            <FormSection
                                icon={faFax}
                                title={'Fax Number'}
                                isOpenByDefault={defaultOpenSections.generalOpen}
                            >
                                <Row>
                                    <FormGroup className={'col'}>
                                        {showDidValidation ? getDidValidation() : ''}
                                        <Label className='form-control-label'>Fax Number</Label>
                                        <TextInput
                                            onValueChange={(v) => editField('did', v)}
                                            placeholder={'Fax Number'}
                                            value={faxConfigState.did}
                                        />
                                    </FormGroup>
                                </Row>
                            </FormSection>
                        )}
                        <FormSection
                            icon={faUsers}
                            isOpenByDefault={defaultOpenSections.bindingsOpen}
                            title={'User Bindings'}
                        >
                            <div className={'d-flex flex-column flex-grow-1'}>
                                {loadingBindings ? (
                                    <Spinner
                                        color={'primary'}
                                        background={'transparent'}
                                        global={false}
                                        size={50}
                                    />
                                ) : (
                                    <FaxBindings
                                        did={faxConfigState.did}
                                        faxBindingsState={faxBindingsState}
                                        faxBindingsDispatch={faxBindingsDispatch}
                                    />
                                )}
                            </div>
                        </FormSection>
                        {!!faxConfig && (
                            <FormSection
                                icon={faCog}
                                isOpenByDefault={defaultOpenSections.advancedOpen}
                                title={
                                    <FormattedMessage
                                        id='message.admin.advanced'
                                        defaultMessage='Advanced'
                                    />
                                }
                            >
                                <FormattedMessage
                                    id='message.admin.delete.fax.number'
                                    defaultMessage={`Delete fax number`}
                                >
                                    {(m) => (
                                        <Card>
                                            <CardBody>
                                                <h2 className={'text-danger'}>{m}</h2>
                                                <CardText className={'text-muted'}>
                                                    <small>
                                                        <FormattedMessage
                                                            id={
                                                                'message.admin.delete.fax.number.explanation'
                                                            }
                                                            defaultMessage={
                                                                'Deleting a fax number will disable all fax-to-email features for this fax number.'
                                                            }
                                                        />
                                                    </small>
                                                </CardText>
                                                <Button
                                                    color={'danger'}
                                                    size={'sm'}
                                                    onClick={() =>
                                                        setDeleteFaxConfigModal(
                                                            !deleteFaxConfigModal
                                                        )
                                                    }
                                                >
                                                    {m}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    )}
                                </FormattedMessage>
                                <DangerModal
                                    isOpen={deleteFaxConfigModal}
                                    toggle={() => setDeleteFaxConfigModal(!deleteFaxConfigModal)}
                                    title={
                                        <FormattedMessage
                                            id={'message.admin.delete.fax.number'}
                                            defaultMessage={'Delete fax number'}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id={'message.admin.delete.fax.number.confirmation'}
                                            defaultMessage={
                                                'Are you sure you wish to delete this fax number? This operation cannot be undone.'
                                            }
                                        />
                                    }
                                    onClick={deleteFaxConfig}
                                />
                            </FormSection>
                        )}
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
};

FaxConfigForm.propTypes = {};

export default FaxConfigForm;
