import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import TextInput from "../input/TextInput";
import {Button} from "reactstrap";

const SearchBar = ({className, fields, live, items, minimumCharacters, setFoundItems, placeholder}) => {

    const [value, setValue] = useState('');

    const search = useCallback(() => {
        setFoundItems(value.length >= minimumCharacters ? items.filter((item) => {
            return fields.some(f => item[f] != null && item[f].toString().toLowerCase().includes(value.toLowerCase()))
        }) : items)
    }, [items, minimumCharacters, setFoundItems, fields, value]);

    useEffect(() => {
        if (live) search()
    }, [live, value, minimumCharacters, search]);

    return (
        <span color={`d-flex align-items-baseline ${className}`}>
        <TextInput icon={'search'} onValueChange={setValue} placeholder={placeholder} value={value}/>
            {!live && <Button color={'primary'} size={'sm'} onClick={search}/>}
        </span>
    )
};

SearchBar.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.string)
};

export default SearchBar;