import React from 'react';

const WhatsNewChangelist = (props) => {
    return <ul className={'mr-5'}>{props.children}</ul>;
};

WhatsNewChangelist.propTypes = {
    //
};

export default WhatsNewChangelist;
