import React from 'react';
import {Card, CardBody, CardDeck, CardHeader, CardText, NavLink} from 'reactstrap';
import {Link, NavLink as NavLinkRRD} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ContentWrapper from '../../components/content/ContentWrapper';
import {Has} from '../../components/security/Has';
import administration from '../../routes/administration';
import superAdministration from '../../routes/superAdministration';
import {getUserCustomerLicense} from '../../store/selectors/user-selectors';
import {useSelector} from 'react-redux';

const Index = (props) => {
    const customerLicense = useSelector(getUserCustomerLicense);

    return (
        <ContentWrapper>
            <CardDeck>
                {props.routes.map((route, index) => (
                    <Has {...route.has} key={index}>
                        <Card>
                            <CardHeader className={'d-flex align-items-baseline'}>
                                {route.icon && (
                                    <FontAwesomeIcon
                                        className={'mr-1 text-primary'}
                                        icon={route.icon}
                                    />
                                )}
                                {route.iconSrc && (
                                    <img
                                        alt='...'
                                        className={'mr-1'}
                                        src={route.iconSrc}
                                        style={{width: 20}}
                                    />
                                )}
                                {route.title}
                            </CardHeader>
                            <CardBody>
                                {route.subroutes &&
                                    route.subroutes.map((subroute, index) => {
                                        if (!subroute.no_nav) {
                                            if (
                                                !subroute.component ||
                                                subroute?.props?.license === customerLicense
                                            ) {
                                                return (
                                                    <CardText key={index}>
                                                        <NavLink
                                                            disabled
                                                            to={`${props.match.url}${route.path}${subroute.path}`}
                                                            tag={NavLinkRRD}
                                                        >
                                                            {subroute.title}
                                                        </NavLink>
                                                    </CardText>
                                                );
                                            } else {
                                                return (
                                                    <Has key={index} {...subroute.has}>
                                                        <CardText>
                                                            <Link
                                                                to={`${props.match.url}${route.path}${subroute.path}`}
                                                            >
                                                                {subroute.title}
                                                            </Link>
                                                        </CardText>
                                                    </Has>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                            </CardBody>
                        </Card>
                    </Has>
                ))}
            </CardDeck>
        </ContentWrapper>
    );
};

export const AdminIndex = (props) => <Index routes={administration} {...props} />;
export const SuperAdminIndex = (props) => <Index routes={superAdministration} {...props} />;
