import React, {Fragment, useState} from 'react';
import {Button, Popover, PopoverBody} from 'reactstrap';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faTimes} from '@fortawesome/free-solid-svg-icons';
import LightFontAwesomeIcon from '../../utils/LightFontAwesomeIcon';
import {arrayToObjectByGrouper} from '../../../utils';
import {ConditionalWrapper} from '../../utils/ConditionalWrapper';

const FilterDisplay = ({
    className,
    icon,
    color,
    children,
    onRemove,
}: {
    className?: string | boolean;
    icon: IconProp;
    color: string;
    children: React.ReactElement;
    onRemove?: () => void;
}) => (
    <Button
        className={`d-flex flex-row align-items-center my-1 ${
            onRemove ? '' : 'cursor-default'
        } ${className}`}
        color={color}
        onClick={(e) => {
            e.preventDefault();
            onRemove && onRemove();
        }}
        outline
        size={'sm'}
    >
        <FontAwesomeIcon icon={icon} className={'mr-1'} />
        {children}
        {onRemove && <FontAwesomeIcon className={'ml-1 cursor-pointer'} icon={faTimes} />}
    </Button>
);

FilterDisplay.defaultProps = {
    icon: faFilter,
    color: 'primary',
};

type FilterDisplaysProps = {
    id?: any;
    filters: any;
    removeFilter?: (filter: string) => void;
    clearFilters?: () => void;
    scrollable?: any | undefined;
    limit?: any;
    groupAction?: any;
    className?: string;
    group?: any;
} & WrappedComponentProps;

export const FilterGroups = injectIntl(
    ({intl, filters, removeFilter, clearFilters, scrollable, groupAction}: FilterDisplaysProps) => {
        const groupedFilters: any = arrayToObjectByGrouper(filters, (f: any) => f.type);

        return (
            filters &&
            filters.length > 0 && (
                <div
                    className={`d-flex flex-row ${
                        !scrollable && 'flex-wrap'
                    } justify-content-start align-items-center mt--1 pt-1 mb-2 ml-2`}
                    style={{overflowY: 'unset', overflowX: scrollable ? 'scroll' : 'initial'}}
                >
                    {Object.keys(groupedFilters).map((filterGroup) => (
                        <ConditionalWrapper
                            key={filterGroup}
                            condition={groupedFilters[filterGroup].length > 1}
                            wrapper={(children: any) => (
                                <div
                                    className={`btn btn-sm btn-secondary d-flex flex-row ${
                                        !scrollable && 'flex-wrap'
                                    } align-items-center my-1 ${
                                        removeFilter ? 'cursor-pointer' : 'cursor-default'
                                    }`}
                                >
                                    {groupAction &&
                                        groupAction[filterGroup] &&
                                        !groupAction[filterGroup].asLink &&
                                        (groupAction[filterGroup].component || (
                                            <FontAwesomeIcon
                                                icon={groupAction[filterGroup].icon}
                                                className={'mr-2'}
                                                onClick={() => groupAction[filterGroup].onClick()}
                                            />
                                        ))}
                                    {children}
                                    {removeFilter && (
                                        <FontAwesomeIcon
                                            className={'ml-1 cursor-pointer'}
                                            icon={faTimes}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                removeFilter &&
                                                    groupedFilters[filterGroup].forEach(
                                                        removeFilter
                                                    );
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        >
                            {groupedFilters[filterGroup].map((filter: any, index: number) => (
                                <Fragment key={filter.index}>
                                    {index !== 0 &&
                                        groupAction &&
                                        groupAction[filterGroup] &&
                                        groupAction[filterGroup].asLink &&
                                        (groupAction[filterGroup].component || (
                                            <FontAwesomeIcon
                                                icon={groupAction[filterGroup].icon}
                                                className={'mr-2'}
                                                onClick={() => groupAction[filterGroup].onClick()}
                                            />
                                        ))}
                                    <FilterDisplay
                                        color={filter.display.color}
                                        icon={filter.display.icon}
                                        key={index}
                                        onRemove={
                                            removeFilter ? () => removeFilter(filter) : undefined
                                        }
                                    >
                                        {intl && filter.display.intlContent
                                            ? filter.display.intlContent(intl)
                                            : filter.display.content}
                                    </FilterDisplay>
                                </Fragment>
                            ))}
                        </ConditionalWrapper>
                    ))}
                    {clearFilters && (
                        <Button
                            className={'my-1'}
                            color={'secondary'}
                            onClick={clearFilters}
                            size='sm'
                        >
                            <FormattedMessage
                                id='btn.clear.filter'
                                defaultMessage='Clear Filters'
                            />
                        </Button>
                    )}
                </div>
            )
        );
    }
);

export const FilterDisplays = injectIntl(
    ({
        id,
        intl,
        className,
        filters,
        removeFilter,
        clearFilters,
        scrollable,
        limit,
        group,
        groupAction,
    }: FilterDisplaysProps) => {
        const visibleFilters =
            filters && filters.length > 0
                ? filters.filter((filter: any) => filter.display !== null)
                : null;

        const shownFilters =
            visibleFilters && limit >= 0 ? visibleFilters.slice(0, limit) : visibleFilters;
        const extraFilters =
            visibleFilters && limit >= 0 && visibleFilters.length > limit
                ? visibleFilters.slice(limit)
                : null;

        const [toggle, setToggle] = useState(false);

        return group ? (
            <FilterGroups
                {...{id, filters, removeFilter, clearFilters, scrollable, limit, groupAction}}
            />
        ) : (
            visibleFilters && (
                <div
                    className={`d-flex filter-bar no-select-flex flex-row ${
                        !scrollable && 'flex-wrap'
                    } justify-content-start align-items-start my--1 pt-1 ${className}`}
                    style={{overflowY: 'unset', overflowX: scrollable ? 'scroll' : 'initial'}}
                >
                    {shownFilters.map((filter: any, index: number) => {
                        return (
                            <FilterDisplay
                                color={filter.display.color}
                                icon={filter.display.icon}
                                key={index}
                                onRemove={removeFilter ? () => removeFilter(filter) : undefined}
                            >
                                {intl && filter.display.intlContent
                                    ? filter.display.intlContent(intl)
                                    : filter.display.content}
                            </FilterDisplay>
                        );
                    })}
                    {extraFilters && id && (
                        <>
                            <Button
                                id={id}
                                className={'my-1'}
                                color={'primary'}
                                outline
                                onClick={() => setToggle(!toggle)}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon
                                    icon={limit === 0 ? 'filter' : 'ellipsis-h'}
                                />
                            </Button>
                            <Popover
                                trigger={'hover'}
                                target={id}
                                toggle={() => setToggle(!toggle)}
                                isOpen={toggle}
                                placement={'top'}
                            >
                                <PopoverBody>
                                    {extraFilters.map((filter: any, index: number) => {
                                        return (
                                            <FilterDisplay
                                                className={index === 0 && 'ml-1'}
                                                color={filter.display.color}
                                                icon={filter.display.icon}
                                                key={index}
                                                onRemove={
                                                    removeFilter
                                                        ? () => removeFilter(filter)
                                                        : undefined
                                                }
                                            >
                                                {intl && filter.display.intlContent
                                                    ? filter.display.intlContent(intl)
                                                    : filter.display.content}
                                            </FilterDisplay>
                                        );
                                    })}
                                </PopoverBody>
                            </Popover>
                        </>
                    )}
                    {clearFilters && (
                        <Button
                            className={'my-1'}
                            color={'secondary'}
                            onClick={clearFilters}
                            size='sm'
                        >
                            <FormattedMessage
                                id='btn.clear.filter'
                                defaultMessage='Clear Filters'
                            />
                        </Button>
                    )}
                </div>
            )
        );
    }
);
