import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Action, ActionsCell} from '../../../components/layout/Table/Cells';
import PolicyDescriptor, {hasUnknownComponents} from './PolicyDescriptor';
import {Table} from '../../../components/layout/Table/Table';
import {useListReducer} from '../../../utils/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCustomerPolicies} from '../../../store/actions/customer-action';
import {getLoading} from '../../../store/selectors/portal-selectors';
import Spinner from '../../../components/spinner/Spinner';
import {getCustomerPolicies} from '../../../store/selectors/customer-selectors';
import {FormattedMessage} from 'react-intl';
import {Has} from '../../../components/security/Has';
import {Alert} from 'reactstrap';

const PolicyList = ({roleId, who, edit, editable}) => {
    const loading = useSelector(getLoading(`policies_${roleId}`));
    const policies = useSelector(getCustomerPolicies);

    const dispatch = useDispatch();

    const [policiesState, policiesStateDispatch] = useListReducer((policy) => policy.id, policies);

    useEffect(() => {
        roleId && dispatch(fetchCustomerPolicies({role_id: roleId}));
    }, [roleId, dispatch]);

    useEffect(() => {
        policiesStateDispatch({type: 'replace', payload: policies});
    }, [policies, policiesStateDispatch]);

    return loading ? (
        <Spinner centered color={'primary'} background={'transparent'} global={false} size={50} />
    ) : policiesState.length === 0 ? (
        <Alert color={'secondary'} className={'m-2 text-center'}>
            <FormattedMessage
                id={'message.no.policies'}
                defaultMessage={'The selected entity has no assigned policy'}
            />
        </Alert>
    ) : (
        <>
            <Table
                itemSize={55}
                columns={[
                    {
                        id: 'descriptor',
                        title: 'Policy',
                        cellFactory: (policy) => <PolicyDescriptor who={who} policy={policy} />,
                        width: 'auto',
                    },
                    {
                        id: 'edit',
                        title: '',
                        cellFactory: (policy) => (
                            <ActionsCell>
                                <Has
                                    aPolicyMatching={[{action: 'WRITE', resourceType: 'POLICIES'}]}
                                >
                                    {editable ? (
                                        <Action
                                            onClick={() => edit(policy, roleId, who)}
                                            icon={'pen'}
                                        />
                                    ) : null}
                                </Has>
                            </ActionsCell>
                        ),
                        width: '50px',
                        className: 'justify-content-end',
                    },
                ]}
                items={policiesState.filter((p) => !hasUnknownComponents(p))}
                itemsDispatch={(items) => policiesStateDispatch({type: 'sort', payload: items})}
            />
        </>
    );
};

PolicyList.propTypes = {
    policies: PropTypes.arrayOf(PropTypes.shape({})),
    editable: PropTypes.bool,
};

PolicyList.defaultProps = {
    editable: true,
};

export default PolicyList;
