import moment from "moment";
import frenchUpdates from '../config/updates/whats_new_fr'
import englishUpdates from '../config/updates/whats_new_en'

const LANGUAGE_CODE_UPDATE_FILES = {
    fr: frenchUpdates,
    en: englishUpdates
}

export const getRelevantUpdates = (lastActive, languageCode) => {
    const updates = LANGUAGE_CODE_UPDATE_FILES[languageCode]
    return updates
        .filter(update => moment(update.date).isBetween(moment(lastActive), moment(), "day", '[]'))
        .sort((update1, update2) => moment(update1).valueOf() - moment(update2).valueOf());
}