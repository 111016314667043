export const arrayToObjectByKey = (array, key) =>
    Object.assign({}, ...array.map((item) => ({[item[key]]: item})));

export const arrayToObjectByGrouper = (array, grouper) => {
    let result = {};
    array.forEach((item) => {
        const key = grouper(item);
        if (result[key] == null) {
            result[key] = [item];
        } else {
            result[key].push(item);
        }
    });
    return result;
};

export const arrayFromRange = (size, startAt = 0) =>
    [...Array(size).keys()].map((i) => i + startAt);

export const arrayToObjectByIndex = (array) =>
    Object.assign({}, ...array.map((item, index) => ({[index]: item})));

export const compare = (a, b, key) => {
    if (a[key] < b[key]) {
        return -1;
    } else if (a[key] > b[key]) {
        return 1;
    } else return 0;
};

export const sortArrayByObjKey = (array, key) => array.sort((a, b) => compare(a, b, key));

export const sortArrayByObjKeyReverted = (array, key) => array.sort((a, b) => compare(b, a, key));

// filter by alphabetical order
export const sortArray = (x, y) => {
    if (x.name < y.name) {
        return -1;
    }
    if (x.name > y.name) {
        return 1;
    }
    return 0;
};

export const exist = (obj) => !!(typeof obj != 'undefined' && obj);
export const isTrue = (obj) => !!(exist(obj) && (obj === true || obj === 1 || obj === '1'));
export const OrIfExist = (obj1, obj2) => (exist(obj1) ? obj1 : exist(obj2) ? obj2 : null);

export const timeMultiplier = (time) => time * 60;
export const timeToSec = (seconds, minutes = 0, hours = 0) =>
    seconds + timeMultiplier(minutes + timeMultiplier(hours));

export const forceDownload = (url, filename = 'file') => {
    let element = document.createElement('a');
    element.style.display = 'none';
    element.href = url;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const parseFileNameInContentDisposition = (contentDisposition) =>
    contentDisposition.substring(
        contentDisposition.indexOf('filename=') + 9,
        contentDisposition.length
    );

export const cyrb53 = function (str, seed = 0) {
    /* eslint-disable */
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export function make_id(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function make_timestamp() {
    return Date.now();
}

export function arrayDiff(
    oldArray,
    newArray,
    idComparator = (x, y) => x === y,
    contentComparator = (x, y) => x === y
) {
    let unchanged = oldArray.filter((x) =>
        newArray.some((y) => idComparator(x, y) && contentComparator(x, y))
    );
    let added = newArray.filter((x) => !oldArray.some((y) => idComparator(x, y)));
    let removed = oldArray.filter((x) => !newArray.some((y) => idComparator(x, y)));
    let edited = newArray.filter((x) =>
        oldArray.some((y) => idComparator(x, y) && !contentComparator(x, y))
    );
    return {unchanged, added, removed, edited};
}
