import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {customerExtensionsSelector} from 'store/selectors/customer-selectors';
import {Badge, Table} from 'reactstrap';
import {formatAlphanumericString, formatDuration} from 'utils/format';
import {getWidgetData} from 'store/selectors/cdr-selectors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {getLoading} from 'store/selectors/portal-selectors';
import Spinner from 'components/spinner/Spinner';
import moment from 'moment';
import {CallStatisticsByAgentParametersType} from './CallStatisticsByAgentParameters';
import {CallReportsParametersType} from '../CallsReportsParameters';
import {Link} from 'react-router-dom';
import {extension_label} from '../../../../../utils/call_history';
import {enumerateDaysBetweenDates} from '../../../../../utils/datetime';

type ElasticSearchReport = {
    by_extension?: {
        buckets: {
            key: string;
            doc_count: number;
            by_hour: {
                buckets: {
                    key_as_string: string;
                    key: number;
                    doc_count: number;
                    duration: {
                        value: number;
                    };
                }[];
            };
        }[];
    };
};

type Hour = {
    label: string;
    duration: number;
    count: number;
};

type HoursRecord = Record<string, Hour>;

type Day = {
    label: string;
    duration: number;
    count: number;
    hours: HoursRecord;
};

type DaysRecord = Record<string, Day>;

type Extension = {
    label: string;
    extension: string;
    duration: number;
    count: number;
    days: DaysRecord;
};

type ExtensionsRecord = Record<string, Extension>;

type FormattedReport = {
    summary: Day;
    extensions: ExtensionsRecord;
};

const color = (number: number) => {
    const minutes: number = Math.floor(number / 60);

    switch (true) {
        case number > 0 && minutes < 10:
            return {background: '#38E76F', text: 'inherit'};
        case minutes >= 10 && minutes < 20:
            return {background: '#B2F135', text: 'inherit'};
        case minutes >= 20 && minutes < 30:
            return {background: '#F5D733', text: 'inherit'};
        case minutes >= 30 && minutes < 40:
            return {background: '#FA6E31', text: 'white'};
        case minutes >= 40 && minutes < 50:
            return {background: '#FF2F40', text: 'white'};
        case minutes >= 50 && minutes < 60:
            return {background: '#AF4D22', text: 'white'};
        case minutes >= 60:
            return {background: '#66131A', text: 'white'};
    }
};

// call the callback to generate the header of the table hours
const headerTableCallback = (cb: () => {}) => {
    return cb();
};

const renderTableData = (extension: string, props: Day) => {
    return (
        <>
            <td>
                <Link
                    to={`/call_history?extension_${extension}=${extension}&date=${moment(
                        props.label
                    )
                        .startOf('day')
                        .format('YYYY-MM-DDTHH:mm:ss')}_${moment(props.label)
                        .endOf('day')
                        .format('YYYY-MM-DDTHH:mm:ss')}`}
                >
                    {props.label}
                </Link>
            </td>
            {Object.values(props.hours).map((call) => {
                const backgroundColor = color(call.duration) || {
                    background: undefined,
                    text: 'inherit',
                };

                return (
                    <td
                        key={formatAlphanumericString(`call-count-${call.label}`)}
                        className={'flex-row align-items-center'}
                        style={{
                            backgroundColor: backgroundColor.background,
                            color: backgroundColor.text,
                            borderLeft: 0,
                            borderRight: 0,
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        {formatDuration(call.duration)}
                    </td>
                );
            })}
            <td>{formatDuration(props.duration)}</td>
        </>
    );
};

const formatElasticSearchReport: (
    elasticSearchResult: ElasticSearchReport | null,
    parameters: CallStatisticsByAgentParametersType & CallReportsParametersType
) => {result: FormattedReport; span: [number, number]} = (
    elasticSearchResult: ElasticSearchReport | null,
    parameters: CallStatisticsByAgentParametersType & CallReportsParametersType
) => {
    const formatted: FormattedReport = {
        summary: {
            label: '',
            duration: 0,
            count: 0,
            hours: {},
        },
        extensions: {},
    };

    let earliest = 23;
    let latest = 0;

    elasticSearchResult?.by_extension?.buckets.forEach((extensionBucket) => {
        formatted.summary.count += extensionBucket.doc_count;
        const extensionData: Extension = {
            label: extension_label(extensionBucket.key),
            extension: extensionBucket.key,
            duration: 0,
            count: extensionBucket.doc_count,
            days: {},
        };
        extensionBucket.by_hour.buckets.forEach((hourBucket) => {
            const date = moment(hourBucket.key_as_string);
            const day = date.format('YYYY-MM-DD');
            const hour = date.hour();

            if (hourBucket.duration.value > 0) {
                earliest = Math.min(hour, earliest);
                latest = Math.max(hour, latest);
            }

            if (Object.keys(extensionData.days).includes(day)) {
                extensionData.days[day].count += hourBucket.doc_count;
                extensionData.days[day].duration += hourBucket.duration.value;
            } else {
                extensionData.days[day] = {
                    label: day,
                    count: hourBucket.doc_count,
                    duration: hourBucket.duration.value,
                    hours: {},
                };
            }

            extensionData.days[day].hours[hour] = {
                label: hour.toString(),
                count: hourBucket.doc_count,
                duration: hourBucket.duration.value,
            };
            extensionData.duration += hourBucket.duration.value;

            if (Object.keys(formatted.summary.hours).includes(hour.toString())) {
                formatted.summary.hours[hour.toString()].count += hourBucket.doc_count;
                formatted.summary.hours[hour.toString()].duration += hourBucket.duration.value;
            } else {
                formatted.summary.hours[hour] = {
                    label: hour.toString(),
                    count: hourBucket.doc_count,
                    duration: hourBucket.duration.value,
                };
            }
        });
        formatted.summary.count += extensionData.count;
        formatted.summary.duration += extensionData.duration;
        formatted.extensions[extensionBucket.key] = extensionData;
    });

    // ElasticSearch trims missing hours, so we must ensure
    // that the first and last days do not have missing hours.
    Object.values(formatted.extensions).forEach((extension) => {
        Object.values(extension.days).forEach((day) => {
            for (let i = 0; i < 24; i++) {
                if (!Object.keys(day.hours).includes(i.toString())) {
                    day.hours[i.toString()] = {count: 0, duration: 0, label: i.toString()};
                }
            }
        });
    });

    // Then, if we must hide empty periods, we need to remove those in a second pass.
    if (parameters.hideEmptyHours) {
        Object.values(formatted.extensions).forEach((extension) => {
            Object.keys(extension.days).forEach((day) => {
                if (extension.days[day].count === 0) {
                    delete extension.days[day];
                }
            });

            Object.values(extension.days).forEach((day) => {
                for (let i = 0; i < earliest; i++) {
                    delete day.hours[i.toString()];
                }
                for (let i = 24; i > latest; i--) {
                    delete day.hours[i.toString()];
                }
            });
        });

        for (let i = 0; i < earliest; i++) {
            delete formatted.summary.hours[i.toString()];
        }
        for (let i = 24; i > latest; i--) {
            delete formatted.summary.hours[i.toString()];
        }
    }

    // Otherwise, we add missing days for the entire period to every extension
    if (!parameters.hideEmptyHours) {
        const allDaysInPeriod = enumerateDaysBetweenDates(
            moment(parameters.date.start),
            moment(parameters.date.end).add(1, 'day')
        );
        Object.values(formatted.extensions).forEach((extension) => {
            allDaysInPeriod.forEach((day) => {
                if (!Object.keys(extension.days).includes(day.format('YYYY-MM-DD'))) {
                    extension.days[day.format('YYYY-MM-DD')] = {
                        label: day.format('YYYY-MM-DD'),
                        count: 0,
                        duration: 0,
                        hours: {},
                    };
                    for (let i = 0; i < 24; i++) {
                        if (!Object.keys(day.hours).includes(i.toString())) {
                            extension.days[day.format('YYYY-MM-DD')].hours[i.toString()] = {
                                count: 0,
                                duration: 0,
                                label: i.toString(),
                            };
                        }
                    }
                }
            });
        });
    }

    // Since ElasticSearch does not return extensions with no data, we add them manually as an empty row
    parameters.paginatedExtensions?.forEach((extension) => {
        if (!Object.keys(formatted.extensions).includes(extension)) {
            formatted.extensions[extension] = {
                label: extension_label(extension),
                extension: extension,
                duration: 0,
                count: 0,
                days: {},
            };
        }
    });

    return {result: formatted, span: parameters.hideEmptyHours ? [earliest, latest] : [0, 23]};
};

const CallsStatisticsByAgent = ({
    parameters,
}: {
    parameters: CallStatisticsByAgentParametersType & CallReportsParametersType;
}) => {
    const [arrowToggle, setArrowToggle] = useState<Array<{extension: string; toggle: Boolean}>>([]);

    const extensions = useSelector(customerExtensionsSelector);
    const loading = useSelector(getLoading('widget_calls_statistics_by_agent'));
    const {
        calls_statistics_by_agent: callStatisticsByAgent,
    }: {calls_statistics_by_agent: ElasticSearchReport} = useSelector(getWidgetData);

    const formattedReport = formatElasticSearchReport(callStatisticsByAgent, parameters);

    const toggleArrow = (extension: string) => {
        const findExtension: {extension: string; toggle: Boolean} | undefined = arrowToggle?.find(
            (element: {extension: string; toggle: Boolean}) => element.extension === extension
        );

        if (findExtension === undefined) {
            setArrowToggle([...arrowToggle, {extension, toggle: true}]);
        } else {
            const updateToggle: {extension: string; toggle: Boolean}[] = [...arrowToggle];
            const findIndexExtension = arrowToggle.findIndex(
                (elem: {extension: string; toggle: Boolean}) => elem.extension === extension
            );

            updateToggle[findIndexExtension].toggle = !findExtension.toggle;
            setArrowToggle(updateToggle);
        }
    };

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return loading ? (
        <Spinner
            color={'primary'}
            background={'spinner-calls-statistics'}
            global={false}
            size={50}
        />
    ) : formattedReport.result.summary.duration === 0 ? (
        <p className='text-center'>
            <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
        </p>
    ) : (
        <Table responsive striped bordered className={'table-flush align-items-center'}>
            <thead className={'thead-light no-select sticky-top'}>
                {/*@ts-ignore*/}
                <tr className={'sticky-top'}>
                    <th className={'sticky-top'}>Hour</th>
                    <>
                        {headerTableCallback((): JSX.Element[] => {
                            const header = [];
                            for (
                                let i = formattedReport.span[0];
                                i <= formattedReport.span[1];
                                i++
                            ) {
                                header.push(
                                    <th className={'sticky-top'} key={i}>
                                        {i}
                                    </th>
                                );
                            }
                            return header;
                        })}
                    </>
                    <th className={'sticky-top'}>Total</th>
                </tr>
                <tr className={'sticky-top'}>
                    <th className={'sticky-top'}>
                        <Link
                            to={`/call_history?date=${moment(parameters.date.start)
                                .startOf('day')
                                .format('YYYY-MM-DDTHH:mm:ss')}_${moment(parameters.date.end)
                                .endOf('day')
                                .format('YYYY-MM-DDTHH:mm:ss')}`}
                        >
                            <FormattedMessage id={'message.total'} defaultMessage={'Total'} />
                        </Link>
                    </th>
                    {Object.values(formattedReport.result.summary.hours).map((call) => {
                        return (
                            <th
                                key={formatAlphanumericString(`call-count-${call.label}`)}
                                style={{
                                    borderLeft: 0,
                                    borderRight: 0,
                                    paddingLeft: '0.5rem',
                                    paddingRight: '0.5rem',
                                }}
                            >
                                {formatDuration(call.duration)}
                            </th>
                        );
                    })}
                    <th>{formatDuration(formattedReport.result.summary.duration)}</th>
                </tr>
            </thead>
            <tbody className={'table-body'}>
                {Object.values(formattedReport.result.extensions).map((extension) => {
                    const extensionArrow = arrowToggle?.find(
                        (element: {extension: string; toggle: Boolean}) =>
                            element.extension === extension.label
                    );

                    return (
                        <React.Fragment key={extension.label}>
                            <tr
                                className={extension.count > 0 ? `cursor-pointer` : 'text-muted'}
                                key={extension.label}
                                onClick={() => toggleArrow(extension.label)}
                            >
                                <td colSpan={formattedReport.span[1] - formattedReport.span[0] + 3}>
                                    <FontAwesomeIcon
                                        style={{opacity: extension.count > 0 ? 100 : 0}}
                                        icon={extensionArrow?.toggle ? faChevronUp : faChevronDown}
                                        className={'mr-2'}
                                    />
                                    {extension_label(extension.label, extensions)}&nbsp;
                                    <Badge>{formatDuration(extension.duration)}</Badge>
                                </td>
                            </tr>
                            {extensionArrow?.toggle &&
                                Object.keys(extension.days)
                                    .sort()
                                    .map((day) => (
                                        <tr key={extension.days[day].label}>
                                            {renderTableData(
                                                extension.extension,
                                                extension.days[day]
                                            )}
                                        </tr>
                                    ))}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default CallsStatisticsByAgent;
