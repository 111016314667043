import React, {useEffect, useState} from 'react';
import {Button, Form, FormGroup, Label} from 'reactstrap';
import {defineMessages, FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {DateRangeInput} from 'components/input/DateInput';
import {useQueryStringObjectReducer} from 'utils/hooks';
import moment from 'moment';
import {getCustomerExtensions, getCustomerTeams} from 'store/selectors/customer-selectors';
import {useDispatch, useSelector} from 'react-redux';
import SelectInput from 'components/input/SelectInput';
import {getLoading} from 'store/selectors/portal-selectors';
import {fetchCustomerTeams} from 'store/actions/customer-action';
import isEqual from 'lodash.isequal';
import {extension_label} from '../../../../utils/call_history';

const messages = defineMessages({
    allExtensions: {
        id: 'placeholder.all.extensions',
        defaultMessage: 'All extensions',
    },
    allTeams: {
        id: 'placeholder.all.teams',
        defaultMessage: 'All teams',
    },
    dates: {
        id: 'message.dates',
        defaultMessage: 'Dates',
    },
    extensions: {
        id: 'message.extensions',
        defaultMessage: 'Extensions',
    },
    teams: {
        id: 'message.teams',
        defaultMessage: 'Teams',
    },
});

export interface CallReportsParametersType {
    date: {
        start: string;
        end: string;
    };
    extensions: string[];
    teams: number[];
}

interface CallsReportsParametersProps extends WrappedComponentProps {
    onChange: (p: CallReportsParametersType) => void;
    className?: string;
}

const DATE_FORMAT = 'YYYY-MM-DD';
const defaultCalleReportsParameters: CallReportsParametersType = {
    date: {
        start: moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD'),
        end: moment().startOf('day').format('YYYY-MM-DD'),
    },
    extensions: [],
    teams: [],
};

const CallReportsParameters = ({className, onChange, intl}: CallsReportsParametersProps) => {
    const extensions = useSelector(getCustomerExtensions);
    const teams = useSelector(getCustomerTeams);
    const loadingAllCalls = useSelector(getLoading('widget_calls_statistics_all'));
    const loadingCallsByAgent = useSelector(getLoading('widget_calls_statistics_by_agent'));

    const reduxDispatch = useDispatch();
    const [currentExtensionsSelected, setCurrentExtensionsSelected] = useState<string[]>([]);

    const [parameters, dispatch] = useQueryStringObjectReducer<CallReportsParametersType>(
        defaultCalleReportsParameters,
        {
            to: (s: string) => {
                const query = new URLSearchParams(s);
                return {
                    date: {
                        start: query.get('start') || defaultCalleReportsParameters.date.start,
                        end: query.get('end') || defaultCalleReportsParameters.date.end,
                    },
                    extensions: query.get('extensions')
                        ? query.get('extensions')?.split(',') || []
                        : [],
                    teams:
                        query
                            .get('teams')
                            ?.split(',')
                            .map((v) => Number(v))
                            .filter((v) => v !== 0) || [],
                };
            },

            from: (p: CallReportsParametersType) => {
                const query = new URLSearchParams();
                query.set('start', p.date.start);
                query.set('end', p.date.end);
                query.set('extensions', p.extensions.join(','));
                query.set('teams', p.teams.join(','));
                return query.toString();
            },
        }
    );

    const findExtensionsByTeams = (teamsId: number[]) => {
        let childTeam: number[] = [];

        if (teamsId.length !== 0 || currentExtensionsSelected.length !== 0) {
            let currentExtensions: string[] = [...currentExtensionsSelected];

            for (const teamId of teamsId) {
                const {children, team_users} = teams.find((team: any) => team.id === teamId);

                team_users.forEach((user: any) =>
                    currentExtensions.push(user.user.extension.toString())
                );

                const filterCurrentExtensions = currentExtensions.filter(
                    (item, index) => currentExtensions.indexOf(item) === index
                );

                parameters.extensions.push(...filterCurrentExtensions);
                childTeam.push(...children);
            }
            if (childTeam.length !== 0) findExtensionsByTeams(childTeam);
            if (currentExtensionsSelected.length !== 0) {
                const filterExtensions = currentExtensions.filter(
                    (item, index) => currentExtensions.indexOf(item) === index
                );
                parameters.extensions.push(...filterExtensions);
            }
        } else parameters.extensions = [];

        parameters.extensions = Array.from(new Set(parameters.extensions));
    };

    useEffect(() => {
        onChange(parameters);
        if (parameters.extensions.length === 0) {
            parameters.teams = [];
        }
    }, [onChange, parameters]);

    useEffect(() => {
        reduxDispatch(fetchCustomerTeams());
    }, [reduxDispatch]);

    return (
        <Form inline className={`d-flex flex-column flex-xl-row align-items-stretch ${className}`}>
            <FormGroup check inline className={`mb-3 mb-xl-0`}>
                <Label className='mr-3'>{intl.formatMessage(messages.dates)}</Label>
                <DateRangeInput
                    value={[parameters.date.start, parameters.date.end]}
                    enabled={!(loadingAllCalls || loadingCallsByAgent)}
                    onValueChange={(value: any) => {
                        dispatch({
                            type: 'edit',
                            payload: {
                                field: 'date',
                                value: {start: value[0], end: value[1]},
                            },
                        });
                    }}
                    dateFormat={DATE_FORMAT}
                    limit={31}
                />
            </FormGroup>
            <FormGroup check inline className={`mb-3 mb-xl-0 flex-grow-1`}>
                <Label className='mr-3'>{intl.formatMessage(messages.teams)}</Label>
                <SelectInput
                    className='mr-2 mw-50'
                    value={parameters.teams}
                    enabled={!(loadingAllCalls || loadingCallsByAgent)}
                    onValueChange={(value: any) => {
                        dispatch({type: 'edit', payload: {field: 'teams', value}});
                        parameters.extensions = [];
                        findExtensionsByTeams(value);
                    }}
                    options={teams.map((team: any) => ({value: team.id, label: team.name}))}
                    multi
                    isSearchable
                    placeholder={intl.formatMessage(messages.allTeams)}
                />
            </FormGroup>
            <FormGroup check inline row className={`flex-grow-1`}>
                <Label className='mr-3'>{intl.formatMessage(messages.extensions)}</Label>
                <SelectInput
                    className='mr-2 mw-50'
                    value={parameters.extensions}
                    enabled={!(loadingAllCalls || loadingCallsByAgent)}
                    onValueChange={(value: any) => {
                        setCurrentExtensionsSelected(value);
                        dispatch({type: 'edit', payload: {field: 'extensions', value}});
                    }}
                    options={Object.values(extensions).map((e: any) => ({
                        value: e.extension,
                        label: extension_label(e.extension, Object.values(extensions)),
                    }))}
                    multi
                    isSearchable
                    placeholder={intl.formatMessage(messages.allExtensions)}
                />
            </FormGroup>
            {!isEqual(defaultCalleReportsParameters, parameters) && (
                <FormGroup className={'mt-3 mt-xl-0 ml-xl-2'} check inline>
                    <Button
                        className={'mr-1'}
                        color={'secondary'}
                        onClick={() =>
                            dispatch({type: 'replace', payload: defaultCalleReportsParameters})
                        }
                        size='sm'
                    >
                        <FormattedMessage id='btn.clear.filter' defaultMessage='Clear Filters' />
                    </Button>
                </FormGroup>
            )}
        </Form>
    );
};
export default injectIntl(CallReportsParameters);
