import {Redirect} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {faPhone, faRocket, faUsers} from '@fortawesome/free-solid-svg-icons';
// import {faFileInvoice} from '@fortawesome/free-solid-svg-icons';

import Users from '../views/admin/users/Users';
import IntegrationsManager from '../views/admin/integrations/IntegrationsManager';
import ApiKeys from '../views/admin/integrations/ApiKeys/ApiKeys';
import IntegrationRoute from '../views/admin/integrations/IntegrationRoute';
import Extensions from '../views/admin/phone-system/extensions/Extensions';
import FaxConfigs from '../views/admin/phone_system/fax/FaxConfigs';
import SmsConfigs from '../views/admin/phone_system/sms/SmsConfigs';
import PhoneNumbersConfigs from '../views/admin/phone_system/phone-numbers/PhoneNumbersConfigs';
// import PowerBroker from '../views/admin/integrations/PowerBroker';
import Teams from '../views/admin/teams/Teams';
import Policies from '../views/admin/permissions/Policies';
import DeprecatedZohoIntegrationRoute from '../views/admin/integrations/overrides/DeprecatedZohoIntegrationRoute';
// import CDRBilling from '../views/admin/billing/CDRBilling';

const administration = [
    {
        path: '/crew',
        name: 'crew',
        title: <FormattedMessage id={'navbar.admin.my.crew'} defaultMessage={'My Crew'} />,
        collapse: true,
        icon: faUsers,
        has: {
            aPolicyMatching: [
                {resourceType: 'USERS'},
                {resourceType: 'TEAMS'},
                {resourceType: 'POLICIES'},
            ],
        },
        subroutes: [
            {
                path: '/users',
                title: (
                    <FormattedMessage id={'navbar.admin.users.members'} defaultMessage={'Users'} />
                ),
                name: 'crew-users',
                component: Users,
                has: {
                    aPolicyMatching: [{resourceType: 'USERS'}],
                },
            },
            {
                path: '/teams',
                title: <FormattedMessage id={'navbar.admin.teams'} defaultMessage={'Teams'} />,
                name: 'crew-teams',
                component: Teams,
                has: {
                    aPolicyMatching: [{resourceType: 'TEAMS'}],
                },
            },
            {
                path: '/policies',
                title: (
                    <FormattedMessage id={'navbar.admin.policies'} defaultMessage={'Policies'} />
                ),
                name: 'crew-policies',
                component: Policies,
                has: {
                    aPolicyMatching: [{resourceType: 'POLICIES'}],
                },
            },
        ],
    },
    {
        path: '/croo_talk',
        name: 'croo_talk',
        title: <FormattedMessage id={'navbar.admin.phone.system'} defaultMessage={'Croo Talk'} />,
        collapse: true,
        icon: faPhone,
        has: {
            oneOfTheseRoles: ['super administrator', 'customer service agent', 'administrator'],
            aPolicyMatching: [{resourceType: 'FAXES'}, {resourceType: 'SMS'}],
        },
        subroutes: [
            {
                path: '/extensions',
                title: (
                    <FormattedMessage
                        id={'navbar.admin.extensions'}
                        defaultMessage={'Extensions'}
                    />
                ),
                name: 'extensions',
                component: Extensions,
                has: {
                    oneOfTheseRoles: [
                        'super administrator',
                        'customer service agent',
                        'administrator',
                    ],
                },
            },
            {
                path: '/fax',
                title: <FormattedMessage id={'navbar.admin.fax'} defaultMessage={'Fax'} />,
                name: 'fax',
                component: FaxConfigs,
                has: {
                    aPolicyMatching: [{resourceType: 'FAXES'}],
                },
            },
            {
                path: '/sms',
                title: <FormattedMessage id={'navbar.admin.sms'} defaultMessage={'SMS'} />,
                name: 'sms',
                component: SmsConfigs,
                has: {
                    aPolicyMatching: [{resourceType: 'SMS'}],
                },
            },
            {
                path: '/phone_numbers',
                name: 'Phone Numbers',
                title: (
                    <FormattedMessage
                        id={'resource.name.phone_numbers'}
                        defaultMessage={'Phone Numbers'}
                    />
                ),
                component: PhoneNumbersConfigs,
                has: {
                    oneOfTheseRoles: [
                        'super administrator',
                        'customer service agent',
                        'administrator',
                    ],
                },
            },
            // {
            //     path: '/polycom',
            //     title: <FormattedMessage id={'navbar.admin.polycom'} defaultMessage={'Polycom'} />,
            //     name: 'polycom',
            //     component: PolycomProvisioning,
            //     has: {
            //         aPolicyMatching: [{resourceType: 'EXTENSIONS'}],
            //     },
            // },
        ],
    },
    {
        path: '/integrations',
        name: 'integrations',
        title: (
            <FormattedMessage id={'navbar.admin.integrations'} defaultMessage={'Integrations'} />
        ),
        collapse: true,
        icon: faRocket,
        has: {
            aPolicyMatching: [{resourceType: 'INTEGRATIONS'}, {resourceType: 'API_KEYS'}],
        },
        subroutes: [
            {
                path: '/manager',
                title: (
                    <FormattedMessage
                        id={'navbar.admin.my.integrations'}
                        defaultMessage={'My Integrations'}
                    />
                ),
                name: 'integration-manager',
                component: IntegrationsManager,
                no_nav: true,
                has: {
                    oneOfTheseRoles: ['super administrator', 'customer service agent'],
                },
            },
            {
                path: '/api',
                title: <FormattedMessage id={'navbar.admin.api'} defaultMessage={'API'} />,
                name: 'api',
                component: ApiKeys,
                has: {
                    aPolicyMatching: [{resourceType: 'API_KEYS'}],
                },
                props: {
                    license: 'PRO',
                },
            },
            {
                path: '/epic',
                title: 'Epic',
                name: 'epic',
                has: {
                    aPolicyMatching: [{resourceType: 'INTEGRATIONS'}],
                    theFeature: 'cfi',
                },
                props: {
                    title: 'Epic',
                    license: 'PRO',
                },
            },
            {
                path: '/hubspot',
                title: 'Hubspot',
                name: 'hubspot',
                component: IntegrationRoute,
                props: {
                    title: 'Hubspot',
                    name: 'hubspot',
                    license: 'PRO',
                },
                has: {
                    oneOfTheseRoles: ['super administrator', 'customer service agent'],
                },
            },
            {
                path: '/zoho_crm',
                title: 'Zoho CRM',
                name: 'zoho_crm',
                component: DeprecatedZohoIntegrationRoute,
                has: {
                    aPolicyMatching: [{resourceType: 'INTEGRATIONS'}],
                },
                props: {
                    title: 'Zoho CRM',
                    name: 'zoho_crm',
                    license: 'PRO',
                },
            },
            {
                path: '/zohodesk',
                title: 'Zoho Desk',
                name: 'zohodesk',
                component: () => <Redirect to={'/admin/integrations/zohodesk'} />,
                has: {
                    aPolicyMatching: [{resourceType: 'INTEGRATIONS'}],
                },
                props: {
                    license: 'PRO',
                },
                no_nav: true,
            },
            {
                path: '/zohodesk',
                title: 'Zoho Desk',
                name: 'zohodesk',
                component: DeprecatedZohoIntegrationRoute,
                has: {
                    aPolicyMatching: [{resourceType: 'INTEGRATIONS'}],
                },
                props: {
                    title: 'Zoho Desk',
                    name: 'zohodesk',
                    license: 'PRO',
                },
            },
            {
                path: '/zoho_one',
                title: 'Zoho PhoneBridge',
                name: 'zoho_one',
                component: IntegrationRoute,
                has: {
                    aPolicyMatching: [{resourceType: 'INTEGRATIONS'}],
                },
                props: {
                    title: 'Zoho PhoneBridge',
                    name: 'zoho_one',
                    license: 'PRO',
                },
            },
            // {
            //     path: '/power_broker',
            //     title: 'Power Broker',
            //     name: 'power_broker',
            //     component: PowerBroker,
            //     has: {
            //         aPolicyMatching: [{resourceType: 'INTEGRATIONS'}],
            //     },
            //     props: {
            //         title: 'Power Broker',
            //         name: 'power_broker',
            //         license: 'PRO',
            //     },
            // },
        ],
    },
    // {
    //     path: '/billings',
    //     name: 'Billings',
    //     title: <FormattedMessage id={'navbar.admin.billing'} defaultMessage={'Billing'} />,
    //     collapse: true,
    //     icon: faFileInvoice,
    //     subroutes: [
    //         {
    //             path: '/cdr',
    //             title: 'Calls',
    //             name: 'cdr',
    //             component: CDRBilling,
    //         },
    //     ],
    // },
];
export default administration;
