import React from 'react';
import {injectIntl} from 'react-intl';
import {actionMessages, resourceTypeMessages, scopeMessages} from './policyMessages';
import {useSelector} from 'react-redux';
import {
    getCustomerAgents,
    getCustomerExtensions,
    getCustomerQueues,
    getCustomers,
    getCustomerTeams,
    getCustomerUsers,
} from '../../../store/selectors/customer-selectors';
import get from 'lodash.get';
import Spinner from '../../../components/spinner/Spinner';
import {Has} from '../../../components/security/Has';

export const hasUnknownComponents = (policy) => {
    return (
        get(scopeMessages, policy.scope.type.name, null) == null ||
        get(actionMessages, policy.action.name, null) == null ||
        get(resourceTypeMessages, policy.resource_type_.name, null) == null
    );
};

// noinspection JSUnusedGlobalSymbols
const scopeTypeValueFormatters = {
    SPECIFIC_USERS: ({values}) => {
        const users = useSelector(getCustomerUsers);

        return (
            <Has
                loaded={['users']}
                orElse={
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={20}
                    />
                }
            >
                <b>
                    {values
                        .map((v) => users.find((u) => u.id === v))
                        .filter((u) => !!u)
                        .map((u) => u.full_name || u.email)
                        .join(', ')}
                </b>
            </Has>
        );
    },
    SPECIFIC_TEAMS: ({values}) => {
        const teams = useSelector(getCustomerTeams);

        return (
            <Has
                loaded={['teams']}
                orElse={
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={20}
                    />
                }
            >
                <b>
                    {values
                        .map((v) => teams.find((t) => t.id === v))
                        .filter((t) => !!t)
                        .map((t) => t.name)
                        .join(', ')}
                </b>
            </Has>
        );
    },
    SPECIFIC_CUSTOMERS: ({values}) => {
        const customers = useSelector(getCustomers);

        return (
            <Has
                loaded={['customers']}
                orElse={
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={20}
                    />
                }
            >
                <b>
                    {values
                        .map((v) => customers.find((c) => c.scopeable_id === v))
                        .filter((c) => !!c)
                        .map((c) => (c.name ? `${c.name} - (${c.id})` : c.id))
                        .join(', ')}
                </b>
            </Has>
        );
    },
    SPECIFIC_QUEUES: ({values}) => {
        const queues = useSelector(getCustomerQueues);

        return (
            <Has
                loaded={['queues']}
                orElse={
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={20}
                    />
                }
            >
                <b>
                    {values
                        .map((v) => queues.find((q) => q.id === v))
                        .filter((q) => !!q)
                        .map((q) => (q.descr ? `${q.extension} - ${q.descr}` : q.extension))
                        .join(', ')}
                </b>
            </Has>
        );
    },
    SPECIFIC_QUEUE_AGENTS: ({values}) => {
        const agents = useSelector(getCustomerAgents);

        return (
            <Has
                loaded={['agents']}
                orElse={
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={20}
                    />
                }
            >
                <b>
                    {values
                        .map((v) => agents.find((a) => a.id === v))
                        .filter((a) => !!a)
                        .map((a) => a.agent)
                        .join(', ')}
                </b>
            </Has>
        );
    },
    SPECIFIC_EXTENSIONS: ({values}) => {
        const extensions = useSelector(getCustomerExtensions);

        return (
            <Has
                loaded={['extensions']}
                orElse={
                    <Spinner
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={20}
                    />
                }
            >
                <b>
                    {values
                        .map((v) => get(extensions, v))
                        .filter((e) => !!e)
                        .map((e) => (e.name ? `${e.extension} - ${e.name}` : e.extension))
                        .join(', ')}
                </b>
            </Has>
        );
    },
};

const ScopeDescriptor = injectIntl(({scope, intl}) => {
    const ValuesComponent = get(scopeTypeValueFormatters, scope.type.name);

    return (
        <>
            {intl.formatMessage(get(scopeMessages, scope.type.name, scopeMessages.UNKNOWN))}{' '}
            {ValuesComponent && <ValuesComponent values={scope.values} />}
        </>
    );
});

const ResourceTypeDescriptor = injectIntl(({resourceType, intl}) => {
    return intl.formatMessage(
        get(resourceTypeMessages, resourceType.name, resourceTypeMessages.UNKNOWN)
    );
});

const ActionDescriptor = injectIntl(({action, intl}) => {
    return intl.formatMessage(get(actionMessages, action.name, actionMessages.UNKNOWN));
});

const PolicyDescriptor = ({policy, who}) => {
    return (
        <span>
            {who} <ActionDescriptor action={policy.action} />{' '}
            <ResourceTypeDescriptor resourceType={policy.resource_type_} />{' '}
            <ScopeDescriptor scope={policy.scope} />{' '}
        </span>
    );
};

PolicyDescriptor.propTypes = {};

export default PolicyDescriptor;
