import {formatSecondsToHour} from '../../utils/format';

import React from 'react';
import moment from 'moment';
import {
    CallHistoryActionsCell,
    NameCell,
    FromNumberCell,
    ToNumberCell,
    StatusCell,
    ParticipantHistoryCell,
} from './NewCallsTableCells';
import {FormattedMessage} from 'react-intl';
import NewCallTimestampCell from './newCallFilters/NewCallTimestampCell';
export const newColumns = [
    {
        id: 'status',
        title: '',
        cellFactory: (call, handles) => (
            <StatusCell
                call={call}
                statusFilterDispatch={handles.statusFilterDispatch}
                directionFilterDispatch={handles.directionFilterDispatch}
            />
        ),
        width: '30px',
    },
    {
        id: 'timestamp',
        title: <FormattedMessage id={'filter.title.date'} defaultMessage={'Date'} />,
        cellFactory: (call) => <NewCallTimestampCell timestamp={call.timestamp} />,
        sort: {
            comparator: (call1, call2) =>
                moment(call1.timestamp).toDate() - moment(call2.timestamp).toDate(),
        },
        width: 'minmax(150px, 250px)',
    },
    {
        id: 'name',
        title: <FormattedMessage id={'filter.title.name'} defaultMessage={'Name'} />,
        cellFactory: (call, handles) => (
            <NameCell call={call} nameFilterDispatch={handles.nameFilterDispatch} />
        ),
        // sort: {key: 'name'},
        width: 'minmax(150px, 230px)',
    },
    {
        id: 'from_number',
        title: <FormattedMessage id={'filter.title.from.number'} defaultMessage={'From number'} />,
        cellFactory: (call, handles) => (
            <FromNumberCell
                call={call}
                fromNumberFilterDispatch={handles.fromNumberFilterDispatch}
            />
        ),
        //sort: {key: 'from_number'},
        width: 'minmax(140px, 250px)',
    },
    {
        id: 'to_number',
        title: <FormattedMessage id={'filter.title.to.number'} defaultMessage={'To number'} />,
        cellFactory: (call, handles) => (
            <ToNumberCell call={call} toNumberFilterDispatch={handles.toNumberFilterDispatch} />
        ),
        //sort: {key: 'to_number'},
        width: 'minmax(140px, 250px)',
    },
    {
        id: 'duration',
        title: <FormattedMessage id={'filter.title.duration'} defaultMessage={'Duration'} />,
        cellFactory: (call) => formatSecondsToHour(call.duration),
        sort: {key: 'duration'},
        width: 'minmax(85px, 95px)',
    },
    {
        id: 'queue_time',
        title: <FormattedMessage id={'tabs.reports.hold.times'} defaultMessage={'Hold Time'} />,
        cellFactory: (call) => formatSecondsToHour(call.queue_time),
        // sort: {key: 'queue_time'},
        width: 'minmax(85px, 105px)',
    },
    {
        id: 'participant_history',
        cellFactory: (call) => (
            <ParticipantHistoryCell callId={call.id} call={call} isNewHistory={true} />
        ),
        width: '50px',
    },
    {
        id: 'recording',
        cellFactory: (call) => <CallHistoryActionsCell call={call} />,
        width: '80px',
        className: 'justify-content-end',
    },
];
