import {
    CLEAR_CALLS,
    CLEAR_WIDGET_DATA,
    DELETE_CALL_RECORDING,
    DELETE_NEW_CALL_RECORDING,
    FETCHED_CALLS,
    FETCHED_NEW_CALLS,
    FETCHING_CALLS,
    FETCHING_NEW_CALLS,
    STORE_CALL_DIRECTIONS,
    STORE_CALL_HISTORY,
    STORE_NEW_CALL_HISTORY,
    STORE_CALL_RECORDING,
    STORE_NEW_CALL_RECORDING,
    STORE_CALL_STATISTICS,
    STORE_NEW_CALL_STATISTICS,
    STORE_CALL_STATUSES,
    STORE_NEW_CALL_STATUSES,
    STORE_CALL_TAGS,
    STORE_LIVE_CALLS,
    STORE_UNFILTERED_CALL_HISTORY,
    STORE_UNFILTERED_NEW_CALL_HISTORY,
    STORE_CONTINUATION_TOKEN,
    STORE_WIDGET_DATA,
    STORE_CALL_PARTICIPANTS,
    STORE_WIDGET_HITS,
} from '../actions/cdr-action';
import {combineReducers} from 'redux';
import {arrayToObjectByKey} from '../../utils';

export function callHistoryReducer(state = {}, action) {
    switch (action.type) {
        case STORE_CALL_HISTORY:
            let obj = arrayToObjectByKey(action.payload.data, 'call_id');
            return action.payload.behaviour === 'replace' ? {...obj} : {...state, ...obj};
        case CLEAR_CALLS:
            return {};
        default:
            return state;
    }
}
export function newCallHistoryReducer(state = {}, action) {
    switch (action.type) {
        case STORE_NEW_CALL_HISTORY:
            let newObj = arrayToObjectByKey(action.payload.data, 'cdr_id');
            return action.payload.behaviour === 'replace' ? {...newObj} : {...state, ...newObj};
        case CLEAR_CALLS:
            return {};
        default:
            return state;
    }
}

export function unfilteredCallHistoryReducer(state = {}, action) {
    switch (action.type) {
        case STORE_UNFILTERED_CALL_HISTORY:
            let obj = arrayToObjectByKey(action.payload.data, 'call_id');
            return action.payload.behaviour === 'replace' ? {...obj} : {...state, ...obj};
        case CLEAR_CALLS:
            return {};
        default:
            return state;
    }
}
export function newUnfilteredCallHistoryReducer(state = {}, action) {
    switch (action.type) {
        case STORE_UNFILTERED_NEW_CALL_HISTORY:
            let obj = arrayToObjectByKey(action.payload.data, 'call_id');
            return action.payload.behaviour === 'replace' ? {...obj} : {...state, ...obj};
        case CLEAR_CALLS:
            return {};
        default:
            return state;
    }
}
export function continuationTokenNewCallHistoryReducer(state = {}, action) {
    switch (action.type) {
        case STORE_CONTINUATION_TOKEN:
            var token = '';
            action.payload.data.map((x) => {
                token += x;
                return token;
            });
            let obj = {continuationToken: token};
            return action.payload.behaviour === 'replace' ? {...obj} : {...state, ...obj};
        case CLEAR_CALLS:
            return {};
        default:
            return state;
    }
}

export function callsLoadingReducer(state = false, action) {
    switch (action.type) {
        case FETCHING_CALLS:
            return true;
        case FETCHED_CALLS:
            return false;
        case CLEAR_CALLS:
            return false;
        default:
            return state;
    }
}
export function newCallsLoadingReducer(state = false, action) {
    switch (action.type) {
        case FETCHING_NEW_CALLS:
            return true;
        case FETCHED_NEW_CALLS:
            return false;
        case CLEAR_CALLS:
            return false;
        default:
            return state;
    }
}

export function liveCallsReducer(state = {}, action) {
    switch (action.type) {
        case STORE_LIVE_CALLS:
            let obj = arrayToObjectByKey(action.payload.data, 'call_id');
            return action.payload.behaviour === 'replace' ? {...obj} : {...state, ...obj};
        case CLEAR_CALLS:
            return {};
        default:
            return state;
    }
}

export function callRecordingsReducer(state = {}, action) {
    switch (action.type) {
        case STORE_CALL_RECORDING:
            return {[action.payload.id]: action.payload.url, ...state};
        case DELETE_CALL_RECORDING:
            window.URL.revokeObjectURL(state[action.payload]);
            delete state[action.payload];
            return {...state};
        default:
            return state;
    }
}
export function newCallRecordingsReducer(state = {}, action) {
    switch (action.type) {
        case STORE_NEW_CALL_RECORDING:
            return {[action.payload]: action.payload.url, ...state};
        case DELETE_NEW_CALL_RECORDING:
            window.URL.revokeObjectURL(state[action.payload]);
            delete state[action.payload];
            return {...state};
        default:
            return state;
    }
}

export function callsTagsReducer(state = [], action) {
    switch (action.type) {
        case STORE_CALL_TAGS:
            return [...new Set([...state, ...action.payload.data])];
        case CLEAR_CALLS:
            return [];
        default:
            return state;
    }
}

export function callsStatusReducer(state = [], action) {
    switch (action.type) {
        case STORE_CALL_STATUSES:
            return [...new Set([...state, ...action.payload.data])];
        case CLEAR_CALLS:
            return [];
        default:
            return state;
    }
}

export function newCallsStatusReducer(state = [], action) {
    switch (action.type) {
        case STORE_NEW_CALL_STATUSES:
            return [...new Set([...state, ...action.payload.data])];
        case CLEAR_CALLS:
            return [];
        default:
            return state;
    }
}

export function callsDirectionsReducer(state = [], action) {
    switch (action.type) {
        case STORE_CALL_DIRECTIONS:
            return [...new Set([...state, ...action.payload.data])];
        case CLEAR_CALLS:
            return [];
        default:
            return state;
    }
}

export function callsStatisticsReducer(state = [], action) {
    switch (action.type) {
        case STORE_CALL_STATISTICS:
            return action.payload.data;
        case STORE_NEW_CALL_STATISTICS:
            return action.payload.data;
        case CLEAR_CALLS:
            return [];
        default:
            return state;
    }
}

export function widgetDataStoreReducer(state = {}, action) {
    switch (action.type) {
        case STORE_WIDGET_DATA:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    ...action.payload.data,
                },
            };
        case STORE_WIDGET_HITS:
            return {
                ...state,
                [`${action.payload.id}_hits`]: {
                    ...state[action.payload.id],
                    ...action.payload.data,
                },
            };
        case CLEAR_WIDGET_DATA:
            return {...state, [action.payload.id]: undefined};
        default:
            return state;
    }
}

export function callParticipantsStoreReducer(state = {}, action) {
    switch (action.type) {
        case STORE_CALL_PARTICIPANTS:
            return {
                ...state,
                [action.payload.data[0]._source.call_id]: action.payload.data,
            };
        default:
            return state;
    }
}

const callsReducer = combineReducers({
    directions: callsDirectionsReducer,
    statuses: callsStatusReducer,
    newStatuses: newCallsStatusReducer,
    history: callHistoryReducer,
    newHistory: newCallHistoryReducer,
    unfilteredHistory: unfilteredCallHistoryReducer,
    newUnfilteredHistory: newUnfilteredCallHistoryReducer,
    continuationTokenNewCallHistory: continuationTokenNewCallHistoryReducer,
    loading: callsLoadingReducer,
    newCallsloading: newCallsLoadingReducer,
    live: liveCallsReducer,
    recordings: callRecordingsReducer,
    newRecordings: newCallRecordingsReducer,
    stats: callsStatisticsReducer,
    tags: callsTagsReducer,
    widget: widgetDataStoreReducer,
    call_events: callParticipantsStoreReducer,
});

export default callsReducer;
