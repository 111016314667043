import React from 'react';
import {Card, CardBody, CardColumns, CardHeader, Container, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {userRequestAttributesSelector} from 'store/selectors/user-selectors';
import API from 'utils/api';

class IntegrationsManager extends React.Component {
    oauth_activation = async (integration_name) => {
        let resp = await API.get({
            endpoint: `/integrations/${integration_name}/redirect`,
            ...this.props.req_attr,
            tries: 1,
        });
        if (resp.data && resp.data.url) {
            window.open(resp.data.url, '_top');
        } else {
            this.props.history.push(`/admin/integrations/${integration_name}`);
        }
    };

    render() {
        return (
            <>
                <Container className='mt--6 d-flex flex-grow-1 flex-column' fluid>
                    <Card className=''>
                        <CardHeader className='table-header no-select'>
                            <Row className='flex-grow-1'>
                                <FormattedMessage
                                    id='title.table.integration_manager'
                                    defaultMessage='Manage your Integrations'
                                >
                                    {(msg) => <h3 className='mb-0 mr-2'>{msg}</h3>}
                                </FormattedMessage>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CardColumns>
                                <Card
                                    body
                                    inverse
                                    color='white'
                                    className='cursor-pointer'
                                    onClick={() => this.oauth_activation('hubspot')}
                                >
                                    <h1>Hubspot </h1>
                                </Card>
                                <Card
                                    body
                                    inverse
                                    color='white'
                                    className='cursor-pointer'
                                    onClick={() => this.oauth_activation('zohodesk')}
                                >
                                    <h1>Zoho Desk</h1>
                                </Card>
                                <Card
                                    body
                                    inverse
                                    color='white'
                                    className='cursor-pointer'
                                    onClick={() => this.oauth_activation('zoho_crm')}
                                >
                                    <h1>Zoho CRM</h1>
                                </Card>
                                <Card
                                    body
                                    inverse
                                    color='white'
                                    className='cursor-pointer'
                                    onClick={() => this.oauth_activation('zoho_one')}
                                >
                                    <h1>Zoho PhoneBridge</h1>
                                </Card>
                            </CardColumns>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    req_attr: userRequestAttributesSelector(state),
});

export default connect(mapStateToProps)(IntegrationsManager);
