import React, {useEffect, useState} from 'react';
import {CardBody, CardHeader} from 'reactstrap';
import {FormattedMessage, injectIntl} from 'react-intl';
import CardContent from '../../../components/content/CardContent';
import {useSelector} from 'react-redux';
import {userRequestCustomerSelector} from '../../../store/selectors/user-selectors';
import {getCustomers} from '../../../store/selectors/customer-selectors';
import PolicyList from './PolicyList';
import {whoMessages} from './policyMessages';

const PoliciesEveryone = injectIntl(({intl, edit}) => {
    const currentCustomerId = useSelector(userRequestCustomerSelector);
    const customers = useSelector(getCustomers);

    const [customer, setCustomer] = useState(null);
    const [implicitRoleId, setImplicitRoleId] = useState(null);

    useEffect(() => {
        setCustomer(customers.find((c) => c.id === currentCustomerId));
    }, [customers, currentCustomerId, setCustomer]);

    useEffect(() => {
        setImplicitRoleId(customer ? customer.implicit_role : null);
    }, [customer]);

    return (
        <CardContent fullHeight>
            <CardHeader className='d-flex flex-row align-items-baseline border-bottom-0'>
                <h2 className='mb-0 mr-2'>
                    <FormattedMessage
                        id='title.table.global.policies'
                        defaultMessage='Global Policies'
                    />
                </h2>
                <p className={'ml-2 text-muted'}>
                    <FormattedMessage
                        id={'message.policies.for.reference.only'}
                        defaultMessage={
                            'These policies are provided for reference only, and are not editable.'
                        }
                    />
                </p>
            </CardHeader>
            <CardBody style={{overflowY: 'auto'}} className={'d-flex flex-column flex-grow-1 p-0'}>
                <PolicyList
                    who={intl.formatMessage(whoMessages.customer)}
                    roleId={implicitRoleId}
                    edit={edit}
                    editable={false}
                />
            </CardBody>
        </CardContent>
    );
});

export default PoliciesEveryone;
