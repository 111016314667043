import React, {useCallback, useEffect, useMemo} from 'react';
import {Button, Form, FormGroup, Label} from 'reactstrap';
import {defineMessages, FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {DateRangeInput} from '../../../../components/input/DateInput';
import {useQueryStringObjectReducer} from '../../../../utils/hooks';
import moment from 'moment';
import {getCustomerAgents, getCustomerQueues} from '../../../../store/selectors/customer-selectors';
import isEqual from 'lodash.isequal';
import {useSelector} from 'react-redux';
import SelectInput from '../../../../components/input/SelectInput';
import {getLoading} from '../../../../store/selectors/portal-selectors';

const messages = defineMessages({
    all: {
        id: 'placeholder.all',
        defaultMessage: 'All',
    },
    queue_statistics: {
        id: 'message.queue_statistics',
        defaultMessage: 'Queue Statistics',
    },
    queues: {
        id: 'message.queues',
        defaultMessage: 'Queues',
    },
    dates: {
        id: 'message.dates',
        defaultMessage: 'Dates',
    },
    agents: {
        id: 'message.agents',
        defaultMessage: 'Agents',
    },
});

export interface QueueReportsParametersType {
    date: {
        start: string;
        end: string;
    };
    queues: number[];
    agents: number[];
}

interface QueuesReportsParametersProps extends WrappedComponentProps {
    onChange: (p: QueueReportsParametersType) => void;
    className?: string;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const QueueReportsParameters = ({className, onChange, intl}: QueuesReportsParametersProps) => {
    const queues = useSelector(getCustomerQueues, isEqual);
    const agents = useSelector(getCustomerAgents, isEqual);
    const loading = useSelector(getLoading('queue_event_statistics'));

    const defaultQueueReportsParameters: QueueReportsParametersType = useMemo(
        () => ({
            date: {
                start: moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            queues: [],
            agents: [],
        }),
        []
    );

    const serializeParameters = useCallback(
        (s: string) => {
            const query = new URLSearchParams(s);
            return {
                date: {
                    start: query.get('start') || defaultQueueReportsParameters.date.start,
                    end: query.get('end') || defaultQueueReportsParameters.date.end,
                },
                queues:
                    query
                        .get('queues')
                        ?.split(',')
                        .map((v) => Number(v))
                        .filter((v) => v !== 0) || [],
                agents:
                    query
                        .get('agents')
                        ?.split(',')
                        .map((v) => Number(v))
                        .filter((v) => v !== 0) || [],
            };
        },
        [defaultQueueReportsParameters]
    );

    const extractParameters = useCallback((p: QueueReportsParametersType) => {
        const query = new URLSearchParams();
        query.set('start', p.date.start);
        query.set('end', p.date.end);
        query.set('queues', p.queues.join(','));
        query.set('agents', p.agents.join(','));
        return query.toString();
    }, []);

    const isomorph = useMemo(
        () => ({to: serializeParameters, from: extractParameters}),
        [serializeParameters, extractParameters]
    );

    const [parameters, dispatch] = useQueryStringObjectReducer<QueueReportsParametersType>(
        defaultQueueReportsParameters,
        isomorph
    );

    useEffect(() => {
        onChange(parameters);
    }, [onChange, parameters]);

    return (
        <Form inline className={`d-flex flex-column flex-xl-row align-items-stretch ${className}`}>
            <FormGroup check inline className={`mb-3 mb-xl-0`}>
                <Label className={'mr-3'}>{intl.formatMessage(messages.dates)}</Label>
                <DateRangeInput
                    value={[parameters.date.start, parameters.date.end]}
                    enabled={!loading}
                    onValueChange={(value) => {
                        dispatch({
                            type: 'edit',
                            payload: {
                                field: 'date',
                                value: {start: value[0], end: value[1]},
                            },
                        });
                    }}
                    dateFormat={DATE_FORMAT}
                    limit={31}
                />
            </FormGroup>
            <FormGroup check inline className={`mb-3 mb-xl-0 flex-grow-1`}>
                <Label className={'mr-3'}>{intl.formatMessage(messages.queues)}</Label>
                <SelectInput
                    value={parameters.queues}
                    enabled={!loading}
                    onValueChange={(value: any) => {
                        dispatch({type: 'edit', payload: {field: 'queues', value}});
                    }}
                    options={queues.map((queue: any) => ({
                        value: queue.id,
                        label: `${queue.extension} - ${queue.descr}`,
                    }))}
                    multi
                    isSearchable
                    placeholder={intl.formatMessage(messages.all)}
                />
            </FormGroup>
            <FormGroup check inline row className={`flex-grow-1`}>
                <Label className={'mr-3'}>{intl.formatMessage(messages.agents)}</Label>
                <SelectInput
                    value={parameters.agents}
                    enabled={!loading}
                    onValueChange={(value: any) =>
                        dispatch({type: 'edit', payload: {field: 'agents', value}})
                    }
                    options={agents.map((agent: any) => ({value: agent.id, label: agent.agent}))}
                    multi
                    isSearchable
                    placeholder={intl.formatMessage(messages.all)}
                />
            </FormGroup>
            {!isEqual(defaultQueueReportsParameters, parameters) && (
                <FormGroup className={'mt-3 mt-xl-0 ml-xl-2'} check inline>
                    <Button
                        className={'mr-xl-1'}
                        color={'secondary'}
                        onClick={() =>
                            dispatch({type: 'replace', payload: defaultQueueReportsParameters})
                        }
                        size='sm'
                    >
                        <FormattedMessage id='btn.clear.filter' defaultMessage='Clear Filters' />
                    </Button>
                </FormGroup>
            )}
        </Form>
    );
};
export default injectIntl(QueueReportsParameters);
