import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SignIn} from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import classnames from 'classnames';
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
} from 'reactstrap';
import {faEnvelope, faUnlockAlt} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import {warmUpLambda} from 'store/actions/portal-action';
import {connect} from 'react-redux';
import LocaleSelector from '../../../components/locale/LocaleSelector';

class ALogin extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
        this.state = {
            hidden: true,
            email: '',
            password: '',
            focusedEmail: false,
            focusedPassword: false,
            loading: false,
            error: null,
        };
        this.inputRef = React.createRef();
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
        if (this.state.error) {
            this.setState({error: null});
        }
    };

    signIn = async (event) => {
        if (event) event.preventDefault();
        this.setState({loading: true, error: null});
        try {
            const user = await Auth.signIn(this.state.email, this.state.password);
            switch (user.challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                    this.changeState('requireNewPassword', user);
                    break;
                default:
                    this.changeState('signedIn', user);
            }
        } catch (e) {
            this.setState({error: e});
        }
    };

    showComponent(_theme) {
        return (
            <Card className='w-card top-25 align-self-start bg-secondary border-0 no-select d-flex'>
                <CardBody className=''>
                    <FormattedMessage id='title.sign.in' defaultMessage='Sign in to your account'>
                        {(txt) => <h3 className='text-muted ta-center mb-4'>{txt}</h3>}
                    </FormattedMessage>
                    <Form role='form' onSubmit={this.signIn}>
                        <FormGroup
                            className={classnames('mb-3', {focused: this.state.focusedEmail})}
                        >
                            <InputGroup className='input-group-merge input-group-alternative'>
                                <InputGroupText>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </InputGroupText>
                                <FormattedMessage
                                    id='input.placeholder.email'
                                    defaultMessage='Your email address'
                                >
                                    {(m) => (
                                        <Input
                                            placeholder={m}
                                            name='email'
                                            type='email'
                                            onFocus={() => {
                                                this.setState({focusedEmail: true});
                                                this.props.warmUpLambda();
                                            }}
                                            onBlur={() => this.setState({focusedEmail: false})}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    )}
                                </FormattedMessage>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup
                            className={classnames('mb-3', {focused: this.state.focusedPassword})}
                        >
                            <InputGroup className='input-group-merge input-group-alternative'>
                                <InputGroupText>
                                    <FontAwesomeIcon icon={faUnlockAlt} />
                                </InputGroupText>
                                <FormattedMessage
                                    id='input.placeholder.password'
                                    defaultMessage='Password'
                                >
                                    {(m) => (
                                        <Input
                                            innerRef={this.inputRef}
                                            placeholder={m}
                                            name='password'
                                            type={this.state.hidden ? 'password' : 'text'}
                                            onFocus={() => this.setState({focusedPassword: true})}
                                            onBlur={() => this.setState({focusedPassword: false})}
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    )}
                                </FormattedMessage>
                                <InputGroupText
                                    className='no-select cursor-pointer'
                                    onClick={() => {
                                        this.setState({hidden: !this.state.hidden});
                                        this.inputRef.current.focus();
                                    }}
                                >
                                    <i
                                        className={
                                            this.state.hidden ? 'fa fa-eye' : 'fa fa-eye-slash'
                                        }
                                    />
                                </InputGroupText>
                            </InputGroup>
                            {this.state.error && (
                                <FormattedMessage
                                    id='error.login'
                                    defaultMessage='Invalid email or password'
                                >
                                    {(m) => (
                                        <span className='ml-2 text-danger text-sm-sm'>{m}</span>
                                    )}
                                </FormattedMessage>
                            )}
                        </FormGroup>
                        <div className='d-flex flex-column text-sm-sm mb-3'>
                            <div>
                                <FormattedMessage
                                    id='link.sign.in.forgot_password'
                                    defaultMessage='Forgot your password ?'
                                >
                                    {(m) => (
                                        <a
                                            href='/'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.changeState('forgotPassword');
                                            }}
                                        >
                                            {m}
                                        </a>
                                    )}
                                </FormattedMessage>
                            </div>
                            <div>
                                <FormattedMessage
                                    id='message.no_account'
                                    defaultMessage="Don't have an account ?"
                                />{' '}
                                <FormattedMessage id='link.contact_us' defaultMessage='Contact us'>
                                    {(m) => (
                                        <a
                                            target='_blank'
                                            href='https://www.foneco.co/'
                                            rel='noopener noreferrer'
                                        >
                                            {m}
                                        </a>
                                    )}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className='text-center'>
                            <FormattedMessage id='btn.signIn' defaultMessage='Sign in'>
                                {(m) => (
                                    <Button color='primary' type='submit'>
                                        {m}
                                    </Button>
                                )}
                            </FormattedMessage>
                        </div>
                    </Form>
                </CardBody>
                <LocaleSelector style={{position: 'fixed', top: 15, right: 15}} />
            </Card>
        );
    }
}

export default connect(null, {warmUpLambda})(ALogin);
