import React, {useEffect} from 'react';
import Spinner from '../../../components/spinner/Spinner';
import {useDispatch, useSelector} from 'react-redux';
import {customerReportSelector} from '../../../store/selectors/customer-selectors';
import {ComponentReportRoute} from './routes';
import {Has} from 'components/security/Has';
import {getUserTravel} from '../../../store/selectors/user-selectors';

type ReportFetcherProps = {
    route: ComponentReportRoute;
    parameters: any;
};

const ReportFetcher = ({route, parameters}: ReportFetcherProps) => {
    const report = useSelector(customerReportSelector(route.name));
    const travelling = useSelector(getUserTravel);

    const dispatch = useDispatch();

    useEffect(() => {
        route.queryFactory(dispatch, parameters);
    }, [dispatch, parameters, route, travelling]);

    return (
        <Has
            loaded={[
                'queue_event_statistics',
                'queues',
                'agents',
                'users',
                'extensions',
                'calls_statistics',
            ]}
            orElse={
                <Spinner
                    className={'my-5'}
                    color={'primary'}
                    background={'transparent'}
                    global={false}
                    size={50}
                />
            }
        >
            <route.component parameters={parameters} report={report} />
        </Has>
    );
};

export default ReportFetcher;
