import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {getCustomerUsers} from '../../../store/selectors/customer-selectors';
import {FormattedMessage, injectIntl} from 'react-intl';
import LightFontAwesomeIcon from '../../../components/utils/LightFontAwesomeIcon';
import GridList from '../../../components/layout/GridList/GridList';
import UserCard from '../../../components/user/UserCard';
import {Button, CardBody, CardHeader} from 'reactstrap';
import CardContent from '../../../components/content/CardContent';
import PolicyList from './PolicyList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {whoMessages} from './policyMessages';
import {Has} from '../../../components/security/Has';

const PoliciesUsers = injectIntl(({edit, intl}) => {
    const users = useSelector(getCustomerUsers);

    const [user, setUser] = useState();

    return (
        <>
            <CardContent fullHeight>
                <CardHeader className='d-flex flex-row align-items-baseline border-bottom-0'>
                    <h2 className='mb-0 mr-2'>
                        <FormattedMessage
                            id='title.table.user.policies'
                            defaultMessage='User Policies'
                        />
                    </h2>
                    {user && (
                        <>
                            <Button
                                className={'d-flex flex-row align-items-center ml-2'}
                                color={'primary'}
                                onClick={() => setUser(null)}
                                outline
                                size={'sm'}
                            >
                                <FontAwesomeIcon icon={faUser} className={'mr-1'} />
                                <FormattedMessage
                                    id={'title.user.policies'}
                                    defaultMessage={"{user}'s Policies"}
                                    values={{user: user.full_name}}
                                />
                                <FontAwesomeIcon className={'ml-1 cursor-pointer'} icon={faTimes} />
                            </Button>
                            <Has aPolicyMatching={[{action: 'WRITE', resourceType: 'POLICIES'}]}>
                                <Button
                                    className={'mr-auto align-self-center'}
                                    color={'primary'}
                                    onClick={() => edit(null, user.implicit_role, user.full_name)}
                                    size={'sm'}
                                >
                                    <LightFontAwesomeIcon icon={'plus'} />
                                </Button>
                            </Has>
                        </>
                    )}
                </CardHeader>
                <CardBody
                    style={{overflowY: 'auto'}}
                    className={`d-flex flex-column flex-grow-1 ${user && 'p-0'}`}
                >
                    {user ? (
                        <PolicyList
                            who={intl.formatMessage(whoMessages.user, {who: user.full_name})}
                            roleId={user.implicit_role}
                            edit={edit}
                        />
                    ) : (
                        <GridList
                            items={users}
                            itemComponent={(item) => (
                                <UserCard
                                    key={item.email}
                                    actionComponent={
                                        <Button
                                            onClick={() => {
                                                setUser(item);
                                            }}
                                            color={'primary'}
                                            size={'sm'}
                                        >
                                            <LightFontAwesomeIcon icon={'key'} className={'mr-1'} />
                                            <FormattedMessage
                                                id={'btn.policies'}
                                                defaultMessage={'Policies'}
                                            />
                                        </Button>
                                    }
                                    className={'mb-2 with-shadow'}
                                    onClick={() => {}}
                                    user={item}
                                    style={{minHeight: 117, maxHeight: 117}}
                                />
                            )}
                            itemKey={(item) => item.email}
                            itemWidth={450}
                            itemHeight={125}
                            style={{overflowX: 'visible'}}
                        />
                    )}
                </CardBody>
            </CardContent>
        </>
    );
});

PoliciesUsers.propTypes = {};

export default PoliciesUsers;
