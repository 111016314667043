import {
    faArrowDown,
    faArrowUp,
    faCircle,
    faExclamation,
    faPhone,
    faPhoneSlash,
    faQuestion,
    faRedo,
    faVoicemail,
} from '@fortawesome/free-solid-svg-icons';
import {capitalize, formatAlphanumericString} from './format';
import {defineMessages} from 'react-intl';
import moment from 'moment';

const STATUS_MESSAGES = defineMessages({
    connected: {
        id: 'message.status.connected',
        defaultMessage: 'Live',
    },
    missed: {
        id: 'message.status.missed',
        defaultMessage: 'Missed',
    },
    voicemail: {
        id: 'message.status.voicemail',
        defaultMessage: 'Voicemail',
    },
    'not connected': {
        id: 'message.status.not_connected',
        defaultMessage: 'No Answer',
    },
    hanged: {
        id: 'message.status.hanged',
        defaultMessage: 'Connected',
    },
});

const DIRECTION_MESSAGES = defineMessages({
    in: {
        id: 'message.direction.in',
        defaultMessage: 'Inbound',
    },
    out: {
        id: 'message.direction.out',
        defaultMessage: 'Outbound',
    },
    internal: {
        id: 'message.direction.internal',
        defaultMessage: 'Internal',
    },
});

export const CALL_STATUSES = {
    hanged: {
        label: 'Connected',
        color: 'success',
        icon: faPhone,
    },
    missed: {
        label: 'Missed',
        color: 'warning',
        icon: faPhoneSlash,
    },
    voicemail: {
        label: 'Voicemail',
        color: 'info',
        icon: faVoicemail,
    },
    'not connected': {
        label: 'No Answer',
        color: 'default',
        icon: faPhoneSlash,
    },
    connected: {
        label: 'Live',
        color: 'primary',
        icon: faExclamation,
    },
};

export const CALL_DIRECTIONS = {
    in: {
        label: 'Inbound',
        color: 'default',
        icon: faArrowDown,
    },
    out: {
        label: 'Outbound',
        color: 'default',
        icon: faArrowUp,
    },
    internal: {
        label: 'Internal',
        color: 'default',
        icon: faRedo,
    },
};

const CALL_ICON = {
    hanged: {
        in: 'arrow-down',
        out: 'arrow-up',
        internal: 'redo',
    },
    missed: {
        in: 'arrow-down',
        out: 'arrow-up',
        internal: 'redo',
    },
    'not connected': {
        in: 'arrow-down',
        out: 'arrow-up',
        internal: 'redo',
    },
    voicemail: {
        in: 'voicemail',
        out: 'voicemail',
        internal: 'voicemail',
    },
    connected: {
        in: 'arrow-down',
        out: 'arrow-up',
        internal: 'redo',
    },
};

const CALL_COLOR = {
    hanged: {
        in: 'success',
        out: 'success',
        internal: 'success',
    },
    missed: {
        in: 'warning',
        out: 'warning',
        internal: 'warning',
    },
    'not connected': {
        in: 'default',
        out: 'default',
        internal: 'default',
    },
    voicemail: {
        in: 'info',
        out: 'info',
        internal: 'info',
    },
    connected: {
        in: 'primary',
        out: 'primary',
        internal: 'primary',
    },
};

export const status_color = (status) =>
    CALL_STATUSES[status] && CALL_STATUSES[status].color ? CALL_STATUSES[status].color : 'primary';

export const status_icon = (status) =>
    CALL_STATUSES[status] && CALL_STATUSES[status].icon ? CALL_STATUSES[status].icon : faCircle;

export const status_label = (status, intl) =>
    capitalize(
        CALL_STATUSES[status] && CALL_STATUSES[status].label
            ? intl && STATUS_MESSAGES[status]
                ? intl.formatMessage(STATUS_MESSAGES[status])
                : CALL_STATUSES[status].label
            : status
    );

export const direction_icon = (direction) =>
    CALL_DIRECTIONS[direction] && CALL_DIRECTIONS[direction].icon
        ? CALL_DIRECTIONS[direction].icon
        : faQuestion;

export const direction_label = (direction, intl) =>
    capitalize(
        CALL_DIRECTIONS[direction] && CALL_DIRECTIONS[direction].label
            ? intl && DIRECTION_MESSAGES[direction]
                ? intl.formatMessage(DIRECTION_MESSAGES[direction])
                : CALL_DIRECTIONS[direction].label
            : direction
    );

export const call_color = (call) =>
    CALL_COLOR[call.status]
        ? CALL_COLOR[call.status][call.direction]
            ? CALL_COLOR[call.status][call.direction]
            : 'default'
        : 'default';

export const call_icon = (call) =>
    CALL_ICON[call.status]
        ? CALL_ICON[call.status][call.direction]
            ? CALL_ICON[call.status][call.direction]
            : 'circle'
        : 'circle';

export const call_description = (call, intl) =>
    `${direction_label(call.direction, intl)} - ${status_label(call.status, intl)}`;

export const extension_label = (extension, extensions, defaultValue = 'system') => {
    if (extension === 's') return defaultValue;
    if (!extensions) return extension;
    const e = extensions.find((e) => e.extension === extension);
    return e ? `${extension} - ${e.name}` : extension;
};

export const appendS3QueryParams = (url, filename) => {
    const urlObj = new URL(url);
    urlObj.searchParams.append(
        'response-content-disposition',
        `attachment; filename*=UTF-8''${filename}`
    );
    return urlObj.toString();
};

export const formatCallRecordingFilename = (call) => {
    const direction = call.direction;
    const extension = formatAlphanumericString(call.extension);
    const number = formatAlphanumericString(call.direction === 'out' ? call.to : call.from);
    const date = moment(call.timestamp).format('YYYYMMDD-hhmmss');
    const id = formatAlphanumericString(call.id);
    return `${date}_${extension}_${direction}_${number}_${id}.mp3`;
};
