import {createSelector} from 'reselect';

export const getCustomerExtensions = (state) => state.customer.extensions;
export const getNewCustomerExtensions = (state) => state.customer.newExtensions;
export const getNewPortalCustomerAccessSelector = (state) => state.customer.newPortalAccess;
export const getNewPortalCustomerAccessSuccessSelector = (state) =>
    state.customer.newPortalAccessSuccess;
export const getNewPortalCustomerAccessFailedSelector = (state) =>
    state.customer.newPortalAccessFailed;
export const getCustomerExtensionConfig = (state) => state.customer.extension_config;
export const getCustomerUsers = (state) => state.customer.users;
export const getCustomers = (state) => state.customer.customers;
export const getCustomerJourneys = (state) => state.customer.short_journeys;
export const getLongJourneys = (state) => state.customer.long_journeys;
export const getCustomerTeams = (state) => state.customer.teams;
export const getCustomerRoles = (state) => state.customer.roles;
export const getCustomerPolicies = (state) => state.customer.policies;
export const getCustomerFaxConfigs = (state) => state.customer.fax_configs;
export const getCustomerFaxBindings = (state) => state.customer.fax_bindings;
export const getCustomerLanguages = (state) => state.customer.languages;
export const getCustomerIntegrations = (state) => state.customer.integrations;
export const getCustomerApiKeys = (state) => state.customer.api_keys;
export const getCustomerMaxApiKeys = (state) => state.customer.max_api_keys;
export const getCustomerQueues = (state) => state.customer.queues;
export const getCustomerAgents = (state) => state.customer.agents;
export const getCustomerReports = (state) => state.customer.reports;
export const getCustomerResourceTypes = (state) => state.customer.resource_types;
export const getCustomerSmsConfigs = (state) => state.customer.sms_configs;
export const getCustomerSmsBindings = (state) => state.customer.sms_bindings;
export const getCustomerSmsBindingsUsers = (state) => state.customer.sms_bindings_users;
export const getIncoming = (state) => state.customer.incoming;
export const getPowerBrokerApiKey = (state) => state.customer.power_broker_api_key;
export const getPbxAdminUsers = (state) => state.customer.pbx_admin_users;
export const getCustomerDeviceProvisioningConfigurations = (state) =>
    state.customer.device_configurations;
export const getCustomerProvisioningConfigurationTemplates = (state) =>
    state.customer.configuration_templates;
export const getCustomerDeviceProvisioningUpToDate = (state) =>
    state.customer.provisioning_up_to_date;
export const getProModule = (state) => state.customer.pro_module;
export const getCustomerPhoneNumbers = (state) => state.customer.phone_numbers;
export const getProvidersList = (state) => state.customer.provider;
export const getCustomerCDRProxyList = (state) => state.customer.cdr_proxy;

export const customerExtensionsSelector = createSelector(getCustomerExtensions, (extensions) => {
    return Object.keys(extensions).map((ext) => ({
        extension: ext,
        name: extensions[ext].name,
        virtual: extensions[ext].sip == null || extensions[ext].sip === [],
    }));
});
export const newCustomerExtensionsSelector = createSelector(
    getNewCustomerExtensions,
    (newExtensions) => {
        if (newExtensions?.result) {
            return Object.keys(newExtensions.result).map((ext) => ({
                group: newExtensions.result[ext].group,
                site: newExtensions.result[ext].site,
                extension: newExtensions.result[ext].user,
                dir: newExtensions.result[ext].dir,
                name: newExtensions.result[ext].firstName,
                lastName: newExtensions.result[ext].lastName,
                domain: newExtensions.result[ext].domain,
                virtual: newExtensions.result[ext].sip == null,
            }));
        } else return {};
    },
);
export const newPortalCustomerAccessSelector = createSelector(
    getNewPortalCustomerAccessSelector,
    (newPortalAccess) => {
        return newPortalAccess;
    },
);
export const newPortalCustomerAccessSuccessSelector = createSelector(
    getNewPortalCustomerAccessSuccessSelector,
    (newPortalAccessSuccess) => {
        return newPortalAccessSuccess;
    },
);
export const newPortalCustomerAccessFailedSelector = createSelector(
    getNewPortalCustomerAccessFailedSelector,
    (newPortalAccessFailed) => {
        return newPortalAccessFailed;
    },
);

export const getCustomerJourney = (journey_id) =>
    createSelector(getLongJourneys, (long_journeys) =>
        journey_id ? long_journeys[journey_id] : null,
    );

export const customerFaxBindingsSelector = (fax_config_id) =>
    createSelector(getCustomerFaxBindings, (bindings) =>
        fax_config_id ? bindings[fax_config_id] : null,
    );

export const customerSmsBindingsSelector = (sms_config_id) =>
    createSelector(getCustomerSmsBindings, (bindings) =>
        sms_config_id ? bindings[sms_config_id] : null,
    );

export const incomingExtensionSelector = () =>
    createSelector(getIncoming, (numbers) => Object.values(numbers));

export const customerIntegrationSelector = (integration_name) =>
    createSelector(getCustomerIntegrations, (integrations) => integrations[integration_name]);

export const customerIntegrationUsersSelector = (integration_name) =>
    createSelector(
        customerIntegrationSelector(integration_name),

        (integration) => integration.users,
    );

export const customerIntegrationBindingSelector = (integration_name) =>
    createSelector(
        customerIntegrationSelector(integration_name),

        (integration) => integration.bindings,
    );

export const customerIntegrationApiKeySelector = (integration_name) =>
    createSelector(
        customerIntegrationSelector(integration_name),

        (integration) => integration.access.apikey,
    );

export const customerReportSelector = (report_name) =>
    createSelector(getCustomerReports, (reports) => reports[report_name]);

export const customerProModuleSelector = (user_id) =>
    createSelector(getProModule, (bindings) => (user_id ? bindings[user_id] : null));
