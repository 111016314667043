import React from 'react'
import classnames from 'classnames'

const Side = props => {
    const {
        className,
        children,
        ...others
    } = props;
    return (
        <ul className={classnames('side-nav', className)} {...others}>
            {children}
        </ul>
    );
}

const SideItem = props => {
    const {
        className,
        children,
        ...others
    } = props;
    return (
        <li className={classnames('side-item', className)} {...others}>
            {children}
        </li>
    );
}

export { Side, SideItem };