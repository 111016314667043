import React from 'react';
import {RequireNewPassword} from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import {Card, CardBody} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import PasswordSetter from '../../../components/password/PasswordSetter';
import LocaleSelector from '../../../components/locale/LocaleSelector';

class ARequireNewPassword extends RequireNewPassword {
    constructor(props) {
        super(props);
        this._validAuthStates = ['requireNewPassword'];
        this.state = {};
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    setPassword = (newPassword) => {
        const user = this.props.authData;
        const resp = Auth.completeNewPassword(user, newPassword);
        this.changeState('signedIn', resp);
    };

    showComponent(_theme) {
        return (
            <Card className='col-6 align-self-center bg-secondary border-0 no-select d-flex'>
                <CardBody className=''>
                    <FormattedMessage
                        id='amplify.sign.in.info'
                        defaultMessage='Choose your new password'
                    >
                        {(txt) => <span className='text-muted'>{txt}</span>}
                    </FormattedMessage>
                    <PasswordSetter
                        size={'md'}
                        onSubmit={(currentPassword, newPassword) => this.setPassword(newPassword)}
                    />
                </CardBody>
                <LocaleSelector style={{position: 'fixed', top: 15, right: 15}} />
            </Card>
        );
    }
}

export default ARequireNewPassword;
