import React from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {connect} from 'react-redux'
import { getPortalAlert } from 'store/selectors/portal-selectors';
import { hidePortalAlert } from 'store/actions/portal-action';

class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show:true
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.alert) {
            return {show: true}
        }
        else return state
    }

    setShow = (value) => this.setState({show: value})
    
    createAlert = () => {
        let alert = this.props.alert;
        if (alert) {
            return <ReactBSAlert
                success={alert.type === 'success'}
                danger={alert.type === 'danger'}
                info={alert.type === 'info'}
                warning={alert.type === 'warning'}
                show={this.state.show}
                style={{ display: "block", marginTop: "-100px" }}
                title={alert.title || (alert.type + '')}
                onConfirm={() => {this.setShow(false); this.props.hidePortalAlert()}}
                onCancel={() => {this.setShow(false); this.props.hidePortalAlert()}}
                confirmBtnBsStyle={'primary'}
                confirmBtnText="OK"
                btnSize=""
        >
            {alert.message}
        </ReactBSAlert>
        }
    }

    render() {
        let alert = this.createAlert();
        return <>
            {alert}
        </>
    }
}


export default connect(state => ({alert :getPortalAlert(state)}), {hidePortalAlert})(Alerts)