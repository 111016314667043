import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
    getUser,
    getUserCustomer,
    getUserCustomerLicense,
    getUserTravel,
} from '../../store/selectors/user-selectors';
import {getLoadings} from '../../store/selectors/portal-selectors';
import React, {PropsWithChildren} from 'react';

export interface HasCheck {
    aPolicyMatching: {
        resourceType?: string;
        action?: string;
    }[];
    allOfTheseRoles: string[];
    active: boolean;
    forbidTravel: boolean;
    requiresTravel: boolean;
    loaded: string | string[];
    oneOfTheseRoles: string[];
    children: React.ReactElement;
    orElse: React.ReactElement;
    theRole: string;
    license?: string | string[];
}

export const hasRole = (user: any, role: any) => {
    return user.user_roles.some((ur: any) => ur.role.name.toLowerCase() === role.toLowerCase());
};

export const isActive = (props: HasCheck, customer: any) => {
    return props.active ? customer.active : true;
};

const roleHasPolicy = (role: any, action: string | undefined, resourceType: string | undefined) => {
    return role.policies.some(
        (p: any) =>
            (p.resource_type_.name === 'ALL' ||
                resourceType == null ||
                p.resource_type_.name === resourceType) &&
            (p.action.name === 'ALL' || action == null || p.action.name === action)
    );
};

export const hasPolicy = (
    user: any,
    action: string | undefined,
    resourceType: string | undefined
) => {
    const a = action ? action.toUpperCase() : undefined;
    const rt = resourceType ? resourceType.toUpperCase() : undefined;

    return user.roles.some((r: any) => roleHasPolicy(r, a, rt));
};

function policyMatch(props: HasCheck & {children?: React.ReactNode | undefined}, user: any) {
    return (
        props.aPolicyMatching.some(({action, resourceType}) =>
            hasPolicy(user, action, resourceType)
        ) || props.aPolicyMatching.length === 0
    );
}

function roleMatch(props: HasCheck & {children?: React.ReactNode | undefined}, user: any) {
    return props.theRole == null || hasRole(user, props.theRole);
}

function licenseMatch(
    props: HasCheck & {children?: React.ReactNode | undefined},
    customerLicenseName: string
) {
    return (
        props.license == null ||
        (typeof props.license === 'string'
            ? props.license !== customerLicenseName
            : props.license?.includes(customerLicenseName))
    );
}

function allRolesMatch(props: HasCheck & {children?: React.ReactNode | undefined}, user: any) {
    return props.allOfTheseRoles.every((role) => hasRole(user, role));
}

function atLeastOneRoleMatch(
    props: HasCheck & {children?: React.ReactNode | undefined},
    user: any
) {
    return (
        props.oneOfTheseRoles.length === 0 ||
        props.oneOfTheseRoles.some((role) => hasRole(user, role))
    );
}

function travelMatch(
    props: HasCheck & {children?: React.ReactNode | undefined},
    travelling: boolean
) {
    return (
        (!props.forbidTravel && !props.requiresTravel) ||
        (props.forbidTravel && !travelling) ||
        (props.requiresTravel && travelling)
    );
}

function loadingMatch(loading_ids: string[], loadings: Record<string, boolean>) {
    return loading_ids.every(
        (id) =>
            !Object.keys(loadings).includes(id) ||
            (Object.keys(loadings).includes(id) && !loadings[id])
    );
}

export const Has = (props: PropsWithChildren<HasCheck>) => {
    const loadings = useSelector(getLoadings);
    const user = useSelector(getUser);
    const customer = useSelector(getUserCustomer);
    const customerLicenseName = useSelector(getUserCustomerLicense);

    const travelling = useSelector(getUserTravel);

    const loadingIds = typeof props.loaded === 'string' ? [props.loaded] : props.loaded;

    const respectsCondition =
        policyMatch(props, user) &&
        roleMatch(props, user) &&
        isActive(props, customer) &&
        licenseMatch(props, customerLicenseName) &&
        allRolesMatch(props, user) &&
        atLeastOneRoleMatch(props, user) &&
        travelMatch(props, travelling) &&
        loadingMatch(loadingIds, loadings);

    return <>{respectsCondition ? props.children : props.orElse}</>;
};

Has.propTypes = {
    aPolicyMatching: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.string,
            resourceType: PropTypes.string,
        })
    ),
    allOfTheseRoles: PropTypes.array,
    forbidTravel: PropTypes.bool,
    loaded: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    oneOfTheseRoles: PropTypes.array,
    orElse: PropTypes.element,
    theRole: PropTypes.string,
    license: PropTypes.string,
};

Has.defaultProps = {
    aPolicyMatching: [],
    allOfTheseRoles: [],
    active: false,
    forbidTravel: false,
    requiresTravel: false,
    loaded: [],
    oneOfTheseRoles: [],
    orElse: null,
    theRole: null,
};
