import React, {useReducer, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Card, CardBody, CardText, Form, FormGroup, Label, Nav, Row} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
    createCustomerPolicy,
    deleteCustomerPolicy,
    updateCustomerPolicy,
} from '../../../store/actions/customer-action';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';
import set from 'lodash.set';
import SidePanelHeader from '../../../components/panel/SidePanelHeader';
import SidePanelContent from '../../../components/panel/SidePanelContent';
import FormSection from '../../../components/form/FormSection';
import {faCog, faUser} from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../../components/input/SelectInput';
import DangerModal from '../../../components/notification/DangerModal';
import {
    getCustomerAgents,
    getCustomerExtensions,
    getCustomerQueues,
    getCustomerResourceTypes,
    getCustomers,
    getCustomerTeams,
    getCustomerUsers,
} from '../../../store/selectors/customer-selectors';
import {actionMessages, resourceTypeMessages, scopeMessages} from './policyMessages';
import Spinner from '../../../components/spinner/Spinner';
import {Has} from '../../../components/security/Has';

// noinspection JSUnusedGlobalSymbols
const SCOPE_TYPE_VALUES_COMPONENTS = {
    OWN: () => <></>,
    OWN_TEAMS: () => <></>,
    OWN_CUSTOMERS: () => <></>,
    SPECIFIC_USERS: injectIntl(({values, onValuesChange, intl}) => {
        const users = useSelector(getCustomerUsers);

        return (
            <Has
                loaded={['users']}
                orElse={
                    <Spinner
                        centered
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                }
            >
                <Row>
                    <FormGroup className='col'>
                        <Label className='form-control-label'>
                            <FormattedMessage
                                id='input.label.scope.specific.users'
                                defaultMessage='Target Users'
                            />
                        </Label>
                        <SelectInput
                            onValueChange={onValuesChange}
                            options={users.map((u) => ({
                                label: u.full_name || u.email,
                                value: u.id,
                            }))}
                            placeholder={intl.formatMessage({
                                id: 'input.placeholder.scope.specific.users',
                                defaultMessage: 'Which users should be targeted by this policy?',
                            })}
                            multi
                            value={values}
                        />
                    </FormGroup>
                </Row>
            </Has>
        );
    }),
    SPECIFIC_TEAMS: injectIntl(({values, onValuesChange, intl}) => {
        const teams = useSelector(getCustomerTeams);

        return (
            <Has
                loaded={['teams']}
                orElse={
                    <Spinner
                        centered
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                }
            >
                <Row>
                    <FormGroup className='col'>
                        <Label className='form-control-label'>
                            <FormattedMessage
                                id='input.label.scope.specific.teams'
                                defaultMessage='Target Teams'
                            />
                        </Label>
                        <SelectInput
                            onValueChange={onValuesChange}
                            options={teams.map((t) => ({
                                label: t.name,
                                value: t.id,
                            }))}
                            placeholder={intl.formatMessage({
                                id: 'input.placeholder.scope.specific.teams',
                                defaultMessage: 'Which teams should be targeted by this policy?',
                            })}
                            multi
                            value={values}
                        />
                    </FormGroup>
                </Row>
            </Has>
        );
    }),
    SPECIFIC_CUSTOMERS: injectIntl(({values, onValuesChange, intl}) => {
        const customers = useSelector(getCustomers);

        return (
            <Has
                loaded={['customers']}
                orElse={
                    <Spinner
                        centered
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                }
            >
                <Row>
                    <FormGroup className='col'>
                        <Label className='form-control-label'>
                            <FormattedMessage
                                id='input.label.scope.specific.customers'
                                defaultMessage='Target Organizations'
                            />
                        </Label>
                        <SelectInput
                            onValueChange={onValuesChange}
                            options={customers.map((c) => ({
                                label: c.name ? `${c.name} (${c.id})` : c.id,
                                value: c.id,
                            }))}
                            placeholder={intl.formatMessage({
                                id: 'input.placeholder.scope.specific.customers',
                                defaultMessage:
                                    'Which organizations should be targeted by this policy?',
                            })}
                            multi
                            value={values}
                        />
                    </FormGroup>
                </Row>
            </Has>
        );
    }),
    SPECIFIC_QUEUE_AGENTS: injectIntl(({values, onValuesChange, intl}) => {
        const agents = useSelector(getCustomerAgents);

        return (
            <Has
                loaded={['agents']}
                orElse={
                    <Spinner
                        centered
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                }
            >
                <Row>
                    <FormGroup className='col'>
                        <Label className='form-control-label'>
                            <FormattedMessage
                                id='input.label.scope.specific.agents'
                                defaultMessage='Target Queue Agents'
                            />
                        </Label>
                        <SelectInput
                            onValueChange={onValuesChange}
                            options={agents.map((a) => ({
                                label: a.agent,
                                value: a.id,
                            }))}
                            placeholder={intl.formatMessage({
                                id: 'input.placeholder.scope.specific.queue.agents',
                                defaultMessage:
                                    'Which queue agents should be targeted by this policy?',
                            })}
                            multi
                            value={values}
                        />
                    </FormGroup>
                </Row>
            </Has>
        );
    }),
    SPECIFIC_QUEUES: injectIntl(({values, onValuesChange, intl}) => {
        const queues = useSelector(getCustomerQueues);

        return (
            <Has
                loaded={['queues']}
                orElse={
                    <Spinner
                        centered
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                }
            >
                <Row>
                    <FormGroup className='col'>
                        <Label className='form-control-label'>
                            <FormattedMessage
                                id='input.label.scope.specific.queues'
                                defaultMessage='Target Queues'
                            />
                        </Label>
                        <SelectInput
                            onValueChange={onValuesChange}
                            options={queues.map((q) => ({
                                label: q.descr ? `${q.extension} - ${q.descr}` : q.id,
                                value: q.id,
                            }))}
                            placeholder={intl.formatMessage({
                                id: 'input.placeholder.scope.specific.queues',
                                defaultMessage: 'Which queues should be targeted by this policy?',
                            })}
                            multi
                            value={values}
                        />
                    </FormGroup>
                </Row>
            </Has>
        );
    }),
    SPECIFIC_EXTENSIONS: injectIntl(({values, onValuesChange, intl}) => {
        const extensions = useSelector(getCustomerExtensions);

        return (
            <Has
                loaded={['extensions']}
                orElse={
                    <Spinner
                        centered
                        color={'primary'}
                        background={'transparent'}
                        global={false}
                        size={50}
                    />
                }
            >
                <Row>
                    <FormGroup className='col'>
                        <Label className='form-control-label'>
                            <FormattedMessage
                                id='input.label.scope.specific.extensions'
                                defaultMessage='Target Extensions'
                            />
                        </Label>
                        <SelectInput
                            onValueChange={onValuesChange}
                            options={Object.values(extensions).map((e) => ({
                                label: e.name ? `${e.extension} - ${e.name}` : `${e.extension}`,
                                value: Number(e.extension),
                            }))}
                            placeholder={intl.formatMessage({
                                id: 'input.placeholder.scope.specific.extensions',
                                defaultMessage:
                                    'Which extensions should be targeted by this policy?',
                            })}
                            multi
                            value={values}
                        />
                    </FormGroup>
                </Row>
            </Has>
        );
    }),
    EVERYONE: () => <></>,
};

const PolicyForm = injectIntl(({intl, policy, toggle, roleId, who}) => {
    const reduxDispatch = useDispatch();

    const resourceTypes = useSelector(getCustomerResourceTypes);

    const defaultPolicyState = () => ({
        id: '',
        action_id: '',
        resource_type_id: '',
        scope_type_id: '',
        scope_values: [],
        role_id: roleId,
    });

    const getPolicyState = () => {
        if (!!!policy) return defaultPolicyState();
        return {
            id: policy.id,
            action_id: policy.action.id,
            resource_type_id: policy.resource_type_.id,
            scope_type_id: policy.scope.type.id,
            scope_values: policy.scope.values || [],
            role_id: policy.role,
        };
    };

    const [deletePolicyModal, setDeletePolicyModal] = useState(false);
    const [policyState, policyDispatch] = useReducer((prevPolicyState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultPolicyState();
            case 'edit':
                if (isEqual(get(prevPolicyState, action.payload.field), action.payload.value)) {
                    return prevPolicyState;
                }
                const newState = {...prevPolicyState};
                set(newState, action.payload.field, action.payload.value);
                return newState;
            default:
                return prevPolicyState;
        }
    }, getPolicyState());

    const submit = (e) => {
        e.preventDefault();
        if (!!policy) {
            reduxDispatch(
                updateCustomerPolicy({
                    id: policy.id,
                    action_id: policyState.action_id,
                    resource_type_id: policyState.resource_type_id,
                    scope: {
                        type_id: policyState.scope_type_id,
                        values: policyState.scope_values,
                    },
                    role_id: policyState.role_id,
                })
            );
        } else {
            reduxDispatch(
                createCustomerPolicy({
                    action_id: policyState.action_id,
                    resource_type_id: policyState.resource_type_id,
                    scope: {
                        type_id: policyState.scope_type_id,
                        values: policyState.scope_values,
                    },
                    role_id: roleId,
                })
            );
        }
        toggle();
    };

    const deletePolicy = () => {
        reduxDispatch(deleteCustomerPolicy({id: policy.id}));
        setDeletePolicyModal(false);
        toggle();
    };

    const editField = (name, value) =>
        policyDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });

    const rt = resourceTypes.find((rt) => rt.id === policyState.resource_type_id);
    const st = rt != null ? rt.scope_types.find((st) => st.id === policyState.scope_type_id) : null;
    const scopeName = st ? st.name : null;

    const isValid = () => {
        return (
            policyState.action_id !== '' &&
            policyState.resource_type_id !== '' &&
            policyState.scope_type_id !== '' &&
            policyState.role_id !== ''
        );
    };

    const ScopeComponent = scopeName ? SCOPE_TYPE_VALUES_COMPONENTS[scopeName] : null;

    return (
        <>
            <SidePanelHeader>
                {policy ? (
                    <FormattedMessage
                        id={'btn.edit.policy'}
                        defaultMessage={'Editing a policy for: {who}'}
                        values={{who}}
                    />
                ) : (
                    <FormattedMessage
                        id={'btn.create.policy'}
                        defaultMessage={'Creating a policy for: {who}'}
                        values={{who}}
                    />
                )}
            </SidePanelHeader>
            <SidePanelContent>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={''}>
                        <FormSection
                            icon={faUser}
                            isOpenByDefault={true}
                            title={
                                <FormattedMessage
                                    id='message.admin.policy.studio'
                                    defaultMessage='Policy Studio'
                                />
                            }
                        >
                            {policyState.resource_type_id !== '' && (
                                <Row>
                                    <FormGroup className='col'>
                                        <Label className='form-control-label'>
                                            <FormattedMessage
                                                id='input.label.action'
                                                defaultMessage='Action'
                                            />
                                        </Label>
                                        <SelectInput
                                            isClearable={false}
                                            isSearchable={true}
                                            onValueChange={(v) =>
                                                editField(
                                                    'action_id',
                                                    v && v !== '' ? Number(v) : null
                                                )
                                            }
                                            options={resourceTypes
                                                .find((t) => {
                                                    return t.id === policyState.resource_type_id;
                                                })
                                                .actions.filter((a) =>
                                                    actionMessages.hasOwnProperty(a.name)
                                                )
                                                .map((a) => ({
                                                    label: intl.formatMessage(
                                                        get(
                                                            actionMessages,
                                                            a.name,
                                                            actionMessages.UNKNOWN
                                                        )
                                                    ),
                                                    value: a.id,
                                                }))}
                                            placeholder={intl.formatMessage({
                                                id: 'input.placeholder.action',
                                                defaultMessage:
                                                    'Which action should this policy allow on these resources?',
                                            })}
                                            value={policyState.action_id}
                                        />
                                    </FormGroup>
                                </Row>
                            )}
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='input.label.resource.type'
                                            defaultMessage='Resource Type'
                                        />
                                    </Label>
                                    <SelectInput
                                        isClearable={false}
                                        isSearchable={true}
                                        onValueChange={(v) =>
                                            editField(
                                                'resource_type_id',
                                                v && v !== '' ? Number(v) : null
                                            )
                                        }
                                        options={
                                            resourceTypes
                                                ? resourceTypes
                                                      .filter((t) =>
                                                          resourceTypeMessages.hasOwnProperty(
                                                              t.name
                                                          )
                                                      )
                                                      .map((t) => ({
                                                          value: Number(t.id),
                                                          label: intl.formatMessage(
                                                              get(
                                                                  resourceTypeMessages,
                                                                  t.name,
                                                                  resourceTypeMessages.UNKNOWN
                                                              )
                                                          ),
                                                      }))
                                                : {}
                                        }
                                        placeholder={intl.formatMessage({
                                            id: 'input.placeholder.resource.type',
                                            defaultMessage:
                                                'Which type of resource should this policy apply to?',
                                        })}
                                        value={policyState.resource_type_id}
                                    />
                                </FormGroup>
                            </Row>
                            {policyState.resource_type_id !== '' && (
                                <Row>
                                    <FormGroup className='col'>
                                        <Label className='form-control-label'>
                                            <FormattedMessage
                                                id='input.label.scope.type'
                                                defaultMessage='Scope Type'
                                            />
                                        </Label>
                                        <SelectInput
                                            isClearable={false}
                                            isSearchable={true}
                                            onValueChange={(v) => {
                                                editField(
                                                    'scope_type_id',
                                                    v && v !== '' ? Number(v) : null
                                                );
                                            }}
                                            options={resourceTypes
                                                .find((t) => t.id === policyState.resource_type_id)
                                                .scope_types.filter((t) =>
                                                    scopeMessages.hasOwnProperty(t.name)
                                                )
                                                .map((t) => ({
                                                    label: intl.formatMessage(
                                                        get(
                                                            scopeMessages,
                                                            t.name,
                                                            scopeMessages.UNKNOWN
                                                        )
                                                    ),
                                                    value: t.id,
                                                }))}
                                            placeholder={intl.formatMessage({
                                                id: 'input.placeholder.scope.type',
                                                defaultMessage:
                                                    'Within which type of scope should this policy apply to?',
                                            })}
                                            value={policyState.scope_type_id}
                                        />
                                    </FormGroup>
                                </Row>
                            )}
                            {ScopeComponent && (
                                <ScopeComponent
                                    values={policyState.scope_values}
                                    onValuesChange={(v) => {
                                        editField('scope_values', v);
                                    }}
                                />
                            )}
                        </FormSection>
                        {!!policy && (
                            <FormSection
                                icon={faCog}
                                isOpenByDefault={false}
                                title={
                                    <FormattedMessage
                                        id={'message.admin.advanced'}
                                        defaultMessage={'Advanced'}
                                    />
                                }
                            >
                                <FormattedMessage
                                    id='message.admin.delete.policy'
                                    defaultMessage={`Delete policy`}
                                >
                                    {(m) => (
                                        <Card>
                                            <CardBody>
                                                <h4 className={'text-danger'}>{m}</h4>
                                                <CardText className={'text-muted'}>
                                                    <small>
                                                        <FormattedMessage
                                                            id={
                                                                'message.admin.delete.policy.explanation'
                                                            }
                                                            defaultMessage={
                                                                'Any user this policy applies to will instantly lose the associated rights.'
                                                            }
                                                        />
                                                    </small>
                                                </CardText>
                                                <Button
                                                    color={'danger'}
                                                    size={'sm'}
                                                    onClick={() =>
                                                        setDeletePolicyModal(!deletePolicyModal)
                                                    }
                                                >
                                                    {m}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    )}
                                </FormattedMessage>
                                <DangerModal
                                    isOpen={deletePolicyModal}
                                    toggle={() => setDeletePolicyModal(!deletePolicyModal)}
                                    title={
                                        <FormattedMessage
                                            id={'message.admin.delete.policy'}
                                            defaultMessage={'Delete policy'}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id={'message.admin.delete.policy.confirmation'}
                                            defaultMessage={
                                                'Are you sure you wish to delete this policy? This operation cannot be undone.'
                                            }
                                        />
                                    }
                                    onClick={deletePolicy}
                                />
                            </FormSection>
                        )}
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'} disabled={!isValid()}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
});

PolicyForm.propTypes = {};

export default PolicyForm;
