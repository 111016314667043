/**
 * @param {string} value
 * Check for special characters in a string
 * Return true or false
 */
export const specialCharacters = (value) =>
    /[`!@#$%^&*()_+¨èéÈÉçÇÀà¬«»\-=[\]{};':"\\|,.<>/?~]/g.test(value);

/**
 * @param {string|number} value
 * Check if it is not a number
 * Return true or false
 */
export const notNumber = (value) => isNaN(value);

/**
 * @param {number} value
 * @param {*} max
 * @param {*} min
 * Limit the value from min to max, this avoids wrong information entered by the user
 * Return value, max or min
 */
export const limitValue = (value, max, min) => (value > max ? max : value < min ? min : value);
