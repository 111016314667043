import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChartPie,
    faHiking,
    faHistory,
    faInfoCircle,
    faLink,
    faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import CheckboxInput from '../../components/input/CheckboxInput';
import {Has} from '../../components/security/Has';
import AdminOnlyBadge from '../../components/notification/whats-new/AdminOnlyBadge';

const whats_new_en = [
    {
        date: '2022-01-27',
        changelog: [
            {
                title: 'Call Timeline',
                content: (
                    <>
                        You can now see a call's timeline in the <strong>Call history</strong>{' '}
                        section. The <strong>Information</strong> icon (
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={'text-primary'}
                            fixedWidth
                        />
                        ) allows you to obtain a detailed list of a call's events, such as wait time
                        and ring time.
                    </>
                ),
            },
            {
                title: 'Call Statistics',
                content: (
                    <>
                        The <strong>Reports</strong> section now contains new statistics about your
                        phone system, including a table showing hourly time spent on the phone for
                        each user.
                    </>
                ),
                has: {
                    aPolicyMatching: [{resourceType: 'QUEUE_EVENTS'}],
                },
            },
            {
                title: 'SMS/MMS via E-mail',
                content: (
                    <>
                        You can now send and receive SMS/MMS messages via e-mail.{' '}
                        <Has theRole={'administrator'}>
                            Head to the <strong>Settings</strong> section to bind your SMS/MMS phone
                            numbers to your users.
                        </Has>
                    </>
                ),
            },
        ],
    },
    {
        date: '2021-07-26',
        changelog: [
            {
                title: 'Advanced Filters',
                content: (
                    <>
                        You can now filter by multiple values of the same category in the{' '}
                        <strong> Call History</strong>. For instance, you can add multiple extension
                        filters to see calls made by any of the selected extensions. When adding
                        multiple tag filters, you can also use the Link/Unlink icon between your
                        filters (
                        <FontAwesomeIcon
                            icon={faLink}
                            className={'text-primary'}
                            fixedWidth
                            transform={{rotate: 45}}
                        />
                        /
                        <FontAwesomeIcon
                            icon={faUnlink}
                            className={'text-primary'}
                            fixedWidth
                            transform={{rotate: 45}}
                        />
                        ) to toggle whether they apply conjunctively (a call should be tagged with
                        all of the selected tags) or disjunctively (a call should be tagged with at
                        least one of the selected tags)
                    </>
                ),
            },
            {
                title: 'Time Sheets',
                content: (
                    <>
                        If you are using the login/logout and pause/unpause features of your phone
                        system queues, you can now head to the <strong>Reports</strong> section in
                        the sidebar (
                        <FontAwesomeIcon icon={faChartPie} className={'text-primary'} fixedWidth />)
                        and select <strong>Time Sheets</strong> to see a history of your users' time
                        spent in or out of queue.
                    </>
                ),
                has: {
                    aPolicyMatching: [{resourceType: 'QUEUE_EVENTS'}],
                },
            },
            {
                title: 'Zoho PhoneBridge Integration',
                content: (
                    <>
                        If you are using the Zoho CRM or Zoho Desk integrations, head towards the{' '}
                        <strong> Administration Panel</strong> to migrate to Zoho PhoneBridge.
                    </>
                ),
            },
            {
                title: 'General',
                content: 'Bug fixes and performance improvements',
            },
        ],
    },
    {
        date: '2021-03-15',
        changelog: [
            {
                title: 'Teams and policies',
                content: (
                    <>
                        You can now create teams in the <strong> Administration Panel</strong>. In
                        addition, you can now create policies and assign them to either a specific
                        user or a specific team. Policies allow you to determine if your users can
                        see various resources within the Croo Portal, such as calls, queue
                        statistics, user journeys, users, teams, integrations, etc.
                        <AdminOnlyBadge />
                    </>
                ),
                has: {theRole: 'administrator'},
            },
            {
                title: 'General',
                content: 'Bug fixes',
            },
        ],
    },
    {
        date: '2020-08-28',
        changelog: [
            {
                title: "'My Calls' Renamed",
                content: (
                    <>
                        You can now access your calls with the
                        <strong> Call History</strong> icon (
                        <FontAwesomeIcon icon={faHistory} className={'text-primary'} fixedWidth />)
                        in the sidebar.{' '}
                        <Has theRole={'administrator'}>
                            For administrators, you can quickly switch between your own calls and
                            all the calls in your system with the
                            <CheckboxInput
                                value={true}
                                inline
                                onValueChange={() => {}}
                                label={
                                    <FormattedMessage
                                        id={'toggle.show.only.my.calls'}
                                        defaultMessage={'Show only my calls'}
                                    />
                                }
                            />{' '}
                            switch.
                        </Has>
                    </>
                ),
            },
            {
                title: 'Statistics Dashboard',
                content: (
                    <>
                        If you are an administrator, your homepage has been replaced with the
                        <strong> Statistics Dashboard</strong>. You can now create graphs and tables
                        to gain insights into your phone system! Track calls over time, by user, and
                        set objectives to be met by your team.
                        <AdminOnlyBadge />
                    </>
                ),
                has: {theRole: 'administrator'},
            },
            {
                title: 'User Journeys',
                content: (
                    <>
                        If you are an administrator, you can now see a live timeline of your user's
                        activity! The <strong>User Journeys</strong> module allows you to see at a
                        glance the activity of each of your users, and to gain insights in their
                        schedule. You can access this module with the
                        <strong> User Journeys</strong> icon (
                        <FontAwesomeIcon icon={faHiking} className={'text-primary'} fixedWidth />)
                        in the sidebar.
                        <AdminOnlyBadge />
                    </>
                ),
                has: {theRole: 'administrator'},
            },
            {
                title: 'General',
                content: 'Bug fixes and performance improvements',
            },
        ],
    },
];

export default whats_new_en;
