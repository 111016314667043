import React from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getUserCustomerLicense} from '../../../store/selectors/user-selectors';
import ContentWrapper from '../../../components/content/ContentWrapper';
import CardContent from '../../../components/content/CardContent';
import JourneysList from '../../../components/journeys/JourneysList';

const Journeys = () => {
    const customerLicense: string = useSelector(getUserCustomerLicense);

    return (
        <ContentWrapper>
            <CardContent fullHeight scroll>
                {customerLicense === 'PREMIUM' || customerLicense === 'PRO' ? (
                    <Redirect
                        to={{
                            pathname: '/license',
                            state: {
                                access: 'journeys',
                            },
                        }}
                    />
                ) : (
                    <JourneysList />
                )}
            </CardContent>
        </ContentWrapper>
    );
};

export default Journeys;
