import React from 'react';

// Redux
import {useDispatch} from 'react-redux';

// Traduction
import {useIntl} from 'react-intl';
import {messages} from '../extensionTraduction';

// Danger modal
import DangerModal from 'components/notification/DangerModal';

// Store call backend
import {updateSipCustomerExtension} from 'store/actions/customer-action';

import {DefaultExtensionProps} from '../ExtensionForm';

const WarningSipModal = ({
    isOpen,
    toggleModal,
    currentExtension,
}: {
    isOpen: boolean;
    toggleModal: () => void;
    currentExtension: DefaultExtensionProps;
}) => {
    const intl = useIntl();
    const reduxDispatch = useDispatch();
    const generateSip = () => {
        const updateSip = {...currentExtension, sip_password: ''};
        reduxDispatch(updateSipCustomerExtension(updateSip));
        toggleModal();
    };

    return (
        <DangerModal
            isOpen={isOpen}
            toggle={() => toggleModal()}
            content={intl.formatMessage(messages.sipGenerate)}
            onClick={() => generateSip()}
        />
    );
};

export default WarningSipModal;
