import React, {useEffect, useMemo, useState} from 'react';
import {useObjectReducer} from 'utils/hooks';
import {Form, FormGroup, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {defineMessages, injectIntl, WrappedComponentProps} from 'react-intl';
import {getLoading} from 'store/selectors/portal-selectors';
import {useSelector} from 'react-redux';
import CheckboxInput from 'components/input/CheckboxInput';
import {getCustomerExtensions} from 'store/selectors/customer-selectors';
import isEqual from 'lodash.isequal';
import {CallReportsParametersType} from '../CallsReportsParameters';
import LightFontAwesomeIcon from '../../../../../components/utils/LightFontAwesomeIcon';

const ROWS_PER_PAGE = 15;

const messages = defineMessages({
    hide_empty_hours: {
        id: 'message.hide_empty_hours',
        defaultMessage: 'Hide Empty Hours',
    },
});

export type CallStatisticsByAgentParametersType = {
    hideEmptyHours: boolean;
    paginatedExtensions: string[];
};

const defaultParameters: CallStatisticsByAgentParametersType = {
    hideEmptyHours: true,
    paginatedExtensions: [],
};

interface CallStatisticsByAgentParametersProps extends WrappedComponentProps {
    onChange: (p: CallStatisticsByAgentParametersType) => void;
    parameters: CallStatisticsByAgentParametersType & CallReportsParametersType;
}

interface DetailPagination {
    [key: string]: {
        first: number;
        last: number;
    };
}

const CallStatisticsByAgentParameters = ({
    parameters: parentParameters,
    onChange,
    intl,
}: CallStatisticsByAgentParametersProps) => {
    const loading = useSelector(getLoading('queue_event_statistics'));
    const extensions = useSelector(getCustomerExtensions);
    const [pagination, setPagination] = useState<DetailPagination>({});
    const [page, setPage] = useState(1);
    const [parameters, dispatch] = useObjectReducer(defaultParameters);

    const filteredExtensions = useMemo(
        () =>
            parentParameters.extensions == null || parentParameters.extensions.length === 0
                ? Object.values(extensions).map((e: any) => e.extension)
                : [...parentParameters.extensions],
        [extensions, parentParameters.extensions]
    );

    const renderPagination = () => {
        const numberPagination = Math.ceil(filteredExtensions.length / ROWS_PER_PAGE);
        const render = [];

        let newPagination: DetailPagination = {};

        for (let i: number = 1; i <= numberPagination; i++) {
            if (i === 1) {
                newPagination = {
                    1: {
                        first: 0,
                        last: ROWS_PER_PAGE,
                    },
                };
            } else {
                newPagination = {
                    ...newPagination,
                    [i.toString()]: {
                        first: Number(newPagination[(i - 1).toString()].last),
                        last: Number(newPagination[(i - 1).toString()].last) + ROWS_PER_PAGE,
                    },
                };
            }
            render.push(
                <PaginationItem
                    key={i}
                    active={page === i || (i === numberPagination && page > numberPagination)}
                >
                    <PaginationLink href={'#'} id={i.toString()} onClick={() => handleClick(i)}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        if (!isEqual(pagination, newPagination)) {
            setPagination(newPagination);
        }
        return render;
    };

    const handleClick = (p: number) => {
        setPage(p);
    };

    useEffect(() => {
        onChange(parameters);
    }, [onChange, parameters]);

    useEffect(() => {
        if (Object.keys(pagination).length > 0) {
            const actualPage = Math.min(page, Object.keys(pagination).length);
            dispatch({
                type: 'edit',
                payload: {
                    field: 'paginatedExtensions',
                    value: filteredExtensions.slice(
                        pagination[actualPage.toString()].first,
                        pagination[actualPage.toString()].last
                    ),
                },
            });
        }
    }, [dispatch, filteredExtensions, page, pagination]);

    return (
        <Form
            inline
            className={`d-flex flex-column flex-lg-row justify-content-between align-items-stretch align-items-lg-baseline`}
        >
            <FormGroup check inline>
                <CheckboxInput
                    label={intl.formatMessage(messages.hide_empty_hours)}
                    value={parameters.hideEmptyHours}
                    enabled={!loading}
                    onValueChange={(value: boolean) =>
                        dispatch({type: 'edit', payload: {field: 'hideEmptyHours', value}})
                    }
                    id={'hide-empty-hours'}
                />
            </FormGroup>
            <FormGroup inline check>
                <Pagination size='sm' className={'ml-2'}>
                    <PaginationItem>
                        <PaginationLink
                            href={'#'}
                            previous
                            onClick={() => {
                                setPage((p) => (p > 1 ? p - 1 : p));
                                handleClick(page > 1 ? page - 1 : page);
                            }}
                        >
                            <LightFontAwesomeIcon icon={'angle-left'} />
                        </PaginationLink>
                    </PaginationItem>
                    {renderPagination()}
                    <PaginationItem>
                        <PaginationLink
                            href={'#'}
                            next
                            onClick={() => {
                                setPage((p) => (p < Object.keys(pagination).length ? p + 1 : p));
                                handleClick(
                                    page < Object.keys(pagination).length ? page + 1 : page
                                );
                            }}
                        >
                            <LightFontAwesomeIcon icon={'angle-right'} />
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </FormGroup>
        </Form>
    );
};

export default injectIntl(CallStatisticsByAgentParameters);
