import React, {useMemo, useState} from 'react';
import CardContent from '../../../components/content/CardContent';
import {CardBody, CardHeader} from 'reactstrap';
import {ComponentReportRoute} from './routes';
import ReportFetcher from './ReportFetcher';
import isEqual from 'lodash.isequal';

type ReportComponentProps = {
    route: ComponentReportRoute;
    parentParameters: {[k: string]: any};
};

const ReportComponent = ({route, parentParameters}: ReportComponentProps) => {
    const [parameters, setParameters] = useState(route.defaultParameters || {});

    const mergedParameters = useMemo(() => {
        return {...parentParameters, ...parameters};
    }, [parentParameters, parameters]);

    return (
        <>
            <CardContent fullHeight={route.fullHeight}>
                <CardHeader
                    className={`border-bottom-0 d-flex flex-row justify-content-between align-items-baseline`}
                >
                    <h2 className={'ml-1 mr-3'}>{route.title}</h2>
                    {route.parameters && (
                        <route.parameters
                            onChange={(newParameters: any) =>
                                setParameters((oldParameters: any) =>
                                    isEqual(oldParameters, newParameters)
                                        ? oldParameters
                                        : newParameters
                                )
                            }
                            parameters={mergedParameters}
                        />
                    )}
                </CardHeader>
                <CardBody
                    style={{overflow: 'auto'}}
                    className={`d-flex flex-column flex-grow-1 p-0 ${route.className}`}
                >
                    <ReportFetcher route={route} parameters={mergedParameters} />
                </CardBody>
            </CardContent>
        </>
    );
};

export default ReportComponent;
