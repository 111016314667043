import {useEffect, useReducer, useState} from 'react';
import {Alert, Button, CardBody, CardHeader, Col, Form, FormGroup, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {
    cognitoChangePassword,
    fetchUserLiteCredentials,
    updateProfile,
    updateUserLiteCredentials,
} from '../../store/actions/user-action';
import isEqual from 'lodash.isequal';
import {getUserLiteCredentials, userProfileSelector} from '../../store/selectors/user-selectors';
import ContentWrapper from '../../components/content/ContentWrapper';
import CardContent from '../../components/content/CardContent';
import ChangePasswordPanel from './ChangePasswordModal';
import {infoAlert} from '../../store/actions/portal-action';
import UserAvatar from '../../components/user/UserAvatar';
import {getCustomerLanguages} from '../../store/selectors/customer-selectors';
import {fetchCustomerLanguages} from '../../store/actions/customer-action';
import TextInput from '../../components/input/TextInput';
import SelectInput from '../../components/input/SelectInput';
import CheckboxInput from '../../components/input/CheckboxInput';
import momentTZ from 'moment-timezone';
import Spinner from '../../components/spinner/Spinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faApple, faWindows} from '@fortawesome/free-brands-svg-icons';

const UserProfile = () => {
    const profile = useSelector(userProfileSelector);
    const languages = useSelector(getCustomerLanguages);
    const liteProfile = useSelector(getUserLiteCredentials);

    const profile_to_state = (profile) => {
        return {
            nickname: profile.nickname,
            full_name: profile.full_name,
            email: profile.email,
            language_id: profile.language.id.toString(),
            timezone: profile.timezone,
            missed_calls_notification: profile.missed_calls_notification,
        };
    };

    const state_to_profile = (state) => ({
        nickname: state.nickname,
        full_name: state.full_name,
        email: state.email,
        language_id: Number(state.language_id),
        timezone: state.timezone,
        missed_calls_notification: state.missed_calls_notification,
    });

    const [changePasswordOpen, setChangePasswordOpen] = useState(false);

    const [state, dispatch] = useReducer((state, action) => {
        if (state[action.type] === action.payload) return state;
        const newState = {...state};
        newState[action.type] = action.payload;
        return newState;
    }, profile_to_state(profile));

    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch(fetchCustomerLanguages());
        reduxDispatch(fetchUserLiteCredentials(profile));
    }, [reduxDispatch, profile]);

    const editField = (name, value) => dispatch({type: name, payload: value});

    const submit = (event) => {
        event.preventDefault();
        if (!isEqual(profile, state_to_profile(state))) {
            reduxDispatch(updateProfile(state_to_profile(state)));
        } else {
            reduxDispatch(infoAlert({title: 'No changes'}));
        }
    };

    function getTimeZonesWithDefault() {
        let timezones = momentTZ.tz.names().map((t) => ({value: t, label: t}));
        timezones.push(defaultTimeZone());
        return timezones.sort();
    }

    const defaultTimeZone = () => ({
        value: 'NULL',
        label: 'Default',
    });

    const updateLiteCredentials = () => {
        reduxDispatch(updateUserLiteCredentials(profile));
    };

    return (
        <>
            <ContentWrapper limitedWidth={true} scroll={true} fullHeight={true}>
                <div className='d-flex flex-row align-items-center mb-4'>
                    <h1 className='ml-4 text-primary'>
                        {state.nickname ? (
                            <FormattedMessage
                                id='profile.header.hey.user'
                                defaultMessage={`Hey {user_name}!`}
                                values={{user_name: state.nickname}}
                            />
                        ) : (
                            <FormattedMessage
                                id='profile.header.hey.unknown'
                                defaultMessage={`Hey there!`}
                            />
                        )}
                    </h1>
                </div>
                <Row
                    className='justify-content-center'
                    style={{marginTop: -37, marginBottom: -37, zIndex: 1030}}
                >
                    <UserAvatar
                        className={'rounded-circle border border-white'}
                        user={state}
                        size={'xl'}
                    />
                </Row>
                <CardContent style={{overflowY: 'visible', paddingTop: '1.5rem'}}>
                    <CardHeader>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage
                                id='profile.header.profile'
                                defaultMessage={'Your Profile'}
                            />
                        </h2>
                    </CardHeader>
                    <CardBody className={'pt-2'}>
                        <Form onSubmit={submit} className={'form-sm'}>
                            <Row>
                                <FormGroup className={'col'}>
                                    <Label for='nickname' className='form-control-label'>
                                        <FormattedMessage
                                            id='profile.user.nickname'
                                            defaultMessage='Nickname'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.nickname'
                                        defaultMessage='How should we call you?'
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('nickname', v)}
                                                placeholder={m}
                                                value={state.nickname}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className={'col'}>
                                    <Label for='full_name' className='form-control-label'>
                                        <FormattedMessage
                                            id='profile.user.full_name'
                                            defaultMessage='Full name'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.full_name'
                                        defaultMessage='Your full name'
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('full_name', v)}
                                                placeholder={m}
                                                value={state.full_name}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className={'col'}>
                                    <Label for='email' className='form-control-label'>
                                        <FormattedMessage
                                            id='profile.user.email'
                                            defaultMessage='Email'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.email'
                                        defaultMessage='Your email address'
                                    >
                                        {(m) => (
                                            <TextInput
                                                onValueChange={(v) => editField('email', v)}
                                                placeholder={m}
                                                value={state.email}
                                                enabled={false}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className={'col'}>
                                    <Label for='language' className='form-control-label'>
                                        <FormattedMessage
                                            id='profile.user.language'
                                            defaultMessage='Language'
                                        />
                                    </Label>
                                    <SelectInput
                                        isClearable={false}
                                        isSearchable={false}
                                        onValueChange={(v) =>
                                            v && editField('language_id', Number(v))
                                        }
                                        options={languages.map((language) => ({
                                            value: language.id.toString(),
                                            label: language.name,
                                        }))}
                                        value={state.language_id.toString()}
                                    />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className={'col'}>
                                    <Label for='timezone' className='form-control-label'>
                                        <FormattedMessage
                                            id='profile.user.timezone'
                                            defaultMessage='Timezone'
                                        />
                                    </Label>
                                    <FormattedMessage
                                        id='input.placeholder.timezone'
                                        defaultMessage='Timezone'
                                    >
                                        {(m) => (
                                            <SelectInput
                                                options={getTimeZonesWithDefault()}
                                                onValueChange={(v) => {
                                                    if (v) {
                                                        editField('timezone', v);
                                                    }
                                                }}
                                                placeholder={m}
                                                value={state.timezone}
                                            />
                                        )}
                                    </FormattedMessage>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup
                                    className={
                                        'col flex-row justify-content-between align-items-left'
                                    }
                                >
                                    <Label for='missedCalls' className='form-control-label'>
                                        <FormattedMessage
                                            id='profile.user.missedCallsNotifications'
                                            defaultMessage='Additional options'
                                        />
                                    </Label>
                                    <CheckboxInput
                                        className={'w-100 d-flex justify-content-between'}
                                        id={'missed-calls-notification'}
                                        size={'sm'}
                                        label={
                                            <FormattedMessage
                                                id='profile.form.missedCallsNotifications'
                                                defaultMessage={'Missed calls email notification'}
                                            />
                                        }
                                        value={state.missed_calls_notification}
                                        onValueChange={(v) =>
                                            editField('missed_calls_notification', v)
                                        }
                                    />
                                </FormGroup>
                            </Row>
                            <Row className='justify-content-between mt-4'>
                                <Col className='flex-grow-0 align-self-end'>
                                    <FormattedMessage
                                        id='profile.form.btn.password'
                                        defaultMessage='Change password'
                                    >
                                        {(m) => (
                                            <Button
                                                size={'sm'}
                                                color='link'
                                                onClick={() =>
                                                    setChangePasswordOpen(!changePasswordOpen)
                                                }
                                            >
                                                {m}
                                            </Button>
                                        )}
                                    </FormattedMessage>
                                </Col>
                                <Col className='flex-grow-0 align-self-end'>
                                    <FormattedMessage
                                        id='profile.form.btn.save'
                                        defaultMessage='Save'
                                    >
                                        {(m) => (
                                            <Button size={'sm'} color='primary' type='submit'>
                                                {m}
                                            </Button>
                                        )}
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </CardContent>
                <CardContent style={{overflowY: 'visible'}}>
                    <CardHeader>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage
                                id='title.litedownload'
                                defaultMessage={'Download Croo Talk Lite'}
                            />
                        </h2>
                    </CardHeader>
                    <CardBody className={'pt-2'}>
                        <Row>
                            <FormGroup className='col mb-1'>
                                <Label className='form-control-label mb--2'>
                                    <FormattedMessage
                                        id='input.label.username'
                                        defaultMessage='Username'
                                    />
                                </Label>
                                <FormattedMessage
                                    id='input.placeholder.username'
                                    defaultMessage='Your username'
                                >
                                    {(m) => (
                                        <TextInput
                                            placeholder={m}
                                            value={state.email}
                                            enabled={!!!state}
                                            onValueChange={() => {}}
                                        />
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className='form-control-label mb--2'>
                                        <FormattedMessage
                                            id='input.label.password'
                                            defaultMessage='Password'
                                        />
                                    </Label>
                                    <div className={'d-flex w-100 ml--3'}>
                                        <div className={'col pr-2'}>
                                            <FormattedMessage
                                                id='input.placeholder.password'
                                                defaultMessage='Password'
                                            >
                                                {(m) => (
                                                    <TextInput
                                                        placeholder={m}
                                                        value={liteProfile?.password}
                                                        enabled={!!!state}
                                                        onValueChange={() => {}}
                                                    />
                                                )}
                                            </FormattedMessage>
                                        </div>
                                        {!liteProfile?.password ? (
                                            <div className={'d-flex flex-column mt-1 pr-4 pl-4'}>
                                                <Spinner
                                                    color={'primary'}
                                                    background={'transparent'}
                                                    global={false}
                                                    size={20}
                                                />
                                            </div>
                                        ) : (
                                            <Button
                                                color={'primary'}
                                                size={'sm'}
                                                className={'mr--3'}
                                                onClick={updateLiteCredentials}
                                            >
                                                <i className={'fa fa-sync pr-1'} />
                                                <FormattedMessage
                                                    id='btn.reset'
                                                    defaultMessage='Reset'
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert className={'w-100 border'} color={'transparent'}>
                                    <div className={'my-4 d-flex flex-column align-items-center'}>
                                        <span className={'w-75 text-center'}>
                                            <FormattedMessage
                                                id={'msg.download.croo.talk.lite'}
                                                defaultMessage={
                                                    'Download Croo Talk Lite now to make calls and send text messages on your computer, and use the credentials above to log in.'
                                                }
                                            />
                                        </span>
                                        <div className={'mt-2'}>
                                            <a
                                                href={
                                                    'https://app.croo.io/downloads/CrooTalkLite_win_x86_latest.exe'
                                                }
                                                target={'_blank'}
                                                rel='noreferrer'
                                                className={'mr-2'}
                                            >
                                                <Button
                                                    size={'sm'}
                                                    className={'text-white'}
                                                    style={{backgroundColor: '#00A4EF'}}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faWindows}
                                                        className={'mr-1'}
                                                    />
                                                    <FormattedMessage
                                                        id={'msg.for.windows'}
                                                        defaultMessage={'For Windows'}
                                                    />
                                                </Button>
                                            </a>
                                            <a
                                                href={
                                                    'https://app.croo.io/downloads/CrooTalkLite_mac_intel_latest.dmg'
                                                }
                                                target={'_blank'}
                                                rel='noreferrer'
                                            >
                                                <Button
                                                    size={'sm'}
                                                    className={'text-white'}
                                                    style={{backgroundColor: '#000000'}}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faApple}
                                                        className={'mr-1'}
                                                    />
                                                    <FormattedMessage
                                                        id={'msg.for.mac'}
                                                        defaultMessage={'For MacOS'}
                                                    />
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                </Alert>
                            </Col>
                        </Row>

                        <ChangePasswordPanel
                            isOpen={changePasswordOpen}
                            setOpen={setChangePasswordOpen}
                            changePassword={(args) => reduxDispatch(cognitoChangePassword(args))}
                        />
                    </CardBody>
                </CardContent>
            </ContentWrapper>
        </>
    );
};

export default UserProfile;
