export const generate = (csvContainer, extensions, moment, url) => {
    let csvHead = [
        'direction',
        'action',
        'action_time',
        'call_start_time',
        'call_id',
        'extension',
        'extension_name',
        'caller_id_name',
        'duration',
        'from_number',
        'to_number',
        'url',
        'tags',
    ];
    let csvList = [];
    csvList.push(csvHead);

    for (let i = 0; i < csvContainer.length; i++) {
        let record = csvContainer[i];

        let entries = record.data?.hits?.hits;

        for (let j = 0; j < entries.length; j++) {
            let line_record = entries[j];
            let caller_id_name = line_record?._source?.caller_id_name;

            let extension_name = extensions[line_record?._source?.extension]?.name;
            let call_start_time = moment(line_record?._source?.call_start_time)
                .locale('en')
                .format('LL, hh:mm A');

            let link = `${url}?id=${line_record?._source?.call_id}`;
            csvList.push([
                line_record?._source?.direction,
                line_record?._source?.action,
                line_record?._source?.action_time,
                call_start_time,
                line_record?._source?.call_id,
                line_record?._source?.extension,
                extension_name,
                caller_id_name,
                line_record?._source?.duration,
                line_record?._source?.from_number,
                line_record?._source?.to_number,
                link,
                line_record?._source?.tags,
            ]);
        }
    }

    return csvList;
};
