import React, { useState } from 'react';

const HoverAnimation = (
    {
        animation,
        className,
        children,
        speed
    }: {
        animation: string,
        className?: string,
        children: React.ReactElement,
        speed: 'delay-1s'| 'delay-2s'| 'delay-3s'| 'delay-4s'| 'delay-5s'| 'fast'| 'faster'| 'slow'| 'slower'
    }) => {
    const [hover, setHover] = useState(false);

    return <span
        className={`${hover ? `animated ${animation} ${speed}` : ''} ${className}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        {children}
    </span>;
};

HoverAnimation.defaultProps = {
    speed: 'fast',
};

export default HoverAnimation;