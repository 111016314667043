import React, {useEffect, useReducer, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {customerExtensionsSelector} from 'store/selectors/customer-selectors';
import ExtensionForm from './ExtensionForm';
import {
    fetchCustomerExtensions,
    fetchCustomerExtensionSettings,
} from 'store/actions/customer-action';
import {Table} from 'components/layout/Table/Table';
import ContentWrapper from 'components/content/ContentWrapper';
import CardContent from 'components/content/CardContent';
import SidePanel from 'components/panel/SidePanel';
import LightFontAwesomeIcon from 'components/utils/LightFontAwesomeIcon';
import {Action, ActionsCell} from 'components/layout/Table/Cells';
import {getLoading} from 'store/selectors/portal-selectors';
import Spinner from 'components/spinner/Spinner';
import {Has} from 'components/security/Has';
import SearchBar from 'components/search/SearchBar';
import isEqual from 'lodash.isequal';
import {messages} from './extensionTraduction';

const columns = (intl) => [
    {
        id: 'extension_number',
        title: intl.formatMessage(messages.titleTableExtensionNumber),
        key: 'extension',
        sort: {key: 'extension'},
        width: 'minmax(200px, 300px)',
    },
    {
        id: 'name',
        title: intl.formatMessage(messages.titleTableExtensionName),
        key: 'name',
        sort: {key: 'name'},
        width: 'auto',
    },
];

const Extensions = () => {
    const reduxDispatch = useDispatch();
    const intl = useIntl();

    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [extension, setExtension] = useState(null);

    const customerExtensions = Object.values(useSelector(customerExtensionsSelector));
    const loading = useSelector(getLoading('extensions'));

    const [foundExtensions, foundExtensionsDispatch] = useReducer((state, action) => {
        const found = action.payload;
        return isEqual(state, found) ? state : found;
    }, customerExtensions);

    useEffect(() => {
        reduxDispatch(fetchCustomerExtensions());
    }, [reduxDispatch]);

    const create = () => {
        setExtension(null);
        setSidePanelOpen(true);
    };

    const cancel = () => {
        setExtension(null);
        setSidePanelOpen(false);
    };

    const edit = (extension_number) => {
        setExtension(extension_number);
        reduxDispatch(fetchCustomerExtensionSettings({extension: extension_number.extension}));
        setSidePanelOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='table-header no-select'>
                        <h2 className='mb-0 mr-2'>
                            {intl.formatMessage(messages.titleTableExtensions)}
                        </h2>
                        <Has aPolicyMatching={[{action: 'WRITE', resourceType: 'EXTENSIONS'}]}>
                            <Button
                                className={'align-self-center mr-auto'}
                                color={'primary'}
                                onClick={create}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                        <SearchBar
                            fields={['extension', 'name']}
                            items={customerExtensions}
                            live
                            minimumCharacters={1}
                            placeholder={intl.formatMessage(messages.inputSearch)}
                            setFoundItems={(f) => foundExtensionsDispatch({payload: f})}
                        />
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1 p-0'}
                    >
                        {loading ? (
                            <Spinner
                                centered
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <Table
                                itemSize={55}
                                columns={[
                                    ...columns(intl),
                                    {
                                        id: 'qrcode',
                                        title: '',
                                        width: '80px',
                                        className: 'justify-content-end',
                                        cellFactory: (extension_number) => (
                                            <ActionsCell>
                                                <Has
                                                    aPolicyMatching={[
                                                        {
                                                            action: 'WRITE',
                                                            resourceType: 'EXTENSIONS',
                                                        },
                                                    ]}
                                                >
                                                    <Action
                                                        onClick={() => edit(extension_number)}
                                                        icon={'pen'}
                                                    />
                                                </Has>
                                            </ActionsCell>
                                        ),
                                    },
                                ]}
                                items={foundExtensions}
                                itemsDispatch={(items) => foundExtensionsDispatch({payload: items})}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={isSidePanelOpen}
                className={'bg-white'}
                onGlassClick={cancel}
                setOpen={setSidePanelOpen}
            >
                <ExtensionForm tempExtension={extension} toggle={cancel} />
            </SidePanel>
        </>
    );
};

export default Extensions;
