import {createSelector} from "reselect";

export const getPortalAlert = state => state.portal.alert;
export const getPortalToasts = state => state.portal.toasts;
export const getPortalAudio = state => state.portal.audio;
export const getPortalAudioShow = state => state.portal.audio.show;
export const getPortalAudioOwner = state => state.portal.audio.owner;
export const getLoadings = state => state.portal.loading;
export const getLoadedOnces = state => state.portal.loaded_once;
export const getLoading = (id, defaultValue= false) => createSelector(
    getLoadings,
    loadings => loadings.hasOwnProperty(id) ? loadings[id] : defaultValue
);
export const getLoadedOnce = (id) => createSelector(
    getLoadedOnces,
    loaded_onces => loaded_onces[id] === true
);

export const getLoadingPattern = (pattern, defaultValue) => createSelector(
    getLoadings,
    loadings => {
        const matching_keys = Object.keys(loadings).filter(k => new RegExp(pattern).test(k))
        return matching_keys.length > 0 ? matching_keys.some(k => loadings[k]) : defaultValue;
    }
);
