import React, {forwardRef, ReactNode} from 'react';
import {Input, InputGroup, InputProps} from 'reactstrap';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import moment from 'moment';
import DatePicker, {registerLocale} from 'react-datepicker';
import './dateInput.css';
import fr from 'date-fns/locale/fr';
import 'moment/locale/fr';

registerLocale('fr', fr);

const DateInputField = forwardRef<Input, InputProps>(({value, onClick}, ref) => {
    return (
        <InputGroup size={'sm'}>
            <Input value={value} onClick={onClick} bsSize={'sm'} ref={ref} onChange={() => {}} />
        </InputGroup>
    );
});

const CalendarContainer = ({
    className,
    children,
}: {
    className: string | undefined;
    children: ReactNode[];
}) => {
    return <div className={className}>{children}</div>;
};

type DateInputProps = {
    enabled?: boolean;
    onValueChange: (d: string) => void;
    onValidityChange?: (v: boolean) => void;
    placeholder?: string;
    dateFormat?: string;
    validator?: (d: string) => boolean;
    limit?: number;
    value: string;
} & WrappedComponentProps;

export const DateInput = injectIntl(
    ({
        enabled,
        intl,
        onValidityChange,
        onValueChange,
        dateFormat,
        placeholder,
        validator,
        value,
    }: DateInputProps) => {
        const date = moment(value).isValid() ? moment(value).toDate() : new Date();

        return (
            <DatePicker
                selected={date}
                onChange={(d: Date) => {
                    onValueChange(moment(d).format(dateFormat));
                    onValidityChange &&
                        validator &&
                        onValidityChange(validator(moment(d).format(dateFormat)));
                }}
                placeholderText={placeholder}
                locale={intl.locale}
                disabled={!enabled}
                customInput={<DateInputField />}
                calendarContainer={CalendarContainer}
            />
        );
    }
);

export type DateRangeInputProps = {
    enabled?: boolean;
    onValueChange: (d: [string, string]) => void;
    onValidityChange?: (v: boolean) => void;
    startPlaceholder?: string;
    endPlaceholder?: string;
    dateFormat?: string;
    validator?: (d: [string, string]) => boolean;
    limit?: number;
    value: [string, string];
} & WrappedComponentProps;

export const DateRangeInput = injectIntl(
    ({
        enabled,
        intl,
        onValueChange,
        onValidityChange,
        startPlaceholder,
        endPlaceholder,
        dateFormat,
        validator,
        value,
        limit,
    }: DateRangeInputProps) => {
        let startDate = moment(value[0]).isValid() ? moment(value[0]).toDate() : new Date();
        let endDate = moment(value[1]).isValid() ? moment(value[1]).toDate() : new Date();

        return (
            <>
                <DatePicker
                    selected={startDate}
                    selectsStart
                    onChange={(d: Date) => {
                        if (limit && moment(d).add(limit, 'days').isBefore(endDate)) {
                            endDate = moment(d).add(limit, 'days').toDate();
                        } else if (moment(d).isAfter(endDate)) {
                            endDate = moment(d).add(1, 'day').toDate();
                        }
                        onValueChange([
                            moment(d).format(dateFormat),
                            moment(endDate).format(dateFormat),
                        ]);
                        onValidityChange &&
                            validator &&
                            onValidityChange(
                                validator([
                                    moment(d).format(dateFormat),
                                    moment(endDate).format(dateFormat),
                                ])
                            );
                    }}
                    placeholderText={startPlaceholder}
                    locale={intl.locale}
                    disabled={!enabled}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={2}
                    customInput={<DateInputField />}
                    calendarContainer={CalendarContainer}
                />
                <span className={'mx-2 d-flex align-items-center'}>
                    <FormattedMessage id={'message.and'} defaultMessage={'and'} />
                </span>
                <DatePicker
                    selected={endDate}
                    selectsEnd
                    onChange={(d: Date) => {
                        if (limit && moment(d).subtract(limit, 'days').isAfter(startDate)) {
                            startDate = moment(d).subtract(limit, 'days').toDate();
                        } else if (moment(d).isBefore(startDate)) {
                            startDate = moment(d).subtract(1, 'day').toDate();
                        }
                        onValueChange([
                            moment(startDate).format(dateFormat),
                            moment(d).format(dateFormat),
                        ]);
                        onValidityChange &&
                            validator &&
                            onValidityChange(
                                validator([
                                    moment(startDate).format(dateFormat),
                                    moment(d).format(dateFormat),
                                ])
                            );
                    }}
                    placeholderText={endPlaceholder}
                    locale={intl.locale}
                    disabled={!enabled}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={2}
                    customInput={<DateInputField />}
                    calendarContainer={CalendarContainer}
                />
            </>
        );
    }
);

DateRangeInput.defaultProps = {
    dateFormat: 'YYYY-MM-DDTHH:mm:ss',
    enabled: true,
    endPlaceholder: 'To',
    onValidityChange: () => true,
    startPlaceholder: 'From',
    validator: () => true,
};

DateInput.defaultProps = {
    dateFormat: 'YYYY-MM-DDTHH:mm:ss',
    enabled: true,
    onValidityChange: () => true,
    placeholder: 'Date',
    validator: () => true,
    value: '',
};
