import React from 'react';

interface SidePanelHeaderProps {
    className?: string;
    children?: any;
    reversed?: boolean;
}

const SidePanelHeader = ({className, children, reversed}: SidePanelHeaderProps) => (
    <div
        className={`${className} d-flex flex-row bg-${reversed ? 'gradient-primary' : 'white'} ${
            reversed ? '' : 'border-bottom'
        } py-3 pl-3`}
        style={{height: 70}}
    >
        <h2 className={`${reversed ? 'text-white' : ''} my-0`}>{children}</h2>
    </div>
);

SidePanelHeader.propTypes = {};

export default SidePanelHeader;
