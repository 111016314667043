import {arrayDiff} from "./index";
import isEqual from 'lodash.isequal'

export class MultiActions {
    constructor(getItemId, actionFactories) {
        this.actions = {};
        this.getItemId = getItemId;
        this.idComparator = (x, y) => this.getItemId(x) === this.getItemId(y);
        this.actionFactories = actionFactories;
    }

    create(item) {
        this.actions[this.getItemId(item)] = (this.actionFactories.c(item))
        return this;
    }

    update(item) {
        this.actions[this.getItemId(item)] = (this.actionFactories.u(item))
        return this;
    }

    delete(item) {
        this.actions[this.getItemId(item)] =(this.actionFactories.d(item))
        return this;
    }

    fromDiff(oldItems, newItems) {
        const diff = arrayDiff(oldItems, newItems, this.idComparator, isEqual)
        diff.added.forEach(item => this.create(item));
        diff.edited.forEach(item => this.update(item));
        diff.removed.forEach(item => this.delete(item));
        return this;
    }

    getActions() {
        return Object.values(this.actions)
    }

    edit(f) {
        Object.keys(this.actions).forEach(k => this.actions[k].payload = f(this.actions[k].payload))
        return this
    }
}