import React, {useCallback, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Button,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Nav,
    NavItem,
    NavLink,
    Table,
} from 'reactstrap';
import {CallReportsParametersType} from '../CallsReportsParameters';
import {useSelector} from 'react-redux';
import {getSingleWidgetData} from '../../../../../store/selectors/cdr-selectors';
import {customerExtensionsSelector} from '../../../../../store/selectors/customer-selectors';
import {getLoading} from '../../../../../store/selectors/portal-selectors';
import moment from 'moment';
import {formatAlphanumericString, formatDuration} from '../../../../../utils/format';
import {Link} from 'react-router-dom';
import Spinner from '../../../../../components/spinner/Spinner';
import {CallStatisticsAllParametersType} from './CallStatisticsAllParameters';
import isEqual from 'lodash.isequal';

type ReportMode = 'in' | 'out' | 'combined';

const reportModeDirections: Record<ReportMode, string[]> = {
    in: ['in'],
    out: ['out'],
    combined: ['in', 'out'],
};

const reportModeActions: Record<ReportMode, string[]> = {
    in: ['hanged', 'missed'],
    out: ['hanged', 'not connected'],
    combined: ['hanged', 'missed', 'not connected'],
};

const reportModeHangedActions: Record<ReportMode, string[]> = {
    in: ['hanged'],
    out: ['hanged'],
    combined: ['hanged'],
};

const reportModeMissedActions: Record<ReportMode, string[]> = {
    in: ['missed'],
    out: ['not connected'],
    combined: ['missed', 'not connected'],
};

type ElasticSearchReport = {
    total: {
        by_direction: {
            buckets: {
                key: string;
                doc_count: number;
                by_action: {
                    buckets: {
                        key: string;
                        doc_count: number;
                        duration: {
                            value: number;
                        };
                    }[];
                };
            }[];
        };
    };
    hits: {
        total: {
            value: number;
        };
    };
    aggregations: {
        by_extension?: {
            buckets: {
                key: string;
                doc_count: number;
                by_direction: {
                    buckets: {
                        key: string;
                        doc_count: number;
                        by_action: {
                            buckets: {
                                key: string;
                                doc_count: number;
                                duration: {
                                    value: number;
                                };
                            }[];
                        };
                    }[];
                };
            }[];
        };
        duration: {
            value: number;
        };
    };
};

type Action = {
    label: string;
    duration: number;
    count: number;
};

type ActionsRecord = Record<string, Action>;

export type Direction = {
    label: string;
    duration: number;
    count: number;
    actions: ActionsRecord;
};

type DirectionsRecord = Record<string, Direction>;

export type Extension = {
    label: string;
    extension: string;
    duration: number;
    count: number;
    directions: DirectionsRecord;
};

type ExtensionsRecord = Record<string, Extension>;

type FormattedReport = {
    count: number;
    hanged: number;
    missed: number;
    duration: number;
    extensions: ExtensionsRecord;
};

type TableRow = {
    label: string;
    total: number;
    hanged: number;
    missed: number;
    missedPercentage: string;
    totalDuration: string;
    averageDuration: string;
    percentageDuration: string;
};

const formatReport = (
    elasticSearchReport: ElasticSearchReport,
    mode: ReportMode,
    extensions: any[],
    paginatedExtensions: string[]
) => {
    const report: FormattedReport = {
        count: 0,
        hanged: 0,
        missed: 0,
        duration: 0,
        extensions: {},
    };

    elasticSearchReport?.total?.by_direction?.buckets
        .filter((directionBucket) => reportModeDirections[mode].includes(directionBucket.key))
        .forEach((directionBucket) => {
            directionBucket.by_action.buckets.forEach((actionBucket) => {
                if (reportModeHangedActions[mode].includes(actionBucket.key)) {
                    report.count += actionBucket.doc_count;
                    report.hanged += actionBucket.doc_count;
                    report.duration += actionBucket.duration.value;
                }
                if (reportModeMissedActions[mode].includes(actionBucket.key)) {
                    report.missed += actionBucket.doc_count;
                    report.count += actionBucket.doc_count;
                }
            });
        });

    const extensionsRecord: ExtensionsRecord = {};

    paginatedExtensions?.forEach((paginatedExtension) => {
        if (
            !elasticSearchReport?.aggregations?.by_extension?.buckets.some(
                (bucket) => bucket.key === paginatedExtension
            )
        ) {
            extensionsRecord[paginatedExtension] = {
                label:
                    extensions.find((agent) => agent.extension === paginatedExtension)?.name ||
                    'Unknown',
                extension: paginatedExtension,
                count: 0,
                duration: 0,
                directions: {},
            };
        }
    });

    elasticSearchReport?.aggregations?.by_extension?.buckets.forEach((extensionBucket) => {
        extensionsRecord[extensionBucket.key] = {
            label:
                extensions.find((agent) => agent.extension === extensionBucket.key)?.name ||
                'Unknown',
            extension: extensionBucket.key,
            count: 0,
            duration: 0,
            directions: {},
        };

        const directionsRecord: DirectionsRecord = {};
        extensionBucket.by_direction.buckets
            .filter((directionBucket) => reportModeDirections[mode].includes(directionBucket.key))
            .forEach((directionBucket) => {
                directionsRecord[directionBucket.key] = {
                    label: directionBucket.key,
                    count: 0,
                    duration: 0,
                    actions: {},
                };

                const actionsRecord: ActionsRecord = {};
                directionBucket.by_action.buckets
                    .filter((actionBucket) => reportModeActions[mode].includes(actionBucket.key))
                    .forEach((actionBucket) => {
                        actionsRecord[actionBucket.key] = {
                            label: actionBucket.key,
                            count: actionBucket.doc_count,
                            duration: actionBucket.duration.value,
                        };
                        if (reportModeActions[mode].includes(actionBucket.key)) {
                            directionsRecord[directionBucket.key].count +=
                                actionsRecord[actionBucket.key].count;
                            if (reportModeHangedActions[mode].includes(actionBucket.key)) {
                                directionsRecord[directionBucket.key].duration +=
                                    actionsRecord[actionBucket.key].duration;
                            }
                        }
                    });
                if (reportModeDirections[mode].includes(directionBucket.key)) {
                    extensionsRecord[extensionBucket.key].duration +=
                        directionsRecord[directionBucket.key].duration;
                    extensionsRecord[extensionBucket.key].count +=
                        directionsRecord[directionBucket.key].count;
                }
                directionsRecord[directionBucket.key].actions = actionsRecord;
            });
        extensionsRecord[extensionBucket.key].directions = directionsRecord;
    });
    report.extensions = extensionsRecord;

    return report;
};
const createTableRow: (
    report: FormattedReport,
    extension: Extension,
    mode: ReportMode
) => TableRow = (report: FormattedReport, extension: Extension, mode: ReportMode) => {
    let hangedCount = 0;
    if (extension.count > 0) {
        for (const direction of reportModeDirections[mode]) {
            for (const action of reportModeHangedActions[mode]) {
                hangedCount += extension?.directions[direction]?.actions[action]?.count || 0;
            }
        }
    }

    let missedCount = 0;
    if (extension.count > 0) {
        for (const direction of reportModeDirections[mode]) {
            for (const action of reportModeMissedActions[mode]) {
                missedCount += extension?.directions[direction]?.actions[action]?.count || 0;
            }
        }
    }

    const totalCount = missedCount + hangedCount;

    return {
        label: extension.label,
        total: missedCount + hangedCount,
        hanged: hangedCount,
        missed: missedCount,
        missedPercentage:
            totalCount > 0 ? `${((missedCount * 100) / totalCount).toFixed(1)}%` : `-`,
        totalDuration: extension.duration > 0 ? formatDuration(extension.duration) : '-',
        percentageDuration:
            extension.duration > 0
                ? `${((100 * extension.duration) / report.duration).toFixed(1)}%`
                : '-',
        averageDuration:
            extension.duration > 0 ? formatDuration(extension.duration / hangedCount) : '-',
    };
};

const AllCallsStatistics = ({
    parameters,
}: {
    parameters: CallReportsParametersType & CallStatisticsAllParametersType;
}) => {
    const extensions = useSelector(customerExtensionsSelector);
    const loading = useSelector(getLoading('widget_calls_statistics_all'));
    const loadedOnce = useSelector(getLoading('widget_calls_statistics_all'));

    const [mode, setMode] = useState<ReportMode>('out');

    const calls_statistics_all = useSelector(getSingleWidgetData('calls_statistics_all'), isEqual);
    const calls_statistics_all_hits = useSelector(
        getSingleWidgetData('calls_statistics_all_hits'),
        isEqual
    );
    const calls_statistics_all_total = useSelector(
        getSingleWidgetData('calls_statistics_all_total'),
        isEqual
    );

    const directionFilter = useMemo(
        () => (mode === 'combined' ? '' : `direction_${mode}=${mode}`),
        [mode]
    );

    const extensionsFilter = useMemo(
        () =>
            parameters.extensions && parameters.extensions.length > 0
                ? parameters.extensions.map((e) => `extension_${e}=${e}`).join('&')
                : '',
        [parameters.extensions]
    );

    const dateFilter = useMemo(
        () =>
            `date=${moment(parameters.date.start)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss')}_${moment(parameters.date.end)
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss')}`,
        [parameters.date.end, parameters.date.start]
    );

    const baseFilter = useMemo(
        () => `/call_history?${directionFilter}&${dateFilter}&${extensionsFilter}`,
        [dateFilter, directionFilter, extensionsFilter]
    );

    const report: FormattedReport = useMemo(() => {
        return formatReport(
            {
                aggregations: calls_statistics_all,
                hits: calls_statistics_all_hits,
                total: calls_statistics_all_total,
            },
            mode,
            extensions,
            parameters.paginatedExtensions
        );
    }, [
        calls_statistics_all,
        calls_statistics_all_hits,
        calls_statistics_all_total,
        extensions,
        mode,
        parameters,
    ]);

    const renderRow = useCallback(
        (extension: Extension) => {
            const row = createTableRow(report, extension, mode);
            const baseUserFilter = `${baseFilter}&extension_${extension.extension}=${extension.extension}`;

            return (
                <tr key={formatAlphanumericString(`calls-statistics-${extension.extension}`)}>
                    <td>{row.label}</td>
                    <td>
                        <Link to={baseUserFilter}>{row.total}</Link>
                    </td>
                    <td>
                        <Link to={`${baseUserFilter}&status_hanged=hanged`}>{row.hanged}</Link>
                    </td>
                    <td>
                        {mode === 'out' && (
                            <Link to={`${baseUserFilter}&status_not+connected=not connected`}>
                                {row.missed}
                            </Link>
                        )}
                        {mode === 'in' && (
                            <Link to={`${baseUserFilter}&status_missed=missed`}>{row.missed}</Link>
                        )}
                        {mode === 'combined' && row.missed}
                    </td>
                    <td>{row.missed === undefined ? 0 : row.missedPercentage}</td>
                    <td>{row.totalDuration}</td>
                    <td>{report.duration === 0 ? 0 : row.percentageDuration}</td>
                    <td>{row.averageDuration}</td>
                </tr>
            );
        },
        [baseFilter, mode, report]
    );

    return (
        <>
            <div className={'sticky sticky-top bg-white'}>
                <Nav tabs className='nav-fill d-flex flex-row bg-white'>
                    <NavItem>
                        <NavLink onClick={() => setMode('out')} active={mode === 'out'}>
                            <FormattedMessage
                                id={'message.direction.out'}
                                defaultMessage={'Outbound'}
                            />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setMode('in')} active={mode === 'in'}>
                            <FormattedMessage
                                id={'message.reports.incoming'}
                                defaultMessage={'Incoming'}
                            />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setMode('combined')} active={mode === 'combined'}>
                            <FormattedMessage
                                id={'message.reports.combined'}
                                defaultMessage={'Combined'}
                            />
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            <div className='render-all-calls-statistics'>
                {loading && (
                    <div className='render-all-calls-statistics-spinner'>
                        <Spinner
                            color={'primary'}
                            background={'spinner-calls-statistics'}
                            global={false}
                            size={50}
                        />
                    </div>
                )}
                {loadedOnce && !loading ? (
                    <div className='text-center pt-5'>
                        <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
                    </div>
                ) : (
                    <Table bordered responsive striped hover>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage
                                        id={'table.filter.user'}
                                        defaultMessage={'User'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'message.total'}
                                        defaultMessage={'Total'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'message.completed'}
                                        defaultMessage={'Completed'}
                                    />
                                </th>
                                <th>
                                    {mode === 'out' ? (
                                        <FormattedMessage
                                            id={'message.status.not_connected'}
                                            defaultMessage={'No Answer'}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id={'message.status.missed'}
                                            defaultMessage={'Missed'}
                                        />
                                    )}
                                </th>
                                <th>
                                    %&nbsp;
                                    {mode === 'out' ? (
                                        <FormattedMessage
                                            id={'message.status.not_connected'}
                                            defaultMessage={'No Answer'}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id={'message.status.missed'}
                                            defaultMessage={'Missed'}
                                        />
                                    )}
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'table.filter.duration'}
                                        defaultMessage={'Duration'}
                                    />
                                </th>
                                <th>
                                    %&nbsp;
                                    <FormattedMessage
                                        id={'table.filter.duration'}
                                        defaultMessage={'Duration'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'table.column.average.duration'}
                                        defaultMessage={'Avg duration'}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(report.extensions).map((item: Extension) =>
                                renderRow(item)
                            )}
                        </tbody>
                    </Table>
                )}
                <ListGroup flush>
                    <ListGroupItem className={'d-flex'}>
                        <Link to={`${baseFilter}`}>
                            <Button outline color='primary' size='sm' className='mr-2'>
                                <FormattedMessage
                                    id={'message.reports.call.number'}
                                    defaultMessage={'Calls'}
                                />
                                : {report.count}
                            </Button>
                        </Link>
                        <Link to={`${baseFilter}&status_hanged=hanged`}>
                            <ButtonGroup size={'sm'} className='mr-2'>
                                <Button outline color='success' size='sm'>
                                    <FormattedMessage
                                        id={'message.status.hanged'}
                                        defaultMessage={'Connected'}
                                    />
                                    : {report.hanged}
                                </Button>
                                <Button outline color='success' size='sm'>
                                    <FormattedMessage
                                        id={'message.reports.total.time'}
                                        defaultMessage={'Total time'}
                                    />
                                    : {formatDuration(report.duration)}
                                </Button>
                            </ButtonGroup>
                        </Link>
                        <Link
                            to={
                                mode === 'in'
                                    ? `${baseFilter}&status_missed=missed`
                                    : mode === 'out'
                                    ? `${baseFilter}&status_not+connected=not connected`
                                    : '#'
                            }
                        >
                            <Button outline color='danger' size='sm' className='mr-2'>
                                <FormattedMessage
                                    id={'message.status.missed'}
                                    defaultMessage={'Missed'}
                                />
                                : {report.missed}
                            </Button>
                        </Link>
                    </ListGroupItem>
                </ListGroup>
            </div>
        </>
    );
};

export default AllCallsStatistics;
