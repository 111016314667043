const DEFAULT_CALL_HISTORY_AGGREGATIONS = [
    (query) => {
        query.aggs['tags'] = {field: 'tags.keyword', agg_type: 'terms', options: {size: 30}};
        return query;
    },
    (query) => {
        query.aggs['directions'] = {field: 'direction.keyword', agg_type: 'terms'};
        return query;
    },
    (query) => {
        query.aggs['statuses'] = {field: 'action.keyword', agg_type: 'terms'};
        return query;
    },
];

const DEFAULT_SORT = (query) => {
    query.sort = {
        by: 'call_start_time',
        order: 'desc',
    };
    return query;
};

const AVAILABLE_SORTS = {
    timestamp: (query, sort) => {
        query.sort = {
            by: 'call_start_time',
            order: sort.type,
        };
        return query;
    },
    name: (query, sort) => {
        query.sort = {
            by: 'caller_id_name.keyword',
            order: sort.type,
        };
        return query;
    },
    extension: (query, sort) => {
        query.sort = {
            by: 'extension.keyword',
            order: sort.type,
        };
        return query;
    },
    queue_time: (query, sort) => {
        query.sort = {
            by: 'queue_time',
            order: sort.type,
        };
        return query;
    },
    duration: (query, sort) => {
        query.sort = {
            by: 'duration',
            order: sort.type,
        };
        return query;
    },
};

const get_sort = (sort) => {
    return sort && sort.type && Object.keys(AVAILABLE_SORTS).includes(sort.column.id)
        ? AVAILABLE_SORTS[sort.column.id]
        : DEFAULT_SORT;
};

export const build_call_history_query = (
    filters = [],
    aggs = [],
    sort = {},
    size = 100,
    from = 0,
    extras = {}
) => {
    let listQuery = {
        filters: {},
        sort: {},
        size,
        from,
        extras,
    };

    let statsQuery = {
        filters: {},
        aggs: {},
        extras,
    };

    listQuery = filters.reduce((query, filter) => filter(query), listQuery);
    statsQuery = filters.reduce((query, filter) => filter(query), statsQuery);

    statsQuery = aggs.reduce((query, agg) => agg(query), statsQuery);
    statsQuery = DEFAULT_CALL_HISTORY_AGGREGATIONS.reduce((query, agg) => agg(query), statsQuery);

    listQuery = get_sort(sort)(listQuery, sort);

    return {list: listQuery, statistics: statsQuery};
};
