import React, {useEffect, useReducer, useState} from 'react';
import {Button, CardBody, CardHeader} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import ContentWrapper from '../../../components/content/ContentWrapper';
import CardContent from '../../../components/content/CardContent';
import SidePanel from '../../../components/panel/SidePanel';
import UserCard from '../../../components/user/UserCard';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomerUsers} from '../../../store/selectors/customer-selectors';
import {
    fetchCustomerExtensions,
    fetchCustomerRoles,
    fetchCustomerTeams,
    fetchCustomerUsers,
} from '../../../store/actions/customer-action';
import GridList from '../../../components/layout/GridList/GridList';
import SearchBar from '../../../components/search/SearchBar';
import isEqual from 'lodash.isequal';
import UserForm from './UserForm';
import LightFontAwesomeIcon from '../../../components/utils/LightFontAwesomeIcon';
import {getLoading} from '../../../store/selectors/portal-selectors';
import Spinner from '../../../components/spinner/Spinner';
import {getUser, getUserTravel} from '../../../store/selectors/user-selectors';
import {Has, hasPolicy} from '../../../components/security/Has';
import {formatAlphanumericString} from '../../../utils/format';
import {fetchUserLiteCredentials} from '../../../store/actions/user-action';

const Users = () => {
    const users = useSelector(getCustomerUsers);
    const loading = useSelector(getLoading('users'));
    const travel = useSelector(getUserTravel);
    const currentUser = useSelector(getUser);

    const reduxDispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const [user, setUser] = useState(null);
    const [foundUsers, foundUsersDispatch] = useReducer((state, action) => {
        const found = action.payload;
        return isEqual(state, found) ? state : found;
    }, users);

    useEffect(() => {
        reduxDispatch(fetchCustomerUsers());
        reduxDispatch(fetchCustomerTeams());
        reduxDispatch(fetchCustomerRoles());
        reduxDispatch(fetchCustomerExtensions());
    }, [reduxDispatch, travel]);

    const cancel = () => {
        setUser(null);
        setModal(false);
    };

    const edit = (user) => {
        setUser(user);
        setModal(true);
        reduxDispatch(fetchUserLiteCredentials(user));
    };

    return (
        <>
            <ContentWrapper>
                <CardContent fullHeight>
                    <CardHeader className='d-flex flex-row align-items-baseline border-bottom-0'>
                        <h2 className='mb-0 mr-2'>
                            <FormattedMessage id='title.table.users' defaultMessage='Users' />
                        </h2>
                        <Has aPolicyMatching={[{action: 'WRITE', resourceType: 'USERS'}]}>
                            <Button
                                className={'mr-auto align-self-center'}
                                color={'primary'}
                                onClick={() => edit(null)}
                                size={'sm'}
                            >
                                <LightFontAwesomeIcon icon={'plus'} />
                            </Button>
                        </Has>
                        <FormattedMessage id={'input.placeholder.search'} defaultMessage={'Search'}>
                            {(msg) => (
                                <SearchBar
                                    className={'mr-auto'}
                                    fields={['nickname', 'full_name', 'extension', 'email']}
                                    items={users}
                                    live
                                    minimumCharacters={3}
                                    setFoundItems={(f) => foundUsersDispatch({payload: f})}
                                    placeholder={msg}
                                />
                            )}
                        </FormattedMessage>
                    </CardHeader>
                    <CardBody
                        style={{overflowY: 'auto'}}
                        className={'d-flex flex-column flex-grow-1'}
                    >
                        {loading ? (
                            <Spinner
                                centered
                                color={'primary'}
                                background={'transparent'}
                                global={false}
                                size={50}
                            />
                        ) : (
                            <GridList
                                items={foundUsers}
                                itemComponent={(item) => (
                                    <UserCard
                                        key={formatAlphanumericString(item.email)}
                                        actionComponent={
                                            <Has
                                                aPolicyMatching={[
                                                    {action: 'WRITE', resourceType: 'USERS'},
                                                ]}
                                            >
                                                <Button
                                                    onClick={() => edit(item)}
                                                    color={'primary'}
                                                    size={'sm'}
                                                >
                                                    <LightFontAwesomeIcon
                                                        icon={'pen'}
                                                        className={'mr-1'}
                                                    />
                                                    <FormattedMessage
                                                        id={'btn.edit'}
                                                        defaultMessage={'Edit'}
                                                    />
                                                </Button>
                                            </Has>
                                        }
                                        className={'mb-2 with-shadow'}
                                        onClick={
                                            hasPolicy(currentUser, 'write', 'users')
                                                ? () => edit(item)
                                                : null
                                        }
                                        user={item}
                                        style={{minHeight: 117, maxHeight: 117}}
                                    />
                                )}
                                itemKey={(item) => item.email}
                                itemWidth={450}
                                itemHeight={125}
                                style={{overflowX: 'visible'}}
                            />
                        )}
                    </CardBody>
                </CardContent>
            </ContentWrapper>
            <SidePanel
                isOpen={modal}
                setOpen={setModal}
                className={'bg-white'}
                onGlassClick={cancel}
            >
                <UserForm user={user} toggle={cancel} />
            </SidePanel>
        </>
    );
};

export default Users;
