import React, {useEffect, useReducer, useState} from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import isEqual from 'lodash.isequal';
import SideSelector from '../navbar/SideSelector';
import WidgetOptions from './factories/WidgetOptions';
import WidgetFactory from './factories/WidgetFactory';
import WidgetStudioForm from './WidgetStudioForm';
import {FilterDisplays} from '../call/filters/FilterDisplays';
import FilterFactories from '../call/filters/FilterFactories';
import {useDispatch} from 'react-redux';
import {fetchCustomerExtensions, fetchCustomerQueues} from '../../store/actions/customer-action';
import {getWidgetOptions, isESDataType} from './utils/studioFunctions';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import WidgetHeader from './WidgetHeader';
import {useDeepState} from '../../utils/hooks';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import FormSection from '../form/FormSection';
import Spinner from '../spinner/Spinner';

const WIDGET_TYPE_MESSAGES = defineMessages({
    'widget.line': {
        id: 'widget.line',
        defaultMessage: 'Line',
    },
    'widget.pie': {
        id: 'widget.pie',
        defaultMessage: 'Pie',
    },
    'widget.bar': {
        id: 'widget.bar',
        defaultMessage: 'Bar',
    },
    'widget.progress': {
        id: 'widget.progress',
        defaultMessage: 'Progress',
    },
    'widget.gauge': {
        id: 'widget.gauge',
        defaultMessage: 'Gauge',
    },
    'widget.value': {
        id: 'widget.value',
        defaultMessage: 'Value',
    },
    'widget.table': {
        id: 'widget.table',
        defaultMessage: 'Table',
    },
});

const WIDGET_DATA_TYPE_MESSAGES = defineMessages({
    'widget.line.calls_by_hour': {
        id: 'widget.line.calls_by_hour',
        defaultMessage: 'Calls by Hour',
    },
    'widget.line.calls_by_day': {
        id: 'widget.line.calls_by_day',
        defaultMessage: 'Calls by Day',
    },
    'widget.line.calls_by_month': {
        id: 'widget.line.calls_by_month',
        defaultMessage: 'Calls by Month',
    },
    'widget.pie.calls_by_language': {
        id: 'widget.pie.calls_by_language',
        defaultMessage: 'Calls by Language',
    },
    'widget.pie.calls_by_agent': {
        id: 'widget.pie.calls_by_agent',
        defaultMessage: 'Calls by Agent',
    },
    'widget.pie.calls_by_status': {
        id: 'widget.pie.calls_by_status',
        defaultMessage: 'Calls by Status',
    },
    'widget.pie.calls_by_tags': {
        id: 'widget.pie.calls_by_tags',
        defaultMessage: 'Calls by Tag',
    },
    'widget.bar.calls_by_agent': {
        id: 'widget.bar.calls_by_agent',
        defaultMessage: 'Calls by Agent',
    },
    'widget.bar.calls_by_status': {
        id: 'widget.bar.calls_by_status',
        defaultMessage: 'Calls by Status',
    },
    'widget.bar.calls_by_agent_per_status': {
        id: 'widget.bar.calls_by_agent_per_status',
        defaultMessage: 'Calls by Agent per Status',
    },
    'widget.bar.calls_by_agent_per_tags': {
        id: 'widget.bar.calls_by_agent_per_tags',
        defaultMessage: 'Calls by Agent per Tag',
    },
    'widget.progress.calls_by_agent': {
        id: 'widget.progress.calls_by_agent',
        defaultMessage: 'Calls by Agent',
    },
    'widget.progress.calls_by_status': {
        id: 'widget.progress.calls_by_status',
        defaultMessage: 'Calls by Status',
    },
    'widget.gauge.calls_gauge': {
        id: 'widget.gauge.calls_gauge',
        defaultMessage: 'Calls Gauge',
    },
    'widget.value.calls_amount': {
        id: 'widget.value.calls_amount',
        defaultMessage: 'Call Amount',
    },
    'widget.table.queue_members': {
        id: 'widget.table.queue_members',
        defaultMessage: 'Queue Agents',
    },
});

const WidgetStudio = injectIntl(
    ({intl, widgetHandler, currentWidget, masterFilters, modalToggle}) => {
        const reduxDispatch = useDispatch();

        const [dataType, setDataType] = useDeepState(
            currentWidget ? currentWidget.dataType : 'widget.line.calls_by_day'
        );
        const [typeChanging, setTypeChanging] = useState(false);
        const [title, setTitle] = useState(
            currentWidget
                ? currentWidget.title
                : intl.formatMessage({id: dataType, defaultMessage: 'Widget Title'})
        );

        const widgetOptions = getWidgetOptions(dataType);

        const [extras, extrasDispatch] = useReducer(
            (previousExtras, action) => {
                let newExtras = {...previousExtras};
                switch (action.type) {
                    case 'add':
                        newExtras[action.payload.index] = action.payload.extra;
                        break;
                    case 'clear':
                        newExtras = {};
                        break;
                    default:
                        newExtras = {};
                }
                return isEqual(previousExtras, newExtras) ? previousExtras : newExtras;
            },
            currentWidget ? (currentWidget.extras ? currentWidget.extras : null) : null
        );

        const addExtra = (extra) =>
            extrasDispatch({
                type: 'add',
                payload: {
                    index: extra.index,
                    extra: extra.value,
                },
            });

        const clearExtras = () => extrasDispatch({type: 'clear'});

        const [filters, filtersDispatch] = useReducer(
            (previousFilters, action) => {
                let newFilters = {...previousFilters};
                switch (action.type) {
                    case 'add':
                        newFilters[action.payload.index] = action.payload.filter;
                        break;
                    case 'remove':
                        delete newFilters[action.payload.index];
                        break;
                    case 'clear':
                        newFilters = {};
                        break;
                    default:
                        return previousFilters;
                }
                return isEqual(previousFilters, newFilters) ? previousFilters : newFilters;
            },
            currentWidget ? currentWidget.filters : {}
        );

        const addFilter = (filter) =>
            filtersDispatch({
                type: 'add',
                payload: {
                    index: filter.index,
                    filter: filter,
                },
            });

        const removeFilter = (filter) =>
            filtersDispatch({
                type: 'remove',
                payload: {
                    index: filter.index,
                },
            });

        const clearFilters = () =>
            filtersDispatch({
                type: 'clear',
            });

        const changeDataType = (dataType) => {
            setTypeChanging(true);
            clearExtras();
            setTimeout(() => {
                setDataType(dataType);
                setTitle(intl.formatMessage({id: dataType, defaultMessage: 'Widget Title'}));
                setTypeChanging(false);
            }, 250);
        };

        const combineFilters = (widgetFilters) => {
            if (Object.keys(widgetFilters).includes('date')) return widgetFilters;
            if (Object.keys(masterFilters).includes('date')) {
                return {...widgetFilters, date: masterFilters['date']};
            } else return widgetFilters;
        };

        const sendWidgetData = (e) => {
            e.preventDefault();
            const widgetData = {
                ...currentWidget,
                title,
                dataType,
                filters,
                extras,
            };
            widgetHandler(widgetData);
            modalToggle();
        };

        useEffect(() => {
            if (widgetOptions.extensionsRequired) reduxDispatch(fetchCustomerExtensions());
            if (widgetOptions.data.type === 'queue')
                reduxDispatch(fetchCustomerQueues({include_system_queues: false}));
        }, [widgetOptions, reduxDispatch]);

        return (
            <div className={'d-flex flex-row flex-grow-1 p-2'}>
                <div className={'d-flex flex-column m--2'}>
                    <SideSelector
                        options={WidgetOptions}
                        selectedOption={dataType}
                        onSelect={changeDataType}
                        optionTitles={WIDGET_TYPE_MESSAGES}
                        subOptionTitles={WIDGET_DATA_TYPE_MESSAGES}
                    />
                </div>
                <div className={'d-flex flex-column ml-4 mr-4 mt-3 mb-3 w-100'}>
                    <div className={'mb-auto flex-grow-1'}>
                        <div className={'ml-2 mb-2'} style={{fontSize: '20px'}}>
                            {title ? (
                                title
                            ) : (
                                <FormattedMessage
                                    id={'dashboard.studio.title'}
                                    defaultMessage={'Create a widget'}
                                />
                            )}
                        </div>
                        <FormSection
                            title={intl.formatMessage({
                                id: 'dashboard.studio.widget_config',
                                defaultMessage: 'Widget configuration',
                            })}
                            icon={faCog}
                            isOpenByDefault
                        >
                            {typeChanging ? (
                                <Spinner
                                    color={'primary'}
                                    background={'transparent'}
                                    global={false}
                                    size={50}
                                />
                            ) : (
                                <WidgetStudioForm
                                    dataType={dataType}
                                    title={title}
                                    setTitle={setTitle}
                                    extras={extras}
                                    addExtra={addExtra}
                                />
                            )}
                        </FormSection>
                    </div>
                    {isESDataType(widgetOptions) ? (
                        <>
                            <div style={{marginLeft: '-8px'}}>
                                <FilterFactories addFilter={addFilter} />
                            </div>
                            <div>
                                <FilterDisplays
                                    className={'py-2 pl-2 pr-4'}
                                    filters={Object.values(filters)}
                                    removeFilter={removeFilter}
                                    clearFilters={clearFilters}
                                />
                            </div>
                        </>
                    ) : null}
                    <Card className={'m-2'} style={{height: 335}}>
                        <CardHeader
                            className={
                                'border-bottom-0 d-flex flex-row align-items-baseline justify-content-between'
                            }
                        >
                            <WidgetHeader
                                widget={
                                    title
                                        ? {title}
                                        : {
                                              title: (
                                                  <FormattedMessage
                                                      id={'dashboard.studio.widget_title'}
                                                      defaultMessage={'Widget title'}
                                                  />
                                              ),
                                          }
                                }
                                editMode={false}
                            />
                        </CardHeader>
                        <CardBody className={'flex-grow-1 w-100'}>
                            <div style={{height: 245}} className={'responsive-chart'}>
                                {!typeChanging && (
                                    <WidgetFactory
                                        id={'-1'}
                                        filters={combineFilters(filters)}
                                        type={dataType}
                                        extras={extras}
                                    />
                                )}
                            </div>
                        </CardBody>
                    </Card>
                    <div className={'d-flex flex-row justify-content-end mt-2'}>
                        <Button
                            size='sm'
                            onClick={sendWidgetData}
                            disabled={!title}
                            color={'primary'}
                            children={<FormattedMessage id={'btn.done'} defaultMessage={'Done'} />}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default WidgetStudio;
