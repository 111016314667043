/* User Actions */
export const FETCH_USERS_SAGA = 'FETCH_USERS_SAGA';
export const STORE_USERS = 'STORE_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

export const CREATE_USER_SAGA = 'CREATE_USER_SAGA';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER_SAGA = 'UPDATE_USER_SAGA';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const DELETE_USER_SAGA = 'DELETE_USER_SAGA';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const RESEND_INVITATION_SAGA = 'RESEND_INVITATION_SAGA';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAILED = 'RESEND_INVITATION_FAILED';

/* Extension Actions */
export const FETCH_EXTENSIONS_SAGA = 'FETCH_EXTENSIONS_SAGA';
export const STORE_EXTENSIONS = 'STORE_EXTENSIONS';
export const FETCH_EXTENSIONS_SUCCESS = 'FETCH_EXTENSIONS_SUCCESS';
export const FETCH_EXTENSIONS_FAILED = 'FETCH_EXTENSIONS_FAILED';

export const FETCH_NEW_EXTENSIONS_SAGA = 'FETCH_NEW_EXTENSIONS_SAGA';
export const FETCH_CUSTOMER_NEW_PORTAL_ACCESS_SAGA = 'FETCH_CUSTOMER_NEW_PORTAL_ACCESS_SAGA';
export const STORE_NEW_EXTENSIONS = 'STORE_NEW_EXTENSIONS';

export const STORE_NEW_PORTAL_ACCESS = 'STORE_NEW_PORTAL_ACCESS';
export const FETCH_NEW_PORTAL_ACCESS_SUCCESS = 'FETCH_NEW_PORTAL_ACCESS_SUCCESS';
export const FETCH_NEW_PORTAL_ACCESS_FAILED = 'FETCH_NEW_PORTAL_ACCESS_FAILED';

export const FETCH_EXTENSION_SETTINGS_SAGA = 'FETCH_EXTENSION_SETTINGS_SAGA';
export const STORE_EXTENSION_SETTINGS_SAGA = 'STORE_EXTENSION_SETTINGS';
export const FETCH_EXTENSION_SETTINGS_SUCCESS = 'FETCH_EXTENSIONS_SETTINGS_SUCCESS';
export const FETCH_EXTENSION_SETTINGS_FAILED = 'FETCH_EXTENSIONS_SETTINGS_FAILED';

export const CREATE_EXTENSION_SAGA = 'CREATE_EXTENSION_SAGA';
export const CREATE_EXTENSION_SUCCESS = 'CREATE_EXTENSION_SUCCESS';
export const CREATE_EXTENSION_FAILED = 'CREATE_EXTENSION_FAILED';

export const UPDATE_EXTENSION_SAGA = 'UPDATE_EXTENSION_SAGA';
export const UPDATE_EXTENSION_SUCCESS = 'UPDATE_EXTENSION_SUCCESS';
export const UPDATE_SIP_EXTENSION_SAGA = 'UPDATE_SIP_EXTENSION_SAGA';
export const UPDATE_EXTENSION_FAILED = 'UPDATE_EXTENSION_FAILED';
export const UPDATE_SIP_EXTENSION_FAILED = 'UPDATE_SIP_EXTENSION_FAILED';

export const DELETE_EXTENSION_SAGA = 'DELETE_EXTENSION_SAGA';
export const DELETE_EXTENSION_SUCCESS = 'DELETE_EXTENSION_SUCCESS';
export const DELETE_EXTENSION_FAILED = 'DELETE_EXTENSION_FAILED';

/* Team Actions */
export const FETCH_TEAMS_SAGA = 'FETCH_TEAMS_SAGA';
export const STORE_TEAMS = 'STORE_TEAMS';
export const FETCH_TEAMS_FAILED = 'FETCH_TEAMS_FAILED';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';

export const CREATE_TEAM_SAGA = 'CREATE_TEAM_SAGA';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILED = 'CREATE_TEAM_FAILED';

export const UPDATE_TEAM_SAGA = 'UPDATE_TEAM_SAGA';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILED = 'UPDATE_TEAM_FAILED';

export const DELETE_TEAM_SAGA = 'DELETE_TEAM_SAGA';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED';

/* Role Actions */
export const FETCH_ROLES_SAGA = 'FETCH_ROLES_SAGA';
export const STORE_ROLES = 'STORE_ROLES';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';

export const CREATE_ROLE_SAGA = 'CREATE_ROLE_SAGA';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILED = 'CREATE_ROLE_FAILED';

export const UPDATE_ROLE_SAGA = 'UPDATE_ROLE_SAGA';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const DELETE_ROLE_SAGA = 'DELETE_ROLE_SAGA';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

/* Resource Types Actions */
export const FETCH_RESOURCE_TYPES_SAGA = 'FETCH_RESOURCE_TYPES_SAGA';
export const STORE_RESOURCE_TYPES = 'STORE_RESOURCE_TYPES';
export const FETCH_RESOURCE_TYPES_FAILED = 'FETCH_RESOURCE_TYPES_FAILED';
export const FETCH_RESOURCE_TYPES_SUCCESS = 'FETCH_RESOURCE_TYPES_SUCCESS';

/* Policies Actions */
export const FETCH_POLICIES_SAGA = 'FETCH_POLICIES_SAGA';
export const STORE_POLICIES = 'STORE_POLICIES';
export const FETCH_POLICIES_FAILED = 'FETCH_POLICIES_FAILED';
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS';

export const CREATE_POLICY_SAGA = 'CREATE_POLICY_SAGA';
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS';
export const CREATE_POLICY_FAILED = 'CREATE_POLICY_FAILED';

export const UPDATE_POLICY_SAGA = 'UPDATE_POLICY_SAGA';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const UPDATE_POLICY_FAILED = 'UPDATE_POLICY_FAILED';

export const DELETE_POLICY_SAGA = 'DELETE_POLICY_SAGA';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_FAILED = 'DELETE_POLICY_FAILED';

/* Fax Configs Actions */
export const FETCH_FAX_CONFIGS_SAGA = 'FETCH_FAX_CONFIGS_SAGA';
export const STORE_FAX_CONFIGS = 'STORE_FAX_CONFIGS';
export const FETCH_FAX_CONFIGS_FAILED = 'FETCH_FAX_CONFIGS_FAILED';
export const FETCH_FAX_CONFIGS_SUCCESS = 'FETCH_FAX_CONFIGS_SUCCESS';

export const CREATE_FAX_CONFIG_SAGA = 'CREATE_FAX_CONFIG_SAGA';
export const CREATE_FAX_CONFIG_SUCCESS = 'CREATE_FAX_CONFIG_SUCCESS';
export const CREATE_FAX_CONFIG_FAILED = 'CREATE_FAX_CONFIG_FAILED';

export const UPDATE_FAX_CONFIG_SAGA = 'UPDATE_FAX_CONFIG_SAGA';
export const UPDATE_FAX_CONFIG_SUCCESS = 'UPDATE_FAX_CONFIG_SUCCESS';
export const UPDATE_FAX_CONFIG_FAILED = 'UPDATE_FAX_CONFIG_FAILED';

export const DELETE_FAX_CONFIG_SAGA = 'DELETE_FAX_CONFIG_SAGA';
export const DELETE_FAX_CONFIG_SUCCESS = 'DELETE_FAX_CONFIG_SUCCESS';
export const DELETE_FAX_CONFIG_FAILED = 'DELETE_FAX_CONFIG_FAILED';

/* Fax Bindings Actions */
export const FETCH_FAX_BINDINGS_SAGA = 'FETCH_FAX_BINDINGS_SAGA';
export const STORE_FAX_BINDINGS = 'STORE_FAX_BINDINGS';
export const FETCH_FAX_BINDINGS_FAILED = 'FETCH_FAX_BINDINGS_FAILED';
export const FETCH_FAX_BINDINGS_SUCCESS = 'FETCH_FAX_BINDINGS_SUCCESS';

export const CREATE_FAX_BINDING_SAGA = 'CREATE_FAX_BINDING_SAGA';
export const CREATE_FAX_BINDING_SUCCESS = 'CREATE_FAX_BINDING_SUCCESS';
export const CREATE_FAX_BINDING_FAILED = 'CREATE_FAX_BINDING_FAILED';

export const UPDATE_FAX_BINDING_SAGA = 'UPDATE_FAX_BINDING_SAGA';
export const UPDATE_FAX_BINDING_SUCCESS = 'UPDATE_FAX_BINDING_SUCCESS';
export const UPDATE_FAX_BINDING_FAILED = 'UPDATE_FAX_BINDING_FAILED';

export const DELETE_FAX_BINDING_SAGA = 'DELETE_FAX_BINDING_SAGA';
export const DELETE_FAX_BINDING_SUCCESS = 'DELETE_FAX_BINDING_SUCCESS';
export const DELETE_FAX_BINDING_FAILED = 'DELETE_FAX_BINDING_FAILED';

/* Sms configs Actions */
export const FETCH_SMS_CONFIGS_SAGA = 'FETCH_SMS_CONFIGS_SAGA';
export const STORE_SMS_CONFIGS = 'STORE_SMS_CONFIGS';
export const FETCH_SMS_CONFIGS_SUCCESS = 'FETCH_SMS_CONFIGS_SUCCESS';
export const FETCH_SMS_CONFIGS_FAILED = 'FETCH_SMS_CONFIGS_FAILED';

export const CREATE_SMS_CONFIG_SAGA = 'CREATE_SMS_CONFIG_SAGA';
export const CREATE_SMS_CONFIG_SUCCESS = 'CREATE_SMS_CONFIG_SUCCESS';
export const CREATE_SMS_CONFIG_FAILED = 'CREATE_SMS_CONFIG_FAILED';

export const UPDATE_SMS_CONFIG_SAGA = 'UPDATE_SMS_CONFIG_SAGA';
export const UPDATE_SMS_CONFIG_SUCCESS = 'UPDATE_SMS_CONFIG_SUCCESS';
export const UPDATE_SMS_CONFIG_FAILED = 'UPDATE_SMS_CONFIG_FAILED';

export const DELETE_SMS_CONFIG_SAGA = 'DELETE_SMS_CONFIG_SAGA';
export const DELETE_SMS_CONFIG_SUCCESS = 'DELETE_SMS_CONFIG_SUCCESS';
export const DELETE_SMS_CONFIG_FAILED = 'DELETE_SMS_CONFIG_FAILED';

/* Sms bindings Actions */
export const FETCH_SMS_BINDINGS_SAGA = 'FETCH_SMS_BINDINGS_SAGA';
export const STORE_SMS_BINDINGS = 'STORE_SMS_BINDINGS';
export const FETCH_SMS_BINDINGS_SUCCESS = 'FETCH_SMS_BINDINGS_SUCCESS';
export const FETCH_SMS_BINDINGS_FAILED = 'FETCH_SMS_BINDINGS_FAILED';

export const CREATE_SMS_BINDING_SAGA = 'CREATE_SMS_BINDING_SAGA';
export const CREATE_SMS_BINDING_SUCCESS = 'CREATE_SMS_BINDING_SUCCESS';
export const CREATE_SMS_BINDING_FAILED = 'CREATE_SMS_BINDING_FAILED';

export const UPDATE_SMS_BINDING_SAGA = 'UPDATE_SMS_BINDING_SAGA';
export const UPDATE_SMS_BINDING_SUCCESS = 'UPDATE_SMS_BINDING_SUCCESS';
export const UPDATE_SMS_BINDING_FAILED = 'UPDATE_SMS_BINDING_FAILED';

export const DELETE_SMS_BINDING_SAGA = 'DELETE_SMS_BINDING_SAGA';
export const DELETE_SMS_BINDING_SUCCESS = 'DELETE_SMS_BINDING_SUCCESS';
export const DELETE_SMS_BINDING_FAILED = 'DELETE_SMS_BINDING_FAILED';

export const FETCH_SMS_BANDWIDTH_SAGA = 'FETCH_SMS_BANDWIDTH_SAGA';
export const STORE_SMS_BANDWIDTH = 'STORE_SMS_BANDWIDTH';
export const FETCH_SMS_BANDWIDTH_SUCCESS = 'FETCH_SMS_BANDWIDTH_SUCCESS';
export const FETCH_SMS_BANDWIDTH_FAILED = 'FETCH_SMS_BANDWIDTH_FAILED';

/* Sms bindings users Actions */
export const FETCH_SMS_BINDINGS_USERS_SAGA = 'FETCH_SMS_BINDINGS_USERS_SAGA';
export const STORE_SMS_BINDINGS_USERS = 'STORE_SMS_BINDINGS_USERS';
export const FETCH_SMS_BINDINGS_USERS_SUCCESS = 'FETCH_SMS_BINDINGS_USERS_SUCCESS';
export const FETCH_SMS_BINDINGS_USERS_FAILURE = 'FETCH_SMS_BINDINGS_USERS_FAILURE';

/* Team User Actions */
export const FETCH_TEAM_USERS_SAGA = 'FETCH_TEAM_USERS_SAGA';
export const STORE_TEAM_USERS = 'STORE_TEAM_USERS';
export const FETCH_TEAM_USERS_FAILED = 'FETCH_TEAM_USERS_FAILED';
export const FETCH_TEAM_USERS_SUCCESS = 'FETCH_TEAM_USERS_SUCCESS';

export const CREATE_TEAM_USER_SAGA = 'CREATE_TEAM_USER_SAGA';
export const CREATE_TEAM_USER_SUCCESS = 'CREATE_TEAM_USER_SUCCESS';
export const CREATE_TEAM_USER_FAILED = 'CREATE_TEAM_USER_FAILED';

export const UPDATE_TEAM_USER_SAGA = 'UPDATE_TEAM_USER_SAGA';
export const UPDATE_TEAM_USER_SUCCESS = 'UPDATE_TEAM_USER_SUCCESS';
export const UPDATE_TEAM_USER_FAILED = 'UPDATE_TEAM_USER_FAILED';

export const DELETE_TEAM_USER_SAGA = 'DELETE_TEAM_USER_SAGA';
export const DELETE_TEAM_USER_SUCCESS = 'DELETE_TEAM_USER_SUCCESS';
export const DELETE_TEAM_USER_FAILED = 'DELETE_TEAM_USER_FAILED';

/* User Role Actions */
export const FETCH_USER_ROLES_SAGA = 'FETCH_USER_ROLES_SAGA';
export const STORE_USER_ROLES = 'STORE_USER_ROLES';
export const FETCH_USER_ROLES_FAILED = 'FETCH_USER_ROLES_FAILED';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';

export const CREATE_USER_ROLE_SAGA = 'CREATE_USER_ROLE_SAGA';
export const CREATE_USER_ROLE_SUCCESS = 'CREATE_USER_ROLE_SUCCESS';
export const CREATE_USER_ROLE_FAILED = 'CREATE_USER_ROLE_FAILED';

export const UPDATE_USER_ROLE_SAGA = 'UPDATE_USER_ROLE_SAGA';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAILED = 'UPDATE_USER_ROLE_FAILED';

export const DELETE_USER_ROLE_SAGA = 'DELETE_USER_ROLE_SAGA';
export const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS';
export const DELETE_USER_ROLE_FAILED = 'DELETE_USER_ROLE_FAILED';

/* Language Actions */
export const FETCH_LANGUAGES_SAGA = 'FETCH_LANGUAGES_SAGA';
export const STORE_LANGUAGES = 'STORE_LANGUAGES';
export const FETCH_LANGUAGES_FAILED = 'FETCH_LANGUAGES_FAILED';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';

/* API Key Action Types */
export const FETCH_API_KEYS_SAGA = 'FETCH_API_KEYS_SAGA';
export const STORE_API_KEYS = 'STORE_API_KEYS';
export const FETCH_API_KEYS_FAILED = 'FETCH_API_KEYS_FAILED';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';

export const CREATE_API_KEY_SAGA = 'CREATE_API_KEY_SAGA';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAILED = 'CREATE_API_KEY_FAILED';

export const UPDATE_API_KEY_SAGA = 'UPDATE_API_KEY_SAGA';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_FAILED = 'UPDATE_API_KEY_FAILED';

export const DELETE_API_KEY_SAGA = 'DELETE_API_KEY_SAGA';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAILED = 'DELETE_API_KEY_FAILED';

/* Power Broker Action Types */
export const FETCH_POWER_BROKER_API_KEY = 'FETCH_POWER_BROKER_API_KEY';
export const FETCH_POWER_BROKER_API_KEY_FAILED = 'FETCH_POWER_BROKER_API_KEY_FAILED';
export const CREATE_POWER_BROKER_API_KEY = 'CREATE_POWER_BROKER_API_KEY';
export const CREATE_POWER_BROKER_API_KEY_FAILED = 'CREATE_POWER_BROKER_API_KEY_FAILED';
export const DELETE_POWER_BROKER_API_KEY = 'DELETE_POWER_BROKER_API_KEY';
export const DELETE_POWER_BROKER_API_KEY_FAILED = 'DELETE_POWER_BROKER_API_KEY_FAILED';
export const STORE_POWER_BROKER_API_KEY = 'STORE_POWER_BROKER_API_KEY';

/* Customer Actions */
export const FETCH_CUSTOMERS_SAGA = 'FETCH_CUSTOMERS_SAGA';
export const STORE_CUSTOMERS = 'STORE_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED';

export const FETCH_CUSTOMER_SAGA = 'FETCH_CUSTOMER_SAGA';
export const STORE_CUSTOMER = 'STORE_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILED = 'FETCH_CUSTOMER_FAILED';

export const CREATE_CUSTOMER_SAGA = 'CREATE_CUSTOMER_SAGA';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER_SAGA = 'UPDATE_CUSTOMER_SAGA';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';

export const FETCH_USER_PRO_SAGA = 'FETCH_USER_PRO_SAGA';
export const STORE_USER_PRO = 'STORE_USER_PRO';
export const FETCH_USER_PRO_SUCCESS = 'FETCH_USER_PRO_SUCCESS';
export const FETCH_USER_PRO_FAILED = 'FETCH_USER_PRO_FAILED';
export const CREATE_USER_PRO_SAGA = 'CREATE_USER_PRO_SAGA';
export const CREATE_USER_PRO = 'CREATE_USER_PRO_SAGA';
// export const CREATE_USER_PRO_SUCCESS = 'CREATE_USER_PRO_SUCCESS';
export const CREATE_USER_PRO_FAILED = 'CREATE_USER_PRO_FAILED';

/* Journeys Actions */
export const FETCH_JOURNEYS_SAGA = 'FETCH_JOURNEYS_SAGA';
export const STORE_JOURNEYS = 'STORE_JOURNEYS';
export const FETCH_JOURNEYS_SUCCESS = 'FETCH_JOURNEYS_SUCCESS';
export const FETCH_JOURNEYS_FAILED = 'FETCH_JOURNEYS_FAILED';

export const FETCH_JOURNEY_SAGA = 'FETCH_JOURNEY_SAGA';
export const STORE_JOURNEY = 'STORE_JOURNEY';
export const FETCH_JOURNEY_SUCCESS = 'FETCH_JOURNEY_SUCCESS';
export const FETCH_JOURNEY_FAILED = 'FETCH_JOURNEY_FAILED';

/* Integration Action Types */
export const FETCH_INTEGRATION_ACCESS_SAGA = 'FETCH_INTEGRATION_ACCESS_SAGA';
export const FETCH_INTEGRATION_ACCESS_SUCCESS = 'FETCH_INTEGRATION_ACCESS_SUCCESS';
export const FETCH_INTEGRATION_ACCESS_FAILED = 'FETCH_INTEGRATION_ACCESS_FAILED';
export const STORE_INTEGRATION_ACCESS = 'STORE_INTEGRATION_ACCESS';

export const FETCH_INTEGRATION_USERS_SAGA = 'FETCH_INTEGRATION_USERS_SAGA';
export const FETCH_INTEGRATION_USERS_SUCCESS = 'FETCH_INTEGRATION_USERS_SUCCESS';
export const FETCH_INTEGRATION_USERS_FAILED = 'FETCH_INTEGRATION_USERS_FAILED';
export const STORE_INTEGRATION_USERS = 'STORE_INTEGRATION_USERS';
export const STORE_INTEGRATION_BINDING = 'STORE_INTEGRATION_BINDING';

export const DELETE_INTEGRATION_SAGA = 'DELETE_INTEGRATION_SAGA';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_FAILED = 'DELETE_INTEGRATION_FAILED';

export const UPDATE_INTEGRATION_BINDING_SAGA = 'UPDATE_INTEGRATION_BINDING_SAGA';
export const UPDATE_INTEGRATION_BINDING_SUCCESS = 'UPDATE_INTEGRATION_BINDING_SUCCESS';
export const UPDATE_INTEGRATION_BINDING_FAILED = 'UPDATE_INTEGRATION_BINDING_FAILED';

export const DELETE_INTEGRATION_BINDING_SAGA = 'DELETE_INTEGRATION_BINDING_SAGA';
export const DELETE_INTEGRATION_BINDING_SUCCESS = 'DELETE_INTEGRATION_BINDING_SUCCESS';
export const DELETE_INTEGRATION_BINDING_FAILED = 'DELETE_INTEGRATION_BINDING_FAILED';

/* Incoming Action Type */
export const FETCH_INCOMING_SAGA = 'FETCH_INCOMING_SAGA';
export const FETCH_INCOMING_SUCCESS = 'FETCH_INCOMING_SUCCESS';
export const FETCH_INCOMING_FAILED = 'FETCH_INCOMING_FAILED';
export const STORE_INCOMING = 'STORE_INCOMING';

/* Queue Action Types */
export const FETCH_QUEUES_SAGA = 'FETCH_QUEUES';
export const STORE_QUEUES = 'STORE_QUEUES';
export const FETCH_QUEUES_SUCCESS = 'FETCH_QUEUES_SUCCESS';
export const FETCH_QUEUES_FAILED = 'FETCH_QUEUES_FAILED';

export const FETCH_AGENTS_SAGA = 'FETCH_AGENTS';
export const STORE_AGENTS = 'STORE_AGENTS';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILED = 'FETCH_AGENTS_FAILED';

export const FETCH_QUEUE_EVENTS_STATISTICS = 'FETCH_QUEUE_EVENTS_STATISTICS';
export const FETCH_QUEUE_EVENTS_STATISTICS_SUCCESS = 'FETCH_QUEUE_EVENTS_STATISTICS_SUCCESS';
export const FETCH_QUEUE_EVENTS_STATISTICS_FAILED = 'FETCH_QUEUE_EVENTS_STATISTICS_FAILED';

export const STORE_QUEUE_EVENTS_STATISTICS = 'STORE_QUEUE_EVENTS_STATISTICS';

/* PBX Admin Users Actions */
export const CREATE_PBX_ADMIN_USERS_SAGA = 'CREATE_PBX_ADMIN_USERS_SAGA';
export const CREATE_PBX_ADMIN_USERS_SUCCESS = 'CREATE_PBX_ADMIN_USERS_SUCCESS';
export const CREATE_PBX_ADMIN_USERS_FAILED = 'CREATE_PBX_ADMIN_USERS_FAILED';
export const STORE_PBX_ADMIN_USERS = 'STORE_PBX_ADMIN_USERS';

/* Polycom device provisioning */
export const FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA = 'FETCH_POLYCOM_DEVICE_CONFIGURATION_SAGA';
export const STORE_POLYCOM_DEVICE_CONFIGURATIONS = 'STORE_POLYCOM_DEVICE_CONFIGURATION';
export const FETCH_POLYCOM_DEVICE_CONFIGURATIONS_FAILED =
    'FETCH_POLYCOM_DEVICE_CONFIGURATION_FAILED';
export const FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SUCCESS =
    'FETCH_POLYCOM_DEVICE_CONFIGURATION_SUCCESS';

export const CREATE_POLYCOM_DEVICE_CONFIGURATION_SAGA = 'CREATE_POLYCOM_DEVICE_CONFIGURATION_SAGA';
export const CREATE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS =
    'CREATE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS';
export const CREATE_POLYCOM_DEVICE_CONFIGURATION_FAILED =
    'CREATE_POLYCOM_DEVICE_CONFIGURATION_FAILED';

export const UPDATE_POLYCOM_DEVICE_CONFIGURATION_SAGA = 'UPDATE_POLYCOM_DEVICE_CONFIGURATION_SAGA';
export const UPDATE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS =
    'UPDATE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS';
export const UPDATE_POLYCOM_DEVICE_CONFIGURATION_FAILED =
    'UPDATE_POLYCOM_DEVICE_CONFIGURATION_FAILED';

export const DELETE_POLYCOM_DEVICE_CONFIGURATION_SAGA = 'DELETE_POLYCOM_DEVICE_CONFIGURATION_SAGA';
export const DELETE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS =
    'DELETE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS';
export const DELETE_POLYCOM_DEVICE_CONFIGURATION_FAILED =
    'DELETE_POLYCOM_DEVICE_CONFIGURATION_FAILED';

/* Polycom provisioning configuration template  */
export const FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA =
    'FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA';
export const STORE_POLYCOM_CONFIGURATION_TEMPLATES = 'STORE_POLYCOM_CONFIGURATION_TEMPLATES';
export const FETCH_POLYCOM_CONFIGURATION_TEMPLATES_FAILED =
    'FETCH_POLYCOM_CONFIGURATION_TEMPLATES_FAILED';
export const FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SUCCESS =
    'FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SUCCESS';

export const CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA =
    'CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA';
export const CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS =
    'CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS';
export const CREATE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED =
    'CREATE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED';

export const UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA =
    'UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA';
export const UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS =
    'UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS';
export const UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED =
    'UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED';

export const DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA =
    'DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA';
export const DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS =
    'DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS';
export const DELETE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED =
    'DELETE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED';

/* Polycom provisioning reload  */
export const CREATE_POLYCOM_RELOAD_SAGA = 'CREATE_POLYCOM_RELOAD_SAGA';
export const CREATE_POLYCOM_RELOAD_SUCCESS = 'CREATE_POLYCOM_RELOAD_SUCCESS';
export const CREATE_POLYCOM_RELOAD_FAILED = 'CREATE_POLYCOM_RELOAD_FAILED';

/* Phone Numbers Configs Actions */
export const FETCH_CUSTOMER_PHONE_NUMBERS_SAGA = 'FETCH_CUSTOMER_PHONE_NUMBERS_SAGA';
export const STORE_CUSTOMER_PHONE_NUMBERS = 'STORE_CUSTOMER_PHONE_NUMBERS';
export const FETCH_CUSTOMER_PHONE_NUMBERS_FAILED = 'FETCH_CUSTOMER_PHONE_NUMBERS_FAILED';
export const FETCH_CUSTOMER_PHONE_NUMBERS_SUCCESS = 'FETCH_CUSTOMER_PHONE_NUMBERS_SUCCESS';

export const CREATE_CUSTOMER_PHONE_NUMBER = 'CREATE_CUSTOMER_PHONE_NUMBER';
export const CREATE_CUSTOMER_PHONE_NUMBER_SUCCESS = 'CREATE_CUSTOMER_PHONE_NUMBER_SUCCESS';
export const CREATE_CUSTOMER_PHONE_NUMBER_FAILED = 'CREATE_CUSTOMER_PHONE_NUMBER_FAILED';

export const UPDATE_CUSTOMER_PHONE_NUMBER = 'UPDATE_CUSTOMER_PHONE_NUMBER';
export const UPDATE_CUSTOMER_PHONE_NUMBER_SUCCESS = 'UPDATE_CUSTOMER_PHONE_NUMBER_SUCCESS';
export const UPDATE_CUSTOMER_PHONE_NUMBER_FAILED = 'UPDATE_CUSTOMER_PHONE_NUMBER_FAILED';

export const DELETE_CUSTOMER_PHONE_NUMBER = 'DELETE_CUSTOMER_PHONE_NUMBER';
export const DELETE_CUSTOMER_PHONE_NUMBER_SUCCESS = 'DELETE_CUSTOMER_PHONE_NUMBER_SUCCESS';
export const DELETE_CUSTOMER_PHONE_NUMBER_FAILED = 'DELETE_CUSTOMER_PHONE_NUMBER_FAILED';

/* Provider list table dr_carriers in db sipproxy */
export const FETCH_PROVIDERS_LIST_SAGA = 'FETCH_PROVIDERS_LIST_SAGA';
export const STORE_PROVIDERS_LIST = 'STORE_PROVIDERS_LIST';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_LIST_FAILED = 'FETCH_PROVIDERS_LIST_FAILED';

/* CDR list table cdr in db sipproxy */
export const FETCH_CUSTOMER_CDR_PROXY_SAGA = 'FETCH_CUSTOMER_CDR_PROXY_SAGA';
export const STORE_CUSTOMER_CDR_PROXY = 'STORE_CUSTOMER_CDR_PROXY';
export const FETCH_CUSTOMER_CDR_PROXY_FAILED = 'FETCH_CUSTOMER_CDR_PROXY_FAILED';
export const FETCH_CUSTOMER_CDR_PROXY_SUCCESS = 'FETCH_CUSTOMER_CDR_PROXY_SUCCESS';

/* User Action Creators */
export const fetchCustomerUsers = (payload) => ({type: FETCH_USERS_SAGA, payload});
export const createCustomerUser = (payload) => ({type: CREATE_USER_SAGA, payload});
export const updateCustomerUser = (payload) => ({type: UPDATE_USER_SAGA, payload});
export const deleteCustomerUser = (payload) => ({type: DELETE_USER_SAGA, payload});
export const resendCustomerUserInvitation = (payload) => ({type: RESEND_INVITATION_SAGA, payload});

/* Extension Action Creators*/
export const fetchCustomerExtensions = (payload) => ({type: FETCH_EXTENSIONS_SAGA, payload});
export const fetchCustomerExtensionSettings = (payload) => ({
    type: FETCH_EXTENSION_SETTINGS_SAGA,
    payload,
});

export const fetchNewCustomerExtensions = (payload) => ({type: FETCH_NEW_EXTENSIONS_SAGA, payload});
export const fetchCustomerNewPortalAccess = (payload) => ({type: FETCH_CUSTOMER_NEW_PORTAL_ACCESS_SAGA, payload});

export const createCustomerExtension = (payload) => ({type: CREATE_EXTENSION_SAGA, payload});
export const updateCustomerExtension = (payload) => ({type: UPDATE_EXTENSION_SAGA, payload});
export const updateSipCustomerExtension = (payload) => ({type: UPDATE_SIP_EXTENSION_SAGA, payload});
export const deleteCustomerExtension = (payload) => ({type: DELETE_EXTENSION_SAGA, payload});

/* Customer Action Creators */
export const fetchCustomers = (payload) => ({type: FETCH_CUSTOMERS_SAGA, payload});
export const fetchCustomer = (payload) => ({type: FETCH_CUSTOMER_SAGA, payload});
export const createCustomer = (payload) => ({type: CREATE_CUSTOMER_SAGA, payload});
export const updateCustomer = (payload) => ({type: UPDATE_CUSTOMER_SAGA, payload});

/* Journeys Action Creators */
export const fetchCustomerJourneys = (payload) => ({type: FETCH_JOURNEYS_SAGA, payload});
export const fetchCustomerJourney = (payload) => ({type: FETCH_JOURNEY_SAGA, payload});

/* Team Action Creators*/
export const fetchCustomerTeams = (payload) => ({type: FETCH_TEAMS_SAGA, payload});
export const createCustomerTeam = (payload) => ({type: CREATE_TEAM_SAGA, payload});
export const updateCustomerTeam = (payload) => ({type: UPDATE_TEAM_SAGA, payload});
export const deleteCustomerTeam = (payload) => ({type: DELETE_TEAM_SAGA, payload});

/* Role Action Creators*/
export const fetchCustomerRoles = (payload) => ({type: FETCH_ROLES_SAGA, payload});
export const createCustomerRole = (payload) => ({type: CREATE_ROLE_SAGA, payload});
export const updateCustomerRole = (payload) => ({type: UPDATE_ROLE_SAGA, payload});
export const deleteCustomerRole = (payload) => ({type: DELETE_ROLE_SAGA, payload});

/* Resource Types Action Creators */
export const fetchCustomerResourceTypes = () => ({type: FETCH_RESOURCE_TYPES_SAGA});

/* Policy Action Creators*/
export const fetchCustomerPolicies = (payload) => ({type: FETCH_POLICIES_SAGA, payload});
export const createCustomerPolicy = (payload) => ({type: CREATE_POLICY_SAGA, payload});
export const updateCustomerPolicy = (payload) => ({type: UPDATE_POLICY_SAGA, payload});
export const deleteCustomerPolicy = (payload) => ({type: DELETE_POLICY_SAGA, payload});

/* Team User Action Creators*/
export const fetchCustomerTeamUsers = (payload) => ({type: FETCH_TEAM_USERS_SAGA, payload});
export const createCustomerTeamUser = (payload) => ({type: CREATE_TEAM_USER_SAGA, payload});
export const updateCustomerTeamUser = (payload) => ({type: UPDATE_TEAM_USER_SAGA, payload});
export const deleteCustomerTeamUser = (payload) => ({type: DELETE_TEAM_USER_SAGA, payload});

/* User Role Action Creators*/
export const fetchCustomerUserRoles = (payload) => ({type: FETCH_USER_ROLES_SAGA, payload});
export const createCustomerUserRole = (payload) => ({type: CREATE_USER_ROLE_SAGA, payload});
export const updateCustomerUserRole = (payload) => ({type: UPDATE_USER_ROLE_SAGA, payload});
export const deleteCustomerUserRole = (payload) => ({type: DELETE_USER_ROLE_SAGA, payload});

/* Fax Configs Action Creators*/
export const fetchCustomerFaxConfigs = (payload) => ({type: FETCH_FAX_CONFIGS_SAGA, payload});
export const createCustomerFaxConfig = (payload) => ({type: CREATE_FAX_CONFIG_SAGA, payload});
export const updateCustomerFaxConfig = (payload) => ({type: UPDATE_FAX_CONFIG_SAGA, payload});
export const deleteCustomerFaxConfig = (payload) => ({type: DELETE_FAX_CONFIG_SAGA, payload});

/* Fax Bindings Action Creators*/
export const fetchCustomerFaxBindings = (payload) => ({type: FETCH_FAX_BINDINGS_SAGA, payload});
export const createCustomerFaxBinding = (payload) => ({type: CREATE_FAX_BINDING_SAGA, payload});
export const updateCustomerFaxBinding = (payload) => ({type: UPDATE_FAX_BINDING_SAGA, payload});
export const deleteCustomerFaxBinding = (payload) => ({type: DELETE_FAX_BINDING_SAGA, payload});

/* Sms Configs Action Creators */
export const fetchCustomerSmsConfigs = (payload) => ({type: FETCH_SMS_CONFIGS_SAGA, payload});
export const createCustomerSmsConfig = (payload) => ({type: CREATE_SMS_CONFIG_SAGA, payload});
export const updateCustomerSmsConfig = (payload) => ({type: UPDATE_SMS_CONFIG_SAGA, payload});
export const deleteCustomerSmsConfig = (payload) => ({type: DELETE_SMS_CONFIG_SAGA, payload});

/* Sms Bindings Action Creators */
export const fetchCustomerSmsBindings = (payload) => ({type: FETCH_SMS_BINDINGS_SAGA, payload});
export const createCustomerSmsBinding = (payload) => ({type: CREATE_SMS_BINDING_SAGA, payload});
export const updateCustomerSmsBinding = (payload) => ({type: UPDATE_SMS_BINDING_SAGA, payload});
export const deleteCustomerSmsBinding = (payload) => ({type: DELETE_SMS_BINDING_SAGA, payload});

/* Sms Bandwidth Application Action Creators */
export const fetchBandwidthApplication = (payload) => ({type: FETCH_SMS_BANDWIDTH_SAGA, payload});

/* Sms Bindings Users Action Creators */
export const fetchCustomerSmsBindingsUsers = (payload) => ({
    type: FETCH_SMS_BINDINGS_USERS_SAGA,
    payload,
});

/* Language Action Creators */
export const fetchCustomerLanguages = (payload) => ({type: FETCH_LANGUAGES_SAGA, payload});

/* API Key Action Creators*/
export const fetchCustomerApiKeys = (payload) => ({type: FETCH_API_KEYS_SAGA, payload});
export const createCustomerApiKey = (payload) => ({type: CREATE_API_KEY_SAGA, payload});
export const updateCustomerApiKey = (payload) => ({type: UPDATE_API_KEY_SAGA, payload});
export const deleteCustomerApiKey = (payload) => ({type: DELETE_API_KEY_SAGA, payload});

/* Croo Talk Pro Module Action Creators */
export const fetchCustomerProModule = (payload) => ({type: FETCH_USER_PRO_SAGA, payload});
export const createCustomerProModule = (payload) => ({type: CREATE_USER_PRO, payload});

/* Power Broker*/
export const fetchPowerBrokerApiKey = (payload) => ({type: FETCH_POWER_BROKER_API_KEY, payload});
export const createPowerBrokerApiKey = (payload) => ({type: CREATE_POWER_BROKER_API_KEY, payload});
export const deletePowerBrokerApiKey = (payload) => ({type: DELETE_POWER_BROKER_API_KEY, payload});

/* Integration action creators*/
export const fetchIntegrationAccess = (payload) => ({type: FETCH_INTEGRATION_ACCESS_SAGA, payload});
export const fetchIntegrationUsers = (payload) => ({type: FETCH_INTEGRATION_USERS_SAGA, payload});
export const updateIntegrationBinding = (payload) => ({
    type: UPDATE_INTEGRATION_BINDING_SAGA,
    payload,
});
export const deleteIntegrationBinding = (payload) => ({
    type: DELETE_INTEGRATION_BINDING_SAGA,
    payload,
});
export const deleteIntegration = (payload) => ({type: DELETE_INTEGRATION_SAGA, payload});

export const fetchCustomerQueues = (payload) => ({type: FETCH_QUEUES_SAGA, payload});
export const fetchCustomerAgents = (payload) => ({type: FETCH_AGENTS_SAGA, payload});
export const fetchCustomerQueueEventsStatistics = (payload) => ({
    type: FETCH_QUEUE_EVENTS_STATISTICS,
    payload,
});

export const fetchIncoming = (payload) => ({type: FETCH_INCOMING_SAGA, payload});

/* PBX Admin Users Actions Creators */
export const createPbxAdminUsers = (payload) => ({type: CREATE_PBX_ADMIN_USERS_SAGA, payload});

/* Phone Numbers Action Creators */
export const fetchCustomerPhoneNumbers = (payload) => ({
    type: FETCH_CUSTOMER_PHONE_NUMBERS_SAGA,
    payload,
});
export const createCustomerPhoneNumber = (payload) => ({
    type: CREATE_CUSTOMER_PHONE_NUMBER,
    payload,
});
export const updateCustomerPhoneNumber = (payload) => ({
    type: UPDATE_CUSTOMER_PHONE_NUMBER,
    payload,
});
export const deleteCustomerPhoneNumber = (payload) => ({
    type: DELETE_CUSTOMER_PHONE_NUMBER,
    payload,
});

/* Provider list table dr_carriers in db sipproxy */
export const fetchProviderListPhoneNumber = (payload) => ({
    type: FETCH_PROVIDERS_LIST_SAGA,
    payload,
});

/* CDR Calls Action Creators */
export const fetchCustomerCDRProxy = (payload) => ({
    type: FETCH_CUSTOMER_CDR_PROXY_SAGA,
    payload,
});

/* Polycom */
export const fetchCustomerDeviceProvisioningConfiguration = (payload) => ({
    type: FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA,
    payload,
});
export const createCustomerDeviceProvisioningConfiguration = (payload) => ({
    type: CREATE_POLYCOM_DEVICE_CONFIGURATION_SAGA,
    payload,
});
export const updateCustomerDeviceProvisioningConfiguration = (payload) => ({
    type: UPDATE_POLYCOM_DEVICE_CONFIGURATION_SAGA,
    payload,
});
export const deleteCustomerDeviceProvisioningConfiguration = (payload) => ({
    type: DELETE_POLYCOM_DEVICE_CONFIGURATION_SAGA,
    payload,
});

export const fetchCustomerProvisioningConfigurationTemplates = (payload) => ({
    type: FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA,
    payload,
});
export const createCustomerProvisioningConfigurationTemplate = (payload) => ({
    type: CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA,
    payload,
});
export const updateCustomerProvisioningConfigurationTemplate = (payload) => ({
    type: UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA,
    payload,
});
export const deleteCustomerProvisioningConfigurationTemplate = (payload) => ({
    type: DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA,
    payload,
});

export const createCustomerProvisioningReload = (payload) => ({
    type: CREATE_POLYCOM_RELOAD_SAGA,
    payload,
});
