/* User Action types */
export const STORE_LOGIN = 'user/ STORE_LOGIN';
export const COGNITO_LOGIN_SAGA = 'user/cognito/LOGIN_SAGA';
export const COGNITO_LOGIN_FAILED = 'user/cognito/LOGIN_FAILED';
export const COGNITO_LOGIN_SUCCESS = 'user/cognito/LOGIN_SUCCESS';

export const COGNITO_UPDATE_EMAIL_FAILED = 'user/cognito/EMAIL_UPDATE_FAILED';
export const COGNITO_UPDATE_EMAIL_SAGA = 'user/cognito/EMAIL_UPDATE_SAGA';
export const COGNITO_UPDATE_EMAIL_SUCCESS = 'user/cognito/EMAIL_UPDATE_SUCCESS';
export const STORE_EMAIL = 'user/cognito/EMAIL_UPDATE';

export const COGNITO_CHANGE_PASSWORD_SAGA = 'user/cognito/CHANGE_PASSWORD_REQUEST';
export const COGNITO_CHANGE_PASSWORD_FAILED = 'user/cognito/CHANGE_PASSWORD_REQUEST_FAILED';
export const COGNITO_CHANGE_PASSWORD_SUCCESS = 'user/cognito/PASSWORD_CHANGED';

export const USER_PROFILE_FAILED = 'user/LOGIN_FAILED';
export const USER_PROFILE_SAGA = 'user/PROFILE_SAGA';
export const USER_PROFILE_SUCCESS = 'user/USER_PROFILE_SUCCESS';

export const USER_PROFILE_CONFIRM_SAGA = 'user/USER_PROFILE_CONFIRM_SAGA';
export const USER_PROFILE_CONFIRM_SUCCESS = 'user/USER_PROFILE_CONFIRM_SUCCESS';
export const USER_PROFILE_CONFIRM_FAILED = 'user/USER_PROFILE_CONFIRM_FAILED';

export const USER_LOGOUT_SAGA = 'user/USER_LOGOUT_SAGA';

export const FETCH_ENDPOINT_SAGA = 'user/FETCH_ENDPOINT_SAGA';
export const FETCH_ENDPOINT_FAILED = 'user/FETCH_ENDPOINT_FAILED';
export const FETCH_ENDPOINT_SUCCESS = 'user/FETCH_ENDPOINT_SUCCESS';
export const STORE_ENDPOINT = 'user/STORE_ENDPOINT';

export const UPDATE_PROFILE_SAGA = 'user/UPDATE_PROFILE_SAGA';
export const UPDATE_PROFILE_FAILED = 'user/UPDATE_PROFILE_FAILED';
export const UPDATE_PROFILE_SUCCESS = 'user/UPDATE_PROFILE_SUCCESS';
export const UPDATE_DASHBOARD_SAGA = 'user/UPDATE_DASHBOARD_SAGA';
export const STORE_PROFILE = 'user/STORE_PROFILE';

export const USER_REGISTER_FAILED = 'user/REGISTER_FAILED';
export const USER_REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
export const USER_REGISTER_SAGA = 'user/REGISTER_SAGA';

export const USER_TRAVEL_SAGA = 'user/TRAVEL_SAGA';
export const STORE_TRAVEL = 'user/STORE_TRAVEL';
export const STOP_TRAVEL = 'user/STOP_TRAVEL';

export const FETCH_USER_LITE_CREDS_SAGA = 'user/FETCH_USER_LITE_CREDS';
export const STORE_USER_LITE_CREDS = 'user/STORE_USER_LITE_CREDS';
export const UPDATE_USER_LITE_CREDS = 'user/UPDATE_USER_LITE_CREDS';

/* User Action Creators */
export const cognitoLogin = () => ({type: COGNITO_LOGIN_SAGA});
export const cognitoChangePassword = (payload) => ({type: COGNITO_CHANGE_PASSWORD_SAGA, payload});
export const userLogout = () => ({type: USER_LOGOUT_SAGA});
export const userConfirmProfile = () => ({type: USER_PROFILE_CONFIRM_SAGA});
export const updateProfile = (payload) => ({type: UPDATE_PROFILE_SAGA, payload});
export const updateDashboard = (payload) => ({type: UPDATE_DASHBOARD_SAGA, payload});
export const registerUser = (payload) => ({type: USER_REGISTER_SAGA, payload});
export const travelTo = (payload) => ({type: USER_TRAVEL_SAGA, payload});
export const stopTravel = () => ({type: STOP_TRAVEL});

export const fetchUserLiteCredentials = (payload) => ({type: FETCH_USER_LITE_CREDS_SAGA, payload});
export const updateUserLiteCredentials = (payload) => ({type: UPDATE_USER_LITE_CREDS, payload});
