import React, {useEffect, useReducer} from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';
import {sortArrayByObjKey, sortArrayByObjKeyReverted} from '../../../utils';
import isEqual from 'lodash.isequal';
import List from '../List/List';
import Spinner from '../../spinner/Spinner';
import AutoSizer from 'react-virtualized-auto-sizer';
import './Table.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons';

const TableBodyCell = ({column, handles, item}) => {
    return item ? (
        <div
            className={`d-flex flex-row align-items-center croo-table-cell ${column.className}`}
            style={column.style}
        >
            {column.key ? item[column.key] : column.cellFactory(item, handles)}
        </div>
    ) : null;
};

const cycleSort = (column, sort) => {
    if (!sort || column.id !== sort.column.id) {
        return {column: column, type: 'asc'};
    } else {
        switch (sort.type) {
            case 'asc':
                return {column: column, type: 'desc'};
            case 'desc':
                return null;
            default:
                return {column: column, type: 'asc'};
        }
    }
};

const TableHeaderCell = ({column, handles, items, sort, sortDispatch}) => {
    return (
        <div
            className={`${classnames(
                'd-flex flex-row justify-content-between align-items-center croo-table-header',
                {'cursor-pointer': column.sort}
            )}`}
            onClick={() => {
                column.sort && sortDispatch(cycleSort(column, sort));
            }}
        >
            {column.title !== undefined
                ? column.title
                : column.head
                ? column.head(items, handles)
                : null}
            {column.sort && (
                <span className={classnames('ml-auto mr-1 fa-layers fa-fw fa-lg')}>
                    <FontAwesomeIcon
                        icon={faSortUp}
                        className={`text-${
                            sort && column.id === sort.column.id && sort.type === 'asc'
                                ? 'primary'
                                : 'light'
                        }`}
                    />
                    <FontAwesomeIcon
                        icon={faSortDown}
                        className={`text-${
                            sort && column.id === sort.column.id && sort.type === 'desc'
                                ? 'primary'
                                : 'light'
                        }`}
                    />
                </span>
            )}
        </div>
    );
};

const TableFooterCell = ({column, handles, items}) => {
    return (
        <div className={'d-flex flex-row align-items-center croo-table-cell sticky-bottom'}>
            {column.foot ? column.foot(items, handles) : null}
        </div>
    );
};

const columns_grid_style = (columns) => ({
    display: 'grid',
    gridTemplateColumns: columns.map((c) => (c.width ? c.width : 'auto')).join(' '),
});

const RowWrapper = ({children, className, columns, style}) => {
    return (
        <div
            className={`w-100 croo-table-row ${className}`}
            style={{...columns_grid_style(columns), ...style}}
        >
            {children}
        </div>
    );
};

const SplitRowWrapper = ({children, style}) => <div style={style}>{children}</div>;

const TableHeader = ({columns, handles, items, sort, sortDispatch, style}) => {
    return (
        <RowWrapper columns={columns} style={style} className={'croo-table-header-row'}>
            {columns.map((column, key) => (
                <TableHeaderCell
                    column={column}
                    handles={handles}
                    items={items}
                    key={key}
                    sort={sort}
                    sortDispatch={sortDispatch}
                />
            ))}
        </RowWrapper>
    );
};

const TableFooter = ({columns, items, handles, style}) => {
    return (
        <RowWrapper columns={columns} style={style}>
            {columns.map((column, key) => (
                <TableFooterCell column={column} handles={handles} items={items} key={key} />
            ))}
        </RowWrapper>
    );
};

const RowRenderer = ({data, index, style}) => {
    let {columns, handles, items} = data;
    return (
        <RowWrapper columns={columns} style={style}>
            {columns.map((c, i) => (
                <TableBodyCell column={c} handles={handles} key={i} item={items[index]} />
            ))}
        </RowWrapper>
    );
};

const InfiniteRowRenderer = ({data, index, style}) => {
    return index < data.items.length && index >= 0 ? (
        RowRenderer({data, index, style})
    ) : (
        <SplitRowWrapper style={style}>
            <Spinner
                background={'white'}
                className={'my-3'}
                color={'primary'}
                global={false}
                size={25}
            />
        </SplitRowWrapper>
    );
};

export const Table = ({
    columns,
    foot,
    handles,
    head,
    infinity,
    itemKey,
    items,
    itemSize,
    onSort,
    itemsDispatch,
}) => {
    const [sort, sortDispatch] = useReducer((state, action) => {
        if (isEqual(state, action)) {
            return state;
        } else {
            return action;
        }
    }, null);

    const loadMore = (start) => {
        if (infinity && !infinity.isLoadingMore) infinity.loadMore(start);
    };

    useEffect(() => {
        onSort(items, sort, itemsDispatch);
    }, [onSort, items, itemsDispatch, sort]);

    return (
        <div
            className={'croo-table d-flex flex-column flex-grow-1 align-items-stretch'}
            style={{overflowX: 'hidden'}}
        >
            <div className={'d-flex flex-column flex-grow-1 align-items-stretch'}>
                {head ? (
                    <TableHeader
                        columns={columns}
                        handles={handles}
                        items={items}
                        sort={sort}
                        sortDispatch={sortDispatch}
                    />
                ) : (
                    <div className={'border-bottom'} />
                )}
                <div className={'flex-grow-1'}>
                    <AutoSizer>
                        {({height, width}) => (
                            <div className={'d-flex flex-column'}>
                                <List
                                    className={`flex-grow-1 croo-table-list-container`}
                                    hasMore={infinity ? infinity.hasMore : false}
                                    height={height}
                                    infinite={!!infinity}
                                    isLoadingMore={infinity ? infinity.isLoadingMore : undefined}
                                    itemCount={items.length}
                                    itemData={{columns, handles, infinity, items}}
                                    itemKey={itemKey}
                                    itemRenderer={infinity ? InfiniteRowRenderer : RowRenderer}
                                    items={items}
                                    itemSize={itemSize}
                                    loadMore={loadMore}
                                    minimumBatchSize={
                                        infinity ? infinity.minimumBatchSize : undefined
                                    }
                                    sort={itemsDispatch && sort && sort.type ? sort.type : null}
                                    threshold={infinity ? infinity.threshold : undefined}
                                    width={width}
                                />
                            </div>
                        )}
                    </AutoSizer>
                </div>
                {foot && <TableFooter columns={columns} handles={handles} items={items} />}
            </div>
        </div>
    );
};

const tableSort = (items, sort, itemsDispatch) => {
    if (itemsDispatch && sort && sort.type !== null) {
        if (sort.column.sort.key) {
            if (sort.type === 'asc') {
                itemsDispatch(sortArrayByObjKey(items, sort.column.sort.key));
            } else if (sort.type === 'desc') {
                itemsDispatch(sortArrayByObjKeyReverted(items, sort.column.sort.key));
            }
        } else if (sort.column.sort.comparator) {
            if (sort.type === 'asc') {
                itemsDispatch(items.sort(sort.column.sort.comparator));
            } else if (sort.type === 'desc') {
                itemsDispatch(items.sort(sort.column.sort.comparator).reverse());
            }
        }
    }
};

Table.propTypes = {
    head: PropTypes.bool,
    foot: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            className: PropTypes.string,
            head: PropTypes.bool,
            cellFactory: PropTypes.func,
            key: PropTypes.string,
            style: PropTypes.object,
            foot: PropTypes.bool,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    itemKey: PropTypes.func,
    itemSize: PropTypes.number.isRequired,
    items: PropTypes.array,
    onSort: PropTypes.func,
    itemsDispatch: PropTypes.func,
    infinity: PropTypes.shape({
        hasMore: PropTypes.bool,
        isLoadingMore: PropTypes.bool,
        loadMore: PropTypes.func.isRequired,
        minimumBatchSize: PropTypes.number,
        threshold: PropTypes.number,
    }),
};

Table.defaultProps = {
    columns: [],
    foot: false,
    head: true,
    itemKey: (index) => index,
    items: [],
    onSort: tableSort,
    handles: {},
};
