import React from 'react';
import {Container} from 'reactstrap';

interface PropsWrapper {
    //TODO: Change the any type of children
    children?: any;
    limitedWidth?: boolean;
    scroll?: boolean;
}

const ContentWrapper = ({children, limitedWidth, scroll}: PropsWrapper) => (
    <Container
        className={'mt--4 d-flex flex-grow-1 flex-column '}
        fluid={!limitedWidth}
        style={{overflowY: scroll ? 'auto' : 'hidden', paddingTop: '1.5rem'}}
    >
        {children}
    </Container>
);

export default ContentWrapper;
