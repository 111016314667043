import React, {useState} from 'react';
import {Button, Card, CardBody, CardText, CardTitle, Nav} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import DangerModal from '../../../components/notification/DangerModal';
import {useDispatch} from 'react-redux';
import {deleteIntegration} from '../../../store/actions/customer-action';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import FormSection from '../../../components/form/FormSection';

const IntegrationSettings = ({toggle, integration}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);

    const reduxDispatch = useDispatch();

    const deleteIntegration_ = () => {
        setCloseAll(true);
        reduxDispatch(deleteIntegration(integration.name));
        toggle();
    };

    return (
        <Nav vertical className={'pb-2'}>
            <FormSection
                icon={faCog}
                isOpenByDefault={true}
                title={<FormattedMessage id='message.admin.advanced' defaultMessage='Advanced' />}
            >
                <FormattedMessage
                    id='message.admin.delete.integration'
                    defaultMessage={`Delete Integration`}
                >
                    {(m) => {
                        return (
                            <>
                                <Card>
                                    <CardBody>
                                        <CardTitle className={'text-danger'}>{m}</CardTitle>
                                        <CardText className={'text-muted'}>
                                            <small>
                                                <FormattedMessage
                                                    id={
                                                        'message.admin.delete.integration.explanation'
                                                    }
                                                    defaultMessage={
                                                        'This integration and all of its bindings will be removed'
                                                    }
                                                />
                                            </small>
                                        </CardText>
                                        <Button
                                            color={'danger'}
                                            size={'sm'}
                                            onClick={() => setDeleteModal(!deleteModal)}
                                        >
                                            {m}
                                        </Button>
                                    </CardBody>
                                </Card>
                            </>
                        );
                    }}
                </FormattedMessage>
                <DangerModal
                    isOpen={deleteModal}
                    toggle={() => setDeleteModal(!deleteModal)}
                    onClosed={() => (closeAll ? toggle() : undefined)}
                    title={
                        <FormattedMessage
                            id={'message.admin.delete.integration'}
                            defaultMessage={'Delete Integration'}
                        />
                    }
                    content={
                        <FormattedMessage
                            id={'message.admin.delete.integration.confirmation'}
                            defaultMessage={
                                'Are you sure you wish to delete this integration? This operation cannot be undone.'
                            }
                        />
                    }
                    onClick={deleteIntegration_}
                />
            </FormSection>
        </Nav>
    );
};

IntegrationSettings.propTypes = {};

export default IntegrationSettings;
