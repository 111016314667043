import React, {useCallback, useEffect, useReducer, useState} from 'react';
import * as PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
    getNewCallHistory,
    getNewCallsLoading,
    getNewUnfilteredCallHistory,
    getContinuationTokenNewCallHistory,
} from '../../store/selectors/cdr-selectors';
import {
    newPortalCustomerAccessSelector,
    newPortalCustomerAccessSuccessSelector,
} from '../../store/selectors/customer-selectors';

import {fetchNewCallHistory} from '../../store/actions/cdr-action';

import {fetchNewCustomerExtensions} from '../../store/actions/customer-action';
import {fetchCustomerNewPortalAccess} from '../../store/actions/customer-action';
import {createFileDownload} from '../../store/actions/download-action';
import {FilterDisplays} from './filters/FilterDisplays';
import FilterFactories, {filters as FILTERS} from './newCallFilters/FilterFactories';
import {build_call_history_query} from './new_calls_elastic_search_query_builder';
import {status_color, status_icon, status_label} from '../../utils/call_history';
import {FormattedMessage, injectIntl} from 'react-intl';
import {status_filter} from './newCallFilters/Status';
import isEqual from 'lodash.isequal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getUser, userRequestCustomerSelector} from '../../store/selectors/user-selectors';
import {useListReducer, useObjectReducer, useQuery} from '../../utils/hooks';
import {useHistory} from 'react-router-dom';
import Spinner from '../spinner/Spinner';
import {name_filter} from './filters/Name';
import {Table} from '../layout/Table/Table';
import {direction_filter} from './newCallFilters/Direction';
import {extension_filter} from './filters/Extension';
import CheckboxInput from '../input/CheckboxInput';
import {Has} from '../security/Has';
import {faLink, faUnlink} from '@fortawesome/free-solid-svg-icons';
import {getDownloadLoading} from '../../store/selectors/download-selectors';
import ModalDownload from './modals/ModalDownload';
import {Button, CardBody, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';
import {newColumns} from './new_call_history_table_descriptor';
import {from_number_filter} from './newCallFilters/FromNumber';
import {to_number_filter} from './newCallFilters/ToNumber';
import moment from 'moment';

const is_filter = (key) => {
    return Object.keys(FILTERS).some((k) => key.startsWith(k));
};

const extract_query_filters = (query) => {
    const results = {};
    query.forEach((value, key) => {
        const filterKey = Object.keys(FILTERS).find((k) => key.startsWith(k));
        const filter = filterKey && FILTERS[filterKey];
        if (filter) {
            const decoded = decodeURIComponent(value);
            const result = filter.deserializer
                ? filter.deserializer(decoded)
                : filter.factory(decoded);
            if (result) results[key] = result;
        }
    });
    return results;
};

var TURNOVER = 500;
var THRESHOLD = 200;

const NewCallHistory = ({defaultFilters, intl, title}) => {
    const query = useQuery();
    const history = useHistory();

    const customer = useSelector(userRequestCustomerSelector);
    const calls = useSelector(getNewCallHistory);
    const newPortalAccess = useSelector(newPortalCustomerAccessSelector);
    const newPortalAccessSuccess = useSelector(newPortalCustomerAccessSuccessSelector);
    const unfilteredCalls = useSelector(getNewUnfilteredCallHistory);
    const continuationToken = useSelector(getContinuationTokenNewCallHistory)[0];
    const stats = {}; // useSelector(getCallsStatistics);
    const loading = useSelector(getNewCallsLoading);
    const user = useSelector(getUser);
    const downloadLoading = useSelector(getDownloadLoading);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [latestQuery, setLatestQuery] = useState({});

    const reduxDispatch = useDispatch();

    const total = 5;
    const [hasMore, setHasMore] = useState(false);
    const [filterFactoryActive, setFilterFactoryActive] = useState(false);
    const [onlyMyCalls, setOnlyMyCalls] = useState(false);
    const [sort, sortDispatch] = useReducer((state, action) => {
        return isEqual(state, action) ? state : action;
    }, null);

    const [showNewHistoryPage, setShowNewHistoryPage] = useState(true);
    const [showNewHistoryPageToggle, setShowNewHistoryPageToggle] = useState(false);
    const [filters, filtersDispatch] = useReducer((previousFilters, action) => {
        let newFilters = {...previousFilters};
        switch (action.type) {
            case 'add':
                if (action.payload.index === 'extension') {
                    setOnlyMyCalls(false);
                }
                newFilters[action.payload.index] = action.payload.filter;
                break;
            case 'remove':
                delete newFilters[action.payload.index];
                break;
            case 'clear':
                newFilters = {};
                break;
            default:
                return previousFilters;
        }
        return isEqual(previousFilters, newFilters) ? previousFilters : newFilters;
    }, extract_query_filters(query));

    const [extras, extrasDispatch] = useObjectReducer({
        tagMode: 'or',
        nameMode: 'or',
    });

    const [invisibleFilters, invisibleFiltersDispatch] = useListReducer(
        (f) => f.index,
        defaultFilters,
    );

    const fetchCallHistoryCallback = useCallback(
        (from, size, behaviour, token) => {
            const query = build_call_history_query(
                [
                    ...Object.values(invisibleFilters).map((f) => f.custom_filter),
                    ...Object.values(filters).map((f) => f.custom_filter),
                ],
                [],
                sort,
                size,
                from,
                extras,
            );
            if (query.list.filters.date && query.list.filters.date.start) {
                var utcStartDate = moment(query.list.filters.date.start).utc(false).format();
                query.list.filters.date.start = utcStartDate;
            }
            if (query.list.filters.date && query.list.filters.date.end) {
                var utcEndDate = moment(query.list.filters.date.end).utc(false).format();
                query.list.filters.date.end = utcEndDate;
            }

            if (token) {
                if (typeof token === 'string') {
                    query.list['continuationToken'] = token;
                }
            }
            setLatestQuery(query);
            reduxDispatch(
                fetchNewCallHistory({
                    params: query,
                    behaviour,
                }),
            );
        },
        [filters, invisibleFilters, reduxDispatch, sort, extras],
    );

    const replaceCalls = useCallback(() => {
        fetchCallHistoryCallback(1, TURNOVER, 'replace');
    }, [fetchCallHistoryCallback]);

    const extendCalls = useCallback(
        (start) => {
            if (start && continuationToken !== '') {
                return fetchCallHistoryCallback(
                    Object.values(unfilteredCalls).length,
                    TURNOVER,
                    'extend',
                    continuationToken,
                );
            } else {
                setHasMore(false);
            }
        },
        [unfilteredCalls, fetchCallHistoryCallback, continuationToken],
    );

    useEffect(() => {
        if (continuationToken) {
            setHasMore(true);
        } else {
            setHasMore(false);
        }
    }, [continuationToken]);

    useEffect(() => {
        const newQuery = new URLSearchParams();
        query.forEach((value, key) => {
            if (!is_filter(key)) newQuery.set(key, value);
        });
        Object.values(filters).forEach((f) =>
            newQuery.set(f.index, encodeURIComponent(f.serialized)),
        );
        history.push({search: newQuery.toString()});
        //eslint-disable-next-line
    }, [filters, showNewHistoryPage]);

    const addFilter = (filter) => {
        filtersDispatch({
            type: 'add',
            payload: {
                index: filter.index,
                filter: filter,
            },
        });
    };

    const removeFilter = (filter) =>
        filtersDispatch({
            type: 'remove',
            payload: {
                index: filter.index,
            },
        });

    const clearFilters = () =>
        filtersDispatch({
            type: 'clear',
        });

    useEffect(() => {
        if (onlyMyCalls) {
            invisibleFiltersDispatch({
                type: 'add',
                payload: extension_filter.factory(user.extension.toString()),
            });
            filtersDispatch({type: 'remove', payload: {index: 'extension'}});
        } else {
            invisibleFiltersDispatch({type: 'remove', payload: {index: 'extension'}});
        }
    }, [onlyMyCalls, invisibleFiltersDispatch, user.extension, showNewHistoryPage]);

    useEffect(() => {
        if (showNewHistoryPageToggle) {
            replaceCalls();
        }
    }, [replaceCalls, filters, sort, fetchCallHistoryCallback, customer, showNewHistoryPageToggle]);

    useEffect(() => {
        if (showNewHistoryPageToggle) {
            reduxDispatch(fetchNewCustomerExtensions());
        }
    }, [reduxDispatch, customer, showNewHistoryPageToggle]);

    useEffect(() => {
        if (!showNewHistoryPage) {
            history.push('/call_history');
        }
    }, [showNewHistoryPage, history]);

    useEffect(() => {
        setShowNewHistoryPageToggle(newPortalAccess.oldPortalAccess);
        if (newPortalAccess.oldPortalAccess === false) {
            history.push('/call_history');
        }
    }, [newPortalAccess, history, newPortalAccessSuccess, customer, setShowNewHistoryPageToggle]);
    useEffect(() => {
        reduxDispatch(fetchCustomerNewPortalAccess());
    }, [customer, reduxDispatch]);

    return (
        <>
            <CardHeader className={'border-bottom-0 d-flex flex-row align-items-baseline'}>
                <h2>{title}</h2>
                <FilterFactories addFilter={addFilter} setActive={setFilterFactoryActive} />
                {!filterFactoryActive && user.extension !== null && (
                    <span>
                        <Has
                            aPolicyMatching={[{action: 'READ', resourceType: 'CALLS'}]}
                            forbidTravel
                        >
                            <CheckboxInput
                                label={
                                    <FormattedMessage
                                        id={'toggle.show.only.my.calls'}
                                        defaultMessage={'Show only my calls'}
                                    />
                                }
                                onValueChange={setOnlyMyCalls}
                                value={onlyMyCalls}
                            />
                        </Has>
                    </span>
                )}

                {showNewHistoryPageToggle ? (
                    <CheckboxInput
                        label={
                            <FormattedMessage
                                id={'toggle.new.call.history'}
                                defaultMessage={'New Call History'}
                            />
                        }
                        onValueChange={setShowNewHistoryPage}
                        value={showNewHistoryPage}
                    />
                ) : (
                    <></>
                )}

                <ModalDownload
                    modal={modal}
                    toggle={toggle}
                    dispatch={reduxDispatch}
                    downloadFile={createFileDownload}
                    total={total}
                    params={latestQuery}
                    filters={filters}
                />
            </CardHeader>
            <CardBody style={{overflowY: 'auto'}} className={'d-flex flex-column flex-grow-1 p-0'}>
                <FilterDisplays
                    className={'py-2 px-4'}
                    filters={Object.values(filters)}
                    removeFilter={removeFilter}
                    clearFilters={clearFilters}
                    scrollable={false}
                    group
                    groupAction={{
                        tag: {
                            asLink: true,
                            component: (
                                <FontAwesomeIcon
                                    className={'cursor-pointer text-default ml-0'}
                                    transform={{rotate: 45}}
                                    icon={extras.tagMode === 'and' ? faLink : faUnlink}
                                    onClick={() => {
                                        extrasDispatch({
                                            type: 'edit',
                                            payload: {
                                                field: 'tagMode',
                                                value: extras.tagMode === 'and' ? 'or' : 'and',
                                            },
                                        });
                                    }}
                                />
                            ),
                        },
                        name: {
                            asLink: true,
                            component: (
                                <FontAwesomeIcon
                                    className={'mx-1 cursor-pointer text-default ml-0'}
                                    transform={{rotate: 45}}
                                    icon={extras.nameMode === 'and' ? faLink : faUnlink}
                                    onClick={() => {
                                        extrasDispatch({
                                            type: 'edit',
                                            payload: {
                                                field: 'nameMode',
                                                value: extras.nameMode === 'and' ? 'or' : 'and',
                                            },
                                        });
                                    }}
                                />
                            ),
                        },
                    }}
                />
                {loading && (
                    <Spinner
                        background={'transparent'}
                        className={'d-flex align-self-center'}
                        color={'primary'}
                        global={false}
                        size={50}
                        style={{
                            position: 'absolute',
                            top: '45%',
                            bottom: '45%',
                            left: '45%',
                            right: '45%',
                            zIndex: 10,
                        }}
                    />
                )}

                {downloadLoading.length > 0 && (
                    <Spinner
                        background={'transparent'}
                        className={'d-flex align-self-center'}
                        color={'primary'}
                        global={false}
                        size={50}
                        style={{
                            position: 'absolute',
                            top: '45%',
                            bottom: '45%',
                            left: '45%',
                            right: '45%',
                            zIndex: 10,
                        }}
                    />
                )}

                <Table
                    columns={newColumns}
                    handles={{
                        activeTags: Object.keys(filters)
                            .filter((k) => k.startsWith('tag_'))
                            .map((k) => filters[k].serialized),
                        nameFilterDispatch: (name) => addFilter(name_filter.factory(name)),

                        fromNumberFilterDispatch: (number) =>
                            addFilter(from_number_filter.factory(number)),

                        toNumberFilterDispatch: (number) =>
                            addFilter(to_number_filter.factory(number)),
                        statusFilterDispatch: (status) => addFilter(status_filter.factory(status)),
                        directionFilterDispatch: (direction) =>
                            addFilter(direction_filter.factory(direction)),
                        extensionFilterDispatch: (extension) =>
                            addFilter(extension_filter.factory(extension)),
                    }}
                    infinity={{
                        hasMore: hasMore,
                        isLoadingMore: loading,
                        loadMore: extendCalls,
                        minimumBatchSize: TURNOVER,
                        threshold: THRESHOLD,
                    }}
                    itemKey={(index, itemData) =>
                        itemData.items[index] ? itemData.items[index].id : index
                    }
                    itemSize={55}
                    items={Object.values(calls)}
                    onSort={(items, sort) => sortDispatch(sort)}
                />
                {stats && stats.status_count && (
                    <ListGroup flush>
                        <ListGroupItem className={'d-flex'}>
                            {Object.keys(stats.status_count).map((k, i) => {
                                const filter = status_filter.factory(k);
                                return (
                                    <Button
                                        className={`mr-2`}
                                        color={status_color(k)}
                                        key={i}
                                        onClick={() => {
                                            filtersDispatch({
                                                type: 'add',
                                                payload: {index: filter.index, filter: filter},
                                            });
                                        }}
                                        outline
                                        size={'sm'}
                                    >
                                        <FontAwesomeIcon icon={status_icon(k)} />
                                        <span>{status_label(k, intl)}: </span>
                                        <span>{stats.status_count[k]}</span>
                                    </Button>
                                );
                            })}
                            <Button size={'sm'} color={'link'} className={`ml-auto cursor-default`}>
                                <FormattedMessage id={'message.total'} defaultMessage={'Total'} /> :
                                {total}
                            </Button>
                        </ListGroupItem>
                    </ListGroup>
                )}
            </CardBody>
        </>
    );
};

NewCallHistory.propTypes = {
    defaultFilters: PropTypes.arrayOf(PropTypes.func),
};

NewCallHistory.defaultProps = {
    defaultFilters: [],
};

export default injectIntl(NewCallHistory);
