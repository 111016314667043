import React, {useEffect, useRef, useState} from 'react';
import * as PropTypes from 'prop-types';
import InfiniteLoader from "react-window-infinite-loader";
import {VariableSizeList} from "react-window";

const List = ({className, hasMore, height, infinite, isLoadingMore, itemRenderer, itemCount, itemData, itemKey, items, itemSize, layout, loadMore, minimumBatchSize, sort, style, threshold, width}) => {

    const listRef = useRef(null);
    const hasMountedRef = useRef(false);

    const [sortFlag, setSortFlag] = useState(false);

    useEffect(() => {
        if (listRef.current && hasMountedRef.current) listRef.current.resetloadMoreItemsCache();
        hasMountedRef.current = true;
    }, [sort, items]);

    useEffect(() => {
        setSortFlag(sort !== 'asc' ? sort === 'desc' ? -1 : 0 : 1)
    }, [sort, setSortFlag]);

    const isItemLoaded = index => !hasMore || (items && index < items.length);
    const loadMoreItems = isLoadingMore ? () => {
    } : loadMore;

    const itemKeyWrapper = (index, itemData) => {
        if (index === itemData.items.length) return 'loading';
        return itemKey(index, itemData);
    };

    const adjustItemCount = (itemCount) => {
        let c = itemCount ? itemCount : items ? items.length : 0;
        if (hasMore) c += 1;
        return c;
    };

    const commonProps = {
        className,
        height,
        itemCount: adjustItemCount(itemCount),
        itemData,
        itemKey: itemKeyWrapper,
        itemSize: typeof itemSize === 'number' ? () => itemSize : itemSize,
        layout,
        style,
        width,
        sortFlag
    };

    return infinite ?
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={adjustItemCount(itemCount)}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={minimumBatchSize}
            ref={listRef}
            threshold={threshold}
        >
            {({onItemsRendered, ref}) =>
                <VariableSizeList onItemsRendered={onItemsRendered} ref={ref} {...commonProps}>
                    {itemRenderer}
                </VariableSizeList>
            }
        </InfiniteLoader> :
        <VariableSizeList {...commonProps} sort={sort}>
            {itemRenderer}
        </VariableSizeList>
};

List.propTypes = {
    hasMore: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    infinite: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    itemRenderer: PropTypes.func.isRequired,
    itemData: PropTypes.shape({
        items: PropTypes.array.isRequired
    }),
    itemKey: PropTypes.func,
    items: PropTypes.array,
    itemSize: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    loadMore: PropTypes.func,
    minimumBatchSize: PropTypes.number,
    sort: PropTypes.any,
    style: PropTypes.object,
    threshold: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

List.defaultProps = {
    infinite: false,
    itemKey: (index) => index,
    minimumBatchSize: 10,
    threshold: 15,
};

export default List;