import { call, put } from 'redux-saga/effects';
import * as PActions from '../actions/portal-action';
import { dangerToast, primaryToast, secondaryToast } from '../actions/portal-action';
import { APIRequestGenerator } from './index';

export function* genericFetch({
    endpoint,
    loadingId,
    storeAction,
    successAction,
    failedAction,
    version = 'v1',
}) {
    try {
        yield put({ type: PActions.LOADING, payload: loadingId });
        let { data, ...metadata } = yield call(APIRequestGenerator, 'get', endpoint, {
            version: version,
        });
        yield put({ type: storeAction, payload: data, metadata });
        yield put({ type: successAction });
    } catch (e) {
        yield put({
            type: failedAction,
            payload: e.message,
        });
    } finally {
        yield put({ type: PActions.LOADED, payload: loadingId });
    }
}

export function* newGenericFetch({
    endpoint,
    loadingId,
    storeAction,
    successAction,
    failedAction,
    version = 'v1',
}) {
    try {
        yield put({ type: PActions.LOADING, payload: loadingId });
        let { data, ...metadata } = yield call(APIRequestGenerator, 'newGet', endpoint, {
            version: version,
        });
        yield put({ type: storeAction, payload: data, metadata });
        yield put({ type: successAction });
    } catch (e) {
        yield put({
            type: failedAction,
            payload: e.message,
        });
    } finally {
        yield put({ type: PActions.LOADED, payload: loadingId });
    }
}

export function* genericCreate({
    endpoint,
    params,
    fetchAction,
    successAction,
    successMessage,
    failedAction,
    failedMessage,
    progressMessage,
}) {
    try {
        if (progressMessage) yield put(secondaryToast({ content: progressMessage, loading: true }));
        yield call(APIRequestGenerator, 'post', endpoint, { params });
        yield put({ type: successAction });
        if (successMessage) yield put(primaryToast({ content: successMessage }));
    } catch (e) {
        yield put({ type: failedAction, payload: e.message });
        if (failedMessage) yield put(dangerToast({ content: failedMessage }));
    } finally {
        if (typeof fetchAction === 'string') {
            yield put({ type: fetchAction });
        } else if (typeof fetchAction === 'object') {
            yield put(fetchAction);
        }
    }
}

export function* genericUpdate({
    endpoint,
    params,
    fetchAction,
    successAction,
    successMessage,
    failedAction,
    failedMessage,
    progressMessage,
}) {
    try {
        if (progressMessage) yield put(secondaryToast({ content: progressMessage, loading: true }));
        yield call(APIRequestGenerator, 'put', endpoint, { params });
        yield put({ type: successAction });
        if (successMessage) yield put(primaryToast({ content: successMessage }));
    } catch (e) {
        yield put({ type: failedAction, payload: e.message });
        if (failedMessage) yield put(dangerToast({ content: failedMessage }));
    } finally {
        if (typeof fetchAction === 'string') {
            yield put({ type: fetchAction });
        } else if (typeof fetchAction === 'object') {
            yield put(fetchAction);
        }
    }
}

export function* genericDelete({
    endpoint,
    params,
    fetchAction,
    successAction,
    successMessage,
    failedAction,
    failedMessage,
    progressMessage,
}) {
    try {
        if (progressMessage) yield put(secondaryToast({ content: progressMessage, loading: true }));
        yield call(APIRequestGenerator, 'del', endpoint, { params });
        yield put({ type: successAction });
        if (successMessage) yield put(primaryToast({ content: successMessage }));
    } catch (e) {
        yield put({ type: failedAction, payload: e.message });
        if (failedMessage) yield put(dangerToast({ content: failedMessage }));
    } finally {
        if (typeof fetchAction === 'string') {
            yield put({ type: fetchAction });
        } else if (typeof fetchAction === 'object') {
            yield put(fetchAction);
        }
    }
}
