import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, useHistory} from 'react-router-dom';
import {useInterval} from '../../utils/hooks';

const RedirectTo = ({path, timer}) => {
    const history = useHistory();
    const [countdown, setCountdown] = useState(timer);

    useInterval(() => {
        if (countdown < 1) history.push(path);
        else setCountdown(countdown - 1);
    }, 1000);

    return (
        <>
            <FormattedMessage
                id='message.redirect.timer'
                defaultMessage={`We will redirect you {timer, plural,
                =0 {now}
                one {within a second}
                other {within {timer} seconds}
            }`}
                values={{timer}}
            >
                {(txt) => <span>{txt}</span>}
            </FormattedMessage>
            <br />
            <FormattedMessage id='message.redirect.if_not' defaultMessage={`If not click here`}>
                {(txt) => <Link to={path}>{txt}</Link>}
            </FormattedMessage>
        </>
    );
};

export default RedirectTo;
