import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Form} from 'reactstrap';
import PasswordSetter from '../../components/password/PasswordSetter';
import SidePanel from '../../components/panel/SidePanel';
import SidePanelContent from '../../components/panel/SidePanelContent';
import SidePanelHeader from '../../components/panel/SidePanelHeader';

const ChangePasswordModal = ({isOpen, setOpen, changePassword}) => {
    const [error, setError] = useState(null);

    const submit = async (currentPassword, newPassword) => {
        try {
            await changePassword({oldPassword: currentPassword, newPassword: newPassword});
            setOpen(!isOpen);
        } catch (e) {
            setError(<span>{e.message}</span>);
        }
    };

    return (
        <SidePanel
            size={50}
            isOpen={isOpen}
            setOpen={setOpen}
            className={'bg-white'}
            onGlassClick={() => setOpen(false)}
        >
            <SidePanelHeader>
                <FormattedMessage id='profile.form.btn.password' defaultMessage='Change password' />
            </SidePanelHeader>
            <SidePanelContent>
                {error}
                <Form>
                    <PasswordSetter
                        onSubmit={submit}
                        onCancel={() => setOpen(!isOpen)}
                        requireCurrent
                    />
                </Form>
            </SidePanelContent>
        </SidePanel>
    );
};

export default ChangePasswordModal;
