import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {withAuthenticator} from 'aws-amplify-react';
import ALogin from 'views/auth/amplify/ALogin';
import Navbar from '../components/navbar/Navbar';
import {cognitoLogin, travelTo} from 'store/actions/user-action';
import {useDispatch, useSelector} from 'react-redux';

import routes from 'routes';
import ARequireNewPassword from 'views/auth/amplify/ARequireNewPassword';
import {
    getUser,
    getUserSession,
    getUserTimeZone,
    getUserTravel,
} from 'store/selectors/user-selectors';
import AForgotPassword from 'views/auth/amplify/AForgotPassword';
import AudioOverlay from '../components/media/audio/AudioOverlay';
import {Toasts} from '../components/notification/Toast';
import Spinner from '../components/spinner/Spinner';
import Sidebar from '../components/navbar/Sidebar';
import MainFooter from '../components/footer/MainFooter';
import {useQuery} from '../utils/hooks';
import {make_travel_query_string} from '../utils/history';
import WhatsNew from '../components/notification/whats-new/WhatsNew';
import moment from 'moment';
import API from '../utils/api';
import {Has} from '../components/security/Has';
import {Alert} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

const Main = () => {
    const query = useQuery();
    const history = useHistory();

    const session = useSelector(getUserSession);
    const user = useSelector(getUser);
    const timezone = useSelector(getUserTimeZone);
    const travel = useSelector(getUserTravel);

    const reduxDispatch = useDispatch();

    let customerLicense = null;
    if (user != null) {
        if (user.customer != null) {
            if (user.customer.licenses != null) {
                if (user.customer.licenses.length > 0) {
                    customerLicense = user.customer.licenses[0].license_type.name;
                }
            }
        }
    }
    const restrictionLicense = customerLicense === 'PRO' || customerLicense === 'PREMIUM';

    useEffect(() => {
        reduxDispatch(cognitoLogin());
    }, [reduxDispatch]);

    useEffect(() => {
        moment.tz.setDefault(timezone);
    }, [timezone]);

    useEffect(() => {
        if (query.get('phone_number') && session != null) {
            API.get({session, endpoint: `/customers/${query.get('phone_number')}`}).then((res) => {
                const customerId = res.data.id;
                reduxDispatch(travelTo(customerId));
                history.push({search: make_travel_query_string(query, customerId)});
            });
        } else if (
            (query.get('customer') && travel == null) ||
            (query.get('customer') && travel != null && query.get('customer') !== travel.customer)
        ) {
            reduxDispatch(travelTo(query.get('customer')));
        } else if (query.get('customer') == null && travel != null && travel.customer != null) {
            history.push({search: make_travel_query_string(query, travel.customer)});
        }
    }, [reduxDispatch, travel, query, history, session]);

    const getRoutes = (routes, traveling) =>
        routes.map((route, key) => {
            if (!traveling || !route.travel)
                return (
                    <Route
                        path={route.path}
                        exact={!!route.exact}
                        component={route.component}
                        key={key}
                    />
                );
            else return <Redirect key={'traveling'} to='/call_history' />;
        });

    if (!user || !user.id)
        return (
            <div className='d-flex h-100'>
                <Spinner />
            </div>
        );
    return (
        <div className='page-body border-top border-primary'>
            <Sidebar />
            <div className='main-content' id={'main-content'}>
                <Navbar />
                <Has
                    active
                    orElse={
                        <Alert color={'secondary'} className={'mt-2 mb-auto'}>
                            <FormattedMessage
                                id={'message.account.suspended'}
                                defaultMessage={'Your account has been suspended.'}
                            />
                        </Alert>
                    }
                >
                    <Switch>
                        {window.location.search.startsWith('?id=') &&
                            window.location.pathname.startsWith('/dashboard') && (
                                <Route
                                    render={() => (
                                        <Redirect to={'/call_history' + window.location.search} />
                                    )}
                                />
                            )}
                        {getRoutes(routes, travel)}
                        <Route
                            path={'*'}
                            render={() => (
                                <Redirect
                                    to={restrictionLicense ? '/call_history' : '/dashboard'}
                                />
                            )}
                        />
                    </Switch>
                </Has>
                <MainFooter />
                <AudioOverlay />
                <Toasts />
                <WhatsNew />
            </div>
        </div>
    );
};

export default withAuthenticator(Main, false, [
    <ALogin />,
    <ARequireNewPassword />,
    <AForgotPassword />,
]);
