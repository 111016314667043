import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import List from '../../../layout/List/List';
import UserAvatar from '../../../user/UserAvatar';
import {FormattedMessage} from 'react-intl';
import {UncontrolledTooltip} from 'reactstrap';

export const QueueList = ({data}) => {
    const members = data ? data.members : null;

    const getColor = (status) => {
        switch (status.toLowerCase()) {
            case 'in use':
                return 'text-danger';
            case 'busy':
                return 'text-warning';
            case 'not in use':
                return 'text-success';
            default:
                return 'text-dark';
        }
    };

    return members ? (
        <AutoSizer>
            {({height, width}) => (
                <div style={{minHeight: height}} className={'d-flex flex-column flex-grow-1 p-0'}>
                    <List
                        height={height}
                        width={width}
                        items={members}
                        itemCount={members.length}
                        itemData={{items: members}}
                        itemSize={45}
                        itemRenderer={({index, style}) => (
                            <div
                                style={{...style, left: (width - 220) / 2, width: 300}}
                                className={'flex-row'}
                            >
                                <UserAvatar
                                    size={'sm'}
                                    user={{nickname: '', full_name: members[index].name}}
                                />
                                <span
                                    className={'fa-stack fa-2x'}
                                    style={{
                                        fontSize: '8px',
                                        position: 'relative',
                                        right: 11,
                                        top: 11,
                                    }}
                                    id={'StatusTooltip-' + index}
                                >
                                    <i className={'fas fa-circle fa-stack-2x text-white'} />
                                    <i
                                        className={
                                            'fas fa-circle fa-stack-1x ' +
                                            getColor(members[index].status)
                                        }
                                    />
                                </span>
                                <UncontrolledTooltip
                                    placement='left'
                                    target={'StatusTooltip-' + index}
                                >
                                    {members[index].status}
                                </UncontrolledTooltip>
                                {members[index].name + ' (' + members[index].extension + ')'}
                            </div>
                        )}
                    />
                </div>
            )}
        </AutoSizer>
    ) : (
        <div className={'text-center'} style={{paddingTop: '100px'}}>
            <FormattedMessage
                id='widget.incomplete'
                defaultMessage='Please fill in the widget configurations'
            />
        </div>
    );
};
