import React from 'react';
import {Row} from "reactstrap";

const SidePanelAvatar = ({children}) =>
    <Row className="justify-content-center" style={{marginTop: -37, marginBottom: -37, zIndex: 1030}}>
        {children}
    </Row>;

SidePanelAvatar.propTypes = {};

export default SidePanelAvatar;