import React, {useReducer, useState} from 'react';
import SidePanelHeader from '../../components/panel/SidePanelHeader';
import {Button, Form, FormGroup, FormText, Label, Nav, Row} from 'reactstrap';
import FormSection from '../../components/form/FormSection';
import {faBuilding} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage, injectIntl} from 'react-intl';
import TextInput from '../../components/input/TextInput';
import CheckboxInput from '../../components/input/CheckboxInput';
import SidePanelContent from '../../components/panel/SidePanelContent';
import isEqual from 'lodash.isequal';
import {useDispatch} from 'react-redux';
import {createCustomer, updateCustomer} from '../../store/actions/customer-action';
import SelectInput from '../../components/input/SelectInput';
import momentTZ from 'moment-timezone';

const defaultOpenSections = {
    customerOpen: true,
    advancedOpen: false,
};

const DATACENTERS = [
    {
        label: 'docker201.lan / US-EAST-1',
        value: 'docker201.lan',
    },
    {
        label: 'docker203.lan / US-EAST-2',
        value: 'docker203.lan',
    },
];

const AWS_REGIONS = [
    {
        label: 'North America (us-east-1)',
        value: 'us-east-1',
    },
    {
        label: 'Australia (ap-southeast-2)',
        value: 'ap-southeast-2',
    },
];

const RATECARDS = [
    {
        label: 'Canada',
        value: 'Canada',
    },
    {
        label: 'United States',
        value: 'US',
    },
    {
        label: 'European Union',
        value: 'EU',
    },
    {
        label: 'Australia',
        value: 'Regular',
    },
    {
        label: 'World (others)',
        value: 'World',
    },
];

const CustomerForm = injectIntl(({customer, toggle, intl}) => {
    const [valid, setValid] = useState(
        customer ? /^[a-z0-9]{1,13}[0-9]{2}$/.test(customer.id) : false
    );

    const defaultCustomerState = () => ({
        id: '',
        name: '',
        datacenter: 'docker201.lan',
        aws_region: 'us-east-1',
        ratecard: 'Canada',
        timezone: 'America/New_York',
        active: true,
        note: '',
    });

    const getCustomerState = () => {
        if (!!!customer) return defaultCustomerState();
        return {
            id: customer.id,
            name: customer.name,
            active: customer.active,
            note: customer.note,
        };
    };

    const [customerState, customerDispatch] = useReducer((preCustomerState, action) => {
        switch (action.type) {
            case 'reset':
                return defaultCustomerState();
            case 'edit':
                if (isEqual(preCustomerState[action.payload.field], action.payload.value))
                    return preCustomerState;
                const newState = {...preCustomerState};
                newState[action.payload.field] = action.payload.value;
                return newState;
            default:
                return preCustomerState;
        }
    }, getCustomerState());

    const reduxDispatch = useDispatch();

    const submit = (e) => {
        e.preventDefault();
        if (!!customer) {
            reduxDispatch(
                updateCustomer({
                    id: customerState.id,
                    name: customerState.name,
                    aws_region: customerState.aws_region,
                    active: customerState.active,
                    note: customerState.note,
                })
            );
        } else {
            reduxDispatch(
                createCustomer({
                    id: customerState.id,
                    name: customerState.name,
                    datacenter: customerState.datacenter,
                    aws_region: customerState.aws_region,
                    ratecard: customerState.ratecard,
                    timezone: customerState.timezone,
                    active: true,
                    note: customerState.note,
                })
            );
        }
        toggle();
    };

    const editField = (name, value) =>
        customerDispatch({
            type: 'edit',
            payload: {field: name, value: value},
        });

    return (
        <>
            <SidePanelHeader>
                {!!customer ? (
                    customer.name
                ) : (
                    <FormattedMessage
                        id={'message.create.customer'}
                        defaultMessage={'Create Customer'}
                    />
                )}
            </SidePanelHeader>
            <SidePanelContent>
                <Form role='form' className='form-sm' onSubmit={submit}>
                    <Nav vertical className={''}>
                        <FormSection
                            icon={faBuilding}
                            isOpenByDefault={defaultOpenSections.customerOpen}
                            title={
                                <FormattedMessage
                                    id={'message.customer'}
                                    defaultMessage={'Customer'}
                                />
                            }
                        >
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='message.customer.id'
                                            defaultMessage='Customer ID'
                                        />
                                    </Label>
                                    <TextInput
                                        onValueChange={(v) => editField('id', v)}
                                        value={customerState.id}
                                        enabled={!!!customer}
                                        validator={(v) => /^[a-z0-9]{1,13}[0-9]{2}$/.test(v)}
                                        onValidityChange={setValid}
                                    />
                                    {!valid && customerState.id !== '' && (
                                        <FormattedMessage
                                            id={'form.text.select.customer.id.validation'}
                                            defaultMessage={
                                                'The customer ID should be at most 15 characters long, and contain up to 15 characters, the last 2 of which should be digits, and the rest letters.'
                                            }
                                        >
                                            {(m) => <FormText color={'danger'}>{m}</FormText>}
                                        </FormattedMessage>
                                    )}
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage
                                            id='message.customer.name'
                                            defaultMessage='Name'
                                        />
                                    </Label>
                                    <TextInput
                                        onValueChange={(v) => editField('name', v)}
                                        placeholder={intl.formatMessage({
                                            id: 'message.customer.name',
                                            defaultMessage: 'Name',
                                        })}
                                        value={customerState.name}
                                        enabled={true}
                                    />
                                </FormGroup>
                            </Row>
                            {!customer && (
                                <Row>
                                    <FormGroup className='col'>
                                        <FormattedMessage
                                            id='input.label.datacenter'
                                            defaultMessage='Datacenter'
                                        >
                                            {(m) => (
                                                <label className='form-control-label'>{m}</label>
                                            )}
                                        </FormattedMessage>
                                        <SelectInput
                                            options={DATACENTERS}
                                            onValueChange={(v) => editField('datacenter', v)}
                                            placeholder={intl.formatMessage({
                                                id: 'input.placeholder.datacenter',
                                                defaultMessage: 'Datacenter',
                                            })}
                                            value={customerState.datacenter}
                                        />
                                    </FormGroup>
                                </Row>
                            )}
                            <Row>
                                <FormGroup className='col'>
                                    <FormattedMessage
                                        id='input.label.aws.region'
                                        defaultMessage='AWS Region'
                                    >
                                        {(m) => <label className='form-control-label'>{m}</label>}
                                    </FormattedMessage>
                                    <SelectInput
                                        options={AWS_REGIONS}
                                        onValueChange={(v) => editField('aws_region', v)}
                                        placeholder={intl.formatMessage({
                                            id: 'input.placeholder.aws.region',
                                            defaultMessage: 'AWS Region',
                                        })}
                                        value={customerState.aws_region}
                                    />
                                </FormGroup>
                            </Row>
                            {!customer && (
                                <Row>
                                    <FormGroup className='col'>
                                        <Label className='form-control-label'>
                                            <FormattedMessage
                                                id='message.customer.ratecard'
                                                defaultMessage='Ratecard'
                                            />
                                        </Label>
                                        <SelectInput
                                            options={RATECARDS}
                                            onValueChange={(v) => editField('ratecard', v)}
                                            placeholder={intl.formatMessage({
                                                id: 'input.placeholder.ratecard',
                                                defaultMessage: 'Ratecard',
                                            })}
                                            value={customerState.ratecard}
                                        />
                                    </FormGroup>
                                </Row>
                            )}
                            {!customer && (
                                <Row>
                                    <FormGroup className='col'>
                                        <FormattedMessage
                                            id='input.label.timezone'
                                            defaultMessage='Timezone'
                                        >
                                            {(m) => (
                                                <label className='form-control-label'>{m}</label>
                                            )}
                                        </FormattedMessage>
                                        <SelectInput
                                            options={momentTZ.tz
                                                .names()
                                                .map((t) => ({value: t, label: t}))}
                                            onValueChange={(v) => editField('timezone', v)}
                                            placeholder={intl.formatMessage({
                                                id: 'input.placeholder.timezone',
                                                defaultMessage: 'Timezone',
                                            })}
                                            value={customerState.timezone}
                                        />{' '}
                                    </FormGroup>
                                </Row>
                            )}
                            <Row>
                                <FormGroup className='col'>
                                    <Label className='form-control-label'>
                                        <FormattedMessage id='message.note' defaultMessage='Note' />
                                    </Label>
                                    <TextInput
                                        onValueChange={(v) => editField('note', v)}
                                        value={customerState.note}
                                        enabled={true}
                                        type={'textarea'}
                                        style={{height: '150px'}}
                                    />
                                </FormGroup>
                            </Row>
                            {!!customer && (
                                <Row>
                                    <FormGroup
                                        className={
                                            'col d-flex flex-row justify-content-between align-items-center'
                                        }
                                        check
                                    >
                                        <CheckboxInput
                                            className={'w-100 d-flex justify-content-between'}
                                            label={
                                                <FormattedMessage
                                                    id='message.customer.active'
                                                    defaultMessage='Active'
                                                />
                                            }
                                            onValueChange={(v) => editField('active', v)}
                                            value={customerState.active}
                                        />
                                    </FormGroup>
                                </Row>
                            )}
                        </FormSection>
                    </Nav>
                    <div className='d-flex justify-content-between mt-4'>
                        <Button
                            color={'secondary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            <FormattedMessage id={'btn.cancel'} defaultMessage={'Cancel'} />
                        </Button>
                        <Button color={'primary'} size={'sm'} disabled={!valid}>
                            <FormattedMessage id={'btn.save'} defaultMessage={'Save'} />
                        </Button>
                    </div>
                </Form>
            </SidePanelContent>
        </>
    );
});

CustomerForm.propTypes = {};

export default CustomerForm;
