import React, {useCallback} from 'react';
import {FormFeedback, Input, InputGroup, Label, FormText} from 'reactstrap';
import LightFontAwesomeIcon from '../utils/LightFontAwesomeIcon';

interface TextInputProps {
    className?: string;
    enabled?: boolean;
    name?: string;
    onEnter?: any;
    onEsc?: any;
    onValidityChange?: any;
    onValueChange?: any;
    placeholder?: any;
    type?: any;
    validator?: any;
    value?: number | string;
    icon?: any;
    onIconClick?: any;
    title?: string;
    errorMessage?: string;
    maxLength?: number;
    style?: {[key: string]: string};
    required?: boolean;
    infoMessage?: string;
}

const TextInput = ({
    type,
    className,
    enabled,
    icon,
    name,
    onValidityChange,
    onValueChange,
    onEnter,
    onEsc,
    onIconClick,
    title,
    placeholder,
    validator,
    value,
    errorMessage,
    maxLength,
    style,
    required,
    infoMessage,
}: TextInputProps) => {
    const keyPress = (e: any) => {
        if (e.keyCode === 13 && value && value !== '' && validator(value)) onEnter(value);
        if (e.keyCode === 27) onEsc();

        // Check length input
        if (e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 39) return true;
        if (e.target.value.length === maxLength) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    };

    const onChange = useCallback(
        (v: any) => {
            onValueChange(v);
            onValidityChange(validator(v));
        },
        [onValueChange, onValidityChange, validator]
    );

    return (
        <>
            <InputGroup size={'sm'} className={className}>
                {title && (
                    <Label size={'sm'} className={'mr-1'}>
                        {title}
                    </Label>
                )}
                <Input
                    autoComplete='new-password'
                    name={name}
                    disabled={!enabled}
                    placeholder={placeholder}
                    type={type}
                    bsSize={'sm'}
                    value={value}
                    onKeyDown={keyPress}
                    onChange={(e) => onChange(e.target.value)}
                    invalid={validator(value)}
                    style={style}
                    required={required}
                />
                {icon && (
                    <LightFontAwesomeIcon
                        icon={icon}
                        className={'align-self-center ml--4 text-muted'}
                        style={{zIndex: 10}}
                        onClick={onIconClick}
                    />
                )}
                {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
            </InputGroup>
            {infoMessage && <FormText>{infoMessage}</FormText>}
        </>
    );
};

TextInput.defaultProps = {
    enabled: true,
    onEnter: () => {},
    onEsc: () => {},
    onValidityChange: () => true,
    placeholder: '',
    type: 'text',
    validator: () => false,
    value: '',
};

export default TextInput;
