import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {getPortalAudioOwner, getPortalAudioShow} from '../../store/selectors/portal-selectors';
import {newCustomerExtensionsSelector} from '../../store/selectors/customer-selectors';
import {getUserLocale} from '../../store/selectors/user-selectors';
import {deleteRecording, fetchNewRecordingLink} from '../../store/actions/cdr-action';
import {hideAudio, secondaryToast, showAudio, storeAudio} from '../../store/actions/portal-action';
import {PopoverBody, Table, UncontrolledPopover, UncontrolledTooltip} from 'reactstrap';
import {
    formatAlphanumericString,
    formatAmericanNumber,
    formatDateToHour,
    formatSecondsToHour,
} from '../../utils/format';
import {copyToClipboard} from '../../utils/clipboard';
import {
    call_color,
    call_description,
    call_icon,
    extension_label,
    formatCallRecordingFilename,
} from '../../utils/call_history';
import {Has} from '../security/Has';
import LightFontAwesomeIcon from '../utils/LightFontAwesomeIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWifi, faHourglassHalf, faPhone} from '@fortawesome/free-solid-svg-icons';
import {Action, ActionsCell} from '../layout/Table/Cells';
import Spinner from '../spinner/Spinner';

export const StatusCell = ({call, statusFilterDispatch, directionFilterDispatch}) => {
    const intl = useIntl();

    return (
        <>
            <span
                id={`call-${formatAlphanumericString(call.id)}-status-tooltip`}
                className={'ml-2 cursor-pointer'}
                onClick={() => {
                    statusFilterDispatch(call.status);
                    directionFilterDispatch(call.direction);
                }}
            >
                <LightFontAwesomeIcon
                    icon={call_icon(call)}
                    className={`text-${call_color(call)}`}
                />
            </span>
            <UncontrolledTooltip
                target={`call-${formatAlphanumericString(call.id)}-status-tooltip`}
                placement={'right'}
            >
                {call_description(call, intl)}
            </UncontrolledTooltip>
        </>
    );
};

StatusCell.defaultProps = {
    call: {},
    statusFilterDispatch: () => {},
    directionFilterDispatch: () => {},
};

export const NameCell = ({call, nameFilterDispatch}) => {
    const newExtensions = useSelector(newCustomerExtensionsSelector);
    if (newExtensions !== undefined && newExtensions.length > 0) {
        const user = newExtensions.find((x) => x.extension === call.extension);
        if (user) {
            call.name = user.name + ' ' + user.lastName;
        }
    }
    return call.name;
};
export const NumberCell = ({call, numberFilterDispatch}) =>
    call.number && (
        <span className={'cursor-pointer'} onClick={() => numberFilterDispatch(call.number)}>
            {formatAmericanNumber(call.number)}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );

export const FromNumberCell = ({call, fromNumberFilterDispatch}) =>
    call.from_number && (
        <span
            className={'cursor-pointer'}
            onClick={() => fromNumberFilterDispatch(call.from_number)}
        >
            {formatAmericanNumber(call.from_number)}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );

export const ToNumberCell = ({call, toNumberFilterDispatch}) =>
    call.from_number && (
        <span className={'cursor-pointer'} onClick={() => toNumberFilterDispatch(call.to_number)}>
            {formatAmericanNumber(call.to_number)}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );

NumberCell.defaultProps = {
    numberFilterDispatch: () => {},
};
ToNumberCell.defaultProps = {
    toNumberFilterDispatch: () => {},
};
FromNumberCell.defaultProps = {
    fromNumberFilterDispatch: () => {},
};

export const UserCell = ({call, extensionFilterDispatch}) => {
    const extensions = useSelector(newCustomerExtensionsSelector);
    return (
        <span className={'cursor-pointer'} onClick={() => extensionFilterDispatch(call.extension)}>
            {extension_label(call.extension, extensions, undefined) || (
                <FormattedMessage id={'message.system.extension'} defaultMessage={'System'} />
            )}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );
};

UserCell.defaultProps = {
    extensionFilterDispatch: () => {},
};

export const CallHistoryActionsCell = ({call}) => {
    const audioOwner = useSelector(getPortalAudioOwner);
    const showingAudio = useSelector(getPortalAudioShow);
    const reduxDispatch = useDispatch();

    useEffect(() => {
        setPlaying(audioOwner === call.id);
    }, [audioOwner, call.id]);

    const [playing, setPlaying] = useState(false);

    const shareLink = `${window.location.origin}/new_call_history?cdr_id=${call.id}`;

    const playToggle = useCallback(() => {
        if (!playing) {
            reduxDispatch(
                fetchNewRecordingLink({
                    orig_callid: call.orig_callid,
                    term_callid: call.term_callid,
                    domain: call.domain,
                    callback: (url) => {
                        return reduxDispatch(
                            storeAudio({
                                owner: call.id,
                                autoPlay: true,
                                src: url,
                                type: 'audio/mp3',
                            }),
                        );
                    },
                }),
            );
            if (audioOwner !== call.id) {
                reduxDispatch(hideAudio());
            }
            reduxDispatch(showAudio());
        } else {
            reduxDispatch(hideAudio());
            reduxDispatch(deleteRecording(call.id));
        }
    }, [playing, reduxDispatch, call, audioOwner]);

    const download = useCallback(() => {
        reduxDispatch(
            fetchNewRecordingLink({
                orig_callid: call.orig_callid,
                term_callid: call.term_callid,
                domain: call.domain,
                saveAs: formatCallRecordingFilename(call),
                callback: (url) => {
                    typeof url === 'string'
                        ? callRecordingDownload(url, formatCallRecordingFilename(call))
                        : url.forEach((u, i) => {
                              setTimeout(() => {
                                  callRecordingDownload(u, `file-${i}`);
                              }, 1000 * i);
                          });
                },
            }),
        );
    }, [reduxDispatch, call]);

    return (
        <ActionsCell>
            {
                <Has loaded={`recording_${call.id}`} requiresTravel={false}>
                    <Action
                        id={`player-${call.id.replace('.', '')}`}
                        enabled={call && call.recorded}
                        onClick={call && call.recorded ? playToggle : () => {}}
                        icon={playing && showingAudio ? 'stop' : 'play'}
                    />
                </Has>
            }
            {
                <Has
                    loaded={`recording_${call.id}`}
                    requiresTravel={false}
                    orElse={
                        <Spinner
                            color={'primary'}
                            background={'transparent'}
                            global={false}
                            size={20}
                            text={false}
                        />
                    }
                >
                    <Action
                        className={'ml-2'}
                        enabled={call && call.recorded}
                        icon={'download'}
                        onClick={call && call.recorded ? download : () => {}}
                    />
                </Has>
            }
            <Action
                icon={'copy'}
                onClick={() => {
                    copyToClipboard(shareLink);
                    reduxDispatch(secondaryToast({content: 'Link copied to clipboard'}));
                }}
            />
        </ActionsCell>
    );
};

CallHistoryActionsCell.defaultProps = {
    call: {},
};

const callRecordingDownload = async (url, filename) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);

        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(urlBlob);
        document.body.removeChild(a);
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
};

export const ParticipantHistoryCell = ({callId, call, isNewHistory}) => {
    const [callHistory, setCallHistory] = useState([]);
    const locale = useSelector(getUserLocale);
    const statusAction = (action) => {
        switch (action) {
            case 'waiting':
                return {
                    icon: faHourglassHalf,
                    rotate: 0,
                    class: 'offset-md-4',
                };
            case 'hanged':
            case 'connected':
                return {
                    icon: faPhone,
                    rotate: 0,
                    class: 'text-success offset-md-4',
                };
            case 'not connected':
            case 'voicemail':
            case 'missed':
                return {
                    icon: faPhone,
                    rotate: 135,
                    class: 'text-danger offset-md-4',
                };
            default:
                return {
                    icon: faPhone,
                    rotate: 0,
                    class: 'offset-md-4',
                };
        }
    };

    const ringCall = () => (
        <td className={'d-flex flex-column'}>
            <FontAwesomeIcon icon={faWifi} />
            <FontAwesomeIcon icon={faPhone} transform={{rotate: 135}} style={{marginTop: '-6px'}} />
        </td>
    );
    const getParticipantHistory = useCallback(() => {
        setCallHistory((oldArray) => [
            {
                action: 'hanged',
                id: call.id,
                duration: formatSecondsToHour(call.call_time_details.duration),
                action_time: call.call_time_details.time_start,
                time_talking: formatSecondsToHour(call.call_time_details.time_talking),
                time_holding: formatSecondsToHour(call.call_time_details.time_holding),
            },
            {
                action: 'not connected',
                id: call.id,
                duration: null,
                action_time: call.call_time_details.time_end,
                time_talking: null,
                time_holding: null,
            },
        ]);
    }, [call]);

    return (
        <>
            <button
                id={`participants-${formatAlphanumericString(callId)}`}
                className='p-0'
                style={{background: 'none', border: 'none'}}
            >
                <LightFontAwesomeIcon
                    className='text-primary ml-1 cursor-pointer'
                    icon={'info-circle'}
                    size={'lg'}
                    id={`participants-${formatAlphanumericString(callId)}`}
                    onClick={() => {
                        getParticipantHistory();
                    }}
                />
            </button>
            <UncontrolledPopover
                trigger='focus'
                placement='auto-start'
                target={`participants-${formatAlphanumericString(callId)}`}
            >
                <PopoverBody className='croo-table-cell-history' style={{width: '500px'}}>
                    <Table>
                        <thead>
                            <tr>
                                <th />
                                <th>
                                    <FormattedMessage
                                        id={'filter.title.hour'}
                                        defaultMessage={'Hour'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'message.time_talking'}
                                        defaultMessage={'Talking Time'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'message.time_holding'}
                                        defaultMessage={'Holding Time'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'message.duration'}
                                        defaultMessage={'Duration'}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {callHistory.length === 0 ? (
                                <tr>
                                    <td>
                                        <Spinner
                                            color={'primary'}
                                            background={'transparent'}
                                            global={false}
                                            size={20}
                                            centered
                                        />
                                    </td>
                                </tr>
                            ) : (
                                callHistory.map((callEvent) => (
                                    <tr
                                        key={`participants-${formatAlphanumericString(
                                            callEvent.action_time + callEvent.extension,
                                        )}`}
                                    >
                                        {callEvent.action === 'ring' ? (
                                            ringCall()
                                        ) : (
                                            <td>
                                                <FontAwesomeIcon
                                                    transform={{
                                                        rotate: statusAction(callEvent.action)
                                                            .rotate,
                                                    }}
                                                    icon={statusAction(callEvent.action).icon}
                                                    id={`call-${formatAlphanumericString(
                                                        callId,
                                                    )}-status-tooltip`}
                                                    className={statusAction(callEvent.action).class}
                                                />
                                            </td>
                                        )}
                                        <td>{formatDateToHour(callEvent.action_time, locale)}</td>
                                        <td>{callEvent.time_talking}</td>
                                        <td>{callEvent.time_holding}</td>
                                        <td>{callEvent.duration}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </PopoverBody>
            </UncontrolledPopover>
        </>
    );
};
