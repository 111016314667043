import React, {useMemo, useState} from 'react';
import moment from 'moment';
import {Alert, Nav, NavItem, NavLink} from 'reactstrap';
import {defineMessages, FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {ResponsiveLine} from '@nivo/line';
import {Granularity, QueuesOverTimeReportParametersType} from './QueuesOverTimeReportParameters';
import {QueueReportsParametersType} from '../QueuesReportsParameters';
import classnames from 'classnames';
import {generate_palette} from '../../../../../utils/colors';

const ISO_8601 = '%Y-%m-%dT%H:%M:%S.%L%Z';
const TIME_FORMATS = {
    hour: '%B %d %H:%M',
    day: '%a %B %d',
    month: '%B %Y',
};

const REPORT_MESSAGES: {[k in DatasetName]: {id: string; defaultMessage: string}} = defineMessages({
    call_time: {
        id: 'message.reports.call.time',
        defaultMessage: 'Call Time (s)',
    },
    hold_time: {
        id: 'message.reports.hold.time',
        defaultMessage: 'Hold time',
    },
    answered: {
        id: 'message.reports.answered',
        defaultMessage: 'Answered',
    },
    timed_out: {
        id: 'message.reports.timed.out',
        defaultMessage: 'Timed out',
    },
    exited: {
        id: 'message.reports.exited',
        defaultMessage: 'Bailed Out',
    },
    abandoned: {
        id: 'message.reports.abandoned',
        defaultMessage: 'Abandoned',
    },
});

const min_date = (time_values: string[]) => {
    return moment.min(time_values.map((t) => moment(t)));
};
const max_date = (time_values: string[]) => {
    return moment.max(time_values.map((t) => moment(t)));
};

const DIVIDERS_OF_24 = [2, 3, 4, 6, 8, 12, 24];
const MAX_TICKS = 10;

const make_tick: (time_values: string[], granularity: Granularity) => string = (
    time_values: string[],
    granularity: Granularity
) => {
    const min = min_date(time_values);
    const max = max_date(time_values);
    const difference = max.diff(min, granularity);
    let frequency = Math.round(Math.max(difference / MAX_TICKS, 1));
    if (granularity === 'hour') {
        // avoid weird tick behaviours
        if (frequency <= 24) {
            while (!DIVIDERS_OF_24.includes(frequency)) {
                frequency += 1;
            }
        } else {
            frequency = Math.ceil(frequency / 24) * 24;
        }
    } else if (granularity === 'day') {
        if (frequency > 15) return 'every 1 month';
    }
    return `every ${frequency} ${granularity}`;
};

interface QueueOverTImeReportProps extends WrappedComponentProps {
    parameters: QueuesOverTimeReportParametersType & QueueReportsParametersType;
    report: {
        data: {
            data: ({
                datetime: string;
                result: string;
            } & {
                [key in DatasetName]: number;
            })[];
        };
    };
}

type DatasetName = 'answered' | 'abandoned' | 'exited' | 'timed_out' | 'call_time' | 'hold_time';
type DataPoint = {
    x: string; // An ISO date string
    y: number;
};
type Dataset = {
    data: DataPoint[];
    id: string;
};

const QueueOverTimeReport = ({intl, parameters, report}: QueueOverTImeReportProps) => {
    const [activeTab, setActiveTab] = useState<'call_times' | 'hold_times' | 'calls'>('call_times');

    const ticks = useMemo(() => {
        if (report == null || report.data == null || report.data.data == null) return null;
        return make_tick(
            report.data.data.map((i) => i.datetime),
            parameters.granularity
        );
    }, [report, parameters]);

    const data = useMemo(() => {
        if (report == null || report.data == null || report.data.data == null) return null;

        const call_times: DatasetName[] = ['answered'];
        const hold_times: DatasetName[] = ['answered', 'abandoned', 'exited'];
        const calls: DatasetName[] = ['answered', 'abandoned', 'exited', 'timed_out'];

        const call_times_datasets: Dataset[] = [];
        const hold_times_datasets: Dataset[] = [];
        const calls_datasets: Dataset[] = [];

        const make_dataset = (dataset_name: DatasetName, y_name: DatasetName) => ({
            id: intl.formatMessage(REPORT_MESSAGES[dataset_name]),
            data: report.data.data
                .filter((i) => i.result === dataset_name)
                .map((i) => {
                    return {
                        x: moment(i.datetime).local().toISOString(true),
                        y: Math.round(i[y_name] * 100) / 100, // Round to two decimals if necessary
                    };
                })
                .filter((d) => d.y !== null),
        });

        call_times.forEach((dataset_name) => {
            const dataset = make_dataset(dataset_name, 'call_time');
            if (dataset.data.length > 0) call_times_datasets.push(dataset);
        });
        hold_times.forEach((dataset_name) => {
            const dataset = make_dataset(dataset_name, 'hold_time');
            if (dataset.data.length > 0) hold_times_datasets.push(dataset);
        });
        calls.forEach((dataset_name) => {
            const dataset = make_dataset(dataset_name, dataset_name);
            if (dataset.data.length > 0) calls_datasets.push(dataset);
        });

        return {
            call_times: call_times_datasets,
            hold_times: hold_times_datasets,
            calls: calls_datasets,
        };
    }, [report, intl]);

    return data ? (
        <>
            <Nav tabs className={'nav-fill d-flex flex-row'}>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === 'call_times'})}
                        onClick={() => setActiveTab('call_times')}
                    >
                        <FormattedMessage
                            id={'tab.reports.call.duration'}
                            defaultMessage={'Call Duration'}
                        />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === 'hold_times'})}
                        onClick={() => setActiveTab('hold_times')}
                    >
                        <FormattedMessage
                            id={'tabs.reports.hold.times'}
                            defaultMessage={'Hold Time'}
                        />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === 'calls'})}
                        onClick={() => setActiveTab('calls')}
                    >
                        <FormattedMessage
                            id={'tab.reports.incoming.call'}
                            defaultMessage={'Incoming Calls'}
                        />
                    </NavLink>
                </NavItem>
            </Nav>
            <div className={'px-2 d-flex flex-column flex-grow-1 flex-shrink-1 responsive-chart'}>
                <ResponsiveLine
                    data={data[activeTab]}
                    margin={{top: 50, right: 50, bottom: 50, left: 50}}
                    curve={'monotoneX'}
                    colors={generate_palette(data[activeTab].length)}
                    useMesh={false}
                    enableSlices={'x'}
                    xScale={{
                        type: 'time',
                        format: ISO_8601,
                        useUTC: true,
                        precision: parameters.granularity,
                    }}
                    xFormat={`time:${ISO_8601}`}
                    yScale={{
                        type: 'linear',
                        stacked: false,
                    }}
                    axisLeft={{
                        legend: 'Call Time (s)',
                        legendPosition: 'middle',
                        legendOffset: -40,
                    }}
                    axisBottom={{
                        format: TIME_FORMATS[parameters.granularity],
                        legend: 'Time',
                        legendPosition: 'middle',
                        legendOffset: 35,
                        tickValues: ticks || '',
                    }}
                    legends={[
                        {
                            anchor: 'top',
                            direction: 'row',
                            justify: false,
                            translateY: -35,
                            itemsSpacing: 10,
                            itemDirection: 'left-to-right',
                            itemWidth: 100,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'square',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        </>
    ) : (
        <div>
            <Alert color={'secondary'} className={'m-2 text-center'}>
                <FormattedMessage id={'message.no.data'} defaultMessage={'No data'} />
            </Alert>
        </div>
    );
};
export default injectIntl(QueueOverTimeReport);
