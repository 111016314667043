import React, {useState} from 'react';
import {faAngleDown, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Collapse, NavLink} from 'reactstrap';
import {injectIntl} from 'react-intl';

const SectionComponent = injectIntl(
    ({intl, title, options, optionTitles, onSelect, isOpen, selectedOption}) => {
        const [open, setOpen] = useState(isOpen);

        return (
            <>
                <div className='section'>
                    <div
                        className='section-title-container d-flex
                flex-row justify-content-start align-items-center pt-2 pb-2 cursor-pointer'
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <FontAwesomeIcon
                            fixedWidth
                            className={'mr-1'}
                            icon={open ? faAngleDown : faAngleRight}
                            color='#E0007A'
                        />
                        <div className='section-title'>{title}</div>
                    </div>
                </div>
                <Collapse isOpen={open}>
                    <div className='section-list pl-4'>
                        {options.map((option, key) => {
                            let title;
                            if (optionTitles && optionTitles.length)
                                title = optionTitles[option]
                                    ? optionTitles[option]
                                    : intl.formatMessage({id: option, defaultMessage: 'Option'});
                            else title = intl.formatMessage({id: option, defaultMessage: 'Option'});
                            return (
                                <NavLink
                                    className={'admin-link cursor-pointer'}
                                    style={{fontWeight: 300}}
                                    tag={'span'}
                                    onClick={() => {
                                        onSelect(option);
                                    }}
                                    key={key}
                                    active={option === selectedOption}
                                >
                                    {title}
                                </NavLink>
                            );
                        })}
                    </div>
                </Collapse>
            </>
        );
    }
);

const SideSelector = injectIntl(
    ({intl, options, selectedOption, onSelect, optionTitles, subOptionTitles}) => (
        <div className='sidebar admin-sidebar'>
            <div style={{height: '30px'}} />
            {Object.keys(options).map((option, key) => {
                let title;
                if (optionTitles && optionTitles.length)
                    title = optionTitles[option]
                        ? optionTitles[option]
                        : intl.formatMessage({id: option, defaultMessage: 'Option'});
                else title = intl.formatMessage({id: option, defaultMessage: 'Option'});
                return (
                    <SectionComponent
                        key={key}
                        title={title}
                        options={Object.keys(options[option])}
                        optionTitles={subOptionTitles}
                        onSelect={onSelect}
                        isOpen={Object.keys(options[option]).some((opt) => opt === selectedOption)}
                        selectedOption={selectedOption}
                    />
                );
            })}
        </div>
    )
);

export default SideSelector;
