import React from 'react';
import {FilterDisplays} from '../call/filters/FilterDisplays';
import {filters} from '../call/filters/FilterFactories';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faMinus,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {formatAlphanumericString} from '../../utils/format';

const WidgetHeader = ({
    widget,
    editMode,
    layouts,
    setLayouts,
    currentLayout,
    setCurrentWidget,
    setModalOpen,
}) => {
    const updateGraphWidth = (id) => {
        setLayouts({
            ...layouts,
            [currentLayout]: layouts[currentLayout].map((widget) => {
                if (widget.i === id && (widget.width === 1 || widget.width === 2)) {
                    return {...widget, width: 3 - widget.width};
                }
                return widget;
            }),
        });
    };

    const updateGraphHeight = (id) => {
        setLayouts({
            ...layouts,
            [currentLayout]: layouts[currentLayout].map((widget) => {
                if (widget.i === id && (widget.height === 1 || widget.height === 2)) {
                    return {...widget, height: 3 - widget.height};
                }
                return widget;
            }),
        });
    };

    const openEditWidget = (id) => {
        for (let j = 0; j < layouts[currentLayout].length; j++) {
            if (layouts[currentLayout][j].i === id) {
                setCurrentWidget(layouts[currentLayout][j]);
                break;
            }
        }
        setModalOpen(true);
    };

    const deleteWidget = (id) => {
        setLayouts({
            ...layouts,
            [currentLayout]: layouts[currentLayout].filter((layout) => {
                return layout.i !== id;
            }),
        });
    };

    const deserializedFilters = widget.filters
        ? Object.values(widget.filters)
              .map((f) => {
                  return filters[f.type]
                      ? filters[f.type].hasOwnProperty('deserializer')
                          ? filters[f.type].deserializer(f.serialized)
                          : filters[f.type].hasOwnProperty('factory')
                          ? filters[f.type].factory(f.serialized)
                          : null
                      : null;
              })
              .filter((f) => f != null)
        : [];

    return (
        <span className={'d-flex flex-row w-100'}>
            <span
                className={'d-flex flex-row flex-grow-1 align-items-center'}
                style={{width: '80%'}}
            >
                <h4 className={'my-0 mr-3'} style={{whiteSpace: 'nowrap'}}>
                    {widget.title}
                </h4>
                <FilterDisplays
                    id={formatAlphanumericString(`widget-${widget.i}-filters`)}
                    filters={deserializedFilters}
                    limit={widget.width || 1}
                />
            </span>
            {editMode ? (
                <div className={'d-flex flex-row justify-content-right align-items-center'}>
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            updateGraphWidth(widget.i);
                        }}
                        className={'ml-2'}
                    >
                        {widget.width === 1 ? (
                            <span className={'d-flex flex-row'}>
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faChevronLeft}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faPlus}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faChevronRight}
                                />
                            </span>
                        ) : (
                            <span className={'d-flex flex-row'}>
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faChevronRight}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faMinus}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faChevronLeft}
                                />
                            </span>
                        )}
                    </span>
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            updateGraphHeight(widget.i);
                        }}
                        className={'ml-2'}
                    >
                        {widget.height === 1 ? (
                            <span className={'d-flex flex-column'}>
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    style={{marginBottom: -5.25}}
                                    size={'sm'}
                                    icon={faChevronUp}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    style={{marginBottom: -5.25}}
                                    size={'sm'}
                                    icon={faPlus}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faChevronDown}
                                />
                            </span>
                        ) : (
                            <span className={'d-flex flex-column'}>
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    style={{marginBottom: -5.25}}
                                    size={'sm'}
                                    icon={faChevronDown}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    style={{marginBottom: -5.25}}
                                    size={'sm'}
                                    icon={faMinus}
                                />
                                <FontAwesomeIcon
                                    className={'text-primary'}
                                    size={'sm'}
                                    icon={faChevronUp}
                                />
                            </span>
                        )}
                    </span>
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            openEditWidget(widget.i);
                        }}
                        className={'ml-2'}
                    >
                        <i className={'fa fa-cog'} />
                    </span>
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            deleteWidget(widget.i);
                        }}
                        className={'ml-2'}
                    >
                        <i className={'fa fa-times'} />
                    </span>
                </div>
            ) : (
                <span>{null}</span>
            )}
        </span>
    );
};

export default WidgetHeader;
