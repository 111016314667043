import React from 'react';
import {Route} from 'react-router-dom';
import ComingSoon from 'views/admin/ComingSoon';
import superAdministration from '../../routes/superAdministration';
import {SuperAdminIndex} from '../admin/AdminIndex';

const SuperAdminRouter = (props) => {
    const getRoutes = (routes, path = '') =>
        routes.map((route, key) => {
            if (route.private) return null;
            return (
                <Route path={path + route.path} exact={!!route.exact} key={key}>
                    {route.wrapper ? (
                        <route.wrapper
                            title={route.title}
                            name={route.name}
                            Component={route.component}
                        />
                    ) : route.component ? (
                        <route.component {...route.props} />
                    ) : (
                        <ComingSoon />
                    )}
                </Route>
            );
        });

    return (
        <>
            {superAdministration.map((section) => {
                return getRoutes(section.subroutes, `${props.match.url}${section.path}`);
            })}
            <Route exact path={props.match.url} component={SuperAdminIndex} />
        </>
    );
};

export default SuperAdminRouter;
