import {capitalize} from '../../../utils/format.js';
import moment from 'moment';
import {status_label} from '../../../utils/call_history';

const esDateToHour = (esDate, locale) => {
    return capitalize(moment(esDate).locale(locale).format('HH:mm'));
};

const esDateToDay = (esDate, locale) => {
    return moment(esDate).locale(locale).format('DD/MM');
};

const esDateToMonth = (esDate, locale) => {
    return capitalize(moment(esDate).locale(locale).format('MMM YYYY'));
};

const formatAgent = (string, extensions) => {
    if (extensions) {
        const agent = extensions.find((e) => e.extension === string);
        return agent
            ? agent.name
                ? `${agent.extension} - ${agent.name}`
                : agent.extension
            : string;
    } else return string;
};

export const parseLineCallsByHour = (data, locale) => {
    if (data.buckets.length <= 24) {
        return [
            {
                id: 'Calls',
                color: '',
                data: data.buckets.map((val) => ({
                    x: esDateToHour(val['key_as_string'], locale),
                    y: val['doc_count'],
                })),
            },
        ];
    } else return [{id: 'Calls', color: '', data: null}];
};

export const parseLineCallsByDay = (data, locale) => {
    if (data.buckets.length <= 61) {
        return [
            {
                id: 'Calls',
                color: '',
                data: data.buckets.map((val) => ({
                    x: esDateToDay(val['key_as_string'], locale),
                    y: val['doc_count'],
                })),
            },
        ];
    } else return [{id: 'Calls', color: '', data: null}];
};

export const parseLineCallsByMonth = (data, locale) => {
    return [
        {
            id: 'Calls',
            color: '',
            data: data.buckets.map((val) => ({
                x: esDateToMonth(val['key_as_string'], locale),
                y: val['doc_count'],
            })),
        },
    ];
};

export const parsePieData = (data) => {
    return data.buckets
        .map((val) => ({
            id: val['key'],
            label: capitalize(val['key']),
            value: val['doc_count'],
            color: '',
        }))
        .filter((slice) => slice.value !== 0);
};

export const parsePieDataByStatus = (data) => {
    return data.buckets
        .map((val) => ({
            id: status_label(val['key']),
            label: status_label(val['key']),
            value: val['doc_count'],
            color: '',
        }))
        .filter((slice) => slice.value !== 0);
};

export const parsePieDataByAgent = (data, extensions) => {
    return extensions
        .map((ext) => {
            const val = data.buckets.find((v) => v['key'] === ext.extension);
            return {
                id: ext.extension,
                label: ext.name ? `${ext.extension} - ${ext.name}` : ext.extension,
                value: val ? val['doc_count'] : 0,
                color: '',
            };
        })
        .filter((slice) => slice.value !== 0);
};

export const parseBarCallsByAgent = (data, extensions) => {
    if (data.buckets.length > 4) {
        return data.buckets.map((val) => ({
            Agent: val['key'],
            Calls: val['doc_count'],
            CallsColor: '',
        }));
    } else {
        return data.buckets.map((val) => ({
            Agent: formatAgent(val['key'], extensions),
            Calls: val['doc_count'],
            CallsColor: '',
        }));
    }
};

export const parseBarCallsByStatus = (data) => {
    return data.buckets.map((val) => ({
        Status: status_label(val['key']),
        Calls: val['doc_count'],
        CallsColor: '',
    }));
};

export const parseProgressData = (data) => {
    let allCalls = {
        key: 'All Calls',
        text: 'All Calls',
        value: 0,
    };

    let result = data.buckets.map((val) => {
        allCalls.value += val['doc_count'];
        return {
            key: val['key'],
            text: capitalize(val['key']),
            value: val['doc_count'],
        };
    });

    result.unshift(allCalls);
    return result;
};

export const parseProgressDataByAgent = (data, extensions) => {
    let allCalls = {
        key: 'All Calls',
        text: 'All Calls',
        value: 0,
    };

    let result = extensions.map((ext) => {
        const val = data.buckets.find((v) => v['key'] === ext.extension);
        if (val) allCalls.value += val['doc_count'];
        return {
            key: ext.extension,
            text: ext.name ? `${ext.extension} - ${ext.name}` : ext.extension,
            value: val ? val['doc_count'] : 0,
        };
    });

    result.unshift(allCalls);
    return result;
};

export const parseBarAgentCallsByStatus = (data, extensions) => {
    let keys = [];

    const parsedData = data.buckets.map((val) => {
        if (val['calls_by_status']) {
            let stats = {};

            val['calls_by_status'].buckets.forEach((stat) => {
                const status = status_label(stat['key']);
                stats[status] = stat['doc_count'];
                stats[status + 'Color'] = '';
                if (!keys.some((key) => key === status)) keys.push(status);
            });
            return {
                ...stats,
                Agent: data.buckets.length > 4 ? val['key'] : formatAgent(val['key'], extensions),
            };
        } else {
            return {
                Agent: data.buckets.length > 4 ? val['key'] : formatAgent(val['key'], extensions),
                Calls: val['doc_count'],
                CallsColor: '',
            };
        }
    });
    return {data: parsedData, keys};
};

export const parseBarAgentCallsByTags = (data, extensions) => {
    let keys = [];

    let parsedData = [];

    data.buckets.forEach((val) => {
        if (val['calls_by_tags']) {
            let stats = {};

            val['calls_by_tags'].buckets.forEach((stat) => {
                if (stat['doc_count']) {
                    stats[stat['key']] = stat['doc_count'];
                    stats[stat['key'] + 'Color'] = '';
                    if (!keys.some((key) => key === stat['key'])) keys.push(stat['key']);
                }
            });
            const result = {
                ...stats,
                Agent: data.buckets.length > 4 ? val['key'] : formatAgent(val['key'], extensions),
            };
            if (Object.keys(result).length > 1) parsedData.push(result);
        } else {
            parsedData.push({
                Agent: data.buckets.length > 4 ? val['key'] : formatAgent(val['key'], extensions),
                Calls: val['doc_count'],
                CallsColor: '',
            });
        }
    });
    parsedData.sort((a, b) => {
        let aSum = 0;
        let bSum = 0;
        keys.forEach((key) => {
            if (a[key]) aSum += a[key];
            if (b[key]) bSum += b[key];
        });
        if (aSum > bSum) {
            return -1;
        } else if (aSum < bSum) {
            return 1;
        } else {
            return 0;
        }
    });
    return {data: parsedData, keys};
};

export const dataPassThrough = (data) => {
    return data;
};
