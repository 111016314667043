import React from 'react'
import {Card, CardBody} from 'reactstrap'
import {FormattedMessage} from 'react-intl'
import RedirectTo from '../../../components/utils/RedirectTo'

const WaitingSingUp = ({loading}) => (
    <Card className="col-6 align-self-center bg-secondary border-0 no-select d-flex">
        <CardBody className="">
            {loading ? <FormattedMessage
                    id="message.verify.link.loading"
                    defaultMessage="We are verifying your link"
                >
                    {(txt) => <span>{txt}</span>}
                </FormattedMessage> :
                <>
                    <FormattedMessage
                        id="message.verify.link.false"
                        defaultMessage="Your link has already been used"
                    >
                        {(txt) => <span>{txt}</span>}
                    </FormattedMessage>
                    <br />
                    <RedirectTo timer={10} path={'/'}/>
                </>
            }
        </CardBody>
    </Card>
)

export default WaitingSingUp