import {call, takeLatest} from '@redux-saga/core/effects';
import API from 'utils/api';
import {WARM_UP} from 'store/actions/portal-action';

export function* warmUpLambdaSaga() {
    try {
        yield call(API.get, {endpoint: `/me/`, tries: 1});
    } catch (e) {}
}
const portalSagas = [takeLatest(WARM_UP, warmUpLambdaSaga)];

export default portalSagas;
